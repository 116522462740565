import { CreateSalesOrderGrid } from '@components/organisms';
import { CreateSalesOrderContextualPanelContainer } from '@containers/CreateSalesOrderContextualPanelContainer';
import { MemberPageContainer, MemberPageContainerProps } from '@containers/MemberPageContainer';
import { HTTP_ERRORS } from '@models';
import {
  ActionBar,
  Alert,
  AlertDescription,
  AlertTitle,
  Dialog,
  DialogTypes,
  Flex,
  LayoutContent,
  Panel,
  PanelTitle,
} from '@oplog/express';
import { urls } from '@routes/urls';
import * as React from 'react';
import { useState } from 'react';
import { Props } from '../../atoms/Component/Component';

const COMPONENT_INTL_KEY = 'CreateSalesOrder';

export interface CreateSalesOrderProps extends Props {
  onDismissPostErrors: () => void;
  error?: ErrorModel;
}

export interface CreateSalesOrderState {
  isPostErrorOpen: boolean;
}

export const CreateSalesOrder: React.FC<CreateSalesOrderProps> = ({ intl, error, onDismissPostErrors }) => {
  const [isPostErrorOpen, setIsOpenErrorOpen] = useState(false);
  const [errorCode, setErrorCode] = useState<any>('');

  const handlePostError = () => {
    if (error && error.errors) {
      error.errors.forEach(error => {
        setErrorCode(error.errorCode);
      });
    }

    setIsOpenErrorOpen(true);
  };

  const handlePostErrorDismiss = () => {
    setIsOpenErrorOpen(false);
    // When error happens clear the related post store
    onDismissPostErrors();
  };

  const renderContextualPanel = () => {
    return (
      <CreateSalesOrderContextualPanelContainer
        onPostError={handlePostError}
        closeErrorAlert={handlePostErrorDismiss}
      />
    );
  };

  const contextualProps: Partial<MemberPageContainerProps> = {
    contextualArea: {
      component: renderContextualPanel(),
      header: intl.messages[`${COMPONENT_INTL_KEY}.ContextualPanel.Title`],
    },
  };

  const breadcrumb = [
    { title: intl.messages['SalesOrders.Header.Title'], url: urls.salesOrders },
    { title: intl.messages[`${COMPONENT_INTL_KEY}.Header.Title`] },
  ];

  // TODO, Dialog is rendered both here and @CreateSalesOrderContextualPanel.tsx. The one in there doesn't work but it should be handled from there.
  // Further investigation required.

  return (
    <MemberPageContainer documentTitle={intl.messages[`${COMPONENT_INTL_KEY}.Header.Title`]} {...contextualProps}>
      <ActionBar top="66px" title={intl.messages[`${COMPONENT_INTL_KEY}.Header.Title`]} breadcrumb={breadcrumb} />

      <Dialog
        type={DialogTypes.Danger}
        message={intl.messages[`${COMPONENT_INTL_KEY}.ReferenceNumberExists`]}
        isOpen={error?.code === HTTP_ERRORS.Conflict}
        text={{ approve: intl.messages[`Modal.Success.Okay`] }}
        onApprove={() => {
          handlePostErrorDismiss();
        }}
      />

      <Alert
        variant="danger"
        isOpen={error?.code === HTTP_ERRORS.BadRequest && isPostErrorOpen}
        onDismiss={() => handlePostErrorDismiss()}
      >
        <AlertTitle>{intl.messages['CreateSalesOrderExceptionMessage.Title']}</AlertTitle>
        <AlertDescription>
            {errorCode
              ? intl.messages[`CreateSalesOrderExceptionMessage.${errorCode}`]
              : intl.messages['CreateSalesOrderExceptionMessage.UnhandledException']}
          </AlertDescription>
      </Alert>

      <LayoutContent className="page-content warehouse-content">
        <Panel>
          <Flex justifyContent="space-between">
            <PanelTitle>{intl.messages[`${COMPONENT_INTL_KEY}.Grid.Title`]}</PanelTitle>
          </Flex>
          <CreateSalesOrderGrid intl={intl} />
        </Panel>
      </LayoutContent>
    </MemberPageContainer>
  );
};

import { FormatterProps } from '@oplog/data-grid';
import { Flex, Text } from '@oplog/express';
import * as React from 'react';
import { FormattedMessage, InjectedIntl } from 'react-intl';

export const receivingOrderDifferenceBadge = (intl: InjectedIntl, gridKey: string, row: any) => {
  let badgeColor = '';
  let intlKey = '';

  if (!row.isArrivedToWarehouse) {
    badgeColor = 'palette.grey';
    intlKey = '3';
  } else {
    if (row.acceptedQuantityDifference === 0) {
      badgeColor = 'palette.green';
      intlKey = '2';
    }
    if (row.acceptedQuantityDifference < 0) {
      badgeColor = 'palette.red';
      intlKey = '1';
    }
    if (row.acceptedQuantityDifference > 0) {
      badgeColor = 'palette.yellow';
      intlKey = '0';
    }
  }
  const text = (
    <FormattedMessage
      id={`${gridKey}.BadgeState.${intlKey}`}
      defaultMessage={intl.messages[`${gridKey}.BadgeState.${intlKey}`]}
      values={{ value: Math.abs(row.acceptedQuantityDifference) || 0 }}
    />
  );
  return {
    badgeColor,
    text,
  };
};

export function coloredBadgeFormatter(props: FormatterProps) {
  if (props) {
    return (
      <Flex
        alignContent="center"
        justifyContent="center"
        width="105px"
        borderRadius="16px"
        backgroundColor={props.dependentValues.color}
      >
        <Text color="palette.white" py="6" fontSize="11" fontWeight={600} lineHeight="normal">
          {props.dependentValues.text}
        </Text>
      </Flex>
    );
  }
  return <></>;
}

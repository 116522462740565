export const RETURN_DETAILS_IMAGE_PREVIEW_OPENED = 'RETURN_DETAILS_IMAGE_PREVIEW_OPENED';
export type RETURN_DETAILS_IMAGE_PREVIEW_OPENED = typeof RETURN_DETAILS_IMAGE_PREVIEW_OPENED;

export const RETURN_DETAILS_IMAGE_PREVIEW_CLOSED = 'RETURN_DETAILS_IMAGE_PREVIEW_CLOSED';
export type RETURN_DETAILS_IMAGE_PREVIEW_CLOSED = typeof RETURN_DETAILS_IMAGE_PREVIEW_CLOSED;

export const RETURN_DETAILS_IMAGE_PREVIEW_ACTIVE_INDEX_CHANGED = 'RETURN_DETAILS_IMAGE_PREVIEW_ACTIVE_INDEX_CHANGED';
export type RETURN_DETAILS_IMAGE_PREVIEW_ACTIVE_INDEX_CHANGED = typeof RETURN_DETAILS_IMAGE_PREVIEW_ACTIVE_INDEX_CHANGED;

export const RETURN_DETAILS_SET_IMAGES = 'RETURN_DETAILS_SET_IMAGES';
export type RETURN_DETAILS_SET_IMAGES = typeof RETURN_DETAILS_SET_IMAGES;

import { DataGridColumn, ExtendedDataGrid, ExtendedDataGridProps } from '@oplog/data-grid';
import { StoreState } from '@store/state';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { tracking } from '../../utils/analytics';

export interface WarehouseCartGridContainerProps {
  intlKey: string;
  columns: Array<DataGridColumn>;
  noRowsView: React.FC<{}>;
  rows: any;
}

function mapStateToProps(
  { grid }: StoreState,
  ownProps: WarehouseCartGridContainerProps
): Partial<ExtendedDataGridProps> {
  const { intlKey, columns, rows } = ownProps;

  return {
    noRowsView: ownProps.noRowsView,
    intlKey,
    rows: rows,
    columns,
    enableRowSelect: false,
    eventCallbacks: {
      onActionsClick: data => {
        tracking.emitGridActionClick(data);
      },
      onFilterButtonClick: () => {
        tracking.emitFilterOpen();
      },
      onFilterAddClick: data => {
        tracking.emitFilterAdd(data);
      },
      onFilterSendClick: data => {
        tracking.emitFilterSend(data);
      },
    },
  };
}

export const WarehouseCartGridContainer = withRouter(
  connect(mapStateToProps, {})(injectIntl(ExtendedDataGrid)) as any
) as any;

import { GridContainer } from '@components/atoms/GridContainer';
import { SortDirection, SortField } from 'dynamic-query-builder-client';
import * as React from 'react';
import { GridProps, GridType } from '../../../models';
import getPackageManipulationColumns from './bones/PackageManipulationColumns';
import getPackageProgressionColumns from './bones/PackageProgressionColumns';
import { getPackageSyncStatusColumns } from './bones/PackageSyncStatusColumns';

const intlKey = 'SupportPage.PackagePage';

const tenantGridInitalSort: SortField = new SortField({
  property: 'createdAt',
  by: SortDirection.DESC,
});

export interface SupportPackagesGridProps extends GridProps {
  type: String;
}

export enum SupportPackagesGridTabs {
  PackageProgression = 'PackageProgression',
  SyncStatus = 'SyncStatus',
  DataManipulation = 'DataManipulation',
}

export class SupportPackagesGrid extends React.Component<SupportPackagesGridProps> {
  render() {
    const { intl, titleKey, type } = this.props;

    var columns;
    if (type === SupportPackagesGridTabs.PackageProgression) {
      columns = getPackageProgressionColumns(intl, intlKey);
    } else if (type === SupportPackagesGridTabs.SyncStatus) {
      columns = getPackageSyncStatusColumns(intl, intlKey);
    } else if (type === SupportPackagesGridTabs.DataManipulation) {
      columns = getPackageManipulationColumns(intl, intlKey);
    }

    return (
      <GridContainer
        titleKey={titleKey ? `${intl.messages['Grid.Title']} - ${titleKey}` : intl.messages['Grid.Title']}
        intlKey={intlKey}
        gridKey={GridType.SupportPackages}
        columns={columns}
        apiArgs={[]}
        actionsDisabled={true}
        predefinedFilters={[]}
        intl={this.props.intl}
        sortField={tenantGridInitalSort}
      />
    );
  }
}

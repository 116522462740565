import { urls } from '@routes/urls';
import React, { useEffect } from 'react';
import { useAuth0 } from '../../../auth/auth0';

const Login: React.FC = () => {
  const { loginWithRedirect, loading } = useAuth0();

  useEffect(() => {
    if (!loading) {
      loginWithRedirect({ appState: urls.home });
    }
  }, [loading]);
  return null;
};
export default Login;

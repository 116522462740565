import { ResourceType } from '@models';
import { validation } from '@oplog/express';
import { fileUploadInit, fileUploadRequested, FileUploadsAction, getUrl, isBusy } from '@oplog/file-uploader';
import { resourceActions, resourceSelectors } from '@oplog/resource-redux';
import { ProductDetails, ProductDetailsProps } from '@pages/ProductDetails';
import { UpdateProductCommand } from '@services';
import { StoreState } from '@store';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { Dispatch } from 'redux';
import { getRouteParam } from '@utils';

export function mapStateToProps({ resources, files }: StoreState): Partial<ProductDetailsProps> {
  return {
    productDetails: resourceSelectors.getData(resources, ResourceType.ProductDetails),
    productFavorite: resourceSelectors.getData(resources, ResourceType.ProductFavorite),
    isBusy: resourceSelectors.isBusy(resources, ResourceType.ProductDetails),
    error: resourceSelectors.getError(resources, ResourceType.ProductDetails),
    updateError: resourceSelectors.getError(resources, ResourceType.UpdateProduct),
    getFavoriteIsBusy: resourceSelectors.isBusy(resources, ResourceType.ProductFavorite),
    getFavoriteError: resourceSelectors.getError(resources, ResourceType.ProductFavorite),
    updateFavoriteError: resourceSelectors.getError(resources, ResourceType.UpdateProductFavorite),
    updateFavoriteIsBusy: resourceSelectors.isBusy(resources, ResourceType.UpdateProductFavorite),
    updateFavoriteSuccess: resourceSelectors.isSuccessComplete(resources, ResourceType.UpdateProductFavorite),
    fileUrl: getUrl(files, '0'),
    isUploading: isBusy(files, '0'),
    isUpdateSuccessFull: resourceSelectors.isSuccessComplete(resources, ResourceType.UpdateProduct),
    isUpdateBusy: resourceSelectors.isBusy(resources, ResourceType.UpdateProduct),
  };
}

export function mapDispatchToProps(
  dispatch: Dispatch<resourceActions.ResourceAction | FileUploadsAction | resourceActions.ResourceInit>,
  routeProps: RouteComponentProps
): Partial<ProductDetailsProps> {
  return {
    onReset: () => dispatch(fileUploadInit()),
    onFileSelect: (file: File) => {
      dispatch(fileUploadRequested('0', file));
    },
    onProductUpdate: (command: UpdateProductCommand) => {
      dispatch(resourceActions.resourceRequested(ResourceType.UpdateProduct, { command }));
    },
    clearError: () => {
      dispatch(resourceActions.resourceInit(ResourceType.UpdateProduct));
    },
    onRefresh: () => {
      const sku = getRouteParam(routeProps, 'sku');
      if (sku) {
        const decodedSKU = decodeURIComponent(sku);
        dispatch(resourceActions.resourceInit(ResourceType.UpdateProduct));
        dispatch(resourceActions.resourceRequested(ResourceType.ProductDetails, { sku: decodedSKU }));
      }
    },
    onDidMount(): void {
      const sku = getRouteParam(routeProps, 'sku');
      if (sku) {
        const decodedSKU = decodeURIComponent(sku);
        dispatch(resourceActions.resourceRequested(ResourceType.ProductDetails, { sku: decodedSKU }));
        dispatch(resourceActions.resourceRequested(ResourceType.ProductFavorite, { sku: decodedSKU }));
      }
    },
    onWillUnmount: (): void => {
      dispatch(resourceActions.resourceInit(ResourceType.ProductDetails));
      dispatch(resourceActions.resourceInit(ResourceType.ProductFavorite));
      dispatch(resourceActions.resourceInit(ResourceType.UpdateProductFavorite));
      dispatch(resourceActions.resourceInit(ResourceType.UpdateProduct));
    },
    onFavoriteChanged: (isFavorited: boolean): void => {
      const sku = getRouteParam(routeProps, 'sku');
      if (sku) {
        const decodedSKU = decodeURIComponent(sku);
        dispatch(
          resourceActions.resourceRequested(ResourceType.UpdateProductFavorite, {
            command: { sku: decodedSKU, favorite: isFavorited },
          })
        );
      }
    },
    onUpdateProductFavoriteSuccess: () => {
      const sku = getRouteParam(routeProps, 'sku');
      if (sku) {
        const decodedSKU = decodeURIComponent(sku);
        dispatch(resourceActions.resourceInit(ResourceType.UpdateProductFavorite));
        dispatch(resourceActions.resourceRequested(ResourceType.ProductFavorite, { sku: decodedSKU }));
      }
    },
  };
}

export const ProductDetailsContainer = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(validation(injectIntl(ProductDetails)))
);

import {
  SupportPackagesGrid,
  SupportPackagesGridTabs,
} from '@components/organisms/SupportPackagesGrid/SupportPackagesGrid';
import { SupportPackageResetWMSSyncStateContainer } from '@containers/SupportPackageResetWMSSyncStateContainer';
import { SupportPackagesResetFulfillmentSyncContainer } from '@containers/SupportPackagesResetFulfillmentSyncContainer';
import { SupportPackagesWMSSyncContainer } from '@containers/SupportPackagesWMSSyncContainer';
import { ActionBar, Box, Button, Flex, LayoutContent, Tab } from '@oplog/express';
import { urls } from '@routes/urls';
import * as React from 'react';
import { useState } from 'react';
import { MemberPageContainer } from '../../../containers/MemberPageContainer/MemberPageContainer';
import { Props } from '../../atoms/Component/Component';

const COMPONENT_INTL_KEY: string = 'SupportPage.PackagePage';

export interface SupportPackagesProps extends Props {
  closeModalViewer: () => void;
}

export const SupportPackages: React.FC<SupportPackagesProps> = ({ intl, closeModalViewer }) => {
  const [currentTab, setCurrentTab] = useState(0);
  const [modalStates, setModalStates] = useState({
    isSyncUnfulfilledIntOpen: false,
    isResetWmsSyncStateModalOpen: false,
    isPackageResetModalOpen: false,
  });

  const syncUnfulfilledIntModalClose = () => {
    setModalStates({
      ...modalStates,
      isSyncUnfulfilledIntOpen: false,
    });
    closeModalViewer();
  };

  const resetWMSSyncModalClose = () => {
    setModalStates({
      ...modalStates,
      isResetWmsSyncStateModalOpen: false,
    });
    closeModalViewer();
  };

  const packageResetModalClose = () => {
    setModalStates({
      ...modalStates,
      isPackageResetModalOpen: false,
    });
    closeModalViewer();
  };

  const renderSyncUnfulfilledIntModal = () => {
    const { isSyncUnfulfilledIntOpen } = modalStates;

    if (isSyncUnfulfilledIntOpen) {
      return <SupportPackagesWMSSyncContainer onClose={syncUnfulfilledIntModalClose} />;
    }
    return null;
  };

  const renderPackagesResetWMSModal = () => {
    const { isResetWmsSyncStateModalOpen } = modalStates;

    if (isResetWmsSyncStateModalOpen) {
      return <SupportPackageResetWMSSyncStateContainer onClose={resetWMSSyncModalClose} />;
    }
    return null;
  };

  const renderPackagesResetFullfillmentModal = () => {
    const { isPackageResetModalOpen } = modalStates;

    if (isPackageResetModalOpen) {
      return <SupportPackagesResetFulfillmentSyncContainer onClose={packageResetModalClose} />;
    }
    return null;
  };

  const breadcrumb = [
    {
      title: intl.messages[`SupportPage.Common.SupportTitle`],
      url: urls.support,
    },
    {
      title: intl.messages[`${COMPONENT_INTL_KEY}.Breadcrumb.Title`],
    },
  ];

  return (
    <MemberPageContainer documentTitle={intl.messages[`${COMPONENT_INTL_KEY}.Header.Title`]}>
      <ActionBar
        top="66px"
        title={intl.messages[`${COMPONENT_INTL_KEY}.Header.Title`]}
        subtitle=""
        breadcrumb={breadcrumb}
      >
        <Flex marginLeft="auto">
          <Button
            variant="dark"
            size="large"
            dropdown={{
              items: [
                {
                  text: intl.messages[`${COMPONENT_INTL_KEY}.Buttons.Dropdown.PackageResetModal`],
                  value: intl.messages[`${COMPONENT_INTL_KEY}.Buttons.Dropdown.PackageResetModal`],
                  onClick: () =>
                    setModalStates({
                      ...modalStates,
                      isPackageResetModalOpen: true,
                    }),
                },
                {
                  text: intl.messages[`${COMPONENT_INTL_KEY}.Buttons.Dropdown.ResetWmsSyncStateModalOpen`],
                  value: intl.messages[`${COMPONENT_INTL_KEY}.Buttons.Dropdown.ResetWmsSyncStateModalOpen`],
                  onClick: () =>
                    setModalStates({
                      ...modalStates,
                      isResetWmsSyncStateModalOpen: true,
                    }),
                },
                {
                  text: intl.messages[`${COMPONENT_INTL_KEY}.Buttons.Dropdown.SyncPackageFromWMS`],
                  value: intl.messages[`${COMPONENT_INTL_KEY}.Buttons.Dropdown.SyncPackageFromWMS`],
                  onClick: () =>
                    setModalStates({
                      ...modalStates,
                      isSyncUnfulfilledIntOpen: true,
                    }),
                },
              ],
            }}
          >
            {intl.messages[`SupportPage.Common.ActionsButton`]}
          </Button>
        </Flex>
      </ActionBar>

      <LayoutContent className="page-content">
        <Box>
          <Tab
            onTabChange={data => {
              setCurrentTab(data);
            }}
            activeIndex={currentTab}
            tabs={[
              {
                id: SupportPackagesGridTabs.PackageProgression,
                title: intl.messages[`${COMPONENT_INTL_KEY}.Grid.Orders`],
                component: <SupportPackagesGrid intl={intl} type={SupportPackagesGridTabs.PackageProgression} />,
              },
              {
                id: SupportPackagesGridTabs.SyncStatus,
                title: intl.messages[`${COMPONENT_INTL_KEY}.Grid.SyncStatus`],
                component: <SupportPackagesGrid intl={intl} type={SupportPackagesGridTabs.SyncStatus} />,
              },
              {
                id: SupportPackagesGridTabs.DataManipulation,
                title: intl.messages[`${COMPONENT_INTL_KEY}.Grid.DataManipulation`],
                component: <SupportPackagesGrid intl={intl} type={SupportPackagesGridTabs.DataManipulation} />,
              },
            ]}
          />
        </Box>
      </LayoutContent>

      {renderSyncUnfulfilledIntModal()}
      {renderPackagesResetWMSModal()}
      {renderPackagesResetFullfillmentModal()}
    </MemberPageContainer>
  );
};

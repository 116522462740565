import {
  CreateCustomerMultiForm,
  CreateCustomerMultiFormProps,
} from '@components/molecules/CreateCustomerMultiForm/CreateCustomerMultiForm';
import { ResourceType } from '@models';
import { validation } from '@oplog/express';
import { resourceActions, resourceSelectors } from '@oplog/resource-redux';
import { CreateCustomerCommand } from '@services';
import { StoreState } from '@store/state';
import { RouterAction } from 'connected-react-router';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

export interface CreateCustomerMultiFormContainerProps {
  isOpen: boolean;
  onModalClose: (isCancelClickButton: boolean) => void;
  closeModal: () => void;
}

export function mapStateToProps(
  { resources }: StoreState,
  ownProps: CreateCustomerMultiFormContainerProps
): Partial<CreateCustomerMultiFormProps> {
  return {
    isCreateCustomerSuccess: resourceSelectors.isSuccessComplete(resources, ResourceType.CreateCustomer),
    createdCustomerData: resourceSelectors.getData(resources, ResourceType.CreateCustomer),
    countryResource: resources[ResourceType.AllCountries],
    provinceResource: resources[ResourceType.AllProvinces],
    districtResource: resources[ResourceType.DistrictsOfProvince],
    isBusy: resourceSelectors.isBusy(resources, ResourceType.CreateCustomer),
    error: resourceSelectors.getError(resources, ResourceType.CreateCustomer),
    isModalOpen: ownProps.isOpen,
    onModalClose: ownProps.onModalClose,
    closeModal: ownProps.closeModal,
  };
}

export function mapDispatchToProps(
  dispatch: Dispatch<resourceActions.ResourceAction | RouterAction>
): Partial<CreateCustomerMultiFormProps> {
  return {
    onSubmit(form: CreateCustomerCommand) {
      dispatch(
        resourceActions.resourceRequested(ResourceType.CreateCustomer, {
          customerForm: form,
        })
      );
    },
    getNewCustomerInfo(customerNo: number) {
      dispatch(
        resourceActions.resourceRequested(ResourceType.CustomerInfo, {
          customerNo,
        })
      );
      dispatch(resourceActions.resourceRequested(ResourceType.CustomerShippingAddresses, { customerNo }));
      dispatch(resourceActions.resourceInit(ResourceType.CreateCustomer));
    },
    onWillUnmount: (): void => {
      dispatch(resourceActions.resourceInit(ResourceType.DistrictsOfProvince));
      dispatch(resourceActions.resourceInit(ResourceType.AllProvinces));
      dispatch(resourceActions.resourceInit(ResourceType.AllCountries));
    },
    onDidMount: (): void => { 
      dispatch(resourceActions.resourceRequested(ResourceType.AllProvinces));
      dispatch(resourceActions.resourceRequested(ResourceType.AllCountries));
    },
    provinceSelect: (command: { province: string }): void => {
      dispatch(resourceActions.resourceRequested(ResourceType.DistrictsOfProvince, command));
    },
    initError: (): void => {
      dispatch(resourceActions.resourceInit(ResourceType.CreateCustomer));
    },
  };
}

export const CreateCustomerMultiFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(validation(injectIntl(CreateCustomerMultiForm)));

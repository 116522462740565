import { CUSTOMER_DETAILS_NEW_ADDRESS_OPEN } from './constants';

export interface CustomerDetailsNewAddressClick {
  type: CUSTOMER_DETAILS_NEW_ADDRESS_OPEN;
  payload: {
    isNewAddressOpen: boolean;
  };
}

export type CustomerDetailsAction = CustomerDetailsNewAddressClick;

export function openNewAddressModal(): CustomerDetailsNewAddressClick {
  return {
    type: CUSTOMER_DETAILS_NEW_ADDRESS_OPEN,
    payload: {
      isNewAddressOpen: true,
    },
  };
}

export function closeNewAddressModal(): CustomerDetailsNewAddressClick {
  return {
    type: CUSTOMER_DETAILS_NEW_ADDRESS_OPEN,
    payload: {
      isNewAddressOpen: false,
    },
  };
}

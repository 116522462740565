let localToken = '';

class AuthManagement {
  setToken = (token: string) => {
    localToken = token;
  };

  getToken = () => localToken;
}

export default new AuthManagement() as any;

import { ResourceType } from '@models';
import { resourceActions, resourceSelectors } from '@oplog/resource-redux';
import { MemberPage, MemberPageProps } from '@pages/MemberPage';
import { StoreState } from '@store';
import { checkRoute } from '@utils';
import { push, RouterAction } from 'connected-react-router';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Dispatch } from 'redux';

export interface MemberPageContainerProps extends RouteComponentProps {
  contextualArea?: {
    component?: JSX.Element;
    header?: string;
  };
  className?: string;
  documentTitle: string;
  id?: string;
}

export function mapStateToProps(
  { resources }: StoreState,
  ownProps: MemberPageContainerProps
): Partial<MemberPageProps> {
  return {
    ...ownProps,
    tenantList: resourceSelectors.getResource(resources, ResourceType.GetAllTenants),
    integrations: resourceSelectors.getData(resources, ResourceType.GetIntegrations),
  };
}

export function mapDispatchToProps(
  dispatch: Dispatch<RouterAction | resourceActions.ResourceAction>,
  routeProps: MemberPageContainerProps
): Partial<MemberPageProps> {
  return {
    fetchTenantList: () => {
      dispatch(resourceActions.resourceRequested(ResourceType.GetAllTenants));
    },

    getIntegrations: () => {
      dispatch(resourceActions.resourceRequested(ResourceType.GetIntegrations));
    },

    routeTo: (url: string) => {
      if (url) {
        if (checkRoute(routeProps, url)) {
          return;
        }
        dispatch(push(url));
      }
    },
  };
}

export const MemberPageContainer = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(injectIntl(MemberPage))
) as any;

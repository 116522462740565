import { ResourceType } from '@models';
import { validation } from '@oplog/express';
import { resourceActions, resourceSelectors } from '@oplog/resource-redux';
import { CreateIntegrationCommand, IntegrationType, UpdateIntegrationCommand } from '@services';
import { StoreState } from '@store';
import { RouterAction } from 'connected-react-router';
import { Dispatch } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import {
  IntegrationChannel,
  IntegrationChannelProps,
} from '../../components/molecules/IntegrationChannel/IntegrationChannel';

export function mapStateToProps({ resources }: StoreState): Partial<IntegrationChannelProps> {
  return {
    createIntegrationResource: resources[ResourceType.CreateIntegration],
    createIntegrationResponseID: resourceSelectors.getData(resources, ResourceType.CreateIntegration),
    isCreateIntegrationSuccessful: resourceSelectors.isSuccessComplete(resources, ResourceType.CreateIntegration),
    isUpdateSuccessful: resourceSelectors.isSuccessComplete(resources, ResourceType.UpdateIntegration),
    integrations: resourceSelectors.getData(resources, ResourceType.GetIntegrations),
    getIntegrationPayloadResource: {
      data: resourceSelectors.getData(resources, ResourceType.GetIntegrationPayload),
      isBusy: resourceSelectors.isBusy(resources, ResourceType.GetIntegrationPayload),
      error: resourceSelectors.getError(resources, ResourceType.GetIntegrationPayload),
      isSuccess: resourceSelectors.isSuccessComplete(resources, ResourceType.GetIntegrationPayload),
    },
  };
}

export function mapDispatchToProps(
  dispatch: Dispatch<resourceActions.ResourceAction | RouterAction>
): Partial<IntegrationChannelProps> {
  return {
    onCreateIntegration: (command: CreateIntegrationCommand) => {
      dispatch(
        resourceActions.resourceRequested(ResourceType.CreateIntegration, {
          command,
        })
      );
    },
    handleUpdateSubmit: (command: UpdateIntegrationCommand) => {
      dispatch(
        resourceActions.resourceRequested(ResourceType.UpdateIntegration, {
          command,
        })
      );
    },
    refreshIntegrations: () => {
      dispatch(resourceActions.resourceInit(ResourceType.CreateIntegration));
      dispatch(resourceActions.resourceRequested(ResourceType.GetIntegrations));
    },

    onWillUnmount: () => {
      dispatch(resourceActions.resourceInit(ResourceType.CreateIntegration)),
        dispatch(resourceActions.resourceInit(ResourceType.UpdateIntegration));
    },
    initResources: () => {
      dispatch(resourceActions.resourceInit(ResourceType.CreateIntegration)),
        dispatch(resourceActions.resourceInit(ResourceType.UpdateIntegration));
    },
    onGetIntegrationPayload: (integrationId: string, integrationType: IntegrationType) => {
      dispatch(
        resourceActions.resourceRequested(ResourceType.GetIntegrationPayload, { integrationId, integrationType })
      );
    },
  };
}

export const IntegrationChannelContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(validation(injectIntl(IntegrationChannel)));

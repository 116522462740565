import { SendToCreateReturnCartGrid } from '@models';
import { SalesOrderQueryOutputDTO } from '@services/swagger';
import {
  CREATE_RETURN_FORM_EDITED,
  CREATE_RETURN_GRID_EDITED,
  CREATE_RETURN_LINE_ITEMS_REMOVED,
  CREATE_RETURN_LINE_ITEM_ADDED,
  CREATE_RETURN_SALES_ORDER_SELECTED,
  CREATE_RETURN_SALES_ORDER_REMOVED,
} from './constants';

export interface CreateReturnCartLineItemAdded {
  type: CREATE_RETURN_LINE_ITEM_ADDED;
  payload: {
    createReturn: SendToCreateReturnCartGrid;
  };
}

export interface CreateReturnCartLineItemsRemoved {
  type: CREATE_RETURN_LINE_ITEMS_REMOVED;
}

export interface CreateReturnCartFormEdited {
  type: CREATE_RETURN_FORM_EDITED;
}

export interface CreateReturnCartGridEdited {
  type: CREATE_RETURN_GRID_EDITED;
}

export interface CreateReturnSalesOrderSelected {
  type: CREATE_RETURN_SALES_ORDER_SELECTED;
  payload: {
    salesOrder: SalesOrderQueryOutputDTO;
  };
}

export interface CreateReturnSalesOrderRemoved {
  type: CREATE_RETURN_SALES_ORDER_REMOVED;
}

export type CreateReturnAction =
  | CreateReturnCartLineItemAdded
  | CreateReturnCartLineItemsRemoved
  | CreateReturnCartFormEdited
  | CreateReturnCartGridEdited
  | CreateReturnSalesOrderSelected
  | CreateReturnSalesOrderRemoved;

export function upsertCreateReturnLineItem(
  createReturnLineItem: SendToCreateReturnCartGrid
): CreateReturnCartLineItemAdded {
  return {
    type: CREATE_RETURN_LINE_ITEM_ADDED,
    payload: {
      createReturn: createReturnLineItem,
    },
  };
}

export function removeAllLineItems(): CreateReturnCartLineItemsRemoved {
  return {
    type: CREATE_RETURN_LINE_ITEMS_REMOVED,
  };
}

export function editCreateReturnCartForm(): CreateReturnCartFormEdited {
  return {
    type: CREATE_RETURN_FORM_EDITED,
  };
}

export function editCreateReturnCartGrid(): CreateReturnCartGridEdited {
  return {
    type: CREATE_RETURN_GRID_EDITED,
  };
}

export function createReturnSalesOrderSelected(salesOrder: SalesOrderQueryOutputDTO): CreateReturnSalesOrderSelected {
  return {
    type: CREATE_RETURN_SALES_ORDER_SELECTED,
    payload: {
      salesOrder,
    },
  };
}

export function createReturnSalesOrderRemoved(): CreateReturnSalesOrderRemoved {
  return {
    type: CREATE_RETURN_SALES_ORDER_REMOVED,
  };
}

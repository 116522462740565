import { Box, Button, Flex, Icon } from '@oplog/express';
import React, { useEffect, useRef, useState } from 'react';
import Dropzone, { DropzoneRef } from 'react-dropzone';
import styled from 'styled-components';

interface UploadParams {
  url: string;
  file: File;
  headers?: any;
  body?: Dictionary<any>;
}
interface UploadResponse {
  responseData?: Response;
  status?: any;
  errorData?: Response;
}

const IconBox = styled(Box)`
  display: flex;
  width: 72px;
  height: 72px;
  box-shadow: inset 0 0 0 4px ${(props: any) => props.theme.colors.palette[props.shadowColor]};
  align-items: center;
  justify-content: center;
`;

const IconWrapper = styled(Icon)`
  display: flex;
  font-size: ${(props: any) => props.theme.fontSizes['28']};
  margin-right: 0;
  justify-content: center;
  align-items: center;
  &:before {
    color: ${(props: any) => props.theme.colors.palette[props.color]};
  }
`;

const ButtonWrapper = styled(Button)`
  margin-top: ${(props: any) => props.theme.space['16']};
  margin-right: 0;
  background-color: ${(props: any) => props.theme.colors.palette[props.color]};
  color: ${(props: any) => props.theme.colors.palette.white};
`;

const Text = styled.p`
  color: ${(props: any) => props.theme.colors.palette.steel_darker};
  text-align: center;
  font-size: ${(props: any) => props.theme.fontSizes['13']};
  line-height: ${(props: any) => props.theme.lineHeights.xxLarge};
`;

const ErrorHeader = styled.p`
  font-family: ${(props: any) => props.theme.fonts.heading};
  font-weight: ${(props: any) => props.theme.fontWeights['800']};
  color: ${(props: any) => props.theme.colors.palette.steel_darker};
  font-size: ${(props: any) => props.theme.fontSizes['13']};
  margin-bottom: ${(props: any) => props.theme.space['16']};
`;

const ErrorList = styled.ul`
  padding-left: ${(props: any) => props.theme.space['22']};
  max-height: 110px;
  overflow: auto;
  li {
    color: ${(props: any) => props.theme.colors.palette.steel_darker};
    font-size: ${(props: any) => props.theme.fontSizes['13']};
    line-height: ${(props: any) => props.theme.lineHeights.xxLarge};
  }
`;

export enum StatusTypes {
  Idle = 'idle',
  Warning = 'warning',
  Error = 'error',
  Success = 'success',
  Loading = 'loading',
}

interface UploadError {
  errorList: any;
  errorType: StatusTypes.Warning | StatusTypes.Error;
}

interface UploadApi {
  url: string;
  headers?: Dictionary<string>;
  body?: Dictionary<any>;
}

interface UploaderProps {
  api: UploadApi;
  acceptedFile: string;
  texts: {
    uploader?: string | JSX.Element;
    success?: string | JSX.Element;
    loading?: string | JSX.Element;
    error?: string | JSX.Element;
    warning?: string | JSX.Element;
    browseButton: string;
    closeButton: string;
    errorsTitle: string;
  };
  errors?: (string | JSX.Element)[];
  errorType?: StatusTypes;
  onClose: () => void;
  onSuccess?: (response: any) => void;
  onError?: (errorList: any) => UploadError | void | null;
}

const ExcelUploader: React.FC<UploaderProps> = ({
  errorType,
  texts,
  api,
  onError,
  onClose,
  onSuccess,
  acceptedFile,
  errors,
}) => {
  const [status, setStatus] = useState(StatusTypes.Idle);
  const [error, setError] = useState(false);
  const dropzone = useRef<DropzoneRef>(null);

  useEffect(() => setStatus(errorType || StatusTypes.Idle), [errorType, error]);

  const upload = ({ url, file, headers, body }: UploadParams) => {
    return new Promise<UploadResponse>(resolve => {
      const data = new FormData();
      data.append('file', file);
      if (body) {
        Object.keys(body).forEach(key => {
          data.append(key, body[key] as any);
        });
      }

      const options = {
        method: 'POST',
        mode: 'cors' as RequestMode,
        body: data,
        headers,
      };

      let firstResponse: any;

      fetch(url, options)
        .then(response => {
          firstResponse = response;
          return response.json();
        })
        .then(response => resolve({ responseData: response, status: firstResponse.status }))
        .catch(err => resolve({ ...err, errorData: err }));
    });
  };

  const uploadFunc = async (files: File[]) => {
    const file = files[0];

    // initial state for loading
    setStatus(StatusTypes.Loading);

    if (!file || file.type !== "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
      console.log("file type error");

      const mockResponse = {
        status: 400,
        responseData: {
          error: {
            code: 400,
            message: "Validation Errors",
            errors: [
              {
                property: "File",
                message: "The uploaded file is not a valid Excel file.",
                errorCode: "InvalidContentType"
              }
            ]
          }
        }
      };

      // Use the onError callback to pass the mockResponse as if it came from the backend
      if (onError) {
        onError(mockResponse);
      }

      setStatus(StatusTypes.Error);
      setError(true);
      return;
    }

    // upload request send
    const response: UploadResponse = await upload({
      url: api.url,
      file: files[0],
      headers: api.headers || {},
      body: api.body || {},
    });

    if (response.status >= 200 && response.status < 300) {
      setStatus(StatusTypes.Success);
      if (onSuccess) onSuccess(response);
    } else if (onError) {
      setError(!error);
      onError(response);
    }
  };

  const iconVariants = {
    loading: { color: 'blue', className: 'fas fa-spinner fa-spin' },
    success: { color: 'green', className: 'far fa-check' },
    warning: { color: 'yellow', className: 'fas fa-exclamation-triangle' },
    error: { color: 'red', className: 'fas fa-times' },
    idle: { color: 'steel', className: 'fas fa-upload' },
  };

  const buttonProps = {
    idle: {
      content: texts.browseButton,
      variant: 'alternative',
      onClick: () => dropzone.current && dropzone.current.open(),
    },
    warning: { content: texts.closeButton, variant: 'dark', onClick: onClose },
  };

  const isLoading = status === StatusTypes.Loading;
  const isSuccess = status === StatusTypes.Success;
  const isWarning = errorType === StatusTypes.Warning;
  const isError = errorType === StatusTypes.Error;

  return (
    <Flex flexDirection="column" alignItems="center" justifyContent="center">
      <Dropzone accept={acceptedFile} onDrop={uploadFunc} disabled={isLoading || isSuccess || isWarning} ref={dropzone}>
        {({ getRootProps, getInputProps }: any) => {
          return (
            <Box
              width={1 / 1}
              p="22"
              border="dashed"
              borderColor="palette.steel"
              borderRadius="8px"
              {...getRootProps()}
            >
              <Flex alignItems="center" justifyContent="center" flexDirection="column">
                <IconBox borderRadius="full" shadowColor={iconVariants[status || 'idle'].color}>
                  <IconWrapper justifyContent="center" {...iconVariants[status || 'idle']} />
                </IconBox>
                <Box mt="16">
                  <input {...getInputProps()} />
                  <Text>
                    {isLoading && texts.loading}
                    {isSuccess && texts.success}
                    {isWarning && texts.warning}
                    {isError && texts.error}
                    {!isLoading && !isSuccess && !isError && !isWarning && texts.uploader}
                  </Text>
                </Box>
                {isWarning && (
                  <Box width={1 / 1} mt="16">
                    {texts.errorsTitle && <ErrorHeader>{texts.errorsTitle}</ErrorHeader>}
                    {errors && (
                      <ErrorList>
                        {errors.map((e: string | JSX.Element, index: number) => (
                          <li key={index.toString()}>{e}</li>
                        ))}
                      </ErrorList>
                    )}
                  </Box>
                )}
              </Flex>
            </Box>
          );
        }}
      </Dropzone>
      <ButtonWrapper size="small" disabled={isLoading} {...buttonProps[isWarning || isSuccess ? 'warning' : 'idle']}>
        {buttonProps[isWarning || isSuccess ? 'warning' : 'idle'].content}
      </ButtonWrapper>
    </Flex>
  );
};

export default ExcelUploader;

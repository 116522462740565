import { GeneralErrorPanel, ReceivingOrderLineItemsGrid, ReceivingOrderLineItemExpirationDatesGrid, TransferInfoModal } from '@components';
import { ReceivingOrderLineItemsSerialNumbersGrid } from '@components/organisms/ReceivingOrderLineItemsSerialNumbersGrid';
import { getIntegrationImageSrc } from '@containers/formatters';
import { MemberPageContainer } from '@containers/MemberPageContainer/MemberPageContainer';
import { PipelineContainer } from '@containers/PipelineContainer';
import { ResourceType } from '@models';
import {
  ActionBar,
  Alert,
  AlertDescription,
  AlertTitle,
  Box,
  Button,
  DATE_FORMAT_NO_TIME,
  Flex,
  formatUtcToLocal,
  Icon,
  isDate,
  LayoutContent,
  Panel,
  PanelTitle,
  Tab,
  Tooltip,
  Widget,
  WidgetEightProps,
} from '@oplog/express';
import { urls } from '@routes/urls';
import {
  CargoTransferDTO,
  CarriageTransferDTO,
  IntegrationType,
  PickupTransferDTO,
  ReceivingOrderDetailOutputDTO,
  ReceivingOrderState,
  ReceivingOrderTransferType,
  UpdateReceivingOrderTransferDetailCommand,
} from '@services';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Props } from '../../atoms/Component/Component';
import { integrationIdentifier } from '@utils';

const COMPONENT_INTL_KEY = 'ReceivingOrderDetails';

export interface ReceivingOrderDetailsProps extends ReceivingOrderDetailOutputDTO, Props {
  postError?: ErrorModel;
  postIsBusy: boolean;
  onUpdateTransferDetails: (receivingOrderTransferDetail: UpdateReceivingOrderTransferDetailCommand) => void;
  onUpdateTransferDetailsSucceed: () => void;
  updateSucceeded: boolean;
  gridData: any;
  initResources: () => void;
  goTab: (tab: string) => void;
}

export interface ReceivingOrderDetailsState {
  isModalOpen: boolean;
  infoBoxEditDisabled: boolean;
  isPostErrorOpen: boolean;
  hasUnexpectedLineItem: boolean;
}

enum ErrorKey {
  ArrivedToWarehouse = 'ArrivedToWarehouse',
  Cargo = 'Cargo',
  Source = 'Source',
}

enum ReceivingOrderTabs {
  LineItems = 'LineItems',
  ExpirationDates = 'ExpirationDates',
  SerialNumbers = 'SerialNumbers'
}

export const ReceivingOrderDetails: React.FC<ReceivingOrderDetailsProps> = ({
  intl,
  onDidMount,
  onWillUnmount,
  postError,
  postIsBusy,
  onUpdateTransferDetails,
  onUpdateTransferDetailsSucceed,
  updateSucceeded,
  gridData,
  initResources,
  referenceNumber,
  transferType,
  transferDetails,
  isBusy,
  integrationType,
  openApiIdentifier,
  integrationName,
  error,
  state,
  goTab,
}) => {
  useEffect(() => {
    onDidMount && onDidMount();

    return () => {
      onWillUnmount && onWillUnmount();
    };
  }, []);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [infoBoxEditDisabled, setInfoBoxEditDisabled] = useState(true);
  const [isPostErrorOpen, setIsPostErrorOpen] = useState(false);
  const [hasUnexpectedLineItem, setHasUnexpectedLineItem] = useState(false);
  const [currentTab, setCurrentTab] = React.useState(0);

  const integrationIconName = integrationIdentifier(integrationType, openApiIdentifier);

  useEffect(() => {
    if (state == ReceivingOrderState.ReceivingOrderCreated) {
      setInfoBoxEditDisabled(false);
    }
  }, [state]);

  useEffect(() => {
    if (postError) {
      setIsPostErrorOpen(true);
      setIsModalOpen(false);
    }
  }, [postError]);

  useEffect(() => {
    if (gridData) {
      for (let lineItem of gridData) {
        if (lineItem.isUnexpected) {
          setHasUnexpectedLineItem(true);
          break;
        }
      }
    }
  }, [gridData]);

  useEffect(() => {
    if (updateSucceeded) {
      onUpdateTransferDetailsSucceed();
    }
  }, [updateSucceeded]);

  const toggleInfoBoxModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handlePostErrorDismiss = () => {
    setIsPostErrorOpen(false);
  };

  const subtitle = intl.messages[`${COMPONENT_INTL_KEY}.Subtitle`];

  const prepareSubtitleComponent = () => {
    const transferTypeEnum = ReceivingOrderTransferType;
    let trackingNoIntl;
    let trackingNo;
    let shipmentDate = '';
    if (transferDetails === undefined) {
      trackingNoIntl = trackingNo = shipmentDate = 'N/A';
    } else {
      switch (transferType) {
        case transferTypeEnum.CargoTransfer: {
          trackingNoIntl = intl.messages[`${COMPONENT_INTL_KEY}.Section.Transfer.TrackingNumber`];
          trackingNo = transferDetails.trackingNumber || 'N/A';
          shipmentDate = isDate(transferDetails.shipmentDate!)
            ? formatUtcToLocal(transferDetails.shipmentDate!, DATE_FORMAT_NO_TIME)
            : 'N/A';
          break;
        }
        case transferTypeEnum.CarriageTransfer: {
          trackingNoIntl = intl.messages[`${COMPONENT_INTL_KEY}.Section.Transfer.WaybillNumber`];
          trackingNo = transferDetails.waybillNumber || 'N/A';
          shipmentDate = isDate(transferDetails.shipmentDate!)
            ? formatUtcToLocal(transferDetails.shipmentDate!)
            : 'N/A';
          break;
        }
        default: {
          trackingNoIntl = '';
          trackingNo = '';
          shipmentDate = '';
        }
      }
    }

    return (
      <Box fontSize="10" color="palette.grey">
        <Box>
          <strong>{trackingNoIntl}</strong> {trackingNo}
        </Box>
        <Box>
          <Icon mr="4px" name="fal fa-calendar-alt" />
          {shipmentDate}
        </Box>
      </Box>
    );
  };

  const getHeaderButton = () => {
    const isDisabled =
      state === ReceivingOrderState.ArrivedToWarehouse ||
      state === ReceivingOrderState.Completed ||
      (integrationType && integrationType !== IntegrationType.Oplog) ||
      transferType === ReceivingOrderTransferType.PickupTransfer;
    const button = (
      <Button
        variant="dark"
        disabled={isBusy || isDisabled}
        icon={{ name: 'fas fa-pencil' }}
        onClick={toggleInfoBoxModal}
      />
    );
    if (isDisabled) {
      let contentKey = '';
      if (
        state === ReceivingOrderState.ReceivingOrderCreated &&
        transferType === ReceivingOrderTransferType.PickupTransfer
      ) {
        contentKey = ErrorKey.Cargo;
      }
      if (state === ReceivingOrderState.Completed || state === ReceivingOrderState.ArrivedToWarehouse) {
        contentKey = ErrorKey.ArrivedToWarehouse;
      }
      if (integrationType && integrationType !== IntegrationType.Oplog) {
        contentKey = ErrorKey.Source;
      }
      return (
        <Tooltip action={['hover']} content={intl.messages[`${COMPONENT_INTL_KEY}.Warning.${contentKey}`]}>
          {button}
        </Tooltip>
      );
    }
    return button;
  };

  const prepareInfoBoxProps = () => {
    const transferTypeEnum = ReceivingOrderTransferType;
    let iconName;
    let title = '';
    let subtitle;

    if (transferDetails === undefined) {
      iconName = title = subtitle = 'N/A';
    } else {
      switch (transferType) {
        case transferTypeEnum.PickupTransfer: {
          iconName = 'fas fa-shipping-fast';
          title = intl.messages[`${COMPONENT_INTL_KEY}.Section.Transfer.PickupTitle`];
          subtitle = (transferDetails as PickupTransferDTO).pickupAddress || 'N/A';
          break;
        }
        case transferTypeEnum.CargoTransfer: {
          iconName = 'fas fa-hand-holding-box';
          title = (transferDetails as CargoTransferDTO).cargoCompanyName || 'N/A';
          subtitle = prepareSubtitleComponent();
          break;
        }
        case transferTypeEnum.CarriageTransfer: {
          iconName = 'fas fa-hand-holding-box';
          title = (transferDetails as CarriageTransferDTO).carriageCompanyName || 'N/A';
          subtitle = prepareSubtitleComponent();
          break;
        }
        default: {
          iconName = 'close';
          title = '';
          subtitle = '';
        }
      }
    }

    const headerButton = getHeaderButton();
    const infoBoxProps: WidgetEightProps = {
      customButton: headerButton,
      icon: { name: iconName, fontSize: '56px', color: 'white' },
      iconBoxProps: { bg: 'palette.purple' },
      title,
      subtitle,
      hasTooltip: true,
    };

    return infoBoxProps;
  };

  const infoBoxProps = prepareInfoBoxProps();

  const breadcrumb = [
    { title: `${intl.messages['ReceivingOrders.Header.Title']}`, url: urls.receivingOrders },
    { title: `#${referenceNumber}` },
  ];

  return (
    <MemberPageContainer documentTitle={`#${referenceNumber} - ${subtitle}`}>
      <Alert variant="danger" isOpen={isPostErrorOpen} onDismiss={handlePostErrorDismiss}>
        <AlertTitle>{intl.messages['PostErrorMessage.Title']}</AlertTitle>
        <AlertDescription>{intl.messages['PostErrorMessage.Description']}</AlertDescription>
      </Alert>
      <ActionBar
        top="66px"
        isBusy={postIsBusy || isBusy}
        title={referenceNumber ? `#${referenceNumber}` : ''}
        subtitle={referenceNumber ? subtitle : intl.messages['ErrorPanel.ErrorMessage']}
        integration={{
          icon: getIntegrationImageSrc(integrationIconName),
          name: integrationName || intl.messages['Integration.NoIntegration'],
        }}
        breadcrumb={breadcrumb}
      />
      <LayoutContent>
        <Flex>
          <Panel width="full" mr="24px">
            <PanelTitle>{intl.messages[`${COMPONENT_INTL_KEY}.Section.Status.Title`]}</PanelTitle>
            <PipelineContainer
              pipelineKey={ResourceType.PipelineStateOfReceivingOrder}
              apiParam={{ referenceNumber }}
              intl={intl}
              enumIntlKey="ReceivingOrderState"
            />
          </Panel>
          <Panel width={1 / 4}>
            <PanelTitle>{intl.messages[`${COMPONENT_INTL_KEY}.Section.Transfer.Title`]}</PanelTitle>
            {error ? <GeneralErrorPanel /> : <Widget.Eight {...infoBoxProps} isLoading={isBusy || postIsBusy} />}
          </Panel>
        </Flex>
        <TransferInfoModal
          intl={intl}
          isModalOpen={isModalOpen}
          transferType={transferType}
          transferDetails={transferDetails || {}}
          onUpdateTransferDetails={onUpdateTransferDetails}
          toggleTransferDetailsModal={toggleInfoBoxModal}
          referenceNumber={referenceNumber}
          lineItems={undefined}
          isBusy={postIsBusy}
          isSuccess={updateSucceeded}
          initResources={initResources}
        />
      </LayoutContent>
      <LayoutContent>
        <Panel
        // title={intl.messages[`${COMPONENT_INTL_KEY}.Grid.Title`]}
        // message={intl.messages[`${COMPONENT_INTL_KEY}.Grid.Title`]}
        >
          {hasUnexpectedLineItem ? <p>{intl.messages[`${COMPONENT_INTL_KEY}.Note`]}</p> : ''}
          <Tab
            onTabChange={data => {
              setCurrentTab(data);
              goTab(Object.values(ReceivingOrderTabs)[data]);
            }}
            activeIndex={currentTab}
            tabs={[
              {
                id: ReceivingOrderTabs.LineItems,
                title: (
                  intl.messages[`${COMPONENT_INTL_KEY}.Grid.Title`]
                ),
                component: <ReceivingOrderLineItemsGrid referenceNumber={referenceNumber} titleKey={referenceNumber} intl={intl} />
              },
              {
                id: ReceivingOrderTabs.ExpirationDates,
                title: (
                  intl.messages[`${COMPONENT_INTL_KEY}.Grid.ExpirationDateGridTitle`]
                ),
                component: <ReceivingOrderLineItemExpirationDatesGrid referenceNumber={referenceNumber} titleKey={referenceNumber} intl={intl} />
              },
              {
                id: ReceivingOrderTabs.SerialNumbers,
                title: (
                  intl.messages[`${COMPONENT_INTL_KEY}.Grid.SerialNumbersGridTitle`]
                ),
                component: <ReceivingOrderLineItemsSerialNumbersGrid referenceNumber={referenceNumber} titleKey={referenceNumber} intl={intl} />
              }
            ]} />
        </Panel>
      </LayoutContent>
    </MemberPageContainer>
  );
};

import { GridContainer } from '@components/atoms/GridContainer';
import { barcodeFormatter, geti18nName, ProductDetailsLinkFormatter, skuReceivingOrderLineItemFormatter } from '@containers/formatters';
import { GridProps, GridType } from '@models';
import {
  ColumnSize,
  DataGridColumn,
  DataGridRow,
  dateTimeFormatter,
  FormatterProps,
  ImageFormatter,
} from '@oplog/data-grid';
import { LineItemOfReceivingOrderQueryOutputDTO } from '@services';
import { coloredBadgeFormatter, receivingOrderDifferenceBadge } from '@utils';
import { ArrayFilterOperation, SortDirection, SortField } from 'dynamic-query-builder-client';
import * as React from 'react';

const intlKey = 'ReceivingOrderLineItemsGrid';
const gridKey = 'ReceivingOrderDetails.Grid';

export interface ReceivingOrderLineItemsGridProps extends GridProps {
  referenceNumber: string;
}

const receivingOrderLineItemsGridInitalSort: SortField = new SortField({
  property: 'isUnexpected',
  by: SortDirection.ASC,
});

export class ReceivingOrderLineItemsGrid extends React.Component<ReceivingOrderLineItemsGridProps> {
  render() {
    const { intl, titleKey } = this.props;
    const receivingOrderLineItemsGridColumns: Array<DataGridColumn> = [
      {
        name: '#',
        key: 'index',
        resizable: true,
        locked: true,
        sortable: false,
        type: 'number',
        visibility: true,
        filterable: false,
        width: ColumnSize.Medium,
        cellClass: 'index-column',
      },
      {
        name: geti18nName('sku', intl, intlKey),
        key: 'sku',
        locked: true,
        sortable: true,
        type: 'string',
        visibility: true,
        filterable: true,
        resizable: true,
        formatter: row => {
          return skuReceivingOrderLineItemFormatter(row);
        },
        getRowMetaData: (row: DataGridRow) => row,
      },
      {
        name: geti18nName('barcode', intl, intlKey),
        key: 'barcodes',
        locked: true,
        type: 'string',
        visibility: true,
        resizable: true,
        sortable: false,
        filterable: true,
        formatter: barcodeFormatter,
        fieldType: 'array',
        searchField: '_',
        outerOp: ArrayFilterOperation.Any,
      },
      {
        name: geti18nName('imageUrl', intl, intlKey),
        key: 'imageUrl',
        locked: true,
        sortable: false,
        type: 'string',
        visibility: true,
        filterable: false,
        formatter: ImageFormatter,
        width: ColumnSize.Medium,
      },
      {
        name: geti18nName('productName', intl, intlKey),
        key: 'productName',
        locked: true,
        sortable: true,
        type: 'string',
        visibility: true,
        filterable: true,
        formatter: ProductDetailsLinkFormatter,
        getRowMetaData: (row: DataGridRow) => row,
        resizable: true,
      },
      {
        name: geti18nName('expectedQuantity', intl, intlKey),
        key: 'expectedQuantity',
        resizable: true,
        sortable: true,
        type: 'number',
        visibility: true,
        filterable: true,
      },
      {
        name: geti18nName('acceptedQuantity', intl, intlKey),
        key: 'acceptedQuantity',
        resizable: true,
        sortable: true,
        type: 'number',
        visibility: true,
        filterable: true,
      },
      {
        name: geti18nName('acceptedQuantityDifference', intl, intlKey),
        key: 'acceptedQuantityDifference',
        locked: true,
        resizable: true,
        sortable: true,
        type: 'string',
        visibility: true,
        filterable: true,
        formatter: (props: FormatterProps) => coloredBadgeFormatter(props),
        width: ColumnSize.XLarge,
        getRowMetaData: (row: LineItemOfReceivingOrderQueryOutputDTO) => {
          const { text, badgeColor } = receivingOrderDifferenceBadge(intl, gridKey, row);
          return {
            row,
            intl,
            intlKey,
            text,
            color: badgeColor,
          };
        },
      },
      {
        name: geti18nName('processedAt', intl, intlKey),
        key: 'processedAt',
        locked: true,
        sortable: true,
        type: 'moment',
        visibility: true,
        filterable: true,
        resizable: true,
        formatter: dateTimeFormatter,
      },
    ];

    return (
      <GridContainer
        titleKey={
          titleKey
            ? `${intl.messages['ReceivingOrderDetails.Grid.Title']} - ${titleKey}`
            : intl.messages['ReceivingOrderDetails.Grid.Title']
        }
        intlKey={intlKey}
        remoteExportToExcel
        gridKey={GridType.ReceivingOrderLineItems}
        columns={receivingOrderLineItemsGridColumns}
        predefinedFilters={[]}
        filtersDisabled={false}
        apiArgs={[this.props.referenceNumber]}
        intl={this.props.intl}
        sortField={receivingOrderLineItemsGridInitalSort}
      />
    );
  }
}

import { GridType, ResourceType } from '@models';
import { gridActions } from '@oplog/data-grid';
import { validation } from '@oplog/express';
import { fileUploadInit, fileUploadRequested, FileUploadsAction, getUrl, isBusy } from '@oplog/file-uploader';
import { resourceActions, resourceSelectors } from '@oplog/resource-redux';
import { ProductCatalog, ProductCatalogProps } from '@pages/ProductCatalog';
import { urls } from '@routes/urls';
import { CreateProductCommand } from '@services';
import { StoreState } from '@store';
import { onTabChange } from '@utils';
import { push, RouterAction } from 'connected-react-router';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Dispatch } from 'redux';

function mapStateToProps({ resources, files }: StoreState): Partial<ProductCatalogProps> {
  return {
    isBusy: resourceSelectors.isBusy(resources, ResourceType.CreateProduct),
    error: resourceSelectors.getError(resources, ResourceType.CreateProduct),
    fileUrl: getUrl(files, '0'),
    isUploading: isBusy(files, '0'),
    isCreateSuccessfull: resourceSelectors.isSuccessComplete(resources, ResourceType.CreateProduct),
  };
}

function mapDispatchToProps(
  dispatch: Dispatch<FileUploadsAction | resourceActions.ResourceAction | gridActions.GridAction | RouterAction>,
  routeProps: RouteComponentProps<any>
): Partial<ProductCatalogProps> {
  return {
    onReset: () => dispatch(fileUploadInit()),
    onCreate: (command: CreateProductCommand) => {
      dispatch(resourceActions.resourceRequested(ResourceType.CreateProduct, { command }));
    },
    onRefresh: () => {
      dispatch(resourceActions.resourceInit(ResourceType.CreateProduct));
    },
    onModalClose: (sku: string) => {
      dispatch(push(urls.productDetails.replace(':sku', `${encodeURIComponent(sku)}`).replace('/:tab', '')));
    },
    onFileSelect: (file: File) => {
      dispatch(fileUploadRequested('0', file));
    },
    onWillUnmount: () => {
      dispatch(resourceActions.resourceInit(ResourceType.CreateProduct));
    },
    onExcelUploadClose: (): void => {
      dispatch(gridActions.gridFetchRequested(GridType.Products));
    },
    goTab: tab => {
      onTabChange(tab, routeProps, dispatch);
    },
  };
}

export const ProductCatalogContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(validation(injectIntl(ProductCatalog)));

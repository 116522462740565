import LoginCallback from '@components/pages/LoginCallback/LoginCallback';
import { CreateCustomerContainer } from '@containers/CreateCustomerContainer';
import { CreateReturnContainer } from '@containers/CreateReturnContainer';
import { CreateSalesOrderContainer } from '@containers/CreateSalesOrderContainer';
import { CustomerDetailsContainer } from '@containers/CustomerDetailsContainer';
import { CustomersContainer } from '@containers/CustomersContainer';
import { IntegrationsContainer } from '@containers/IntegrationsContainer';
import { InventoryContainer } from '@containers/InventoryContainer';
import { LoginContainer } from '@containers/LoginContainer';
import { NotFoundContainer } from '@containers/NotFoundContainer';
import { ProductCatalogContainer } from '@containers/ProductCatalogContainer';
import { ProductDetailsContainer } from '@containers/ProductDetailsContainer';
import { ReceivingOrderDetailsContainer } from '@containers/ReceivingOrderDetailsContainer';
import { ReceivingOrdersContainer } from '@containers/ReceivingOrdersContainer';
import { ReturnDetailsContainer } from '@containers/ReturnDetailsContainer';
import { ReturnsContainer } from '@containers/ReturnsContainer';
import { SalesOrderDetailsContainer } from '@containers/SalesOrderDetailsContainer';
import { SalesOrdersContainer } from '@containers/SalesOrdersContainer';
import { SendToWarehouseContainer } from '@containers/SendToWarehouseContainer';
import { SettingsContainer } from '@containers/SettingsContainer';
import { SupportPackagesContainer } from '@containers/SupportPackagesContainer';
import { SupportPageContainer } from '@containers/SupportPageContainer';
import { SupportReceivingOrdersContainer } from '@containers/SupportReceivingOrdersContainer';
import { SupportSalesOrdersContainer } from '@containers/SupportSalesOrdersContainer';
import { InvalidSalesOrderDetailsContainer } from '@containers/InvalidSalesOrderDetailsContainer';
import { ModuleScopes } from '../auth/scopes';
import { AppRouteConfig } from './model';
import { urls } from './urls';
import { InvalidSalesOrderDetails } from '@components/pages/InvalidSalesOrderDetails';

export const definitions: Dictionary<AppRouteConfig> = {
  home: new AppRouteConfig({
    url: urls.home,
    component: SalesOrdersContainer,
    scopes: ModuleScopes.Home,
  }),
  authentication: new AppRouteConfig({
    url: urls.authentication,
    component: LoginContainer,
    protectedRoute: false,
  }),
  callback: new AppRouteConfig({
    url: urls.callback,
    component: LoginCallback,
    protectedRoute: false,
  }),

  integrations: new AppRouteConfig({
    url: urls.integrations,
    component: IntegrationsContainer,
    scopes: ModuleScopes.Integrations,
  }),
  salesOrders: new AppRouteConfig({
    url: urls.salesOrders,
    component: SalesOrdersContainer,
    scopes: ModuleScopes.SalesOrders,
  }),
  salesOrderDetails: new AppRouteConfig({
    url: urls.salesOrderDetails,
    component: SalesOrderDetailsContainer,
    scopes: ModuleScopes.SalesOrderDetails,
  }),
  invalidSalesOrderDetails: new AppRouteConfig({
    url: urls.invalidSalesOrderDetails,
    component: InvalidSalesOrderDetailsContainer,
    scopes: ModuleScopes.SalesOrderDetails,
  }),
  createSalesOrder: new AppRouteConfig({
    url: urls.createSalesOrder,
    component: CreateSalesOrderContainer,
    scopes: ModuleScopes.CreateSalesOrder,
  }),
  products: new AppRouteConfig({
    url: urls.products,
    component: ProductCatalogContainer,
    scopes: ModuleScopes.Products,
  }),
  productDetails: new AppRouteConfig({
    url: urls.productDetails,
    component: ProductDetailsContainer,
    scopes: ModuleScopes.ProductDetails,
  }),
  inventory: new AppRouteConfig({
    url: urls.inventory,
    component: InventoryContainer,
    scopes: ModuleScopes.Inventory,
  }),
  receivingOrders: new AppRouteConfig({
    url: urls.receivingOrders,
    component: ReceivingOrdersContainer,
    scopes: ModuleScopes.ReceivingOrders,
  }),
  receivingOrderDetails: new AppRouteConfig({
    url: urls.receivingOrderDetails,
    component: ReceivingOrderDetailsContainer,
    scopes: ModuleScopes.ReceivingOrderDetails,
  }),
  warehouse: new AppRouteConfig({
    url: urls.warehouse,
    component: SendToWarehouseContainer,
    scopes: ModuleScopes.Warehouse,
  }),
  customers: new AppRouteConfig({
    url: urls.customers,
    component: CustomersContainer,
    scopes: ModuleScopes.Customers,
  }),
  customerDetails: new AppRouteConfig({
    url: urls.customerDetails,
    component: CustomerDetailsContainer,
    scopes: ModuleScopes.CustomerDetails,
  }),
  createCustomer: new AppRouteConfig({
    url: urls.createCustomer,
    component: CreateCustomerContainer,
    scopes: ModuleScopes.CreateCustomer,
  }),
  returns: new AppRouteConfig({
    url: urls.returns,
    component: ReturnsContainer,
    scopes: ModuleScopes.Returns,
  }),
  returnDetails: new AppRouteConfig({
    url: urls.returnDetails,
    component: ReturnDetailsContainer,
  }),
  createReturn: new AppRouteConfig({
    url: urls.createReturn,
    component: CreateReturnContainer,
    scopes: ModuleScopes.CreateReturn,
  }),
  settings: new AppRouteConfig({
    url: urls.settings,
    component: SettingsContainer,
    scopes: ModuleScopes.Settings,
  }),
  support: new AppRouteConfig({
    url: urls.support,
    component: SupportPageContainer,
    scopes: ModuleScopes.SupportPage,
  }),

  supportSalesOrders: new AppRouteConfig({
    url: urls.supportSalesOrders,
    component: SupportSalesOrdersContainer,
    scopes: ModuleScopes.Support,
  }),
  supportReceivingOrders: new AppRouteConfig({
    url: urls.supportReceivingOrders,
    component: SupportReceivingOrdersContainer,
    scopes: ModuleScopes.Support,
  }),
  supportPackages: new AppRouteConfig({
    url: urls.supportPackages,
    component: SupportPackagesContainer,
    scopes: ModuleScopes.Support,
  }),

  notFound: new AppRouteConfig({
    key: 'notFound',
    url: urls.notFound,
    component: NotFoundContainer,
    protectedRoute: false,
  }),
};

Object.keys(definitions).map(k => {
  if (definitions[k].component === undefined) {
    console.error('Route component definition is undefined');
    console.error('Change containers/index export order for the following component;');
    console.error(`url: ${definitions[k].url}`);
  }
});

import { FormatterProps } from '@oplog/data-grid';
import { Text } from '@oplog/express';
import * as React from 'react';
import { InjectedIntl } from 'react-intl';

export function i18nTextFormatter(props: FormatterProps, intl: InjectedIntl, intlKey: string) {
  if (props) {
    return <Text color="red">{intl.messages[`${intlKey}.ErrorList.${props.value}`]}</Text>;
  }
  return <></>;
}

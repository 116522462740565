import { GridContainer } from '@components/atoms/GridContainer';
import { geti18nName } from '@containers/formatters';
import { GridProps, GridType } from '@models';
import { ColumnSize, DataGridColumn, dateTimeFormatter, ellipsisFormatter } from '@oplog/data-grid';
import { checkShippingAddress } from '@utils';
import { SortDirection, SortField } from 'dynamic-query-builder-client';
import * as React from 'react';

const intlKey = 'CustomerShippingAddresses';

export interface CustomerShippingAddressesProps extends GridProps {
  customerNo: string;
}

const customerShippingAddressesGridInitalSort: SortField = new SortField({
  property: 'createdAt',
  by: SortDirection.ASC,
});

export class CustomerShippingAddressesGrid extends React.Component<CustomerShippingAddressesProps> {
  render() {
    const { intl, titleKey } = this.props;
    const customerShippingAddressesGridColumns: Array<DataGridColumn> = [
      {
        name: '#',
        key: 'index',
        resizable: true,
        locked: true,
        sortable: false,
        type: 'number',
        visibility: true,
        filterable: false,
        width: ColumnSize.Medium,
        cellClass: 'index-column',
      },
      {
        name: geti18nName('shippingAddressFullName', intl, intlKey),
        key: 'addressFullName',
        locked: false,
        sortable: true,
        type: 'string',
        visibility: true,
        filterable: true,
        width: ColumnSize.XLarge,
      },
      {
        name: geti18nName('shippingAddresses', intl, intlKey),
        key: 'address',
        locked: false,
        sortable: true,
        type: 'string',
        visibility: true,
        filterable: true,
        formatter: row => {
          const modRow = { ...row };
          modRow.value = checkShippingAddress(row.value);
          return ellipsisFormatter(modRow);
        },
      },
      {
        name: geti18nName('phone', intl, intlKey),
        key: 'phone',
        locked: true,
        sortable: true,
        type: 'string',
        visibility: true,
        filterable: true,
        width: ColumnSize.XLarge,
      },
      {
        name: geti18nName('company', intl, intlKey),
        key: 'company',
        locked: true,
        sortable: true,
        type: 'string',
        visibility: true,
        filterable: true,
      },
      {
        name: geti18nName('createdAt', intl, intlKey),
        key: 'createdAt',
        locked: true,
        resizable: true,
        sortable: true,
        type: 'moment',
        visibility: true,
        filterable: true,
        formatter: dateTimeFormatter,
        width: ColumnSize.XLarge,
      },
    ];

    return (
      <GridContainer
        intl={intl}
        titleKey={
          titleKey
            ? `${intl.messages['CustomerShippingAddresses.Grid.Title']} - ${titleKey}`
            : intl.messages['CustomerShippingAddresses.Grid.Title']
        }
        intlKey={intlKey}
        remoteExportToExcel
        gridKey={GridType.CustomerShippingAddresses}
        columns={customerShippingAddressesGridColumns}
        predefinedFilters={[]}
        apiArgs={[this.props.customerNo]}
        sortField={customerShippingAddressesGridInitalSort}
      />
    );
  }
}

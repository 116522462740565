import { CreateSalesOrderCartGridContainer } from '@containers/CreateSalesOrderCartGridContainer';
import { geti18nName } from '@containers/formatters';
import { SendToCreateSalesOrderCartGrid } from '@models';
import { ColumnSize, DataGridColumn, DataGridRow, ellipsisFormatter } from '@oplog/data-grid';
import { Flex, Icon, Text } from '@oplog/express';
import useCreateSalesOrderState from '@store/createSalesOrder/CreateSalesOrderState';
import * as React from 'react';
import { Props } from '../../atoms/Component/Component';
import { CreateSalesOrderCartGridDeleteButton } from './bones/CreateSalesOrderCartGridDeleteButton';

export interface CreateSalesOrderCartGridProps extends Props {
  intlKey: string;
}

export const CreateSalesOrderCartGrid: React.FC<CreateSalesOrderCartGridProps> = ({
  intlKey,
  intl,
}: CreateSalesOrderCartGridProps) => {
  const [state, actions] = useCreateSalesOrderState();

  const createSalesOrderCartGridColumns: Array<DataGridColumn> = [
    {
      name: '#',
      key: 'index',
      resizable: true,
      locked: true,
      sortable: false,
      type: 'number',
      visibility: true,
      filterable: false,
      width: ColumnSize.Medium,
      cellClass: 'index-column',
    },
    {
      name: geti18nName('sku', intl, intlKey),
      key: 'sku',
      resizable: false,
      locked: true,
      sortable: false,
      type: 'string',
      visibility: true,
    },
    {
      name: geti18nName('productName', intl, intlKey),
      key: 'productName',
      resizable: false,
      locked: true,
      sortable: false,
      type: 'string',
      visibility: true,
      formatter: ellipsisFormatter,
    },
    {
      name: geti18nName('amountInOrder', intl, intlKey),
      key: 'amountInOrder',
      resizable: true,
      sortable: false,
      type: 'number',
      visibility: true,
    },
    {
      name: '',
      key: '',
      resizable: false,
      locked: true,
      sortable: false,
      type: 'string',
      visibility: true,

      formatter: row => {
        return <CreateSalesOrderCartGridDeleteButton sku={row.dependentValues['sku']} />;
      },
      getRowMetaData: (row: DataGridRow) => row,
    },
  ];

  const emptyRowsViewComponent = (): JSX.Element => {
    return (
      <Flex flexDirection="column" mt="44px" px="57px" textAlign="center">
        <Icon fontSize="111px" color="palette.snow" name="fas fa-cart-plus" />
        <Text mt="24px" color="palette.snow">
          {intl.messages[`${intlKey}.NoRowsMessage`]}
        </Text>
      </Flex>
    );
  };

  const rowRenderer = ({ ...props }: SendToCreateSalesOrderCartGrid) => {
    return typeof props.availableAmount !== 'number' || props.availableAmount < props.amountInOrder
      ? 'productStockWarning'
      : '';
  };

  function getCreateSalesOrderDataRows() {
    if (state.panelData) {
      const values = Array.from(state.panelData.values());
      return values.map((value, index) => {
        return {
          ...value,
          index: index + 1,
        };
      });
    }
    return undefined;
  }

  return (
    <CreateSalesOrderCartGridContainer
      intlKey={intlKey}
      columns={createSalesOrderCartGridColumns}
      noRowsView={emptyRowsViewComponent}
      rowRenderer={rowRenderer}
      rows={getCreateSalesOrderDataRows()}
    />
  );
};

import { useAuth0 } from '@auth/auth0';
import { MemberPageContainer } from '@containers/MemberPageContainer/MemberPageContainer';
import { Box, Heading, Icon, Link, Text } from '@oplog/express';
import { urls } from '@routes/urls';
import React from 'react';
import { Props } from '../../atoms/Component/Component';

export const NotFoundContent: React.FC<Props> = ({ intl }) => {
  const baseUrl: string = urls.home;

  return (
    <Box height="calc(100vh - 66px)" display="flex" justifyContent="center" alignItems="center" flexDirection="column">
      <Heading color="palette.steel_darker" fontFamily="heading" fontWeight={800} fontSize="22">
        {intl.messages['NotFound.Header']}
      </Heading>
      <Text my="72px" fontFamily="heading" fontWeight={800} as="p" color="palette.teal" fontSize="200px">
        4<Icon fontSize="162px" name="fas fa-eye-slash" />4
      </Text>
      <Text as="p" fontWeight={700}>
        {intl.messages['NotFound.Description']}
      </Text>
      <Link fontWeight={700} href={baseUrl}>
        {intl.messages['NotFound.Navigate']}
      </Link>
    </Box>
  );
};

export const NotFound: React.FC<Props> = ({ intl }) => {
  const { isAuthenticated } = useAuth0();
  if (isAuthenticated) {
    return (
      <MemberPageContainer documentTitle={intl.messages['NotFound.Header']}>
        <NotFoundContent intl={intl} />
      </MemberPageContainer>
    );
  }

  return <NotFoundContent intl={intl} />;
};

import {
  Box,
  Button,
  Dialog,
  DialogTypes,
  Flex,
  Form,
  FormControl,
  FormErrorMessage,
  FormLabel,
  FormValidator,
  Input,
  Intent,
  IntentType,
  PanelTitle,
  SelectHTML,
  Text,
} from '@oplog/express';
import { Resource } from '@oplog/resource-redux';
import { CreateCustomerCommand, CreatedCustomerOutputDTO, ErrorCode } from '@services';
import {
  addressErrorMessages,
  DropdownOptionsFromResource,
  generateAddressFormSchema,
  generateUserFormSchema,
  userErrorMessageInterface,
} from '@utils';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import useForceUpdate from 'use-force-update';
import { Props } from '../../atoms/Component/Component';
const COMPONENT_INTL_KEY = 'CreateCustomerForm';

export interface CreateCustomerFormProps extends Props {
  validator: FormValidator;
  isCreateCustomerSuccess: boolean;
  createdCustomerData: CreatedCustomerOutputDTO;
  isBusy?: boolean;
  countryResource: Resource<string[]>;
  provinceResource: Resource<string[]>;
  districtResource: Resource<string[]>;
  onModalClose: () => void;
  onSubmit: (form: Partial<CreateCustomerCommand>) => void;
  onCreateCustomerSuccessModalClose: (customerNo: number) => void;
  provinceSelect: (command: { province: string }) => void;
  updateValidation: () => void;
}

export interface CreateCustomerFormState {
  fields: Partial<CreateCustomerCommand>;
}

export const CreateCustomerForm: React.FC<CreateCustomerFormProps> = ({
  intl,
  onWillUnmount,
  onDidMount,
  validator,
  isCreateCustomerSuccess,
  createdCustomerData,
  districtResource,
  onCreateCustomerSuccessModalClose,
  onSubmit,
  countryResource,
  provinceResource,
  provinceSelect,
  error,
  isBusy,
}) => {
  const forceUpdate = useForceUpdate();

  useEffect(() => {
    onDidMount && onDidMount();
    validator.registerSchema(createCustomerFormSchema());
    validator.clearErrors();
    return () => {
      onWillUnmount && onWillUnmount();
    };
  }, []);

  const [fields, setFields] = useState<CreateCustomerCommand>({
    firstName: '',
    lastName: '',
    email: '',
    phone: '', // default Turkey
    addressFirstName: '',
    addressLastName: '',
    company: '',
    addressOne: '',
    addressTwo: '',
    district: '',
    city: '',
    country: intl.messages[`${COMPONENT_INTL_KEY}.Turkey`],
    addressPhone: '', // default Turkey
    postalCode: '',
  });

  const createCustomerFormSchema = () => {
    const userErrorMessages: userErrorMessageInterface = {
      firstName: {
        required: `${intl.messages[`${COMPONENT_INTL_KEY}.Error.Customer.Name.Empty`]}`,
        invalid: `${intl.messages[`${COMPONENT_INTL_KEY}.Error.Customer.Name.Invalid`]}`,
      },
      lastName: {
        required: `${intl.messages[`${COMPONENT_INTL_KEY}.Error.Customer.LastName.Empty`]}`,
        invalid: `${intl.messages[`${COMPONENT_INTL_KEY}.Error.Customer.LastName.Invalid`]}`,
      },
      email: {
        required: `${intl.messages[`${COMPONENT_INTL_KEY}.Error.Customer.Email.Empty`]}`,
        invalid: `${intl.messages[`${COMPONENT_INTL_KEY}.Error.Customer.Email.Invalid`]}`,
      },
      phone: {
        length: `${intl.messages[`${COMPONENT_INTL_KEY}.Error.Customer.PhoneNumber.PhoneLength`]}`,
        type: `${intl.messages[`${COMPONENT_INTL_KEY}.Error.Customer.PhoneNumber.PhoneType`]}`,
        required: `${intl.messages[`${COMPONENT_INTL_KEY}.Error.Customer.PhoneNumber.Empty`]}`,
      },
    };

    const addressErrorMessages: addressErrorMessages = {
      addressFirstName: {
        required: `${intl.messages[`${COMPONENT_INTL_KEY}.Error.Receiver.Name.Empty`]}`,
        invalid: `${intl.messages[`${COMPONENT_INTL_KEY}.Error.Receiver.Name.Invalid`]}`,
      },
      addressLastName: {
        required: `${intl.messages[`${COMPONENT_INTL_KEY}.Error.Receiver.LastName.Empty`]}`,
        invalid: `${intl.messages[`${COMPONENT_INTL_KEY}.Error.Receiver.LastName.Invalid`]}`,
      },

      addressOne: {
        required: `${intl.messages[`${COMPONENT_INTL_KEY}.Error.Receiver.AddressOne`]}`,
      },

      district: {
        required: `${intl.messages[`${COMPONENT_INTL_KEY}.Error.Receiver.District`]}`,
      },

      city: {
        required: `${intl.messages[`${COMPONENT_INTL_KEY}.Error.Receiver.City`]}`,
      },

      country: {
        required: `${intl.messages[`${COMPONENT_INTL_KEY}.Error.Receiver.Country`]}`,
      },

      addressPhone: {
        length: `${intl.messages[`${COMPONENT_INTL_KEY}.Error.Receiver.PhoneNumber.PhoneLength`]}`,
        type: `${intl.messages[`${COMPONENT_INTL_KEY}.Error.Receiver.PhoneNumber.PhoneType`]}`,
        required: `${intl.messages[`${COMPONENT_INTL_KEY}.Error.Receiver.PhoneNumber.Empty`]}`,
      },
    };

    const userSchema = generateUserFormSchema(userErrorMessages);
    const addressSchema = generateAddressFormSchema(addressErrorMessages);

    const combinedSchema = { ...userSchema, ...addressSchema };

    return combinedSchema;
  };

  const handleOnBlur = (key: keyof CreateCustomerCommand) => () => {
    validator.validate(key, fields[key]);
    forceUpdate();
  };

  const handleOnInputChange = (key: string) => (e: any) => {
 
    let { district } = fields;
    // reseting selected district when city changes
    if (key === 'city' && fields.city !== e.currentTarget.value) {
      provinceSelect({ province: e.currentTarget.value });
      district = '';
    }

    if (key === 'country' && fields.country !== e.currentTarget.value) {    
      setFields({
        ...fields,
        city : '',
        district : '',
        [key]: e.currentTarget.value,
      });
    }else{ 
      setFields({
        ...fields,
        district,
        [key]: e.currentTarget.value,
      });
    }
 
    validator.validate(key, e.currentTarget.value);
    forceUpdate();
  };

  const getErrorMessage = (key: string): Intent | undefined => {
    if (error && error.errors && error.errors.length > 0) {
      for (const e of error.errors) {
        if (e.property.toLowerCase() === key && e.errorCode === ErrorCode.ResourceAlreadyExists.toString()) {
          return {
            type: IntentType.Error,
            text: intl.messages[`${COMPONENT_INTL_KEY}.Error.CustomerExists`],
          };
        }
      }
    }
    return undefined;
  };

  const isFormInvalid = (): boolean => {
    const requiredFields = [
      'firstName',
      'lastName',
      'email',
      'phone',
      'addressFirstName',
      'addressLastName',
      'addressOne', 
      'district',
      'city',
      'country',
      'addressPhone',
    ];
    const hasEmptyFields = requiredFields.some((key: string) => !fields[key]);
    return hasEmptyFields || validator.hasErrors();
  };

  const renderCreateCustomerSuccessModal = () => {
    if (createdCustomerData) {
      const customerName = `${createdCustomerData.firstName} ${createdCustomerData.lastName}`;
      return (
        <Dialog
          type={DialogTypes.Success}
          message={
            <FormattedMessage
              id={`${COMPONENT_INTL_KEY}.Modal.Success`}
              values={{
                CustomerName: <b>{customerName}</b>,
              }}
            />
          }
          isOpen={isCreateCustomerSuccess}
          onCancel={() => onCreateCustomerSuccessModalClose(createdCustomerData.customerNo)}
          onApprove={() => onCreateCustomerSuccessModalClose(createdCustomerData.customerNo)}
          text={{ approve: intl.messages[`Modal.Success.Okay`] }}
        />
      );
    }
    return undefined;
  };

  const provinceOptions = (): { value: string; label: string; key: string }[] | [] => {
    return DropdownOptionsFromResource(provinceResource);
  };

  const districtOptions = (): { value: string; label: string; key: string }[] | [] => {
    return DropdownOptionsFromResource(districtResource);
  };

  return (
    <Flex>
      <Box pl="162px" width="full">
        <Flex pb="22">
          <Box alignSelf="flex-start" top="185px" position="sticky" maxWidth="174px" mr="43px" mt="8px">
            <PanelTitle>{intl.messages[`${COMPONENT_INTL_KEY}.CustomerInfo`]}</PanelTitle>
            <Text mt="16" fontSize="13" color="palette.grey">
              {intl.messages[`${COMPONENT_INTL_KEY}.CustomerInfoDetail`]}
            </Text>
          </Box>
          <Box bg="palette.white" width="full" p="22" borderRadius="sm" boxShadow="medium">
            <Form loading={isBusy}>
              <Flex>
                <FormControl
                  isInvalid={!!validator.getErrorIntent('firstName')}
                  isDisabled={isBusy}
                  width={1 / 2}
                  pr="16"
                  mb="11"
                >
                  <FormLabel>{intl.messages[`${COMPONENT_INTL_KEY}.CustomerName`]}*</FormLabel>
                  <Input
                    placeholder={intl.messages[`${COMPONENT_INTL_KEY}.PlaceHolder.CustomerName`]}
                    onBlur={handleOnBlur('firstName')}
                    onChange={handleOnInputChange('firstName')}
                    value={fields.firstName}
                  />
                  <FormErrorMessage>{validator.getErrorIntent('firstName')?.text}</FormErrorMessage>
                </FormControl>
                <FormControl
                  isInvalid={!!validator.getErrorIntent('lastName')}
                  isDisabled={isBusy}
                  width={1 / 2}
                  mb="11"
                >
                  <FormLabel>{intl.messages[`${COMPONENT_INTL_KEY}.CustomerLastname`]}*</FormLabel>
                  <Input
                    placeholder={intl.messages[`${COMPONENT_INTL_KEY}.PlaceHolder.CustomerLastname`]}
                    onBlur={handleOnBlur('lastName')}
                    onChange={handleOnInputChange('lastName')}
                    value={fields.lastName}
                  />
                  <FormErrorMessage>{validator.getErrorIntent('lastName')?.text}</FormErrorMessage>
                </FormControl>
              </Flex>
              <FormControl
                isInvalid={!!getErrorMessage('email') || !!validator.getErrorIntent('email')}
                isDisabled={isBusy}
                mb="11"
              >
                <FormLabel>{intl.messages[`${COMPONENT_INTL_KEY}.CustomerEmail`]}*</FormLabel>
                <Input
                  placeholder={intl.messages[`${COMPONENT_INTL_KEY}.PlaceHolder.CustomerEmail`]}
                  onBlur={handleOnBlur('email')}
                  onChange={handleOnInputChange('email')}
                  value={fields.email}
                />
                <FormErrorMessage>
                  {getErrorMessage('email')?.text || validator.getErrorIntent('email')?.text}
                </FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={!!validator.getErrorIntent('phone')} isDisabled={isBusy}>
                <FormLabel>{intl.messages[`${COMPONENT_INTL_KEY}.CustomerPhoneNumber`]}*</FormLabel>
                <Input
                  placeholder={intl.messages[`${COMPONENT_INTL_KEY}.PlaceHolder.CustomerPhoneNumber`]}
                  onChange={handleOnInputChange('phone')}
                  onBlur={handleOnBlur('phone')}
                  value={fields.phone}
                />
                <FormErrorMessage>{validator.getErrorIntent('phone')?.text}</FormErrorMessage>
              </FormControl>
            </Form>
          </Box>
        </Flex>
        <Flex pt="22">
          <Box alignSelf="flex-start" top="185px" position="sticky" maxWidth="174px" mr="43px" mt="8px">
            <PanelTitle>{intl.messages[`${COMPONENT_INTL_KEY}.ShipmentInfo`]}</PanelTitle>
            <Text mt="16" fontFamily="base" fontSize="13" color="palette.grey">
              {intl.messages[`${COMPONENT_INTL_KEY}.ShipmentInfoDetail`]}
            </Text>
          </Box>
          <Box bg="palette.white" width="full" p="22" borderRadius="sm" boxShadow="medium">
            <Form loading={isBusy}>
              <Flex>
                <FormControl
                  isInvalid={!!validator.getErrorIntent('addressFirstName')}
                  isDisabled={isBusy}
                  pr="16"
                  mb="11"
                  width="full"
                >
                  <FormLabel>{intl.messages[`${COMPONENT_INTL_KEY}.ReceiverName`]}*</FormLabel>
                  <Input
                    placeholder={intl.messages[`${COMPONENT_INTL_KEY}.PlaceHolder.ReceiverName`]}
                    onBlur={handleOnBlur('addressFirstName')}
                    onChange={handleOnInputChange('addressFirstName')}
                    value={fields.addressFirstName}
                  />
                  <FormErrorMessage>{validator.getErrorIntent('addressFirstName')?.text}</FormErrorMessage>
                </FormControl>
                <FormControl
                  isInvalid={!!validator.getErrorIntent('addressLastName')}
                  isDisabled={isBusy}
                  mb="11"
                  width="full"
                >
                  <FormLabel>{intl.messages[`${COMPONENT_INTL_KEY}.ReceiverLastname`]}*</FormLabel>
                  <Input
                    placeholder={intl.messages[`${COMPONENT_INTL_KEY}.PlaceHolder.ReceiverLastname`]}
                    onBlur={handleOnBlur('addressLastName')}
                    onChange={handleOnInputChange('addressLastName')}
                    value={fields.addressLastName}
                  />
                  <FormErrorMessage>{validator.getErrorIntent('addressLastName')?.text}</FormErrorMessage>
                </FormControl>
              </Flex>
              <FormControl isDisabled={isBusy} mb="11">
                <FormLabel>{intl.messages[`${COMPONENT_INTL_KEY}.Company`]}</FormLabel>
                <Input
                  placeholder={intl.messages[`${COMPONENT_INTL_KEY}.PlaceHolder.Company`]}
                  onChange={handleOnInputChange('company')}
                  value={fields.company}
                />
              </FormControl>
              <FormControl isInvalid={!!validator.getErrorIntent('addressOne')} isDisabled={isBusy} mb="11">
                <FormLabel>{intl.messages[`${COMPONENT_INTL_KEY}.ReceiverAddressOne`]}*</FormLabel>
                <Input
                  placeholder={intl.messages[`${COMPONENT_INTL_KEY}.PlaceHolder.ReceiverAddressOne`]}
                  onBlur={handleOnBlur('addressOne')}
                  onChange={handleOnInputChange('addressOne')}
                  value={fields.addressOne}
                />
                <FormErrorMessage>{validator.getErrorIntent('addressOne')?.text}</FormErrorMessage>
              </FormControl>
              <FormControl isDisabled={isBusy} mb="11">
                <FormLabel>{intl.messages[`${COMPONENT_INTL_KEY}.ReceiverAddressTwo`]}</FormLabel>
                <Input
                  placeholder={intl.messages[`${COMPONENT_INTL_KEY}.PlaceHolder.ReceiverAddressTwo`]}
                  onChange={handleOnInputChange('addressTwo')}
                  value={fields.addressTwo}
                />
              </FormControl>
              <Flex>
                <FormControl width="full" pr="16" mb="11">
                  <FormLabel>{intl.messages[`${COMPONENT_INTL_KEY}.Country`]}*</FormLabel>
                  <SelectHTML
                    placeholder={intl.messages[`${COMPONENT_INTL_KEY}.PlaceHolder.Country`]}
                    onChange={handleOnInputChange('country')}
                    onBlur={handleOnBlur('country')}
                    value={fields.country}
                    options={DropdownOptionsFromResource(countryResource)}
                  />

                </FormControl>
                <FormControl width="full"  mb="11" >
                  <FormLabel>{intl.messages[`${COMPONENT_INTL_KEY}.City`]}*</FormLabel> 
                  {
                      fields.country == intl.messages[`${COMPONENT_INTL_KEY}.Turkey`] ?
                      <SelectHTML
                      placeholder={intl.messages[`${COMPONENT_INTL_KEY}.PlaceHolder.City`]}
                      options={provinceOptions()}
                      value={fields.city}
                      isBusy={provinceResource ? provinceResource.isBusy : false}
                      onChange={handleOnInputChange('city')}
                    /> : 
                    <Input
                    placeholder={intl.messages[`${COMPONENT_INTL_KEY}.PlaceHolder.City`]} 
                    onChange={handleOnInputChange('city')}
                    value={fields.city} />
                  } 
                  <FormErrorMessage>{validator.getErrorIntent('city')?.text}</FormErrorMessage>
                </FormControl>
              </Flex>
              <Flex>
                <FormControl
                  isDisabled={districtResource ? districtResource.isBusy : false}
                  className="big"
                  errorMessage={!!validator.getErrorIntent('district')}
                  pr="16"
                  width="full"
                  mb="11"
                >
                  <FormLabel>{intl.messages[`${COMPONENT_INTL_KEY}.District`]}*</FormLabel> 
                  {
                      fields.country == intl.messages[`${COMPONENT_INTL_KEY}.Turkey`] ? 
                      <SelectHTML
                        placeholder={intl.messages[`${COMPONENT_INTL_KEY}.PlaceHolder.District`]}
                        options={districtOptions()}
                        value={fields.district}
                        onChange={handleOnInputChange('district')}
                      /> :
                      <Input
                      placeholder={intl.messages[`${COMPONENT_INTL_KEY}.PlaceHolder.District`]} 
                      onChange={handleOnInputChange('district')}
                      value={fields.district} /> 
                  } 
                  <FormErrorMessage>{validator.getErrorIntent('district')?.text}</FormErrorMessage>
                </FormControl>
                <FormControl
                  width="full"
                  isInvalid={!!validator.getErrorIntent('postalCode')}
                  isDisabled={isBusy}
                  mb="11"
                >
                  <FormLabel>{intl.messages[`${COMPONENT_INTL_KEY}.PostalCode`]}</FormLabel>
                  <Input
                    placeholder={intl.messages[`${COMPONENT_INTL_KEY}.PlaceHolder.PostalCode`]}
                    onBlur={handleOnBlur('postalCode')}
                    onChange={handleOnInputChange('postalCode')}
                    value={fields.postalCode}
                  />
                  <FormErrorMessage>{validator.getErrorIntent('postalCode')?.text}</FormErrorMessage>
                </FormControl>
              </Flex>
              <FormControl isInvalid={!!validator.getErrorIntent('addressPhone')} isDisabled={isBusy}>
                <FormLabel>{intl.messages[`${COMPONENT_INTL_KEY}.ReceiverPhoneNumber`]}*</FormLabel>
                <Input
                  placeholder={intl.messages[`${COMPONENT_INTL_KEY}.PlaceHolder.ReceiverPhoneNumber`]}
                  onBlur={handleOnBlur('addressPhone')}
                  onChange={handleOnInputChange('addressPhone')}
                  value={fields.addressPhone}
                />
                <FormErrorMessage>{validator.getErrorIntent('addressPhone')?.text}</FormErrorMessage>
              </FormControl>
            </Form>
          </Box>
        </Flex>
      </Box>

      <Box position="sticky" top="175px" mr="159px" ml="44px" alignSelf="flex-start">
        <Button
          disabled={isFormInvalid() || isBusy}
          onClick={() => {
            onSubmit(fields);
          }}
          isLoading={isBusy}
        >
          {intl.messages[`${COMPONENT_INTL_KEY}.Save`]}
        </Button>
      </Box>
      {renderCreateCustomerSuccessModal()}
    </Flex>
  );
};

import { GridContainer } from '@components/atoms/GridContainer';
import { barcodeFormatter, geti18nName, ProductDetailsLinkFormatter } from '@containers/formatters';
import { GridProps, GridType } from '@models';
import { ColumnSize, DataGridColumn, DataGridRow, ImageFormatter, PredefinedFilter } from '@oplog/data-grid';
import useSendToWarehouseState, { SendToWarehouseStateKeys } from '@store/sendToWarehouse/SendToWarehouseState';
import {
  ArrayFilterOperation,
  BooleanFilter,
  BooleanFilterOperation,
  SortDirection,
  SortField,
} from 'dynamic-query-builder-client';
import * as React from 'react';
import { useEffect } from 'react';
import { SendToWarehouseNumericStep } from './bones';

export interface SendToWarehouseGridProps extends GridProps {}

const intlKey = 'SendToWarehouseGrid';
const titleKey = 'SendToWarehouse.Grid.Title';

const sendToWarehouseGridInitalSort: SortField = new SortField({
  property: 'sku',
  by: SortDirection.ASC,
});

export const sendToWarehouseGridPredefinedFilters: Array<PredefinedFilter> = [
  {
    filter: new BooleanFilter({
      id: 'Starred',
      property: 'favorite',
      op: BooleanFilterOperation.Equals,
      value: true,
    }),
    selected: false,
  },
];

export const SendToWarehouseGrid: React.FC<SendToWarehouseGridProps> = ({ intl }) => {
  const [state, actions] = useSendToWarehouseState();

  useEffect(() => {
    if (state.panelData.length === 0) {
      actions.setStateData(SendToWarehouseStateKeys.gridData, []);
    }
  }, [state.panelData]);

  const sendToWarehouseGridColumns: Array<DataGridColumn> = [
    {
      name: '#',
      key: 'index',
      resizable: true,
      locked: true,
      sortable: false,
      type: 'number',
      visibility: true,
      filterable: false,
      width: ColumnSize.Medium,
      cellClass: 'index-column',
    },
    {
      name: geti18nName('sku', intl, intlKey),
      key: 'sku',
      resizable: true,
      locked: true,
      sortable: true,
      type: 'string',
      visibility: true,
      filterable: true,
    },
    {
      name: geti18nName('barcode', intl, intlKey),
      key: 'barcodes',
      locked: true,
      type: 'string',
      visibility: true,
      resizable: true,
      sortable: false,
      filterable: true,
      formatter: barcodeFormatter,
      fieldType: 'array',
      searchField: '_',
      outerOp: ArrayFilterOperation.Any,
    },
    {
      name: geti18nName('name', intl, intlKey),
      key: 'name',
      resizable: true,
      locked: true,
      sortable: false,
      type: 'string',
      visibility: true,
      filterable: true,
      formatter: ProductDetailsLinkFormatter,
      getRowMetaData: (row: DataGridRow) => row,
    },
    {
      name: geti18nName('imageUrl', intl, intlKey),
      key: 'imageUrl',
      locked: true,
      sortable: false,
      type: 'string',
      visibility: true,
      filterable: false,
      formatter: ImageFormatter,
      width: ColumnSize.Medium,
    },
    {
      name: geti18nName('stockAmount', intl, intlKey),
      key: 'stockAmount',
      resizable: true,
      sortable: true,
      type: 'number',
      visibility: true,
      filterable: true,
    },
    {
      name: geti18nName('sentAmount', intl, intlKey),
      key: '',
      resizable: false,
      sortable: false,
      type: 'component',
      visibility: true,
      locked: true,
      filterable: false,
      formatter: row => {
        return <SendToWarehouseNumericStep sku={row.dependentValues['sku']} dependentValues={row.dependentValues} />;
      },
      getRowMetaData: (row: DataGridRow) => row,
    },
  ];

  return (
    <GridContainer
      titleKey={titleKey}
      intlKey={intlKey}
      gridKey={GridType.Products}
      columns={sendToWarehouseGridColumns}
      predefinedFilters={sendToWarehouseGridPredefinedFilters}
      sortField={sendToWarehouseGridInitalSort}
      intl={intl}
      actionsDisabled
    />
  );
};

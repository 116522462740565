/* eslint-disable no-nested-ternary */
import { Props } from '@components/atoms/Component/Component';
import { Box, Button, Flex, FormControl, Icon, Input, Select, Text, Image, Popover } from '@oplog/express';
import { Resource } from '@oplog/resource-redux';
import {
  CargoCarriersApiDTO,
  CreateIntegrationCommand,
  ECommerceCargoCompany,
  IntegrationOutputDTO,
  IntegrationState,
  IntegrationType,
  OpenApiIdentifier,
  TicimaxIntegrationPayload,
  UpdateIntegrationCommand,
} from '@services';
import useCommonDataStore from '@store/commonData/commonDataState';
import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';

export interface TicimaxMappingProps extends Props {
  integration: IntegrationOutputDTO;
  onSubmit: (
    integration: UpdateIntegrationCommand | CreateIntegrationCommand,
    initialIntegrationState: boolean | undefined
  ) => void;
  onGetIntegrationPayload: (integrationId: string, integrationType: IntegrationType) => void;
  getIntegrationPayloadResource: Resource<Response>;
  editMode: boolean;
}

const TicimaxMappingForm: React.FC<TicimaxMappingProps> = ({
  integration,
  onSubmit,
  onGetIntegrationPayload,
  getIntegrationPayloadResource,
  isBusy,
  intl,
  editMode,
  onWillUnmount,
}: TicimaxMappingProps) => {
  const COMPONENT_INTL_KEY = 'Integrations.IntegrationInfo.TicimaxMappingForm';

  const [initialIntegrationState, setInitalIntegrationState] = useState(
    integration ? integration.state === IntegrationState.Active : true
  );

  const [{ cargoCarriers }, { clearState }] = useCommonDataStore();

  const [integrationUpdateState, setIntegrationUpdateState] = useState<UpdateIntegrationCommand>({} as any);
  const cargoCompanyCount = integration.ticimaxPayload?.cargoCompanies?.length || 0;
  const [integrationCount, setIntegrationCount] = useState(0);
  const [cargoCompanies, setCargoCompanies] = useState<ECommerceCargoCompany[]>();
  const [isGetPayloadCalled, setIsGetPayloadCalled] = useState(false);

  const renderCargoCompanies = () => {
    var options: any = [];
    cargoCarriers.forEach((carrier: CargoCarriersApiDTO) => {
      options.push({
        label: carrier.value,
        value: carrier.value,
      });
    });
    if (!isGetPayloadCalled) {
      return options;
    }
    return options;
  };

  const checkSubmit = () => {
    if (integrationCount != cargoCompanyCount && editMode === false) {
      return true;
    }
    return isBusy;
  };

  useEffect(() => {
    return () => {
      onWillUnmount && onWillUnmount();
    };
  }, []);

  useEffect(() => {
    if (integration.id) {
      if (integration.ticimaxPayload) {
        setCargoCompanies(integration.ticimaxPayload.cargoCompanies);
      }
      setIntegrationUpdateState({
        id: integration.id,
        isActive: integration ? integration.state === IntegrationState.Active : true,
        integrationName: integration.name,
        type: integration.type,
        shouldSyncMissingData: false,
        shouldSyncMissingOrders: false,
        shouldSyncMissingProducts: false,
        password: integration.password,
        shopLink: integration.shopLink,
        ticimaxIntegrationConfigurations: {
          cargoCompanies: integration.ticimaxConfigurations?.cargoCompanies
            ? { ...integration.ticimaxConfigurations?.cargoCompanies }
            : ({} as any),
        },
        ticimaxIntegrationPayload: integration.ticimaxPayload,
        openApiIdentifier: integration ? integration.openApiIdentifier : OpenApiIdentifier.None,
        isCoDActive: integration.isCoDActive,
        creditCardKey: integration.creditCardKey,
        cashKey: integration.cashKey,
        skuWithBarcode: integration.skuWithBarcode,
        isSameAsBarcode: integration.isSameAsBarcode,
        isActiveOrderNumber: integration.isActiveOrderNumber
      });
    }
  }, [integration]);

  useEffect(() => {
    if (getIntegrationPayloadResource.isSuccess && isGetPayloadCalled) {
      const ticimaxPayload: TicimaxIntegrationPayload = getIntegrationPayloadResource.data as TicimaxIntegrationPayload;
      setIntegrationUpdateState({
        ...integrationUpdateState,
        ticimaxIntegrationConfigurations: {
          cargoCompanies: {},
        },
        ticimaxIntegrationPayload: ticimaxPayload,
      });
      setCargoCompanies(ticimaxPayload.cargoCompanies);
    }
  }, [getIntegrationPayloadResource]);

  const handleOnSubmit = () => {
    onSubmit(integrationUpdateState, initialIntegrationState);
  };

  const handleOnUpdateConfiguration = () => {
    setCargoCompanies([]);
    onGetIntegrationPayload(integration.id, integration.type);
    setIsGetPayloadCalled(true);
  };

  const handleSelectChange = (e: any, integrationValue: string) => {
    if (cargoCompanyCount != integrationCount) {
      // If all items are already checked stop the count.
      setIntegrationCount(integrationCount + 1);
    }

    setIntegrationUpdateState({
      ...integrationUpdateState,
      ticimaxIntegrationConfigurations: {
        cargoCompanies: {
          ...integrationUpdateState.ticimaxIntegrationConfigurations?.cargoCompanies,
          [integrationValue]: e.value.value
        } as any,
      },
    });
  };

  const boxStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  };

  return (
    <>
      <Box flexDirection="row">
        <Image src="/images/information_icon.png" width="20px" marginLeft="5px" marginTop="-5px" />
        <Text>{intl.messages[`${COMPONENT_INTL_KEY}.UpdateConfigurationWarning`]}</Text>
      </Box>
      <Box {...boxStyle}>
        <Button
          size="small"
          type="submit"
          onClick={() => handleOnUpdateConfiguration()}
          isLoading={getIntegrationPayloadResource.isBusy}
          variant="alternative"
          disabled={getIntegrationPayloadResource.isBusy}
          m="16"
          width="100%"
        >
          {intl.messages[`${COMPONENT_INTL_KEY}.UpdateConfigurationButton`]}
        </Button>
      </Box>
      <Box flexDirection="row">
        <Text color="palette.steel_dark" fontSize="14" fontWeight={800}>
          {intl.messages[`${COMPONENT_INTL_KEY}.CompanyMapping`]}
        </Text>
        <Popover
          isDark
          content={intl.messages[`${COMPONENT_INTL_KEY}.CompanyMappingInfo`]}
          placement="bottom"
          action={['hover']}
        >
          <Image src="/images/questionmark_icon.png" width="20px" marginLeft="5px" marginTop="-5px" />
        </Popover>
      </Box>
      <Box marginTop="10px" marginBottom="10px">
        {integration &&
          cargoCompanies?.map((value: any, index: number) => {
            const existingIntegrations = integrationUpdateState.ticimaxIntegrationConfigurations?.cargoCompanies;
            var keyValue;
            if (existingIntegrations) {
              var key = Object.keys(existingIntegrations).find(
                key => key === value.referenceCode
              );
              if (key)
                keyValue = existingIntegrations[key];
            }

            return (
              <Flex width={1} key={index}>
                <FormControl p={10} width={0.45} size={'small'} isRequired={false} isInvalid={false} isDisabled={true}>
                  <Input placeholder={value.name} />
                </FormControl>

                <Box style={boxStyle}>
                  <Icon p={10} color="palette.snow_dark" name="fa fa-caret-right" fontSize={20} />
                </Box>

                <FormControl p={10} width={0.45} size={'small'}>
                  <Select
                    options={renderCargoCompanies()}
                    placeholder={intl.messages[`${COMPONENT_INTL_KEY}.CompanySelectionPlaceHolder`]}
                    onChange={(e: any) => handleSelectChange(e, value.referenceCode)}
                    value={keyValue}
                  />
                </FormControl>
              </Flex>
            );
          })}

        {cargoCompanyCount === 0 && "Cargo company data doesn't exist."}
      </Box>

      <Box style={boxStyle} marginTop="10px">
        <Button
          size="small"
          type="submit"
          disabled={checkSubmit()}
          onClick={() => handleOnSubmit()}
          isLoading={false}
          variant="success"
          m="16"
          width="100%"
        >
          {intl.messages[`Modal.Confirmation.Save`]}
        </Button>
      </Box>
    </>
  );
};

export default injectIntl(TicimaxMappingForm);

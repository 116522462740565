import { FormatterProps } from '@oplog/data-grid';
import { Pipeline } from '@oplog/express';
import { ReceivingOrderState } from '@services';
import * as React from 'react';

export function bubbleStepFormatter(props: FormatterProps) {
  const { intl } = props.dependentValues;
  const enumValues = Object.keys(props.dependentValues.enum);
  const lastCompletedStep = props.dependentValues.row.lastCompletedState;
  const { value } = props;
  const isCancelled = value === ReceivingOrderState.Cancelled;
  const currentIndex = enumValues.findIndex(enumValue => enumValue === lastCompletedStep);
  const steps: any = [];

  new Array(enumValues.length - 1).fill('').forEach((_, index) => {
    if (currentIndex === index - 1) {
      steps.push({ state: isCancelled ? 'cancelled' : 'active', title: intl.messages[`Enum.BubbleStep.${value}`] });
    } else if (currentIndex >= index && index != 4) {
      steps.push({ state: 'completed', title: intl.messages[`Enum.BubbleStep.${value}`] });
    } else if (currentIndex >= index && index == 4 && !isCancelled) {
      steps.push({ state: 'completed', title: intl.messages[`Enum.BubbleStep.${value}`] });
    } else if (index == 4 && isCancelled) {
      steps.push({ state: 'cancelled', title: intl.messages[`Enum.BubbleStep.${value}`] });
    } else {
      steps.push({ state: 'disabled', title: intl.messages[`Enum.BubbleStep.${value}`] });
    }
  });

  return <Pipeline size="small" steps={steps} />;
}

import { ResourceType } from '@models';
import { validation } from '@oplog/express';
import { resourceActions, resourceSelectors } from '@oplog/resource-redux';
import { urls } from '@routes/urls';
import { CreateCustomerCommand } from '@services';
import { StoreState } from '@store';
import { push, RouterAction } from 'connected-react-router';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import {
  CreateCustomerForm,
  CreateCustomerFormProps,
} from '../../components/molecules/CreateCustomerForm/CreateCustomerForm';

function mapStateToProps({ resources, customerDetails }: StoreState): Partial<CreateCustomerFormProps> {
  return {
    isCreateCustomerSuccess: resourceSelectors.isSuccessComplete(resources, ResourceType.CreateCustomer),
    createdCustomerData: resourceSelectors.getData(resources, ResourceType.CreateCustomer),
    countryResource: resources[ResourceType.AllCountries],
    provinceResource: resources[ResourceType.AllProvinces],
    districtResource: resources[ResourceType.DistrictsOfProvince],
    isBusy: resourceSelectors.isBusy(resources, ResourceType.CreateCustomer),
    error: resourceSelectors.getError(resources, ResourceType.CreateCustomer),
  };
}

function mapDispatchToProps(
  dispatch: Dispatch<resourceActions.ResourceAction | RouterAction>
): Partial<CreateCustomerFormProps> {
  return {
    onSubmit(form: CreateCustomerCommand) {
      dispatch(
        resourceActions.resourceRequested(ResourceType.CreateCustomer, {
          customerForm: form,
        })
      );
    },
    onCreateCustomerSuccessModalClose: (customerNo: number): void => {
      dispatch(resourceActions.resourceInit(ResourceType.CreateCustomer));
      dispatch(push(urls.customerDetails.replace(':customerNo', `${customerNo}`).replace('/:tab', '')));
    },
    onDidMount: (): void => {
      dispatch(resourceActions.resourceRequested(ResourceType.AllProvinces));
      dispatch(resourceActions.resourceRequested(ResourceType.AllCountries));
    },
    provinceSelect: (command: { province: string }): void => {
      dispatch(resourceActions.resourceRequested(ResourceType.DistrictsOfProvince, command));
    },
    onWillUnmount: (): void => {
      dispatch(resourceActions.resourceInit(ResourceType.CreateCustomer));
      dispatch(resourceActions.resourceInit(ResourceType.DistrictsOfProvince));
      dispatch(resourceActions.resourceInit(ResourceType.AllProvinces));
      dispatch(resourceActions.resourceInit(ResourceType.AllCountries));
    },
  };
}

export const CreateCustomerFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(validation(injectIntl(CreateCustomerForm)));

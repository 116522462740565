import { CreateCustomerMultiFormContainer } from '@containers/CreateCustomerMultiFormContainer';
import { Box, DrawerTitle, InputSearch } from '@oplog/express';
import { SearchData } from '@oplog/express/dist/components/InputSearch/InputSearch';
import { Resource } from '@oplog/resource-redux';
import { SearchCustomersQueryOutputDTO } from '@services';
import { checkShippingAddress } from '@utils';
import * as React from 'react';
import { useState } from 'react';
import { Props } from '../../atoms/Component/Component';

export interface CustomerSearchProps extends Props {
  intlKey: string;
  onInputEnter: (text: string) => void;
  onInputChange: (event: React.SyntheticEvent<HTMLInputElement>) => void;
  onNewCustomerCompleted: () => void;
  onCustomerClick: (customerNo: number) => void;
  customers: Resource<SearchCustomersQueryOutputDTO[]>;
  showTitle: boolean;
}

export const CustomerSearch: React.FC<CustomerSearchProps> = ({
  intlKey,
  onInputEnter,
  intl,
  onInputChange,
  onNewCustomerCompleted,
  onCustomerClick,
  customers,
  showTitle,
  isBusy,
}) => {
  const [modalState, setModalState] = useState({
    newCustomerModal: false,
  });

  const getData = (): SearchData[] => {
    const { data } = customers;
    if (!data) {
      return [];
    }
    return data.map((item: SearchCustomersQueryOutputDTO) => ({
      id: `${item.customerNo}`,
      title: `${item.firstName} ${item.lastName} - ${item.email}`,
      subtitle: checkShippingAddress(item.address),
    }));
  };

  return (
    <>
      {showTitle && (
        <DrawerTitle size="medium" boxShadow="small">
          {intl.messages[`${intlKey}.Header.ChooseCustomer`]}
        </DrawerTitle>
      )}

      <Box px="22" mt="13px">
        <InputSearch
          placeholder={intl.messages['CustomerSearch.Placeholder']}
          onEnter={onInputEnter}
          onInputChange={onInputChange}
          isLoading={!!isBusy}
          onSelect={(item: SearchData) => {
            onCustomerClick(parseInt(item.id as any, 10));
          }}
          data={getData()}
          newButton={{
            title: intl.messages['CustomerSearch.NewCustomer'],
            onClick: () =>
              setModalState({
                ...modalState,
                newCustomerModal: true,
              }),
          }}
        />
      </Box>
      {modalState.newCustomerModal && (
        <CreateCustomerMultiFormContainer
          onModalClose={(isCancelClickButton: boolean) => {
            if (!isCancelClickButton) {
              onNewCustomerCompleted();
            }
            setModalState({
              ...modalState,
              newCustomerModal: false,
            });
          }}
          closeModal={() => {
            setModalState({
              ...modalState,
              newCustomerModal: false,
            });
          }}
          isOpen={modalState.newCustomerModal}
        />
      )}
    </>
  );
};

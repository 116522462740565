import { GridContainer } from '@components/atoms/GridContainer';
import { SortDirection, SortField } from 'dynamic-query-builder-client';
import * as React from 'react';
import { GridProps, GridType } from '../../../models';
import getSupportSalesOrdersColumns from './bones/SupportSalesOrdersColumns';
import getSupportSalesOrdersManipulationColumns from './bones/SupportSalesOrdersManipulationColumns';
import getSupportSalesOrdersSyncColumns from './bones/SupportSalesOrderSyncColumns';

const intlKey = 'SupportSalesOrdersGrid';

const tenantGridInitalSort: SortField = new SortField({
  property: 'createdAt',
  by: SortDirection.DESC,
});

export interface SupportSalesOrdersGridProps extends GridProps {
  type: String;
}

enum SalesOrderTabs {
  Orders = 'OrderProgression',
  SyncStatus = 'SyncStatus',
  DataManipulation = 'DataManipulation',
}

export class SupportSalesOrdersGrid extends React.Component<SupportSalesOrdersGridProps> {
  render() {
    const { intl, titleKey, type } = this.props;

    var columns;
    if (type === SalesOrderTabs.Orders) {
      columns = getSupportSalesOrdersColumns(intl, intlKey);
    } else if (type === SalesOrderTabs.SyncStatus) {
      columns = getSupportSalesOrdersSyncColumns(intl, intlKey);
    } else if (type === SalesOrderTabs.DataManipulation) {
      columns = getSupportSalesOrdersManipulationColumns(intl, intlKey);
    }

    return (
      <GridContainer
        titleKey={titleKey ? `${intl.messages['Grid.Title']} - ${titleKey}` : intl.messages['Grid.Title']}
        intlKey={intlKey}
        gridKey={GridType.SupportSalesOrders}
        columns={columns}
        apiArgs={[]}
        actionsDisabled={true}
        predefinedFilters={[]}
        intl={this.props.intl}
        sortField={tenantGridInitalSort}
      />
    );
  }
}

/* eslint-disable @typescript-eslint/interface-name-prefix */
import React from 'react';
import globalHook, { Store } from 'use-global-hook';

export enum SyncModalState {
  StartDate = 'startDate',
  EndDate = 'endDate',
  Integration = 'integration',
  IntegrationName = 'integrationName',
}

/* Interfaces */
export interface SupportOrdersSyncModal {
  syncModal: {
    startDate?: Date;
    endDate?: Date;
    integration: string;
    integrationName: string;
  };
}

export interface SupportOrdersSyncModalActions {
  clearState: (data: Partial<SupportOrdersSyncModal>) => void;
  setSyncModalData: (key: SyncModalState, data: any) => void;
  clearSyncModal: () => void;
}

/* InitalStates */
export const initalState: SupportOrdersSyncModal = {
  syncModal: {
    startDate: undefined,
    endDate: undefined,
    integration: '',
    integrationName: '',
  },
};

/* Actions */
export const initalActions: object = {
  clearState: (
    store: Store<SupportOrdersSyncModal, SupportOrdersSyncModalActions>,
    data: Partial<SupportOrdersSyncModal>
  ) => {
    store.setState({ ...store.state, ...data });
  },
  clearSyncModal: (store: Store<SupportOrdersSyncModal, SupportOrdersSyncModalActions>) => {
    store.setState({
      ...store.state,
      syncModal: { ...initalState.syncModal },
    });
  },
  setSyncModalData: (
    store: Store<SupportOrdersSyncModal, SupportOrdersSyncModalActions>,
    key: SyncModalState,
    data: any
  ) => {
    const syncModal = {
      ...store.state.syncModal,
      [key]: data,
    };
    store.setState({ ...store.state, syncModal: syncModal });
  },
};

/* Export */
const useSupportOrdersState = globalHook<SupportOrdersSyncModal, SupportOrdersSyncModalActions>(
  React,
  initalState,
  initalActions
);

export default useSupportOrdersState;

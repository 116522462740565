import { IntegrationStates } from '@components/pages/Integrations';
import {
  Alert,
  AlertDescription,
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  FormValidator,
  Input,
  Toggle,
} from '@oplog/express';
import { Resource } from '@oplog/resource-redux';
import { urls } from '@routes/urls';
import {
  CreateIntegrationCommand,
  IntegrationOutputDTO,
  IntegrationState,
  IntegrationType,
  OpenApiIdentifier,
  UpdateIntegrationCommand,
} from '@services';
import { getIntegrationErrorMessage } from '@utils';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import useForceUpdate from 'use-force-update';
import * as Yup from 'yup';
import { Props } from '../../atoms/Component/Component';

const COMPONENT_INTL_KEY = 'Integrations.IntegrationInfo';

//const CashOnDeliveryHelpUrl = `${urls.helpHome}/integration-actions/cash-on-delivery/cash-on-delivery-shopify`;

export interface ShopifyIntegrationProps extends Props {
  integration?: IntegrationOutputDTO;
  type?: IntegrationType;
  validator: FormValidator;
  deleteIntegrationResource?: Resource<Response>;
  onSubmit: (
    integration: UpdateIntegrationCommand | CreateIntegrationCommand,
    initialIntegrationState: boolean | undefined
  ) => void;
  onClose: () => void;
  onDelete?: () => void;
  isEdit: boolean;
  isNew: boolean;
  isReferenceNumberRequired?: boolean;
}

export const ShopifyIntegrationForm: React.FC<ShopifyIntegrationProps> = ({
  integration,
  type,
  validator,
  deleteIntegrationResource,
  onSubmit,
  onClose,
  onDelete,
  isEdit,
  isNew,
  isReferenceNumberRequired,
  isBusy,
  intl,
  onWillMount,
  onWillUnmount,
  error,
}) => {
  const forceUpdate = useForceUpdate();

  const [integrationState, setIntegrationState] = useState<IntegrationStates>({
    id: integration ? integration.id : '',
    isActive: integration ? integration.state === IntegrationState.Active : true,
    integrationName: integration ? integration.name : '',
    apiPassword: integration ? integration.apiPassword : '',
    shopLink: integration ? integration.shopLink : '',
    shouldSyncMissingData: false,
    shouldSyncMissingOrders: false,
    shouldSyncMissingProducts: false,
    type: IntegrationType.Shopify,
    isCoDActive: integration ? integration.isCoDActive : false,
    cashKey: integration ? integration.cashKey : '',
    creditCardKey: integration ? integration.creditCardKey : '',
    locationId: integration ? integration.locationId : 0,
    referenceNumberPrefix: integration ? integration.referenceNumberPrefix : '',
    isPartialFulfillmentActive: integration ? integration.isPartialFulfillmentActive : false,
    partialFulfillmentServiceName: integration ? integration.partialFulfillmentServiceName : '',
    isSameDayDeliveryActive: integration ? integration.isSameDayDeliveryActive : false,
    sameDayDeliveryKey: integration ? integration.sameDayDeliveryKey : '',
    openApiIdentifier: integration ? integration.openApiIdentifier : OpenApiIdentifier.None,
  });

  const [initialIntegrationState, setInitialIntegrationState] = React.useState<boolean>(
    integration ? integration.state === IntegrationState.Active : true
  );

  useEffect(() => {
    onWillMount && onWillMount();
    validator.registerSchema(shopifyIntegrationFormSchemaFields());

    return () => {
      onWillUnmount && onWillUnmount();
      validator.clearErrors();
    };
  }, []);

  const handleCheckboxChange = (key: 'isCoDActive' | 'isActive') => (e: any) => {
    setIntegrationState({
      ...integrationState,
      [key]: e.currentTarget.checked,
      cashKey: '',
      creditCardKey: '',
    });
  };

  const handleIsPartialFulfillmentActiveChange = () => (e: any) => {
    setIntegrationState({
      ...integrationState,
      isPartialFulfillmentActive: e.currentTarget.checked,
      partialFulfillmentServiceName: '',
    });
  };

  const handleIsSameDayDeliveryActiveChange = () => (e: any) => {
    setIntegrationState({
      ...integrationState,
      isSameDayDeliveryActive: e.currentTarget.checked,
      sameDayDeliveryKey: '',
    });
  };

  const isEmpty = () => {
    return (
      integrationState.integrationName === '' ||
      integrationState.locationId === 0 ||
      integrationState.password === '' ||
      integrationState.shopLink === '' ||
      (integrationState.partialFulfillmentServiceName === '' && integrationState.isPartialFulfillmentActive) ||
      (isReferenceNumberRequired === true && integrationState.referenceNumberPrefix === '') ||
      (integrationState.isCoDActive && (integrationState.cashKey === '' || integrationState.creditCardKey === ''))
    );
  };

  const handleBlur = (key: string) => () => {
    validator.validate(key, integrationState[key]);
    forceUpdate();
  };

  const handleInputChange = (key: string) => (e: any) => {
    let { value } = e.currentTarget;
    if (key === 'locationId' && value && typeof parseInt(value, 10) === 'number') {
      value = parseInt(value, 10);
    }

    setIntegrationState({
      ...integrationState,
      [key]: value,
    });
  };

  const handleDelete = () => {
    if (onDelete) {
      if (deleteIntegrationResource && (isBusy || deleteIntegrationResource.isBusy)) {
        return;
      }
      onDelete();
    }
  };

  const handleClose = () => {
    if (deleteIntegrationResource && (isBusy || deleteIntegrationResource.isBusy)) {
      return;
    }
    onClose();
  };

  const handleSubmit = (e?: React.SyntheticEvent<HTMLFormElement>) => {
    const integration = { ...integrationState };

    e && e.preventDefault();
    if ((deleteIntegrationResource && deleteIntegrationResource.isBusy) || isBusy) {
      return;
    }

    if (isNew) {
      const excludeNew = ['id', 'isActive', 'shouldSyncMissingData'];
      excludeNew.forEach(key => delete integration[key]);
    }

    for (const key in integration) {
      if (integration.hasOwnProperty(key)) {
        const element = integration[key];
        validator.validate(key, element);
      }
    }

    if (validator.hasErrors()) {
      forceUpdate();
      return;
    }

    if (!integration.referenceNumberPrefix) {
      delete integration.referenceNumberPrefix;
    }

    onSubmit(integration, initialIntegrationState);
  };

  const shopifyIntegrationFormSchemaFields = () => {
    const referenceNumberPrefix = isReferenceNumberRequired
      ? Yup.string()
        .required(`${intl.messages[`${COMPONENT_INTL_KEY}.Form.ReferenceNumberPrefixInfo`]}`)
        .max(3, `${intl.messages[`${COMPONENT_INTL_KEY}.Error.ReferenceNumberPrefixMaxChar`]}`)
      : Yup.string().max(3, `${intl.messages[`${COMPONENT_INTL_KEY}.Error.ReferenceNumberPrefixMaxChar`]}`);

    return {
      id: Yup.string(),
      isActive: Yup.boolean(),
      isCoDActive: Yup.boolean(),
      integrationName: Yup.string().required(`${intl.messages[`${COMPONENT_INTL_KEY}.Error.IntegrationName`]}`),
      apiPassword: Yup.string().required(`${intl.messages[`${COMPONENT_INTL_KEY}.Error.ApiPassword`]}`),
      shopLink: Yup.string()
        .url(`${intl.messages[`${COMPONENT_INTL_KEY}.Error.Url`]}`)
        .required(`${intl.messages[`${COMPONENT_INTL_KEY}.Error.ShopLink`]}`),
      type: Yup.string(),
      shouldSyncMissingData: Yup.boolean(),
      creditCardKey: Yup.string(),
      cashKey: Yup.string(),
      locationId: Yup.number()
        .typeError(`${intl.messages[`${COMPONENT_INTL_KEY}.Error.LocationId`]}`)
        .min(1, `${intl.messages[`${COMPONENT_INTL_KEY}.Error.LocationId`]}`)
        .required(`${intl.messages[`${COMPONENT_INTL_KEY}.Error.LocationId`]}`),
      referenceNumberPrefix,
    };
  };

  return (
    <form onSubmit={handleSubmit}>
      {error?.errors && (
        <Alert variant="danger" isOpen mb="11" flexDirection="column" alignItems="flex-start">
          {error?.errors.map(error => (
            <AlertDescription>
              {' '}
              {getIntegrationErrorMessage(error.errorCode, error.property, COMPONENT_INTL_KEY, intl)}
            </AlertDescription>
          ))}
        </Alert>
      )}
      {isEdit && integration && (
        <FormControl
          size="small"
          isInline
          mb="22"
          isDisabled={isBusy || (deleteIntegrationResource && deleteIntegrationResource.isBusy)}
        >
          <FormLabel>{intl.messages[`${COMPONENT_INTL_KEY}.Form.Fields.IsActive`]}</FormLabel>
          <Toggle isChecked={isEdit && integrationState.isActive} onChange={handleCheckboxChange('isActive')} />
        </FormControl>
      )}
      <FormControl
        size="small"
        isDisabled={isBusy || (deleteIntegrationResource && deleteIntegrationResource.isBusy)}
        isInvalid={!!validator.getErrorIntent('integrationName')}
        isInline
        mb="11"
      >
        <FormLabel>{intl.messages[`${COMPONENT_INTL_KEY}.Form.Fields.IntegrationName`]}*</FormLabel>
        <Input
          placeholder={intl.messages[`${COMPONENT_INTL_KEY}.Form.Placeholders.IntegrationName`]}
          value={integrationState.integrationName}
          onChange={handleInputChange('integrationName')}
          onBlur={handleBlur('integrationName')}
        />
        <FormErrorMessage>{validator.getErrorIntent('integrationName')?.text}</FormErrorMessage>
      </FormControl>
      <FormControl
        size="small"
        isDisabled={isBusy || (deleteIntegrationResource && deleteIntegrationResource.isBusy)}
        isInvalid={!!validator.getErrorIntent('apiPassword')}
        isInline
        mb="11"
      >
        <FormLabel>{intl.messages[`${COMPONENT_INTL_KEY}.Form.Fields.ApiPassword`]}*</FormLabel>
        <Input
          placeholder={intl.messages[`${COMPONENT_INTL_KEY}.Form.Placeholders.ApiPassword`]}
          value={integrationState.apiPassword}
          onChange={handleInputChange('apiPassword')}
          onBlur={handleBlur('apiPassword')}
          type="password"
        />
        <FormErrorMessage>{validator.getErrorIntent('apiPassword')?.text}</FormErrorMessage>
      </FormControl>
      <FormControl
        size="small"
        isInvalid={!!validator.getErrorIntent('shopLink')}
        isInline
        isDisabled={isBusy || (deleteIntegrationResource && deleteIntegrationResource.isBusy)}
        mb="11"
      >
        <FormLabel>{intl.messages[`${COMPONENT_INTL_KEY}.Form.Fields.ShopLink`]}*</FormLabel>
        <Input
          placeholder={intl.messages[`${COMPONENT_INTL_KEY}.Form.Placeholders.ShopLink`]}
          value={integrationState.shopLink}
          onChange={handleInputChange('shopLink')}
          onBlur={handleBlur('shopLink')}
        />
        <FormErrorMessage>{validator.getErrorIntent('shopLink')?.text}</FormErrorMessage>
      </FormControl>
      <FormControl
        size="small"
        isInvalid={!!validator.getErrorIntent('locationId')}
        isInline
        isDisabled={isBusy || (deleteIntegrationResource && deleteIntegrationResource.isBusy)}
        mb="11"
      >
        <FormLabel>{intl.messages[`${COMPONENT_INTL_KEY}.Form.Fields.LocationId`]}*</FormLabel>
        <Input
          type="number"
          placeholder={intl.messages[`${COMPONENT_INTL_KEY}.Form.Placeholders.LocationId`]}
          value={integrationState.locationId}
          onChange={handleInputChange('locationId')}
          onBlur={handleBlur('locationId')}
        />
        <FormErrorMessage>{validator.getErrorIntent('locationId')?.text}</FormErrorMessage>
      </FormControl>
      <FormControl
        size="small"
        isInvalid={!!validator.getErrorIntent('referenceNumberPrefix')}
        isInline
        isDisabled={isEdit || isBusy || (deleteIntegrationResource && deleteIntegrationResource.isBusy)}
        mb="11"
      >
        <FormLabel>{intl.messages[`${COMPONENT_INTL_KEY}.Form.Fields.ReferenceNumberPrefix`]}</FormLabel>
        <Input
          placeholder={intl.messages[`${COMPONENT_INTL_KEY}.Form.Placeholders.ReferenceNumberPrefix`]}
          value={integrationState.referenceNumberPrefix}
          onChange={handleInputChange('referenceNumberPrefix')}
          onBlur={handleBlur('referenceNumberPrefix')}
        />
        <FormErrorMessage>{validator.getErrorIntent('referenceNumberPrefix')?.text}</FormErrorMessage>
      </FormControl>
      <FormControl
        mt="22"
        mb="11"
        isInline
        isDisabled={isBusy || (deleteIntegrationResource && deleteIntegrationResource.isBusy)}
      >
        <FormLabel>{intl.messages[`${COMPONENT_INTL_KEY}.Form.Fields.CashOnDelivery`]}</FormLabel>
        <Toggle isChecked={integrationState.isCoDActive} onChange={handleCheckboxChange('isCoDActive')} />
      </FormControl>
      {integrationState.isCoDActive && (
        <>
          <FormControl
            size="small"
            isDisabled={isBusy || (deleteIntegrationResource && deleteIntegrationResource.isBusy)}
            isInline
            isInvalid={!!validator.getErrorIntent('cashKey')}
            mb="11"
          >
            <FormLabel>{intl.messages[`${COMPONENT_INTL_KEY}.Form.Fields.CashKey`]}*</FormLabel>
            <Input
              placeholder={intl.messages[`${COMPONENT_INTL_KEY}.Form.Placeholders.CashKey`]}
              value={integrationState.cashKey}
              onChange={handleInputChange('cashKey')}
              onBlur={handleBlur('cashKey')}
            />
            <FormErrorMessage>{validator.getErrorIntent('cashKey')?.text}</FormErrorMessage>
          </FormControl>
          <FormControl
            size="small"
            isInline
            isInvalid={!!validator.getErrorIntent('creditCardKey')}
            isDisabled={isBusy || (deleteIntegrationResource && deleteIntegrationResource.isBusy)}
            mb="11"
          >
            <FormLabel>{intl.messages[`${COMPONENT_INTL_KEY}.Form.Fields.CreditCardKey`]}*</FormLabel>
            <Input
              placeholder={intl.messages[`${COMPONENT_INTL_KEY}.Form.Placeholders.CreditCardKey`]}
              value={integrationState.creditCardKey}
              onChange={handleInputChange('creditCardKey')}
              onBlur={handleBlur('creditCardKey')}
            />
            <FormErrorMessage>{validator.getErrorIntent('creditCardKey')?.text}</FormErrorMessage>
          </FormControl>
          <FormErrorMessage independent mb="11">
            {intl.messages[`${COMPONENT_INTL_KEY}.Form.ShopifyCODWarning`]}
          </FormErrorMessage>
        </>
      )}
      <FormControl
        mt="22"
        mb="11"
        isInline
        isDisabled={isBusy || (deleteIntegrationResource && deleteIntegrationResource.isBusy)}
      >
        <FormLabel>{intl.messages[`${COMPONENT_INTL_KEY}.Form.Fields.ShopifyPartial`]}</FormLabel>
        <Toggle
          isChecked={integrationState.isPartialFulfillmentActive}
          onChange={handleIsPartialFulfillmentActiveChange()}
        />
      </FormControl>
      {integrationState.isPartialFulfillmentActive && (
        <>
          <FormControl
            size="small"
            isDisabled={isBusy || (deleteIntegrationResource && deleteIntegrationResource.isBusy)}
            isInline
            isInvalid={!!validator.getErrorIntent('partialFulfillmentServiceName')}
            mb="11"
          >
            <FormLabel>{intl.messages[`${COMPONENT_INTL_KEY}.Form.Fields.ShopifyPartialLabel`]}*</FormLabel>
            <Input
              placeholder={intl.messages[`${COMPONENT_INTL_KEY}.Form.Placeholders.ShopifyPartialLabel`]}
              value={integrationState.partialFulfillmentServiceName}
              onChange={handleInputChange('partialFulfillmentServiceName')}
              onBlur={handleBlur('partialFulfillmentServiceName')}
            />
            <FormErrorMessage>{validator.getErrorIntent('partialFulfillmentServiceName')?.text}</FormErrorMessage>
          </FormControl>
          <FormErrorMessage independent mb="11">
            {intl.messages[`${COMPONENT_INTL_KEY}.Form.ShopifyPartialWarning`]}
          </FormErrorMessage>
        </>
      )}
      <FormControl
        mt="22"
        mb="11"
        isInline
        isDisabled={isBusy || (deleteIntegrationResource && deleteIntegrationResource.isBusy)}
      >
        <FormLabel>{intl.messages[`${COMPONENT_INTL_KEY}.Form.Fields.SameDayDelivery`]}</FormLabel>
        <Toggle
          isChecked={integrationState.isSameDayDeliveryActive}
          onChange={handleIsSameDayDeliveryActiveChange()}
        />
      </FormControl>
      {integrationState.isSameDayDeliveryActive && (
        <>
          <FormControl
            size="small"
            isDisabled={isBusy || (deleteIntegrationResource && deleteIntegrationResource.isBusy)}
            isInline
            isInvalid={!!validator.getErrorIntent('sameDayDeliveryKey')}
            mb="11"
          >
            <FormLabel>{intl.messages[`${COMPONENT_INTL_KEY}.Form.Fields.SameDayDeliveryLabel`]}*</FormLabel>
            <Input
              placeholder={intl.messages[`${COMPONENT_INTL_KEY}.Form.Placeholders.SameDayDeliveryLabel`]}
              value={integrationState.sameDayDeliveryKey}
              onChange={handleInputChange('sameDayDeliveryKey')}
              onBlur={handleBlur('sameDayDeliveryKey')}
            />
            <FormErrorMessage>{validator.getErrorIntent('sameDayDeliveryKey')?.text}</FormErrorMessage>
          </FormControl>
          <FormErrorMessage independent mb="11">
            {intl.messages[`${COMPONENT_INTL_KEY}.Form.ShopifySameDayDeliveryWarning`]}
          </FormErrorMessage>
        </>
      )}
      {/* This will stay commented out until new help document */}
      {/* <Box color="palette.grey" fontSize="11" lineHeight="medium">
        <FormattedMessage
          id={`${COMPONENT_INTL_KEY}.Form.ShopifyCODInfo`}
          defaultMessage={intl.messages[`${COMPONENT_INTL_KEY}.Form.ShopifyCODInfo`]}
          values={{
            link: <Link to={CashOnDeliveryHelpUrl}>{intl.messages[`${COMPONENT_INTL_KEY}.Form.ClickHere`]}</Link>,
          }}
        />
      </Box> */}
      <Flex mt="16">
        <Button
          size="small"
          type="button"
          kind="outline"
          variant="dark"
          disabled={isBusy || (deleteIntegrationResource && deleteIntegrationResource.isBusy)}
          onClick={handleClose}
          mr="11"
          width="full"
          maxWidth="118px"
        >
          {intl.messages[`${COMPONENT_INTL_KEY}.Form.Cancel`]}
        </Button>
        {isEdit && (
          <Button
            size="small"
            type="button"
            variant="danger"
            disabled={isBusy}
            isLoading={deleteIntegrationResource && deleteIntegrationResource.isBusy}
            onClick={handleDelete}
            width="full"
          >
            {intl.messages[`${COMPONENT_INTL_KEY}.Form.Delete`]}
          </Button>
        )}
        <Button
          ml="11"
          size="small"
          type="submit"
          disabled={
            isBusy ||
            (deleteIntegrationResource && deleteIntegrationResource.isBusy) ||
            validator.hasErrors() ||
            isEmpty()
          }
          isLoading={isBusy && !(deleteIntegrationResource && deleteIntegrationResource.isBusy)}
          variant="success"
          width="full"
        >
          {intl.messages[`${COMPONENT_INTL_KEY}.Form.Submit`]}
        </Button>
      </Flex>
    </form>
  );
};

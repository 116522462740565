import { FEEDBACK_MODAL_TOGGLE } from './constants';

export interface FeedBackModalAction {
  type: FEEDBACK_MODAL_TOGGLE;
  payload: {
    isOpen: boolean;
    subject?: string;
  };
}
export type FeedbackOpenAction = FeedBackModalAction;

export function openFeedbackModal(subject?: string): FeedBackModalAction {
  return {
    type: FEEDBACK_MODAL_TOGGLE,
    payload: {
      isOpen: true,
      subject,
    },
  };
}

export function closeFeedbackModal(): FeedBackModalAction {
  return {
    type: FEEDBACK_MODAL_TOGGLE,
    payload: {
      isOpen: false,
      subject: '',
    },
  };
}

import { ResourceType } from '@models';
import { resourceActions } from '@oplog/resource-redux';
import { CreateReturn, CreateReturnProps } from '@pages/CreateReturn';
import { StoreState } from '@store';
import { CreateReturnAction, createReturnActions, createReturnSelectors } from '@store/createReturn';
import { replace, RouterAction } from 'connected-react-router';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

export function mapStateToProps({ createReturn }: StoreState): Partial<CreateReturnProps> {
  return {
    selectedSalesOrder: createReturnSelectors.getCreateReturnSalesOrder(createReturn),
  };
}

export function mapDispatchToProps(
  dispatch: Dispatch<resourceActions.ResourceAction | RouterAction | CreateReturnAction | RouterAction>
): Partial<CreateReturnProps> {
  return {
    onDismissPostErrors(): void {
      dispatch(resourceActions.resourceInit(ResourceType.CreateReturn));
    },
    clearRoute(): void {
      const route = window.location.pathname;
      dispatch(replace(route));
    },
    onWillUnmount(): void {
      dispatch(createReturnActions.createReturnSalesOrderRemoved());
      dispatch(createReturnActions.removeAllLineItems());
    },
  };
}

export const CreateReturnContainer = connect(mapStateToProps, mapDispatchToProps)(injectIntl(CreateReturn));

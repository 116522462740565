import { ResourceType } from '@models';
import { resourceActions, resourceSelectors } from '@oplog/resource-redux';
import { CreateSalesOrder, CreateSalesOrderProps } from '@pages/CreateSalesOrder';
import { StoreState } from '@store';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

export function mapStateToProps({ resources }: StoreState): Partial<CreateSalesOrderProps> {
  return {
    error: resourceSelectors.getError(resources, ResourceType.CreateSalesOrder),
  };
}

export function mapDispatchToProps(dispatch: Dispatch<resourceActions.ResourceAction>): Partial<CreateSalesOrderProps> {
  return {
    onDismissPostErrors(): void {
      // We initialize post request store here if error happens.
      dispatch(resourceActions.resourceInit(ResourceType.CreateSalesOrder));
    },
  };
}

export const CreateSalesOrderContainer = connect(mapStateToProps, mapDispatchToProps)(injectIntl(CreateSalesOrder));

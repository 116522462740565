/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { UpdateSalesOrderCargoInfoInputDTO } from '@services';
import {
  Modal,
  ModalTitle,
  ModalHeader,
  ModalContent,
  Box,
  Heading,
  NumericStep,
  PseudoBox,
  Button,
  ModalFooter,
  Dialog,
  DialogTypes,
  Alert,
  AlertTitle,
  AlertDescription,
  Tooltip,
  Icon,
  Flex,
  Form,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  Text,
  FormValidator,
  SelectHTML,
  DrawerTitle,
  Popover,
  InputGroup,
  InputRightAddon,
  Image,
} from '@oplog/express';
import { injectIntl, FormattedMessage } from 'react-intl';
import ImagePreviewer from '@components/atoms/ImagePreviewer/ImagePreviewer';
import { FileUploader } from '@oplog/file-uploader';

const COMPONENT_INTL_KEY = 'UpdateCargoInfosModal';

export interface UpdateCargoInfosModalProps {
  validator: FormValidator;
  onClose: () => void;
  onCreate: (data: any) => void;
  intl?: any;
  referenceNumber: string;
  requestInfo?: {
    isBusy: boolean;
    isCompleted: boolean;
    isError: boolean;
    getError: any;
  };
  cargoCarriers:[{key: any, value: any}];
  isUploading: boolean;
  fileUrl: string;
  onFileSelect: (file: File) => void;
  onFileReset: () => void;
}

export interface UpdateCargoInfosModalState {
  fields: UpdateSalesOrderCargoInfoInputDTO;
}

const UpdateCargoInfosModal: React.FC<UpdateCargoInfosModalProps> = ({
  referenceNumber,
  validator,
  onClose,
  intl,
  requestInfo,
  cargoCarriers,
  isUploading,
  fileUrl,
  onFileSelect,
  onFileReset,
  onCreate,
}: UpdateCargoInfosModalProps) => {
  const [alertOpen, setAlertOpen] = React.useState(false);
  const [isCompleted, setIsCompleted] = React.useState(false);
  const [errorCode, setErrorCode] = useState<any>('');
  const [isFileUploadCompleted, setIsFileUploadCompleted] = useState(false);
  const [fileUploadHasError, setFileUploadHasError] = useState(false);
  const [uploadStatu, setUploadStatu] = useState('Upload');

  const [fields, setFields] = useState({
    referenceNumber: referenceNumber,
    cargoCarrier: '',
    cargoDocumentUrl: '',
    cargoCode: ''
  });

  const onUpload = () => { };

  React.useEffect(() => {
    if (fileUrl !== undefined && fileUrl !== '' && fileUrl !== fields.cargoDocumentUrl) {
      setIsFileUploadCompleted(true);
      setUploadStatu('Uploaded');
      setFields({
        ...fields,
        cargoDocumentUrl: fileUrl,
      });
    }
  }, [fileUrl]);

  const onFileSelected = (file: File) => {
    if (file && (file.type === 'application/pdf'
    || file.type === 'image/jpeg' || file.type === 'image/jpg' 
    || file.type === 'image/png')) {
      setUploadStatu('Uploading');
      onFileSelect(file);
      setFileUploadHasError(false);
    } else {
      setFileUploadHasError(true);
    }
  };

  const handlePostError = (error: ErrorModel) => {
    if (error && error.errors) {
      error.errors.forEach(error => {
        if (error.errorCode == 'InvalidSalesOrderReferenceNumber') {
          setErrorCode(error.errorCode);
        } else if (error.errorCode == 'CargoCodeAndCargoDocumentUrlCannotBeNullOrEmpty') {
          setErrorCode(error.errorCode);
        } else if (error.errorCode == 'InvalidUrl') {
          setErrorCode(error.errorCode);
        } else if (error.errorCode == 'CargoCarrierNotFound') {
          setErrorCode(error.errorCode);
        } else if (error.errorCode == 'CancelledSalesOrder') {
          setErrorCode(error.errorCode);
        } else if (error.errorCode == 'CargoCarrierShouldNotBeEmptyWhenCargoCodeNotNull') {
          setErrorCode(error.errorCode);
        } else if (error.errorCode == 'SalesOrderDoesNotSupportCargoInfoUpdate') {
          setErrorCode(error.errorCode);
        } else if (error.errorCode == 'DeliveredSalesOrder') {
          setErrorCode(error.errorCode);
        }
        
        // Do not handle codes that is unknown.
      });
    }

    setAlertOpen(true);
  };

  useEffect(() => {
    if (requestInfo?.isCompleted) {
      setIsCompleted(true);
    } else if (requestInfo?.isError) {
      handlePostError(requestInfo.getError);
    }
  }, [requestInfo]);

  const onSuccessCloseDialog = () => {
    onClose();
    setIsCompleted(false);
  };

  const onDismissAlert = () => {
    setErrorCode('');
    setAlertOpen(false)
  };

  const handleOnInputChange = (key: keyof UpdateSalesOrderCargoInfoInputDTO) => (e: any) => {
    setFields({
      ...fields,
      [key]: e.currentTarget.value,
    });
  };

  const handleOnSelectedCargoCompanyChange = (e: any) => {
    setFields({
      ...fields,
      cargoCarrier: e.currentTarget.value,
    });
  };

  const retrieveCargoCarrierOptions = () => {
    const dropDownCargoCarriers: { label: string; value: number }[] = [];
    cargoCarriers.forEach(element => {
      dropDownCargoCarriers.push({ label: element.value, value: element.key });
    });
    return dropDownCargoCarriers;
  };

  const handleSubmit = () => {
    const data: UpdateSalesOrderCargoInfoInputDTO = {
      referenceNumber: fields.referenceNumber,
      cargoCarrier: fields.cargoCarrier,
      cargoCode: fields.cargoCode,
      cargoDocumentUrl: fields.cargoDocumentUrl
    };
    onCreate(data);
  };

  return (
    <Modal isOpen size="2xl" onClose={onClose}>
      <ModalHeader>
        <ModalTitle>{intl.messages[`${COMPONENT_INTL_KEY}.Header.UpdateCargoInfo`]}</ModalTitle>
      </ModalHeader>
      <ModalContent>
        {alertOpen && (
          <Alert variant="danger" isOpen={alertOpen} onDismiss={() => onDismissAlert()}>
            <AlertTitle>{intl.messages['PostErrorMessage.Title']}</AlertTitle>
            <AlertDescription>
              {errorCode
                ? intl.messages[`PostErrorMessage.${errorCode}`]
                : intl.messages['PostErrorMessage.DefaultMessage']}
            </AlertDescription>
          </Alert>
        )}
        <br></br>
        <Form>
          <Flex flexDirection="row" flexWrap="wrap">
            <FormControl
              size="small"
              width={1}
              mb="11"
            >
              <FormLabel>{intl.messages[`${COMPONENT_INTL_KEY}.CargoCarrier`]}</FormLabel>
              <SelectHTML
                placeholder={intl.messages[`${COMPONENT_INTL_KEY}.PlaceHolder.CargoCarrier`]}
                value={fields.cargoCarrier}
                onChange={(e: any) => handleOnSelectedCargoCompanyChange(e)}
                options={retrieveCargoCarrierOptions()}
              />
              <FormErrorMessage></FormErrorMessage>
            </FormControl>
            <FormControl
              size="small"
              width={1}
              mb="11"
            >
              <FormLabel>{intl.messages[`${COMPONENT_INTL_KEY}.CargoCode`]}</FormLabel>
              <Input
                placeholder={intl.messages[`${COMPONENT_INTL_KEY}.PlaceHolder.CargoCode`]}
                value={fields.cargoCode}
                onChange={handleOnInputChange('cargoCode')}
                isDisabled={!!fields.cargoDocumentUrl}
              />
              <FormErrorMessage></FormErrorMessage>
            </FormControl>
            <FormControl size="small" width={1} mb="11">
            <Box mb="22" display="flex" flexDirection="column">
                  <DrawerTitle pt="0" pl="0" size="small">
                    <>
                      {intl.messages[`${"CreateSalesOrderContextualPanel"}.OrderInfoFormHeaders.CargoLabel`]}
                      <Popover
                        isDark
                        content={
                          <ImagePreviewer
                            image="/images/sample/CargoDocument.png"
                            text={intl.messages[`${"CreateSalesOrderContextualPanel"}.OrderInfoFormHeaders.CargoLabelInfo`]}
                          />
                        }
                        placement="right"
                        action={['hover']}
                      >
                        <Image src="/images/information_icon.png" width="20px" marginLeft="5px" marginTop="-5px" />
                      </Popover>
                    </>
                  </DrawerTitle>
                  <Box>
                    <InputGroup>
                      <InputRightAddon
                        as="label"
                        htmlFor={fields.cargoCode ? '' : 'cargoLabelFile'}
                        cursor={fields.cargoCode ? 'not-allowed' : 'pointer'}
                        bg={fields.cargoDocumentUrl === '' ? 'palette.grey' : 'palette.teal'}
                        disabled
                      >
                        <Text fontWeight={800} color="palette.white" fontFamily="heading" fontSize="13">
                          {fields.cargoDocumentUrl && (
                            <Icon name="fas fa-check" fontSize="13" mr="11" color="palette.white" />
                          )}
                          {
                            intl.messages[
                            `${"CreateSalesOrderContextualPanel"}.OrderInfoFormUploadButtons.${uploadStatu}`
                            ]
                          }
                        </Text>
                      </InputRightAddon>
                      <Input
                        value={fields.cargoDocumentUrl}
                        isDisabled={!!fields.cargoCode}
                        placeholder={intl.messages[`${"CreateSalesOrderContextualPanel"}.OrderInfoFormPlaceholders.UploadCargoLabel`]}
                      />
                    </InputGroup>
                  </Box>
                  {fileUploadHasError && (
                    <Text mt="10px" color="red">
                      {intl.messages[`${"CreateSalesOrderContextualPanel"}.OrderInfoFormPlaceholders.FileUploadHasError`]}
                    </Text>
                  )}
                  <Box display="none">
                    <FileUploader
                      id="cargoLabelFile"
                      labelTextKey="Upload"
                      onComplete={onUpload}
                      resetTimeout={1500}
                      intl={intl}
                      isDisabled
                      isBusy={true || isUploading}
                      isCompleted={isFileUploadCompleted}
                      onReset={onFileReset}
                      url={fields.cargoDocumentUrl}
                      onFileSelect={onFileSelected}
                      fileType="application/pdf, image/png, image/jpg, image/jpeg"
                    />
                  </Box>
                </Box>
            </FormControl>
          </Flex>
        </Form>
        <br></br>
        <Flex justifyContent="center" width="100%" px="13px">
          <Button
            className="cancel"
            onClick={onClose}
            kind="outline"
            variant="dark"
            width={1}
            size="small"
          >
            {intl.messages['Form.Action.Cancel']}
          </Button>
          <Button
            disabled={(requestInfo && requestInfo.isBusy)}
            isLoading={(requestInfo && requestInfo.isBusy)}
            ml="6"
            className="save"
            onClick={() => handleSubmit()}
            width={1}
            size="small"
            variant="success"
          >
            {intl.messages['Form.Action.Save']}
          </Button>
        </Flex>
        {/* todo: dialog isn't working */}
        {isCompleted && (
          <Dialog
            type={DialogTypes.Success}
            isOpen
            onApprove={() => onSuccessCloseDialog()}
            text={{ approve: intl.messages[`Modal.Success.Okay`] }}
            message={
              <FormattedMessage
                id={`${COMPONENT_INTL_KEY}.Modal.Success`}
              />
            }
          />
        )}
      </ModalContent>
      <ModalFooter borderTop="xs" borderColor="palette.snow_light">
        <Flex>
          <Icon mr="8px" name="far fa-question-circle" fontSize="21px" />
          <Text color="palette.grey" fontSize="13" lineHeight={1.54}>
            {intl.messages[`${COMPONENT_INTL_KEY}.Warning`]}
          </Text>
        </Flex>
      </ModalFooter>
    </Modal>
  );
};

export default injectIntl(UpdateCargoInfosModal);

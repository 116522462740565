import { GridContainer } from '@components/atoms/GridContainer';
import { barcodeFormatter, geti18nName, ProductDetailsLinkFormatter } from '@containers/formatters';
import { GridProps, GridType } from '@models';
import { ColumnSize, DataGridColumn, DataGridRow, dateTimeFormatter, enumFormatter } from '@oplog/data-grid';
import { ArrayFilterOperation, SortDirection, SortField } from 'dynamic-query-builder-client';
import * as React from 'react';

export interface ReturnLineItemsGridProps extends GridProps {
  returnNo: string;
}

export const ReturnLineItemsGrid: React.FC<ReturnLineItemsGridProps> = ({ intl, returnNo, titleKey }) => {
  const intlKey = 'ReturnLineItemsGrid';

  const returnLineItemsGridInitalSort: SortField = new SortField({
    property: 'sku',
    by: SortDirection.ASC,
  });

  const returnLineItemsGridColumns: DataGridColumn[] = [
    {
      name: '#',
      key: 'index',
      resizable: true,
      locked: true,
      sortable: false,
      type: 'number',
      visibility: true,
      filterable: false,
      width: ColumnSize.Medium,
      cellClass: 'index-column',
    },
    {
      name: geti18nName('sku', intl, intlKey),
      key: 'sku',
      locked: true,
      sortable: true,
      type: 'string',
      visibility: true,
      filterable: true,
      resizable: true,
    },
    {
      name: geti18nName('barcode', intl, intlKey),
      key: 'barcodes',
      locked: true,
      type: 'string',
      visibility: true,
      resizable: true,
      sortable: false,
      filterable: true,
      formatter: barcodeFormatter,
      fieldType: 'array',
      searchField: '_',
      outerOp: ArrayFilterOperation.Any,
    },
    {
      name: geti18nName('name', intl, intlKey),
      key: 'name',
      locked: true,
      sortable: true,
      type: 'string',
      visibility: true,
      filterable: true,
      formatter: ProductDetailsLinkFormatter,
      getRowMetaData: (row: DataGridRow) => row,
      resizable: true,
    },
    {
      name: geti18nName('state', intl, intlKey),
      key: 'state',
      locked: true,
      sortable: true,
      type: 'string',
      formatter: enumFormatter,
      // options: getEnumOptions(intl, ReturnLineItemState),
      visibility: true,
      filterable: true,
      resizable: true,
      getRowMetaData: () => {
        return intl;
      },
    },
    {
      name: geti18nName('processedAt', intl, intlKey),
      key: 'processedAt',
      locked: true,
      sortable: true,
      type: 'moment',
      visibility: true,
      filterable: true,
      resizable: true,
      formatter: dateTimeFormatter,
    },
    {
      name: geti18nName('serialNumber', intl, intlKey),
      key: 'serialNumber',
      locked: true,
      sortable: true,
      type: 'string',
      visibility: true,
      filterable: true,
      getRowMetaData: (row: DataGridRow) => row,
      resizable: true,
    },
  ];

  return (
    <GridContainer
      titleKey={
        titleKey
          ? `${intl.messages['ReturnDetails.Grid.Title']} - ${titleKey}`
          : intl.messages['ReturnDetails.Grid.Title']
      }
      intlKey={intlKey}
      gridKey={GridType.ReturnLineItems}
      columns={returnLineItemsGridColumns}
      predefinedFilters={[]}
      apiArgs={[returnNo]}
      intl={intl}
      sortField={returnLineItemsGridInitalSort}
      actionsDisabled
    />
  );
};

import { CreateReturnContextualPanel, CreateReturnContextualPanelProps } from '@components';
import { ResourceType } from '@models';
import { resourceActions, resourceSelectors } from '@oplog/resource-redux';
import { urls } from '@routes/urls';
import { CreateOmnitroReturnCommand, SalesOrderQueryOutputDTO } from '@services';
import { StoreState } from '@store';
import { CreateReturnAction } from '@store/createReturn';
import { push, RouterAction } from 'connected-react-router';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

export interface CreateReturnContextualPanelContainerProps {
  salesOrder?: SalesOrderQueryOutputDTO;
}

export function mapStateToProps({ resources }: StoreState): Partial<CreateReturnContextualPanelProps> {
  return {
    createReturnResources: resourceSelectors.getResource(resources, ResourceType.CreateReturn),
    returnCountResources: resourceSelectors.getResource(resources, ResourceType.ReturnCountBySalesOrder),
  };
}

export function mapDispatchToProps(
  dispatch: Dispatch<resourceActions.ResourceAction | RouterAction | CreateReturnAction>,
  ownProps: CreateReturnContextualPanelContainerProps
): Partial<CreateReturnContextualPanelProps> {
  return {
    data: ownProps.salesOrder,
    createReturn: (data: CreateOmnitroReturnCommand) => {
      dispatch(resourceActions.resourceRequested(ResourceType.CreateReturn, { data }));
    },
    checkReturnCount: (salesOrderId, startTime) => {
      const data = { salesOrderId, startTime };
      dispatch(resourceActions.resourceRequested(ResourceType.ReturnCountBySalesOrder, data));
    },
    resetResources: () => {
      dispatch(resourceActions.resourceInit(ResourceType.CreateReturn));
      dispatch(resourceActions.resourceInit(ResourceType.ReturnCountBySalesOrder));
    },
    initalCreateReturn: (referenceNumber: string) => {
      dispatch(push(urls.returnDetails.replace(':referenceNumber', `${referenceNumber}`)));
    },
  };
}

export const CreateReturnContextualPanelContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(CreateReturnContextualPanel));

import { GeneralErrorPanel, MainContent, ReturnsGrid } from '@components';
import { MemberPageContainer } from '@containers/MemberPageContainer/MemberPageContainer';
import { ResourceType } from '@models';
import { ActionBar, BarChart, Box, Button, Flex, Icon, Panel, Tab, Widget } from '@oplog/express';
import { Resource } from '@oplog/resource-redux';
import { ReturnLongTermReportOutputDTO, ReturnState, WeeklyReportOutputDTO } from '@services';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Props } from '../../atoms/Component/Component';

export interface ReturnsProps extends Props {
  navigateToCreateReturn: () => void;
  longTermReportResource: Resource<ReturnLongTermReportOutputDTO>;
  weeklyReportResource: Resource<WeeklyReportOutputDTO>;
  onClick: ({ day, state }: { day?: string; state?: string }) => void;
  goTab: (tab: string) => void;
  excelToExport: (resourceType: ResourceType) => void;
  excelToExportInit: () => void;
  excelExportResource: any;
  excelExportDetailedResource: any;
  gridFilters: any;
}

export const COMPONENT_INTL_KEY = 'Returns';

enum ReturnsTabs {
  Returns = 'Returns',
  Damaged = 'Damaged',
}

export const Returns: React.FC<ReturnsProps> = ({
  intl,
  onDidMount,
  navigateToCreateReturn,
  longTermReportResource,
  weeklyReportResource,
  onClick,
  goTab,
  excelToExport,
  excelToExportInit,
  excelExportResource,
  excelExportDetailedResource,
  gridFilters,
}) => {
  const [currentTab, setCurrentTab] = useState(0);

  useEffect(() => {
    onDidMount && onDidMount();
  }, []);

  const renderLongTermInfoBoxes = () => {
    const { isBusy, data, error } = longTermReportResource || { isBusy: false, data: undefined, error: undefined };

    if (error) {
      return <GeneralErrorPanel className="align-left" />;
    }

    return (
      <Flex flexWrap="wrap">
        <Box width={[1, 1, 1, 1 / 2]} pr={['0', '0', '0', '11']} pb="22">
          <Widget.Two
            icon={<Icon color="white" name="fas fa-clock" fontSize="56px" />}
            iconContainerProps={{ bg: 'palette.red' }}
            title={`${data ? data.waitingInWarehouseReturnCount : ''}`}
            titleProps={{ fontSize: !isBusy && '40px', color: 'palette.red' }}
            direction="left"
            textAlign="right"
            subtitle={intl.messages[`${COMPONENT_INTL_KEY}.LongTermReport.PendingReturnsCount`]}
            isLoading={isBusy}
            {...(data &&
              data.waitingInWarehouseReturnCount && {
              onClick: () => onClick({ day: '90', state: ReturnState.ArrivedToWarehouse.toString() }),
            })}
          />
        </Box>
        <Box width={[1, 1, 1, 1 / 2]} pb={['22', '22', '22', '0']} pl={['0', '0', '0', '11']}>
          <Widget.Two
            icon={<Icon color="white" name="fas fa-calendar-check" fontSize="56px" />}
            iconContainerProps={{ bg: 'palette.blue' }}
            title={`${data ? data.todaysReturnCount : ''}`}
            titleProps={{ fontSize: !isBusy && '40px', color: 'palette.grey_darker' }}
            direction="left"
            textAlign="right"
            subtitle={intl.messages[`${COMPONENT_INTL_KEY}.LongTermReport.TodaysReturnCount`]}
            isLoading={isBusy}
            {...(data &&
              data.todaysReturnCount && {
              onClick: () => onClick({ day: '0' }),
            })}
          />
        </Box>
        <Box width={[1, 1, 1, 1 / 2]} pr={['0', '0', '0', '11']} pb="22">
          <Widget.Two
            icon={
              <>
                <Icon name="fa-stack fa-2x" width="56px" height="56px" fontSize="25px">
                  <Icon name="fas fa-calendar fa-stack-2x" color="palette.white" fontSize="56px" />
                  <Icon name="fa-stack-1x" fontSize="25px" paddingTop="18px" color="palette.green" lineHeight="normal">
                    7
                  </Icon>
                </Icon>
              </>
            }
            iconContainerProps={{ bg: 'palette.green' }}
            title={`${data ? data.lastWeeksReturnCount : ''}`}
            titleProps={{ fontSize: !isBusy && '40px', color: 'palette.grey_darker' }}
            direction="left"
            textAlign="right"
            subtitle={intl.messages[`${COMPONENT_INTL_KEY}.LongTermReport.LastWeeksReturnCount`]}
            isLoading={isBusy}
            {...(data &&
              data.lastWeeksReturnCount && {
              onClick: () => onClick({ day: '7' }),
            })}
          />
        </Box>
        <Box width={[1, 1, 1, 1 / 2]} pb={['22', '22', '22', '0']} pl={['0', '0', '0', '11']}>
          <Widget.Two
            icon={
              <>
                <Icon name="fa-stack fa-2x" width="56px" height="56px" fontSize="25px">
                  <Icon name="fas fa-calendar fa-stack-2x" color="palette.white" fontSize="56px" />
                  <Icon name="fa-stack-1x" fontSize="25px" paddingTop="18px" color="palette.orange" lineHeight="normal">
                    30
                  </Icon>
                </Icon>
              </>
            }
            iconContainerProps={{ bg: 'palette.orange' }}
            title={`${data ? data.lastThirtyDays : ''}`}
            titleProps={{ fontSize: !isBusy && '40px', color: 'palette.grey_darker' }}
            direction="left"
            textAlign="right"
            subtitle={intl.messages[`${COMPONENT_INTL_KEY}.LongTermReport.LastMonthsReturnCount`]}
            isLoading={isBusy}
            {...(data &&
              data.lastThirtyDays && {
              onClick: () => onClick({ day: '30' }),
            })}
          />
        </Box>
      </Flex>
    );
  };

  const populateWeeklyReportData = () => {
    const chartData: any[] | undefined = [];
    if (weeklyReportResource?.data) {
      const { report } = weeklyReportResource.data;
      if (report) {
        const count = report.length;
        report.forEach((week, index) => {
          chartData.push({
            name: index == count - 1 ? intl.messages[`${COMPONENT_INTL_KEY}.Days.Today`] : intl.messages[`${COMPONENT_INTL_KEY}.Days.${week.dayOfWeek}`],
            value: week.count,
          });
        });
      }
    }
    return chartData;
  };

  const renderWeeklyChart = (barChartProps: any) => {
    if (weeklyReportResource && weeklyReportResource.error !== undefined) {
      return <GeneralErrorPanel className="align-left" />;
    }
    return (
      <Box mt="40px" ml="-45px">
        <BarChart {...barChartProps} />
      </Box>
    );
  };

  const breadcrumb = [{ title: intl.messages[`${COMPONENT_INTL_KEY}.Header.Title`] }];

  const barChartProps = {
    data: populateWeeklyReportData(),
    columnColor: '#abb4eb',
    height: 208,
    isLegendVisible: false,
    isTooltipVisible: true,
  };

  return (
    <MemberPageContainer
      key="member-page-sales-orders"
      documentTitle={intl.messages[`${COMPONENT_INTL_KEY}.Header.Title`]}
    >
      <ActionBar
        title={intl.messages[`${COMPONENT_INTL_KEY}.Header.Title`]}
        subtitle={intl.messages[`${COMPONENT_INTL_KEY}.Header.Subtitle`]}
        breadcrumb={breadcrumb}
        top="66px"
      >
        <Button
          variant="dark"
          marginLeft="auto"
          onClick={() => {
            navigateToCreateReturn();
          }}
        >
          {intl.messages[`${COMPONENT_INTL_KEY}.CreateReturn`]}
        </Button>
      </ActionBar>

      <MainContent>
        <Flex>
          <Box width={[1, 1, 1 / 2, 1 / 2]} pr="22">
            <Panel
              key="long-term-report"
              className="long-term-report"
              title={intl.messages[`${COMPONENT_INTL_KEY}.LongTermReport.Title`]}
            >
              {renderLongTermInfoBoxes()}
            </Panel>
          </Box>
          <Box
            width={[1, 1, 1 / 2, 1 / 2]}
            pl={['0', '0', '22', '22']}
            pb={['44', '44', '0', '0']}
            pt={['22', '22', '0', '0']}
            pr={['22', '22', '0', '0']}
          >
            <Panel
              key="panel-chart"
              className="chart-panel"
              title={intl.messages[`${COMPONENT_INTL_KEY}.Header.Panel.WeeklyReportTitle`]}
            >
              {renderWeeklyChart(barChartProps)}
            </Panel>
          </Box>
        </Flex>
        <Box mt="22">
          <Tab
            onTabChange={data => {
              setCurrentTab(data);
              goTab(Object.values(ReturnsTabs)[data]);
            }}
            activeIndex={currentTab}
            tabs={[
              {
                id: ReturnsTabs.Returns,
                title: intl.messages[`${COMPONENT_INTL_KEY}.Tabs.Returns`],
                component: (
                  <ReturnsGrid
                    intl={intl}
                    excelToExport={excelToExport}
                    excelExportResource={excelExportResource}
                    excelExportDetailedResource={excelExportDetailedResource}
                    excelToExportInit={excelToExportInit}
                    gridFilters={gridFilters}
                  />
                ),
              },
              // {
              //   id: ReturnsTabs.Damaged,
              //   title: intl.messages[`${COMPONENT_INTL_KEY}.Tabs.Damaged`],
              //   component: <ReturnsDamangedGrid intl={intl} />,
              // },
            ]}
          />
        </Box>
      </MainContent>
    </MemberPageContainer>
  );
};

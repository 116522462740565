import { Box, Link } from '@oplog/express';
import { urls } from '@routes/urls';
import * as React from 'react';
import history from '../../history';

export function packageLinkFormatter(row: any) {
  if (row) {
    var salesOrderReferenceNumber: string = row.value;
    const salesOrderPackageNumber = salesOrderReferenceNumber.substring(
      salesOrderReferenceNumber.lastIndexOf('_') + 1,
      salesOrderReferenceNumber.length
    );
    salesOrderReferenceNumber = salesOrderReferenceNumber.substring(0, salesOrderReferenceNumber.lastIndexOf('_'));
    return (
      <Link
        onClick={() =>
          history.push(`${urls.salesOrderDetails.replace(':referenceNumber', salesOrderReferenceNumber)}`, {
            packageNumber: Number(salesOrderPackageNumber),
          })
        }
      >
        <Box color="text.link">{row.value}</Box>
      </Link>
    );
  }

  return <></>;
}

import { Tabs } from '@containers/CustomerDetailsTabMenuContainer';
import { GridType, ResourceType } from '@models';
import { gridActions } from '@oplog/data-grid';
import { resourceActions, resourceSelectors } from '@oplog/resource-redux';
import { StoreState } from '@store';
import { customerDetailsAction, CustomerDetailsAction, customerDetailsSelectors } from '@store/customerDetails';
import { getRouteParam } from '@utils';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { Dispatch } from 'redux';
import { CustomerDetails, CustomerDetailsProps } from '../../components/pages/CustomerDetails/CustomerDetails';

function mapStateToProps({ resources, customerDetails }: StoreState): Partial<CustomerDetailsProps> {
  return {
    customerDetails: resourceSelectors.getData(resources, ResourceType.CustomerDetails),
    isBusy: resourceSelectors.isBusy(resources, ResourceType.CustomerDetails),
    error: resourceSelectors.getError(resources, ResourceType.CustomerDetails),
    isNewAddressModalOpen: customerDetailsSelectors.isNewCreateShippingAddressOpen(customerDetails),
    isNewAddressCreateSuccess: resourceSelectors.isSuccessComplete(
      resources,
      ResourceType.AddNewCustomerShippingAddress
    ),
    hasError:
      resourceSelectors.hasError(resources, ResourceType.UpdateCustomerInfo) ||
      resourceSelectors.hasError(resources, ResourceType.AddNewCustomerShippingAddress) ||
      resourceSelectors.hasError(resources, ResourceType.UpdateCustomerShippingAddress) ||
      resourceSelectors.hasError(resources, ResourceType.CustomerShippingAddresses) ||
      resourceSelectors.hasError(resources, ResourceType.CustomerInfo),
  };
}

function mapDispatchToProps(
  dispatch: Dispatch<resourceActions.ResourceAction | CustomerDetailsAction | gridActions.GridAction>,
  routeProps: RouteComponentProps
): Partial<CustomerDetailsProps> {
  const customerNo = parseInt(getRouteParam(routeProps, 'customerNo'), 10);
  const tab = getRouteParam(routeProps, 'tab');
  return {
    onDidMount: (): void => {
      dispatch(
        resourceActions.resourceRequested(ResourceType.CustomerDetails, {
          customerNo,
        })
      );
    },
    onWillUnmount: (): void => {
      dispatch(resourceActions.resourceInit(ResourceType.CustomerDetails));
      dispatch(resourceActions.resourceInit(ResourceType.CustomerInfo));
      dispatch(resourceActions.resourceInit(ResourceType.CustomerShippingAddresses));
      dispatch(resourceActions.resourceInit(ResourceType.UpdateCustomerInfo));
      dispatch(resourceActions.resourceInit(ResourceType.UpdateCustomerShippingAddress));
    },
    getUserInfo: (): void => {
      if (routeProps && routeProps.location) {
        dispatch(
          resourceActions.resourceRequested(ResourceType.CustomerDetails, {
            customerNo,
          })
        );
      }
    },
    onCreateShippingAddressSuccessModalClose: () => {
      dispatch(resourceActions.resourceInit(ResourceType.AddNewCustomerShippingAddress));
      if (tab === Tabs.ShippingAddresses) {
        dispatch(gridActions.gridFetchRequested(GridType.CustomerShippingAddresses, [customerNo]));
      }
    },
    onEditShippingAddressSuccessModalClose: () => {
      if (tab === Tabs.ShippingAddresses) {
        dispatch(gridActions.gridFetchRequested(GridType.CustomerShippingAddresses, [customerNo]));
      }
    },
    onNewAddresClick: () => {
      dispatch(customerDetailsAction.openNewAddressModal());
    },
    onNewAddressModalClose: () => {
      dispatch(customerDetailsAction.closeNewAddressModal());
    },
    initResources: () => {
      dispatch(resourceActions.resourceInit(ResourceType.CustomerShippingAddresses));
      dispatch(resourceActions.resourceInit(ResourceType.CustomerInfo));
      dispatch(resourceActions.resourceInit(ResourceType.AddNewCustomerShippingAddress));
      dispatch(resourceActions.resourceInit(ResourceType.UpdateCustomerShippingAddress));
    },
  };
}

export const CustomerDetailsContainer = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(injectIntl(CustomerDetails))
);

import { SupportSalesOrdersGrid } from '@components/organisms/SupportSalesOrdersGrid';
import { SupportSalesOrderCancelModalContainer } from '@containers/SupportSalesOrderCancelModalContainer';
import { SupportSalesOrdersDeleteModalContainer } from '@containers/SupportSalesOrdersDeleteModalContainer';
import { SupportSalesOrdersReActivateCancelledModalContainer } from '@containers/SupportSalesOrdersReActivateCancelledModalContainer';
import { SupportSalesOrdersSyncCancelledIntegrationsModalContainer } from '@containers/SupportSalesOrdersSyncCancelledIntegrationsModalContainer';
import { SupportSalesOrdersSyncUnfulfilledIntegrationsModalContainer } from '@containers/SupportSalesOrdersSyncUnfulfilledIntegrationsModalContainer';
import { CleanMonthlyInvalidSalesOrdersModalContainer } from '@containers/CleanMonthlyInvalidSalesOrdersModalContainer';
import { ActionBar, Box, Button, Flex, LayoutContent, Tab } from '@oplog/express';
import { urls } from '@routes/urls';
import * as React from 'react';
import { useState } from 'react';
import { MemberPageContainer } from '../../../containers/MemberPageContainer/MemberPageContainer';
import { Props } from '../../atoms/Component/Component';
import { CorrectInvalidSalesOrdersLineItemNotFoundModalContainer } from '@containers/CorrectInvalidSalesOrdersLineItemNotFoundModalContainer';

const COMPONENT_INTL_KEY: string = 'SupportSalesOrders';

export interface SupportSalesOrdersProps extends Props {
  closeModalViewer: () => void;
  goTab: (tab: any) => void;
}

enum SalesOrderTabs {
  Orders = 'OrderProgression',
  SyncStatus = 'SyncStatus',
  DataManipulation = 'DataManipulation',
}

export const SupportSalesOrders: React.FC<SupportSalesOrdersProps> = ({
  closeModalViewer,
  intl,
  goTab,
}: SupportSalesOrdersProps) => {
  const [modalStates, setModalStates] = useState({
    isSyncUnfulfilledIntOpen: false,
    isSyncCancelledIntOpen: false,
    isCancelModalOpen: false,
    isDeleteModalOpen: false,
    isReActivateCancelModalOpen: false,
    isCleanMonthlyInvalidSalesOrdersModalOpen: false,
    isCorrectInvalidSalesOrdersLineItemNotFoundModalOpen: false
  });

  const [currentTab, setCurrentTab] = useState(0);

  const syncUnfulfilledIntModalClose = () => {
    setModalStates({ ...modalStates, isSyncUnfulfilledIntOpen: false });
    closeModalViewer();
  };

  const syncCancelledIntModalClose = () => {
    setModalStates({ ...modalStates, isSyncCancelledIntOpen: false });
    closeModalViewer();
  };

  const cleanMonthlyInvalidSalesOrdersModalClose = () => {
    setModalStates({ ...modalStates, isCleanMonthlyInvalidSalesOrdersModalOpen: false });
    closeModalViewer();
  };

  const correctInvalidSalesOrdersLineItemNotFoundModalClose = () => {
    setModalStates({ ...modalStates, isCorrectInvalidSalesOrdersLineItemNotFoundModalOpen: false });
    closeModalViewer();
  };

  const cancelModalClose = () => {
    setModalStates({ ...modalStates, isCancelModalOpen: false });
    closeModalViewer();
  };

  const deleteModalClose = () => {
    setModalStates({ ...modalStates, isDeleteModalOpen: false });
    closeModalViewer();
  };

  const reActivateCancelModalClose = () => {
    setModalStates({ ...modalStates, isReActivateCancelModalOpen: false });
    closeModalViewer();
  };

  const renderSyncUnfulfilledIntModal = () => {
    if (modalStates.isSyncUnfulfilledIntOpen) {
      return <SupportSalesOrdersSyncUnfulfilledIntegrationsModalContainer onClose={syncUnfulfilledIntModalClose} />;
    }
    return null;
  };

  const renderSyncCancelledIntModal = () => {
    if (modalStates.isSyncCancelledIntOpen) {
      return <SupportSalesOrdersSyncCancelledIntegrationsModalContainer onClose={syncCancelledIntModalClose} />;
    }
    return null;
  };

  const renderCleanMonthlyInvalidSalesOrdersModal = () => {
    if (modalStates.isCleanMonthlyInvalidSalesOrdersModalOpen) {
      return <CleanMonthlyInvalidSalesOrdersModalContainer onClose={cleanMonthlyInvalidSalesOrdersModalClose} />;
    }
    return null;
  };

  const renderCorrectInvalidSalesOrdersLineItemNotFoundModal = () => {
    if (modalStates.isCorrectInvalidSalesOrdersLineItemNotFoundModalOpen) {
      return <CorrectInvalidSalesOrdersLineItemNotFoundModalContainer onClose={correctInvalidSalesOrdersLineItemNotFoundModalClose} />;
    }
    return null;
  };

  const renderCancelModal = () => {
    if (modalStates.isCancelModalOpen) {
      return <SupportSalesOrderCancelModalContainer onClose={cancelModalClose} />;
    }
    return null;
  };

  const renderDeleteModal = () => {
    if (modalStates.isDeleteModalOpen) {
      return <SupportSalesOrdersDeleteModalContainer onClose={deleteModalClose} />;
    }
    return null;
  };

  const renderRemovePendingModal = () => {
    if (modalStates.isReActivateCancelModalOpen) {
      return <SupportSalesOrdersReActivateCancelledModalContainer onClose={reActivateCancelModalClose} />;
    }
    return null;
  };

  const breadcrumb = [
    {
      title: intl.messages[`${COMPONENT_INTL_KEY}.Breadcrumb.SupportTitle`],
      url: urls.support,
    },
    {
      title: intl.messages[`${COMPONENT_INTL_KEY}.Breadcrumb.Title`],
    },
  ];

  return (
    <MemberPageContainer documentTitle={intl.messages[`${COMPONENT_INTL_KEY}.Header.Title`]}>
      <ActionBar
        top="66px"
        title={intl.messages[`${COMPONENT_INTL_KEY}.Header.Title`]}
        subtitle=""
        breadcrumb={breadcrumb}
      >
        <Flex marginLeft="auto">
          <Button
            variant="dark"
            size="large"
            dropdown={{
              items: [
                {
                  text: intl.messages[`${COMPONENT_INTL_KEY}.Buttons.Dropdown.CancelSalesOrders`],
                  value: intl.messages[`${COMPONENT_INTL_KEY}.Buttons.Dropdown.CancelSalesOrders`],
                  onClick: () => setModalStates({ ...modalStates, isCancelModalOpen: true }),
                },
                {
                  text: intl.messages[`${COMPONENT_INTL_KEY}.Buttons.Dropdown.SoftDeleteSalesOrders`],
                  value: intl.messages[`${COMPONENT_INTL_KEY}.Buttons.Dropdown.SoftDeleteSalesOrders`],
                  onClick: () => setModalStates({ ...modalStates, isDeleteModalOpen: true }),
                },
                {
                  text: intl.messages[`${COMPONENT_INTL_KEY}.Buttons.Dropdown.SyncUnfulfilled`],
                  value: intl.messages[`${COMPONENT_INTL_KEY}.Buttons.Dropdown.SyncUnfulfilled`],
                  onClick: () => setModalStates({ ...modalStates, isSyncUnfulfilledIntOpen: true }),
                },
                {
                  text: intl.messages[`${COMPONENT_INTL_KEY}.Buttons.Dropdown.SyncCancelled`],
                  value: intl.messages[`${COMPONENT_INTL_KEY}.Buttons.Dropdown.SyncCancelled`],
                  onClick: () => setModalStates({ ...modalStates, isSyncCancelledIntOpen: true }),
                },
                {
                  text: intl.messages[`${COMPONENT_INTL_KEY}.Buttons.Dropdown.CleanMonthlyInvalidSalesOrders`],
                  value: intl.messages[`${COMPONENT_INTL_KEY}.Buttons.Dropdown.CleanMonthlyInvalidSalesOrders`],
                  onClick: () => setModalStates({ ...modalStates, isCleanMonthlyInvalidSalesOrdersModalOpen: true }),
                },
                {
                  text: intl.messages[`${COMPONENT_INTL_KEY}.Buttons.Dropdown.CorrectInvalidSalesOrdersLineItemNotFound`],
                  value: intl.messages[`${COMPONENT_INTL_KEY}.Buttons.Dropdown.CorrectInvalidSalesOrdersLineItemNotFound`],
                  onClick: () => setModalStates({ ...modalStates, isCorrectInvalidSalesOrdersLineItemNotFoundModalOpen: true }),
                },
                // {
                //   text: intl.messages[`${COMPONENT_INTL_KEY}.Buttons.Dropdown.ReActivateCancelledSalesOrders`],
                //   value: intl.messages[`${COMPONENT_INTL_KEY}.Buttons.Dropdown.ReActivateCancelledSalesOrders`],
                //   onClick: () => setModalStates({ ...modalStates, isReActivateCancelModalOpen: true }),
                // },
              ],
            }}
          >
            {intl.messages[`${COMPONENT_INTL_KEY}.Buttons.Title`]}
          </Button>
        </Flex>
      </ActionBar>

      <LayoutContent className="page-content">
        <Box>
          <Tab
            onTabChange={data => {
              setCurrentTab(data);
              goTab(Object.values(SalesOrderTabs)[data]);
            }}
            activeIndex={currentTab}
            tabs={[
              {
                id: SalesOrderTabs.Orders,
                title: intl.messages[`${COMPONENT_INTL_KEY}.Grid.Orders`],
                component: <SupportSalesOrdersGrid intl={intl} type={SalesOrderTabs.Orders} />,
              },
              {
                id: SalesOrderTabs.SyncStatus,
                title: intl.messages[`${COMPONENT_INTL_KEY}.Grid.SyncStatus`],
                component: <SupportSalesOrdersGrid intl={intl} type={SalesOrderTabs.SyncStatus} />,
              },
              {
                id: SalesOrderTabs.DataManipulation,
                title: intl.messages[`${COMPONENT_INTL_KEY}.Grid.DataManipulation`],
                component: <SupportSalesOrdersGrid intl={intl} type={SalesOrderTabs.DataManipulation} />,
              },
            ]}
          />
        </Box>
      </LayoutContent>

      {renderCorrectInvalidSalesOrdersLineItemNotFoundModal()}        
      {renderCleanMonthlyInvalidSalesOrdersModal()}
      {renderSyncUnfulfilledIntModal()}
      {renderSyncCancelledIntModal()}
      {renderCancelModal()}
      {renderDeleteModal()}
      {renderRemovePendingModal()}
    </MemberPageContainer>
  );
};

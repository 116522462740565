import { GridContainer } from '@components/atoms/GridContainer';
import { bubbleStepFormatter, geti18nName, SalesOrderDetailsLinkFormatter } from '@containers/formatters';
import { GridProps, GridType } from '@models';
import { ColumnSize, DataGridColumn, DataGridRow, dateTimeFormatter, getEnumOptions } from '@oplog/data-grid';
import { SalesOrderState } from '@services';
import { SortDirection, SortField } from 'dynamic-query-builder-client';
import * as React from 'react';

const intlKey = 'ProductSalesOrdersGrid';

export interface ProductSalesOrderGridProps extends GridProps {
  sku: string;
}

const productSalesOrderGridInitalSort: SortField = new SortField({
  property: 'orderCreatedAt',
  by: SortDirection.DESC,
});

export class ProductSalesOrderGrid extends React.Component<ProductSalesOrderGridProps> {
  render() {
    const { intl, titleKey } = this.props;
    const productSalesOrderGridColumns: Array<DataGridColumn> = [
      {
        name: '#',
        key: 'index',
        resizable: true,
        locked: true,
        sortable: false,
        type: 'number',
        visibility: true,
        filterable: false,
        width: ColumnSize.Medium,
        cellClass: 'index-column',
      },
      {
        name: geti18nName('referenceNumber', intl, intlKey),
        key: 'referenceNumber',
        locked: true,
        sortable: true,
        type: 'string',
        visibility: true,
        filterable: true,
        formatter: SalesOrderDetailsLinkFormatter,
        getRowMetaData: (row: DataGridRow) => row,
        resizable: true,
      },
      {
        name: geti18nName('state', intl, intlKey),
        key: 'state',
        locked: true,
        sortable: true,
        type: 'enum',
        visibility: true,
        filterable: true,
        formatter: bubbleStepFormatter,
        options: getEnumOptions(intl, SalesOrderState),
        getRowMetaData: (row: DataGridRow) => {
          return {
            intl,
            enum: SalesOrderState,
            row,
          };
        },
        resizable: true,
      },
      {
        name: geti18nName('amountOfProduct', intl, intlKey),
        key: 'amountOfProduct',
        locked: true,
        sortable: true,
        type: 'number',
        visibility: true,
        filterable: true,
        getRowMetaData: (row: DataGridRow) => row,
        resizable: true,
      },
      {
        name: geti18nName('orderCreatedAt', intl, intlKey),
        key: 'orderCreatedAt',
        locked: true,
        sortable: true,
        type: 'moment',
        visibility: true,
        filterable: true,
        resizable: true,
        formatter: dateTimeFormatter,
      },
    ];

    return (
      <GridContainer
        titleKey={
          titleKey
            ? `${intl.messages['ProductSalesOrdersGrid.Grid.Title']} - ${titleKey}`
            : intl.messages['ProductSalesOrdersGrid.Grid.Title']
        }
        intlKey={intlKey}
        remoteExportToExcel
        gridKey={GridType.ProductSalesOrders}
        columns={productSalesOrderGridColumns}
        predefinedFilters={[]}
        apiArgs={[this.props.sku]}
        intl={intl}
        sortField={productSalesOrderGridInitalSort}
      />
    );
  }
}

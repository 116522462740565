import { GridContainer } from '@components/atoms/GridContainer';
import {
  barcodeFormatter,
  geti18nName,
  ProductDetailsLinkFormatter,
  salesOrderDetailBlockedBadgeFormatter,
} from '@containers/formatters';
import { GridProps, GridType } from '@models';
import {
  ColumnSize,
  DataGridColumn,
  DataGridRow,
  enumFormatter,
  getEnumOptions,
  ImageFormatter,
} from '@oplog/data-grid';
import { Button, Icon } from '@oplog/express';
import {
  LineItemOfSalesOrderQueryOutputDTO,
  LineItemOfSalesOrderQueryOutputDTODynamicQueryOutputDTO,
  ProductType,
} from '@services';
import { ArrayFilterOperation } from 'dynamic-query-builder-client';
import * as React from 'react';

const intlKey = 'SalesOrderLineItemsGrid';

export enum OrderStates {
  'LineItems',
  'CancelledItems',
  'WaitingForPackaging',
}

export interface SalesOrderLineItemsGridProps extends GridProps {
  referenceNumber: string;
  isPending: boolean;
  isPackage?: boolean;
  headerContent?: React.ReactNode;
  packageless?: boolean;
  onCancelLineItem?: (rowData: LineItemOfSalesOrderQueryOutputDTO) => void;
  items?: LineItemOfSalesOrderQueryOutputDTODynamicQueryOutputDTO;
  state?: keyof typeof OrderStates;
}

export const SalesOrderLineItemsGrid: React.FC<SalesOrderLineItemsGridProps> = ({
  intl,
  titleKey,
  isPending,
  referenceNumber,
  packageless,
  isPackage,
  headerContent,
  onCancelLineItem,
  items,
  state,
}) => {
  const [cancelledExist, setCancelledExist] = React.useState(false);
  const [missingExist, setMissignExist] = React.useState(false);
  let quantityTitle = 'amountInOrder';
  if (state === 'CancelledItems') quantityTitle = 'cancelledAmountInOrder';
  if (isPackage) quantityTitle = 'amountInPackage';

  const salesOrderLineItemsGridColumns: Array<DataGridColumn> = [
    {
      name: '#',
      key: 'index',
      resizable: true,
      locked: true,
      sortable: false,
      type: 'number',
      visibility: true,
      filterable: false,
      width: ColumnSize.Medium,
      cellClass: 'index-column',
    },
    {
      name: geti18nName('sku', intl, intlKey),
      key: 'sku',
      locked: true,
      sortable: true,
      type: 'string',
      visibility: true,
      filterable: true,
      resizable: true,
    },
    {
      name: geti18nName('barcode', intl, intlKey),
      key: 'barcodes',
      locked: true,
      type: 'string',
      visibility: true,
      resizable: true,
      sortable: false,
      filterable: true,
      formatter: barcodeFormatter,
      fieldType: 'array',
      searchField: '_',
      outerOp: ArrayFilterOperation.Any,
    },
    {
      name: geti18nName('imageUrl', intl, intlKey),
      key: 'imageUrl',
      locked: true,
      sortable: false,
      type: 'string',
      visibility: true,
      filterable: false,
      formatter: ImageFormatter,
      width: ColumnSize.Medium,
    },
    {
      name: geti18nName('productName', intl, intlKey),
      key: 'productName',
      locked: true,
      sortable: true,
      type: 'string',
      visibility: true,
      filterable: true,
      formatter: ProductDetailsLinkFormatter,
      getRowMetaData: (row: DataGridRow) => row,
      resizable: true,
    },
    {
      name: geti18nName('productType', intl, intlKey),
      key: 'productType',
      resizable: true,
      sortable: true,
      type: 'enum',
      visibility: true,
      filterable: true,
      formatter: enumFormatter,
      options: getEnumOptions(intl, ProductType),
      getRowMetaData: () => {
        return intl;
      },
    },
    {
      name: geti18nName('productCategory', intl, intlKey),
      key: 'productCategory',
      resizable: true,
      sortable: true,
      type: 'string',
      visibility: true,
      filterable: true,
    },
    {
      name: geti18nName(quantityTitle, intl, intlKey),
      key: 'amountInOrder',
      resizable: true,
      sortable: true,
      type: 'number',
      visibility: true,
      filterable: true,
    },
    {
      name: geti18nName('cancelledAmount', intl, intlKey),
      key: 'cancelledAmount',
      resizable: true,
      sortable: true,
      type: 'number',
      visibility: cancelledExist,
      filterable: true,
      formatter: props => (props.value > 0 ? props.value : '-'),
      getRowMetaData: (row: DataGridRow) => {
        return { row, intl };
      },
    },
    {
      name: geti18nName('missingQuantity', intl, intlKey),
      key: 'missingQuantity',
      resizable: true,
      sortable: true,
      width: ColumnSize.XLarge,
      type: 'number',
      visibility: missingExist,
      filterable: true,
      formatter: salesOrderDetailBlockedBadgeFormatter,
      getRowMetaData: (row: DataGridRow) => {
        return { row, intl };
      },
    },
    {
      name: geti18nName('Actions', intl, intlKey),
      key: 'id',
      type: 'component',
      filterable: false,
      locked: true,
      resizable: true,
      sortable: false,
      visibility: !isPackage,
      formatter: ({ dependentValues }) =>
        dependentValues.isCancellable ? (
          <Button
            dropdown={{
              icon: <Icon name="fas fa-ellipsis-v" />,
              items: [
                {
                  text: intl.messages[`${intlKey}.Actions.Delete`],
                  value: 'delete',
                  icon: { name: 'fal fa-trash' },
                  onClick: () => {
                    onCancelLineItem && onCancelLineItem(dependentValues);
                  },
                },
              ],
              popoverProps: {
                withPortal: true,
                withArrow: true,
                closeOutside: true,
              },
            }}
            size="small"
            addonProps={{ bg: 'transparent', color: 'text.body' }}
            bg="transparent"
            _hover={{ bg: 'transparent' }}
            _focus={{ outline: '0' }}
            marginLeft="auto"
          />
        ) : null,
      width: ColumnSize.Large,
      getRowMetaData: (row: DataGridRow) => {
        return { ...row };
      },
    },
  ];

  const checkItems = () => {
    if (isPending && items && items.count > 0) {
      // eslint-disable-next-line no-unused-expressions
      items?.data?.find(item => {
        if (item.cancelledAmount > 0) {
          setCancelledExist(true);
        }

        if (item.missingQuantity > 0) {
          setMissignExist(true);
        }
      });
    }
  };

  React.useEffect(() => checkItems(), []);
  React.useEffect(() => {
    checkItems();
  }, [items, isPending]);

  const packageArg = packageless || true;

  const rowRenderer = ({ ...props }: any) => {
    return props.isPendingReason ? 'productStockWarning' : '';
  };

  return (
    <GridContainer
      titleKey={
        titleKey
          ? `${intl.messages['SalesOrderDetails.Grid.Title']} - ${titleKey}`
          : intl.messages['SalesOrderDetails.Grid.Title']
      }
      remoteExportToExcel
      intlKey={intlKey}
      gridKey={isPackage ? GridType.SalesOrderPackageLineItems : GridType.SalesOrderLineItems}
      columns={salesOrderLineItemsGridColumns}
      predefinedFilters={[]}
      filtersDisabled
      apiArgs={isPackage ? [referenceNumber] : [referenceNumber, packageArg]}
      intl={intl}
      headerContent={headerContent}
      rowRenderer={isPending ? rowRenderer : undefined}
    />
  );
};

import { GridContainer } from '@components/atoms/GridContainer';
import { barcodeFormatter, geti18nName, ProductDetailsLinkFormatter } from '@containers/formatters';
import { GridProps, GridType } from '@models';
import {
  ColumnSize,
  DataGridColumn,
  DataGridRow,
  enumFormatter,
  getEnumOptions,
  ImageFormatter,
  PredefinedFilter,
} from '@oplog/data-grid';
import { ProductType } from '@services';
import {
  ArrayFilterOperation,
  BooleanFilter,
  BooleanFilterOperation,
  SortDirection,
  SortField,
} from 'dynamic-query-builder-client';
import * as React from 'react';

const intlKey = 'CustomerOrderLineItemsGrid';

export interface CustomerOrderLineItemsProps extends GridProps {
  customerNo: string;
}

const customerOrderLineItemsGridInitalSort: SortField = new SortField({
  property: 'sku',
  by: SortDirection.ASC,
});

const customerOrderLineItemsGridPredefinedFilters: Array<PredefinedFilter> = [
  {
    filter: new BooleanFilter({
      id: 'Favorite',
      property: 'favorite',
      op: BooleanFilterOperation.Equals,
      value: true,
    }),
    selected: false,
    visibility: true,
  },
];

export class CustomerOrderLineItemsGrid extends React.Component<CustomerOrderLineItemsProps> {
  render() {
    const { intl, titleKey } = this.props;
    const customerOrderLineItemsGridColumns: Array<DataGridColumn> = [
      {
        name: '#',
        key: 'index',
        resizable: true,
        locked: true,
        sortable: false,
        type: 'number',
        visibility: true,
        filterable: false,
        width: ColumnSize.Medium,
        cellClass: 'index-column',
      },
      {
        name: geti18nName('favorite', intl, intlKey),
        key: 'favorite',
        locked: true,
        sortable: false,
        type: 'boolean',
        visibility: false,
        filterable: false,
      },
      {
        name: geti18nName('sku', intl, intlKey),
        key: 'sku',
        resizable: true,
        locked: true,
        sortable: true,
        type: 'string',
        visibility: true,
        filterable: true,
      },
      {
        name: geti18nName('barcode', intl, intlKey),
        key: 'barcodes',
        locked: true,
        type: 'string',
        visibility: true,
        resizable: true,
        sortable: false,
        filterable: true,
        formatter: barcodeFormatter,
        fieldType: 'array',
        searchField: '_',
        outerOp: ArrayFilterOperation.Any,
      },
      {
        name: geti18nName('imageUrl', intl, intlKey),
        key: 'imageUrl',
        locked: true,
        sortable: false,
        type: 'string',
        visibility: true,
        filterable: false,
        formatter: ImageFormatter,
        width: ColumnSize.Medium,
      },
      {
        name: geti18nName('productName', intl, intlKey),
        key: 'productName',
        locked: true,
        sortable: true,
        resizable: true,
        type: 'string',
        visibility: true,
        filterable: true,
        formatter: ProductDetailsLinkFormatter,
        getRowMetaData: (row: DataGridRow) => row,
      },
      {
        name: geti18nName('productType', intl, intlKey),
        key: 'productType',
        locked: true,
        resizable: true,
        sortable: true,
        type: 'enum',
        options: getEnumOptions(intl, ProductType),
        visibility: true,
        filterable: true,
        formatter: enumFormatter,
        getRowMetaData: () => {
          return intl;
        },
      },
      {
        name: geti18nName('productCategory', intl, intlKey),
        key: 'productCategory',
        resizable: true,
        locked: true,
        sortable: true,
        type: 'string',
        visibility: true,
        filterable: true,
      },
      {
        name: geti18nName('totalSalesCount', intl, intlKey),
        key: 'totalSalesCount',
        locked: true,
        resizable: true,
        sortable: true,
        type: 'number',
        visibility: true,
        filterable: true,
      },
    ];

    return (
      <GridContainer
        intl={intl}
        titleKey={
          titleKey
            ? `${intl.messages['CustomerOrderLineItemsGrid.Grid.Title']} - ${titleKey}`
            : intl.messages['CustomerOrderLineItemsGrid.Grid.Title']
        }
        intlKey={intlKey}
        remoteExportToExcel
        gridKey={GridType.CustomerSalesOrderLineItems}
        columns={customerOrderLineItemsGridColumns}
        predefinedFilters={customerOrderLineItemsGridPredefinedFilters}
        apiArgs={[this.props.customerNo]}
        sortField={customerOrderLineItemsGridInitalSort}
      />
    );
  }
}

import { Alert, AlertDescription, AlertIcon, AlertTitle, Box, Button, Flex, FormControl, FormLabel, Icon, Modal, ModalContent, ModalFooter, ModalHeader, Select, Text } from '@oplog/express';
import useCommonDataStore from '@store/commonData/commonDataState';
import useSupportOrdersState from '@store/supportPage/SupportSalesOrdersState';
import React, { ReactElement, useEffect, useState } from 'react';
import { Props } from '../../atoms/Component/Component';

export interface CleanMonthlyInvalidSalesOrdersModalProps extends Props {
    onClose: () => void;
    hasError: any;
    responseData: any;
    COMPONENT_INTL_KEY: string;
    setIntegration: (integrationId: String) => void;
}

export const CleanMonthlyInvalidSalesOrdersModal: React.FC<CleanMonthlyInvalidSalesOrdersModalProps> = ({
    onClose,
    COMPONENT_INTL_KEY,
    setIntegration,
    intl,
    isBusy,
    onDidMount,
    onWillUnmount,
}): ReactElement => {
    const [state, actions] = useSupportOrdersState();
    const [{ integrations }] = useCommonDataStore();
    const [integrationId, setIntegrationId] = useState('');
    const [modalState, setModalState] = useState({
        confirmationModalOpen: false,
        cleanMonthlyInvalidSalesOrdersModalOpen: true,
    });

    React.useEffect(() => {
        onDidMount && onDidMount();
    }, []);

    const onSubmit = () => {
        const integrationID = integrationId.split('/')[0];
        setIntegration(integrationID);
        setModalState({
            ...modalState,
            cleanMonthlyInvalidSalesOrdersModalOpen: false,
            confirmationModalOpen: true,
        });
    };

    const closeModal = () => {
        onWillUnmount && onWillUnmount();
        actions.clearSyncModal();
        onClose();
    };

    const changeIntegration = (e: any) => {
        if (e != undefined && e.currentTarget != undefined && e.currentTarget.value != undefined) {
            setIntegrationId(e.currentTarget.value);
        }
    };

    const closeConfirmationDialog = () => {
        setModalState({
            ...modalState,
            confirmationModalOpen: false,
            cleanMonthlyInvalidSalesOrdersModalOpen: false,
        });
        closeModal();
    };

    var filtered = integrations && integrations.filter((item: any) => item.state === 'Active');
    var options: any = [];
    if (filtered) {
        var options = filtered.map((integration: any) => {
            return { value: integration.id + '/' + integration.name, label: integration.name };
        });
    }

    return (
        <Box>
            <Modal onClose={closeModal} isOpen={modalState.cleanMonthlyInvalidSalesOrdersModalOpen}>
                <ModalHeader>{intl.messages[`${COMPONENT_INTL_KEY}.Title`]}</ModalHeader>

                <ModalContent>
                    <Flex flexDirection="column" flexWrap="wrap">
                        <FormLabel>{intl.messages[`${COMPONENT_INTL_KEY}.Integrations`]}</FormLabel>
                        <FormControl size="large">
                            <Select
                                placeholder={intl.messages[`${COMPONENT_INTL_KEY}.Placeholders.Int`]}
                                value={integrationId}
                                options={options}
                                onChange={(e: any) => changeIntegration(e)}
                            />
                        </FormControl>
                        <br></br>
                        <Flex justifyContent="center" width="100%" px="13px">
                            <Button className="cancel" onClick={onClose} kind="outline" variant="dark" width={1} size="small">
                                {intl.messages[`${COMPONENT_INTL_KEY}.CancelButton`]}
                            </Button>
                            <Button
                                ml="6"
                                disabled={integrationId === ''}
                                className="save"
                                onClick={onSubmit}
                                width={1}
                                size="small"
                                variant="success"
                            >
                                {intl.messages[`${COMPONENT_INTL_KEY}.ApproveButton`]}
                            </Button>
                        </Flex>
                    </Flex>
                </ModalContent>
                <ModalFooter borderTop="xs" borderColor="palette.snow_light">
                    <Flex>
                        <Icon mr="8px" name="far fa-question-circle" fontSize="21px" />
                        <Text color="palette.grey" fontSize="13" lineHeight={1.54}>
                            {intl.messages[`${COMPONENT_INTL_KEY}.Note`]}
                        </Text>
                    </Flex>
                </ModalFooter>
            </Modal>

            <Modal isOpen={modalState.confirmationModalOpen && !isBusy} onClose={closeConfirmationDialog}>
                <ModalContent>
                    <Flex flexDirection="column" flexWrap="wrap">
                        <FormControl size="large" m="16">
                            <FormLabel>{intl.messages[`${COMPONENT_INTL_KEY}.DataSent`]}</FormLabel>
                            <Alert variant={'primary'} isOpen={true}>
                                <AlertIcon name="fas fa-exclamation-triangle" />
                                <AlertTitle>{intl.messages[`${COMPONENT_INTL_KEY}.Alert.Success`]}</AlertTitle>
                                <AlertDescription>{intl.messages[`${COMPONENT_INTL_KEY}.Alert.Message`]}</AlertDescription>
                            </Alert>
                        </FormControl>
                    </Flex>
                    <br />
                    <Flex justifyContent="center" width="100%" px="13px">
                        <Button
                            ml="6"
                            disabled={false}
                            className="save"
                            onClick={closeConfirmationDialog}
                            width={1}
                            size="small"
                            variant="success"
                        >
                            {intl.messages[`${COMPONENT_INTL_KEY}.Alert.ApproveButton`]}
                        </Button>
                    </Flex>
                </ModalContent>
            </Modal>
        </Box>

    );
};

import { Props } from '../components/atoms/Component/Component';

export enum GridType {
  Products = 'products',
  ProductsExportToExcel = 'productsExportToExcel',
  ProductReceivingOrders = 'productReceivingOrders',
  ProductQuarantine = 'productQuarantine',
  ProductQuarantineExportToExcel = 'productQuarantineExportToExcel',
  Customers = 'customers',
  CustomersExportToExcel = 'customersExportToExcel',
  InventoryItems = 'inventoryItems',
  InventoryItemExpirationDates = 'inventoryItemExpirationDates',
  InventoryItemExpirationDatesExportToExcel = 'inventoryItemExpirationDatesExportToExcel',
  InventoryItemSerialNumbers = 'inventoryItemSerialNumbers',
  InventoryItemSerilaNumbersExportToExcel = 'inventoryItemSerialNumbersExportToExcel',
  InventoryItemsExportToExcel = 'inventoryItemsExportToExcel',
  InventoryQuarantineExportToExcel = 'inventoryQuarantineExportToExcel',
  InventoryQuarantine = 'inventoryQuarantine',
  SalesOrders = 'salesOrders',
  SalesOrdersReturnable = 'salesOrdersReturnable',
  SalesOrdersExportToExcel = 'salesOrdersExportToExcel',
  SalesOrderQuarantine = 'salesOrderQuarantine',
  SalesOrderQuarantineExportToExcel = 'salesOrderQuarantineExportToExcel',
  SalesOrderBlocked = 'salesOrderBlocked',
  SalesOrdersGetStockQuantityDetailsUsedInBlocked = 'salesOrdersGetStockQuantityDetailsUsedInBlocked',
  SalesOrderBlockedExportToExcel = 'salesOrderBlockedExportToExcel',
  ProductSalesOrders = 'productSalesOrders',
  CustomerSalesOrders = 'customerSalesOrders',
  CustomerSalesOrderLineItems = 'customerSalesOrderLineItems',
  ProductSalesOrderCustomers = 'productSalesOrderCustomers',
  ProductExpirationDates = 'productExpirationDates',
  ProductExpirationDatesExportToExcel = 'productExpirationDatesExportToExcel',
  ProductSerialNumbers = 'productSerialNumbers',
  ProductSerialNumbersExportToExcel = 'productSerialNumbersExportToExcel',
  SalesOrderLineItems = 'salesOrderLineItems',
  SalesOrderSerialNumbers = 'salesOrderSerialNumbers',
  InvalidSalesOrderLineItems = 'invalidSalesOrderLineItems',
  SalesOrderPackageLineItems = 'salesOrderPackageLineItems',
  SalesOrderRecentLineItems = 'salesOrderRecentLineItems',
  ReceivingOrders = 'receivingOrders',
  ReceivingOrdersExportToExcel = 'receivingOrdersExportToExcel',
  ReceivingOrderLineItems = 'receivingOrderLineItems',
  ReceivingOrderLineItemExpirationDates = 'receivingOrderLineItemExpirationDates',
  ReceivingOrderLineItemsExportToExcel = 'receivingOrderLineItemsExportToExcel',
  Returns = 'returns',
  ReturnsExportToExcel = 'returnsExportToExcel',
  ReturnsDamaged = 'returnsDamaged',
  ReturnLineItems = 'returnLineItems',
  CustomerShippingAddresses = 'customerShippingAddresses',
  PaymentsDetails = 'paymentsDetails',
  Users = 'users',
  SupportProducts = 'supportProducts',
  SupportReceivingOrders = 'supportReceivingOrders',
  SupportSalesOrders = 'supportSalesOrders',
  SupportPackages = 'supportPackages',
  ProductSalesOrdersExportToExcel = 'productSalesOrdersExportToExcel',
  ProductSalesOrderCustomersExportToExcel = 'productSalesOrderCustomersExportToExcel',
  ProductReceivingOrdersExportToExcel = 'productReceivingOrdersExportToExcel',
  CustomerSalesOrdersExportToExcel = 'customerSalesOrdersExportToExcel',
  CustomerSalesOrderLineItemsExportToExcel = 'customerSalesOrderLineItemsExportToExcel',
  CustomerShippingAddressesExportToExcel = 'customerShippingAddressesExportToExcel',
  SalesOrderPackageLineItemsExportToExcel = 'salesOrderPackageLineItemsExportToExcel',
  SalesOrderLineItemsExportToExcel = 'salesOrderLineItemsExportToExcel',
  SalesOrderSerialNumbersExportToExcel = 'salesOrderSerialNumbersExportToExcel',
  ReceivingOrderLineItemExpirationDatesExportToExcel = 'receivingOrderLineItemExpirationDatesExportToExcel',
  ReceivingOrderLineItemSerialNumbers = 'receivingOrderLineItemSerialNumbers',
  ReceivingOrderLineItemSerialNumbersExportToExcel = 'receivingOrderLineItemSerialNumbersExportToExcel',
}

export interface GridProps extends Props {
  titleKey?: string;
}

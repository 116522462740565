import { Resource } from '@oplog/resource-redux';
import { InjectedIntl } from 'react-intl';
import { OptionProps } from '@oplog/express';

export const DropdownOptionsFromResource = (
  resource: Resource<string[]>
): { value: string; label: string; key: string }[] => {
  if (!resource || !resource.data || resource.isBusy) {
    return [];
  } 
  return resource.data.map((val: string) => ({
    value: val,
    label: val,
    key: val,
  }));
};

export function generatorRandomID(): string {
  function _p8(s?: boolean) {
    const p = `${Math.random().toString(16)}000000000`.substr(2, 8);
    return s ? `-${p.substr(0, 4)}-${p.substr(4, 4)}` : p;
  }
  return (_p8() + _p8(true) + _p8(true) + _p8()).toString();
}

export function enumToDropdownData(enumObject: any, intl?: InjectedIntl, intlKey?: string): OptionProps[] {
  const data: OptionProps[] = [];

  Object.keys(enumObject).map((item: string) => {
    data.push({
      key: enumObject[item],
      label: intl ? intl.messages[`${intlKey}.${enumObject[item]}`] : enumObject[item],
      value: enumObject[item],
    });
  });
  return data;
}

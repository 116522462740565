import { GridType, ResourceType } from '@models';
import { gridActions } from '@oplog/data-grid';
import { validation } from '@oplog/express';
import { resourceActions, resourceSelectors } from '@oplog/resource-redux';
import { Settings, SettingsProps } from '@pages/Settings';
import { ChangeUserTypeCommand, CreateUserCommand, DeleteUserCommand, UpdateCompanyCommand } from '@services';
import { StoreState } from '@store';
import { Dispatch } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

export function mapStateToProps({ resources }: StoreState): Partial<SettingsProps> {
  return {
    isCreateSuccesful: resourceSelectors.isSuccessComplete(resources, ResourceType.CreateUser),
    isBusy:
      resourceSelectors.isBusy(resources, ResourceType.CreateUser) ||
      resourceSelectors.isBusy(resources, ResourceType.ChangeUserType) ||
      resourceSelectors.isBusy(resources, ResourceType.DeleteUser) ||
      resourceSelectors.isBusy(resources, ResourceType.ChangeUserInfo) ||
      resourceSelectors.isBusy(resources, ResourceType.ChangeCompanyInfo),
    isError: resourceSelectors.isErrorComplete(resources, ResourceType.CreateUser),
    isChangeSuccessful: resourceSelectors.isSuccessComplete(resources, ResourceType.ChangeUserType),
    userData: resources[ResourceType.GetUserDetail],
    companyData: resources[ResourceType.GetCompanyDetail],
    isDeleteSuccessful: resourceSelectors.isSuccessComplete(resources, ResourceType.DeleteUser),
    isDeleteError: resourceSelectors.isErrorComplete(resources, ResourceType.DeleteUser),
    isUpdateUserSuccessful: resourceSelectors.isSuccessComplete(resources, ResourceType.ChangeUserInfo),
    isUpdateCompanySuccessful: resourceSelectors.isSuccessComplete(resources, ResourceType.ChangeCompanyInfo),
    createError: resourceSelectors.getError(resources, ResourceType.CreateUser),
  };
}

export function mapDispatchToProps(
  dispatch: Dispatch<resourceActions.ResourceAction | gridActions.GridFetchRequested>
): Partial<SettingsProps> {
  const refreshCompanyDetail = () => {
    dispatch(resourceActions.resourceRequested(ResourceType.GetCompanyDetail));
  };
  const refreshGrid = () => {
    dispatch(gridActions.gridFetchRequested(GridType.Users));
  };
  const resourcesRequest = () => {
    refreshCompanyDetail();
    refreshGrid();
  };
  return {
    initResource: () => {
      dispatch(resourceActions.resourceInit(ResourceType.CreateUser));
      dispatch(resourceActions.resourceInit(ResourceType.ChangeUserType));
      dispatch(resourceActions.resourceInit(ResourceType.DeleteUser));
      dispatch(resourceActions.resourceInit(ResourceType.ChangeUserInfo));
      dispatch(resourceActions.resourceInit(ResourceType.ChangeCompanyInfo));
    },
    updateUser: (form: any, userID: string) => {
      if (userID) {
        const command = { ...form, userId: userID };
        dispatch(resourceActions.resourceRequested(ResourceType.ChangeUserInfo, { command }));
      }
    },
    updateCompany: (form: UpdateCompanyCommand) => {
      dispatch(resourceActions.resourceRequested(ResourceType.ChangeCompanyInfo, { form }));
    },
    refreshList: () => {
      dispatch(gridActions.gridFetchRequested(GridType.Users));
    },
    onCreate: (form: CreateUserCommand) => {
      dispatch(resourceActions.resourceRequested(ResourceType.CreateUser, { form }));
    },
    onChangeType: (form: ChangeUserTypeCommand) => {
      dispatch(resourceActions.resourceRequested(ResourceType.ChangeUserType, { form }));
    },
    onDelete: (form: DeleteUserCommand) => {
      dispatch(resourceActions.resourceRequested(ResourceType.DeleteUser, { form }));
    },
    onWillUnmount: () => {
      dispatch(resourceActions.resourceInit(ResourceType.CreateUser));
      dispatch(resourceActions.resourceInit(ResourceType.ChangeUserType));
      dispatch(resourceActions.resourceInit(ResourceType.GetUserDetail));
      dispatch(resourceActions.resourceInit(ResourceType.DeleteUser));
      dispatch(resourceActions.resourceInit(ResourceType.GetCompanyDetail));
      dispatch(resourceActions.resourceInit(ResourceType.ChangeUserInfo));
    },
    initComponent: (userInfo: any) => {
      resourcesRequest();
      const auth0Id = userInfo.sub;
      if (auth0Id) {
        dispatch(resourceActions.resourceRequested(ResourceType.GetUserDetail, { userId: auth0Id }));
      }
    },
    refreshUserDetail: (userInfo: any) => {
      const auth0Id = userInfo.sub;
      if (auth0Id) {
        dispatch(resourceActions.resourceRequested(ResourceType.GetUserDetail, { userId: auth0Id }));
      }
    },
    refreshCompanyDetail,
    refreshGrid,
  };
}

export const SettingsContainer = connect(mapStateToProps, mapDispatchToProps)(validation(injectIntl(Settings)));

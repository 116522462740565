import {
  Button,
  Dialog,
  DialogTypes,
  Flex,
  Form,
  FormControl,
  FormErrorMessage,
  FormLabel,
  FormValidator,
  Icon,
  Input,
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  Text,
} from '@oplog/express';
import { Resource } from '@oplog/resource-redux';
import { CustomerInfoOutputDTO, UpdateCustomerInfoCommand } from '@services';
import { phoneValidation } from '@utils';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import useForceUpdate from 'use-force-update';
import * as Yup from 'yup';
import { Props } from '../../atoms/Component/Component';

const COMPONENT_INTL_KEY = 'EditCustomerInfoForm';

export interface EditCustomerInfoFormProps extends Props {
  validator: FormValidator;
  onModalClose: () => void;
  onSubmit: (form: Partial<UpdateCustomerInfoCommand>) => void;
  userInfo: Resource<CustomerInfoOutputDTO>;
  commandIsBusy: boolean;
  isCompleted: boolean;
  initResources: () => void;
  onSuccessClose: () => void;
}

export interface EditCustomerInfoFormState {
  fields: CustomerInfoOutputDTO;
}

export const EditCustomerInfoForm: React.FC<EditCustomerInfoFormProps> = ({
  intl,
  validator,
  onModalClose,
  onSubmit,
  userInfo,
  commandIsBusy,
  isCompleted,
  initResources,
  onSuccessClose,
  onDidMount,
}) => {
  const forceUpdate = useForceUpdate();

  const [fields, setFields] = useState({
    customerNo: -1,
    id: '-1',
    email: '',
    firstName: '',
    lastName: '',
    phone: '',
  });

  const [initalStateReceived, setInitalStateReceived] = useState(false);

  /*
      This is done because previous data needs to be validated.
      We used to allow phone numbers such as: 05323332345, but now we require
      country code. So prepareValidation() also checks for that. 
  */

  useEffect(() => {
    validator.registerSchema(editCustomerInfoFormSchema());
    onDidMount && onDidMount();

    return () => {
      validator.clearErrors();
      initResources();
    };
  }, []);

  useEffect(() => {
    if (userInfo && !userInfo.isBusy && userInfo.data) {
      setFields({
        ...userInfo.data,
      });
    }
  }, [userInfo]);

  useEffect(() => {
    if (fields.customerNo !== -1 && !initalStateReceived) {
      prepareValidation();
      setInitalStateReceived(true);
    }
  }, [fields]);

  const editCustomerInfoFormSchema = () => {
    return {
      firstName: Yup.string().required(`${intl.messages[`${COMPONENT_INTL_KEY}.Error.Customer.Name`]}`),
      lastName: Yup.string().required(`${intl.messages[`${COMPONENT_INTL_KEY}.Error.Customer.Lastname`]}`),
      phone: phoneValidation(
        `${intl.messages[`${COMPONENT_INTL_KEY}.Error.Customer.PhoneEmpty`]}`,
        `${intl.messages[`${COMPONENT_INTL_KEY}.Error.Customer.PhoneLength`]}`,
        `${intl.messages[`${COMPONENT_INTL_KEY}.Error.Customer.PhoneType`]}`
      ),
    };
  };

  const prepareValidation = () => {
    const fieldKeys = ['firstName', 'lastName', 'phone'];
    fieldKeys.forEach(key => {
      validator.validate(key, fields[key]);
    });
  };

  const handleOnInputChange = (key: keyof UpdateCustomerInfoCommand) => (e: any) => {
    setFields({
      ...fields,
      [key]: e.currentTarget.value,
    });
    validator.validate(key, e.currentTarget.value);
    forceUpdate();
  };

  const onSuccessCloseDialog = () => {
    initResources();
    onSuccessClose();
    onModalClose();
  };

  const handleSubmit = () => {
    const command: UpdateCustomerInfoCommand = {
      customerNo: fields.customerNo,
      firstName: fields.firstName,
      lastName: fields.lastName,
      phone: fields.phone,
    };
    onSubmit(command);
  };

  return (
    <Modal isOpen size="md" onClose={onModalClose}>
      <ModalHeader>
        <ModalTitle>{intl.messages[`${COMPONENT_INTL_KEY}.Header.CustomerInfo`]}</ModalTitle>
      </ModalHeader>
      <ModalContent>
        <Form>
          <Flex flexDirection="row" flexWrap="wrap">
            <FormControl
              isDisabled={(userInfo && userInfo.isBusy) || commandIsBusy}
              size="small"
              isInvalid={!!validator.getErrorIntent('firstName')}
              width={1}
              mb="11"
            >
              <FormLabel>{intl.messages[`${COMPONENT_INTL_KEY}.CustomerName`]}*</FormLabel>
              <Input
                placeholder={intl.messages[`${COMPONENT_INTL_KEY}.PlaceHolder.CustomerName`]}
                onChange={handleOnInputChange('firstName')}
                value={fields.firstName}
              />
              <FormErrorMessage>{validator.getErrorIntent('firstName')?.text}</FormErrorMessage>
            </FormControl>
            <FormControl
              isDisabled={(userInfo && userInfo.isBusy) || commandIsBusy}
              isInvalid={!!validator.getErrorIntent('lastName')}
              size="small"
              width={1}
              mb="11"
            >
              <FormLabel>{intl.messages[`${COMPONENT_INTL_KEY}.CustomerLastname`]}*</FormLabel>
              <Input
                placeholder={intl.messages[`${COMPONENT_INTL_KEY}.PlaceHolder.CustomerLastname`]}
                onChange={handleOnInputChange('lastName')}
                value={fields.lastName}
              />
              <FormErrorMessage>{validator.getErrorIntent('lastName')?.text}</FormErrorMessage>
            </FormControl>
            <FormControl isDisabled size="small" width={1} mb="11">
              <FormLabel>{intl.messages[`${COMPONENT_INTL_KEY}.CustomerEmail`]}*</FormLabel>
              <Input
                placeholder={intl.messages[`${COMPONENT_INTL_KEY}.PlaceHolder.CustomerEmail`]}
                value={fields.email}
              />
            </FormControl>
            <FormControl
              isDisabled={(userInfo && userInfo.isBusy) || commandIsBusy}
              isInvalid={!!validator.getErrorIntent('phone')}
              size="small"
              width={1}
              mb="11"
            >
              <FormLabel>{intl.messages[`${COMPONENT_INTL_KEY}.CustomerPhoneNumber`]}*</FormLabel>
              <Input
                placeholder={intl.messages[`${COMPONENT_INTL_KEY}.PlaceHolder.CustomerPhoneNumber`]}
                onChange={handleOnInputChange('phone')}
                value={fields.phone}
              />
              <FormErrorMessage>{validator.getErrorIntent('phone')?.text}</FormErrorMessage>
            </FormControl>
          </Flex>
        </Form>
        <Flex justifyContent="center" width="100%" px="13px">
          <Button
            className="cancel"
            disabled={commandIsBusy}
            onClick={onModalClose}
            kind="outline"
            variant="dark"
            width={1}
            size="small"
          >
            {intl.messages['Form.Action.Cancel']}
          </Button>
          <Button
            ml="6"
            disabled={(userInfo && userInfo.isBusy) || commandIsBusy || validator.hasErrors()}
            isLoading={(userInfo && userInfo.isBusy) || commandIsBusy}
            className="save"
            onClick={() => handleSubmit()}
            width={1}
            size="small"
            variant="success"
          >
            {intl.messages['Form.Action.Save']}
          </Button>
        </Flex>
        {/* todo: dialog isn't working */}
        {isCompleted && (
          <Dialog
            type={DialogTypes.Success}
            isOpen
            onApprove={() => onSuccessCloseDialog()}
            text={{ approve: intl.messages[`Modal.Success.Okay`] }}
            message={
              <FormattedMessage
                id={`${COMPONENT_INTL_KEY}.Modal.Success`}
                values={{
                  CustomerName: (
                    <b>
                      {fields.firstName} {fields.lastName}
                    </b>
                  ),
                }}
              />
            }
          />
        )}
      </ModalContent>
      <ModalFooter borderTop="xs" borderColor="palette.snow_light">
        <Flex>
          <Icon mr="8px" name="far fa-question-circle" fontSize="21px" />
          <Text color="palette.grey" fontSize="13" lineHeight={1.54}>
            {intl.messages[`${COMPONENT_INTL_KEY}.Warning`]}
          </Text>
        </Flex>
      </ModalFooter>
    </Modal>
  );
};

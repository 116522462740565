export enum Scopes {
  SystemAdmin = 'SystemAdmin',
  TenantAdmin = 'TenantAdmin',
  TenantUser = 'TenantUser',
  WarehouseAdmin = 'WarehouseAdmin',
}

export const ModuleScopes = {
  Home: [Scopes.TenantUser],
  Integrations: [Scopes.TenantUser],
  SalesOrders: [Scopes.TenantUser],
  SalesOrderDetails: [Scopes.TenantUser],
  CreateSalesOrder: [Scopes.TenantUser],
  Products: [Scopes.TenantUser],
  ProductDetails: [Scopes.TenantUser],
  Inventory: [Scopes.TenantUser],
  ReceivingOrders: [Scopes.TenantUser],
  ReceivingOrderDetails: [Scopes.TenantUser],
  Returns: [Scopes.TenantUser],
  Warehouse: [Scopes.TenantUser],
  Customers: [Scopes.TenantUser],
  CustomerDetails: [Scopes.TenantUser],
  CreateCustomer: [Scopes.TenantUser],
  CreateReturn: [Scopes.TenantUser],
  Settings: [Scopes.TenantUser],
  CreateTenant: [Scopes.SystemAdmin],
  Support: [Scopes.SystemAdmin],
  SupportPage: [Scopes.SystemAdmin],
  TenantProducts: [Scopes.SystemAdmin],
};

import { ResourceType } from '@models';
import { validation } from '@oplog/express';
import { resourceActions, resourceSelectors } from '@oplog/resource-redux';
import { Integrations, IntegrationsProps } from '@pages/Integrations';
import { DeleteIntegrationCommand, IntegrationType, SyncIntegrationCommand, UpdateIntegrationCommand } from '@services';
import { StoreState } from '@store';
import { FeedBackModalAction, openFeedbackModal } from '@store/feedbackModal';
import { RouterAction } from 'connected-react-router';
import { Dispatch } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

export function mapStateToProps({ resources }: StoreState): Partial<IntegrationsProps> {
  return {
    integrationState: {
      isBusy: resourceSelectors.isBusy(resources, ResourceType.GetIntegrations),
      error: resourceSelectors.getError(resources, ResourceType.GetIntegrations),
    },
    updateIntegrationResource: {
      data: resourceSelectors.getData(resources, ResourceType.UpdateIntegration),
      isBusy: resourceSelectors.isBusy(resources, ResourceType.UpdateIntegration),
      error: resourceSelectors.getError(resources, ResourceType.UpdateIntegration),
    },
    deleteIntegrationResource: {
      data: resourceSelectors.getData(resources, ResourceType.DeleteIntegration),
      isBusy: resourceSelectors.isBusy(resources, ResourceType.DeleteIntegration),
      error: resourceSelectors.getError(resources, ResourceType.DeleteIntegration),
    },
    isDeleteIntegrationSuccessful: resourceSelectors.isSuccessComplete(resources, ResourceType.DeleteIntegration),
    isUpdateIntegrationsSuccessful: resourceSelectors.isSuccessComplete(resources, ResourceType.UpdateIntegration),
    createIntegrationError: resourceSelectors.getError(resources, ResourceType.CreateIntegration),
    cargoCarriersOutput: resourceSelectors.getData(resources, ResourceType.CargoCarriers),
    getIntegrationPayloadResource: {
      data: resourceSelectors.getData(resources, ResourceType.GetIntegrationPayload),
      isBusy: resourceSelectors.isBusy(resources, ResourceType.GetIntegrationPayload),
      error: resourceSelectors.getError(resources, ResourceType.GetIntegrationPayload),
      isSuccess: resourceSelectors.isSuccessComplete(resources, ResourceType.GetIntegrationPayload),
    },
  };
}

export function mapDispatchToProps(
  dispatch: Dispatch<resourceActions.ResourceAction | RouterAction | FeedBackModalAction>
): Partial<IntegrationsProps> {
  const initResources = () => {
    dispatch(resourceActions.resourceInit(ResourceType.DeleteIntegration));
    dispatch(resourceActions.resourceInit(ResourceType.UpdateIntegration));
  };

  return {
    feedbackModalOpen: (subject?: string) => {
      dispatch(openFeedbackModal(subject));
    },

    onWillUnmount: () => initResources(),
    onDeleteIntegration: (command: DeleteIntegrationCommand) => {
      initResources();
      dispatch(
        resourceActions.resourceRequested(ResourceType.DeleteIntegration, {
          command,
        })
      );
    },
    onDeleteIntegrationSuccess: () => {
      dispatch(resourceActions.resourceInit(ResourceType.DeleteIntegration));
      window.location.reload();
      //dispatch(resourceActions.resourceRequested(ResourceType.GetIntegrations));
    },
    onUpdateIntegration: (command: UpdateIntegrationCommand) => {
      initResources();
      dispatch(
        resourceActions.resourceRequested(ResourceType.UpdateIntegration, {
          command,
        })
      );
    },
    onGetIntegrationPayload: (integrationId: string, integrationType: IntegrationType) => {
      dispatch(
        resourceActions.resourceRequested(ResourceType.GetIntegrationPayload, { integrationId, integrationType })
      );
    },
    onUpdateIntegrationSuccess: () => {
      dispatch(resourceActions.resourceInit(ResourceType.UpdateIntegration));
      //window.location.reload();
      //dispatch(resourceActions.resourceRequested(ResourceType.GetIntegrations));
    },
    onSyncIntegration: (integrationId: string) => {
      const command: SyncIntegrationCommand = { integrationId };
      dispatch(resourceActions.resourceRequested(ResourceType.SyncManualIntegration, { command }));
    },
    getCargoCarriers: () => {
      dispatch(resourceActions.resourceRequested(ResourceType.CargoCarriers));
    },
    initResources,
  };
}

export const IntegrationsContainer = connect(mapStateToProps, mapDispatchToProps)(validation(injectIntl(Integrations)));

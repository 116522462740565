import { FeedbackModalResource } from '@store/state';
import { FeedBackModalAction } from './actions';
import { FEEDBACK_MODAL_TOGGLE } from './constants';

export function feedBackModalReducer(state: FeedbackModalResource, action: FeedBackModalAction): FeedbackModalResource {
  switch (action.type) {
    case FEEDBACK_MODAL_TOGGLE:
      return {
        ...action.payload,
      };
    default:
      return state;
  }
}

import { GridContainer } from '@components/atoms/GridContainer';
import { CustomerDetailsLinkFormatter, geti18nName } from '@containers/formatters';
import { GridProps, GridType } from '@models';
import { ColumnSize, DataGridColumn, DataGridRow, dateTimeFormatter, ellipsisFormatter } from '@oplog/data-grid';
import { checkShippingAddress } from '@utils';
import { SortDirection, SortField } from 'dynamic-query-builder-client';
import * as React from 'react';

const intlKey = 'CustomersGrid';

const customerGridInitalSort: SortField = new SortField({
  property: 'createdAt',
  by: SortDirection.DESC,
});

export class CustomersGrid extends React.Component<GridProps, {}> {
  render() {
    const { intl, titleKey } = this.props;
    const customersGridColumns: Array<DataGridColumn> = [
      {
        name: '#',
        key: 'index',
        resizable: false,
        locked: true,
        sortable: false,
        type: 'number',
        visibility: true,
        filterable: false,
        cellClass: 'index-column',
        width: ColumnSize.Medium,
      },
      {
        name: geti18nName('customerNo', intl, intlKey),
        key: 'customerNo',
        locked: true,
        sortable: true,
        type: 'number',
        visibility: true,
        filterable: true,
        resizable: false,
      },
      {
        name: geti18nName('fullName', intl, intlKey),
        key: 'fullName',
        locked: true,
        sortable: false,
        type: 'string',
        visibility: true,
        filterable: true,
        resizable: true,
        formatter: CustomerDetailsLinkFormatter,
        getRowMetaData: (row: DataGridRow) => row,
        width: ColumnSize.XLarge,
      },
      {
        name: geti18nName('email', intl, intlKey),
        key: 'email',
        locked: true,
        sortable: false,
        type: 'string',
        visibility: true,
        filterable: true,
        resizable: true,
      },
      {
        name: geti18nName('address', intl, intlKey),
        key: 'lastShippingAddress',
        locked: true,
        sortable: false,
        type: 'string',
        visibility: true,
        width: ColumnSize.Bigger,
        filterable: true,
        resizable: true,
        formatter: row => {
          const modRow = { ...row };
          modRow.value = checkShippingAddress(row.value);
          return ellipsisFormatter(modRow);
        },
        getRowMetaData: (row: DataGridRow) => row,
      },
      {
        name: geti18nName('phone', intl, intlKey),
        key: 'phone',
        locked: true,
        sortable: false,
        type: 'string',
        visibility: true,
        filterable: true,
        resizable: true,
      },
      {
        name: geti18nName('addressCount', intl, intlKey),
        key: 'addressCount',
        locked: true,
        sortable: false,
        type: 'number',
        visibility: true,
        cellClass: 'index-column',
        filterable: true,
        resizable: true,
      },
      {
        name: geti18nName('salesOrderCount', intl, intlKey),
        key: 'salesOrderCount',
        locked: true,
        sortable: true,
        type: 'number',
        cellClass: 'index-column',
        visibility: true,
        filterable: true,
        resizable: true,
      },
      {
        name: geti18nName('createdAt', intl, intlKey),
        key: 'createdAt',
        locked: true,
        sortable: true,
        type: 'moment',
        visibility: true,
        filterable: true,
        resizable: true,
        formatter: dateTimeFormatter,
      },
    ];

    return (
      <GridContainer
        titleKey={
          titleKey ? `${intl.messages['Customers.Grid.Title']} - ${titleKey}` : intl.messages['Customers.Grid.Title']
        }
        intlKey={intlKey}
        remoteExportToExcel
        gridKey={GridType.Customers}
        columns={customersGridColumns}
        predefinedFilters={[]}
        intl={this.props.intl}
        sortField={customerGridInitalSort}
      />
    );
  }
}

import { Image } from '@oplog/express/dist/components/ImageViewer/ImageViewer';

export interface ReturnDetailsState {
  canRespond?: boolean;
  imageViewer: {
    isOpen: boolean;
    images: Image[];
    activeIndex: number;
  };
}

export function initialReturnDetailsState(): ReturnDetailsState {
  return {
    imageViewer: {
      isOpen: false,
      images: [],
      activeIndex: 0,
    },
    canRespond: false,
  };
}

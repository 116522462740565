import { ErrorCode, IntegrationType, OpenApiIdentifier } from '@services';

export const getIntegrationErrorMessage = (
  key: string,
  property: string,
  COMPONENT_INTL_KEY: string,
  intl: any
): string => {
  const ignoredProperties = ['ShopLink', 'ClientId'];

  if (ignoredProperties.includes(property)) {
    return '';
  } else if (key === ErrorCode.ResourceAlreadyExists.toString()) {
    return intl.messages[`${COMPONENT_INTL_KEY}.Error.IntegrationExists`];
  } else if (key === ErrorCode.InvalidCredential.toString()) {
    return intl.messages[`${COMPONENT_INTL_KEY}.Error.AuthFailed`];
  }

  return intl.messages['PostErrorMessage.Description'];
};

export const integrationIdentifier = (integrationType: IntegrationType | undefined, openApiIdentifier: OpenApiIdentifier | undefined): IntegrationType | OpenApiIdentifier | undefined => {
  if (integrationType && openApiIdentifier) {
    if (openApiIdentifier !== OpenApiIdentifier.None) {
      return openApiIdentifier;
    }
    else {
      return integrationType;
    }
  }
  return undefined;
}

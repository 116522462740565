import { packageLinkFormatter } from '@containers/formatters/packageLinkComponent';
import { ColumnSize, DataGridColumn, DataGridRow, dateTimeFormatter } from '@oplog/data-grid';
import { geti18nName } from '../../../../containers/formatters';

export const getPackageManipulationColumns = (intl: any, intlKey: any): DataGridColumn[] => {
  const SupportSalesOrdersColumns: Array<DataGridColumn> = [
    {
      name: '#',
      key: 'index',
      resizable: false,
      locked: true,
      sortable: false,
      type: 'number',
      visibility: true,
      filterable: false,
      cellClass: 'index-column',
      width: ColumnSize.Medium,
    },
    {
      name: geti18nName('id', intl, intlKey),
      key: 'id',
      resizable: true,
      locked: true,
      sortable: true,
      type: 'string',
      visibility: true,
      filterable: true,
    },
    {
      name: geti18nName('salesOrderId', intl, intlKey),
      key: 'salesOrderId',
      resizable: true,
      locked: true,
      sortable: true,
      type: 'string',
      visibility: true,
      filterable: true,
    },
    {
      name: geti18nName('referenceNumber', intl, intlKey),
      key: 'referenceNumber',
      resizable: true,
      locked: true,
      sortable: true,
      type: 'string',
      visibility: true,
      filterable: true,
      formatter: packageLinkFormatter,
      getRowMetaData: (row: DataGridRow) => row,
    },
    {
      name: geti18nName('createdAt', intl, intlKey),
      key: 'createdAt',
      locked: true,
      sortable: true,
      type: 'moment',
      visibility: true,
      filterable: true,
      resizable: true,
      formatter: dateTimeFormatter,
    },
    {
      name: geti18nName('updatedAt', intl, intlKey),
      key: 'updatedAt',
      locked: true,
      sortable: true,
      type: 'moment',
      visibility: true,
      filterable: true,
      resizable: true,
      formatter: dateTimeFormatter,
    },
    {
      name: geti18nName('cancelledAt', intl, intlKey),
      key: 'cancelledAt',
      locked: true,
      sortable: true,
      type: 'moment',
      visibility: true,
      filterable: true,
      resizable: true,
      formatter: dateTimeFormatter,
    },
    {
      name: geti18nName('deletedAt', intl, intlKey),
      key: 'deletedAt',
      locked: true,
      sortable: true,
      type: 'moment',
      visibility: true,
      filterable: true,
      resizable: true,
      formatter: dateTimeFormatter,
    },
  ];
  return SupportSalesOrdersColumns;
};

export default getPackageManipulationColumns;

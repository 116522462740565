import { GridType, ResourceType } from '@models';
import { gridActions } from '@oplog/data-grid';
import { GridFetchRequested } from '@oplog/data-grid/dist/store/actions';
import { resourceActions, resourceSelectors } from '@oplog/resource-redux';
import { RouterAction } from 'connected-react-router';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Dispatch } from 'redux';
import {
  SyncUnfulfilledAndCancelledIntegrationsModal,
  SyncUnfulfilledAndCancelledIntegrationsModalProps,
} from '../../components/molecules/SyncUnfulfilledAndCancelledIntegrationsModal';
import { StoreState } from '../../store/state';

export interface SyncUnfulfilledIntegrationsModalContainerProps {
  onClose: () => void;
}

function mapStateToProps(
  { resources }: StoreState,
  ownProps: SyncUnfulfilledIntegrationsModalContainerProps
): Partial<SyncUnfulfilledAndCancelledIntegrationsModalProps> {
  return {
    integrations: resourceSelectors.getResource(resources, ResourceType.GetIntegrations),
    responseData: resourceSelectors.getData(resources, ResourceType.SyncFromIntegrationUnfulfilled),
    isBusy:
      resourceSelectors.isBusy(resources, ResourceType.SyncFromIntegrationUnfulfilled) ||
      resourceSelectors.isBusy(resources, ResourceType.GetIntegrations),
    hasError: resourceSelectors.hasError(resources, ResourceType.SyncFromIntegrationUnfulfilled),
    COMPONENT_INTL_KEY: 'SupportSalesOrdersSyncUnfulfilledIntegrations.Modal',
  };
}

function mapDispatchToProps(
  dispatch: Dispatch<resourceActions.ResourceAction | RouterAction | GridFetchRequested>,
  routeProps: RouteComponentProps,
  ownProps: SyncUnfulfilledIntegrationsModalContainerProps
): Partial<SyncUnfulfilledAndCancelledIntegrationsModalProps> {
  return {
    syncCommandTimeRange: (integrationId: String, startDate: Date, endDate: Date) => {
      const jsonObject = {
        integrationId: integrationId,
        fetchByTimeRangeMethod: {
          start: startDate,
          end: endDate,
        },
      };
      dispatch(resourceActions.resourceRequested(ResourceType.SyncFromIntegrationUnfulfilled, { jsonObject }));
      dispatch(gridActions.gridFetchRequested(GridType.SupportSalesOrders));
    },
    onWillUnmount: () => {
      dispatch(resourceActions.resourceInit(ResourceType.SyncFromIntegrationUnfulfilled));
      dispatch(gridActions.gridFetchRequested(GridType.SupportSalesOrders));
    },
  };
}

export const SupportSalesOrdersSyncUnfulfilledIntegrationsModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(SyncUnfulfilledAndCancelledIntegrationsModal));

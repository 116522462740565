import { ResourceType } from '@models';
import { resourceActions } from '@oplog/resource-redux';
import { CreateCustomer } from '@pages/CreateCustomer';
import { RouterAction } from 'connected-react-router';
import { Dispatch } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

export function mapDispatchToProps(dispatch: Dispatch<resourceActions.ResourceAction | RouterAction>) {
  return {
    onWillUnmount: () => {
      dispatch(resourceActions.resourceInit(ResourceType.CreateCustomer));
    },
  };
}

export const CreateCustomerContainer = connect(null, mapDispatchToProps)(injectIntl(CreateCustomer));

import { SupportReceivingOrderGrid } from '@components/organisms/SupportReceivingOrderGrid';
import { SupportReceivingOrdersSoftDeleteContainer } from '@containers/SupportReceivingOrdersSoftDeleteContainer';
import { SupportReceivingOrdersSyncToWMSModalContainer } from '@containers/SupportReceivingOrdersSyncToWMSModalContainer';
import { ActionBar, Button, Flex, LayoutContent, Panel } from '@oplog/express';
import * as React from 'react';
import { useState } from 'react';
import { MemberPageContainer } from '../../../containers/MemberPageContainer/MemberPageContainer';
import { urls } from '../../../routes/urls';
import { Props } from '../../atoms/Component/Component';

const COMPONENT_INTL_KEY: string = 'SupportReceivingOrders';

export interface SupportReceivingOrdersProps extends Props {
  closeModalViewer: () => void;
}

export interface SupportReceivingOrdersState {
  resetWMSSyncOpen: boolean;
  softDeleteModalOpen: boolean;
}

export const SupportReceivingOrders: React.FC<SupportReceivingOrdersProps> = ({
  closeModalViewer,
  intl,
}: SupportReceivingOrdersProps) => {
  const [resetWMSSyncOpen, setResetWMSSyncOpen] = useState(false);
  const [softDeleteModalOpen, setSoftDeleteModalOpen] = useState(false);

  const syncFromWMSModalClose = () => {
    setResetWMSSyncOpen(false);
    closeModalViewer();
  };

  const sendToWMSModalClose = () => {
    setSoftDeleteModalOpen(false);
    closeModalViewer();
  };

  const renderSyncFromWMSModal = () => {
    if (resetWMSSyncOpen) {
      return <SupportReceivingOrdersSyncToWMSModalContainer onClose={syncFromWMSModalClose} />;
    }
    return null;
  };

  const renderSendToWMSModal = () => {
    if (softDeleteModalOpen) {
      return <SupportReceivingOrdersSoftDeleteContainer onClose={sendToWMSModalClose} />;
    }
    return null;
  };

  const breadcrumb = [
    {
      title: intl.messages[`${COMPONENT_INTL_KEY}.Breadcrumb.SupportTitle`],
      url: urls.support,
    },
    {
      title: intl.messages[`${COMPONENT_INTL_KEY}.Breadcrumb.Title`],
    },
  ];

  return (
    <MemberPageContainer documentTitle={intl.messages[`${COMPONENT_INTL_KEY}.Header.Title`]}>
      <ActionBar
        top="66px"
        title={intl.messages[`${COMPONENT_INTL_KEY}.Header.Title`]}
        subtitle=""
        breadcrumb={breadcrumb}
      >
        <Flex marginLeft="auto">
          <Button
            variant="dark"
            size="large"
            dropdown={{
              items: [
                {
                  text: intl.messages[`${COMPONENT_INTL_KEY}.Buttons.Dropdown.ResetWMSSync`],
                  value: intl.messages[`${COMPONENT_INTL_KEY}.Buttons.Dropdown.ResetWMSSync`],
                  onClick: () => setResetWMSSyncOpen(true),
                },
                {
                  text: intl.messages[`${COMPONENT_INTL_KEY}.Buttons.Dropdown.SoftDelete`],
                  value: intl.messages[`${COMPONENT_INTL_KEY}.Buttons.Dropdown.SoftDelete`],
                  onClick: () => setSoftDeleteModalOpen(true),
                },
              ],
            }}
          >
            {intl.messages[`${COMPONENT_INTL_KEY}.Buttons.Title`]}
          </Button>
        </Flex>
      </ActionBar>

      <LayoutContent className="page-content">
        <Panel title={intl.messages[`${COMPONENT_INTL_KEY}.Grid.Title`]}>
          <SupportReceivingOrderGrid intl={intl} />
        </Panel>
      </LayoutContent>

      {renderSyncFromWMSModal()}
      {renderSendToWMSModal()}
    </MemberPageContainer>
  );
};

import { GridType, ResourceType } from '@models';
import { gridActions } from '@oplog/data-grid';
import { GridAction } from '@oplog/data-grid/dist/store/actions';
import { resourceActions, resourceSelectors } from '@oplog/resource-redux';
import { SalesOrderDetails, SalesOrderDetailsProps } from '@pages/SalesOrderDetails';
import { CancelLineItemsInputDTO, CancelSalesOrderCommand } from '@services';
import { StoreState } from '@store';
import { getRouteParam, onTabChange } from '@utils';
import { RouterAction } from 'connected-react-router';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { Dispatch } from 'redux';
import { fileUploadInit, fileUploadRequested, FileUploadsAction, getUrl, isBusy } from '@oplog/file-uploader';

const FILE_ID = 'orderCargoInfo';

export interface SalesOrderDetailsContainerProps extends RouteComponentProps {
  referenceNumber: number;
}

export function mapStateToProps(
  { resources, files }: StoreState,
  ownProps: SalesOrderDetailsContainerProps
): Partial<SalesOrderDetailsProps> {
  const referenceNumber = getRouteParam(ownProps, 'referenceNumber');
  const refNo = decodeURIComponent(referenceNumber);
  const iisBusy = resourceSelectors.isBusy(resources, ResourceType.SalesOrderDetails);
  const salesOrderDetails = resourceSelectors.getData(resources, ResourceType.SalesOrderDetails);
  const salesOrderStateDetails = resourceSelectors.getData(resources, ResourceType.PipelineStateOfSalesOrder)
  const error = resourceSelectors.getError(resources, ResourceType.SalesOrderDetails);
  const errorCreatePackages = resourceSelectors.getError(resources, ResourceType.CreateSalesOrderPackage);
  return {
    ...salesOrderDetails,
    iisBusy,
    error,
    errorCreatePackages,
    referenceNumber: refNo,
    salesOrderPackages: resourceSelectors.getData(resources, ResourceType.SalesOrderPackages),
    salesOrderLineItems: resourceSelectors.getData(resources, ResourceType.SalesOrderLineItems),
    salesOrderStateDetails: salesOrderStateDetails,
    cargoCarriers: resourceSelectors.getData(resources, ResourceType.CargoCarriers),
    cancelOrder: {
      isBusy: resourceSelectors.isBusy(resources, ResourceType.CancelSalesOrder),
      isCompleted: resourceSelectors.isSuccessComplete(resources, ResourceType.CancelSalesOrder),
      isError: resourceSelectors.isErrorComplete(resources, ResourceType.CancelSalesOrder),
    },
    cancelPackage: {
      isBusy: resourceSelectors.isBusy(resources, ResourceType.CancelSalesOrderPackage),
      isCompleted: resourceSelectors.isSuccessComplete(resources, ResourceType.CancelSalesOrderPackage),
      isError: resourceSelectors.isErrorComplete(resources, ResourceType.CancelSalesOrderPackage),
      getError: resourceSelectors.getError(resources, ResourceType.CancelSalesOrderPackage),
    },
    cancelLineItemRequest: {
      isBusy: resourceSelectors.isBusy(resources, ResourceType.CancelSalesOrderLineItem),
      isCompleted: resourceSelectors.isSuccessComplete(resources, ResourceType.CancelSalesOrderLineItem),
      isError: resourceSelectors.isErrorComplete(resources, ResourceType.CancelSalesOrderLineItem),
    },
    createPackageRequest: {
      isBusy: resourceSelectors.isBusy(resources, ResourceType.CreateSalesOrderPackage),
      isCompleted: resourceSelectors.isSuccessComplete(resources, ResourceType.CreateSalesOrderPackage),
      isError: resourceSelectors.isErrorComplete(resources, ResourceType.CreateSalesOrderPackage),
    },
    updateCargoInfoRequest: {
      isBusy: resourceSelectors.isBusy(resources, ResourceType.UpdateCargoInfo),
      isCompleted: resourceSelectors.isSuccessComplete(resources, ResourceType.UpdateCargoInfo),
      isError: resourceSelectors.isErrorComplete(resources, ResourceType.UpdateCargoInfo),
      getError: resourceSelectors.getError(resources, ResourceType.UpdateCargoInfo)
    },

    isUploading: isBusy(files, FILE_ID),
    fileUrl: getUrl(files, FILE_ID),
  };
}

export function mapDispatchToProps(
  dispatch: Dispatch<FileUploadsAction | resourceActions.ResourceAction | RouterAction | GridAction>,
  ownProps: SalesOrderDetailsProps & RouteComponentProps
): Partial<SalesOrderDetailsProps> {
  const referenceNumber = decodeURIComponent(ownProps?.match?.params?.['referenceNumber']);

  const getSalesOrderDetails = (refNumber?: any) => {
    const value = { referenceNumber: refNumber || referenceNumber };
    dispatch(resourceActions.resourceRequested(ResourceType.SalesOrderDetails, value));
  };

  const getPackages = () => {
    dispatch(resourceActions.resourceRequested(ResourceType.SalesOrderPackages, { referenceNumber }));
  };

  const getLineItems = () => {
    const value = { referenceNumber, isPackageless: true };

    dispatch(resourceActions.resourceRequested(ResourceType.SalesOrderLineItems, value));
  };

  const getCargoCarriers = () => {
    dispatch(resourceActions.resourceRequested(ResourceType.CargoCarriers));
  };

  return {
    onDidMount(): void {
      getSalesOrderDetails();
      getLineItems();
      getCargoCarriers();
    },
    getLineItems(hasPackage): void {
      if (hasPackage) getPackages();
    },
    initResources: () => {
      dispatch(resourceActions.resourceInit(ResourceType.CancelSalesOrder));
      dispatch(resourceActions.resourceInit(ResourceType.CreateSalesOrderPackage));
      dispatch(resourceActions.resourceInit(ResourceType.UpdateCargoInfo));
      dispatch(resourceActions.resourceInit(ResourceType.CancelSalesOrderLineItem));
      dispatch(resourceActions.resourceInit(ResourceType.CancelSalesOrderPackage));
    },
    refreshData: () => {
      if (ownProps && ownProps.location) {
        dispatch(resourceActions.resourceRequested(ResourceType.PipelineStateOfSalesOrder, { referenceNumber }));
      }
      dispatch(resourceActions.resourceInit(ResourceType.CancelSalesOrderPackage));
      getSalesOrderDetails();
      getLineItems();
      getCargoCarriers()
    },

    initCancelPackageError: () => {
      dispatch(resourceActions.resourceInit(ResourceType.CancelSalesOrderPackage));
    },

    cancelOrderCommand: () => {
      if (ownProps && ownProps.location) {
        const command: CancelSalesOrderCommand = { referenceNumber };
        dispatch(resourceActions.resourceRequested(ResourceType.CancelSalesOrder, { command }));
      }
    },
    onTabChange: (refNumber: any, options?: { isMain?: boolean; isPackageless?: boolean }) => {
      if (options?.isMain) {
        dispatch(gridActions.gridFetchRequested(GridType.SalesOrderLineItems, [refNumber, options.isPackageless]));
      } else {
        dispatch(gridActions.gridFetchRequested(GridType.SalesOrderPackageLineItems, [refNumber]));
      }
    },
    onCreatePackage: (data) => {
      dispatch(
        resourceActions.resourceRequested(ResourceType.CreateSalesOrderPackage, {
          data
        })
      );
    },
    onUpdateCargoInfo: data => {
      dispatch(
        resourceActions.resourceRequested(ResourceType.UpdateCargoInfo, {
          data,
        })
      );
    },
    cancelPackageCommand: (refNumber: string) => {
      const command: CancelSalesOrderCommand = { referenceNumber: refNumber };
      dispatch(resourceActions.resourceRequested(ResourceType.CancelSalesOrderPackage, { command }));
    },
    cancelLineItemCommand: (sku: string, amount: number) => {
      const command: CancelLineItemsInputDTO = { referenceNumber, amount, sku };
      dispatch(resourceActions.resourceRequested(ResourceType.CancelSalesOrderLineItem, { command }));
    },
    onWillUnmount: (): void => {
      dispatch(resourceActions.resourceInit(ResourceType.SalesOrderDetails));
      dispatch(resourceActions.resourceInit(ResourceType.CancelSalesOrder));
    },
    goTab: tab => {
      onTabChange(tab, ownProps, dispatch);
    },
    getSalesOrderStateDetails: () => {
      dispatch(resourceActions.resourceRequested(ResourceType.PipelineStateOfSalesOrder, { referenceNumber }));
    },
    onReset: () => dispatch(fileUploadInit()),
    onFileSelect: (file: File) => {
      dispatch(fileUploadRequested(FILE_ID, file));
    },
  };
}

export const SalesOrderDetailsContainer = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(injectIntl(SalesOrderDetails))
);

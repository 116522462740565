import { GridContainer } from '@components/atoms/GridContainer';
import { barcodeFormatter, geti18nName, ProductDetailsLinkFormatter, skuReceivingOrderLineItemFormatter } from '@containers/formatters';
import { GridProps, GridType } from '@models';
import {
  ColumnSize,
  DataGridColumn,
  DataGridRow,
  dateTimeFormatter,
} from '@oplog/data-grid';
import { log } from 'console';
import { ArrayFilterOperation, SortDirection, SortField } from 'dynamic-query-builder-client';
import * as React from 'react';

const intlKey = 'ReceivingOrderLineItemsExpirationDateGrid';
const gridKey = 'ReceivingOrderDetails.Grid';

export interface ReceivingOrderLineItemsExpirationDatesGridProps extends GridProps {
  referenceNumber: string;
}

const receivingOrderLineItemExpirationDatesGridInitalSort: SortField = new SortField({
  property: 'SKU',
  by: SortDirection.ASC,
});

export class ReceivingOrderLineItemExpirationDatesGrid extends React.Component<ReceivingOrderLineItemsExpirationDatesGridProps> {
  render() {
    const { intl, titleKey } = this.props;
    const receivingOrderLineItemExpirationDatesGridColumns: Array<DataGridColumn> = [
      {
        name: '#',
        key: 'index',
        resizable: true,
        locked: true,
        sortable: false,
        type: 'number',
        visibility: true,
        filterable: false,
        width: ColumnSize.Medium,
        cellClass: 'index-column',
      },
      {
        name: geti18nName('sku', intl, intlKey),
        key: 'sku',
        locked: true,
        sortable: true,
        type: 'string',
        visibility: true,
        filterable: true,
        resizable: true,
        formatter: row => {
          return skuReceivingOrderLineItemFormatter(row);
        },
        getRowMetaData: (row: DataGridRow) => row,
      },
      {
        name: geti18nName('barcode', intl, intlKey),
        key: 'barcodes',
        locked: true,
        type: 'string',
        visibility: true,
        resizable: true,
        sortable: false,
        filterable: true,
        formatter: barcodeFormatter,
        fieldType: 'array',
        searchField: '_',
        outerOp: ArrayFilterOperation.Any,
      },
      {
        name: geti18nName('productName', intl, intlKey),
        key: 'productName',
        locked: true,
        sortable: true,
        type: 'string',
        visibility: true,
        filterable: true,
        formatter: ProductDetailsLinkFormatter,
        getRowMetaData: (row: DataGridRow) => row,
        resizable: true,
      },
      {
        name: geti18nName('quantity', intl, intlKey),
        key: 'quantity',
        resizable: true,
        sortable: true,
        type: 'number',
        visibility: true,
        filterable: true,
      },
      {
        name: geti18nName('expirationDate', intl, intlKey),
        key: 'expirationDate',
        locked: true,
        sortable: true,
        type: 'moment',
        visibility: true,
        filterable: true,
        resizable: true,
        formatter: (row) => {
          return <>
            {row.value.toString().split('T')[0]}
          </>
        },
      },
    ];

    return (
      <GridContainer
        titleKey={
          titleKey
            ? `${titleKey}-expirationDates`
            : intl.messages['ReceivingOrderDetails.Grid.ExpirationDateGridTitle']
        }
        intlKey={intlKey}
        remoteExportToExcel
        gridKey={GridType.ReceivingOrderLineItemExpirationDates}
        columns={receivingOrderLineItemExpirationDatesGridColumns}
        predefinedFilters={[]}
        filtersDisabled={false}
        apiArgs={[this.props.referenceNumber]}
        intl={this.props.intl}
        sortField={receivingOrderLineItemExpirationDatesGridInitalSort}
      />
    );
  }
}

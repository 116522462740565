import createAuth0Client, {
  getIdTokenClaimsOptions,
  GetTokenSilentlyOptions,
  GetTokenWithPopupOptions,
  IdToken,
  LogoutOptions,
  PopupLoginOptions,
  RedirectLoginOptions,
} from '@auth0/auth0-spa-js';
import Auth0Client from '@auth0/auth0-spa-js/dist/typings/Auth0Client';
import { urls } from '@routes/urls';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { config } from '../config';
import history from '../history';
import { tracking } from '@utils';
import {TENANT_ID_KEY} from "@services";
import {Scopes} from "@auth/scopes";

interface Auth0Context {
  isAuthenticated: boolean;
  user: any;
  loading: boolean;
  popupOpen: boolean;
  loginWithPopup(options: PopupLoginOptions): Promise<void>;
  handleRedirectCallback(): Promise<any>;
  getIdTokenClaims(o?: getIdTokenClaimsOptions): Promise<IdToken | undefined>;
  loginWithRedirect(o: RedirectLoginOptions): Promise<void>;
  getAccessTokenSilently(o?: GetTokenSilentlyOptions): Promise<string | undefined>;
  getTokenWithPopup(o?: GetTokenWithPopupOptions): Promise<string | undefined>;
  logout(o?: LogoutOptions): void;
}

export const Auth0Context = createContext<Auth0Context | null>(null);
export const useAuth0 = () => useContext(Auth0Context)!;

const onRedirectCallback = (appState: any) => {
  history.replace(appState && appState.returnTo ? appState.returnTo : urls.home);
};

let initOptions: any = config.auth;

const getAuth0Client: any = () => {
  return new Promise(async (resolve, reject) => {
    let client;
    if (!client) {
      try {
        client = await createAuth0Client({ ...initOptions, cacheLocation: 'localstorage', useRefreshTokens: true });
        resolve(client);
      } catch (e) {
        reject(new Error(`getAuth0Client Error: ${e}`));
      }
    }
  });
};

export const getTokenSilently = async (...p: any) => {
  const client = await getAuth0Client();
  return await client.getTokenSilently(...p);
};

export const Auth0Provider = ({ children }: any): any => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState<any>();
  const [auth0Client, setAuth0] = useState<Auth0Client>();
  const [loading, setLoading] = useState(true);
  const [popupOpen, setPopupOpen] = useState(false);

  useEffect(() => {
    const initAuth0 = async () => {
      const client = await getAuth0Client();
      setAuth0(client);
      if (window.location.search.includes('code=')) {
        const { appState } = await client.handleRedirectCallback();
        onRedirectCallback(appState);
      }
      const isAuthenticated = await client.isAuthenticated();
      setIsAuthenticated(isAuthenticated);

      if (isAuthenticated) {
        const user = await client.getUser();

        //validate cached tenant id
        const cachedTenantId = localStorage.getItem(TENANT_ID_KEY);
        if (cachedTenantId)
        {
          if (!user || !user[config.auth.userMetadataKey])
          {
            localStorage.removeItem(TENANT_ID_KEY);
          }
          else
          {
            const userMeta = user[config.auth.userMetadataKey];
            const authTenantId = userMeta.tenant?.id;
            const isAdmin = user[config.auth.userPermissionKey]
                ? user[config.auth.userPermissionKey].split(' ').includes(Scopes.SystemAdmin) 
                : false;
            
            if (!authTenantId)
            {
              localStorage.removeItem(TENANT_ID_KEY);
            }
            else if (!isAdmin 
                && (!userMeta.permittedTenants || !userMeta.permittedTenants.includes(cachedTenantId)) // Not one of the permittedTenants then it should be authtenantid
                && authTenantId !== cachedTenantId)
            {
              localStorage.setItem(TENANT_ID_KEY, authTenantId);
            }
          }
        }
        
        setUser(user);
        tracking.setUserId(user?.sub.replace('auth0|', ''));
      } else {
        history.push(urls.authentication);
      }

      setLoading(false);
    };
    initAuth0();
    // eslint-disable-next-line
  }, []);

  const loginWithPopup = async (params = {}) => {
    setPopupOpen(true);
    try {
      await auth0Client!.loginWithPopup(params);
    } catch (error) {
      console.error(error);
    } finally {
      setPopupOpen(false);
    }
    const user = await auth0Client!.getUser();
    setUser(user);
    setIsAuthenticated(true);
  };

  const handleRedirectCallback = async () => {
    setLoading(true);
    await auth0Client!.handleRedirectCallback();
    const user = await auth0Client!.getUser();
    setLoading(false);
    setIsAuthenticated(true);
    setUser(user);
  };

  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated,
        user,
        loading,
        popupOpen,
        loginWithPopup,
        handleRedirectCallback,
        getIdTokenClaims: (o: getIdTokenClaimsOptions | undefined) => auth0Client!.getIdTokenClaims(o),
        loginWithRedirect: (o: RedirectLoginOptions) => auth0Client!.loginWithRedirect(o),
        getAccessTokenSilently: (o: GetTokenSilentlyOptions | undefined) => auth0Client!.getTokenSilently(o),
        getTokenWithPopup: (o: GetTokenWithPopupOptions | undefined) => auth0Client!.getTokenWithPopup(o),
        logout: (o: LogoutOptions | undefined) => auth0Client!.logout(o),
      }}
    >
      {children}
    </Auth0Context.Provider>
  );
};

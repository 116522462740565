import { GridContainer } from '@components/atoms/GridContainer';
import { bubbleStepFormatter, geti18nName, ReceivingOrderDetailsLinkFormatter } from '@containers/formatters';
import { GridProps, GridType } from '@models';
import { ColumnSize, DataGridColumn, DataGridRow, dateTimeFormatter, getEnumOptions } from '@oplog/data-grid';
import { ReceivingOrderState } from '@services';
import { SortDirection, SortField } from 'dynamic-query-builder-client';
import * as React from 'react';

const intlKey = 'ProductReceivingOrdersGrid';

export interface ProductReceivingOrdersGridProps extends GridProps {
  sku: string;
}

const productReceivingOrdersGridInitalSort: SortField = new SortField({
  property: 'createdAt',
  by: SortDirection.DESC,
});

export class ProductReceivingOrdersGrid extends React.Component<ProductReceivingOrdersGridProps> {
  render() {
    const { intl, titleKey } = this.props;
    const productReceivingOrderGridColumns: Array<DataGridColumn> = [
      {
        name: '#',
        key: 'index',
        resizable: true,
        locked: true,
        sortable: false,
        type: 'number',
        visibility: true,
        filterable: false,
        width: ColumnSize.Medium,
        cellClass: 'index-column',
      },
      {
        name: geti18nName('referenceNumber', intl, intlKey),
        key: 'referenceNumber',
        locked: true,
        sortable: true,
        type: 'string',
        visibility: true,
        filterable: true,
        formatter: ReceivingOrderDetailsLinkFormatter,
        getRowMetaData: (row: DataGridRow) => row,
        resizable: true,
      },
      {
        name: geti18nName('state', intl, intlKey),
        key: 'state',
        resizable: true,
        locked: true,
        sortable: false,
        type: 'enum',
        visibility: true,
        filterable: true,
        formatter: bubbleStepFormatter,
        getRowMetaData: (row: DataGridRow) => {
          return {
            intl,
            enum: ReceivingOrderState,
            row,
          };
        },
        options: getEnumOptions(intl, ReceivingOrderState),
      },
      {
        name: geti18nName('acceptedQuantity', intl, intlKey),
        key: 'acceptedQuantity',
        locked: true,
        sortable: true,
        type: 'number',
        visibility: true,
        filterable: true,
        resizable: true,
      },
      {
        name: geti18nName('createdAt', intl, intlKey),
        key: 'createdAt',
        locked: true,
        sortable: true,
        type: 'moment',
        visibility: true,
        filterable: true,
        resizable: true,
        formatter: dateTimeFormatter,
      },
    ];

    return (
      <GridContainer
        titleKey={
          titleKey
            ? `${intl.messages['ProductReceivingOrdersGrid.Grid.Title']} - ${titleKey}`
            : intl.messages['ProductReceivingOrdersGrid.Grid.Title']
        }
        intlKey={intlKey}
        remoteExportToExcel
        gridKey={GridType.ProductReceivingOrders}
        columns={productReceivingOrderGridColumns}
        predefinedFilters={[]}
        apiArgs={[this.props.sku]}
        intl={this.props.intl}
        sortField={productReceivingOrdersGridInitalSort}
      />
    );
  }
}

import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Icon,
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Text,
  Textarea,
} from '@oplog/express';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Props } from '../../atoms/Component/Component';

export interface SupportSubmitReferenceNumbersModalProps extends Props {
  onClose: () => void;
  response?: any;
  submitReferenceNumbers: (referenceNumbersArray: string[], isCancelBlockedOnlyActive?: Boolean) => void;
  isCancelBlockedOnlyActive?: Boolean;
  //COMPONENT_INTL_KEY: string;
  type: SupportSubmitReferenceNumbersModalIntlKeys;
  error?: ErrorModel;
}

export enum SupportSubmitReferenceNumbersModalIntlKeys {
  CancelSalesOrder = 'CancelSalesOrder.Modal',
  SoftDeleteSalesOrder = 'DeleteSalesOrder.Modal',
  ReActivateCancelledSalesOrders = 'ReActivateCancelledSalesOrders.Modal',
  SoftDeleteReceivingOrders = 'SoftDeleteReceivingOrders.Modal',
  SyncToWMSReceivingOrders = 'SyncToWMSReceivingOrders.Modal',
  ResetFulfillmentSync = 'SupportPage.PackagePage.ResetFulfillmentSync',
  ResetWMSSyncState = 'SupportPage.PackagePage.ResetWMSSyncState',
}

export const SupportSubmitReferenceNumbersModal: React.FC<SupportSubmitReferenceNumbersModalProps> = ({
  onClose,
  intl,
  response,
  submitReferenceNumbers,
  isCancelBlockedOnlyActive = false,
  type,
  onWillUnmount,
}) => {
  const [refNums, setRefNums] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [refNumsArray, setRefNumsArray] = useState<string[]>([]);
  const [modalState, setModalState] = useState({
    confirmationModalOpen: false,
    confirmationDialogOpen: false,
    enterReferenceNumbersModalOpen: true,
  });

  useEffect(() => {
    return () => {
      onWillUnmount && onWillUnmount();
    };
  }, []);

  useEffect(() => {
    if (response) {
      setIsLoading(false);
      setModalState({
        ...modalState,
        confirmationModalOpen: false,
        enterReferenceNumbersModalOpen: false,
        confirmationDialogOpen: true,
      });
    }
  }, [response]);

  const handleRefNumsChange = (e: any) => {
    setRefNums(e.currentTarget.value);
  };

  const onSubmit = () => {
    const refNumsArray: string[] = refNums.split(/\n/);
    var filtered = refNumsArray.filter(function(el) {
      return el != '';
    });

    setRefNumsArray(filtered);
    setModalState({
      ...modalState,
      confirmationModalOpen: true,
    });
  };

  const isSubmitDisabled = () => {
    var result = refNums?.length === 0 || refNums === null;
    return result;
  };

  const closeConfirmationDialog = () => {
    setModalState({
      ...modalState,
      confirmationModalOpen: false,
      enterReferenceNumbersModalOpen: true,
    });
  };

  const confirmSubmit = () => {
    setIsLoading(true);
    submitReferenceNumbers(refNumsArray, isCancelBlockedOnlyActive);
  };

  const closeDialog = () => {
    setModalState({
      ...modalState,
      confirmationDialogOpen: false,
    });
    onClose();
  };
  const listItems = refNumsArray.map(number => <li key={number.toString()}>{number}</li>);
  const COMPONENT_INTL_KEY = type;

  return (
    <Box>
      <Modal isOpen={!modalState.confirmationModalOpen && modalState.enterReferenceNumbersModalOpen} onClose={onClose}>
        <ModalHeader>{intl.messages[`${COMPONENT_INTL_KEY}.Title`]}</ModalHeader>

        <ModalContent>
          <Flex flexDirection="column" flexWrap="wrap">
            <FormControl>
              <FormLabel>{intl.messages[`${COMPONENT_INTL_KEY}.RefNumRb`]}</FormLabel>
              <p>{intl.messages[`Modal.NewLineMessage`]}</p>
              <Textarea
                value={refNums}
                m="16"
                height="250px"
                size={'large'}
                isRequired={true}
                onChange={handleRefNumsChange}
              />
            </FormControl>
          </Flex>
          <br />
          <Flex justifyContent="center" width="100%" px="13px">
            <Button className="cancel" onClick={onClose} kind="outline" variant="dark" width={1} size="small">
              {intl.messages[`${COMPONENT_INTL_KEY}.CancelButton`]}
            </Button>
            <Button
              ml="6"
              disabled={isSubmitDisabled()}
              className="save"
              onClick={onSubmit}
              width={1}
              size="small"
              variant="success"
            >
              {intl.messages[`${COMPONENT_INTL_KEY}.ApproveButton`]}
            </Button>
          </Flex>
        </ModalContent>
        <ModalFooter borderTop="xs" borderColor="palette.snow_light">
          <Flex>
            <Icon mr="8px" name="far fa-question-circle" fontSize="21px" />
            <Text color="palette.grey" fontSize="13" lineHeight={1.54}>
              {intl.messages[`${COMPONENT_INTL_KEY}.Note`]}
            </Text>
          </Flex>
        </ModalFooter>
      </Modal>

      <Modal isOpen={modalState.confirmationModalOpen} onClose={closeConfirmationDialog}>
        <ModalHeader>{intl.messages[`${COMPONENT_INTL_KEY}.ConfirmationTitle`]}</ModalHeader>

        <ModalContent>
          <Flex flexDirection="column" flexWrap="wrap">
            <FormControl size="large" m="16">
              <FormLabel>{intl.messages[`${COMPONENT_INTL_KEY}.RefNumRb`]}</FormLabel>
              <p>{intl.messages[`${COMPONENT_INTL_KEY}.ConfirmationMessage`]}</p>
              <ul min-height="250px">{listItems}</ul>
            </FormControl>
          </Flex>
          <br />
          <Flex justifyContent="center" width="100%" px="13px">
            <Button
              className="cancel"
              onClick={closeConfirmationDialog}
              kind="outline"
              variant="dark"
              width={1}
              size="small"
            >
              {intl.messages[`Modal.GoBack`]}
            </Button>
            <Button
              ml="6"
              disabled={false}
              className="save"
              onClick={confirmSubmit}
              width={1}
              size="small"
              variant="success"
              isLoading={isLoading}
            >
              {intl.messages[`${COMPONENT_INTL_KEY}.ApproveButton`]}
            </Button>
          </Flex>
        </ModalContent>
      </Modal>

      <Modal isOpen={modalState.confirmationDialogOpen} onClose={closeDialog}>
        <ModalHeader>{intl.messages[`${COMPONENT_INTL_KEY}.ConfirmationTitle`]}</ModalHeader>

        <ModalContent>
          <Flex flexDirection="column" flexWrap="wrap">
            <FormControl size="large" m="16">
              <FormLabel>{intl.messages[`${COMPONENT_INTL_KEY}.DataSent`]}</FormLabel>

              {response && response.faultyItems.length != 0 && (
                <div>
                  <Alert variant={'danger'} isOpen={true}>
                    <AlertIcon name="fas fa-exclamation-triangle" />
                    <AlertTitle>{intl.messages[`${COMPONENT_INTL_KEY}.Alert.Fail`]}</AlertTitle>
                    <AlertDescription>{intl.messages[`${COMPONENT_INTL_KEY}.Alert.FailMessage`]}</AlertDescription>
                  </Alert>
                  <br />
                  <ul>
                    {response.faultyItems.map(function(item: any, i: string) {
                      return (
                        <li key={i}>
                          {item.referenceNumber} - {intl.messages[`${COMPONENT_INTL_KEY}.Alert.${item.errorCode}`]}
                        </li>
                      );
                    })}
                  </ul>
                </div>
              )}

              {response &&
                response.successfulItems.length != 0 &&
                response.successfulItems.length != refNumsArray.length && (
                  <div>
                    <Alert variant={'primary'} isOpen={true}>
                      <AlertIcon name="fas fa-exclamation-triangle" />
                      <AlertTitle>{intl.messages[`${COMPONENT_INTL_KEY}.Alert.Success`]}</AlertTitle>
                      <AlertDescription>{intl.messages[`${COMPONENT_INTL_KEY}.Alert.Message`]}</AlertDescription>
                    </Alert>
                    <br />
                    <ul>
                      {response.successfulItems.map(function(item: any, i: string) {
                        return <li key={i}>{item.referenceNumber}</li>;
                      })}
                    </ul>
                  </div>
                )}
            </FormControl>
          </Flex>
          <br />
          <Flex justifyContent="center" width="100%" px="13px">
            <Button
              ml="6"
              disabled={false}
              className="save"
              onClick={closeDialog}
              width={1}
              size="small"
              variant="success"
            >
              {intl.messages[`${COMPONENT_INTL_KEY}.ApproveButton`]}
            </Button>
          </Flex>
        </ModalContent>
      </Modal>
    </Box>
  );
};

import { ActionBar, Box, Flex, Panel, Widget } from '@oplog/express';
import { Resource } from '@oplog/resource-redux';
import { TenantDetailsQueryOutputDTODynamicQueryOutputDTO } from '@services';
import { dateToString } from '@utils';
import * as React from 'react';
import { MemberPageContainer } from '../../../containers/MemberPageContainer/MemberPageContainer';
import { Props } from '../../atoms/Component/Component';

export interface SupportProps extends Props {
  onSalesOrderClickHandler: () => void;
  onReceivingOrderClickHandler: () => void;
  onPackagesClickHandler: () => void;
  onDidMount: () => void;
  tenantInfo: Resource<TenantDetailsQueryOutputDTODynamicQueryOutputDTO>;
}

const COMPONENT_INTL_KEY: string = 'SupportPage';

export const Support: React.FC<SupportProps> = ({
  intl,
  onSalesOrderClickHandler,
  onReceivingOrderClickHandler,
  onPackagesClickHandler,
  onDidMount,
  tenantInfo,
  isBusy,
}) => {
  React.useEffect(() => {
    onDidMount();
  }, []);

  interface TenantInterface {
    id: string;
    name: string;
    customerCode: string;
    warehouseCode: string;
    warehouseLocation: string;
    salesOrdersLastSyncedAtFromWMS: any;
    packagesLastSyncedAtFromWMS: any;
    returnLastSyncedAtFromWMS: any;
    receivingOrdersLastSyncedAtFromWMS: any;
    inventoryLastSyncedAtFromWMS: any;
  }

  const [tenant, setTenantInfo] = React.useState<TenantInterface>({
    id: '',
    name: '',
    customerCode: '',
    warehouseCode: '',
    warehouseLocation: '',
    salesOrdersLastSyncedAtFromWMS: '',
    packagesLastSyncedAtFromWMS: '',
    returnLastSyncedAtFromWMS: '',
    receivingOrdersLastSyncedAtFromWMS: '',
    inventoryLastSyncedAtFromWMS: '',
  });

  React.useEffect(() => {
    if (tenantInfo && tenantInfo.data && tenantInfo.data.data) {
      const tenant = tenantInfo.data.data[0];
      setTenantInfo({
        ...tenant,
        id: tenant.id,
        name: tenant.name,
        customerCode: tenant.customerCode,
        warehouseCode: tenant.warehouseCode,
        warehouseLocation: tenant.warehouseLocation,
        salesOrdersLastSyncedAtFromWMS: tenant.salesOrdersLastSyncedAtFromWMS
          ? dateToString(tenant.salesOrdersLastSyncedAtFromWMS)
          : 'N/A',
        packagesLastSyncedAtFromWMS: tenant.packagesLastSyncedAtFromWMS
          ? dateToString(tenant.packagesLastSyncedAtFromWMS)
          : 'N/A',
        returnLastSyncedAtFromWMS: tenant.returnLastSyncedAtFromWMS
          ? dateToString(tenant.returnLastSyncedAtFromWMS)
          : 'N/A',
        receivingOrdersLastSyncedAtFromWMS: tenant.receivingOrdersLastSyncedAtFromWMS
          ? dateToString(tenant.receivingOrdersLastSyncedAtFromWMS)
          : 'N/A',
        inventoryLastSyncedAtFromWMS: tenant.inventoryLastSyncedAtFromWMS
          ? dateToString(tenant.inventoryLastSyncedAtFromWMS)
          : 'N/A',
      });
    }
  }, [tenantInfo]);

  const handleClick = (actionType: string) => {
    switch (actionType) {
      case intl.messages[`${COMPONENT_INTL_KEY}.SyncInfoWidget.Products`]: {
        break;
      }
      case intl.messages[`${COMPONENT_INTL_KEY}.SyncInfoWidget.Inventory`]: {
        break;
      }
      case intl.messages[`${COMPONENT_INTL_KEY}.SyncInfoWidget.SalesOrders`]: {
        onSalesOrderClickHandler();
        break;
      }
      case intl.messages[`${COMPONENT_INTL_KEY}.SyncInfoWidget.ReceivingOrders`]: {
        onReceivingOrderClickHandler();
        break;
      }
      case intl.messages[`${COMPONENT_INTL_KEY}.SyncInfoWidget.Packages`]: {
        onPackagesClickHandler();
        break;
      }
      default:
        break;
    }
  };

  const actions = [
    intl.messages[`${COMPONENT_INTL_KEY}.SyncInfoWidget.SalesOrders`],
    intl.messages[`${COMPONENT_INTL_KEY}.SyncInfoWidget.Products`],
    intl.messages[`${COMPONENT_INTL_KEY}.SyncInfoWidget.Inventory`],
    intl.messages[`${COMPONENT_INTL_KEY}.SyncInfoWidget.ReceivingOrders`],
    intl.messages[`${COMPONENT_INTL_KEY}.SyncInfoWidget.Packages`],
  ];
  const icons = ['fal fa-truck', 'fal fa-box-full', 'fal fa-boxes-alt', 'fal fa-warehouse-alt', 'fal fa-box'];

  const breadcrumb = [
    {
      title: intl.messages[`${COMPONENT_INTL_KEY}.Header.BreadcrumbTitle`],
    },
  ];

  const infoWidget = [
    { title: intl.messages[`${COMPONENT_INTL_KEY}.InfoWidget.Id`], value: tenant.id },
    { title: intl.messages[`${COMPONENT_INTL_KEY}.InfoWidget.Name`], value: tenant.name },
    { title: intl.messages[`${COMPONENT_INTL_KEY}.InfoWidget.Code`], value: tenant.warehouseCode },
    {
      title: intl.messages[`${COMPONENT_INTL_KEY}.InfoWidget.WarehouseLocation`],
      value: tenant.warehouseLocation ? tenant.warehouseLocation : 'N/A',
    },
  ];

  const syncInfoWidget = [
    {
      title: intl.messages[`${COMPONENT_INTL_KEY}.SyncInfoWidget.SalesOrders`],
      value: tenant.salesOrdersLastSyncedAtFromWMS,
    },
    {
      title: intl.messages[`${COMPONENT_INTL_KEY}.SyncInfoWidget.Packages`],
      value: tenant.packagesLastSyncedAtFromWMS,
    },
    {
      title: intl.messages[`${COMPONENT_INTL_KEY}.SyncInfoWidget.Return`],
      value: tenant.returnLastSyncedAtFromWMS,
    },
    {
      title: intl.messages[`${COMPONENT_INTL_KEY}.SyncInfoWidget.ReceivingOrders`],
      value: tenant.receivingOrdersLastSyncedAtFromWMS,
    },
    {
      title: intl.messages[`${COMPONENT_INTL_KEY}.SyncInfoWidget.Inventory`],
      value: tenant.inventoryLastSyncedAtFromWMS,
    },
  ];

  return (
    <MemberPageContainer documentTitle={intl.messages[`${COMPONENT_INTL_KEY}.Header.BreadcrumbTitle`]}>
      <ActionBar title={tenant.name} subtitle={''} breadcrumb={breadcrumb} />
      <Flex justifyContent="center" alignItems="center" flexDirection="column">
        <Flex flexDirection="row" flexWrap="wrap" mt="16" width={0.8}>
          <Flex flexDirection="column" width={[1, 1, 1 / 2]} p="16">
            <Widget.One
              panelProps={{ title: intl.messages[`${COMPONENT_INTL_KEY}.InfoWidget.Title`] }}
              column={2}
              fields={infoWidget}
              isLoading={isBusy}
            />
          </Flex>
          <Flex flexDirection="column" width={[1, 1, 1 / 2]} p="16">
            <Widget.One
              panelProps={{ title: intl.messages[`${COMPONENT_INTL_KEY}.SyncInfoWidget.Title`] }}
              column={2}
              fields={syncInfoWidget}
              isLoading={isBusy}
            />
          </Flex>
        </Flex>
        <Box width={0.8} mt="16">
          <Panel title={intl.messages[`${COMPONENT_INTL_KEY}.Header.PanelTitle`]} titleProps={{ pl: 16 }}>
            <Flex flexWrap="wrap">
              {actions.map((action, index) => (
                <Box width={[1 / 2]} p="16">
                  <Widget.Eight
                    hasTooltip={false}
                    isLoading={false}
                    icon={{
                      name: icons[index],
                      color: '#4A90E2',
                      fontSize: '24px',
                    }}
                    button={{
                      icon: { name: 'fas fa-angle-right', fontSize: '24px', color: '#4A90E2' },
                      variant: 'light',
                      onClick: () => handleClick(actions[index]),
                    }}
                    iconBoxProps={{ background: 'white', padding: '20px' }}
                    title={action}
                    subtitle={''}
                    borderRadius="12px"
                    onClick={() => handleClick(actions[index])}
                  />
                </Box>
              ))}
            </Flex>
          </Panel>
        </Box>
      </Flex>
    </MemberPageContainer>
  );
};

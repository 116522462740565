interface Option {
  label: string;
  value: string;
}

export function customi18nEnumFormatter(intl: any, stateEnum: any): Option[] {
  const optionsArray: Array<Option> = [];

  for (let state in stateEnum) {
    optionsArray.push({
      label: intl.messages[`Packages.PackageState.${state}`],
      value: state,
    });
  }

  return optionsArray;
}

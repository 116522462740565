/* eslint-disable @typescript-eslint/interface-name-prefix */

import React from 'react';
import globalHook, { Store } from 'use-global-hook';

export enum SendToWarehouseStateKeys {
  gridData = 'gridData',
  panelData = 'panelData',
}

export interface SendToWarehouseCardInfo {
  numOfTypes: number;
  numOfProducts: number;
}

interface GridArray {
  amount: number;
  row: any;
  sku: string;
}

/* Interfaces */
export interface SendToWarehouse {
  gridData: GridArray[];
  panelData: any[];
}

export interface SendToWarehouseActions {
  clearState: (data: Partial<SendToWarehouse>) => void;
  setStateData: (key: SendToWarehouseStateKeys, data: any) => void;
  clearData: () => void;
}

/* InitalStates */
export const initalState: SendToWarehouse = {
  gridData: [],
  panelData: [],
};

export const getSendToWarehouseCartGridInfo = (panelData: any[]): SendToWarehouseCardInfo => {
  if (panelData.length > 0) {
    const numOfTypes: number = panelData.length;
    let numOfProducts = 0;
    panelData.forEach(order => {
      numOfProducts += order.expectedQuantity;
    });

    return { numOfTypes, numOfProducts } as SendToWarehouseCardInfo;
  }

  return { numOfTypes: 0, numOfProducts: 0 } as SendToWarehouseCardInfo;
};

export const getSendToWarehousePanelData = (store: Store<SendToWarehouse, SendToWarehouseActions>) => {
  return store.state.panelData;
};

/* Actions */
export const initalActions: object = {
  clearState: (store: Store<SendToWarehouse, SendToWarehouseActions>, data: Partial<SendToWarehouse>) => {
    store.setState({ ...store.state, ...data });
  },

  setStateData: (store: Store<SendToWarehouse, SendToWarehouseActions>, key: SendToWarehouseStateKeys, data: any) => {
    store.setState({ ...store.state, [key]: data });
  },

  clearData: (store: Store<SendToWarehouse, SendToWarehouseActions>) => {
    store.setState({
      ...store.state,
      gridData: [...initalState.gridData],
      panelData: [...initalState.panelData],
    });
  },
};

/* Export */
const useSendToWarehouseState = globalHook<SendToWarehouse, SendToWarehouseActions>(React, initalState, initalActions);

export default useSendToWarehouseState;

import { Button, Datepicker, DATE_FORMAT, Flex, FormControl, FormLabel } from '@oplog/express';
import useSupportPackagesState, { SyncModalState } from '@store/supportPage/SupportPackagesState';
import React, { ReactElement } from 'react';
import { Props } from '../../../atoms/Component/Component';

interface DateAndIntegrationSelectionProps extends Props {
  COMPONENT_INTL_KEY: string;
  onClose: () => void;
  onSubmit: () => void;
}

const DateAndIntegrationSelection: React.FC<DateAndIntegrationSelectionProps> = ({
  intl,
  COMPONENT_INTL_KEY,
  onClose,
  onSubmit,
}): ReactElement => {
  const [state, actions] = useSupportPackagesState();

  const { endDate, startDate } = state.syncModal;

  const handleStartDateTimeChange = (date: Date) => {
    actions.setSyncModalData(SyncModalState.StartDate, date);
  };

  const handleEndDateTimeChange = (date: Date) => {
    actions.setSyncModalData(SyncModalState.EndDate, date);
  };

  const isSubmitDisabled = () => {
    const result = startDate === undefined || endDate === undefined;
    if (result) {
      return true;
    }
    return false;
  };

  return (
    <>
      <Flex flexDirection="column" flexWrap="wrap">
        <>
          <FormLabel mt="10px">{intl.messages[`${COMPONENT_INTL_KEY}.TimeRange`]}</FormLabel>
          <FormControl mt="10px" size="large" mb="20px" isRequired={true}>
            <FormLabel>{intl.messages[`${COMPONENT_INTL_KEY}.TimeStart`]}</FormLabel>
            <Datepicker
              dateFormat={DATE_FORMAT}
              popperPlacement="top-start"
              popperProps={{
                positionFixed: true,
              }}
              selected={startDate}
              onChange={handleStartDateTimeChange}
              showTimeSelect
              showDisabledMonthNavigation
              inputFormat={DATE_FORMAT}
              maxDate={new Date()}
            />

            <FormLabel mt="10px">{intl.messages[`${COMPONENT_INTL_KEY}.TimeEnd`]}</FormLabel>
            <Datepicker
              dateFormat={DATE_FORMAT}
              popperPlacement="top-start"
              selected={endDate}
              onChange={handleEndDateTimeChange}
              popperProps={{
                positionFixed: true,
              }}
              disabled={startDate === undefined}
              showTimeSelect
              showDisabledMonthNavigation
              inputFormat={DATE_FORMAT}
              maxDate={new Date()}
              minDate={startDate}
            />
          </FormControl>

          <Flex justifyContent="center" width="100%" px="13px">
            <Button className="cancel" onClick={onClose} kind="outline" variant="dark" width={1} size="small">
              {intl.messages[`${COMPONENT_INTL_KEY}.CancelButton`]}
            </Button>
            <Button
              ml="6"
              disabled={isSubmitDisabled()}
              className="save"
              onClick={onSubmit}
              width={1}
              size="small"
              variant="success"
            >
              {intl.messages[`${COMPONENT_INTL_KEY}.ApproveButton`]}
            </Button>
          </Flex>
        </>
      </Flex>
    </>
  );
};

export default DateAndIntegrationSelection;

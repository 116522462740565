/* eslint-disable react/prefer-stateless-function */
import { GridContainer } from '@components/atoms/GridContainer';
import { geti18nName, InvalidSalesOrderDetailsLinkFormatter, sourceIconFormatter } from '@containers/formatters';
import { booleanFormatter } from '@containers/formatters/booleanFormatter';
import { GridProps, GridType } from '@models';
import {
  ColumnSize,
  DataGridColumn,
  DataGridRow,
  dateTimeFormatter,
  enumFormatter,
  getEnumOptions,
  PredefinedFilter,
} from '@oplog/data-grid';
import { OmnitroErrorCode } from '@services';
import { generateIntegrationEnum } from '@utils';
import { SortDirection, SortField } from 'dynamic-query-builder-client';
import * as React from 'react';

const intlKey = 'QuarantinaGrid';

const quarantinaGridInitalSort: SortField = new SortField({
  property: 'createdAt',
  by: SortDirection.DESC,
});

export const quarantinaGridPredefinedFilters: Array<PredefinedFilter> = [];

interface SalesOrdersGridProps extends GridProps {
  integrations: any;
}

export const SalesOrderQuarantineGrid: React.FC<SalesOrdersGridProps> = ({ integrations, intl, titleKey }) => {
  const quarantinaGridColumns: Array<DataGridColumn> = [
    {
      name: '#',
      key: 'index',
      resizable: true,
      locked: true,
      sortable: false,
      type: 'number',
      visibility: true,
      filterable: false,
      width: ColumnSize.Medium,
      cellClass: 'index-column',
    },
    {
      name: geti18nName('referenceNumber', intl, intlKey),
      key: 'referenceNumber',
      resizable: true,
      locked: true,
      sortable: true,
      type: 'string',
      visibility: true,
      filterable: true,
      formatter: InvalidSalesOrderDetailsLinkFormatter,
      getRowMetaData: (row: DataGridRow) => row,
    },
    {
      name: geti18nName('integrationType', intl, intlKey),
      key: 'integrationName',
      locked: true,
      sortable: true,
      type: 'enum',
      visibility: true,
      filterable: true,
      resizable: true,
      width: ColumnSize.Large,
      formatter: row => sourceIconFormatter(row.value, row.dependentValues, intl),
      getRowMetaData: (row: DataGridRow) => row,
      options: integrations ? generateIntegrationEnum(integrations) : [],
    },
    {
      name: geti18nName('salesChannel', intl, intlKey),
      key: 'salesChannel',
      locked: true,
      sortable: true,
      type: 'string',
      visibility: true,
      filterable: true,
      resizable: true,
      getRowMetaData: (row: DataGridRow) => row['integrationType'],
    },
    {
      name: geti18nName('customerFullName', intl, intlKey),
      key: 'customerFullName',
      locked: true,
      sortable: true,
      type: 'string',
      visibility: true,
      filterable: true,
      resizable: true,
    },
    {
      name: geti18nName('paymentGatewayNames', intl, intlKey),
      key: 'isPaid',
      locked: true,
      sortable: true,
      type: 'boolean',
      visibility: true,
      filterable: true,
      formatter: booleanFormatter,
      getRowMetaData: () => {
        return { intl, negativeIntlKey: 'Enum.Future', positiveIntlKey: 'Enum.Paid' };
      },
    },
    {
      name: geti18nName('error', intl, intlKey),
      key: 'error',
      locked: true,
      sortable: true,
      type: 'string',
      visibility: true,
      filterable: true,
      resizable: true,
      formatter: enumFormatter,
      options: getEnumOptions(intl, OmnitroErrorCode),
      getRowMetaData: () => {
        return intl;
      },
    },
    {
      name: geti18nName('createdAt', intl, intlKey),
      key: 'createdAt',
      locked: true,
      sortable: true,
      type: 'moment',
      visibility: true,
      filterable: true,
      resizable: true,
      formatter: dateTimeFormatter,
    },
  ];

  return (
    <GridContainer
      titleKey={
        titleKey
          ? `${intl.messages['SalesOrders.Tabs.Quarantine']} - ${titleKey}`
          : intl.messages['SalesOrders.Tabs.Quarantine']
      }
      intlKey={intlKey}
      remoteExportToExcel
      gridKey={GridType.SalesOrderQuarantine}
      columns={quarantinaGridColumns}
      predefinedFilters={quarantinaGridPredefinedFilters}
      intl={intl}
      sortField={quarantinaGridInitalSort}
    />
  );
};

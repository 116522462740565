import { FormatterProps } from '@oplog/data-grid';
import * as React from 'react';

export function returnCountFormatter(props: FormatterProps) {
  if (props) {
    if (props.value >= 1) {
      return <>{props.value}</>;
    }
    return <>-</>;
  }
  return <></>;
}

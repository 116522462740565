import { resourceActions } from '@oplog/resource-redux';
import { Customers, CustomersProps } from '@pages/Customers';
import { urls } from '@routes/urls';
import { push, RouterAction } from 'connected-react-router';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

export function mapDispatchToProps(
  dispatch: Dispatch<resourceActions.ResourceAction | RouterAction>
): Partial<CustomersProps> {
  return {
    onCreateCustomerClickHandler: () => {
      dispatch(push(urls.createCustomer));
    },
  };
}

export const CustomersContainer = connect(null, mapDispatchToProps)(injectIntl(Customers));

import { CreateSalesOrderContextualPanel, CreateSalesOrderContextualPanelProps } from '@components';
import { ResourceType } from '@models';
import { fileUploadInit, fileUploadRequested, FileUploadsAction, getUrl, isBusy } from '@oplog/file-uploader';
import { resourceActions, resourceSelectors } from '@oplog/resource-redux';
import { urls } from '@routes/urls';
import { CreateSalesOrderInputDTO } from '@services';
import { StoreState } from '@store';
import { customerDetailsAction, CustomerDetailsAction, customerDetailsSelectors } from '@store/customerDetails';
import { push, RouterAction } from 'connected-react-router';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

const FILE_ID = 'orderDeliveryInfo';
const VAS_FILE_ID = 'orderDeliveryInfoVas';

export interface CreateSalesOrderContextualPanelContainerProps {
  onPostError: () => void;
  closeErrorAlert: () => void;
}

export function mapStateToProps({
  resources,
  files,
  customerDetails,
}: StoreState): Partial<CreateSalesOrderContextualPanelProps> {
  return {
    tenantDetails: resourceSelectors.getData(resources, ResourceType.TenantGetDetails),
    isBusy:
      resourceSelectors.isBusy(resources, ResourceType.CreateSalesOrder) ||
      resourceSelectors.isBusy(resources, ResourceType.CustomerInfo) ||
      resourceSelectors.isBusy(resources, ResourceType.CustomerShippingAddresses),
    error:
      resourceSelectors.getError(resources, ResourceType.CreateSalesOrder) ||
      resourceSelectors.getError(resources, ResourceType.UpdateCustomerInfo),
    createdSalesOrderData: resourceSelectors.getData(resources, ResourceType.CreateSalesOrder),
    customers: {
      isBusy: resourceSelectors.isBusy(resources, ResourceType.SearchCustomers),
      error: resourceSelectors.getError(resources, ResourceType.SearchCustomers),
      data: resourceSelectors.getData(resources, ResourceType.SearchCustomers),
    },
    customerInfo: resourceSelectors.getData(resources, ResourceType.CustomerInfo),
    shippingAddresses: {
      isBusy: resourceSelectors.isBusy(resources, ResourceType.CustomerShippingAddresses),
      error: resourceSelectors.getError(resources, ResourceType.CustomerShippingAddresses),
      data: resourceSelectors.getData(resources, ResourceType.CustomerShippingAddresses),
    },
    isEditCustomerInfoUpdateSuccess: resourceSelectors.isSuccessComplete(resources, ResourceType.UpdateCustomerInfo),
    isNewAddressCreateSuccess: resourceSelectors.isSuccessComplete(
      resources,
      ResourceType.AddNewCustomerShippingAddress
    ),
    isNewAddressModalOpen: customerDetailsSelectors.isNewCreateShippingAddressOpen(customerDetails),

    isUploading: isBusy(files, FILE_ID),
    fileUrl: getUrl(files, FILE_ID),
    isUploadingVas: isBusy(files, VAS_FILE_ID),
    fileUrlVas: getUrl(files, VAS_FILE_ID),

    salesChannelsOutput: resourceSelectors.getData(resources, ResourceType.SalesChannels),
    cargoCarriersOutput: resourceSelectors.getData(resources, ResourceType.CargoCarriers),
  };
}

export function mapDispatchToProps(
  dispatch: Dispatch<FileUploadsAction | resourceActions.ResourceAction | RouterAction | CustomerDetailsAction>,
  ownProps: CreateSalesOrderContextualPanelContainerProps
): Partial<CreateSalesOrderContextualPanelProps> {
  return {
    onPostError(): void {
      ownProps.onPostError();
    },

    getTenantDetails: () => {
      dispatch(resourceActions.resourceRequested(ResourceType.TenantGetDetails));
    },

    getCargoCarrierAndSalesChannels(): void {
      dispatch(resourceActions.resourceRequested(ResourceType.SalesChannels));
      dispatch(resourceActions.resourceRequested(ResourceType.CargoCarriers));
    },

    closeErrorAlert(): void {
      ownProps.closeErrorAlert();
    },
    onDidMount(): void {
      dispatch(fileUploadInit());
      dispatch(
        resourceActions.resourceRequested(ResourceType.SearchCustomers, {
          text: null,
        })
      );
    },
    redirectOnSalesOrderCreateSuccess: (referenceNumber: string): void => {
      dispatch(push(urls.salesOrderDetails.replace(':referenceNumber', `${referenceNumber}`)));
    },
    searchCustomers(text: string): void {
      dispatch(
        resourceActions.resourceRequested(ResourceType.SearchCustomers, {
          text,
        })
      );
    },
    getAllCustomerShippingAddressDetails: (customerNo: number): void => {
      dispatch(
        resourceActions.resourceRequested(ResourceType.CustomerShippingAddresses, {
          customerNo,
        })
      );
    },
    getCustomerInfo(customerNo: number): void {
      dispatch(
        resourceActions.resourceRequested(ResourceType.CustomerInfo, {
          customerNo,
        })
      );
    },

    onCreateSalesOrderCartData(salesOrders: CreateSalesOrderInputDTO): void {
      ownProps.closeErrorAlert();
      dispatch(
        resourceActions.resourceRequested(ResourceType.CreateSalesOrder, {
          salesOrders,
        })
      );
    },
    onEditCustomerInfoSuccessModalClose: (customerNo: number, text: string) => {
      dispatch(resourceActions.resourceInit(ResourceType.UpdateCustomerInfo));
      dispatch(resourceActions.resourceInit(ResourceType.CustomerInfo));
      dispatch(
        resourceActions.resourceRequested(ResourceType.SearchCustomers, {
          text,
        })
      );
      dispatch(
        resourceActions.resourceRequested(ResourceType.CustomerInfo, {
          customerNo,
        })
      );
    },
    onNewAddresClick: () => {
      dispatch(customerDetailsAction.openNewAddressModal());
    },
    onNewAddressModalClose: () => {
      dispatch(customerDetailsAction.closeNewAddressModal());
    },
    onCreateShippingAddressModalClose: (customerNo: number) => {
      ownProps.closeErrorAlert();
      dispatch(resourceActions.resourceInit(ResourceType.AddNewCustomerShippingAddress));
      dispatch(
        resourceActions.resourceRequested(ResourceType.CustomerShippingAddresses, {
          customerNo,
        })
      );
    },
    onWillUnmount: () => {
      dispatch(resourceActions.resourceInit(ResourceType.CreateSalesOrder));
      dispatch(resourceActions.resourceInit(ResourceType.CustomerShippingAddresses));
      dispatch(resourceActions.resourceInit(ResourceType.SearchCustomers));
      dispatch(resourceActions.resourceInit(ResourceType.CustomerInfo));
      ownProps.closeErrorAlert();
    },
    onReset: () => dispatch(fileUploadInit()),
    onFileSelect: (file: File) => {
      dispatch(fileUploadRequested(FILE_ID, file));
    },
    onVasReset: () => dispatch(fileUploadInit()),
    onVasFileSelect: (file: File) => {
      dispatch(fileUploadRequested(VAS_FILE_ID, file));
    },
  };
}

export const CreateSalesOrderContextualPanelContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(CreateSalesOrderContextualPanel));

import { CargoType, TransferInfoForm, TransferType } from '@components/molecules/TransferInfoForm';
import {
  Button,
  convertLocalToUtc,
  convertUtcToLocal,
  Dialog,
  DialogTypes,
  Flex,
  Icon,
  isDate,
  Modal,
  ModalContent,
  ModalHeader,
  ModalTitle,
  Text,
} from '@oplog/express';
import {
  CargoTransferDTO,
  CarriageTransferDTO,
  CreateReceivingOrderLineItemInputDTO,
  PickupTransferDTO,
  ReceivingOrderTransferType,
  UpdateReceivingOrderTransferDetailCommand,
} from '@services';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Props } from '../../atoms/Component/Component';

const COMPONENT_INTL_KEY = 'ReceivingOrderDetails';
const COMPONENT_INTL_KEY2 = 'WarehouseContextualPanel';

export interface TransferInfoModalProps extends Props {
  isModalOpen: boolean;
  toggleTransferDetailsModal: () => void;
  referenceNumber: string;
  lineItems?: Array<CreateReceivingOrderLineItemInputDTO>;
  transferType: ReceivingOrderTransferType;
  transferDetails: CargoTransferDTO | CarriageTransferDTO | PickupTransferDTO;
  onUpdateTransferDetails: (receivingOrderTransferDetails: UpdateReceivingOrderTransferDetailCommand) => void;
  isSuccess: boolean;
  initResources: () => void;
  isSendToWarehouse?: boolean;
}

export interface TransferInfoModalState {
  selectedTransferOption?: TransferType;
  selectedCargoOption?: CargoType;
  selectedDate: {
    cargo?: Date;
    carriage?: Date;
    pickup?: Date;
  };
  cargoFormCompany: {
    cargo?: string;
    carriage?: string;
  };
  cargoFormNumber: {
    cargo?: string;
    carriage?: string;
  };
  cargoFormPickupAddress?: string;
  modals: Modals;
}

export interface Modals {
  confirmationModal: boolean;
  successModal: boolean;
}

export const TransferInfoModal: React.FC<TransferInfoModalProps> = ({
  isBusy,
  intl,
  isModalOpen,
  toggleTransferDetailsModal,
  referenceNumber,
  lineItems,
  transferType,
  transferDetails,
  onUpdateTransferDetails,
  isSuccess,
  initResources,
  isSendToWarehouse,
}) => {
  const [selectedCargoOption, setSelectedCargoOption] = useState<CargoType>(CargoType.Cargo);
  const [selectedTransferOption, setSelectedTransferOption] = useState<TransferType>(TransferType.Cargo);
  const [cargoFormCompany, setCargoFormCompany] = useState<any>({
    cargo: undefined,
    carriage: undefined,
  });

  const [cargoFormNumber, setCargoFormNumber] = useState<any>({
    cargo: undefined,
    carriage: undefined,
  });

  const [selectedDate, setSelectedDate] = useState<any>({
    cargo: undefined,
    carriage: undefined,
    pickup: undefined,
  });

  const [modal, setModalState] = useState({
    confirmationModal: false,
    successModal: false,
  });

  const [cargoFormPickupAddress, setCargoFormPickupAddress] = useState<any>(undefined);

  useEffect(() => {
    if (!isModalOpen) {
      setModalState({
        confirmationModal: false,
        successModal: false,
      });
    }
  }, [isModalOpen]);

  useEffect(() => {
    if (transferDetails) {
      switch (transferType) {
        case ReceivingOrderTransferType.CargoTransfer: {
          setSelectedCargoOption(CargoType.Cargo);
          setSelectedTransferOption(TransferType.Cargo);
          setCargoFormCompany({
            cargo: (transferDetails as CargoTransferDTO).cargoCompanyName,
            carriage: undefined,
          });

          setCargoFormNumber({
            cargo: (transferDetails as CargoTransferDTO).trackingNumber,
            carriage: undefined,
          });

          setSelectedDate({
            cargo: isDate((transferDetails as CargoTransferDTO).shipmentDate!)
              ? convertUtcToLocal((transferDetails as CargoTransferDTO).shipmentDate!)
              : undefined,
            carriage: undefined,
            pickup: undefined,
          });
          setCargoFormPickupAddress(undefined);
          break;
        }
        case ReceivingOrderTransferType.CarriageTransfer: {
          setSelectedCargoOption(CargoType.SelfShipment);
          setSelectedTransferOption(TransferType.Cargo);

          setCargoFormCompany({
            carriage: (transferDetails as CarriageTransferDTO).carriageCompanyName,
            cargo: undefined,
          });

          setCargoFormNumber({
            carriage: (transferDetails as CarriageTransferDTO).waybillNumber,
            cargo: undefined,
          });

          setSelectedDate({
            carriage: isDate((transferDetails as CarriageTransferDTO).shipmentDate!)
              ? convertUtcToLocal((transferDetails as CarriageTransferDTO).shipmentDate!)
              : undefined,
            cargo: undefined,
            pickup: undefined,
          });
          setCargoFormPickupAddress(undefined);
          break;
        }
        case ReceivingOrderTransferType.PickupTransfer: {
          setSelectedCargoOption(CargoType.Cargo);
          setSelectedTransferOption(TransferType.Pickup);

          setCargoFormCompany({
            carriage: undefined,
            cargo: undefined,
          });

          setCargoFormNumber({
            carriage: undefined,
            cargo: undefined,
          });

          setSelectedDate({
            pickup: isDate((transferDetails as PickupTransferDTO).pickupDate!)
              ? convertUtcToLocal((transferDetails as PickupTransferDTO).pickupDate!)
              : undefined,
            cargo: undefined,
            carriage: undefined,
          });

          setCargoFormPickupAddress((transferDetails as PickupTransferDTO).pickupAddress);
          break;
        }
        default: {
          break;
        }
      }
    }
  }, [transferDetails]);

  const handleRadioChange = (e: React.SyntheticEvent<any> | any) => {
    setSelectedCargoOption(e.currentTarget.value);
  };

  const handleCompanyNameChange = (e: React.SyntheticEvent<HTMLInputElement>) => {
    const companyName = e.currentTarget.value.length > 0 ? e.currentTarget.value : undefined;

    if (selectedCargoOption === CargoType.Cargo) {
      setCargoFormCompany({
        cargo: companyName,
        carriage: cargoFormCompany.carriage,
      });
    } else {
      setCargoFormCompany({
        cargo: cargoFormCompany.cargo,
        carriage: companyName,
      });
    }
  };

  const handlePickupAddressChange = (e: React.SyntheticEvent<HTMLInputElement>) => {
    setCargoFormPickupAddress(e.currentTarget.value);
  };

  const onSuccessClose = () => {
    initResources();
    setModalState({
      ...modal,
      confirmationModal: false,
    });
    toggleTransferDetailsModal();
  };

  const handleDateTimeChange = (date: Date) => {
    if (!selectedTransferOption) {
      return;
    }

    if (selectedTransferOption === TransferType.Pickup) {
      setSelectedDate({
        pickup: date,
        cargo: selectedDate.cargo,
        carriage: selectedDate.carriage,
      });
    } else {
      if (selectedCargoOption === CargoType.Cargo) {
        setSelectedDate({
          cargo: date,
          pickup: selectedDate.pickup,
          carriage: selectedDate.carriage,
        });
      } else {
        // CargoType.SelfShipment
        setSelectedDate({
          carriage: date,
          cargo: selectedDate.cargo,
          pickup: selectedDate.pickup,
        });
      }
    }
  };

  const handleWaybillOrTrackingNumberChange = (e: React.SyntheticEvent<HTMLInputElement>) => {
    const formNumber = e.currentTarget.value.length > 0 ? e.currentTarget.value : undefined;

    if (selectedCargoOption === CargoType.Cargo) {
      setCargoFormNumber({
        cargo: formNumber,
        carriage: cargoFormNumber.carriage,
      });
    } else {
      setCargoFormNumber({
        cargo: cargoFormNumber.cargo,
        carriage: formNumber,
      });
    }
  };

  const handleSaveButtonClick = () => {
    let transferInfo: UpdateReceivingOrderTransferDetailCommand | undefined;

    if (selectedTransferOption === TransferType.Pickup) {
      const pickupSelectedDate = selectedDate.pickup ? new Date(convertLocalToUtc(selectedDate.pickup)) : undefined;
      transferInfo = {
        transferType: ReceivingOrderTransferType.PickupTransfer,
        pickupTransfer: {
          pickupDate: pickupSelectedDate,
          pickupAddress: cargoFormPickupAddress,
        } as PickupTransferDTO,
        referenceNumber: referenceNumber,
        lineItems: lineItems
      };
    } else {
      if (selectedCargoOption === CargoType.Cargo) {
        const cargoSelectedDate = selectedDate.cargo ? new Date(selectedDate.cargo) : undefined;
        transferInfo = {
          transferType: ReceivingOrderTransferType.CargoTransfer,
          cargoTransfer: {
            cargoCompanyName: cargoFormCompany.cargo,
            shipmentDate: cargoSelectedDate,
            trackingNumber: cargoFormNumber.cargo,
          } as CargoTransferDTO,
          referenceNumber: referenceNumber,
          lineItems: lineItems
        };
      }

      if (selectedCargoOption === CargoType.SelfShipment) {
        const carriageoSelectedDate = selectedDate.carriage ? new Date(selectedDate.carriage) : undefined;
        transferInfo = {
          transferType: ReceivingOrderTransferType.CarriageTransfer,
          carriageTransfer: {
            carriageCompanyName: cargoFormCompany.carriage,
            shipmentDate: carriageoSelectedDate,
            waybillNumber: cargoFormNumber.carriage,
          } as CarriageTransferDTO,
          referenceNumber: referenceNumber,
          lineItems: lineItems
        };
      }
    }

    if (transferInfo) {
      onUpdateTransferDetails(transferInfo);
    }
  };

  const handleCancelButtonClick = () => {
    toggleTransferDetailsModal();
  };

  const handleTransferDetailsEditModalClose = () => {
    toggleTransferDetailsModal();

    if (transferDetails) {
      switch (transferType) {
        case ReceivingOrderTransferType.CargoTransfer: {
          setCargoFormCompany({
            cargo: (transferDetails as CargoTransferDTO).cargoCompanyName,
            carriage: undefined,
          });

          setCargoFormNumber({
            cargo: (transferDetails as CargoTransferDTO).trackingNumber,
            carriage: undefined,
          });

          setCargoFormPickupAddress(undefined);
          setSelectedDate({
            cargo: isDate((transferDetails as CargoTransferDTO).shipmentDate!)
              ? convertUtcToLocal((transferDetails as CargoTransferDTO).shipmentDate!)
              : undefined,
            carriage: undefined,
            pickup: undefined,
          });

          setSelectedCargoOption(CargoType.Cargo);
          setSelectedTransferOption(TransferType.Cargo);
          break;
        }
        case ReceivingOrderTransferType.CarriageTransfer: {
          setCargoFormCompany({
            carriage: (transferDetails as CarriageTransferDTO).carriageCompanyName,
            cargo: undefined,
          });

          setCargoFormNumber({
            carriage: (transferDetails as CarriageTransferDTO).waybillNumber,
            cargo: undefined,
          });

          setCargoFormPickupAddress(undefined);

          setSelectedDate({
            carriage: isDate((transferDetails as CarriageTransferDTO).shipmentDate!)
              ? convertUtcToLocal((transferDetails as CarriageTransferDTO).shipmentDate!)
              : undefined,
            cargo: undefined,
            pickup: undefined,
          });

          setSelectedCargoOption(CargoType.SelfShipment);
          setSelectedTransferOption(TransferType.Cargo);
          break;
        }
        default: {
          break;
        }
      }
    }
  };

  const getSelectedDateAccordingToType = () => {
    if (selectedTransferOption === TransferType.Pickup) {
      return selectedDate.pickup;
    }
    if (selectedCargoOption === CargoType.Cargo) {
      return selectedDate.cargo;
    }
    return selectedDate.carriage;
  };

  const getCompanyNameAccordingToType = () => {
    if (selectedCargoOption === CargoType.Cargo) {
      return cargoFormCompany.cargo;
    }
    return cargoFormCompany.carriage;
  };

  const getFormNumberAccordingToType = () => {
    if (selectedCargoOption === CargoType.Cargo) {
      return cargoFormNumber.cargo;
    }
    return cargoFormNumber.carriage;
  };

  return (
    <>
      <Modal
        size="md"
        isOpen={isModalOpen}
        onClose={handleTransferDetailsEditModalClose}
        disableEscapeButtonClose={true}
        disableOutsideMouseEvents={true}
      >
        <ModalHeader>
          <ModalTitle>
            {isSendToWarehouse
              ? intl.messages[`${COMPONENT_INTL_KEY}.Section.Transfer.EditModal.SendToWarehouseTitle`]
              : intl.messages[`${COMPONENT_INTL_KEY}.Section.Transfer.EditModal.Title`]}
          </ModalTitle>
        </ModalHeader>
        <ModalContent p="22">
          {/* {isSendToWarehouse && (
            <Flex marginBottom="20px">
              <Text color="palette.green_dark" fontWeight={700}>
                <Icon name="fas fa-check" />{' '}
                {intl.messages[`${COMPONENT_INTL_KEY}.Section.Transfer.EditModal.SendToWarehouseMessage`]}
              </Text>
            </Flex>
          )} */}
          <TransferInfoForm
            intl={intl}
            intlKey={COMPONENT_INTL_KEY2}
            selectedCargoOption={selectedCargoOption}
            selectedTransferOption={TransferType.Cargo}
            selectedDate={getSelectedDateAccordingToType()}
            cargoFormCompany={getCompanyNameAccordingToType()}
            cargoFormNumber={getFormNumberAccordingToType()}
            cargoFormPickupAddress={cargoFormPickupAddress}
            onCargoFormRadioChange={handleRadioChange}
            onCompanyNameChange={handleCompanyNameChange}
            onWaybillOrTrackingNumberChange={handleWaybillOrTrackingNumberChange}
            onDateTimeChange={handleDateTimeChange}
            onPickupAddressChange={handlePickupAddressChange}
          />
          <Flex>
            {/* <Button
              size="small"
              kind="outline"
              variant="dark"
              onClick={handleCancelButtonClick}
              disabled={isBusy}
              mr="11"
              width="75%"
            >
              {intl.messages[`${COMPONENT_INTL_KEY}.Section.Transfer.EditModal.CancelButton`]}
            </Button> */}
            <Button
              size="small"
              variant="success"
              disabled={getSelectedDateAccordingToType() ? false : true}
              onClick={() =>
                setModalState({
                  ...modal,
                  confirmationModal: true,
                })
              }
              width="full"
            >
              {intl.messages[`${COMPONENT_INTL_KEY}.Section.Transfer.EditModal.SaveButton`]}
            </Button>
          </Flex>
        </ModalContent>
      </Modal>
      {modal.confirmationModal && isModalOpen && (
        <Dialog
          type={DialogTypes.Confirmation}
          icon="fas fa-pallet"
          isLoading={isBusy}
          onCancel={() =>
            setModalState({
              ...modal,
              confirmationModal: false,
            })
          }
          onApprove={handleSaveButtonClick}
          text={{
            approve: intl.messages[`Modal.Confirmation.Okay`],
            cancel: intl.messages[`Modal.Confirmation.Cancel`],
          }}
          isOpen
          message={
            <FormattedMessage
              id={
                isSendToWarehouse
                  ? `${COMPONENT_INTL_KEY}.Section.SendToWarehouseConfirmationDialog`
                  : `${COMPONENT_INTL_KEY}.Section.ConfirmationDialog`
              }
              values={{
                no: <b>{referenceNumber}</b>,
              }}
            />
          }
        />
      )}
      {isSuccess && isModalOpen && (
        <Dialog
          type={DialogTypes.Success}
          message={
            <FormattedMessage
              id={
                isSendToWarehouse
                  ? `${COMPONENT_INTL_KEY}.Section.SendToWarehouseSuccessModal`
                  : `${COMPONENT_INTL_KEY}.Section.SuccessModal`
              }
              values={{
                no: <b>{referenceNumber}</b>,
              }}
            />
          }
          isOpen
          onApprove={onSuccessClose}
          text={{ approve: intl.messages[`Modal.Success.Okay`] }}
        />
      )}
    </>
  );
};

import { GridContainer } from '@components/atoms/GridContainer';
import { barcodeFormatter, geti18nName, ProductDetailsLinkFormatter } from '@containers/formatters';
import { GridProps, GridType } from '@models';
import { ColumnSize, DataGridColumn, DataGridRow } from '@oplog/data-grid';
import { ArrayFilterOperation } from 'dynamic-query-builder-client';
import * as React from 'react';

const intlKey = 'SalesOrderSerialNumbersGrid';

export interface SalesOrderSerialNumbersGridProps extends GridProps {
  referenceNumber: string;
  isPending: boolean;
  isPackage?: boolean;
  headerContent?: React.ReactNode;
  packageless?: boolean;
}

export const SalesOrderSerialNumbersGrid: React.FC<SalesOrderSerialNumbersGridProps> = ({
  intl,
  titleKey,
  referenceNumber,
  packageless,
  isPackage,
  headerContent,
}) => {
  const salesOrderSerialNumbersGridColumns: Array<DataGridColumn> = [
    {
      name: '#',
      key: 'index',
      resizable: true,
      locked: true,
      sortable: false,
      type: 'number',
      visibility: true,
      filterable: false,
      width: ColumnSize.Medium,
      cellClass: 'index-column',
    },
    {
      name: geti18nName('sku', intl, intlKey),
      key: 'sku',
      locked: true,
      sortable: true,
      type: 'string',
      visibility: true,
      filterable: true,
      resizable: true,
    },
    {
      name: geti18nName('barcode', intl, intlKey),
      key: 'barcodes',
      locked: true,
      type: 'string',
      visibility: true,
      resizable: true,
      sortable: false,
      filterable: true,
      formatter: barcodeFormatter,
      fieldType: 'array',
      searchField: '_',
      outerOp: ArrayFilterOperation.Any,
    },
    {
      name: geti18nName('productName', intl, intlKey),
      key: 'productName',
      locked: true,
      sortable: true,
      type: 'string',
      visibility: true,
      filterable: true,
      formatter: ProductDetailsLinkFormatter,
      getRowMetaData: (row: DataGridRow) => row,
      resizable: true,
    },
    {
      name: geti18nName('serialNumber', intl, intlKey),
      key: 'serialNumber',
      locked: true,
      sortable: true,
      type: 'string',
      visibility: true,
      filterable: true,
      resizable: true,
    },
  ];

  const packageArg = packageless || true;
  const rowRenderer = ({ ...props }: any) => {
    return props.isPendingReason ? 'productStockWarning' : '';
  };

  return (
    <GridContainer
      titleKey={
        titleKey
          ? `${intl.messages['SalesOrderDetails.Grid.Title']} - ${titleKey}`
          : intl.messages['SalesOrderDetails.Grid.Title']
      }
      remoteExportToExcel
      intlKey={intlKey}
      gridKey={GridType.SalesOrderSerialNumbers}
      columns={salesOrderSerialNumbersGridColumns}
      predefinedFilters={[]}
      filtersDisabled
      apiArgs={isPackage ? [referenceNumber] : [referenceNumber, packageArg]}
      intl={intl}
      headerContent={headerContent}
      rowRenderer={rowRenderer}
    />
  );
};

export default SalesOrderSerialNumbersGrid;

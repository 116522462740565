import { Flex, Icon, Modal, ModalContent, ModalFooter, ModalHeader, Text } from '@oplog/express';
import { Resource } from '@oplog/resource-redux';
import { IntegrationOutputDTO } from '@services';
import useSupportPackagesState from '@store/supportPage/SupportPackagesState';
import React, { ReactElement } from 'react';
import { Props } from '../../atoms/Component/Component';
import DateAndIntegrationSelection from './bones/DateAndIntegrationSelection';
import DateAndIntegrationSelectionConfirmation from './bones/DateAndIntegrationSelectionConfirmation';

export interface SupportTimeRangeSubmitModalProps extends Props {
  onClose: () => void;
  hasError: any;
  integrations: Resource<Array<IntegrationOutputDTO>>;
  responseData: any;
  COMPONENT_INTL_KEY: string;
  syncCommandTimeRange: (startDate: Date, endDate: Date) => void;
}

export interface SupportTimeRangeSubmitModalState {}

export const SupportTimeRangeSubmitModal: React.FC<SupportTimeRangeSubmitModalProps> = ({
  onClose,
  COMPONENT_INTL_KEY,
  syncCommandTimeRange,
  intl,
  isBusy,
  onDidMount,
  responseData,
  hasError,
  onWillUnmount,
}): ReactElement => {
  const [state, actions] = useSupportPackagesState();
  const [step, setStep] = React.useState(0);
  const { endDate, startDate } = state.syncModal;

  React.useEffect(() => {
    onDidMount && onDidMount();
  }, []);

  const onSubmit = () => {
    if (startDate && endDate) {
      syncCommandTimeRange(startDate, endDate);
      setStep(1);
    }
  };

  const closeModal = () => {
    onWillUnmount && onWillUnmount();
    actions.clearSyncModal();
    onClose();
  };

  return (
    <Modal onClose={closeModal} isOpen={true}>
      <ModalHeader>{intl.messages[`${COMPONENT_INTL_KEY}.Title`]}</ModalHeader>

      <ModalContent>
        {step == 0 && !isBusy && (
          <DateAndIntegrationSelection
            COMPONENT_INTL_KEY={COMPONENT_INTL_KEY}
            intl={intl}
            onClose={closeModal}
            onSubmit={onSubmit}
          ></DateAndIntegrationSelection>
        )}
        {step == 1 && responseData && !isBusy && (
          <DateAndIntegrationSelectionConfirmation
            COMPONENT_INTL_KEY={COMPONENT_INTL_KEY}
            intl={intl}
            responseData={responseData}
            onClose={closeModal}
          ></DateAndIntegrationSelectionConfirmation>
        )}
        {isBusy && <Text>{intl.messages[`${COMPONENT_INTL_KEY}.Loading`]}</Text>}
        {hasError && <Text>{intl.messages[`${COMPONENT_INTL_KEY}.ErrorCodes.UnHandledError`]}</Text>}
      </ModalContent>
      <ModalFooter borderTop="xs" borderColor="palette.snow_light">
        <Flex>
          <Icon mr="8px" name="far fa-question-circle" fontSize="21px" />
          <Text color="palette.grey" fontSize="13" lineHeight={1.54}>
            {intl.messages[`${COMPONENT_INTL_KEY}.Note`]}
          </Text>
        </Flex>
      </ModalFooter>
    </Modal>
  );
};

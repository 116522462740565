import { ResourceType } from '@models';
import { resourceActions, resourceSelectors } from '@oplog/resource-redux';
import { SendToWarehouse, SendToWarehouseProps } from '@pages/SendToWarehouse';
import { urls } from '@routes/urls';
import { UpdateReceivingOrderTransferDetailCommand } from '@services';
import { StoreState } from '@store';
import { push, RouterAction } from 'connected-react-router';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

function mapStateToProps({ resources }: StoreState): Partial<SendToWarehouseProps> {
  return {
    postIsBusy: resourceSelectors.isBusy(resources, ResourceType.UpdateReceivingOrderTransferDetails),
    updateSucceeded: resourceSelectors.isSuccessComplete(resources, ResourceType.UpdateReceivingOrderTransferDetails),
  };
}

function mapDispatchToProps(
  dispatch: Dispatch<resourceActions.ResourceAction | RouterAction>
): Partial<SendToWarehouseProps> {
  return {
    onDismissPostErrors(): void {
      // We initialize post request store here if error happens.
      dispatch(resourceActions.resourceInit(ResourceType.SendReceivingOrders));
    },
    onUpdateTransferDetails(receivingOrderTransferDetails: UpdateReceivingOrderTransferDetailCommand): void {
      dispatch(
        resourceActions.resourceRequested(ResourceType.UpdateReceivingOrderTransferDetails, {
          command: receivingOrderTransferDetails,
        })
      );
    },
    initResources: () => {
      dispatch(resourceActions.resourceInit(ResourceType.UpdateReceivingOrderTransferDetails));
    },
    redirect: (referenceNumber: string): void => {
      dispatch(push(urls.receivingOrderDetails.replace(':referenceNumber', `${referenceNumber}`)));
    },
  };
}

export const SendToWarehouseContainer = connect(mapStateToProps, mapDispatchToProps)(injectIntl(SendToWarehouse));

import {
  Button,
  Dialog,
  DialogTypes,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  FormValidator,
  Icon,
  Input,
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  SelectHTML,
  Text,
} from '@oplog/express';
import { Resource } from '@oplog/resource-redux';
import { AddressOfCustomerOutputDTO, UpdateAddressOfCustomerCommand } from '@services';
import { addressErrorMessages, DropdownOptionsFromResource, generateAddressFormSchema } from '@utils';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import useForceUpdate from 'use-force-update';
import { Props } from '../../atoms/Component/Component';

const COMPONENT_EDIT_SHIPPING_INTL_KEY = 'EditShippingAddress';
const COMPONENT_INTL_KEY = 'EditCustomerAddressesForm';

export interface EditCustomerShippingAddressesFormProps extends Props {
  validator: FormValidator;
  onModalClose: () => void;
  customerNo: number;
  selectAddressId?: string;
  onSubmit: (form: Partial<UpdateAddressOfCustomerCommand>, customerNo: number) => void;
  addresses: AddressOfCustomerOutputDTO[];
  commandIsBusy: boolean;
  isEditCustomerShippingAddressesUpdateSuccess: boolean;
  onSuccessClose: () => void;
  countryResource: Resource<string[]>;
  provinceResource: Resource<string[]>;
  districtResource: Resource<string[]>;
  provinceSelect: (command: { province: string }) => void;
}

export interface EditCustomerShippingAddressesFormState {
  fields: AddressOfCustomerOutputDTO;
  initalDataReceived: boolean;
}

const ignoreValidationFields = ['addressTwo', 'details', 'company', 'id', 'addressNo', 'postalCode'];

export const EditCustomerShippingAddressesForm: React.FC<EditCustomerShippingAddressesFormProps> = ({
  intl,
  isBusy,
  onDidMount,
  onWillUnmount,
  validator,
  addresses,
  commandIsBusy,
  customerNo,
  districtResource,
  isEditCustomerShippingAddressesUpdateSuccess,
  onModalClose,
  onSubmit,
  onSuccessClose,
  countryResource,
  provinceResource,
  provinceSelect,
  selectAddressId,
}) => {
  const [fields, setFields] = useState<AddressOfCustomerOutputDTO>({
    addressNo: -1,
    details: '',
    id: '',
    addressFirstName: '',
    addressLastName: '',
    company: '',
    addressOne: '',
    addressTwo: '',
    district: '',
    city: '',
    postalCode: '',
    country: '',
    addressPhone: '',
  });

  const [initalDataReceived, setInitalDataReceived] = useState(false);

  const forceUpdate = useForceUpdate();

  useEffect(() => {
    validator.registerSchema(editCustomerShippingAddressesFormSchema());
    onDidMount && onDidMount();

    return () => {
      validator.clearErrors();
      onWillUnmount && onWillUnmount();
    };
  }, []);

  useEffect(() => {
    if (fields.addressNo === -1 && addresses && addresses.length > 0) {
      if (selectAddressId) {
        const address = addresses.find(address => address.id === selectAddressId) || addresses[0];
        provinceSelect({ province: address.city });
        setFields(address);
        setInitalDataReceived(true);
      } else {
        provinceSelect({ province: addresses[0].city });
        setFields(addresses[0]);
        setInitalDataReceived(true);
      }
    }
  }, [addresses]);

  useEffect(() => {
    if (initalDataReceived) {
      setInitalDataReceived(false);
      refreshValidations();
    }
  }, [initalDataReceived]);

  const dropdownOptions = (): { label: string; value: string }[] => {
    if (!addresses) {
      return [];
    }
    return addresses.map(address => ({
      label: address.details || '',
      value: address.id,
    }));
  };

  const handleOnBlur = (key: string) => () => {
    validator.validate(key, fields[key]);
    forceUpdate();
  };

  const refreshValidations = () => {
    validator.clearErrors();
    Object.keys(fields).forEach((key: keyof AddressOfCustomerOutputDTO) => {
      if (ignoreValidationFields.indexOf(key) > -1) {
        return;
      }
      validator.validate(key, fields[key]);
    });
    forceUpdate();
  };

  const editCustomerShippingAddressesFormSchema = () => {
    const addressErrorMessages: addressErrorMessages = {
      addressFirstName: {
        required: `${intl.messages[`${COMPONENT_INTL_KEY}.Error.Receiver.Name.Empty`]}`,
        invalid: `${intl.messages[`${COMPONENT_INTL_KEY}.Error.Receiver.Name.Invalid`]}`,
      },
      addressLastName: {
        required: `${intl.messages[`${COMPONENT_INTL_KEY}.Error.Receiver.LastName.Empty`]}`,
        invalid: `${intl.messages[`${COMPONENT_INTL_KEY}.Error.Receiver.LastName.Invalid`]}`,
      },

      addressOne: {
        required: `${intl.messages[`${COMPONENT_INTL_KEY}.Error.Receiver.AddressOne`]}`,
      },

      district: {
        required: `${intl.messages[`${COMPONENT_INTL_KEY}.Error.Receiver.District`]}`,
      },

      city: {
        required: `${intl.messages[`${COMPONENT_INTL_KEY}.Error.Receiver.City`]}`,
      },

      country: {
        required: `${intl.messages[`${COMPONENT_INTL_KEY}.Error.Receiver.Country`]}`,
      },

      addressPhone: {
        length: `${intl.messages[`${COMPONENT_INTL_KEY}.Error.Receiver.PhoneNumber.PhoneLength`]}`,
        type: `${intl.messages[`${COMPONENT_INTL_KEY}.Error.Receiver.PhoneNumber.PhoneType`]}`,
        required: `${intl.messages[`${COMPONENT_INTL_KEY}.Error.Receiver.PhoneNumber.Empty`]}`,
      },
    };

    return generateAddressFormSchema(addressErrorMessages);
  };

  const isFormInvalid = (): boolean => {
    if (isBusy) {
      return false;
    }
    const isAnyEmpty = Object.keys(fields).some(field => {
      if (ignoreValidationFields.indexOf(field) > -1) {
        return false;
      }
      return !fields[field];
    });
    return isAnyEmpty || validator.hasErrors();
  };

  const handleOnAddressChange = (e: any) => {
    const selectedAddress = addresses.find(address => address.id === e.currentTarget.value);
    if (selectedAddress) {
      setFields(selectedAddress);
      setInitalDataReceived(true);
      provinceSelect({ province: selectedAddress.city });
    }
  };

  const handleOnInputChange = (key: string) => (e: any) => {
    let { district } = fields;
    if (key === 'city' && fields.city !== e.currentTarget.value) {
      provinceSelect({ province: e.currentTarget.value });
      district = '';
    }

    if (key === 'country' && fields.country !== e.currentTarget.value) {    
      setFields({
        ...fields,
        city : '',
        district : '',
        [key]: e.currentTarget.value,
      });
    }else{ 
      setFields({
        ...fields,
        district,
        [key]: e.currentTarget.value,
      });
    }


    validator.validate(key, e.currentTarget.value);
    forceUpdate();
  };

  const renderForm = () => {
    return (
      <form>
        <Flex flexDirection="row" flexWrap="wrap">
          <FormControl size="small" width={1} mb="11">
            <FormLabel>{intl.messages[`${COMPONENT_INTL_KEY}.EditAddress`]}</FormLabel>
            <SelectHTML
              options={dropdownOptions()}
              label={intl.messages[`${COMPONENT_INTL_KEY}.EditAddress`]}
              placeholder={intl.messages[`${COMPONENT_INTL_KEY}.EditAddress`]}
              onChange={(e: any) => handleOnAddressChange(e)}
              value={fields.id}
            />
          </FormControl>
          <FormControl
            size="small"
            isInvalid={!!validator.getErrorIntent('addressFirstName')}
            width={1 / 2}
            pr="11"
            mb="11"
          >
            <FormLabel>{intl.messages[`${COMPONENT_INTL_KEY}.ReceiverName`]}*</FormLabel>
            <Input
              value={fields.addressFirstName || ''}
              placeholder={intl.messages[`${COMPONENT_INTL_KEY}.PlaceHolder.ReceiverName`]}
              onChange={handleOnInputChange('addressFirstName')}
              onBlur={handleOnBlur('addressFirstName')}
            />
            <FormErrorMessage>{validator.getErrorIntent('addressFirstName')?.text}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={!!validator.getErrorIntent('addressLastName')} size="small" width={1 / 2} mb="11">
            <FormLabel>{intl.messages[`${COMPONENT_INTL_KEY}.ReceiverLastname`]}*</FormLabel>
            <Input
              value={fields.addressLastName || ''}
              placeholder={intl.messages[`${COMPONENT_INTL_KEY}.PlaceHolder.ReceiverLastname`]}
              onChange={handleOnInputChange('addressLastName')}
              onBlur={handleOnBlur('addressLastName')}
            />
            <FormErrorMessage>{validator.getErrorIntent('addressLastName')?.text}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={!!validator.getErrorIntent('company')} size="small" width={1} mb="11">
            <FormLabel>{intl.messages[`${COMPONENT_INTL_KEY}.Company`]}</FormLabel>
            <Input
              value={fields.company || ''}
              placeholder={intl.messages[`${COMPONENT_INTL_KEY}.PlaceHolder.Company`]}
              onChange={handleOnInputChange('company')}
            />
            <FormErrorMessage>{validator.getErrorIntent('company')?.text}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={!!validator.getErrorIntent('addressOne')} size="small" width={1} mb="11">
            <FormLabel>{intl.messages[`${COMPONENT_INTL_KEY}.ReceiverAddressOne`]}*</FormLabel>
            <Input
              value={fields.addressOne || ''}
              placeholder={intl.messages[`${COMPONENT_INTL_KEY}.PlaceHolder.ReceiverAddressOne`]}
              onChange={handleOnInputChange('addressOne')}
              onBlur={handleOnBlur('addressOne')}
            />
            <FormErrorMessage>{validator.getErrorIntent('addressOne')?.text}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={!!validator.getErrorIntent('addressTwo')} size="small" width={1} mb="11">
            <FormLabel>{intl.messages[`${COMPONENT_INTL_KEY}.ReceiverAddressTwo`]}</FormLabel>
            <Input
              value={fields.addressTwo || ''}
              placeholder={intl.messages[`${COMPONENT_INTL_KEY}.PlaceHolder.ReceiverAddressTwo`]}
              onChange={handleOnInputChange('addressTwo')}
            />
            <FormErrorMessage>{validator.getErrorIntent('addressTwo')?.text}</FormErrorMessage>
          </FormControl>
          <FormControl size="small" width={1 / 2} pr="11" mb="11" >
            <FormLabel>{intl.messages[`${COMPONENT_INTL_KEY}.Country`]}*</FormLabel> 
            <SelectHTML
              placeholder={intl.messages[`${COMPONENT_INTL_KEY}.PlaceHolder.Country`]}
              onChange={handleOnInputChange('country')}
              onBlur={handleOnBlur('country')}
              value={fields.country}
              options={DropdownOptionsFromResource(countryResource)}
            />
          </FormControl>
          <FormControl size="small" width={1 / 2} isInvalid={!!validator.getErrorIntent('city')} mb="11" >
            <FormLabel>{intl.messages[`${COMPONENT_INTL_KEY}.City`]}*</FormLabel>

            {
                  fields.country == intl.messages[`${COMPONENT_INTL_KEY}.Turkey`] ? 
                  <SelectHTML
                  value={fields.city}
                  placeholder={intl.messages[`${COMPONENT_INTL_KEY}.PlaceHolder.City`]}
                  onChange={handleOnInputChange('city')}
                  options={DropdownOptionsFromResource(provinceResource)}
                  disabled={provinceResource && provinceResource.isBusy}
                /> : 
                <Input
                placeholder={intl.messages[`${COMPONENT_INTL_KEY}.PlaceHolder.City`]} 
                onChange={handleOnInputChange('city')}
                value={fields.city} />
            }
 
            <FormErrorMessage>{validator.getErrorIntent('city')?.text}</FormErrorMessage>
          </FormControl>
          <FormControl size="small" isInvalid={!!validator.getErrorIntent('district')} width={1 / 2} pr="11" mb="11" >
            <FormLabel>{intl.messages[`${COMPONENT_INTL_KEY}.District`]}*</FormLabel> 
              { 
                  fields.country == intl.messages[`${COMPONENT_INTL_KEY}.Turkey`] ? 

                    <SelectHTML
                    value={fields.district}
                    placeholder={intl.messages[`${COMPONENT_INTL_KEY}.PlaceHolder.District`]}
                    onChange={handleOnInputChange('district')}
                    options={DropdownOptionsFromResource(districtResource)}
                    disabled={districtResource && districtResource.isBusy}
                  />
                  :
                  <Input
                  placeholder={intl.messages[`${COMPONENT_INTL_KEY}.PlaceHolder.District`]} 
                  onChange={handleOnInputChange('district')}
                  value={fields.district} />
              } 
            <FormErrorMessage>{validator.getErrorIntent('district')?.text}</FormErrorMessage>
          </FormControl>
          <FormControl size="small" width={1 / 2} isInvalid={!!validator.getErrorIntent('postalCode')} mb="11">
            <FormLabel>{intl.messages[`${COMPONENT_INTL_KEY}.PostalCode`]}</FormLabel>
            <Input
              value={fields.postalCode || ''}
              placeholder={intl.messages[`${COMPONENT_INTL_KEY}.PlaceHolder.PostalCode`]}
              onChange={handleOnInputChange('postalCode')}
            />
            <FormErrorMessage>{validator.getErrorIntent('postalCode')?.text}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={!!validator.getErrorIntent('addressPhone')} size="small" width={1} mb="11">
            <FormLabel>{intl.messages[`${COMPONENT_INTL_KEY}.ReceiverPhoneNumber`]}*</FormLabel>
            <Input
              value={fields.addressPhone || ''}
              placeholder={intl.messages[`${COMPONENT_INTL_KEY}.PlaceHolder.ReceiverPhoneNumber`]}
              onBlur={handleOnBlur('addressPhone')}
              onChange={handleOnInputChange('addressPhone')}
            />
            <FormErrorMessage>{validator.getErrorIntent('addressPhone')?.text}</FormErrorMessage>
          </FormControl>
        </Flex>
      </form>
    );
  };

  return (
    <>
      <Modal isOpen onClose={onModalClose} size="3xl">
        <ModalHeader>
          <ModalTitle>{intl.messages[`${COMPONENT_EDIT_SHIPPING_INTL_KEY}.Header.ShippingAddress`]}</ModalTitle>
        </ModalHeader>
        <ModalContent>
          {renderForm()}
          <Flex justifyContent="center">
            <Button
              width="122px"
              mr="11px"
              size="small"
              kind="outline"
              variant="dark"
              disabled={commandIsBusy}
              onClick={onModalClose}
            >
              {intl.messages['Form.Action.Cancel']}
            </Button>
            <Button
              width="182px"
              size="small"
              variant="success"
              disabled={isFormInvalid() || isBusy || commandIsBusy}
              isLoading={commandIsBusy}
              onClick={() => onSubmit(fields, customerNo)}
            >
              {intl.messages[`Form.Action.Save`]}
            </Button>
          </Flex>
        </ModalContent>
        <ModalFooter borderTop="xs" borderColor="palette.snow_light">
          <Flex>
            <Icon mr="8px" name="far fa-question-circle" fontSize="21px" />
            <Text color="palette.grey" fontSize="13" lineHeight={1.54}>
              {intl.messages[`${COMPONENT_EDIT_SHIPPING_INTL_KEY}.Warning`]}
            </Text>
          </Flex>
        </ModalFooter>
      </Modal>
      {isEditCustomerShippingAddressesUpdateSuccess && (
        <Dialog
          type={DialogTypes.Success}
          message={
            <FormattedMessage
              id={`${COMPONENT_EDIT_SHIPPING_INTL_KEY}.Modal.Success`}
              values={{
                CustomerName: <b>{`${fields.addressFirstName} ${fields.addressLastName}`}</b>,
              }}
            />
          }
          isOpen={isEditCustomerShippingAddressesUpdateSuccess}
          onApprove={onSuccessClose || onModalClose}
          text={{ approve: intl.messages[`Modal.Success.Okay`] }}
        />
      )}
    </>
  );
};

import { ResourceType } from '@models';
import { validation } from '@oplog/express';
import { resourceActions, resourceSelectors } from '@oplog/resource-redux';
import { AddAddressToCustomerCommand } from '@services';
import { customerDetailsAction, CustomerDetailsAction } from '@store/customerDetails';
import { StoreState } from '@store/state';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { Dispatch } from 'redux';
import {
  CreateCustomerShippingAddressForm,
  CreateCustomerShippingAddressFormProps,
} from '../../components/molecules/CreateCustomerShippingAddressForm/CreateCustomerShippingAddressForm';
export interface CreateCustomerShippingAddressFormContainerProps extends RouteComponentProps {
  onModalClose: () => void;
  customerNo: number;
}

function mapStateToProps({ resources }: StoreState): Partial<CreateCustomerShippingAddressFormProps> {
  return {
    countryResource: resources[ResourceType.AllCountries],
    provinceResource: resources[ResourceType.AllProvinces],
    districtResource: resources[ResourceType.DistrictsOfProvince],
    isComplete: resourceSelectors.isSuccessComplete(resources, ResourceType.AddNewCustomerShippingAddress),
    isBusy: resourceSelectors.isBusy(resources, ResourceType.AddNewCustomerShippingAddress),
    error: resourceSelectors.getError(resources, ResourceType.AddNewCustomerShippingAddress),
  };
}

function mapDispatchToProps(
  dispatch: Dispatch<resourceActions.ResourceAction | CustomerDetailsAction>,
  ownProps: CreateCustomerShippingAddressFormContainerProps
) {
  return {
    onModalClose: ownProps.onModalClose,
    onSubmit(form: AddAddressToCustomerCommand, customerNo: number) {
      const newCustomerShippingAddressForm: AddAddressToCustomerCommand = {
        ...form,
        customerNo,
      };
      dispatch(
        resourceActions.resourceRequested(ResourceType.AddNewCustomerShippingAddress, {
          newCustomerShippingAddressForm,
        })
      );
    },
    onComplete: () => {
      dispatch(customerDetailsAction.closeNewAddressModal());
    },
    onDidMount: (): void => {
      dispatch(resourceActions.resourceRequested(ResourceType.AllProvinces));
      dispatch(resourceActions.resourceRequested(ResourceType.AllCountries));
    },
    onWillUnmount: () => {
      dispatch(resourceActions.resourceInit(ResourceType.DistrictsOfProvince));
      dispatch(resourceActions.resourceInit(ResourceType.AllProvinces));
      dispatch(resourceActions.resourceInit(ResourceType.AllCountries));
    },
    provinceSelect: (command: { province: string }): void => {
      dispatch(resourceActions.resourceRequested(ResourceType.DistrictsOfProvince, command));
    },
  };
}

export const CreateCustomerShippingAddressFormContainer = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(validation(injectIntl(CreateCustomerShippingAddressForm)))
);

import { ReturnDetailsState } from './types';
import { ReturnDetailsAction } from './actions';
import {
  RETURN_DETAILS_IMAGE_PREVIEW_ACTIVE_INDEX_CHANGED,
  RETURN_DETAILS_IMAGE_PREVIEW_CLOSED,
  RETURN_DETAILS_IMAGE_PREVIEW_OPENED,
} from './constants';

export function returnDetailsReducer(state: ReturnDetailsState, action: ReturnDetailsAction): ReturnDetailsState {
  switch (action.type) {
    case RETURN_DETAILS_IMAGE_PREVIEW_ACTIVE_INDEX_CHANGED:
      return {
        ...state,
        imageViewer: {
          ...state.imageViewer,
          activeIndex: action.payload.index,
        },
      };
    case RETURN_DETAILS_IMAGE_PREVIEW_CLOSED:
      return {
        ...state,
        imageViewer: {
          ...state.imageViewer,
          isOpen: false,
        },
      };
    case RETURN_DETAILS_IMAGE_PREVIEW_OPENED:
      return {
        ...state,
        imageViewer: {
          ...state.imageViewer,
          isOpen: true,
          images: action.payload.images,
          activeIndex: action.payload.index,
        },
      };
    default:
      return state;
  }
}

import { Flex, Image, Text } from '@oplog/express';
import React from 'react';

interface ImagePreviewerProps {
    image: string;
    text?: string;
}

const ImagePreviewer: React.FC<ImagePreviewerProps> = ({
    image,
    text
}) => {

    return (
        <Flex flexDirection="column" alignItems="center" justifyContent="center" width="300px">
            <Image
                width="300px"
                src={image}
            />
            <Text fontFamily='Lato'>{text}</Text>
        </Flex>
    );
};

export default ImagePreviewer;
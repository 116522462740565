import { CustomerSearch } from '@components/molecules/CustomerSearch/CustomerSearch';
import { CustomerShippingInfoSearch } from '@components/molecules/CustomerShippingInfoSearch';
import { OrderDeliveryInfo, OrderDeliveryInfoFields } from '@components/molecules/OrderDeliveryInfo/OrderDeliveryInfo';
import {
  OrderGeneralInfo,
  OrderGeneralInfoFields,
  validateRefNo,
} from '@components/molecules/OrderGeneralInfo/OrderGeneralInfo';
import { CreateSalesOrderCartGrid } from '@components/organisms/CreateSalesOrderCartGrid/CreateSalesOrderCartGrid';
import { CreateCustomerShippingAddressFormContainer } from '@containers/CreateCustomerShippingAddressFormContainer';
import { EditCustomerInfoFormContainer } from '@containers/EditCustomerInfoFormContainer';
import { EditCustomerShippingAddressesFormContainer } from '@containers/EditCustomerShippingAddressesFormContainer';
import { HTTP_ERRORS } from '@models';
import { dateTimeFormatter } from '@oplog/data-grid';
import {
  Box,
  Button,
  Dialog,
  DialogTypes,
  Flex,
  FormErrorMessage,
  Icon,
  Modal,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalTitle,
} from '@oplog/express';
import { Resource } from '@oplog/resource-redux';
import {
  AddressOfCustomerOutputDTO,
  CargoType,
  CreatedSalesOrderOutputDTO,
  CreateSalesOrderInputDTO,
  CreateSalesOrderLineItemInputDTO,
  Currency,
  CustomerInfoOutputDTO,
  ErrorCode,
  PaymentOption,
  SearchCustomersQueryOutputDTO,
  TenantDetailsDTO,
} from '@services/swagger';
import useCommonDataStore, { OTHER_SALES_CHANNEL_KEY } from '@store/commonData/commonDataState';
import useCreateSalesOrderState, {
  doesContainItemWithAmountMoreThanStock,
  getCreateSalesOrderCartGridInfo,
} from '@store/createSalesOrder/CreateSalesOrderState';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { FormattedMessage } from 'react-intl';
import { tracking } from '../../../utils/analytics';
import { Props } from '../../atoms/Component/Component';

const COMPONENT_INTL_KEY = 'CreateSalesOrderContextualPanel';
const COMPONENT_NEW_ADDRESS_INTL_KEY = 'SalesOrderCustomerNewAddress';

export enum CreateSalesOrderStepType {
  Grid,
  CustomerSearch,
  ShippingAddress,
  OrderInfo,
  PaymentInfo,
}

export enum CreateSalesOrderDialogType {
  NoDialog,
  Warning,
  Confirmation,
  Completed,
  DistrictError,
}

export interface CreateSalesOrderContextualPanelProps extends Props {
  isBusy: boolean;
  createdSalesOrderData?: CreatedSalesOrderOutputDTO;
  customers: Resource<SearchCustomersQueryOutputDTO[]>;
  customerInfo: CustomerInfoOutputDTO;
  shippingAddresses: Resource<AddressOfCustomerOutputDTO[]>;

  salesChannelsOutput: any;
  cargoCarriersOutput: any;
  getCargoCarrierAndSalesChannels: () => void;

  searchCustomers: (text: string) => void;
  getAllCustomerShippingAddressDetails: (customerNo: number) => void;
  getCustomerInfo: (customerNo: number) => void;
  onCreateSalesOrderCartData: (salesOrders: CreateSalesOrderInputDTO) => void;

  onPostError: () => void;
  closeErrorAlert: () => void;
  redirectOnSalesOrderCreateSuccess: (referenceNumber: string) => void;
  /* Edit Customer */
  onEditCustomerInfoSuccessModalClose: (customerNo: number, text: string) => void;
  isEditCustomerInfoUpdateSuccess: boolean;
  /* New Customer Address */
  onNewAddresClick: () => void;
  onNewAddressModalClose: () => void;
  onCreateShippingAddressModalClose: (customerNo: number) => void;
  isNewAddressCreateSuccess: boolean;
  isNewAddressModalOpen: boolean;
  isUploading: boolean;
  fileUrl: string;
  isUploadingVas: boolean;
  fileUrlVas: string;
  onFileSelect: (file: File) => void;
  onReset: () => void;
  onVasFileSelect: (file: File) => void;
  onVasReset: () => void;
  orderDeliveryInfoFields: OrderGeneralInfoFields;
  handleOrderDeliveryInfoFields: (fields: OrderGeneralInfoFields) => void;
  tenantDetails: TenantDetailsDTO;
  getTenantDetails: () => void;
}
export interface CreateSalesOrderContextualPanelState {
  step: number;
  showDialog: CreateSalesOrderDialogType;
  shippingAddress?: AddressOfCustomerOutputDTO;
  editCustomerModal: boolean;
  newCustomerAddressModal: boolean;
  editCustomerAddressModal: boolean;
  selectedCustomerNo: number;
  validCargoForm: boolean;
  validPaymentForm: boolean;
  deliveryInfoFields: OrderGeneralInfoFields;
  paymentInfoFields: OrderDeliveryInfoFields;
}

export const CreateSalesOrderContextualPanel: React.FC<CreateSalesOrderContextualPanelProps> = ({
  error,
  onPostError,
  intl,
  isBusy,
  shippingAddresses,
  customerInfo,
  onCreateSalesOrderCartData,
  redirectOnSalesOrderCreateSuccess,
  getCustomerInfo,
  getAllCustomerShippingAddressDetails,
  onEditCustomerInfoSuccessModalClose,
  onCreateShippingAddressModalClose,
  onNewAddressModalClose,
  isNewAddressCreateSuccess,
  customers,
  searchCustomers,
  onNewAddresClick,
  onFileSelect,
  onReset,
  onVasReset,
  onVasFileSelect,
  fileUrl,
  isUploading,
  fileUrlVas,
  isUploadingVas,
  onDidMount,
  onWillUnmount,
  createdSalesOrderData,
  cargoCarriersOutput,
  salesChannelsOutput,
  getCargoCarrierAndSalesChannels,
  tenantDetails,
  getTenantDetails,
}) => {
  const [state, actions] = useCreateSalesOrderState();
  const [{ cargoCarriers, salesChannels }, { setCargoCarriers, setSalesChannels }] = useCommonDataStore();

  const [step, setStep] = useState(CreateSalesOrderStepType.Grid);
  const [showDialog, setShowDialog] = useState(CreateSalesOrderDialogType.NoDialog);
  const [modalStates, setModalStates] = useState({
    editCustomerModal: false,
    newCustomerAddressModal: false,
    editCustomerAddressModal: false,
  });
  const [selectedCustomerNo, setSelectedCustomerNo] = useState(-1);
  const [selectedAddressId, setSelectedAddressId] = useState('');
  const [validGeneralForm, setValidGeneralForm] = useState(true);
  const [validDeliveryForm, setValidDeliveryForm] = useState(true);
  const [generalInfoFields, setGeneralInfoFields] = useState<OrderGeneralInfoFields>({
    orderRefNo: '',
    paymentOption: '',
    paymentAmount: 0,
    currency: Currency.TRY,
    vasAttachmentUrl: '',
  });
  const [deliveryInfoFields, setDeliveryInfoFields] = useState<OrderDeliveryInfoFields>({
    salesChannel: '',
    otherSalesChannel: '',
    cargoCompany: '',
    cargoCode: '',
    cargoType: undefined,
    differentCargoCompanyChoice: true,
    cargoAttachmentUrl: '',
    expectedShipmentDate: undefined
  });

  const [shippingAddress, setShippingAddress] = useState<AddressOfCustomerOutputDTO>({
    addressFirstName: '',
    addressLastName: '',
    company: '',
    addressOne: '',
    addressTwo: '',
    district: '',
    city: '',
    postalCode: '',
    country: '',
    addressPhone: '',
    addressNo: 0,
    details: '',
    id: '',
  });

  useEffect(() => {
    onDidMount && onDidMount();

    getTenantDetails();

    if (cargoCarriers === undefined || salesChannels === undefined) {
      getCargoCarrierAndSalesChannels();
    }

    return () => {
      actions.clearData();
      onWillUnmount && onWillUnmount();
    };
  }, []);

  useEffect(() => {
    setCargoCarriers(cargoCarriersOutput);
    setSalesChannels(salesChannelsOutput);
  }, [salesChannelsOutput, cargoCarriersOutput]);

  useEffect(() => {
    onPostError();
    setShowDialog(CreateSalesOrderDialogType.NoDialog);
  }, [error]);

  useEffect(() => {
    if (createdSalesOrderData && !error) {
      setShowDialog(CreateSalesOrderDialogType.Completed);
    }
  }, [createdSalesOrderData]);

  useEffect(() => {
    trackStepChange();
  }, [step]);

  useEffect(() => {
    handleGeneralFormValidity();
  }, [generalInfoFields]);

  useEffect(() => {
    handleDeliveryFormValidity();
  }, [deliveryInfoFields]);

  const getShippingAddresses = () => {
    return shippingAddresses;
  };

  const renderStepButtons = () => {
    return (
      <Flex p="22">
        <Button width={1 / 3} kind="outline" variant="dark" mr="11" onClick={handleStepButtonsBackClick}>
          {intl.messages[`${COMPONENT_INTL_KEY}.Buttons.Back`]}
        </Button>

        <Button
          width={2 / 3}
          type="submit"
          disabled={isBusy || checkStepButtonsDisabled()}
          addons={{ right: <Icon name="far fa-angle-right" /> }}
          onClick={() => {
            setStep(step + 1);
          }}
        >
          {intl.messages[`${COMPONENT_INTL_KEY}.Buttons.Next`]}
        </Button>
      </Flex>
    );
  };

  const checkStepButtonsDisabled = () => {
    if (step === CreateSalesOrderStepType.CustomerSearch) return true;
    if (
      step === CreateSalesOrderStepType.OrderInfo &&
      generalInfoFields.orderRefNo &&
      !validateRefNo(generalInfoFields.orderRefNo)
    )
      return true;
    if (step === CreateSalesOrderStepType.ShippingAddress && !shippingAddress) return true;
    if (step === CreateSalesOrderStepType.OrderInfo && !validGeneralForm) return true;
    if (step === CreateSalesOrderStepType.PaymentInfo && !validDeliveryForm) return true;
    return false;
  };

  const handleStepButtonsBackClick = () => {
    if (step === CreateSalesOrderStepType.CustomerSearch) {
      setStep(step - 1);
    } else {
      setStep(step - 1);
    }
  };

  const renderConfirmationDialogMessage = () => {
    const { numOfTypes, numOfProducts } = getCreateSalesOrderCartGridInfo(state.panelData);

    let shippingCustomerFullName = '';

    if (selectedAddressId === '') {
      if (shippingAddress !== undefined) {
        shippingCustomerFullName = `${shippingAddress.addressFirstName} ${shippingAddress.addressLastName}`;
      } else {
        shippingCustomerFullName = shippingAddresses.data
          ? `${shippingAddresses.data[0].addressFirstName}
           ${shippingAddresses.data[0].addressLastName}`
          : '';
      }
    }
    else {
      const selectedAddress: AddressOfCustomerOutputDTO[] | undefined = shippingAddresses.data
        ? shippingAddresses.data.filter((item: AddressOfCustomerOutputDTO) => item.id == selectedAddressId)
        : undefined;

      if (selectedAddress) {
        shippingCustomerFullName = `${selectedAddress[0].addressFirstName} ${selectedAddress[0].addressLastName}`;
      }
    }

    return (
      <FormattedMessage
        id={`${COMPONENT_INTL_KEY}.ConfirmationDialog.Message`}
        values={{
          numOfTypes: <strong>{numOfTypes}</strong>,
          numOfProducts: <strong>{numOfProducts}</strong>,
          customerFullName: <strong>{shippingCustomerFullName}</strong>,
        }}
      />
    );
  };

  const handleConfirmationDialogAcceptClick = () => {
    setShowDialog(CreateSalesOrderDialogType.NoDialog);

    let shippingAddressId = '';

    if (selectedAddressId === '') {
      if (shippingAddress) {
        shippingAddressId = shippingAddress.id ? shippingAddress.id : '';
      } else {
        shippingAddressId = shippingAddresses.data ? shippingAddresses.data[0].id : '';
      }
    }
    else {
      const selectedAddress: AddressOfCustomerOutputDTO[] | undefined = shippingAddresses.data
        ? shippingAddresses.data.filter((item: AddressOfCustomerOutputDTO) => item.id == selectedAddressId)
        : undefined;

      if (selectedAddress) {
        shippingAddressId = selectedAddress[0]?.id;
      }
    }

    let salesChannelToSet;

    if (
      deliveryInfoFields.salesChannel === OTHER_SALES_CHANNEL_KEY &&
      deliveryInfoFields.otherSalesChannel &&
      deliveryInfoFields.otherSalesChannel.trim()
    ) {
      salesChannelToSet = deliveryInfoFields.otherSalesChannel;
    } else if (deliveryInfoFields.salesChannel && deliveryInfoFields.salesChannel.trim()) {
      salesChannelToSet = deliveryInfoFields.salesChannel;
    } else {
      salesChannelToSet = undefined;
    }

    const createSalesOrder: CreateSalesOrderInputDTO = {
      salesChannel: salesChannelToSet,
      referenceNumber:
        generalInfoFields.orderRefNo && generalInfoFields.orderRefNo.trim()
          ? generalInfoFields.orderRefNo.trim()
          : (undefined as any),
      orderCreatedAt: new Date(),
      customerId: customerInfo.id,
      shippingAddressId,
      billingAddressId: shippingAddressId,
      totalPaymentAmount: generalInfoFields.paymentAmount,
      currency: Currency[`${generalInfoFields.currency}`],
      paymentOption: PaymentOption[`${generalInfoFields.paymentOption}`],
      salesOrderLineItems: prepareCreateSalesOrderLineItemsData(),
      cargoCarrier: deliveryInfoFields.differentCargoCompanyChoice ? deliveryInfoFields.cargoCompany : undefined,
      cargoDocumentUrl: deliveryInfoFields.differentCargoCompanyChoice
        ? deliveryInfoFields.cargoAttachmentUrl && deliveryInfoFields.cargoAttachmentUrl.trim()
          ? deliveryInfoFields.cargoAttachmentUrl.trim()
          : (undefined as any)
        : undefined,
      cargoCode: deliveryInfoFields.differentCargoCompanyChoice
        ? deliveryInfoFields.cargoCode && deliveryInfoFields.cargoCode.trim()
          ? deliveryInfoFields.cargoCode.trim()
          : (undefined as any)
        : undefined,
      cargoType: deliveryInfoFields.cargoType ? deliveryInfoFields.cargoType : (undefined as any),
      vasDocumentUrl:
        generalInfoFields.vasAttachmentUrl && generalInfoFields.vasAttachmentUrl.trim()
          ? generalInfoFields.vasAttachmentUrl.trim()
          : (undefined as any),
      expectedShipmentDate: deliveryInfoFields.expectedShipmentDate
    };

    onCreateSalesOrderCartData(createSalesOrder);
  };

  const handleCompletedAcceptClick = () => {
    setShowDialog(CreateSalesOrderDialogType.NoDialog);
    setStep(CreateSalesOrderStepType.Grid);
    setShippingAddress({
      addressFirstName: '',
      addressLastName: '',
      company: '',
      addressOne: '',
      addressTwo: '',
      district: '',
      city: '',
      postalCode: '',
      country: '',
      addressPhone: '',
      addressNo: 0,
      details: '',
      id: '',
    });
    setSelectedAddressId('');
    actions.clearData();

    if (createdSalesOrderData) {
      redirectOnSalesOrderCreateSuccess(createdSalesOrderData.referenceNumber);
    }
  };

  const handleShippingAddressChange = (e: any) => {
    const shippingAddress: AddressOfCustomerOutputDTO[] | undefined = shippingAddresses.data
      ? shippingAddresses.data.filter((item: AddressOfCustomerOutputDTO) => {
        let value;
        if (e) {
          if (e.currentTarget) value = e.currentTarget.value;
          if (!e.currentTarget && e.value) value = e.value;
        }
        return item.id === value;
      })
      : undefined;
    if (shippingAddress) {
      setShippingAddress(shippingAddress[0]);
      setSelectedAddressId(shippingAddress[0].id);
    }
  };

  React.useEffect(() => {
    if (shippingAddresses.data && shippingAddresses.data.length > 0) {
      setShippingAddress(shippingAddresses.data[0]);
    }
  }, [shippingAddresses]);

  const handleCustomerClick = (customerNo: number) => {
    setSelectedCustomerNo(customerNo);
    getCustomerInfo(customerNo);
    getAllCustomerShippingAddressDetails(customerNo);
    setStep(CreateSalesOrderStepType.ShippingAddress);
  };

  const handleEditCustomerInfoSuccess = () => {
    onEditCustomerInfoSuccessModalClose(selectedCustomerNo, '');
    setModalStates({
      ...modalStates,
      editCustomerModal: false,
    });
  };

  const handleEditCustomerClick = (customerNo: number) => {
    setSelectedCustomerNo(customerNo);
    setModalStates({
      ...modalStates,
      editCustomerModal: true,
    });
  };

  const handleCreateShippingAddressModal = () => {
    onCreateShippingAddressModalClose(customerInfo.customerNo);
    setModalStates({
      ...modalStates,
      newCustomerAddressModal: false,
    });
  };

  const handleGeneralFormValidity = () => {
    if (generalInfoFields.paymentOption === Object.keys(PaymentOption)[0]) {
      setValidGeneralForm(true);
      return;
    }
    // Temporary solution. The issue is here that even though the state paymentAmount is defined as a number, it is designed so that it comes as a string.
    // So the system will need a complete look to block cases such as ,0.
    if (generalInfoFields.paymentAmount != 0 && generalInfoFields.paymentAmount.toString() !== '0') {
      setValidGeneralForm(true);
      return;
    }
    setValidGeneralForm(false);
  };

  const handleOrderDeliveryInfoFields = (fields: any) => {
    setGeneralInfoFields(fields);
  };

  const handleDeliveryFormValidity = () => {
    if (!deliveryInfoFields.differentCargoCompanyChoice) {
      setValidDeliveryForm(true);
      return;
    }
    if (
      deliveryInfoFields.differentCargoCompanyChoice &&
      (deliveryInfoFields.cargoAttachmentUrl !== '' || deliveryInfoFields.cargoCode !== '') &&
      deliveryInfoFields.cargoCompany
    ) {
      setValidDeliveryForm(true);
      return;
    }
    setValidDeliveryForm(false);
  };

  const handleOrderPaymentInfoFields = (fields: OrderDeliveryInfoFields) => {
    setDeliveryInfoFields(fields);
  };

  const editCustomerClose = () => {
    getCustomerInfo(selectedCustomerNo);
    setModalStates({
      ...modalStates,
      editCustomerModal: false,
    });
  };

  /* Edit Customer Address */

  const handleEditCustomerAddressesModalClose = () => {
    setModalStates({
      ...modalStates,
      editCustomerAddressModal: false,
    });
    getAllCustomerShippingAddressDetails(customerInfo.customerNo);
  };

  /* Far from optimal solution but can't use hooks or componentDidUpdate(prevState) */
  const trackStepChange = () => {
    let stepName = CreateSalesOrderStepType[step];
    tracking.emitEvent('CreateSalesOrderPanelChange', stepName);
  };

  const prepareCreateSalesOrderLineItemsData = () => {
    const createSalesOrderLineItems: Array<CreateSalesOrderLineItemInputDTO> = [];
    if (state.panelData) {
      state.panelData.forEach(lineItem => {
        createSalesOrderLineItems.push({
          sku: lineItem.sku,
          amountInOrder: lineItem.amountInOrder,
        });
      });

      return createSalesOrderLineItems;
    }

    return [];
  };

  const renderNewAddressModal = () => {
    return (
      <Modal
        isOpen={modalStates.newCustomerAddressModal}
        onClose={() => {
          setModalStates({
            ...modalStates,
            newCustomerAddressModal: false,
          });
          onNewAddressModalClose();
        }}
        size="3xl"
      >
        <ModalHeader>
          <ModalTitle>{intl.messages[`${COMPONENT_NEW_ADDRESS_INTL_KEY}.Header.Title`]}</ModalTitle>
        </ModalHeader>
        <ModalContent>
          <CreateCustomerShippingAddressFormContainer
            customerNo={customerInfo.customerNo}
            onModalClose={() => {
              setModalStates({
                ...modalStates,
                newCustomerAddressModal: false,
              });
              getAllCustomerShippingAddressDetails(customerInfo.customerNo);
              onNewAddressModalClose();
            }}
          />
        </ModalContent>
      </Modal>
    );
  };

  const renderResourceAlreadyExistsErrors = (propertyName: string): boolean => {
    if (error && error.errors) {
      for (const e of error.errors) {
        if (
          propertyName === 'referenceNumber' &&
          e.property.toLowerCase() === 'referencenumber' &&
          e.errorCode === ErrorCode.ResourceAlreadyExists.toString()
        ) {
          setStep(CreateSalesOrderStepType.OrderInfo);
          return true;
        }
      }
      return false;
    }
    return false;
  };

  const renderNewAddressSuccessModal = () => {
    const customerName = `${customerInfo.firstName} ${customerInfo.lastName}`;
    return (
      <Dialog
        type={DialogTypes.Success}
        message={
          <FormattedMessage
            id={`${COMPONENT_NEW_ADDRESS_INTL_KEY}.Modal.Success`}
            values={{
              CustomerName: <strong>{customerName}</strong>,
            }}
          />
        }
        isOpen={isNewAddressCreateSuccess}
        onCancel={() => handleCreateShippingAddressModal()}
        onApprove={() => handleCreateShippingAddressModal()}
        text={{ approve: intl.messages[`Modal.Success.Okay`] }}
      />
    );
  };

  const renderSteps = () => {
    const { numOfTypes, numOfProducts } = getCreateSalesOrderCartGridInfo(state.panelData);

    const warehouseCartLineItemNumber: number = state.panelData ? state.panelData.length : 0;
    const address = intl.messages[`${COMPONENT_INTL_KEY}.Address`];

    switch (step) {
      case CreateSalesOrderStepType.Grid:
        return (
          <Box>
            <CreateSalesOrderCartGrid intl={intl} intlKey={COMPONENT_INTL_KEY} />
            <Box boxShadow="0 6px 10px 0 hsla(0,0%,78%,.1), 0 -6px 10px 0 hsla(0,0%,78%,.1)" p="11px 16px">
              <FormattedMessage
                id={`${COMPONENT_INTL_KEY}.GridFooter`}
                values={{
                  numOfTypes: <strong>{numOfTypes}</strong>,
                  numOfProducts: <strong>{numOfProducts}</strong>,
                }}
              />
            </Box>
            {doesContainItemWithAmountMoreThanStock(state.panelData) && (
              <FormErrorMessage p="0 16px" independent>
                {intl.messages[`${COMPONENT_INTL_KEY}.MoreThanStock`]}
              </FormErrorMessage>
            )}
            <Flex p="22">
              {warehouseCartLineItemNumber > 0 && (
                <Button
                  width={1 / 3}
                  kind="outline"
                  variant="dark"
                  onClick={() => setShowDialog(CreateSalesOrderDialogType.Warning)}
                  mr="11"
                >
                  {intl.messages[`${COMPONENT_INTL_KEY}.Buttons.Empty`]}
                </Button>
              )}

              <Button
                marginLeft="auto"
                width={2 / 3}
                type="submit"
                disabled={warehouseCartLineItemNumber === 0}
                addons={{ right: <Icon name="far fa-angle-right" /> }}
                onClick={() => {
                  setStep(CreateSalesOrderStepType.CustomerSearch);
                }}
              >
                {intl.messages[`${COMPONENT_INTL_KEY}.Buttons.Next`]}
              </Button>
            </Flex>
            <Dialog
              type={DialogTypes.Warning}
              message={intl.messages[`${COMPONENT_INTL_KEY}.WarningDialog.Message`]}
              isOpen={showDialog === CreateSalesOrderDialogType.Warning}
              text={{
                cancel: intl.messages[`Modal.Warning.Cancel`],
                approve: intl.messages[`Modal.Warning.Okay`],
              }}
              onApprove={() => {
                actions.clearData();
                setShowDialog(CreateSalesOrderDialogType.NoDialog);
              }}
              onCancel={() => setShowDialog(CreateSalesOrderDialogType.NoDialog)}
              showOverlay
            />
          </Box>
        );
      case CreateSalesOrderStepType.CustomerSearch:
        return (
          <>
            <CustomerSearch
              intl={intl}
              intlKey={COMPONENT_INTL_KEY}
              customers={customers}
              onNewCustomerCompleted={() => {
                setStep(CreateSalesOrderStepType.ShippingAddress);
              }}
              onInputEnter={searchCustomers}
              onInputChange={() => {}}
              onCustomerClick={handleCustomerClick}
              showTitle={true}
            />
            {renderStepButtons()}
            {createPortal(<ModalOverlay opacity={0.2} zIndex={3999} />, document.querySelector('body') as any)}
          </>
        );
      case CreateSalesOrderStepType.ShippingAddress:
        return (
          <>
            <CustomerShippingInfoSearch
              onCustomerCloseClick={() => {
                setStep(CreateSalesOrderStepType.CustomerSearch);
              }}
              onAddAddressClick={() => {
                onNewAddresClick();
                setModalStates({
                  ...modalStates,
                  newCustomerAddressModal: true,
                });
              }}
              onEditAddressClick={() =>
                setModalStates({
                  ...modalStates,
                  editCustomerAddressModal: true,
                })
              }
              onShippingAddressChange={handleShippingAddressChange}
              onEditCustomerClick={() => handleEditCustomerClick(customerInfo.customerNo)}
              shippingAddresses={getShippingAddresses()}
              customerInfo={customerInfo}
              intl={intl}
              intlKey={COMPONENT_INTL_KEY}
              receivedSelectShippingAddress={shippingAddress}
              showTitle={true}
            />
            {renderStepButtons()}

            {/* Customer Modals */}
            {modalStates.editCustomerModal && (
              <EditCustomerInfoFormContainer
                customerNo={selectedCustomerNo}
                onSuccessClose={handleEditCustomerInfoSuccess}
                onModalClose={editCustomerClose}
              />
            )}
            {customerInfo ? (
              <>
                {/* Add Customer Address */}
                {renderNewAddressModal()}
                {renderNewAddressSuccessModal()}
              </>
            ) : (
              <></>
            )}
            {modalStates.editCustomerAddressModal && (
              <EditCustomerShippingAddressesFormContainer
                customerNo={customerInfo.customerNo}
                onModalClose={handleEditCustomerAddressesModalClose}
                onSuccessClose={handleEditCustomerAddressesModalClose}
                selectAddressId={shippingAddress && shippingAddress.id}
              />
            )}
            {createPortal(<ModalOverlay opacity={0.2} zIndex={3999} />, document.querySelector('body') as any)}
          </>
        );
      case CreateSalesOrderStepType.OrderInfo:
        return (
          <>
            <OrderGeneralInfo
              intl={intl}
              intlKey={COMPONENT_INTL_KEY}
              onVasFileReset={onVasReset}
              onVasFileSelect={onVasFileSelect}
              fileUrlVas={fileUrlVas}
              isUploadingVas={isUploadingVas}
              onOrderDeliveryInfoFields={handleOrderDeliveryInfoFields}
              setDeliveryInfoFields={generalInfoFields}
            />
            {renderStepButtons()}
            {createPortal(<ModalOverlay opacity={0.2} zIndex={3999} />, document.querySelector('body') as any)}
          </>
        );
      case CreateSalesOrderStepType.PaymentInfo:
        return (
          <>
            <OrderDeliveryInfo
              intl={intl}
              intlKey={COMPONENT_INTL_KEY}
              onChangeOrderPaymentInfoFields={handleOrderPaymentInfoFields}
              setPaymentInfoFields={deliveryInfoFields}
              onFileSelect={onFileSelect}
              fileUrl={fileUrl}
              isUploading={isUploading}
              onFileReset={onReset}
              tenantDetails={tenantDetails}
            />
            <Flex p="22">
              <Button
                width={1 / 3}
                kind="outline"
                disabled={isBusy}
                variant="dark"
                onClick={handleStepButtonsBackClick}
                mr="11"
              >
                {intl.messages[`${COMPONENT_INTL_KEY}.Buttons.Back`]}
              </Button>
              <Button
                width={2 / 3}
                type="submit"
                className="contextual-panel-primary-button"
                addons={{ right: <Icon name="far fa-angle-right" /> }}
                disabled={isBusy || checkStepButtonsDisabled()}
                isLoading={isBusy}
                onClick={() => {
                  setShowDialog(CreateSalesOrderDialogType.Confirmation);
                }}
              >
                {intl.messages[`${COMPONENT_INTL_KEY}.Buttons.Completed`]}
              </Button>
            </Flex>
            <Dialog
              type={DialogTypes.Confirmation}
              text={{
                approve: intl.messages[`Modal.Confirmation.Okay`],
                cancel: intl.messages[`Modal.Confirmation.Cancel`],
              }}
              icon="fas fa-shopping-cart"
              message={renderConfirmationDialogMessage()}
              isOpen={showDialog === CreateSalesOrderDialogType.Confirmation}
              onApprove={handleConfirmationDialogAcceptClick}
              onCancel={() => {
                setShowDialog(CreateSalesOrderDialogType.NoDialog);
              }}
              showOverlay
            />
            <Dialog
              type={DialogTypes.Success}
              message={
                <FormattedMessage
                  id={`${COMPONENT_INTL_KEY}.CompleteOrderDialog.Message`}
                  values={{
                    address: <strong>&quot;{address}&quot;</strong>,
                  }}
                />
              }
              isOpen={showDialog === CreateSalesOrderDialogType.Completed}
              onCancel={handleCompletedAcceptClick}
              onApprove={handleCompletedAcceptClick}
              text={{
                approve: intl.messages[`Modal.Success.Okay`],
              }}
              showOverlay
            />
            <Dialog
              type={DialogTypes.Danger}
              message={intl.messages[`${COMPONENT_INTL_KEY}.ErrorConflict`]}
              isOpen={error?.code === HTTP_ERRORS.Conflict && !renderResourceAlreadyExistsErrors('referenceNumber')}
              text={{ approve: intl.messages[`Modal.Success.Okay`] }}
              onApprove={() => {
                onPostError();
              }}
            />
            <Dialog
              type={DialogTypes.Danger}
              message={intl.messages[`${COMPONENT_INTL_KEY}.InvalidDistrict`]}
              isOpen={
                !!(
                  error?.code === HTTP_ERRORS.BadRequest &&
                  error?.errors &&
                  error.errors[0]?.errorCode === 'InvalidDistrict'
                )
              }
              text={{ approve: intl.messages[`Modal.Success.Okay`] }}
              onApprove={() => {
                onCreateShippingAddressModalClose(selectedCustomerNo);
              }}
              onCancel={() => {
                onCreateShippingAddressModalClose(selectedCustomerNo);
              }}
            />
            {createPortal(<ModalOverlay opacity={0.2} zIndex={3999} />, document.querySelector('body') as any)}
          </>
        );
      default:
        return <></>;
    }
  };

  return <>{renderSteps()}</>;
};
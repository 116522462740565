import React from 'react';
import {
  Modal,
  ModalTitle,
  ModalHeader,
  ModalContent,
  Box,
  Button,
  ModalFooter,
  Dialog,
  DialogTypes,
  Alert,
  AlertTitle,
  AlertDescription,
  Flex,
  NumericStep,
} from '@oplog/express';
import { injectIntl, FormattedMessage } from 'react-intl';
import { LineItemOfSalesOrderQueryOutputDTO } from '@services';

export interface CancelLineItemModalProps {
  onClose: () => void;
  onCancel: (sku: string, amount: number) => void;
  intl?: any;
  item: LineItemOfSalesOrderQueryOutputDTO;
  requestInfo?: {
    isBusy: boolean;
    isCompleted: boolean;
    isError: boolean;
  };
  dialogOpen?: boolean;
  onDialogClose?: () => void;
}

const INTL_KEY = 'SalesOrderLineItemsGrid';

const CancelLineItemModal: React.FC<CancelLineItemModalProps> = ({
  onClose,
  intl,
  requestInfo,
  item,
  onCancel,
  dialogOpen,
  onDialogClose,
}: CancelLineItemModalProps) => {
  const [dialogSettings, setDialogSettings] = React.useState<{ show: boolean }>({ show: dialogOpen || false });
  const [amount, setAmount] = React.useState(dialogOpen ? 1 : 0);
  const [alertOpen, setAlertOpen] = React.useState(false);

  React.useEffect(() => {
    if (requestInfo?.isCompleted) {
      onClose();
    } else if (requestInfo?.isError) {
      setAlertOpen(true);
    }
  }, [requestInfo]);

  return (
    <>
      <Modal isOpen onClose={onClose}>
        <ModalHeader>
          <ModalTitle>{intl.messages[`${INTL_KEY}.Actions.Delete`]}</ModalTitle>
        </ModalHeader>
        <ModalContent>
          {alertOpen && (
            <Alert variant="danger" isOpen={alertOpen} onDismiss={() => setAlertOpen(false)}>
              <AlertTitle>{intl.messages['PostErrorMessage.Title']}</AlertTitle>
              <AlertDescription>{intl.messages['PostErrorMessage.Description']}</AlertDescription>
            </Alert>
          )}
          {intl.messages[`${INTL_KEY}.Actions.EnterDeleteCount`]}
          <Flex justifyContent="space-between" alignItems="center" mt="22px">
            {item.productName}
            <NumericStep onChange={input => setAmount(input)} value={amount} max={item.cancellableAmount} />
          </Flex>
        </ModalContent>
        <ModalFooter>
          <Box display="flex" justifyContent="center" m="0 auto">
            <Button onClick={onClose} mr="11px" variant="danger" size="small" minWidth="120px">
              {intl.messages[`Modal.Warning.Cancel`]}
            </Button>
            <Button
              isLoading={requestInfo?.isBusy}
              disabled={amount < 1}
              onClick={() => setDialogSettings({ show: true })}
              size="small"
              minWidth="200px"
            >
              {intl.messages[`${INTL_KEY}.Actions.Delete`]}
            </Button>
          </Box>
        </ModalFooter>
      </Modal>
      <Dialog
        type={DialogTypes.Confirmation}
        message={
          <FormattedMessage
            id={`${INTL_KEY}.Actions.AboutToDelete`}
            values={{ sku: <strong>{item.sku}</strong>, amount: <strong>{amount}</strong> }}
          />
        }
        text={{
          approve: intl.messages[`Modal.Confirmation.Okay`],
          cancel: intl.messages[`Modal.Confirmation.Cancel`],
        }}
        onApprove={() => {
          setDialogSettings({ show: false });
          onCancel(item.sku, amount);
        }}
        onCancel={() => {
          setDialogSettings({ show: false });
          onDialogClose && onDialogClose();
        }}
        isOpen={dialogSettings.show}
      />
    </>
  );
};

export default injectIntl(CancelLineItemModal);

import { GridType, ResourceType } from '@models';
import { gridSelectors } from '@oplog/data-grid';
import { resourceActions, resourceSelectors } from '@oplog/resource-redux';
import { ReceivingOrderDetails, ReceivingOrderDetailsProps } from '@pages/ReceivingOrderDetails';
import { UpdateReceivingOrderTransferDetailCommand } from '@services';
import { StoreState } from '@store';
import { getPathFromUrl, getRouteParam, onTabChange } from '@utils';
import { RouterAction } from 'connected-react-router';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { Dispatch } from 'redux';

export interface ReceivingOrderDetailsContainerProps extends RouteComponentProps {
  referenceNumber: number;
}

function mapStateToProps(
  { resources, grid }: StoreState,
  ownerProps: ReceivingOrderDetailsContainerProps
): Partial<ReceivingOrderDetailsProps> {
  const referenceNumber = getRouteParam(ownerProps, 'referenceNumber');
  const isBusy = resourceSelectors.isBusy(resources, ResourceType.ReceivingOrderDetails);
  const postIsBusy = resourceSelectors.isBusy(resources, ResourceType.UpdateReceivingOrderTransferDetails);
  const receivingOrderDetailsData = resourceSelectors.getData(resources, ResourceType.ReceivingOrderDetails);
  const error = resourceSelectors.getError(resources, ResourceType.ReceivingOrderDetails);
  const postError = resourceSelectors.getError(resources, ResourceType.UpdateReceivingOrderTransferDetails);
  const gridData = gridSelectors.getGridRawData(GridType.ReceivingOrderLineItems, grid);
  const updateSucceeded = resourceSelectors.isSuccessComplete(
    resources,
    ResourceType.UpdateReceivingOrderTransferDetails
  );
  return {
    ...receivingOrderDetailsData,
    isBusy,
    postIsBusy,
    error,
    postError,
    referenceNumber,
    updateSucceeded,
    gridData,
  };
}

function mapDispatchToProps(
  dispatch: Dispatch<resourceActions.ResourceAction | RouterAction>,
  routeProps: RouteComponentProps
): Partial<ReceivingOrderDetailsProps> {
  return {
    onUpdateTransferDetails(receivingOrderTransferDetails: UpdateReceivingOrderTransferDetailCommand): void {
      dispatch(
        resourceActions.resourceRequested(ResourceType.UpdateReceivingOrderTransferDetails, {
          command: receivingOrderTransferDetails,
        })
      );
    },
    onDidMount(): void {
      if (routeProps && routeProps.location) {
        const referenceNumber = getRouteParam(routeProps, "referenceNumber");
        dispatch(resourceActions.resourceRequested(ResourceType.ReceivingOrderDetails, { referenceNumber }));
      }
    },
    onWillUnmount: () => {
      dispatch(resourceActions.resourceInit(ResourceType.ReceivingOrderDetails));
      dispatch(resourceActions.resourceInit(ResourceType.UpdateReceivingOrderTransferDetails));
    },
    initResources: () => {
      dispatch(resourceActions.resourceInit(ResourceType.UpdateReceivingOrderTransferDetails));
    },
    onUpdateTransferDetailsSucceed: () => {
      if (routeProps && routeProps.location) {
        const referenceNumber = getRouteParam(routeProps, "referenceNumber");
        dispatch(resourceActions.resourceRequested(ResourceType.ReceivingOrderDetails, { referenceNumber }));
      }
    },
    goTab: tab => {
      onTabChange(tab, routeProps, dispatch);
    },
  };
}

export const ReceivingOrderDetailsContainer = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(injectIntl(ReceivingOrderDetails))
);

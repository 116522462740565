import { config } from '@config';
import { Box, FormControl, SelectHTML } from '@oplog/express';
import { Resource } from '@oplog/resource-redux';
import { urls } from '@routes/urls';
import { TenantOutputDTO, TENANT_ID_KEY } from '@services';
import useAuthStore from '@store/auth/auth';
import React, { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';

// For Easy Tenant Switching
export const TenantTeleport = (props: { tenantList: Resource<TenantOutputDTO[]> }) => {
  const [{ auth0UserInfo }] = useAuthStore();

  const [selectedTenant, updateSelectedTenant] = useState<string | undefined>(
    localStorage.getItem(TENANT_ID_KEY) ?? undefined
  );

  useEffect(() => {
    if (selectedTenant === undefined && auth0UserInfo[config.auth.userMetadataKey]) {
      updateSelectedTenant(auth0UserInfo[config.auth.userMetadataKey].tenant.id);
    }
  }, [auth0UserInfo]);

  const getOptions = () => {
    return props.tenantList?.data?.map((item: TenantOutputDTO) => {
      return { value: item.id, label: item.name };
    });
  };

  const handleChange = (data: any) => {
    localStorage.setItem(TENANT_ID_KEY, data.value);
    window.location.href = urls.salesOrders;
  };

  var values = getOptions();
  if (values) {
    values = values.sort((a, b) => a.label.localeCompare(b.label));
  }

  return (
    <Box mt="11">
      {props.tenantList?.data && selectedTenant !== undefined ? (
        <FormControl>
          <SelectHTML
            options={values}
            defaultValue={selectedTenant}
            onChange={(e: any) => handleChange(e.currentTarget)}
          />
        </FormControl>
      ) : (
        <Skeleton width="120px" />
      )}
    </Box>
  );
};

import { GeneralErrorPanel } from '@components/atoms/GeneralErrorPanel';
import { Box, formatUtcToLocal, isEmpty, Pipeline as PipelineExpress, Text } from '@oplog/express';
import { StateDetailOutputDTO, StateDetailsOutputDTO } from '@services';
import _ from 'lodash';
import * as React from 'react';
import { InjectedIntl } from 'react-intl';
import Component, { Props } from '../../atoms/Component/Component';

// TODO: Revisit types

export interface PipelineStepState {
  active: boolean;
  completed: boolean;
  disabled: boolean;
  cancelled: boolean;
}

export interface PipelineProps extends Props {
  intl: InjectedIntl;
  error?: ErrorModel;
  isBusy?: boolean;
  pipelineData: StateDetailsOutputDTO;
  pipelineSteps: Array<PipelineStepState>;
  enumIntlKey: string;
  detailsIntlKey?: string;
  salesOrderPackages?: object;
}

export class Pipeline extends Component<PipelineProps, {}> {
  getCompletedAt = (active: boolean, disabled: boolean, completedAt?: Date) => {
    const { intl, enumIntlKey } = this.props;
    if (completedAt) {
      return formatUtcToLocal(completedAt);
    }
    if (active) {
      return intl.messages[`Enum.${enumIntlKey}.InProgress`];
    }
    if (disabled) {
      return intl.messages[`Enum.${enumIntlKey}.NotStarted`];
    }
    return 'N/A';
  };

  getPopUpContent = (details?: any, salesOrderPackages?: any) => {
    let setMessageOk = false;
    const urlRegex = new RegExp(
      /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi
    );
    const { intl, detailsIntlKey } = this.props;
    return Object.keys(details).map(key => {
      if (!details[key]) return null;

      if (details[key].match(urlRegex)) {
        return (
          <div>
            <a href={details[key]} target="_blank">
              <Text color="text.link" fontFamily="base" fontWeight="bold" lineHeight="xxLarge" fontSize="10">
                {intl.messages[`${detailsIntlKey}.${key}`]}
              </Text>
            </a>
            <br></br>
          </div>
        );
      }

      if ((!salesOrderPackages) || (salesOrderPackages && salesOrderPackages.count <= 1)) {
        return (
          <Box key={key}>
            <Text color="palette.grey" fontFamily="base" fontWeight="700" lineHeight="xxLarge" fontSize="10">
              {intl.messages[`${detailsIntlKey}.${key}`]}
            </Text>
            :{' '}
            <Text color="palette.grey" fontFamily="base" fontWeight="500" lineHeight="xxLarge" fontSize="10">
              {details[key]}
            </Text>
          </Box>
        );
      }
      else if (salesOrderPackages && salesOrderPackages.count > 1 && !setMessageOk) {
        setMessageOk = true;
        return (
          <Box>
            <Text color="palette.grey" fontFamily="base" fontWeight="700" lineHeight="xxLarge" fontSize="10">
              {intl.messages[`${detailsIntlKey}.PackageCountMessage`]}
            </Text>
          </Box>
        ); 
      }
      else {
        return; 
      }
    });
  };

  getStepTitle = (pipelineStepState: PipelineStepState, pipelineStep: any) => {
    const { intl, enumIntlKey } = this.props;

    if (pipelineStepState === undefined || isEmpty(pipelineStepState)) {
      return '';
    }

    if (pipelineStepState.active) {
      return intl.messages[`Enum.${enumIntlKey}.${pipelineStep.state}.Active`];
    }
    if (pipelineStepState.disabled) {
      return intl.messages[`Enum.${enumIntlKey}.${pipelineStep.state}.Disabled`];
    }
    if (pipelineStepState.completed) {
      return intl.messages[`Enum.${enumIntlKey}.${pipelineStep.state}.Completed`];
    }
    if (pipelineStepState.cancelled) {
      return intl.messages[`Enum.${enumIntlKey}.-1.Cancelled`];
    }
    return '';
  };

  getPropertyAwaitingInfo = (propertyName: string) => {
    const { intl, detailsIntlKey } = this.props;

    if (propertyName == 'shippingCompany') {
      return intl.messages[`${detailsIntlKey}.AwaitingShippingCompany`];
    }
    if (propertyName == 'trackingId') {
      return intl.messages[`${detailsIntlKey}.AwaitingTrackingId`];
    }
    return '';
  };

  renderPipelineState = (pipelineStep: StateDetailOutputDTO, pipelineStepState: PipelineStepState) => {
    const { cancelled, active, disabled, completed } = pipelineStepState;
    const stepTitle = this.getStepTitle(pipelineStepState, pipelineStep);
    const completedAt: string = this.getCompletedAt(active, disabled, pipelineStep.completedAt);
    const { pipelineData, salesOrderPackages }: any = this.props;
    const cargoCarrier: string = pipelineData?.details?.[0]?.details?.cargoCarrier || '';
    const isExternalContract: boolean = salesOrderPackages?.data[0].isExternalContract;

    let icon;
    if (completed || cancelled) icon = { name: 'far fa-calendar-alt' };

    if (pipelineStep.state === 4 && pipelineStepState.completed) {
      if (!pipelineStep.details) {
        pipelineStep.details = {};
      }
      const detailKeys = Object.keys(pipelineStep.details);
      const shippingCompany = 'shippingCompany',
        trackingId = 'trackingId';

      if (pipelineStep.state === 4) {
        pipelineStep.details[shippingCompany] =
          detailKeys.includes(shippingCompany) && Boolean(pipelineStep.details[shippingCompany])
            ? pipelineStep.details[shippingCompany]
            : this.getPropertyAwaitingInfo(shippingCompany);
        pipelineStep.details[trackingId] =
          detailKeys.includes(trackingId) && Boolean(pipelineStep.details[trackingId])
            ? pipelineStep.details[trackingId]
            : this.getPropertyAwaitingInfo(trackingId);

        if (isExternalContract) {
          pipelineStep.details[shippingCompany] = cargoCarrier;
          pipelineStep.details[trackingId] = '';
        }
      }
      return {
        title: stepTitle,
        subtitle: { title: completedAt, icon },
        detail: this.getPopUpContent(pipelineStep.details, salesOrderPackages),
      };
    } else if (
      !_.isEmpty(pipelineStep.details) &&
      !_.isNull(pipelineStep.details) &&
      !_.isUndefined(pipelineStep.details)
    ) {
      return {
        title: stepTitle,
        subtitle: { title: completedAt, icon },
        detail: this.getPopUpContent(pipelineStep.details, salesOrderPackages),
      };
    } else {
      return {
        title: stepTitle,
        subtitle: { title: completedAt, icon },
      };
    }
  };

  renderPipelineSteps() {
    const { pipelineData, pipelineSteps } = this.props;

    return (
      pipelineData &&
      pipelineData.details &&
      pipelineData.details.map((step: any, index: number) => {
        return this.renderPipelineState(step, pipelineSteps[index]);
      })
    );
  }

  render() {
    const { isBusy, pipelineData, pipelineSteps, error } = this.props;

    if (error !== undefined) {
      return <GeneralErrorPanel className="align-left" />;
    }
    return (
      <PipelineExpress
        isLoading={!pipelineData || isEmpty(pipelineData.details) || isBusy}
        steps={
          pipelineData &&
          pipelineData.details &&
          (pipelineData.details.map((data, i) => {
            const pipelineStatus = Object.keys(pipelineSteps[i]).find(key => pipelineSteps[i][key]);
            return {
              state: pipelineStatus,
              ...this.renderPipelineState(data, pipelineSteps[i]),
            };
          }) as any)
        }
      />
    );
  }
}

import { GridContainer } from '@components/atoms/GridContainer';
import { barcodeFormatter, geti18nName, ProductDetailsLinkFormatter } from '@containers/formatters';
import { sourceIconFormatter } from '@containers/formatters/sourceIconFormatter';
import { GridProps, GridType } from '@models';
import {
  ColumnSize,
  DataGridColumn,
  DataGridRow,
  dateTimeFormatter,
  enumFormatter,
  getEnumOptions,
  ImageFormatter,
  PredefinedFilter,
} from '@oplog/data-grid';
import { ProductState, ProductType } from '@services';
import { generateIntegrationEnum } from '@utils';
import {
  ArrayFilterOperation,
  BooleanFilter,
  BooleanFilterOperation,
  SortDirection,
  SortField,
} from 'dynamic-query-builder-client';
import * as React from 'react';

const intlKey = 'ProductCatalogGrid';

const productGridInitalSort: SortField = new SortField({
  property: 'createdAt',
  by: SortDirection.DESC,
});

export const productGridPredefinedFilters: Array<PredefinedFilter> = [
  {
    filter: new BooleanFilter({
      id: 'Starred',
      property: 'favorite',
      op: BooleanFilterOperation.Equals,
      value: true,
    }),
    selected: false,
  },
];

interface ProductGridProps extends GridProps {
  integrations: any;
}

export const ProductGrid: React.FC<ProductGridProps> = ({ intl, titleKey, integrations }) => {
  const productGridColumns: Array<DataGridColumn> = [
    {
      name: '#',
      key: 'index',
      resizable: true,
      locked: true,
      sortable: false,
      type: 'number',
      visibility: true,
      filterable: false,
      width: ColumnSize.Medium,
      cellClass: 'index-column',
    },
    {
      name: geti18nName('integrationType', intl, intlKey),
      key: 'integrationName',
      locked: true,
      sortable: false,
      type: 'enum',
      visibility: true,
      filterable: true,
      resizable: true,
      width: ColumnSize.Large,
      formatter: row => sourceIconFormatter(row.value, row.dependentValues, intl),
      getRowMetaData: (row: DataGridRow) => row,
      options: integrations ? generateIntegrationEnum(integrations) : [],
    },
    {
      name: geti18nName('sku', intl, intlKey),
      key: 'sku',
      locked: true,
      sortable: true,
      type: 'string',
      visibility: true,
      filterable: true,
      resizable: true,
      width: ColumnSize.XLarge,
    },
    {
      name: geti18nName('barcode', intl, intlKey),
      key: 'barcodes',
      locked: true,
      type: 'string',
      visibility: true,
      resizable: true,
      sortable: false,
      filterable: true,
      formatter: barcodeFormatter,
      fieldType: 'array',
      searchField: '_',
      outerOp: ArrayFilterOperation.Any,
      width: ColumnSize.XLarge,
    },
    {
      name: geti18nName('imageUrl', intl, intlKey),
      key: 'imageUrl',
      locked: true,
      sortable: false,
      type: 'string',
      visibility: true,
      filterable: false,
      formatter: ImageFormatter,
      width: ColumnSize.Medium,
    },
    {
      name: geti18nName('name', intl, intlKey),
      key: 'name',
      locked: true,
      sortable: false,
      type: 'string',
      visibility: true,
      filterable: true,
      formatter: ProductDetailsLinkFormatter,
      getRowMetaData: (row: DataGridRow) => row,
      resizable: true,
    },
    {
      name: geti18nName('type', intl, intlKey),
      key: 'type',
      resizable: true,
      sortable: false,
      type: 'enum',
      visibility: true,
      options: getEnumOptions(intl, ProductType),
      filterable: true,
      formatter: enumFormatter,
      getRowMetaData: () => {
        return intl;
      },
    },
    {
      name: geti18nName('category', intl, intlKey),
      key: 'category',
      resizable: true,
      sortable: false,
      type: 'string',
      visibility: true,
      filterable: true,
    },
    {
      name: geti18nName('status', intl, intlKey),
      key: 'state',
      resizable: true,
      sortable: false,
      type: 'enum',
      visibility: true,
      filterable: true,
      formatter: enumFormatter,
      options: getEnumOptions(intl, ProductState),
      getRowMetaData: () => {
        return intl;
      },
    },
    {
      name: geti18nName('createdAt', intl, intlKey),
      key: 'createdAt',
      resizable: true,
      sortable: true,
      type: 'moment',
      visibility: true,
      filterable: true,
      formatter: dateTimeFormatter,
    },
    {
      name: geti18nName('favorite', intl, intlKey),
      key: 'favorite',
      resizable: true,
      sortable: false,
      type: 'boolean',
      visibility: false,
      filterable: false,
    },
  ];

  return (
    <GridContainer
      titleKey={
        titleKey
          ? `${intl.messages['ProductCatalog.Grid.Title']} - ${titleKey}`
          : intl.messages['ProductCatalog.Grid.Title']
      }
      intlKey={intlKey}
      remoteExportToExcel
      gridKey={GridType.Products}
      columns={productGridColumns}
      predefinedFilters={productGridPredefinedFilters}
      intl={intl}
      sortField={productGridInitalSort}
    />
  );
};

import { Dialog, DialogTypes } from '@oplog/express';
import React from 'react';
import { FormattedMessage, InjectedIntl } from 'react-intl';
import { ExportTypes } from '../ExcelExportModal';

interface DialogProps {
  rowCount: number;
  isOpen: boolean;
  closeDialog: () => void;
  intl: InjectedIntl;
  exportLimit: number;
  excelExportType: ExportTypes;
  isDetailed: boolean;
}

export const ExcelExportFilterErrorDialog: React.FC<DialogProps> = ({
  isOpen,
  intl,
  closeDialog,
  rowCount,
  exportLimit,
  excelExportType,
  isDetailed,
}) => {
  return (
    <Dialog
      type={DialogTypes.Information}
      showCloseButton={false}
      message={
        isDetailed ? (
          <FormattedMessage
            id={`ExcelUploadModal.ExportLimit`}
            values={{
              rowCount: <b>{rowCount.toString()}</b>,
              type: <b>{intl.messages[`ExcelUploadModal.ExcelDetailedExportTypeText.${excelExportType}`]}</b>,
              exportRowCountLimit: <b>{exportLimit.toString()}</b>,
            }}
          />
        ) : (
          <FormattedMessage
            id={`ExcelUploadModal.ExportLimit`}
            values={{
              rowCount: <b>{rowCount.toString()}</b>,
              type: <b>{intl.messages[`ExcelUploadModal.ExportTypeTexts.${excelExportType}`]}</b>,
              exportRowCountLimit: <b>{exportLimit.toString()}</b>,
            }}
          />
        )
      }
      text={{
        approve: intl.messages[`Modal.Success.Okay`],
      }}
      onApprove={closeDialog}
      isOpen={isOpen}
    />
  );
};

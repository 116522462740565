import { GridType, ResourceType } from '@models';
import { gridActions } from '@oplog/data-grid';
import { GridFetchRequested } from '@oplog/data-grid/dist/store/actions';
import { resourceActions, resourceSelectors } from '@oplog/resource-redux';
import { RouterAction } from 'connected-react-router';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import {
  SyncUnfulfilledAndCancelledIntegrationsModal,
  SyncUnfulfilledAndCancelledIntegrationsModalProps,
} from '../../components/molecules/SyncUnfulfilledAndCancelledIntegrationsModal';
import { StoreState } from '../../store/state';

export interface SupportSalesOrdersSyncCancelledIntegrationsModalContainerProps {
  onClose: () => void;
}

function mapStateToProps(
  { resources }: StoreState,
  ownProps: SupportSalesOrdersSyncCancelledIntegrationsModalContainerProps
): Partial<SyncUnfulfilledAndCancelledIntegrationsModalProps> {
  return {
    responseData: resourceSelectors.getData(resources, ResourceType.SyncFromIntegrationCancelled),
    isBusy:
      resourceSelectors.isBusy(resources, ResourceType.SyncFromIntegrationCancelled) ||
      resourceSelectors.isBusy(resources, ResourceType.GetIntegrations),
    hasError: resourceSelectors.hasError(resources, ResourceType.SyncFromIntegrationCancelled),
    COMPONENT_INTL_KEY: 'SupportSalesOrdersSyncCancelledIntegrations.Modal',
  };
}

function mapDispatchToProps(
  dispatch: Dispatch<resourceActions.ResourceAction | RouterAction | GridFetchRequested>
): Partial<SyncUnfulfilledAndCancelledIntegrationsModalProps> {
  return {
    syncCommandTimeRange: (integrationId: String, startDate: Date, endDate: Date) => {
      const jsonObject = {
        integrationId: integrationId,
        fetchByTimeRangeMethod: {
          start: startDate,
          end: endDate,
        },
      };
      dispatch(resourceActions.resourceRequested(ResourceType.SyncFromIntegrationCancelled, { jsonObject }));
    },
    onWillUnmount: () => {
      dispatch(resourceActions.resourceInit(ResourceType.SyncFromIntegrationCancelled));
      dispatch(gridActions.gridFetchRequested(GridType.SupportSalesOrders));
    },
  };
}

export const SupportSalesOrdersSyncCancelledIntegrationsModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(SyncUnfulfilledAndCancelledIntegrationsModal));

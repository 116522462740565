import { Text } from '@oplog/express';
import * as React from 'react';

export function skuReceivingOrderLineItemFormatter(row: any) {
  if (row) {
    if (row.dependentValues.isUnexpected) {
      return <Text color="red">{row.value}</Text>;
    }
    return <>{row.value}</>;
  }

  return <></>;
}

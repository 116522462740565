import { CustomersGrid } from '@components';
import { MemberPageContainer } from '@containers/MemberPageContainer/MemberPageContainer';
import { Button, ActionBar, LayoutContent, Panel } from '@oplog/express';
import * as React from 'react';
import { Props } from '../../atoms/Component/Component';

const COMPONENT_INTL_KEY = 'Customers';

export interface CustomersProps extends Props {
  onCreateCustomerClickHandler: () => void;
}

export const Customers: React.FC<CustomersProps> = props => {
  const { intl } = props;
  const breadcrumb = [{ title: intl.messages[`${COMPONENT_INTL_KEY}.Header.Title`] }];

  return (
    <MemberPageContainer documentTitle={intl.messages[`${COMPONENT_INTL_KEY}.Header.Title`]}>
      <ActionBar top="66px" title={intl.messages[`${COMPONENT_INTL_KEY}.Header.Title`]} breadcrumb={breadcrumb}>
        <Button marginLeft="auto" size="large" variant="dark" onClick={props.onCreateCustomerClickHandler}>
          {intl.messages[`${COMPONENT_INTL_KEY}.CreateCustomer`]}
        </Button>
      </ActionBar>

      <LayoutContent className="page-content">
        <Panel title={intl.messages[`${COMPONENT_INTL_KEY}.Grid.Title`]}>
          <CustomersGrid intl={intl} />
        </Panel>
      </LayoutContent>
    </MemberPageContainer>
  );
};

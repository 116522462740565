export const config: any = {
  api: {
    url: 'https://one-webapi-int-01-a3e7egcecqebdffg.westeurope-01.azurewebsites.net',
  },
  logger: {
    level: 'warn',
    appinsights: {
      instrumentationKey: '4490c59e-fffb-4c8a-9c91-e81ddc11e5c0',
    },
  },
  auth: {
    userMetadataKey: 'https://omniservices-int.azureedge.net/user_metadata',
    userPermissionKey: 'https://omniservices-int.azureedge.net/permissions',
    domain: 'omniservices-staging.eu.auth0.com',
    client_id: 'nJLnPOstdP0aoMsq1nj40NP6GOU-fsEZ',
    redirect_uri: 'https://int.oplog.one/callback',
    logout_uri: 'https://int.oplog.one/login',
    audience: 'https://omnitro.com/web',
    responseType: 'token id_token',
    scope: 'openid email profile offline_access',
    leeway: 30,
  },
  elastic: {
    serviceName: 'Omniservices WebApp',
    serverUrl: 'https://c70736125c33418187ef7ec8b1cc98d9.apm.westeurope.azure.elastic-cloud.com:443',
    serviceVersion: '1.0',
    environment: 'int',
  },
};

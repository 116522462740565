import { config } from '@config';

const LANG_KEY = 'omnitro:lang';

export const LanguageHelper = {
  getLanguage: () => {
    const localLang = localStorage.getItem(LANG_KEY);
    if (localLang && localLang !== null) {
      return localLang;
    }
    return config.i18n.default;
  },

  setLanguage: (data: string) => {
    localStorage.setItem(LANG_KEY, data);
    window.location.reload();
  },
};

export const CREATE_RETURN_LINE_ITEM_ADDED = 'CREATE_RETURN_LINE_ITEM_ADDED';
export type CREATE_RETURN_LINE_ITEM_ADDED = typeof CREATE_RETURN_LINE_ITEM_ADDED;

export const CREATE_RETURN_LINE_ITEMS_REMOVED = 'CREATE_RETURN_LINE_ITEM_REMOVED';
export type CREATE_RETURN_LINE_ITEMS_REMOVED = typeof CREATE_RETURN_LINE_ITEMS_REMOVED;

export const CREATE_RETURN_FORM_EDITED = 'CREATE_RETURN_FORM_EDITED';
export type CREATE_RETURN_FORM_EDITED = typeof CREATE_RETURN_FORM_EDITED;

export const CREATE_RETURN_GRID_EDITED = 'CREATE_RETURN_GRID_EDITED';
export type CREATE_RETURN_GRID_EDITED = typeof CREATE_RETURN_GRID_EDITED;

export const CREATE_RETURN_SALES_ORDER_SELECTED = 'CREATE_RETURN_SALES_ORDER_SELECTED';
export type CREATE_RETURN_SALES_ORDER_SELECTED = typeof CREATE_RETURN_SALES_ORDER_SELECTED;

export const CREATE_RETURN_SALES_ORDER_REMOVED = 'CREATE_RETURN_SALES_ORDER_REMOVED';
export type CREATE_RETURN_SALES_ORDER_REMOVED = typeof CREATE_RETURN_SALES_ORDER_REMOVED;

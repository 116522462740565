import { GridContainer } from '@components/atoms/GridContainer';
import { geti18nName } from '@containers/formatters';
import { GridProps, GridType } from '@models';
import { ColumnSize, DataGridColumn } from '@oplog/data-grid';
import { SortDirection, SortField } from 'dynamic-query-builder-client';
import * as React from 'react';

const intlKey = 'ReturnsDamangedGrid';

const returnsDamangedGridInitialSort: SortField = new SortField({
  property: 'sku',
  by: SortDirection.DESC,
});

export class ReturnsDamangedGrid extends React.Component<GridProps, {}> {
  render() {
    const { intl, titleKey } = this.props;
    const returnsDamangedGridColumns: DataGridColumn[] = [
      {
        name: '#',
        key: 'index',
        resizable: true,
        locked: true,
        sortable: false,
        type: 'number',
        visibility: true,
        filterable: false,
        width: ColumnSize.Medium,
        cellClass: 'index-column',
      },
      {
        name: geti18nName('sku', intl, intlKey),
        key: 'sku',
        locked: true,
        sortable: true,
        type: 'string',
        visibility: true,
        filterable: true,
        resizable: true,
      },

      {
        name: geti18nName('name', intl, intlKey),
        key: 'name',
        locked: true,
        sortable: true,
        type: 'string',
        visibility: true,
        filterable: true,
        resizable: true,
      },

      {
        name: geti18nName('waitingInWarehouseCount', intl, intlKey),
        key: 'waitingInWarehouseCount',
        locked: true,
        sortable: true,
        type: 'string',
        visibility: true,
        filterable: true,
        resizable: true,
      },
    ];

    return (
      <GridContainer
        titleKey={
          titleKey
            ? `${intl.messages['ReturnsDamangedGrid.Grid.Title']} - ${titleKey}`
            : intl.messages['ReturnsDamangedGrid.Grid.Title']
        }
        intlKey={intlKey}
        gridKey={GridType.ReturnsDamaged}
        columns={returnsDamangedGridColumns}
        predefinedFilters={[]}
        intl={intl}
        sortField={returnsDamangedGridInitialSort}
      />
    );
  }
}

import { addLocaleData } from 'react-intl';

/**
 * flattenMessages converts deep objects to flat map of {[key:string]:string}
 * Example input:{ a: {b: "c"}}
 * Example output: {"a.b": "c"}
 */
const flattenMessages = (nestedMessages: any, prefix = '') => {
  if (nestedMessages === undefined) {
    return {};
  }
  return Object.keys(nestedMessages).reduce((messages, key) => {
    const value = nestedMessages[key];
    const prefixedKey = prefix ? `${prefix}.${key}` : key;

    if (typeof value === 'string') {
      Object.assign(messages, { [prefixedKey]: value });
    } else {
      Object.assign(messages, flattenMessages(value, prefixedKey));
    }

    return messages;
  }, {});
};

/**
 * getLocale returns intl shape with messages & formats given locale
 * @param locale
 */
export function getLocale(locale: string) {
  const trLanguages = ['tr', 'tr-TR'];

  if (trLanguages.includes(locale)) {
    locale = 'tr-TR';
  } else {
    locale = 'en-US';
  }
  document.querySelector('html')!.lang = locale;
  const en = require(`react-intl/locale-data/en`);
  const tr = require(`react-intl/locale-data/tr`);
  addLocaleData([...en, ...tr]);

  const { messages } = require(`./messages/${locale}`);

  return {
    locale,
    messages,
  };
}

/**
 * buildMessages consolidates all container translations into single messages object
 * @param locale
 */
export function buildMessages(locale: string): object {
  let messages = {};

  const commonMessages = require(`./${locale}`);
  messages = {
    ...messages,
    ...flattenMessages(commonMessages),
  };

  return messages;
}

import { clickableCellAction } from '@oplog/data-grid';
import { resourceActions } from '@oplog/resource-redux';
import { onTabChange } from '@utils';
import { RouterAction } from 'connected-react-router';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Dispatch } from 'redux';
import { SupportSalesOrders, SupportSalesOrdersProps } from '../../components/pages/SupportSalesOrders';

function mapDispatchToProps(
  dispatch: Dispatch<resourceActions.ResourceAction | clickableCellAction.ClickableCellActions | RouterAction>,
  routeProps: RouteComponentProps
): Partial<SupportSalesOrdersProps> {
  return {
    closeModalViewer: (): void => {
      dispatch(clickableCellAction.clickableCellResetted());
    },
    goTab: (tab: any): void => {
      onTabChange(tab, routeProps, dispatch);
    },
  };
}

export const SupportSalesOrdersContainer = connect(null, mapDispatchToProps)(injectIntl(SupportSalesOrders));

import { ResourceType } from '@models';
import { gridActions } from '@oplog/data-grid';
import { resourceActions, resourceSelectors } from '@oplog/resource-redux';
import { ReturnDetails, ReturnDetailsProps } from '@pages/ReturnDetails';
import { StoreState } from '@store';
import { returnDetailsActions } from '@store/returnDetails';
import { getPathFromUrl } from '@utils';
import { RouterAction } from 'connected-react-router';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Dispatch } from 'redux';

function mapStateToProps({ resources }: StoreState): Partial<ReturnDetailsProps> {
  return {
    resources: resourceSelectors.getResource(resources, ResourceType.ReturnDetails),
  };
}

function mapDispatchToProps(
  dispatch: Dispatch<
    | resourceActions.ResourceAction
    | RouterAction
    | returnDetailsActions.ReturnDetailsAction
    | gridActions.GridFetchRequested
  >,
  routeProps: RouteComponentProps
): Partial<ReturnDetailsProps> {
  return {
    onDidMount: (): void => {
      if (routeProps && routeProps.location) {
        const returnNo = getPathFromUrl(routeProps.location.pathname);
        dispatch(
          resourceActions.resourceRequested(ResourceType.ReturnDetails, {
            returnNo,
          })
        );
        dispatch(
          resourceActions.resourceRequested(ResourceType.SalesOrderOfReturn, {
            returnNo,
          })
        );
      }
    },
  };
}

export const ReturnDetailsContainer = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(injectIntl(ReturnDetails))
);

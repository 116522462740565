import {
  Datepicker,
  DATE_FORMAT,
  DATE_FORMAT_NO_TIME,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Popover,
  Radio,
  Image,
  RadioGroup,
  FormValidator,
  FormErrorMessage,
} from '@oplog/express';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Props } from '../../atoms/Component/Component';

export enum TransferType {
  Cargo = 'option-cargo',
  Pickup = 'option-pickup',
}

export enum CargoType {
  Cargo = 'Cargo',
  SelfShipment = 'SelfShipment',
}

const COMPONENT_ID_KEY = 'transferinfoform';

export interface TransferInfoFormProps extends Props {
  isBusy?: boolean;
  intlKey: string;
  selectedCargoOption?: CargoType;
  selectedTransferOption?: TransferType;
  selectedDate?: Date;
  cargoFormCompany?: string;
  cargoFormNumber?: string;
  referenceNumber?: string;
  cargoFormPickupAddress?: string; // unused.
  validator?: FormValidator;
  onCargoFormRadioChange: (e: React.SyntheticEvent<any>) => void;
  onReferenceNumberChange?: (e: React.SyntheticEvent<any>) => void;
  onCompanyNameChange: (e: React.SyntheticEvent<HTMLInputElement>) => void;
  onWaybillOrTrackingNumberChange: (event: React.SyntheticEvent<HTMLInputElement>) => void;
  onDateTimeChange: (date: Date) => void;
  onPickupAddressChange: (event: React.SyntheticEvent<HTMLInputElement>) => void; // unused.
  handleOnBlur?: (key: string) => void;
}

export const TransferInfoForm: React.FC<TransferInfoFormProps> = ({
  isBusy,
  intlKey,
  selectedCargoOption,
  intl,
  selectedTransferOption,
  selectedDate,
  cargoFormCompany,
  cargoFormNumber,
  referenceNumber,
  validator,
  onCargoFormRadioChange,
  onCompanyNameChange,
  onWaybillOrTrackingNumberChange,
  onDateTimeChange,
  onReferenceNumberChange,
  handleOnBlur,
}) => {
  const [cargoFormCompanyState, setCargoFormCompanyState] = useState('');
  const [cargoFormNumberState, setCargoFormNumberState] = useState('');

  useEffect(() => {
    setCargoFormCompanyState(cargoFormCompany || '');
  }, [cargoFormCompany]);

  useEffect(() => {
    setCargoFormNumberState(cargoFormNumber || '');
  }, [cargoFormNumber]);

  const addDays = (date: Date, days: number) => {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  };

  const renderCargoForm = () => {
    // TODO return to prev state after fixing oplog express library
    // const datePicker = {
    //   dateFormat: selectedCargoOption === CargoType.Cargo ? DATE_FORMAT_NO_TIME : DATE_FORMAT,
    //   inputFormat: selectedCargoOption === CargoType.Cargo ? DATE_FORMAT_NO_TIME : DATE_FORMAT,
    // };

    const datePicker = {
      dateFormat: DATE_FORMAT_NO_TIME,
      inputFormat: DATE_FORMAT_NO_TIME,
    };

    // TODO return to prev state after fixing oplog express library
    // const isTimeVisible = selectedCargoOption !== CargoType.Cargo;
    const isTimeVisible = false;
    return (
      <>
        {referenceNumber !== undefined && (
          <FormControl mb="11" isDisabled={isBusy}>
            <FormLabel>{intl.messages[`${intlKey}.ReferenceNumber`]}</FormLabel>
            <Input
              type="text"
              onChange={onReferenceNumberChange}
              value={referenceNumber}
              data-cy={`${COMPONENT_ID_KEY}-input-referenceNumber`}
            />
          </FormControl>
        )}

        <FormControl mb="11">
          <FormLabel>{intl.messages[`${intlKey}.TypeOfShipment`]}</FormLabel>
          <RadioGroup
            onChange={onCargoFormRadioChange}
            isInline
            defaultValue={selectedCargoOption || CargoType.Cargo}
            spacing="22px"
          >
            <Radio
              name="radioGroup"
              value={CargoType.Cargo}
              checked={selectedCargoOption === CargoType.Cargo}
              isDisabled={isBusy}
              data-cy={`${COMPONENT_ID_KEY}-radio-radio1`}
            >
              {intl.messages[`${intlKey}.Cargo.TypeName`]}
            </Radio>
            <Radio
              name="radioGroup"
              value={CargoType.SelfShipment}
              checked={selectedCargoOption === CargoType.SelfShipment}
              isDisabled={isBusy}
              data-cy={`${COMPONENT_ID_KEY}-radio-radio2`}
            >
              {intl.messages[`${intlKey}.SelfShipment.TypeName`]}
            </Radio>
          </RadioGroup>
        </FormControl>
        <FormControl mb="11" isDisabled={isBusy}>
          <FormLabel>{intl.messages[`${intlKey}.${selectedCargoOption}.CompanyName`]}</FormLabel>
          <Input
            id="cargo-firmasi-field"
            type="text"
            onChange={onCompanyNameChange}
            value={cargoFormCompanyState}
            data-cy={`${COMPONENT_ID_KEY}-input-cargoField`}
          />
        </FormControl>
        <FormControl mb="11" isDisabled={isBusy}>
          <FormLabel>{intl.messages[`${intlKey}.${selectedCargoOption}.TraceNo`]}</FormLabel>
          <Input
            id="takip-no-field"
            type="text"
            onChange={onWaybillOrTrackingNumberChange}
            value={cargoFormNumberState}
            data-cy={`${COMPONENT_ID_KEY}-input-cargoTracking`}
          />
        </FormControl>
        <FormControl
          mb="11"
          isDisabled={isBusy}
          isInvalid={!!validator?.getErrorIntent('shipmentDate')}
        >
          <FormLabel>
            {intl.messages[`${intlKey}.DateOfShipment`]}*
            <Popover
              isDark
              content={<Flex>{intl.messages[`${intlKey}.DateOfShipmentInfo`]}</Flex>}
              placement="bottom"
              withArrow
              action={['hover']}
            >
              <Image src="/images/information_icon.png" width="20px" marginLeft="5px" marginTop="-5px" />
            </Popover>
          </FormLabel>
          <Datepicker
            id="shipmentDate"
            popperPlacement="top-start"
            dateFormat={datePicker.dateFormat}
            value={selectedDate?.toString()}
            selected={selectedDate}
            onChange={(e) => {
              onDateTimeChange(e!)
            }}
            inputFormat={datePicker.inputFormat}
            minDate={new Date().getHours() >= 16 ? addDays(new Date(), 2) : addDays(new Date(), 1)}
            showTimeSelect={isTimeVisible}
            showDisabledMonthNavigation
            onBlur={(e) => {
              if (handleOnBlur !== undefined) {
                handleOnBlur("shipmentDate")
              }
            }}
            popperProps={{
              positionFixed: true,
            }}
            data-cy={`${COMPONENT_ID_KEY}-datepicker-cargodate`}
          />
          <FormErrorMessage>{validator?.getErrorIntent('shipmentDate')?.text}</FormErrorMessage>
        </FormControl>
      </>
    );
  };

  // This is unused. But further investigation is required to remove the props that was added for this functionality..

  /*

  const renderPickupForm = () =>  {


    return (
      <>
        <FormControl mb="11" isDisabled={isBusy}>
          <FormLabel>{intl.messages[`${intlKey}.Pickup.Address`]}</FormLabel>
          <Textarea rows={3} onChange={onPickupAddressChange} value={cargoFormPickupAddressState} />
        </FormControl>
        <FormControl mb="11">
          <FormLabel>{intl.messages[`${intlKey}.Pickup.DateOfPickup`]}</FormLabel>
          <Datepicker
            dateFormat={DATE_FORMAT}
            popperPlacement="top-start"
            selected={selectedDate}
            onChange={onDateTimeChange}
            showTimeSelect
            showDisabledMonthNavigation
            inputFormat={DATE_FORMAT}
            minDate={new Date()}
            popperProps={{
              positionFixed: true,
            }}
          />
        </FormControl>
      </>
    );
  }

  */

  return <>{selectedTransferOption === TransferType.Cargo && renderCargoForm()}</>;
};

import { Auth0Provider } from '@auth/auth0';
import { config } from '@config';
import { init as initApm } from '@elastic/apm-rum';
import { ThemeProvider } from '@oplog/express';
import { routes } from '@routes';
import { initStore } from '@store';
import { ConnectedRouter } from 'connected-react-router';
import * as React from 'react';
import { IntlProvider } from 'react-intl-redux';
import { Provider } from 'react-redux';
import { Route, Switch } from 'react-router';
import GlobalStyle from './globalStyles';
import history from './history';
import theme from './theme';
import { tracking } from './utils/analytics';

initApm(config.elastic);
tracking.initAnalytics();
export const store = initStore();

const App: React.FC = () => {
  const renderRoutes = () => {
    return routes
      .filter(r => r.enabled)
      .map(route => {
        if (route.protectedRoute && route.component) {
          return <Route exact key={route.key} path={route.url} component={route.component} />;
        }
        if (!route.protectedRoute && route.component) {
          return <Route key={route.key} path={route.url} component={route.component} exact />;
        }
        throw {
          message: `No component or render is available for route: ${route.url}`,
          code: 0,
        };
      });
  };

  return (
    <Provider store={store}>
      <IntlProvider>
        <Auth0Provider>
          <ThemeProvider customTheme={theme}>
            <GlobalStyle />
            <ConnectedRouter history={history}>
              <React.Suspense fallback={<p>loader</p>}>
                <Switch>{renderRoutes()}</Switch>
              </React.Suspense>
            </ConnectedRouter>
          </ThemeProvider>
        </Auth0Provider>
      </IntlProvider>
    </Provider>
  );
};

export default App;

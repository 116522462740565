import { customi18nEnumFormatter } from '@containers/formatters/customi18nEnumFormatter';
import { packageLinkFormatter } from '@containers/formatters/packageLinkComponent';
import { packageStateFormatter } from '@containers/formatters/packageStateFormatter';
import { ColumnSize, DataGridColumn, DataGridRow, dateTimeFormatter } from '@oplog/data-grid';
import { PackageState } from '@services';
import { geti18nName } from '../../../../containers/formatters';

export const getPackageProgressionColumns = (intl: any, intlKey: any): DataGridColumn[] => {
  const SupportSalesOrdersColumns: Array<DataGridColumn> = [
    {
      name: '#',
      key: 'index',
      resizable: false,
      locked: true,
      sortable: false,
      type: 'number',
      visibility: true,
      filterable: false,
      cellClass: 'index-column',
      width: ColumnSize.Medium,
    },
    {
      name: geti18nName('id', intl, intlKey),
      key: 'id',
      resizable: true,
      locked: true,
      sortable: true,
      type: 'string',
      visibility: true,
      filterable: true,
    },
    {
      name: geti18nName('salesOrderId', intl, intlKey),
      key: 'salesOrderId',
      resizable: true,
      locked: true,
      sortable: true,
      type: 'string',
      visibility: true,
      filterable: true,
    },
    {
      name: geti18nName('referenceNumber', intl, intlKey),
      key: 'referenceNumber',
      resizable: true,
      locked: true,
      sortable: true,
      type: 'string',
      visibility: true,
      filterable: true,
      formatter: packageLinkFormatter,
      getRowMetaData: (row: DataGridRow) => row,
    },
    {
      name: geti18nName('state', intl, intlKey),
      key: 'state',
      locked: true,
      sortable: false,
      type: 'enum',
      visibility: true,
      filterable: true,
      formatter: packageStateFormatter,
      getRowMetaData: (row: DataGridRow) => {
        return { row, intl, intlKey };
      },
      options: customi18nEnumFormatter(intl, PackageState),
    },
    {
      name: geti18nName('pickedAt', intl, intlKey),
      key: 'pickedAt',
      locked: true,
      sortable: true,
      type: 'moment',
      visibility: true,
      filterable: true,
      resizable: true,
      formatter: dateTimeFormatter,
    },
    {
      name: geti18nName('packedAt', intl, intlKey),
      key: 'packedAt',
      locked: true,
      sortable: true,
      type: 'moment',
      visibility: true,
      filterable: true,
      resizable: true,
      formatter: dateTimeFormatter,
    },
    {
      name: geti18nName('shippedAt', intl, intlKey),
      key: 'shippedAt',
      locked: true,
      sortable: true,
      type: 'moment',
      visibility: true,
      filterable: true,
      resizable: true,
      formatter: dateTimeFormatter,
    },
    {
      name: geti18nName('deliveredAt', intl, intlKey),
      key: 'deliveredAt',
      locked: true,
      sortable: true,
      type: 'moment',
      visibility: true,
      filterable: true,
      resizable: true,
      formatter: dateTimeFormatter,
    },
  ];
  return SupportSalesOrdersColumns;
};

export default getPackageProgressionColumns;

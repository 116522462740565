/* eslint-disable no-nested-ternary */
import React from 'react';
import { CargoType, LineItemOfSalesOrderQueryOutputDTODynamicQueryOutputDTO, SalesOrderDetailOutputDTO } from '@services';
import {
  Modal,
  ModalTitle,
  ModalHeader,
  ModalContent,
  Box,
  Heading,
  NumericStep,
  PseudoBox,
  Button,
  ModalFooter,
  Dialog,
  DialogTypes,
  Alert,
  AlertTitle,
  AlertDescription,
  Tooltip,
  Icon,
  Flex,
  Datepicker,
  DATE_FORMAT_NO_TIME,
  DATE_FORMAT,
  Text,
  Popover,
  Image
} from '@oplog/express';
import { injectIntl, FormattedMessage } from 'react-intl';

export interface NewPackageModalProps {
  onClose: () => void;
  onCreate: (data: any) => void;
  intl?: any;
  items: LineItemOfSalesOrderQueryOutputDTODynamicQueryOutputDTO;
  cargoType: CargoType;
  referenceNumber?: string;
  requestInfo?: {
    isBusy: boolean;
    isCompleted: boolean;
    isError: boolean;
  };
  type: SalesOrderDetailOutputDTO['packageCreationType'];
  error?: ErrorModel;
}

interface SelectedItem {
  sku: string;
  amount: number;
}

const INTL_KEY = 'Packages';

const NewPackageModal: React.FC<NewPackageModalProps> = ({
  items,
  onClose,
  intl,
  requestInfo,
  onCreate,
  type,
  error,
  cargoType
}: NewPackageModalProps) => {
  const [selectedItems, setSelectedItems] = React.useState<SelectedItem[]>([]);
  const [expectedShipmentDate, setexpectedShipmentDate] = React.useState<any>(null);
  const [dialogSettings, setDialogSettings] = React.useState<{ show: boolean; type?: DialogTypes; message?: string }>();
  const [alertOpen, setAlertOpen] = React.useState(false);
  const [expectedShipmentDateOpen, setexpectedShipmentDateOpen] = React.useState(false);

  React.useEffect(() => {
    if (requestInfo?.isCompleted) {
      setDialogSettings({ show: true, type: DialogTypes.Success, message: intl.messages[`Packages.Dialog.Success`] });
      onClose();
    } else if (requestInfo?.isError) {
      setAlertOpen(true);
    }
  }, [requestInfo]);

  React.useEffect(() => {
    if (cargoType == CargoType.ScheduledDelivery || cargoType == CargoType.ManualDelivery) {
      setexpectedShipmentDateOpen(true);
    }
  });

  const onAmountChange = (amount: number, sku: string) => {
    let itemList = [...selectedItems];
    const itemExist = itemList.findIndex(item => item.sku === sku);
    if (itemExist === -1 && amount !== 0) {
      itemList = [...itemList, { amount, sku }];
      setSelectedItems(itemList);
    } else {
      if (amount === 0) {
        itemList.splice(itemExist, 1);
      } else {
        itemList[itemExist].amount = amount;
      }
      setSelectedItems(itemList);
    }
  };

  const tableHeads = [
    { title: 'sku', tableKey: 'sku' },
    { title: 'productName', tableKey: 'productName', others: { flex: ['1 1 50%', '1 1 80%'] } },
    { title: 'maxAmount', tableKey: 'maxAmount', others: { flex: ['1 1 50%', '1 1 30%'] } },
    { title: 'count', tableKey: 'count', others: { flex: ['1 1 auto', '1 1 300px'] } },
  ];

  const handleDateTimeChangeManualDelivery = (date: Date) => {
    setexpectedShipmentDate(date);
  };

  const datePicker = {
    dateFormat: DATE_FORMAT_NO_TIME,
    inputFormat: DATE_FORMAT_NO_TIME,
    dateFormatWithTime: DATE_FORMAT
  };

  const getMinDate = () => {
    const date = new Date()

    if (date.getDay() == 5) {
      return addDays(new Date(), 3)
    } else if (date.getDay() == 6 || date.getHours() >= 18) {
      return addDays(new Date(), 2)
    } else {
      return addDays(new Date(), 1)
    }
  };

  const addDays = (date: Date, days: number) => {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  };

  const getMinTime = () => {
    const currentDate = new Date();

    if (
      expectedShipmentDate &&
      expectedShipmentDate.getDate() <= currentDate.getDate() + 1 &&
      expectedShipmentDate.getMonth() === currentDate.getMonth() &&
      expectedShipmentDate.getFullYear() === currentDate.getFullYear()
    ) {
      // If it's tomorrow, set the minimum time to the current hour
      return currentDate;
    }

    // For any other day, set the minimum time to 08:00
    const minTime = new Date(currentDate);
    minTime.setDate(minTime.getDate() + 1);
    minTime.setHours(8, 0, 0, 0);
    return minTime;
  };

  const getMaxTime = () => {
    const currentDate = new Date();
    currentDate.setHours(18, 0, 0, 0);
    return currentDate;
  };

  const isWeekday = (date: Date) => {
    const day = date.getDay();
    return day !== 0 && day !== 6; // Exclude Sunday (0) and Saturday (6)
  };

  const handleSubmit = () => {
    const data: any = {
      selectedItems: selectedItems,
      expectedShipmentDate: expectedShipmentDate
    };
    onCreate(data);
  };

  const getErrors = (): string[] => {
    console.log("error", error);
    if (error) {
      return error.errors
        ? error.errors.map(item => intl.messages[`PostErrorMessage.${item.errorCode}`])
        : [intl.messages[`PostErrorMessage.DefaultMessage`]];
    }
    return [];
  };

  return (
    <>
      <Modal isOpen onClose={onClose} size="6xl">
        <ModalHeader>
          <ModalTitle>{intl.messages[`${INTL_KEY}.Create`]}</ModalTitle>
        </ModalHeader>
        <ModalContent>
          {alertOpen && (
            <Alert variant="danger" isOpen={alertOpen} onDismiss={() => setAlertOpen(false)}>
              <AlertTitle>{intl.messages['PostErrorMessage.Title']}</AlertTitle>
              <AlertDescription>{getErrors()}</AlertDescription>
            </Alert>
          )}

          {expectedShipmentDateOpen && (
            <Box mt="22">
              <Text
                mb="11"
                fontFamily="heading"
                fontWeight={800}
                fontSize="13"
                letterSpacing="negativeSmall"
              >
                {intl.messages[`CreateSalesOrderContextualPanel.OrderInfoFormHeaders.ExpectedShipmentDate`]}
                <Popover
                  isDark
                  content={intl.messages[`${INTL_KEY}.CreatePackageExpectedShipmentDateInfo`]}
                  placement="bottom"
                  action={['hover']}
                >
                  <Image src="/images/information_icon.png" width="20px" marginLeft="5px" marginTop="-5px" />
                </Popover>
              </Text>
              <Flex mt="11">
                <Datepicker
                  id="date-field"
                  popperPlacement="top-start"
                  dateFormat="yyyy-MM-dd HH:mm"
                  value={expectedShipmentDate?.toString()}
                  selected={expectedShipmentDate}
                  onChange={handleDateTimeChangeManualDelivery}
                  inputFormat={datePicker.dateFormatWithTime}
                  minDate={getMinDate()}
                  maxDate={addDays(new Date(), 365)}
                  minTime={getMinTime()}
                  maxTime={getMaxTime()}
                  showTimeSelect={true}
                  showDisabledMonthNavigation
                  filterDate={isWeekday}
                />
              </Flex>
            </Box>
          )}


          <Box display={['none', 'flex']} py="11px">
            {tableHeads.map((head, i) => (
              <Heading
                fontSize="11"
                textTransform="uppercase"
                key={i.toString()}
                flex="1 1 20%"
                mr="11px"
                alignSelf="center"
                {...head.others}
              >
                {intl.messages[`SalesOrderLineItemsGrid.Column.${head.title}`]}
              </Heading>
            ))}
          </Box>
          {items.data?.map(item => {
            let maxAmount = item.packableAmount;

            const stockMissing = item.availableStock < item.packableAmount;
            if (stockMissing) maxAmount = item.availableStock;

            if (maxAmount < 1) {

              return null;
            }

            return (
              <PseudoBox
                display="flex"
                py={['11px', '6px']}
                px={['11px', '0']}
                alignItems={['flex-start', 'center']}
                key={item.sku}
                margin={['16px 0', '-1px 0']}
                borderY={['none', 'xs']}
                borderColor={['none', '#e0e0e0']}
                _last={{ borderBottom: 0 }}
                flexWrap={['wrap', 'nowrap']}
                boxShadow={['large', 'none']}
              >
                {tableHeads.map((head, i) => {
                  const itemExist = selectedItems.find(selectedItem => selectedItem.sku === item['sku']);
                  return (
                    <Box
                      as="div"
                      key={i.toString()}
                      flex={['1 1 50%', '1 1 20%']}
                      mr="11px"
                      marginBottom={['11px', '0']}
                      {...head.others}
                    >
                      <Heading fontSize="11" textTransform="uppercase" mb="6px" display={['block', 'none']}>
                        {head.title}
                      </Heading>
                      {head.tableKey === 'count' ? (
                        <>
                          {type === 'ByQuantity' && (
                            <NumericStep onChange={amount => onAmountChange(amount, item['sku'])} max={maxAmount} />
                          )}
                          {type === 'BySKU' && (
                            <Button
                              variant={itemExist ? 'dark' : 'primary'}
                              size="small"
                              onClick={() => onAmountChange(itemExist ? 0 : maxAmount, item['sku'])}
                              width="full"
                            >
                              <FormattedMessage
                                id={itemExist ? 'Packages.Modal.RemoveItem' : 'Packages.Modal.AddItem'}
                                values={{
                                  maxAmount,
                                }}
                              />
                            </Button>
                          )}
                        </>
                      ) : head.tableKey === 'maxAmount' ? (
                        <Tooltip
                          action={['hover']}
                          content={
                            stockMissing && (
                              <FormattedMessage
                                id="Packages.Modal.MissingStockCount"
                                values={{
                                  amountInOrder: item.amountInOrder,
                                  missingAmount: item.amountInOrder - item.availableStock,
                                }}
                              />
                            )
                          }
                        >
                          <Flex
                            display="inline-flex"
                            alignItems="center"
                            color={stockMissing ? 'palette.white' : 'text.body'}
                            bg={stockMissing ? 'palette.red_darker' : 'transparent'}
                            fontWeight={stockMissing ? 800 : 500}
                            borderRadius="2000px"
                            px="11px"
                          >
                            {maxAmount} {stockMissing && <Icon fontSize={11} name="fas fa-exclamation" ml="11px" />}
                          </Flex>
                        </Tooltip>
                      ) : (
                        item[head.tableKey]
                      )}
                    </Box>
                  );
                })}
              </PseudoBox>
            );
          })}
        </ModalContent>
        <ModalFooter>
          <Box display="flex" justifyContent="center" m="0 auto">
            <Button onClick={onClose} mr="11px" variant="danger" size="small" minWidth="120px">
              {intl.messages[`Modal.Warning.Cancel`]}
            </Button>
            <Button
              disabled={requestInfo?.isBusy || selectedItems.length < 1}
              isLoading={requestInfo?.isBusy}
              onClick={() =>
                setDialogSettings({
                  show: true,
                  type: DialogTypes.Confirmation,
                  message: intl.messages[`Packages.Dialog.Message`],
                })
              }
              size="small"
              minWidth="200px"
            >
              {intl.messages[`${INTL_KEY}.Create`]}
            </Button>
          </Box>
        </ModalFooter>
      </Modal>
      <Dialog
        type={dialogSettings?.type || DialogTypes.Confirmation}
        message={dialogSettings?.message}
        text={{
          approve: intl.messages[`Modal.${dialogSettings?.type}.Okay`],
          cancel: intl.messages[`Modal.${dialogSettings?.type}.Cancel`],
        }}
        onApprove={() => {
          handleSubmit();
          setDialogSettings({ show: false });
        }}
        onCancel={() => setDialogSettings({ show: false })}
        isOpen={dialogSettings?.show || false}
      />
    </>
  );
};

export default injectIntl(NewPackageModal);

import { CargoType, TransferInfoForm, TransferType } from '@components/molecules/TransferInfoForm';
import { WarehouseCartGrid } from '@components/organisms/WarehouseCartGrid';
import {
  Box,
  Button,
  Dialog,
  DialogTypes,
  DrawerContent,
  DrawerTitle,
  Flex,
  formatLocalToUtc,
  formatUtcToLocal,
  DATE_FORMAT,
  Icon,
  ModalOverlay,
  DATE_FORMAT_NO_TIME,
  FormValidator,
} from '@oplog/express';
import {
  CreatedReceivingOrderOutputDTO,
  CreateReceivingOrderCommand,
  CreateReceivingOrderLineItemInputDTO,
  ReceivingOrderTransferType,
} from '@services/swagger';
import useSendToWarehouseState, { getSendToWarehouseCartGridInfo } from '@store/sendToWarehouse/SendToWarehouseState';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { FormattedMessage } from 'react-intl';
import { tracking } from '../../../utils/analytics';
import { Props } from '../../atoms/Component/Component';
import useForceUpdate from 'use-force-update';
import * as Yup from 'yup';

const COMPONENT_INTL_KEY = 'WarehouseContextualPanel';

export enum StepType {
  Grid = 0,
  Form = 1,
}

export enum DialogType {
  NoDialog = 0,
  Warning = 1,
  Confirmation = 2,
  CompleteOrder = 3,
}

const COMPONENT_ID_KEY = 'warehousecontextualpanel';

export interface WarehouseContextualPanelProps extends Props {
  createdReceivingOrderData?: CreatedReceivingOrderOutputDTO;
  warehouseCartData?: CreateReceivingOrderLineItemInputDTO[];
  onSendWarehouseCartData: (receivingOrders: CreateReceivingOrderCommand) => void;
  onPostError: (error: ErrorModel) => void;
  redirectOnReceivingOrderCreateSuccess: (referenceNumber: string) => void;
  createReceivingOrderError: any;
  validator: FormValidator;
}

export const WarehouseContextualPanel: React.FC<WarehouseContextualPanelProps> = ({
  createReceivingOrderError,
  onPostError,
  createdReceivingOrderData,
  intl,
  isBusy,
  onSendWarehouseCartData,
  redirectOnReceivingOrderCreateSuccess,
  onWillMount,
  onWillUnmount,
  validator
}) => {
  const [state, actions] = useSendToWarehouseState();
  const forceUpdate = useForceUpdate();
  const [step, setStep] = useState<StepType>(StepType.Grid);
  const [showDialog, setShowDialog] = useState<DialogType>(DialogType.NoDialog);
  const [selectedCargoOption, setSelectedCargoOption] = useState<CargoType>(CargoType.Cargo);
  const [selectedTransferOption, setSelectedTransferOption] = useState<TransferType>(TransferType.Cargo);
  const [cargoFormCompany, setCargoFormCompany] = useState<any>({
    cargo: undefined,
    carriage: undefined,
  });

  const [cargoFormNumber, setCargoFormNumber] = useState<any>({
    cargo: undefined,
    carriage: undefined,
  });

  const [selectedDate, setSelectedDate] = useState<any>({
    cargo: undefined,
    carriage: undefined,
    pickup: undefined,
  });

  const [referenceNumber, setReferenceNumber] = useState<string>('');

  const [cargoFormPickupAddress, setCargoFormPickupAddress] = useState<any>(undefined);

  useEffect(() => {
    return () => {
      onWillUnmount && onWillUnmount();
      actions.clearData();
    };
  }, []);

  useEffect(() => {
    if (createReceivingOrderError) {
      onPostError(createReceivingOrderError);
      setShowDialog(DialogType.NoDialog);
    }
  }, [createReceivingOrderError]);

  useEffect(() => {
    if (createdReceivingOrderData && !createReceivingOrderError) {
      setShowDialog(DialogType.CompleteOrder);
    }
  }, [createdReceivingOrderData]);

  useEffect(() => {
    onWillMount && onWillMount();
    validator?.registerSchema(transferReceivingOrdersFormModalSchema());

    return () => {
      onWillUnmount && onWillUnmount();
      validator?.clearErrors();
    };
  }, []);

  const setStatesToInitalValues = () => {
    setSelectedCargoOption(CargoType.Cargo);
    setSelectedTransferOption(TransferType.Cargo);
    setSelectedDate({
      cargo: undefined,
      carriage: undefined,
      pickup: undefined,
    });
    setCargoFormCompany({
      cargo: undefined,
      carriage: undefined,
    });
    setCargoFormNumber({
      cargo: undefined,
      carriage: undefined,
    });
    setCargoFormPickupAddress(undefined);
    setReferenceNumber('');
  };

  const getInfoMessage = () => {
    if (selectedTransferOption) {
      return;
    }
    return (
      <Box px="22" pb="11" fontSize="12" color="text.inputPlaceholder" data-cy={`${COMPONENT_ID_KEY}-box-infoBox`}>
        <Icon name="fal fa-info-circle" mr="4px" />
        {intl.messages[`${COMPONENT_INTL_KEY}.InfoMessage`]}
      </Box>
    );
  };

  const transferReceivingOrdersFormModalSchema = () => {
    return {
      shipmentDate: Yup.date().required(`${intl.messages[`${COMPONENT_INTL_KEY}.Form.Errors.DateOfShipmentInfo`]}`),
    }
  }

  const handleFormButtonsBackClick = () => {
    setStep(StepType.Grid);
    tracking.emitEvent('Warehouse', 'ReturnGridPanel');
  };

  const handleFormButtonsCompleteClick = () => {
    setShowDialog(DialogType.Confirmation);
    tracking.emitEvent('Warehouse', 'RequestCompletion');
  };

  const renderFormButtons = () => {
    return (
      <>
        {getInfoMessage()}
        <Flex px="22px">
          <Button
            width={1 / 3}
            kind="outline"
            variant="dark"
            mr="11"
            onClick={handleFormButtonsBackClick}
            disabled={isBusy}
            data-cy={`${COMPONENT_ID_KEY}-button-back`}
          >
            {intl.messages[`${COMPONENT_INTL_KEY}.Buttons.Back`]}
          </Button>

          <Button
            width={2 / 3}
            type="submit"
            disabled={isBusy || validator.hasErrors() || !isEmpty()}
            addons={{ right: <Icon name="fas fa-angle-right" /> }}
            onClick={handleFormButtonsCompleteClick}
            isLoading={isBusy}
            data-cy={`${COMPONENT_ID_KEY}-button-completeTransfer`}
          >
            {intl.messages[`${COMPONENT_INTL_KEY}.Buttons.CompleteTransfer`]}
          </Button>
        </Flex>
      </>
    );
  };

  const isEmpty = () => {
    return Object.values(selectedDate).some(prop => prop !== undefined);
  };

  const handleOnBlur = (key: string) => {
    if (selectedTransferOption === TransferType.Pickup) {
      validator.validate(key, selectedDate.pickup)
    }
    else {
      if (selectedCargoOption === CargoType.Cargo) {
        validator.validate(key, selectedDate.cargo)
      }
      else {
        validator.validate(key, selectedDate.carriage)
      }
    }
    forceUpdate();
  };

  const handleGridButtonsEmptyClick = () => {
    setShowDialog(DialogType.Warning);
  };

  const handleGridButtonsNextClick = () => {
    setStep(StepType.Form);
    tracking.emitEvent('Warehouse', 'EnterFormPanel');
  };

  const handleCompanyNameChange = (e: React.SyntheticEvent<HTMLInputElement>) => {
    const companyName = e.currentTarget.value.length > 0 ? e.currentTarget.value : undefined;

    if (selectedCargoOption === CargoType.Cargo) {
      setCargoFormCompany({
        cargo: companyName,
        carriage: cargoFormCompany.carriage,
      });
    } else {
      setCargoFormCompany({
        cargo: cargoFormCompany.cargo,
        carriage: companyName,
      });
    }
  };

  const handleReferenceNumberChange = (e: React.SyntheticEvent<HTMLInputElement>) => {
    setReferenceNumber(e.currentTarget.value);
  };
  const handleWaybillOrTrackingNumberChange = (e: React.SyntheticEvent<HTMLInputElement>) => {
    const formNumber = e.currentTarget.value.length > 0 ? e.currentTarget.value : undefined;

    if (selectedCargoOption === CargoType.Cargo) {
      setCargoFormNumber({
        cargo: formNumber,
        carriage: cargoFormNumber.carriage,
      });
    } else {
      setCargoFormNumber({
        cargo: cargoFormNumber.cargo,
        carriage: formNumber,
      });
    }
  };

  const handleDateTimeChange = (date: Date) => {
    if (!selectedTransferOption) {
      return;
    }

    if (selectedTransferOption === TransferType.Pickup) {
      setSelectedDate({
        pickup: date,
        cargo: selectedDate.cargo,
        carriage: selectedDate.carriage,
      });
    } else {
      if (selectedCargoOption === CargoType.Cargo) {
        setSelectedDate({
          cargo: date,
          pickup: selectedDate.pickup,
          carriage: selectedDate.carriage,
        });
      } else {
        // CargoType.SelfShipment
        setSelectedDate({
          carriage: date,
          cargo: selectedDate.cargo,
          pickup: selectedDate.pickup,
        });
      }
      validator?.validate("shipmentDate", date)
    }
  };

  const handlePickupAddressChange = (event: React.SyntheticEvent<HTMLInputElement>) => {
    setCargoFormPickupAddress(event.currentTarget.value);
  };

  const handleRadioChange = (e: React.SyntheticEvent<any> | any) => {
    setSelectedCargoOption(e.currentTarget.value);
    if (e.currentTarget.value == CargoType.Cargo) {
      if (selectedDate.cargo == undefined || selectedDate.cargo == null) {
        selectedDate.cargo = selectedDate.carriage;
      }
    }
    else if (e.currentTarget.value == CargoType.SelfShipment) {
      if (selectedDate.carriage == undefined || selectedDate.carriage == null) {
        selectedDate.carriage = selectedDate.cargo;
      }
    }
  };

  const handleWarningDialogCancelClick = () => {
    setShowDialog(DialogType.NoDialog);
  };

  const handleWarningDialogAcceptClick = () => {
    setShowDialog(DialogType.NoDialog);
    actions.clearData();
  };

  const handleConfirmationDialogCancelClick = () => {
    setShowDialog(DialogType.NoDialog);
    tracking.emitEvent('Warehouse', 'CancelCompletion');
  };

  const prepareReceivingOrderLineItemsData = () => {
    const receivingOrderLineItems: Array<CreateReceivingOrderLineItemInputDTO> = [];
    if (state.panelData) {
      state.panelData.forEach(lineItem => {
        receivingOrderLineItems.push({
          expectedQuantity: lineItem.expectedQuantity,
          sku: lineItem.sku,
        });
      });

      return receivingOrderLineItems;
    }

    return [];
  };

  const handleConfirmationDialogAcceptClick = () => {
    setShowDialog(DialogType.NoDialog);

    let receivingOrder: CreateReceivingOrderCommand | undefined;
    if (selectedTransferOption === TransferType.Pickup) {
      const pickupSelectedDate = selectedDate.pickup ? new Date(formatLocalToUtc(selectedDate.pickup)) : undefined;
      receivingOrder = {
        transferType: ReceivingOrderTransferType.PickupTransfer,
        pickupTransfer: {
          pickupDate: pickupSelectedDate,
          pickupAddress: cargoFormPickupAddress,
        },
        lineItems: prepareReceivingOrderLineItemsData(),
        referenceNumber: referenceNumber,
      };
    } else {
      if (selectedCargoOption === CargoType.Cargo) {
        // TODO in this case we have to send datetime as local time, JSON.Stringify changes local time to UTC time automatically, this is why we have to send date like this
        const cargoSelectedDate = selectedDate.cargo
          ? new Date(selectedDate.cargo.getTime() - selectedDate.cargo.getTimezoneOffset() * 60000)
          : undefined;
        receivingOrder = {
          transferType: ReceivingOrderTransferType.CargoTransfer,
          cargoTransfer: {
            cargoCompanyName: cargoFormCompany.cargo,
            shipmentDate: cargoSelectedDate,
            trackingNumber: cargoFormNumber.cargo,
          },
          lineItems: prepareReceivingOrderLineItemsData(),
          referenceNumber: referenceNumber,
        };
      }

      if (selectedCargoOption === CargoType.SelfShipment) {
        // TODO in this case we have to send datetime as local time, JSON.Stringify changes local time to UTC time automatically, this is why we have to send date like this
        const carriageoSelectedDate = selectedDate.carriage
          ? new Date(selectedDate.carriage.getTime() - selectedDate.carriage.getTimezoneOffset() * 60000)
          : undefined;
        receivingOrder = {
          transferType: ReceivingOrderTransferType.CarriageTransfer,
          carriageTransfer: {
            carriageCompanyName: cargoFormCompany.carriage,
            shipmentDate: carriageoSelectedDate,
            waybillNumber: cargoFormNumber.carriage,
          },
          lineItems: prepareReceivingOrderLineItemsData(),
          referenceNumber: referenceNumber,
        };
      }
    }

    if (receivingOrder) {
      onSendWarehouseCartData(receivingOrder);
      tracking.emitEvent('Warehouse', 'CompletionSuccess'); // Should we base this on the following step, making sure post goes through
      // When error happens on post, do not initialize state.
      if (createReceivingOrderError) {
        setStatesToInitalValues();
      }
    }
  };

  const renderConfirmationDialog = () => {
    const { numOfTypes, numOfProducts } = getSendToWarehouseCartGridInfo(state.panelData);

    return (
      <Dialog
        type={DialogTypes.Confirmation}
        text={{
          approve: intl.messages[`Modal.Confirmation.Okay`],
          cancel: intl.messages[`Modal.Confirmation.Cancel`],
        }}
        icon="fas fa-truck-moving"
        message={
          <FormattedMessage
            id={`${COMPONENT_INTL_KEY}.ConfirmationDialog.Message`}
            values={{
              numOfTypes: <b>{numOfTypes}</b>,
              numOfProducts: <b>{numOfProducts}</b>,
            }}
          />
        }
        isOpen={showDialog === DialogType.Confirmation}
        onApprove={handleConfirmationDialogAcceptClick}
        onCancel={handleConfirmationDialogCancelClick}
        data-cy={`${COMPONENT_ID_KEY}-dialog-confirm`}
      />
    );
  };

  const handleCompleteOrderAcceptClick = () => {
    setStep(StepType.Grid);
    setShowDialog(DialogType.NoDialog);

    // Delete warehouse orders when steps are complete.
    actions.clearData();

    // Here redirect to receiving order details page
    if (createdReceivingOrderData) {
      redirectOnReceivingOrderCreateSuccess(createdReceivingOrderData.referenceNumber);
    }
  };

  const renderCompleteOrderDialog = () => {
    return (
      <Dialog
        type={DialogTypes.Success}
        message={
          <FormattedMessage
            id={`${COMPONENT_INTL_KEY}.CompleteOrderDialog.Message`}
            values={{
              address: <b>{intl.messages[`${COMPONENT_INTL_KEY}.Address`]}</b>,
            }}
          />
        }
        isOpen={showDialog === DialogType.CompleteOrder}
        onApprove={handleCompleteOrderAcceptClick}
        text={{ approve: intl.messages[`Modal.Success.Okay`] }}
        data-cy={`${COMPONENT_ID_KEY}-dialog-complete`}
      />
    );
  };

  const getSelectedDateAccordingToType = () => {
    if (selectedTransferOption === TransferType.Pickup) {
      return selectedDate.pickup;
    }
    if (selectedCargoOption === CargoType.Cargo) {
      return selectedDate.cargo;
    }
    return selectedDate.carriage;
  };

  const getCompanyNameAccordingToType = () => {
    if (selectedCargoOption === CargoType.Cargo) {
      return cargoFormCompany.cargo;
    }
    return cargoFormCompany.carriage;
  };

  const getFormNumberAccordingToType = () => {
    if (selectedCargoOption === CargoType.Cargo) {
      return cargoFormNumber.cargo;
    }
    return cargoFormNumber.carriage;
  };

  const warehouseCartLineItemNumber: number = state.panelData ? state.panelData.length : 0;

  const renderSteps = () => {
    const { numOfTypes, numOfProducts } = getSendToWarehouseCartGridInfo(state.panelData);
    if (step === StepType.Grid) {
      return (
        <Box position="relative">
          <WarehouseCartGrid intl={intl} intlKey={COMPONENT_INTL_KEY} />
          <Box
            p="11px 22px"
            color="palette.grey"
            fontSize="12"
            boxShadow="0 -6px 10px 0 rgba(199, 199, 199, 0.1), 0 6px 10px 0 rgba(199, 199, 199, 0.1)"
          >
            <FormattedMessage
              id={`${COMPONENT_INTL_KEY}.GridFooter`}
              values={{
                numOfTypes: (
                  <Box as="strong" color="palette.black">
                    {numOfTypes}
                  </Box>
                ),
                numOfProducts: (
                  <Box as="strong" color="palette.black">
                    {numOfProducts}
                  </Box>
                ),
              }}
            />
          </Box>
          <Flex p="22px">
            {warehouseCartLineItemNumber > 0 && (
              <Button width={1 / 3} kind="outline" variant="dark" mr="11" onClick={handleGridButtonsEmptyClick}>
                {intl.messages[`${COMPONENT_INTL_KEY}.Buttons.Empty`]}
              </Button>
            )}

            <Button
              width={2 / 3}
              type="submit"
              disabled={warehouseCartLineItemNumber === 0}
              addons={{ right: <Icon name="fas fa-angle-right" /> }}
              onClick={handleGridButtonsNextClick}
              data-cy={`${COMPONENT_ID_KEY}-button-next`}
            >
              {intl.messages[`${COMPONENT_INTL_KEY}.Buttons.Next`]}
            </Button>
          </Flex>
          <Dialog
            type={DialogTypes.Warning}
            text={{ cancel: intl.messages[`Modal.Warning.Cancel`], approve: intl.messages[`Modal.Warning.Okay`] }}
            message={intl.messages[`${COMPONENT_INTL_KEY}.WarningDialog.Message`]}
            isOpen={showDialog === DialogType.Warning}
            onApprove={handleWarningDialogAcceptClick}
            onCancel={handleWarningDialogCancelClick}
            data-cy={`${COMPONENT_ID_KEY}-dialog-warning`}
          />
        </Box>
      );
    }

    return (
      <>
        <DrawerTitle
          boxShadow="0 6px 10px 0 rgba(199, 199, 199, 0.1), 0 -6px 10px 0 rgba(199, 199, 199, 0.1)"
          size="medium"
        >
          {intl.messages[`${COMPONENT_INTL_KEY}.Header.EnterTransferInfo`]}
        </DrawerTitle>
        <form onSubmit={(e: React.SyntheticEvent<HTMLFormElement>) => e.preventDefault()}>
          {createPortal(<ModalOverlay opacity={0.2} zIndex={3999} />, document.querySelector('body') as any)}

          <DrawerContent px="22" pt="22" pb="4px">
            <TransferInfoForm
              intl={intl}
              intlKey={COMPONENT_INTL_KEY}
              selectedCargoOption={selectedCargoOption}
              selectedTransferOption={TransferType.Cargo}
              referenceNumber={referenceNumber}
              selectedDate={getSelectedDateAccordingToType()}
              cargoFormCompany={getCompanyNameAccordingToType()}
              cargoFormNumber={getFormNumberAccordingToType()}
              cargoFormPickupAddress={cargoFormPickupAddress}
              onCargoFormRadioChange={handleRadioChange}
              onCompanyNameChange={handleCompanyNameChange}
              onWaybillOrTrackingNumberChange={handleWaybillOrTrackingNumberChange}
              onDateTimeChange={handleDateTimeChange}
              onReferenceNumberChange={handleReferenceNumberChange}
              onPickupAddressChange={handlePickupAddressChange}
              isBusy={isBusy}
              validator={validator}
              handleOnBlur={handleOnBlur}
            />
          </DrawerContent>
          {renderFormButtons()}
        </form>
        {renderConfirmationDialog()}
        {renderCompleteOrderDialog()}
      </>
    );
  };

  return renderSteps();
};

import { GridContainer } from '@components/atoms/GridContainer';
import { ExcelExportModal, ExportTypes } from '@components/molecules/ExcelExportModal';
import {
  bubbleStepFormatter,
  geti18nName,
  ReceivingOrderDetailsLinkFormatter,
  sourceIconFormatter,
} from '@containers/formatters';
import { GridProps, GridType, ResourceType } from '@models';
import {
  ColumnSize,
  DataGridColumn,
  DataGridRow,
  dateTimeFormatter,
  enumFormatter,
  FormatterProps,
  getEnumOptions,
  PredefinedFilter,
} from '@oplog/data-grid';
import { Box } from '@oplog/express';
import {
  ReceivingOrderArrivalState,
  ReceivingOrderDetailOutputDTO,
  ReceivingOrderState,
  ReceivingOrderTransferType,
} from '@services';
import {
  coloredBadgeFormatter,
  EXCEL_EXPORT_LIMIT,
  EXCEL_EXPORT_RECEIVINGORDER_LIMIT,
  generateIntegrationEnum,
  receivingOrderDifferenceBadge,
} from '@utils';
import { SortDirection, SortField, StringFilter, StringFilterOperation } from 'dynamic-query-builder-client';
import * as React from 'react';

const intlKey = 'ReceivingOrdersGrid';
const gridKey = 'ReceivingOrders.Grid';

export const receivingOrdersGridPredefinedFilters: Array<PredefinedFilter> = [
  {
    filter: new StringFilter({
      id: 'IncompleteOrders',
      property: 'state',
      op: StringFilterOperation.NotEqual,
      value: `${ReceivingOrderState.Completed}`,
    }),
    selected: false,
  },
];

const receivingOrdersGridInitalSort: SortField = new SortField({
  property: 'createdAt',
  by: SortDirection.DESC,
});

interface ReceivingOrdersGridProps extends GridProps {
  integrations: any;
  excelToExport: (resourceType: ResourceType) => void;
  excelExportResource: any;
  excelToExportInit: () => void;
  excelExportDetailedResource: any;
  gridFilters: any;
}

export const ReceivingOrdersGrid: React.FC<ReceivingOrdersGridProps> = ({
  intl,
  titleKey,
  integrations,
  excelToExport,
  excelToExportInit,
  excelExportResource,
  excelExportDetailedResource,
  gridFilters,
}) => {
  const [isExcelExportModalOpen, setIsExcelExportModalOpen] = React.useState(false);
  const [rowCount, setRowCount] = React.useState(0);

  React.useEffect(() => {
    setRowCount(gridFilters.footer.rowCount);
  }, [gridFilters.footer]);

  const receivingOrdersGridColumns: Array<DataGridColumn> = [
    {
      name: '#',
      key: 'index',
      resizable: true,
      locked: true,
      sortable: false,
      type: 'number',
      visibility: true,
      filterable: false,
      width: ColumnSize.Medium,
      cellClass: 'index-column',
    },
    {
      name: geti18nName('integrationType', intl, intlKey),
      key: 'integrationName',
      locked: true,
      sortable: false,
      type: 'enum',
      visibility: true,
      filterable: true,
      resizable: true,
      width: ColumnSize.Large,
      formatter: row => sourceIconFormatter(row.value, row.dependentValues, intl),
      getRowMetaData: (row: DataGridRow) => row,
      options: integrations ? generateIntegrationEnum(integrations) : [],
    },
    {
      name: geti18nName('referenceNumber', intl, intlKey),
      key: 'referenceNumber',
      resizable: true,
      locked: true,
      sortable: true,
      type: 'string',
      visibility: true,
      filterable: true,
      formatter: ReceivingOrderDetailsLinkFormatter,
      getRowMetaData: (row: DataGridRow) => row,
    },
    {
      name: geti18nName('state', intl, intlKey),
      key: 'state',
      resizable: true,
      locked: true,
      sortable: false,
      type: 'enum',
      visibility: true,
      filterable: true,
      formatter: bubbleStepFormatter,
      getRowMetaData: (row: DataGridRow) => {
        return {
          intl,
          enum: ReceivingOrderState,
          row,
        };
      },
      options: getEnumOptions(intl, ReceivingOrderState),
    },
    {
      name: geti18nName('arrivalState', intl, intlKey),
      key: 'arrivalState',
      resizable: true,
      locked: true,
      sortable: false,
      type: 'enum',
      visibility: true,
      filterable: true,
      formatter: enumFormatter,
      options: getEnumOptions(intl, ReceivingOrderArrivalState),
      getRowMetaData: () => {
        return intl;
      },
    },
    {
      name: geti18nName('transferType', intl, intlKey),
      key: 'transferType',
      resizable: true,
      locked: true,
      sortable: false,
      type: 'enum',
      visibility: true,
      filterable: true,
      formatter: enumFormatter,
      options: getEnumOptions(intl, ReceivingOrderTransferType),
      getRowMetaData: () => {
        return intl;
      },
    },
    {
      name: geti18nName('productTypeCount', intl, intlKey),
      key: 'productTypeCount',
      resizable: true,
      locked: true,
      sortable: false,
      type: 'number',
      visibility: true,
      filterable: true,
    },
    {
      name: geti18nName('productCount', intl, intlKey),
      key: 'productCount',
      locked: true,
      sortable: true,
      resizable: true,
      type: 'number',
      visibility: true,
      filterable: true,
    },
    {
      name: geti18nName('acceptedQuantity', intl, intlKey),
      key: 'acceptedQuantity',
      locked: true,
      sortable: false,
      resizable: true,
      type: 'number',
      visibility: true,
      filterable: true,
    },
    {
      name: geti18nName('acceptedQuantityDifference', intl, intlKey),
      key: 'acceptedQuantityDifference',
      locked: true,
      resizable: true,
      sortable: true,
      type: 'string',
      visibility: true,
      filterable: true,
      formatter: (props: FormatterProps) => coloredBadgeFormatter(props),
      width: ColumnSize.XLarge,
      getRowMetaData: (row: ReceivingOrderDetailOutputDTO) => {
        const { text, badgeColor } = receivingOrderDifferenceBadge(intl, gridKey, row);
        return {
          row,
          intl,
          intlKey,
          text,
          color: badgeColor,
        };
      },
    },
    {
      name: geti18nName('createdAt', intl, intlKey),
      key: 'createdAt',
      resizable: true,
      sortable: true,
      type: 'moment',
      visibility: true,
      filterable: true,
      formatter: dateTimeFormatter,
    },
    {
      name: geti18nName('completedAt', intl, intlKey),
      key: 'completedAt',
      resizable: true,
      sortable: true,
      type: 'moment',
      visibility: true,
      filterable: true,
      formatter: dateTimeFormatter,
    },
  ];

  const toggleExcelExportModal = () => {
    setIsExcelExportModalOpen(true);
  };

  const onModalClose = () => {
    setIsExcelExportModalOpen(false);
  };

  return (
    <Box>
      <GridContainer
        titleKey={
          titleKey
            ? `${intl.messages['ReceivingOrders.Grid.Title']} - ${titleKey}`
            : intl.messages['ReceivingOrders.Grid.Title']
        }
        intlKey={intlKey}
        remoteExportToExcel
        gridKey={GridType.ReceivingOrders}
        columns={receivingOrdersGridColumns}
        onExcelExportButtonClick={toggleExcelExportModal}
        predefinedFilters={receivingOrdersGridPredefinedFilters}
        intl={intl}
        sortField={receivingOrdersGridInitalSort}
      />
      {isExcelExportModalOpen && (
        <ExcelExportModal
          excelToExport={excelToExport}
          intl={intl}
          onModalClose={onModalClose}
          excelExportResource={excelExportResource}
          excelExportDetailedResource={excelExportDetailedResource}
          excelToExportInit={excelToExportInit}
          excelExportType={ExportTypes.ReceivingOrders}
          rowCount={rowCount}
          defaultExportLimit={EXCEL_EXPORT_LIMIT}
          detailedExportLimit={EXCEL_EXPORT_RECEIVINGORDER_LIMIT}
        />
      )}
    </Box>
  );
};

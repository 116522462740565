import { GridContainer as OPLOGGridContainer } from '@oplog/data-grid';
import React from 'react';
import { withRouter } from 'react-router-dom';
import { tracking } from '../../utils/analytics';

const AGridContainer: React.FC<any> = props => {
  return (
    <OPLOGGridContainer
      {...props}
      eventCallbacks={{
        onActionsClick: data => {
          tracking.emitGridActionClick(data);
        },
        onFilterButtonClick: () => {
          tracking.emitFilterOpen();
        },
        onFilterAddClick: data => {
          tracking.emitFilterAdd(data);
        },
        onFilterSendClick: data => {
          tracking.emitFilterSend(data);
        },
      }}
    />
  );
};

export const GridContainer = withRouter(AGridContainer);

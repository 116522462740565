import { GridProps } from '@models';
import {
    Box,
    Button,
    Flex,
    Text,
    Modal,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalTitle,
} from '@oplog/express';
import React, { useEffect, useState } from 'react';
import { CleanBlockedSalesOrdersPreviewGrid } from '@components/organisms/CleanBlockedSalesOrdersPreviewGrid';
import { CleanBlockedSalesOrdersApprovalModal } from '../CleanBlockedSalesOrdersApprovalModal';
import { BulkCancelLineItemsInput } from '@services';

import { StockQuantityDetailsExtendedDataGridRow } from '@utils';
import Skeleton from 'react-loading-skeleton';

interface CleanBlockedSalesOrdersPreviewModalProps extends GridProps {
    onModalClose: () => void;
    selectedReferenceNumbers: string[];
    onApproveCleanBlockedClick: ({ referenceNumbers }: { referenceNumbers: string[]; }) => void;
    cleanBlockedGridResource: any;
    stockQuantityDetailsUsedInBlockedOrders: any;
    isBusyGetStockQuantityDetailsUsedInBlockedSalesOrders: boolean;
    isErrorGetStockQuantityDetailsUsedInBlockedSalesOrders: boolean;
    bulkCreatePackageResource: any;
    bulkCancelLineItemsResource: any;
    bulkCancelLineItemsCommand: (cancelLineItems: BulkCancelLineItemsInput[]) => void;
}

export const CleanBlockedSalesOrdersPreviewModal = ({
    intl,
    onModalClose,
    selectedReferenceNumbers,
    onApproveCleanBlockedClick,
    cleanBlockedGridResource,
    stockQuantityDetailsUsedInBlockedOrders,
    isBusyGetStockQuantityDetailsUsedInBlockedSalesOrders,
    isErrorGetStockQuantityDetailsUsedInBlockedSalesOrders,
    bulkCreatePackageResource,
    bulkCancelLineItemsResource,
    bulkCancelLineItemsCommand,
}: CleanBlockedSalesOrdersPreviewModalProps) => {
    const [isApproveModalOpen, setIsApproveModalOpen] = useState(false);

    const COMPONENT_INTL_KEY = 'CleanBlockedReviewGridModal';

    const toggleApproveModal = () => {
        setIsApproveModalOpen(!isApproveModalOpen);
    }

    const onApproveModalClose = () => {
        setIsApproveModalOpen(false);
        window.location.reload();
    }

    const onButtonClick = () => {
        toggleApproveModal();
    }

    const onApproveBulkCreatePackages = ({ referenceNumbers }: { referenceNumbers: string[] }) => {
        onApproveCleanBlockedClick({ referenceNumbers: referenceNumbers });
    }

    const boxStyle = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        border: '1px solid #e0e0e0',
        padding: '20px',
        borderRadius: '5px',
        shadow: '0 0 10px rgba(0, 0, 0, 0.1)',
    };

    return (
        <Modal
            onClose={() => {
                onModalClose();
            }}
            isOpen={true}
            size={"6xl"}
            disableOutsideMouseEvents={true}
            disableEscapeButtonClose={true}
            disablePortal
        >
            {isApproveModalOpen && stockQuantityDetailsUsedInBlockedOrders && (
                <CleanBlockedSalesOrdersApprovalModal
                    intl={intl}
                    onModalClose={onApproveModalClose}
                    selectedReferenceNumbers={selectedReferenceNumbers}
                    onApproveCleanBlockedClick={onApproveBulkCreatePackages}
                    cleanBlockedGridResource={cleanBlockedGridResource}
                    bulkcreatePackageResource={bulkCreatePackageResource}
                    bulkCancelLineItemsResource={bulkCancelLineItemsResource}
                    bulkCancelLineItemsCommand={bulkCancelLineItemsCommand}
                    stockQuantityDetailsUsedInBlockedOrders={stockQuantityDetailsUsedInBlockedOrders.data as StockQuantityDetailsExtendedDataGridRow[]}
                />
            )}
            <ModalHeader>
                <ModalTitle fontSize="24">{intl.messages[`${COMPONENT_INTL_KEY}.Title`]}</ModalTitle>
            </ModalHeader>
            <ModalContent>
                {isErrorGetStockQuantityDetailsUsedInBlockedSalesOrders ? (
                    <Flex justifyContent="center" alignItems="center" height="100vh">
                        <Box {...boxStyle}>
                            <Text fontSize="18" color="palette.grey_default" fontWeight='bold' >{intl.messages[`${COMPONENT_INTL_KEY}.Error`]}</Text>
                            <Button variant='danger' mt='10px' onClick={() => window.location.reload()}>
                                {intl.messages[`${COMPONENT_INTL_KEY}.ReloadButton`]}
                            </Button>
                        </Box>
                    </Flex>
                ) : (isBusyGetStockQuantityDetailsUsedInBlockedSalesOrders || stockQuantityDetailsUsedInBlockedOrders === undefined) ? (
                    <Flex justifyContent="center" alignItems="center" height="100vh">
                        <Box width="100%" height="100%">
                            <Skeleton count={20} height="20px" />
                        </Box>
                    </Flex>
                ) : (
                    <CleanBlockedSalesOrdersPreviewGrid intl={intl} data={stockQuantityDetailsUsedInBlockedOrders.data as StockQuantityDetailsExtendedDataGridRow[]} />
                )}
            </ModalContent>
            <ModalFooter>
                <Flex justifyContent="end">
                    <Button
                        type="button"
                        onClick={() => onButtonClick()}
                        isLoading={isBusyGetStockQuantityDetailsUsedInBlockedSalesOrders}
                        kind="solid"
                        variant="success"
                        width={1 / 3}
                        size="small"
                        disabled={cleanBlockedGridResource && cleanBlockedGridResource.isBusy}
                    >
                        {intl.messages[`${COMPONENT_INTL_KEY}.Approve`]}
                    </Button>
                </Flex>
            </ModalFooter>
        </Modal>
    );
};

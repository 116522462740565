import { GridContainer } from '@components/atoms/GridContainer';
import { barcodeFormatter, geti18nName, i18nTextFormatter, sourceIconFormatter } from '@containers/formatters';
import { GridProps, GridType } from '@models';
import { ColumnSize, DataGridColumn, DataGridRow, dateTimeFormatter, FormatterProps } from '@oplog/data-grid';
import { generateIntegrationEnum } from '@utils';
import { ArrayFilterOperation, SortDirection, SortField } from 'dynamic-query-builder-client';
import * as React from 'react';

const intlKey = 'ProductCatalogGrid';

const productGridInitalSort: SortField = new SortField({
  property: 'createdAt',
  by: SortDirection.DESC,
});

interface ProductQuarantineGridProps extends GridProps {
  integrations: any;
}

export const ProductQuarantineGrid: React.FC<ProductQuarantineGridProps> = ({ intl, titleKey, integrations }) => {
  const productGridColumns: Array<DataGridColumn> = [
    {
      name: '#',
      key: 'index',
      resizable: true,
      locked: true,
      sortable: false,
      type: 'number',
      visibility: true,
      filterable: false,
      width: ColumnSize.Medium,
      cellClass: 'index-column',
    },
    {
      name: geti18nName('integrationType', intl, intlKey),
      key: 'integrationName',
      locked: true,
      sortable: false,
      type: 'enum',
      visibility: true,
      filterable: true,
      resizable: true,
      width: ColumnSize.Large,
      formatter: row => sourceIconFormatter(row.value, row.dependentValues, intl),
      getRowMetaData: (row: DataGridRow) => row,
      options: integrations ? generateIntegrationEnum(integrations) : [],
    },
    {
      name: geti18nName('sku', intl, intlKey),
      key: 'sku',
      locked: true,
      sortable: true,
      type: 'string',
      visibility: true,
      filterable: true,
      resizable: true,
    },
    {
      name: geti18nName('barcode', intl, intlKey),
      key: 'barcodes',
      locked: true,
      sortable: true,
      type: 'string',
      visibility: true,
      filterable: true,
      resizable: true,
    },
    {
      name: geti18nName('name', intl, intlKey),
      key: 'name',
      locked: true,
      sortable: false,
      type: 'string',
      visibility: true,
      filterable: true,
      resizable: true,
    },
    {
      name: geti18nName('error', intl, intlKey),
      key: 'error',
      locked: true,
      sortable: false,
      type: 'string',
      visibility: true,
      filterable: true,
      resizable: true,
      formatter: (props: FormatterProps) => i18nTextFormatter(props, intl, intlKey),
    },
    {
      name: geti18nName('createdAt', intl, intlKey),
      key: 'createdAt',
      resizable: true,
      sortable: true,
      type: 'moment',
      visibility: true,
      filterable: true,
      formatter: dateTimeFormatter,
    },
  ];

  return (
    <GridContainer
      titleKey={
        titleKey
          ? `${intl.messages['ProductCatalog.Grid.Title']} - ${titleKey}`
          : intl.messages['ProductCatalog.Grid.Title']
      }
      intlKey={intlKey}
      remoteExportToExcel
      gridKey={GridType.ProductQuarantine}
      columns={productGridColumns}
      predefinedFilters={[]}
      intl={intl}
      sortField={productGridInitalSort}
    />
  );
};

import { NumericStep } from '@oplog/express';
import useSendToWarehouseState, { SendToWarehouseStateKeys } from '@store/sendToWarehouse/SendToWarehouseState';
import React, { useEffect, useRef } from 'react';

export interface SendToWarehouseNumericStepProps {
  sku: string;
  dependentValues: any;
}

export const SendToWarehouseNumericStep: React.FC<SendToWarehouseNumericStepProps> = ({ sku, dependentValues }) => {
  const [state, actions] = useSendToWarehouseState();
  const gridRef = useRef(state.gridData);

  useEffect(() => {
    gridRef.current = state.gridData;
  }, [state.gridData]);

  const onAmountChange = (amount: number, row: any, sku: string) => {
    let itemList = [...gridRef.current];
    const itemExist = itemList.findIndex(item => item.sku === sku);
    if (itemExist === -1 && amount !== 0) {
      itemList = [...itemList, { amount, row, sku }];
      actions.setStateData(SendToWarehouseStateKeys.gridData, itemList);
    } else {
      if (amount === 0) {
        itemExist !== -1 && itemList.splice(itemExist, 1);
      } else {
        itemList[itemExist].amount = amount;
      }
      actions.setStateData(SendToWarehouseStateKeys.gridData, itemList);
    }
    const newRows = itemList.map((item: any) => ({
      id: item.row.id,
      productName: item.row.name,
      sku: item.row.sku,
      expectedQuantity: item.amount,
      imageUrl: item.row.imageUrl,
    }));

    actions.setStateData(SendToWarehouseStateKeys.panelData, newRows);
  };

  const findValue = (sku: string) => {
    let value;
    const itemExist = state.panelData.findIndex((data: any) => data.sku === sku);
    if (itemExist !== -1) {
      value = state.panelData[itemExist].expectedQuantity;
    } else {
      value = 0;
    }
    return value;
  };

  const value = findValue(sku);
  return (
    <NumericStep
      value={value === 0 ? '0' : value}
      max={99999} // Değer product manager tarafından verilmiştir.
      onChange={(amount: any) => onAmountChange(amount, dependentValues, sku)}
    />
  );
};

import { GridContainer } from '@components/atoms/GridContainer';
import {
  barcodeFormatter,
  geti18nName,
  ProductDetailsLinkFormatter,
  sourceIconFormatter,
} from '@containers/formatters';
import { GridProps, GridType } from '@models';
import {
  ColumnSize,
  DataGridColumn,
  DataGridRow,
  enumFormatter,
  getEnumOptions,
  ImageFormatter,
  PredefinedFilter,
} from '@oplog/data-grid';
import { ProductState, ProductType } from '@services';
import { generateIntegrationEnum } from '@utils';
import {
  ArrayFilterOperation,
  NumericFilter,
  NumericFilterOperation,
  SortDirection,
  SortField,
} from 'dynamic-query-builder-client';
import * as React from 'react';

const intlKey = 'InventoryGrid';

const invertoryGridInitalSort: SortField = new SortField({
  property: 'sku',
  by: SortDirection.ASC,
});

export const inventoryGridPredefinedFilters: Array<PredefinedFilter> = [
  {
    filter: new NumericFilter({
      id: 'AvailableStock',
      property: 'availableAmount',
      op: NumericFilterOperation.Equals,
      value: 0,
    }),
    selected: false,
  },
];

interface InventoryGridProps extends GridProps {
  integrations: any;
}

export const InventoryGrid: React.FC<InventoryGridProps> = ({ intl, titleKey, integrations }) => {
  const inventoryGridColumns: Array<DataGridColumn> = [
    {
      name: '#',
      key: 'index',
      resizable: true,
      locked: true,
      sortable: false,
      type: 'number',
      visibility: true,
      filterable: false,
      width: ColumnSize.Medium,
      cellClass: 'index-column',
    },
    {
      name: geti18nName('integrationType', intl, intlKey),
      key: 'integrationName',
      locked: true,
      sortable: false,
      type: 'enum',
      visibility: true,
      filterable: true,
      resizable: true,
      width: ColumnSize.Large,
      formatter: row => sourceIconFormatter(row.value, row.dependentValues, intl),
      getRowMetaData: (row: DataGridRow) => row,
      options: integrations ? generateIntegrationEnum(integrations) : [],
    },
    {
      name: geti18nName('sku', intl, intlKey),
      key: 'sku',
      locked: true,
      sortable: true,
      type: 'string',
      visibility: true,
      filterable: true,
      resizable: true,
    },
    {
      name: geti18nName('barcode', intl, intlKey),
      key: 'barcodes',
      locked: true,
      type: 'string',
      visibility: true,
      resizable: true,
      sortable: false,
      filterable: true,
      formatter: barcodeFormatter,
      fieldType: 'array',
      searchField: '_',
      outerOp: ArrayFilterOperation.Any,
    },
    {
      name: geti18nName('imageUrl', intl, intlKey),
      key: 'imageUrl',
      locked: true,
      sortable: false,
      type: 'string',
      visibility: true,
      filterable: false,
      formatter: ImageFormatter,
      width: ColumnSize.Medium,
    },
    {
      name: geti18nName('name', intl, intlKey),
      key: 'name',
      locked: true,
      sortable: false,
      type: 'string',
      visibility: true,
      filterable: true,
      formatter: ProductDetailsLinkFormatter,
      getRowMetaData: (row: DataGridRow) => row,
      resizable: true,
    },
    {
      name: geti18nName('type', intl, intlKey),
      key: 'type',
      resizable: true,
      sortable: false,
      type: 'enum',
      options: getEnumOptions(intl, ProductType),
      visibility: true,
      filterable: true,
      formatter: enumFormatter,
      getRowMetaData: () => {
        return intl;
      },
    },
    {
      name: geti18nName('category', intl, intlKey),
      key: 'category',
      resizable: true,
      sortable: false,
      type: 'string',
      visibility: true,
      filterable: true,
    },
    {
      name: geti18nName('status', intl, intlKey),
      key: 'state',
      resizable: true,
      sortable: false,
      type: 'enum',
      options: getEnumOptions(intl, ProductState),
      visibility: true,
      filterable: true,
      formatter: enumFormatter,
      getRowMetaData: () => {
        return intl;
      },
    },
    {
      name: geti18nName('stockAmount', intl, intlKey),
      key: 'stockAmount',
      resizable: true,
      sortable: true,
      type: 'number',
      visibility: true,
      filterable: true,
    },
    {
      name: geti18nName('availableAmount', intl, intlKey),
      key: 'availableAmount',
      resizable: true,
      sortable: true,
      type: 'number',
      visibility: true,
      filterable: true,
    },
    {
      name: geti18nName('reservedAmount', intl, intlKey),
      key: 'reservedAmount',
      resizable: true,
      sortable: true,
      type: 'number',
      visibility: true,
      filterable: true,
    },
    {
      name: geti18nName('damagedItemAmount', intl, intlKey),
      key: 'damagedItemAmount',
      resizable: true,
      sortable: true,
      type: 'number',
      visibility: true,
      filterable: true,
    },
    {
      name: geti18nName('expiredAmount', intl, intlKey),
      key: 'expiredAmount',
      resizable: true,
      sortable: true,
      type: 'number',
      visibility: true,
      filterable: true,
    },
    {
      name: geti18nName('receivedItemAmount', intl, intlKey),
      key: 'receivedItemAmount',
      resizable: true,
      sortable: true,
      type: 'number',
      visibility: true,
      filterable: true,
    },
  ];
  return (
    <GridContainer
      titleKey={
        titleKey ? `${intl.messages['StockStatus.Grid.Title']} - ${titleKey}` : intl.messages['StockStatus.Grid.Title']
      }
      intlKey={intlKey}
      remoteExportToExcel
      gridKey={GridType.InventoryItems}
      columns={inventoryGridColumns}
      predefinedFilters={inventoryGridPredefinedFilters}
      intl={intl}
      sortField={invertoryGridInitalSort}
    />
  );
};

import { GridType, ResourceType } from '@models';
import { gridActions } from '@oplog/data-grid';
import { GridFetchRequested } from '@oplog/data-grid/dist/store/actions';
import { resourceActions, resourceSelectors } from '@oplog/resource-redux';
import { RouterAction } from 'connected-react-router';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import {
  SupportSubmitReferenceNumbersModal,
  SupportSubmitReferenceNumbersModalIntlKeys,
  SupportSubmitReferenceNumbersModalProps,
} from '../../components/molecules/SupportSubmitReferenceNumbersModal';
import { StoreState } from '../../store/state';

export interface DeleteSalesOrderModalContainerProps {
  onClose: () => void;
}

function mapStateToProps(
  { resources }: StoreState,
  ownProps: DeleteSalesOrderModalContainerProps
): Partial<SupportSubmitReferenceNumbersModalProps> {
  return {
    type: SupportSubmitReferenceNumbersModalIntlKeys.SoftDeleteSalesOrder,
    error: resourceSelectors.getError(resources, ResourceType.BulkSoftDeleteSalesOrders),
    response: resourceSelectors.getData(resources, ResourceType.BulkSoftDeleteSalesOrders),
  };
}

function mapDispatchToProps(
  dispatch: Dispatch<resourceActions.ResourceAction | RouterAction | GridFetchRequested>,
  ownProps: DeleteSalesOrderModalContainerProps
): Partial<SupportSubmitReferenceNumbersModalProps> {
  return {
    submitReferenceNumbers: (referenceNumbersArray: string[]) => {
      const jsonObject = {
        referenceNumbers: referenceNumbersArray,
      };

      dispatch(resourceActions.resourceRequested(ResourceType.BulkSoftDeleteSalesOrders, { jsonObject }));
    },

    onWillUnmount: () => {
      dispatch(resourceActions.resourceInit(ResourceType.BulkSoftDeleteSalesOrders));
      dispatch(gridActions.gridFetchRequested(GridType.SupportSalesOrders));
    },
  };
}

export const SupportSalesOrdersDeleteModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(SupportSubmitReferenceNumbersModal));

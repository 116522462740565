import { WarehouseContextualPanel, WarehouseContextualPanelProps } from '@components';
import { ResourceType } from '@models';
import { validation } from '@oplog/express';
import { resourceActions, resourceSelectors } from '@oplog/resource-redux';
import { urls } from '@routes/urls';
import { CreateReceivingOrderCommand } from '@services';
import { StoreState } from '@store';
import { push, RouterAction } from 'connected-react-router';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

export interface WarehouseContextualPanelContainerProps {
  onPostError: (error: ErrorModel) => void;
}

function mapStateToProps({ resources }: StoreState): Partial<WarehouseContextualPanelProps> {
  return {
    isBusy: resourceSelectors.isBusy(resources, ResourceType.SendReceivingOrders),
    createReceivingOrderError: resourceSelectors.getError(resources, ResourceType.SendReceivingOrders),
    createdReceivingOrderData: resourceSelectors.getData(resources, ResourceType.SendReceivingOrders),
  };
}

function mapDispatchToProps(
  dispatch: Dispatch<resourceActions.ResourceAction | RouterAction>,
  ownProps: WarehouseContextualPanelContainerProps
): Partial<WarehouseContextualPanelProps> {
  return {
    onPostError(error: ErrorModel): void {
      ownProps.onPostError(error);
    },
    redirectOnReceivingOrderCreateSuccess: (referenceNumber: string): void => {
      dispatch(push(urls.receivingOrderDetails.replace(':referenceNumber', `${referenceNumber}`)));
    },
    onSendWarehouseCartData(receivingOrders: CreateReceivingOrderCommand): void {
      dispatch(
        resourceActions.resourceRequested(ResourceType.SendReceivingOrders, {
          receivingOrders,
        })
      );
    },
    onWillUnmount: () => {
      dispatch(resourceActions.resourceInit(ResourceType.SendReceivingOrders));
    },
  };
}

export const WarehouseContextualPanelContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(validation(injectIntl(WarehouseContextualPanel)));

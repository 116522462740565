import { CustomerDetailsResource } from '@store/state';
import { CustomerDetailsAction } from './actions';
import { CUSTOMER_DETAILS_NEW_ADDRESS_OPEN } from './constants';

export function customerDetailsReducer(
  state: CustomerDetailsResource,
  action: CustomerDetailsAction
): CustomerDetailsResource {
  switch (action.type) {
    case CUSTOMER_DETAILS_NEW_ADDRESS_OPEN:
      return {
        isNewAddressOpen: action.payload.isNewAddressOpen,
      };
    default:
      return state;
  }
}

import { GridContainer } from '@components/atoms/GridContainer';
import { geti18nName } from '@containers/formatters';
import { GridProps, GridType } from '@models';
import {
  ColumnSize,
  DataGridColumn,
  DataGridRow,
  dateTimeFormatter,
  enumFormatter,
  getEnumOptions,
} from '@oplog/data-grid';
import { Button, Icon } from '@oplog/express';
import { DropdownItem } from '@oplog/express/dist/components/Button';
import { UserDTO, UserStatus, UserType } from '@services';
import useAuthStore from '@store/auth/auth';
import { SortDirection, SortField } from 'dynamic-query-builder-client';
import * as React from 'react';

const intlKey = 'Settings.Grid';

export interface UsersGridProps extends GridProps {
  onAssignAdmin: (rowData: UserDTO) => void;
  onDeleteUser: (rowData: UserDTO) => void;
}

export const UsersGrid: React.FC<UsersGridProps> = ({ intl, onAssignAdmin, onDeleteUser, titleKey }) => {
  const usersGridInitialSort: SortField = new SortField({
    property: 'createdAt',
    by: SortDirection.DESC,
  });

  const [{ auth0UserInfo }, { userIsTenantAdmin }] = useAuthStore();

  const isTenantAdmin = userIsTenantAdmin();
  const usersGridColumns: Array<DataGridColumn> = [
    {
      name: '#',
      key: 'index',
      resizable: true,
      locked: true,
      sortable: false,
      type: 'number',
      visibility: true,
      filterable: false,
      width: ColumnSize.Medium,
      cellClass: 'index-column',
    },
    {
      name: geti18nName('fullName', intl, intlKey),
      key: 'fullName',
      locked: true,
      sortable: false,
      type: 'string',
      visibility: true,
      filterable: true,
      resizable: true,
    },
    {
      name: geti18nName('status', intl, intlKey),
      key: 'status',
      locked: true,
      sortable: false,
      type: 'enum',
      visibility: true,
      filterable: true,
      resizable: true,
      options: getEnumOptions(intl, UserStatus),
      formatter: enumFormatter,
      getRowMetaData: () => {
        return intl;
      },
    },
    {
      name: geti18nName('type', intl, intlKey),
      key: 'type',
      locked: true,
      sortable: false,
      type: 'enum',
      visibility: true,
      filterable: true,
      resizable: true,
      options: getEnumOptions(intl, UserType),
      formatter: enumFormatter,
      getRowMetaData: () => {
        return intl;
      },
    },
    {
      name: geti18nName('email', intl, intlKey),
      key: 'email',
      locked: true,
      sortable: false,
      type: 'string',
      visibility: true,
      filterable: true,
      resizable: true,
    },
    {
      name: geti18nName('createdAt', intl, intlKey),
      key: 'createdAt',
      locked: true,
      sortable: false,
      type: 'moment',
      visibility: true,
      filterable: true,
      resizable: true,
      formatter: dateTimeFormatter,
    },
  ];

  const statusActions = (row: UserDTO): Array<DropdownItem> => {
    // const isTenantAdmin = auth.userHasScopes([scopes.TenantAdmin]);

    let options: any = [];
    if (row.type === UserType.Regular && isTenantAdmin) {
      options = [
        {
          text: intl.messages[`${intlKey}.Column.AssignAdmin`],
          value: 'edit',
          icon: { name: 'fal fa-user-tie' },
          onClick: () => onAssignAdmin(row),
        },
      ];
    }
    if (row.type !== UserType.Owner) {
      return [
        ...options,
        {
          text: intl.messages[`${intlKey}.Column.DeleteUser`],
          value: 'edit',
          icon: { name: 'fal fa-trash-alt' },
          onClick: () => onDeleteUser(row),
        },
      ];
    }
    return [];
  };

  const columns: DataGridColumn[] = isTenantAdmin
    ? [
        ...usersGridColumns,
        {
          name: geti18nName('id', intl, intlKey),
          key: 'id',
          type: 'component',
          filterable: false,
          locked: true,
          resizable: true,
          sortable: false,
          visibility: true,
          width: ColumnSize.Large,
          formatter: (props: any) => {
            const { dependentValues } = props;
            const statusActionsArray: Array<DropdownItem> = statusActions(dependentValues);

            if (!statusActionsArray.length) return null;
            return (
              <Button
                dropdown={{
                  icon: <Icon name="fas fa-ellipsis-v" />,
                  items: statusActionsArray,
                  popoverProps: { withPortal: true },
                }}
                size="small"
                addonProps={{ bg: 'transparent', color: 'palette.grey' }}
                bg="transparent"
                _hover={{ bg: 'transparent' }}
                _focus={{ outline: '0' }}
                marginLeft="auto"
              />
            );
          },
          getRowMetaData: (row: DataGridRow) => row,
        },
      ]
    : usersGridColumns;

  return (
    <GridContainer
      titleKey={
        titleKey ? `${intl.messages['Settings.Grid.Title']} - ${titleKey}` : intl.messages['Settings.Grid.Title']
      }
      intlKey={intlKey}
      gridKey={GridType.Users}
      columns={columns}
      predefinedFilters={[]}
      intl={intl}
      filtersDisabled
      sortField={usersGridInitialSort}
    />
  );
};

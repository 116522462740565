import { geti18nName } from '@containers/formatters';
import { WarehouseCartGridContainer } from '@containers/WarehouseCartGridContainer';
import { ColumnSize, DataGridColumn, DataGridRow, ellipsisFormatter } from '@oplog/data-grid';
import { Flex, Icon, Text } from '@oplog/express';
import useSendToWarehouseState from '@store/sendToWarehouse/SendToWarehouseState';
import * as React from 'react';
import { Props } from '../../atoms/Component/Component';
import { SendToWarehouseDeleteButton } from './bones';

export interface WarehouseCartGridProps extends Props {
  intlKey: string;
}

// There is an exception about the folder of this components
// This component violates atomic design structure because
// WarehouseContextualPanel, which is an organism, includes
// WarehouseCartGrid. Atomic design does not have this kind of
// hierarchy

export const WarehouseCartGrid: React.FC<WarehouseCartGridProps> = ({ intl, intlKey }) => {
  const [state, actions] = useSendToWarehouseState();

  const warehouseCartGridColumns: Array<DataGridColumn> = [
    {
      name: '#',
      key: 'index',
      resizable: true,
      locked: true,
      sortable: false,
      type: 'number',
      visibility: true,
      filterable: false,
      width: ColumnSize.Medium,
      cellClass: 'index-column',
    },
    {
      name: geti18nName('sku', intl, intlKey),
      key: 'sku',
      resizable: false,
      locked: true,
      sortable: false,
      type: 'string',
      visibility: true,
    },
    {
      name: geti18nName('productName', intl, intlKey),
      key: 'productName',
      resizable: false,
      locked: true,
      sortable: false,
      type: 'string',
      visibility: true,
      formatter: ellipsisFormatter,
    },
    {
      name: geti18nName('expectedQuantity', intl, intlKey),
      key: 'expectedQuantity',
      resizable: true,
      sortable: false,
      type: 'number',
      visibility: true,
    },
    {
      name: '',
      key: '',
      resizable: false,
      locked: true,
      sortable: false,
      type: 'string',
      visibility: true,

      formatter: row => {
        return <SendToWarehouseDeleteButton sku={row.dependentValues['sku']} />;
      },
      getRowMetaData: (row: DataGridRow) => row,
    },
  ];

  const EmptyRowsViewComponent = (): JSX.Element => {
    return (
      <Flex flexDirection="column" mt="44px" px="57px" textAlign="center">
        <Icon fontSize="111px" color="palette.snow" name="fas fa-truck-loading" />
        <Text mt="24px" color="palette.snow">
          {intl.messages[`${intlKey}.NoRowsMessage`]}
        </Text>
      </Flex>
    );
  };

  const getSendToWareHouseData = () => {
    if (state.panelData) {
      const values = Array.from(state.panelData.values());
      return values.map((value, index) => {
        return {
          ...value,
          index: index + 1,
        };
      });
    }
    return undefined;
  };

  return (
    <WarehouseCartGridContainer
      intlKey={intlKey}
      columns={warehouseCartGridColumns}
      noRowsView={EmptyRowsViewComponent}
      rows={getSendToWareHouseData()}
    />
  );
};

import { definitions } from './definitions';
import { AppRouteConfig } from './model';

const routes: Array<AppRouteConfig> = Object.keys(definitions).map(k => {
  return definitions[k];
});

// NotFoundRoute should reside as the last route definition
// NOTE: Do not push app routes, use routes array definition instead
routes.push(definitions.notFound);
export { routes };

import { CustomerOrderLineItemsGrid, CustomerSalesOrdersGrid, CustomerShippingAddressesGrid } from '@components';
import { TabItem, Tab, TabProps } from '@oplog/express';
import { StoreState } from '@store';
import { RouterAction } from 'connected-react-router';
import * as React from 'react';
import { InjectedIntl } from 'react-intl';
import { connect, Omit } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { Dispatch } from 'redux';
import { onTabChange, getRouteParam } from '@utils';
import { RouteProps } from 'react-router-dom';

export function resolveActiveIndex(tabItems: Array<TabItem>, tabIds: any, routeProps: any) {
  const tabKeys = Object.keys(tabIds);
  let activeTab = tabIds[0];
  const tab = getRouteParam(routeProps, 'tab');
  tabKeys.map(tabKey => {
    if (tab === tabIds[tabKey]) {
      activeTab = tabIds[tabKey];
    }
  });

  const activeIndex = tabItems.findIndex(t => t.id === activeTab);
  return activeIndex < 0 ? 0 : activeIndex;
}

export enum Tabs {
  SalesOrders = 'sales-orders',
  SalesOrderLineItems = 'sales-order-line-items',
  ShippingAddresses = 'shipping-addresses',
}

interface ContainerTabProps extends TabProps {
  intl: InjectedIntl;
  customerName: string;
}
export interface CustomerDetailsTabMenuContainerProps extends RouteComponentProps {
  intl: InjectedIntl;
  customerName: string;
}

function mapStateToProps({ resources }: StoreState, ownProps: CustomerDetailsTabMenuContainerProps): Partial<TabProps> {
  const customerNo = getRouteParam(ownProps, 'customerNo');
  const { intl, customerName } = ownProps;

  let tabItems: Array<TabItem> = [];

  tabItems = [
    {
      id: Tabs.SalesOrders,
      title: intl.messages['CustomerDetailsTabMenu.Pane.Orders.Title'],
      component: <CustomerSalesOrdersGrid customerNo={customerNo} intl={ownProps.intl} titleKey={customerName} />,
    },
    {
      id: Tabs.SalesOrderLineItems,
      title: intl.messages['CustomerDetailsTabMenu.Pane.Products.Title'],
      component: <CustomerOrderLineItemsGrid customerNo={customerNo} intl={ownProps.intl} titleKey={customerName} />,
    },
    {
      id: Tabs.ShippingAddresses,
      title: intl.messages['CustomerDetailsTabMenu.Pane.ShippingAddresses.Title'],
      component: <CustomerShippingAddressesGrid customerNo={customerNo} intl={ownProps.intl} titleKey={customerName} />,
    },
  ];

  return {
    tabs: tabItems,
    activeIndex: resolveActiveIndex(tabItems, Tabs, ownProps),
  };
}

function mapDispatchToProps(
  dispatch: Dispatch<RouterAction>,
  routeProps: CustomerDetailsTabMenuContainerProps
): Partial<TabProps> {
  return {
    onTabChange: (activeIndex: number) => {
      switch (activeIndex) {
        case 0:
          onTabChange(Tabs.SalesOrders, routeProps, dispatch);
          break;
        case 1:
          onTabChange(Tabs.SalesOrderLineItems, routeProps, dispatch);
          break;
        case 2:
          onTabChange(Tabs.ShippingAddresses, routeProps, dispatch);
          break;
        default:
          onTabChange(Tabs.SalesOrders, routeProps, dispatch);
      }
    },
  };
}

export const CustomerDetailsTabMenuContainer = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Tab)
) as React.ComponentType<Omit<ContainerTabProps, 'activeIndex' | 'onTabChange' | 'tabs'>>;

import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  FormValidator,
  Input,
  isEmpty,
  SelectHTML
} from '@oplog/express';
import { Resource } from '@oplog/resource-redux';
import { AddAddressToCustomerCommand } from '@services';
import { addressErrorMessages, DropdownOptionsFromResource, generateAddressFormSchema } from '@utils';
import * as React from 'react';
import { useEffect, useState } from 'react';
import useForceUpdate from 'use-force-update';
import { Props } from '../../atoms/Component/Component';

const COMPONENT_INTL_KEY = 'CreateCustomerShippingAddressForm';

export interface CreateCustomerShippingAddressFormProps extends Props {
  validator: FormValidator;
  customerNo: number;
  isComplete: boolean;
  countryResource: Resource<string[]>;
  provinceResource: Resource<string[]>;
  districtResource: Resource<string[]>;
  onModalClose: () => void;
  onSubmit: (form: Partial<AddAddressToCustomerCommand>, customerNo: number) => void;
  onComplete: () => void;
  provinceSelect: (command: { province: string }) => void;
}

export interface CreateCustomerShippingAddressFormState {
  fields: Partial<AddAddressToCustomerCommand>;
}

export const CreateCustomerShippingAddressForm: React.FC<CreateCustomerShippingAddressFormProps> = ({
  intl,
  isBusy,
  onDidMount,
  onWillUnmount,
  validator,
  customerNo,
  districtResource,
  onModalClose,
  onSubmit,
  countryResource,
  provinceResource,
  provinceSelect,
  isComplete,
  onComplete,
}) => {
  const [fields, setFields] = useState({
    addressFirstName: '',
    addressLastName: '',
    company: '',
    addressOne: '',
    addressTwo: '',
    district: '',
    city: '',
    country: intl.messages[`${COMPONENT_INTL_KEY}.Turkey`],
    postalCode: '',
    addressPhone: '',
  });

  const forceUpdate = useForceUpdate();

  useEffect(() => {
    validator.registerSchema(createCustomerShippingAddressFormSchema());
    onDidMount && onDidMount();

    return () => {
      validator.clearErrors();
      onWillUnmount && onWillUnmount();
    };
  }, []);

  useEffect(() => {
    // İlginç bir abimiz
    if (isComplete === true) {
      onComplete();
    }
  }, [isComplete]);

  const handleOnBlur = (key: keyof AddAddressToCustomerCommand) => () => {
    validator.validate(key, fields[key]);
    forceUpdate();
  };

  const handleOnInputChange = (key: keyof AddAddressToCustomerCommand) => (e: any) => {
    let { district, ...rest } = fields;
    if (key === 'city' && fields.city !== e.currentTarget.value) {
      provinceSelect({ province: e.currentTarget.value });
      district = '';
    }

    if (key === 'country' && fields.country !== e.currentTarget.value) {    
      setFields({
        ...fields,
        city : '',
        district : '',
        [key]: e.currentTarget.value,
      });
    }else{ 
      setFields({
        ...fields,
        district,
        [key]: e.currentTarget.value,
      });
    }

    validator.validate(key, e.currentTarget.value);
    forceUpdate();
  };

  const createCustomerShippingAddressFormSchema = () => {
    const addressErrorMessages: addressErrorMessages = {
      addressFirstName: {
        required: `${intl.messages[`${COMPONENT_INTL_KEY}.Error.Receiver.Name.Empty`]}`,
        invalid: `${intl.messages[`${COMPONENT_INTL_KEY}.Error.Receiver.Name.Invalid`]}`,
      },
      addressLastName: {
        required: `${intl.messages[`${COMPONENT_INTL_KEY}.Error.Receiver.LastName.Empty`]}`,
        invalid: `${intl.messages[`${COMPONENT_INTL_KEY}.Error.Receiver.LastName.Invalid`]}`,
      },

      addressOne: {
        required: `${intl.messages[`${COMPONENT_INTL_KEY}.Error.Receiver.AddressOne`]}`,
      },

      district: {
        required: `${intl.messages[`${COMPONENT_INTL_KEY}.Error.Receiver.District`]}`,
      },

      city: {
        required: `${intl.messages[`${COMPONENT_INTL_KEY}.Error.Receiver.City`]}`,
      },

      country: {
        required: `${intl.messages[`${COMPONENT_INTL_KEY}.Error.Receiver.Country`]}`,
      },

      addressPhone: {
        length: `${intl.messages[`${COMPONENT_INTL_KEY}.Error.Receiver.PhoneNumber.PhoneLength`]}`,
        type: `${intl.messages[`${COMPONENT_INTL_KEY}.Error.Receiver.PhoneNumber.PhoneType`]}`,
        required: `${intl.messages[`${COMPONENT_INTL_KEY}.Error.Receiver.PhoneNumber.Empty`]}`,
      },
    };

    return generateAddressFormSchema(addressErrorMessages);
  };

  const isFormInvalid = (): boolean => {
    const requiredFields = [
      'addressFirstName',
      'addressLastName',
      'addressOne',
      'district',
      'city', 
      'country',
      'addressPhone',
    ];
    return requiredFields.some(key => isEmpty(fields[key])) || validator.hasErrors();
  };

  return (
    <>
      <form onSubmit={(e: React.SyntheticEvent<HTMLFormElement>) => e.preventDefault()}>
        <Flex flexDirection="row" flexWrap="wrap">
          <FormControl
            isInvalid={!!validator.getErrorIntent('addressFirstName')}
            size="small"
            width={1 / 2}
            mb="8"
            pr="16"
          >
            <FormLabel>{intl.messages[`${COMPONENT_INTL_KEY}.ReceiverName`]}*</FormLabel>
            <Input
              placeholder={intl.messages[`${COMPONENT_INTL_KEY}.PlaceHolder.ReceiverName`]}
              onBlur={handleOnBlur('addressFirstName')}
              onChange={handleOnInputChange('addressFirstName')}
              value={fields.addressFirstName}
            />
            <FormErrorMessage>{validator.getErrorIntent('addressFirstName')?.text}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={!!validator.getErrorIntent('addressLastName')} size="small" width={1 / 2} mb="8">
            <FormLabel>{intl.messages[`${COMPONENT_INTL_KEY}.ReceiverLastname`]}*</FormLabel>
            <Input
              placeholder={intl.messages[`${COMPONENT_INTL_KEY}.PlaceHolder.ReceiverLastname`]}
              onChange={handleOnInputChange('addressLastName')}
              onBlur={handleOnBlur('addressLastName')}
              value={fields.addressLastName}
            />
            <FormErrorMessage>{validator.getErrorIntent('addressLastName')?.text}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={!!validator.getErrorIntent('company')} size="small" width={1} mb="8">
            <FormLabel>{intl.messages[`${COMPONENT_INTL_KEY}.Company`]}</FormLabel>
            <Input
              placeholder={intl.messages[`${COMPONENT_INTL_KEY}.PlaceHolder.Company`]}
              onChange={handleOnInputChange('company')}
              value={fields.company}
            />
            <FormErrorMessage>{validator.getErrorIntent('company')?.text}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={!!validator.getErrorIntent('addressOne')} size="small" width={1} mb="8">
            <FormLabel>{intl.messages[`${COMPONENT_INTL_KEY}.ReceiverAddressOne`]}*</FormLabel>
            <Input
              placeholder={intl.messages[`${COMPONENT_INTL_KEY}.PlaceHolder.ReceiverAddressOne`]}
              onBlur={handleOnBlur('addressOne')}
              onChange={handleOnInputChange('addressOne')}
              value={fields.addressOne}
            />
            <FormErrorMessage>{validator.getErrorIntent('addressOne')?.text}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={!!validator.getErrorIntent('addressTwo')} size="small" width={1} mb="8">
            <FormLabel>{intl.messages[`${COMPONENT_INTL_KEY}.ReceiverAddressTwo`]}</FormLabel>
            <Input
              placeholder={intl.messages[`${COMPONENT_INTL_KEY}.PlaceHolder.ReceiverAddressTwo`]}
              onChange={handleOnInputChange('addressTwo')}
              value={fields.addressTwo}
            />
            <FormErrorMessage>{validator.getErrorIntent('addressTwo')?.text}</FormErrorMessage>
          </FormControl>
          <FormControl size="small" isInvalid={!!validator.getErrorIntent('addressTwo')} width={1 / 2} pr="16" mb="11">
            <FormLabel>{intl.messages[`${COMPONENT_INTL_KEY}.Country`]}*</FormLabel>  
            <SelectHTML
              placeholder={intl.messages[`${COMPONENT_INTL_KEY}.PlaceHolder.Country`]}
              onChange={handleOnInputChange('country')}
              onBlur={handleOnBlur('country')}
              value={fields.country}
              options={DropdownOptionsFromResource(countryResource)}
            />
            <FormErrorMessage>{validator.getErrorIntent('country')?.text}</FormErrorMessage>
          </FormControl>
          <FormControl size="small" isInvalid={!!validator.getErrorIntent('city')} width={1 / 2} mb="11" >
            <FormLabel>{intl.messages[`${COMPONENT_INTL_KEY}.City`]}*</FormLabel>
            {
                fields.country == intl.messages[`${COMPONENT_INTL_KEY}.Turkey`] ?

                <SelectHTML
                placeholder={intl.messages[`${COMPONENT_INTL_KEY}.PlaceHolder.City`]}
                onChange={handleOnInputChange('city')}
                value={fields.city}
                options={DropdownOptionsFromResource(provinceResource)}
                selection
                /> :
                <Input
                placeholder={intl.messages[`${COMPONENT_INTL_KEY}.PlaceHolder.City`]} 
                onChange={handleOnInputChange('city')}
                value={fields.city} /> 
            }
 
            <FormErrorMessage>{validator.getErrorIntent('city')?.text}</FormErrorMessage>
          </FormControl>
          <FormControl size="small" isInvalid={!!validator.getErrorIntent('district')} width={1 / 2} pr="16" mb="11" >
            <FormLabel>{intl.messages[`${COMPONENT_INTL_KEY}.District`]}*</FormLabel>
            { 
                fields.country == intl.messages[`${COMPONENT_INTL_KEY}.Turkey`] ?

                  <SelectHTML
                  placeholder={intl.messages[`${COMPONENT_INTL_KEY}.PlaceHolder.District`]}
                  onChange={handleOnInputChange('district')}
                  value={fields.district}
                  options={DropdownOptionsFromResource(districtResource)}
                  selection
                /> :
                <Input
                placeholder={intl.messages[`${COMPONENT_INTL_KEY}.PlaceHolder.District`]} 
                onChange={handleOnInputChange('district')}
                value={fields.district} /> 
            } 

            <FormErrorMessage>{validator.getErrorIntent('district')?.text}</FormErrorMessage>
          </FormControl>
          <FormControl size="small" isInvalid={!!validator.getErrorIntent('postalCode')} width={1 / 2} mb="11">
            <FormLabel>{intl.messages[`${COMPONENT_INTL_KEY}.PostalCode`]}</FormLabel>
            <Input
              placeholder={intl.messages[`${COMPONENT_INTL_KEY}.PlaceHolder.PostalCode`]}
              onChange={handleOnInputChange('postalCode')}
              value={fields.postalCode}
            />
            <FormErrorMessage>{validator.getErrorIntent('postalCode')?.text}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={!!validator.getErrorIntent('addressPhone')} size="small" width={1} mb="11">
            <FormLabel>{intl.messages[`${COMPONENT_INTL_KEY}.ReceiverPhoneNumber`]}*</FormLabel>
            <Input
              placeholder={intl.messages[`${COMPONENT_INTL_KEY}.PlaceHolder.ReceiverPhoneNumber`]}
              onBlur={handleOnBlur('addressPhone')}
              onChange={handleOnInputChange('addressPhone')}
              value={fields.addressPhone}
            />
            <FormErrorMessage>{validator.getErrorIntent('addressPhone')?.text}</FormErrorMessage>
          </FormControl>
        </Flex>
      </form>
      <Flex justifyContent="center">
        <Button
          width="122px"
          mr="11px"
          size="small"
          kind="outline"
          variant="dark"
          disabled={isBusy}
          onClick={onModalClose}
        >
          {intl.messages['Form.Action.Cancel']}
        </Button>
        <Button
          width="182px"
          size="small"
          variant="success"
          disabled={isBusy || isFormInvalid()}
          isLoading={isBusy}
          onClick={() => onSubmit(fields, customerNo)}
        >
          {intl.messages[`Form.Action.Save`]}
        </Button>
      </Flex>
    </>
  );
};

import { GridContainer } from '@components/atoms/GridContainer';
import { Box, Button, Flex } from '@oplog/express';
import { ExcelExportModal, ExportTypes } from '@components/molecules/ExcelExportModal';
import {
  bubbleStepFormatter,
  CustomerDetailsLinkFormatter,
  geti18nName,
  SalesOrderDetailsLinkFormatter,
  sourceIconFormatter,
} from '@containers/formatters';
import { GridProps, GridType, ResourceType } from '@models';
import {
  ColumnSize,
  DataGridColumn,
  DataGridRow,
  dateTimeFormatter,
  ellipsisFormatter,
  enumFormatter,
  getEnumOptions,
  PredefinedFilter,
} from '@oplog/data-grid';
import { BulkCancelLineItemsInput, CargoType, PaymentOption, SalesOrderQueryOutputDTO, SalesOrderQueryOutputDTODynamicQueryOutputDTO, SalesOrderState } from '@services';
import { EXCEL_EXPORT_LIMIT, EXCEL_EXPORT_SALESORDER_LIMIT, generateIntegrationEnum } from '@utils';
import { SortDirection, SortField } from 'dynamic-query-builder-client';
import * as React from 'react';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { CleanBlockedSalesOrdersPreviewModal } from '@components/molecules/CleanBlockedSalesOrdersPreviewModal';

const intlKey = 'BlockedGrid';

const blockedGridInitalSort: SortField = new SortField({
  property: 'orderCreatedAt',
  by: SortDirection.DESC,
});

export const blockedGridPredefinedFilters: Array<PredefinedFilter> = [];

interface SalesOrdersGridProps extends GridProps {
  isBusy?: boolean;
  filters: any;
  integrations: any;
  blockedExcelToExport: (resourceType: ResourceType) => void;
  blockedExcelExportResource: any;
  blockedExcelToExportInit: () => void;
  blockedExcelExportDetailedResource: any;
  blockedSalesOrdersPackageDetailExcelExportLimit: number;
  onCleanBlockedButtonClick: ({ referenceNumbers }: { referenceNumbers: string[]; }) => void;
  onApproveCleanBlockedClick: ({ referenceNumbers }: { referenceNumbers: string[]; }) => void;
  stockQuantityDetailsUsedInBlockedOrders: any;
  isBusyGetStockQuantityDetailsUsedInBlockedSalesOrders: boolean;
  isErrorGetStockQuantityDetailsUsedInBlockedSalesOrders: boolean;
  cleanBlockedGridResource: any;
  pendingSalesOrders: SalesOrderQueryOutputDTODynamicQueryOutputDTO;
  fetchPendingSalesOrders: () => void;
  bulkCreatePackageResource: any;
  bulkCancelLineItemsResource: any;
  bulkCancelLineItemsCommand: (cancelLineItems: BulkCancelLineItemsInput[]) => void;
}

const Text = styled.p`
  color: ${(props: any) => props.theme.colors.palette.steel_darker};
  text-align: center;
  font-size: ${(props: any) => props.theme.fontSizes['13']};
  line-height: ${(props: any) => props.theme.lineHeights.xxLarge};
`;

export const SalesOrderBlockedGrid: React.FC<SalesOrdersGridProps> = ({
  intl,
  titleKey,
  isBusy,
  filters,
  integrations,
  blockedExcelToExport,
  blockedExcelToExportInit,
  blockedExcelExportResource,
  blockedExcelExportDetailedResource,
  blockedSalesOrdersPackageDetailExcelExportLimit,
  onCleanBlockedButtonClick,
  onApproveCleanBlockedClick,
  cleanBlockedGridResource,
  pendingSalesOrders,
  stockQuantityDetailsUsedInBlockedOrders,
  isBusyGetStockQuantityDetailsUsedInBlockedSalesOrders,
  isErrorGetStockQuantityDetailsUsedInBlockedSalesOrders,
  fetchPendingSalesOrders,
  bulkCreatePackageResource,
  bulkCancelLineItemsResource,
  bulkCancelLineItemsCommand,
}) => {
  const [isBlockedExcelExportModalOpen, setIsBlockedExcelExportModalOpen] = useState(false);
  const [rowCount, setRowCount] = React.useState(0);
  const [selectedIndexes, setSelectedIndexes] = useState<any>([]);
  const [isCleanBlockedModalOpen, setIsCleanBlockedModalOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState<any>([]);
  const [allReferenceNumbers, setAllReferenceNumbers] = useState<string[]>([]);
  const [selectedReferenceNumbers, setSelectedReferenceNumbers] = useState<string[]>([]);
  const [stockQuantityDetails, setStockQuantityDetails] = useState<any>();

  const [excelExportBlockedPackageDetailLimit, setExcelExportBlockedPackageDetailLimit] = useState(EXCEL_EXPORT_SALESORDER_LIMIT);

  useEffect(() => {
    fetchPendingSalesOrders();
  }, []);
  useEffect(() => {
    if (pendingSalesOrders && pendingSalesOrders.data) {
      setAllReferenceNumbers(pendingSalesOrders.data.map((order: any) => order.referenceNumber));
    }
  }, [pendingSalesOrders]);

  useEffect(() => {
    let blockedSalesOrders = filters.data as SalesOrderQueryOutputDTO[];
    if (blockedSalesOrders.length > 0) {
      setRowCount(blockedSalesOrders[0].totalProductCountInSalesOrder);
    }
  });

  useEffect(() => {
    if (stockQuantityDetailsUsedInBlockedOrders) {
      setStockQuantityDetails(stockQuantityDetailsUsedInBlockedOrders);
    }
  }, [stockQuantityDetailsUsedInBlockedOrders]);

  useEffect(() => {
    if (blockedSalesOrdersPackageDetailExcelExportLimit) {
      setExcelExportBlockedPackageDetailLimit(blockedSalesOrdersPackageDetailExcelExportLimit);
    }
    else {
      setExcelExportBlockedPackageDetailLimit(EXCEL_EXPORT_SALESORDER_LIMIT);
    }
  });

  useEffect(() => {
    if (filters) {
      setSelectedIndexes([]);
      setSelectedRows([]);
      setSelectedReferenceNumbers([]);
    }
  }, [filters])

  const onRowsSelected = (rows: any) => {

    let newSelectedReferenceNumbers = selectedReferenceNumbers;

    newSelectedReferenceNumbers = Array.from(new Set([...selectedReferenceNumbers, ...rows.map((row: any) => row.row.referenceNumber)]));

    const newSelectedIndexes = selectedIndexes.concat(rows.map((r: { rowIdx: any; }) => r.rowIdx));
    const newSelectedRows = selectedRows.concat(rows);

    setSelectedIndexes(newSelectedIndexes);
    setSelectedRows(newSelectedRows);
    setSelectedReferenceNumbers(newSelectedReferenceNumbers);
  };

  const onRowsDeselected = (rows: any[]) => {
    let rowIndexes = rows.map(r => r.rowIdx);
    let deselectedReferenceNumbers = rows.map(row => row.row.referenceNumber);

    if (rows.length > 1) {
      setSelectedRows([]);
      setSelectedIndexes([]);
      setSelectedReferenceNumbers([]);
    } else {
      setSelectedRows(
        selectedRows.filter((_: any, index: any) => index !== selectedIndexes.findIndex((i: any) => rowIndexes.indexOf(i) !== -1))
      );
      setSelectedIndexes(selectedIndexes.filter((i: any) => rowIndexes.indexOf(i) === -1));
      setSelectedReferenceNumbers(selectedReferenceNumbers.filter(refNum => !deselectedReferenceNumbers.includes(refNum)));
    }
  };

  const openCleanBlockedModal = () => {
    setIsCleanBlockedModalOpen(true);
    onCleanBlockedButtonClick({ referenceNumbers: selectedReferenceNumbers });
  }

  const openCleanBlockedModalAllSalesOrders = () => {
    setIsCleanBlockedModalOpen(true);
    onCleanBlockedButtonClick({ referenceNumbers: allReferenceNumbers });
  }

  const toggleBlockedExcelExportModal = () => {
    setIsBlockedExcelExportModalOpen(true);
  };

  const onCleanBlockedModalClose = () => {
    setIsCleanBlockedModalOpen(false);
    window.location.reload();
  }

  const onBlockedModalClose = () => {
    setIsBlockedExcelExportModalOpen(false);
  };

  const blockedGridColumns: Array<DataGridColumn> = [
    {
      name: '#',
      key: 'index',
      resizable: true,
      locked: true,
      sortable: false,
      type: 'number',
      visibility: true,
      filterable: false,
      width: ColumnSize.Medium,
      cellClass: 'index-column',
    },
    {
      name: geti18nName('referenceNumber', intl, intlKey),
      key: 'referenceNumber',
      resizable: true,
      locked: true,
      sortable: true,
      type: 'string',
      visibility: true,
      filterable: true,
      formatter: SalesOrderDetailsLinkFormatter,
      getRowMetaData: (row: DataGridRow) => row,
    },
    {
      name: geti18nName('customerEmail', intl, intlKey),
      key: 'customerEmail',
      type: 'string',
      visibility: false,
      filterable: true,
    },
    {
      name: geti18nName('customerPhone', intl, intlKey),
      key: 'customerPhone',
      type: 'string',
      visibility: false,
      filterable: true,
    },
    {
      name: geti18nName('integrationType', intl, intlKey),
      key: 'integrationName',
      locked: true,
      sortable: true,
      type: 'enum',
      visibility: true,
      filterable: true,
      resizable: true,
      width: ColumnSize.Large,
      formatter: row => sourceIconFormatter(row.value, row.dependentValues, intl),
      getRowMetaData: (row: DataGridRow) => row,
      options: integrations ? generateIntegrationEnum(integrations) : [],
    },
    {
      name: geti18nName('salesChannel', intl, intlKey),
      key: 'salesChannel',
      locked: true,
      sortable: true,
      type: 'string',
      visibility: true,
      filterable: true,
      resizable: true,
      getRowMetaData: (row: DataGridRow) => row['integrationType'],
    },
    {
      name: geti18nName('state', intl, intlKey),
      key: 'state',
      locked: true,
      sortable: true,
      type: 'enum',
      visibility: true,
      filterable: true,
      formatter: bubbleStepFormatter,
      getRowMetaData: (row: DataGridRow) => {
        return {
          intl,
          enum: SalesOrderState,
          row,
        };
      },
      resizable: true,
      options: getEnumOptions(intl, SalesOrderState),
    },
    {
      name: geti18nName('cargoType', intl, intlKey),
      key: 'cargoType',
      locked: true,
      sortable: false,
      type: 'enum',
      visibility: true,
      filterable: true,
      formatter: enumFormatter,
      getRowMetaData: () => {
        return intl;
      },
      resizable: true,
      options: getEnumOptions(intl, CargoType),
    },
    {
      name: geti18nName('customerFullName', intl, intlKey),
      key: 'customerFullName',
      locked: true,
      sortable: true,
      type: 'string',
      visibility: true,
      filterable: true,
      formatter: CustomerDetailsLinkFormatter,
      getRowMetaData: (row: DataGridRow) => row,
      resizable: true,
    },
    {
      name: geti18nName('shippingAddressCity', intl, intlKey),
      key: 'shippingAddressCity',
      locked: true,
      sortable: true,
      type: 'string',
      visibility: true,
      filterable: true,
      resizable: true,
      formatter: ellipsisFormatter,
      getRowMetaData: (row: DataGridRow) => row,
    },
    {
      name: geti18nName('paymentOption', intl, intlKey),
      key: 'paymentOption',
      locked: true,
      sortable: true,
      type: 'enum',
      visibility: true,
      filterable: true,
      formatter: enumFormatter,
      options: getEnumOptions(intl, PaymentOption),
      getRowMetaData: () => {
        return intl;
      },
    },
    {
      name: geti18nName('uniqueProductCount', intl, intlKey),
      key: 'uniqueProductCount',
      locked: true,
      sortable: true,
      type: 'number',
      visibility: true,
      filterable: true,
      resizable: true,
    },
    {
      name: geti18nName('totalProductCount', intl, intlKey),
      key: 'totalProductCount',
      locked: true,
      sortable: true,
      type: 'number',
      visibility: true,
      filterable: true,
      resizable: true,
    },
    {
      name: geti18nName('orderCreatedAt', intl, intlKey),
      key: 'orderCreatedAt',
      locked: true,
      sortable: true,
      type: 'moment',
      visibility: true,
      filterable: true,
      resizable: true,
      formatter: dateTimeFormatter,
    },
  ];

  return (
    <Box>
      <GridContainer
        titleKey={
          titleKey
            ? `${intl.messages['SalesOrders.Tabs.Blocked']} - ${titleKey}`
            : intl.messages['SalesOrders.Tabs.Blocked']
        }
        intlKey={intlKey}
        remoteExportToExcel
        onExcelExportButtonClick={toggleBlockedExcelExportModal}
        rowSelection={{
          onRowsSelected: onRowsSelected,
          onRowsDeselected: onRowsDeselected,
          selectBy: {
            indexes: selectedIndexes,
          },
        }}
        gridKey={GridType.SalesOrderBlocked}
        columns={blockedGridColumns}
        predefinedFilters={blockedGridPredefinedFilters}
        intl={intl}
        sortField={blockedGridInitalSort}
        enableRowSelect={true}
        headerContent={
          <Box>
            <Flex justifyContent="end">
              <Box>
                <Button
                  disabled={isBusy || selectedReferenceNumbers.length > 0}
                  onClick={() => {
                    if (!isCleanBlockedModalOpen) {
                      openCleanBlockedModalAllSalesOrders();
                    }
                  }}
                  variant='alternative'
                  mx='10px'
                >
                  {intl.messages[`${intlKey}.Header.CleanBlockedAll`]}
                </Button>
              </Box>
              <Box>
                <Button
                  disabled={isBusy || selectedReferenceNumbers.length === 0}
                  onClick={() => {
                    if (!isCleanBlockedModalOpen) {
                      openCleanBlockedModal();
                    }
                  }}
                >
                  {intl.messages[`${intlKey}.Header.CleanBlockedSelected`]}
                </Button>
              </Box>
            </Flex>
          </Box>
        }
      />
      {isBlockedExcelExportModalOpen && (
        <ExcelExportModal
          excelToExport={blockedExcelToExport}
          intl={intl}
          onModalClose={onBlockedModalClose}
          excelExportResource={blockedExcelExportResource}
          excelExportDetailedResource={blockedExcelExportDetailedResource}
          excelToExportInit={blockedExcelToExportInit}
          excelExportType={ExportTypes.SalesOrderBlocked}
          rowCount={rowCount}
          defaultExportLimit={EXCEL_EXPORT_LIMIT}
          detailedExportLimit={excelExportBlockedPackageDetailLimit}
        />
      )}
      {isCleanBlockedModalOpen && (
        <CleanBlockedSalesOrdersPreviewModal
          intl={intl}
          onModalClose={onCleanBlockedModalClose}
          selectedReferenceNumbers={selectedReferenceNumbers}
          onApproveCleanBlockedClick={onApproveCleanBlockedClick}
          cleanBlockedGridResource={cleanBlockedGridResource}
          stockQuantityDetailsUsedInBlockedOrders={stockQuantityDetails}
          isBusyGetStockQuantityDetailsUsedInBlockedSalesOrders={isBusyGetStockQuantityDetailsUsedInBlockedSalesOrders}
          isErrorGetStockQuantityDetailsUsedInBlockedSalesOrders={isErrorGetStockQuantityDetailsUsedInBlockedSalesOrders}
          bulkCreatePackageResource={bulkCreatePackageResource}
          bulkCancelLineItemsResource={bulkCancelLineItemsResource}
          bulkCancelLineItemsCommand={bulkCancelLineItemsCommand}
        />
      )}
    </Box>
  );
};

import { packageLinkFormatter } from '@containers/formatters/packageLinkComponent';
import { ColumnSize, DataGridColumn, DataGridRow, getEnumOptions } from '@oplog/data-grid';
import { PackageFulfillmentSyncState, WmsSyncState } from '@services';
import { geti18nName } from '../../../../containers/formatters';

export const getPackageSyncStatusColumns = (intl: any, intlKey: any): DataGridColumn[] => {
  const SupportSalesOrdersColumns: Array<DataGridColumn> = [
    {
      name: '#',
      key: 'index',
      resizable: false,
      locked: true,
      sortable: false,
      type: 'number',
      visibility: true,
      filterable: false,
      cellClass: 'index-column',
      width: ColumnSize.Medium,
    },
    {
      name: geti18nName('id', intl, intlKey),
      key: 'id',
      resizable: true,
      locked: true,
      sortable: true,
      type: 'string',
      visibility: true,
      filterable: true,
    },
    {
      name: geti18nName('salesOrderId', intl, intlKey),
      key: 'salesOrderId',
      resizable: true,
      locked: true,
      sortable: true,
      type: 'string',
      visibility: true,
      filterable: true,
    },
    {
      name: geti18nName('referenceNumber', intl, intlKey),
      key: 'referenceNumber',
      resizable: true,
      locked: true,
      sortable: true,
      type: 'string',
      visibility: true,
      filterable: true,
      formatter: packageLinkFormatter,
      getRowMetaData: (row: DataGridRow) => row,
    },
    {
      name: geti18nName('wmsSyncState', intl, intlKey),
      key: 'wmsSyncState',
      resizable: true,
      locked: true,
      sortable: false,
      type: 'enum',
      visibility: true,
      filterable: true,
      options: getEnumOptions(intl, WmsSyncState),
    },
    {
      name: geti18nName('wmsSyncRetryCount', intl, intlKey),
      key: 'wmsSyncRetryCount',
      resizable: true,
      locked: true,
      sortable: true,
      type: 'number',
      visibility: true,
      filterable: true,
    },
    {
      name: geti18nName('packageFulfillmentSyncState', intl, intlKey),
      key: 'packageFulfillmentSyncState',
      resizable: true,
      locked: true,
      sortable: false,
      type: 'enum',
      visibility: true,
      filterable: true,
      options: getEnumOptions(intl, PackageFulfillmentSyncState),
    },
    {
      name: geti18nName('packageFulfillmentSyncRetryCount', intl, intlKey),
      key: 'packageFulfillmentSyncRetryCount',
      resizable: true,
      locked: true,
      sortable: true,
      type: 'number',
      visibility: true,
      filterable: true,
    },
  ];
  return SupportSalesOrdersColumns;
};

export default getPackageSyncStatusColumns;

import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Flex,
  FormLabel,
  Text,
  Toggle,
} from '@oplog/express';
import useSupportOrdersState from '@store/supportPage/SupportSalesOrdersState';
import { dateToString } from '@utils';
import React, { ReactElement } from 'react';
import { Props } from '../../../atoms/Component/Component';

interface ValidationModalProps extends Props {
  options: any;
  COMPONENT_INTL_KEY: string;
  responseData: any;
  onClose: () => void;
}

const DateAndIntegrationSelectionConfirmation: React.FC<ValidationModalProps> = ({
  intl,
  COMPONENT_INTL_KEY,
  responseData,
  onClose,
}): ReactElement => {
  const [state, actions] = useSupportOrdersState();
  const [showException, setShowException] = React.useState(false);

  const { endDate, startDate, integrationName } = state.syncModal;
  const isEmptyResponseData = responseData.successfulItems.length === 0 && responseData.faultyItems.length === 0;
  const hasValidData = responseData.successfulItems.length > 0;
  const hasErrorData = responseData.faultyItems.length > 0;

  const startDateFormatted = dateToString(startDate);
  const endDateFormatted = dateToString(endDate);
  return (
    <>
      <Flex flexDirection="column" flexWrap="wrap">
        <FormLabel>{intl.messages[`${COMPONENT_INTL_KEY}.Information`]}</FormLabel>

        <Box>
          <Box>
            {intl.messages[`${COMPONENT_INTL_KEY}.Integration`]}: {integrationName}
          </Box>
          <Box>
            {intl.messages[`${COMPONENT_INTL_KEY}.StartDate`]}: {startDateFormatted}
          </Box>
          <Box>
            {intl.messages[`${COMPONENT_INTL_KEY}.EndDate`]}: {endDateFormatted}
          </Box>
        </Box>

        {isEmptyResponseData && (
          <Text mb="20px" mt="20px">
            <b>{intl.messages[`${COMPONENT_INTL_KEY}.NoDataReturned`]}</b>
          </Text>
        )}

        {hasValidData && (
          <>
            <Alert mb="10px" mt="10px" variant={'primary'} isOpen={true}>
              <AlertIcon name="fas fa-exclamation-triangle" />
              <AlertTitle>{intl.messages[`${COMPONENT_INTL_KEY}.SuccessAlert.SyncTitle`]}</AlertTitle>
              <AlertDescription>{intl.messages[`${COMPONENT_INTL_KEY}.SuccessAlert.SyncDescription`]}</AlertDescription>
            </Alert>

            <Box>
              {responseData.successfulItems.map(function(item: any, i: number) {
                return <Box key={i}>-{item.referenceNumber}</Box>;
              })}
            </Box>
          </>
        )}

        {hasErrorData && (
          <>
            <Alert mb="10px" mt="10px" variant={'danger'} isOpen={true}>
              <AlertIcon name="fas fa-exclamation-triangle" />
              <AlertTitle>{intl.messages[`${COMPONENT_INTL_KEY}.DismissAlert.SyncTitle`]}</AlertTitle>
              <AlertDescription>{intl.messages[`${COMPONENT_INTL_KEY}.DismissAlert.SyncDescription`]}</AlertDescription>
            </Alert>

            <Box mt="10px">
              <Text mr="2px">{intl.messages[`${COMPONENT_INTL_KEY}.ShowException`]}</Text>

              <Toggle
                bg={'palette.steel'}
                checkedColor={'palette.green'}
                onChange={() => setShowException(!showException)}
              />
            </Box>

            <Box mb="10px">
              {responseData.faultyItems.map(function(item: any, i: number) {
                var message;
                showException
                  ? (message = item.exceptionMessage)
                  : (message = intl.messages[`${COMPONENT_INTL_KEY}.ErrorCodes.${item.errorCode}`]);

                return (
                  <Box key={i}>
                    -{item.referenceNumber} - {message}
                  </Box>
                );
              })}
            </Box>
          </>
        )}
        <Flex justifyContent="center" width="100%" px="13px" mb="10px" mt="10px">
          <Button className="cancel" onClick={onClose} kind="outline" variant="dark" width={1} size="small">
            {intl.messages[`${COMPONENT_INTL_KEY}.OkayButton`]}
          </Button>
        </Flex>
      </Flex>
    </>
  );
};

export default DateAndIntegrationSelectionConfirmation;

import {
  Flex,
  Icon,
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalProps,
  ModalTitle,
  Text,
} from '@oplog/express';
import React from 'react';
import { injectIntl } from 'react-intl';
import { AddUserForm, AddUserFormProps } from '../AddUserForm/AddUserForm';

const COMPONENT_INTL_KEY = 'Settings';

export interface AddUserModalProps extends AddUserFormProps {
  isOpen: ModalProps['isOpen'];
}

const AddUserModal: React.FC<AddUserModalProps> = ({
  intl,
  onClose,
  isOpen,
  onSubmit,
  closeConfirmationModal,
  validator,
  isBusy,
  createError,
}) => {
  return (
    <Modal onClose={onClose} isOpen={isOpen}>
      <ModalHeader>
        <ModalTitle>{intl.messages[`${COMPONENT_INTL_KEY}.AddUserForm.Title`]}</ModalTitle>
      </ModalHeader>
      <ModalContent>
        <AddUserForm
          intl={intl}
          onSubmit={onSubmit}
          onClose={onClose}
          closeConfirmationModal={closeConfirmationModal}
          validator={validator}
          isBusy={isBusy}
          createError={createError}
        />
      </ModalContent>
      <ModalFooter borderTop="xs" borderColor="palette.snow_light">
        <Flex>
          <Icon mr="8px" name="far fa-question-circle" fontSize="16px" />
          <Text color="palette.grey" fontSize="13" lineHeight={1.54}>
            {intl.messages[`${COMPONENT_INTL_KEY}.AddUserForm.Info`]}
          </Text>
        </Flex>
      </ModalFooter>
    </Modal>
  );
};

export default injectIntl<any>(AddUserModal);

import { Alert, AlertDescription, AlertIcon, AlertTitle, Box, Button, Flex, FormControl, FormLabel, Icon, Modal, ModalContent, ModalFooter, ModalHeader, Select, Text } from '@oplog/express';
import useSupportOrdersState from '@store/supportPage/SupportSalesOrdersState';
import React, { ReactElement, useEffect, useState } from 'react';
import { Props } from '../../atoms/Component/Component';

export interface CorrectInvalidSalesOrdersLineItemNotFoundModalProps extends Props {
    onClose: () => void;
    hasError: any;
    responseData: any;
    COMPONENT_INTL_KEY: string;
    addQueueMessage: () => void;
}

export const CorrectInvalidSalesOrdersLineItemNotFoundModal: React.FC<CorrectInvalidSalesOrdersLineItemNotFoundModalProps> = ({
    onClose,
    COMPONENT_INTL_KEY,
    addQueueMessage,
    intl,
    isBusy,
    onDidMount,
    onWillUnmount,
}): ReactElement => {
    const [state, actions] = useSupportOrdersState();
    const [modalState, setModalState] = useState({
        confirmationAlertOpen: false,
        confirmationModalOpen: true,
    });

    React.useEffect(() => {
        onDidMount && onDidMount();
    }, []);

    const onSubmit = () => {
        addQueueMessage();
        setModalState({
            ...modalState,
            confirmationModalOpen: false,
            confirmationAlertOpen: true,
        });
    };

    const closeModal = () => {
        onWillUnmount && onWillUnmount();
        actions.clearSyncModal();
        onClose();
    };

    const closeConfirmationDialog = () => {
        setModalState({
            ...modalState,
            confirmationModalOpen: false,
            confirmationAlertOpen: false
        });
        closeModal();
    };

    return (
        <Box>
            <Modal isOpen={modalState.confirmationModalOpen} onClose={closeConfirmationDialog}>
                <ModalHeader>{intl.messages[`${COMPONENT_INTL_KEY}.ConfirmationTitle`]}</ModalHeader>

                <ModalContent>
                    <Flex flexDirection="column" flexWrap="wrap">
                        <FormControl size="large" m="16">
                            <p>{intl.messages[`${COMPONENT_INTL_KEY}.ConfirmationMessage`]}</p>
                        </FormControl>
                    </Flex>
                    <br />
                    <Flex justifyContent="center" width="100%" px="13px">
                        <Button
                            className="cancel"
                            onClick={closeConfirmationDialog}
                            kind="outline"
                            variant="dark"
                            width={1}
                            size="small"
                        >
                            {intl.messages[`Modal.GoBack`]}
                        </Button>
                        <Button
                            ml="6"
                            disabled={false}
                            className="save"
                            onClick={onSubmit}
                            width={1}
                            size="small"
                            variant="success"
                        >
                            {intl.messages[`${COMPONENT_INTL_KEY}.ApproveButton`]}
                        </Button>
                    </Flex>
                </ModalContent>
            </Modal>

            <Modal isOpen={modalState.confirmationAlertOpen && !isBusy} onClose={closeConfirmationDialog}>
                <ModalContent>
                    <Flex flexDirection="column" flexWrap="wrap">
                        <FormControl size="large" m="16">
                            <FormLabel>{intl.messages[`${COMPONENT_INTL_KEY}.DataSent`]}</FormLabel>
                            <Alert variant={'primary'} isOpen={true}>
                                <AlertIcon name="fas fa-exclamation-triangle" />
                                <AlertTitle>{intl.messages[`${COMPONENT_INTL_KEY}.Alert.Success`]}</AlertTitle>
                                <AlertDescription>{intl.messages[`${COMPONENT_INTL_KEY}.Alert.Message`]}</AlertDescription>
                            </Alert>
                        </FormControl>
                    </Flex>
                    <br />
                    <Flex justifyContent="center" width="100%" px="13px">
                        <Button
                            ml="6"
                            disabled={false}
                            className="save"
                            onClick={closeConfirmationDialog}
                            width={1}
                            size="small"
                            variant="success"
                        >
                            {intl.messages[`${COMPONENT_INTL_KEY}.Alert.ApproveButton`]}
                        </Button>
                    </Flex>
                </ModalContent>
            </Modal>
        </Box>

    );
};

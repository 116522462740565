import { Config } from './config.model';

const env = process.env.REACT_APP_ENV === undefined ? 'development' : process.env.REACT_APP_ENV;

export const config: Partial<Config> = {
  env,
  isDev: env === 'development',
  isStaging: env === 'staging',
  isProduction: env === 'production',
  isTest: process.env.NODE_ENV === 'test',
  i18n: {
    default: 'tr-TR',
    locales: ['tr-TR', 'en-US'],
  },
};

import ImagePreviewer from '@components/atoms/ImagePreviewer/ImagePreviewer';
import {
  Box,
  Checkbox,
  DrawerTitle,
  Flex,
  Icon,
  Input,
  InputGroup,
  InputRightAddon,
  Popover,
  SelectHTML,
  Text,
  Image,
  Datepicker,
  DATE_FORMAT_NO_TIME,
  DATE_FORMAT,
  Select,
} from '@oplog/express';
import { FileUploader } from '@oplog/file-uploader';
import { CargoCarriersApiDTO, CargoType, SalesChannelsDTO, TenantDetailsDTO } from '@services';
import useCommonDataStore, { OTHER_SALES_CHANNEL_KEY } from '@store/commonData/commonDataState';
import * as React from 'react';
import { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { Props } from '../../atoms/Component/Component';
import {API_DATE_FORMAT} from "@oplog/express/dist/utils/DateTime/datetime";

export interface OrderDeliveryInfoProps extends Props {
  intlKey: string;
  onChangeOrderPaymentInfoFields: (fields: object) => void;
  setPaymentInfoFields: OrderDeliveryInfoFields;
  onFileSelect: (file: File) => void;
  fileUrl: string;
  onFileReset: () => void;
  isUploading: boolean;
  tenantDetails: TenantDetailsDTO;
  expectedShipmentDate?: Date;
}

export interface OrderDeliveryInfoFields {
  salesChannel?: string;
  otherSalesChannel?: string;
  cargoCompany: string | undefined;
  cargoCode: string | undefined;
  cargoType: CargoType | undefined;
  cargoAttachmentUrl: string;
  differentCargoCompanyChoice: boolean;
  expectedShipmentDate?: Date;
}

export const OrderDeliveryInfo: React.FC<OrderDeliveryInfoProps> = ({
  intl,
  setPaymentInfoFields,
  isBusy,
  intlKey,
  onFileSelect,
  fileUrl,
  onFileReset,
  isUploading,
  onChangeOrderPaymentInfoFields,
  tenantDetails,
  expectedShipmentDate
}: OrderDeliveryInfoProps) => {
  const [isFileUploadCompleted, setIsFileUploadCompleted] = useState(false);
  const [fileUploadHasError, setFileUploadHasError] = useState(false);
  const [showCargoOptions, setShowCargoOptions] = useState(false);

  const [{ cargoCarriers, salesChannels }, { clearState }] = useCommonDataStore();

  const [fields, setFields] = useState<OrderDeliveryInfoFields>({
    cargoAttachmentUrl: '',
    cargoCode: '',
    cargoCompany: '',
    cargoType: undefined,
    differentCargoCompanyChoice: true,
    salesChannel: '',
    otherSalesChannel: '',
  });

  const onUpload = () => { };

  React.useEffect(() => {
    if (fileUrl !== undefined && fileUrl !== '' && fileUrl !== fields.cargoAttachmentUrl) {
      setIsFileUploadCompleted(true);
      setFields({
        ...fields,
        cargoAttachmentUrl: fileUrl,
      });
    }
  }, [fileUrl]);

  const handleCargoCheckboxChange = (e: any) => {
    e.currentTarget.checked
      ? setFields({
        ...fields,
        differentCargoCompanyChoice: e.currentTarget.checked !== undefined ? e.currentTarget.checked : false,
      })
      : setFields({
        ...fields,
        differentCargoCompanyChoice: e.currentTarget.checked !== undefined ? e.currentTarget.checked : false,
        cargoAttachmentUrl: '',
        cargoCode: '',
        cargoCompany: '',
      });
  };

  const onFileSelected = (file: File) => {
    if (file && file.type === 'application/pdf') {
      onFileSelect(file);
      setFileUploadHasError(false);
    } else {
      setFileUploadHasError(true);
    }
  };

  const handleOnSelectedCargoCompanyChange = (e: any) => {
    setFields({
      ...fields,
      cargoCompany: e.currentTarget.value,
    });
  };

  const handleOnSelectedCargoTypeChange = (e: any) => {
    setFields({
      ...fields,
      cargoType: e.currentTarget.value,
    });
    setShowCargoOptions(true);
    if (e.currentTarget.value === CargoType.SameDayDelivery) {
      setFields({
        ...fields,
        cargoType: e.currentTarget.value,
        cargoAttachmentUrl: '',
        cargoCode: '',
        cargoCompany: '',
        differentCargoCompanyChoice: false,
      });
      setShowCargoOptions(false);
    }

    if (e.currentTarget.value === CargoType.ManualDelivery) {
      setFields({
        ...fields,
        cargoType: e.currentTarget.value,
        cargoAttachmentUrl: '',
        cargoCode: '',
        cargoCompany: '',
        differentCargoCompanyChoice: false,
      });
      setShowCargoOptions(false);
    }
  };

  const handleOnCargoCodeChange = (e: any) => {
    setFields({
      ...fields,
      cargoCode: e.currentTarget.value,
    });
  };

  const retrieveCargoCarrierOptions = () => {
    return cargoCarriers.map((cargoCarrierOption: CargoCarriersApiDTO) => {
      return {
        label: cargoCarrierOption.value,
        value: cargoCarrierOption.key,
      };
    });
  };

  const handleDateTimeChange = (date: Date) => {
    setFields({
      ...fields,
      expectedShipmentDate: new Date(new Date(date).setHours(date.getHours() + 3))
    });
  };

  const retrieveCargoTypeOptions = () => {
    if (tenantDetails.isSameDayDeliveryActive) {
      return Object.keys(CargoType).map((cargoTypeOption: CargoType) => {
        return {
          label: intl.messages[`${intlKey}.OrderCargoTypeOptios.${cargoTypeOption}`],
          value: cargoTypeOption,
        };
      });
    } else {
      const notSameDayDelivery = { ManualDelivery: CargoType.ManualDelivery, RegularDelivery: CargoType.RegularDelivery, ScheduledDelivery: CargoType.ScheduledDelivery };
      return Object.keys(notSameDayDelivery).map((cargoTypeOption) => {
        return {
          label: intl.messages[`${intlKey}.OrderCargoTypeOptios.${cargoTypeOption}`],
          value: cargoTypeOption,
        };
      });
    }
  };

  const retrieveSalesChannelOptions = () => {
    const salesChannelOptions: any = [];

    salesChannels.map((salesChannel: SalesChannelsDTO) => {
      if (salesChannel.key === OTHER_SALES_CHANNEL_KEY) {
        salesChannelOptions.push({
          label: intl.messages['Enum.Other'],
          value: salesChannel.key,
        });
      } else {
        salesChannelOptions.push({
          label: salesChannel.value,
          value: salesChannel.key,
        });
      }
    });

    return salesChannelOptions;
  };

  const salesChannelsOptions = retrieveSalesChannelOptions();

  useEffect(() => {
    setFields({
      ...fields,
      salesChannel: setPaymentInfoFields.salesChannel || '',
      cargoCompany: setPaymentInfoFields.cargoCompany || '',
      cargoAttachmentUrl: setPaymentInfoFields.cargoAttachmentUrl || '',
      cargoCode: setPaymentInfoFields.cargoCode || '',
      differentCargoCompanyChoice: setPaymentInfoFields.differentCargoCompanyChoice || true,
      otherSalesChannel: setPaymentInfoFields.otherSalesChannel || '',
    });
  }, []);

  React.useEffect(() => {
    onChangeOrderPaymentInfoFields(fields);
  }, [fields]);

  React.useEffect(() => {
    if (fields.salesChannel != '') {
      if (fields.salesChannel !== OTHER_SALES_CHANNEL_KEY) {
        setFields({
          ...fields,
          differentCargoCompanyChoice: true,
          cargoType: undefined,
          expectedShipmentDate: undefined
        });
      } else {
        if (!hasCargoValues()) {
          setFields({
            ...fields,
            differentCargoCompanyChoice: false,
          });
        }
      }

      setShowCargoOptions(true);
    }
  }, [fields.salesChannel]);

  const hasCargoValues = () => {
    return fields.cargoCompany !== '' || fields.cargoAttachmentUrl !== '' || fields.cargoCode !== '';
  };

  const addDays = (date: Date, days: number) => {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  };

  const datePicker = {
    dateFormat: DATE_FORMAT_NO_TIME,
    inputFormat: DATE_FORMAT_NO_TIME,
    dateFormatWithTime: DATE_FORMAT
  };

  const [selectedDate, setSelectedDate] = useState(new Date());

  const handleDateTimeChangeManualDelivery = (date: Date) => {
    setSelectedDate(date);
    setFields({
      ...fields,
      expectedShipmentDate: date
    });
  };

  const isWeekday = (date: Date) => {
    const day = date.getDay();
    return day !== 0 && day !== 6; // Exclude Sunday (0) and Saturday (6)
  };

  const getMinDate = () => {
    const date = new Date()

    if (date.getDay() == 5) {
      return addDays(new Date(), 3)
    } else if (date.getDay() == 6 || date.getHours() >= 18) {
      return addDays(new Date(), 2)
    } else {
      return addDays(new Date(), 1)
    }
  };

  const getMinTime = () => {
    const currentDate = new Date();

    if (
        selectedDate &&
        selectedDate.getDate() <= currentDate.getDate() + 1 &&
        selectedDate.getMonth() === currentDate.getMonth() &&
        selectedDate.getFullYear() === currentDate.getFullYear()
    ) {
      // If it's tomorrow, set the minimum time to the current hour
      return currentDate;
    }

    // For any other day, set the minimum time to 08:00
    const minTime = new Date(currentDate);
    minTime.setDate(minTime.getDate() + 1);
    minTime.setHours(8, 0, 0, 0);
    return minTime;
  };

  const getMaxTime = () => {
    const currentDate = new Date();
    currentDate.setHours(18, 0, 0, 0);
    return currentDate;
  };

  useEffect(() => {
    setSelectedDate(getMinDate);
  }, []);

  return isBusy ? (
    <Skeleton />
  ) : (
    <>
      <DrawerTitle size="medium" boxShadow="small">
        {intl.messages[`${intlKey}.Header.EnterPaymentInfo`]}
      </DrawerTitle>
      <Box px="22">
        <DrawerTitle pt="16" pl="0" size="small">
          {intl.messages[`${intlKey}.PaymentInfoFormHeaders.SalesChannel`]}
        </DrawerTitle>
        <Select
          placeholder={intl.messages[`${intlKey}.OrderInfoFormHeaders.SalesChannelPlaceHolder`]}
          value={fields.salesChannel}
          options={salesChannelsOptions}
          onChange={(e: any) => {
            setFields({
              ...fields,
              salesChannel: e.currentTarget.value,
            });
          }}
        />
        {fields.salesChannel === OTHER_SALES_CHANNEL_KEY && (
          <Box display="flex" flexDirection="column" mt="22" mb="11">
            <DrawerTitle pt="0" pl="0" size="small">
              {intl.messages[`${intlKey}.PaymentInfoFormHeaders.OtherSalesChannel`]}
            </DrawerTitle>
            <Input
              value={fields.otherSalesChannel}
              onChange={(e: any) => {
                setFields({
                  ...fields,
                  otherSalesChannel: e.currentTarget.value,
                });
              }}
            />
          </Box>
        )}

        {fields.salesChannel === OTHER_SALES_CHANNEL_KEY && (
          <Box mt="22">
            <Text
              mb="11"
              fontFamily="heading"
              color="palette.steel"
              fontWeight={800}
              fontSize="13"
              letterSpacing="negativeSmall"
            >
              {intl.messages[`${intlKey}.OrderInfoFormHeaders.CargoType`]}
            </Text>
            <Flex mt="11">
              <Select
                placeholder={intl.messages[`${intlKey}.OrderInfoFormHeaders.CargoType`]}
                value={fields.cargoType}
                onChange={(e: any) => handleOnSelectedCargoTypeChange(e)}
                options={retrieveCargoTypeOptions()}
              />
            </Flex>
          </Box>
        )}

        {showCargoOptions && fields.salesChannel === OTHER_SALES_CHANNEL_KEY && fields.cargoType === CargoType.ScheduledDelivery && (
          <Box mt="22">
            <Text
              mb="11"
              fontFamily="heading"
              color="palette.steel"
              fontWeight={800}
              fontSize="13"
              letterSpacing="negativeSmall"
            >
              {intl.messages[`${intlKey}.OrderInfoFormHeaders.ExpectedShipmentDate`]}
            </Text>
            <Flex mt="11">
              <Datepicker
                id="date-field"
                popperPlacement="top-start"
                dateFormat={datePicker.dateFormat}
                value={expectedShipmentDate?.toString()}
                selected={expectedShipmentDate}
                onChange={handleDateTimeChange}
                inputFormat={datePicker.inputFormat}
                minDate={new Date().getHours() >= 16 ? addDays(new Date(), 2) : addDays(new Date(), 1)}
                showTimeSelect={false}
                showDisabledMonthNavigation
              />
            </Flex>
          </Box>
        )}

        {fields.salesChannel === OTHER_SALES_CHANNEL_KEY && fields.cargoType === CargoType.ManualDelivery && (
            <Box mt="22">
              <Text
                  mb="11"
                  fontFamily="heading"
                  color="palette.steel"
                  fontWeight={800}
                  fontSize="13"
                  letterSpacing="negativeSmall"
              >
                {intl.messages[`${intlKey}.OrderInfoFormHeaders.ExpectedShipmentDate`]}
              </Text>
              <Flex mt="11">
                <Datepicker
                    id="date-field"
                    popperPlacement="top-start"
                    dateFormat="yyyy-MM-dd HH:mm"
                    value={expectedShipmentDate?.toString()}
                    selected={expectedShipmentDate}
                    onChange={handleDateTimeChangeManualDelivery}
                    inputFormat={datePicker.dateFormatWithTime}
                    minDate={getMinDate()} 
                    maxDate={addDays(new Date(), 365)}
                    minTime={getMinTime()}
                    maxTime={getMaxTime()}
                    showTimeSelect={true}
                    showDisabledMonthNavigation
                    filterDate={isWeekday}
                />
              </Flex>
            </Box>
        )}

        {showCargoOptions && fields.cargoType !== CargoType.SameDayDelivery && (
          <Box mt="22">
            <Text
              mb="11"
              fontFamily="heading"
              color="palette.steel"
              fontWeight={800}
              fontSize="13"
              letterSpacing="negativeSmall"
            >
              {intl.messages[`${intlKey}.OrderInfoFormHeaders.CargoCompanyChoice`]}
            </Text>
            <Flex mt="11">
              <Checkbox
                id="differentCargoCompanyChoiceCheckbox"
                isChecked={fields.differentCargoCompanyChoice}
                onChange={handleCargoCheckboxChange}
                isDisabled={fields.salesChannel !== OTHER_SALES_CHANNEL_KEY}
                cursor={fields.salesChannel === OTHER_SALES_CHANNEL_KEY ? 'pointer' : 'default'}
              >
                {intl.messages[`${intlKey}.DeliveryInfoDifferentCargoLabel`]}
              </Checkbox>
            </Flex>
          </Box>
        )}

        {fields.differentCargoCompanyChoice && showCargoOptions && (
          <Box mt="22">
            <Box mb="22" display="flex" flexDirection="column">
              <DrawerTitle pt="0" pl="0" size="small">
                {intl.messages[`${intlKey}.OrderInfoFormHeaders.CargoCompany`]}
              </DrawerTitle>
              <SelectHTML
                placeholder={intl.messages[`${intlKey}.OrderInfoFormHeaders.Placeholder`]}
                value={fields.cargoCompany}
                onChange={(e: any) => handleOnSelectedCargoCompanyChange(e)}
                options={retrieveCargoCarrierOptions()}
              />
            </Box>
            {fields.cargoType !== CargoType.SameDayDelivery && (
              <>
                <Box mb="22" display="flex" flexDirection="column">
                  <DrawerTitle pt="0" pl="0" size="small">
                    <>
                      {intl.messages[`${intlKey}.OrderInfoFormHeaders.CargoLabel`]}
                      <Popover
                        isDark
                        content={
                          <ImagePreviewer
                            image="/images/sample/CargoDocument.png"
                            text={intl.messages[`${intlKey}.OrderInfoFormHeaders.CargoLabelInfo`]}
                          />
                        }
                        placement="bottom"
                        action={['hover']}
                      >
                        <Image src="/images/information_icon.png" width="20px" marginLeft="5px" marginTop="-5px" />
                      </Popover>
                    </>
                  </DrawerTitle>
                  <Box>
                    <InputGroup>
                      <InputRightAddon
                        as="label"
                        htmlFor={fields.cargoCode ? '' : 'cargoLabelFile'}
                        cursor={fields.cargoCode ? 'not-allowed' : 'pointer'}
                        bg={fields.cargoAttachmentUrl === '' ? 'palette.grey' : 'palette.teal'}
                        disabled
                      >
                        <Text fontWeight={800} color="palette.white" fontFamily="heading" fontSize="13">
                          {fields.cargoAttachmentUrl && (
                            <Icon name="fas fa-check" fontSize="13" mr="11" color="palette.white" />
                          )}
                          {
                            intl.messages[
                            `${intlKey}.OrderInfoFormUploadButtons.${fields.cargoAttachmentUrl ? 'Uploaded' : 'Upload'
                            }`
                            ]
                          }
                        </Text>
                      </InputRightAddon>
                      <Input
                        value={fields.cargoAttachmentUrl}
                        isDisabled={!!fields.cargoCode}
                        placeholder={intl.messages[`${intlKey}.OrderInfoFormPlaceholders.UploadCargoLabel`]}
                      />
                    </InputGroup>
                  </Box>
                  {fileUploadHasError && (
                    <Text mt="10px" color="red">
                      {intl.messages[`${intlKey}.OrderInfoFormPlaceholders.FileUploadHasError`]}
                    </Text>
                  )}
                  <Box display="none">
                    <FileUploader
                      id="cargoLabelFile"
                      labelTextKey="Upload"
                      onComplete={onUpload}
                      resetTimeout={1500}
                      intl={intl}
                      isDisabled
                      isBusy={true || isUploading}
                      isCompleted={isFileUploadCompleted}
                      onReset={onFileReset}
                      url={fields.cargoAttachmentUrl}
                      onFileSelect={onFileSelected}
                      fileType="application/pdf"
                    />
                  </Box>
                </Box>
                <Box>
                  <DrawerTitle pt="0" pl="0" size="small">
                    <>
                      {intl.messages[`${intlKey}.OrderInfoFormHeaders.CargoCode`]}
                      <Popover
                        isDark
                        content={<Flex>{intl.messages[`${intlKey}.OrderInfoFormHeaders.CargoCodeInfo`]}</Flex>}
                        placement="bottom"
                        action={['hover']}
                      >
                        <Image src="/images/information_icon.png" width="20px" marginLeft="5px" marginTop="-5px" />
                      </Popover>
                    </>
                  </DrawerTitle>
                  <Input
                    placeholder={intl.messages[`${intlKey}.OrderInfoFormPlaceholders.EnterCargoCode`]}
                    onChange={(e: any) => handleOnCargoCodeChange(e)}
                    value={fields.cargoCode}
                    isDisabled={!!fields.cargoAttachmentUrl}
                  />
                </Box>
              </>
            )}
          </Box>
        )}
      </Box>
    </>
  );
};

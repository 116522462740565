import { CreateCustomerFormContainer } from '@containers/CreateCustomerFormContainer';
import { MemberPageContainer } from '@containers/MemberPageContainer/MemberPageContainer';
import { ActionBar, LayoutContent } from '@oplog/express';
import { urls } from '@routes/urls';
import * as React from 'react';
import { useEffect } from 'react';
import { Props } from '../../atoms/Component/Component';

const COMPONENT_INTL_KEY = 'CreateCustomer';

export const CreateCustomer: React.FC<Props> = ({ intl, onWillUnmount }) => {
  useEffect(() => {
    return () => {
      onWillUnmount && onWillUnmount();
    };
  }, []);

  const breadcrumb = [
    { title: intl.messages[`Customers.Header.Title`], url: urls.customers },
    { title: intl.messages[`${COMPONENT_INTL_KEY}.Title`] },
  ];

  return (
    <MemberPageContainer documentTitle={intl.messages[`${COMPONENT_INTL_KEY}.Title`]}>
      <ActionBar top="66px" title={intl.messages[`${COMPONENT_INTL_KEY}.Title`]} breadcrumb={breadcrumb} />
      <LayoutContent>
        <CreateCustomerFormContainer />
      </LayoutContent>
    </MemberPageContainer>
  );
};

import { CreateReturnResource } from '@store/state';
import { SendToCreateReturnCartGrid } from '@models';
import {
  CREATE_RETURN_FORM_EDITED,
  CREATE_RETURN_GRID_EDITED,
  CREATE_RETURN_LINE_ITEMS_REMOVED,
  CREATE_RETURN_LINE_ITEM_ADDED,
  CREATE_RETURN_SALES_ORDER_SELECTED,
  CREATE_RETURN_SALES_ORDER_REMOVED,
} from './constants';
import { CreateReturnAction } from './actions';

export function createReturnReducer(
  state: CreateReturnResource<Map<string, SendToCreateReturnCartGrid>>,
  action: CreateReturnAction
): CreateReturnResource<Map<string, SendToCreateReturnCartGrid>> {
  switch (action.type) {
    case CREATE_RETURN_LINE_ITEM_ADDED:
      const newData: SendToCreateReturnCartGrid = action.payload.createReturn;
      if (state.data === undefined) {
        return state;
      }
      const populatedData: Map<string, SendToCreateReturnCartGrid> = new Map();

      state.data.forEach((value: SendToCreateReturnCartGrid, key: string) => {
        populatedData.set(key, value);
      });

      if (newData.returnAmount > 0) {
        // Add a new data to the map or
        // update existing data if amountToSend is greater than 0
        populatedData.set(newData.id, newData);
      } else {
        // Remove new data from the map if amount is dropped to 0.
        populatedData.delete(newData.id);
      }

      return {
        salesOrder: state.salesOrder,
        isBusy: false,
        data: populatedData,
      };
    case CREATE_RETURN_LINE_ITEMS_REMOVED:
      return {
        ...state,
        isBusy: false,
        data: new Map<string, SendToCreateReturnCartGrid>(),
      };
    case CREATE_RETURN_FORM_EDITED:
      return {
        ...state,
        isBusy: false,
      };
    case CREATE_RETURN_GRID_EDITED:
      return {
        ...state,
        isBusy: false,
      };
    case CREATE_RETURN_SALES_ORDER_SELECTED:
      return {
        ...state,
        isBusy: false,
        salesOrder: action.payload.salesOrder,
      };
    case CREATE_RETURN_SALES_ORDER_REMOVED:
      return {
        ...state,
        isBusy: false,
        salesOrder: undefined,
      };
    default:
      return state;
  }
}

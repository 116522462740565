import { Box, Icon } from '@oplog/express';
import useCreateSalesOrderState, { CreateSalesOrderStateKeys } from '@store/createSalesOrder/CreateSalesOrderState';
import React from 'react';

export interface CreateSalesOrderCartGridDeleteButtonProps {
  sku: string;
}

export const CreateSalesOrderCartGridDeleteButton: React.FC<CreateSalesOrderCartGridDeleteButtonProps> = ({
  sku,
}: CreateSalesOrderCartGridDeleteButtonProps) => {
  const [state, actions] = useCreateSalesOrderState();

  const onDelete = () => {
    const gridSplit = state.gridData.filter((item: any) => item.sku !== sku);
    const panelSplit = state.panelData.filter((item: any) => item.sku !== sku);

    actions.setStateData(CreateSalesOrderStateKeys.gridData, gridSplit);
    actions.setStateData(CreateSalesOrderStateKeys.panelData, panelSplit);
  };

  return (
    <Box width={1}>
      <Icon cursor="pointer" onClick={() => onDelete()} name="fa fa-times" />
    </Box>
  );
};

import {
  Button,
  Dialog,
  DialogTypes,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  FormValidator,
  Input,
  Modal,
  ModalContent,
  ModalHeader,
  ModalTitle,
} from '@oplog/express';
import { CompanyDTO, UpdateCompanyCommand } from '@services';
import { phoneValidation } from '@utils';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Props } from '@components/atoms/Component/Component';
import useForceUpdate from 'use-force-update';
import * as Yup from 'yup';

const COMPONENT_INTL_KEY = 'Settings.ChangeCompanyInfoModal';

export interface ChangeCompanyInfoModalProps extends Props {
  isOpen: boolean;
  validator: FormValidator;
  onClose: (refresh?: boolean) => void;
  fields: Partial<CompanyDTO>;
  isSuccessful: boolean;
  onSubmit: (form: UpdateCompanyCommand) => void;
}

export interface ChangeCompanyInfoForm {
  title: string;
  address: string;
  phone: string;
  taxAdministration: string;
  taxNo: string;
}

export const ChangeCompanyInfoModal: React.FC<ChangeCompanyInfoModalProps> = ({
  intl,
  fields,
  validator,
  onSubmit,
  isSuccessful,
  isBusy,
  onClose,
}) => {
  const [form, setForm] = useState({
    title: fields.title || '',
    address: fields.address || '',
    phone: fields.phone || '',
    taxAdministration: fields.taxAdministration || '',
    taxNo: fields.taxNo || '',
  });

  const [tempField, setTempField] = useState('');

  const [confirmationModal, setConfirmationModal] = useState(false);
  const forceUpdate = useForceUpdate();
  const fieldKeys = ['title', 'address', 'phone', 'taxAdministration', 'taxNo'];

  const changeCompanyInfoSchema = {
    title: Yup.string().required(`${intl.messages[`${COMPONENT_INTL_KEY}.Form.Errors.Title`]}`),
    address: Yup.string().required(`${intl.messages[`${COMPONENT_INTL_KEY}.Form.Errors.Address`]}`),
    phone: phoneValidation(
      `${intl.messages[`${COMPONENT_INTL_KEY}.Form.Errors.Phone`]}`,
      `${intl.messages[`${COMPONENT_INTL_KEY}.Form.Errors.PhoneLength`]}`,
      `${intl.messages[`${COMPONENT_INTL_KEY}.Form.Errors.PhoneType`]}`
    ),
    taxAdministration: Yup.string().required(`${intl.messages[`${COMPONENT_INTL_KEY}.Form.Errors.TaxAdministration`]}`),
    taxNo: Yup.number()
      .typeError(`${intl.messages[`${COMPONENT_INTL_KEY}.Form.Errors.TaxNoType`]}`)
      .required(`${intl.messages[`${COMPONENT_INTL_KEY}.Form.Errors.TaxNo`]}`),
  };

  const prepareValidation = () => {
    validator.registerSchema(changeCompanyInfoSchema);
    fieldKeys.forEach(key => {
      validator.validate(key, form[key]);
    });
  };

  useEffect(() => {
    prepareValidation(); // This is preferred in edit forms. Because there may be data entries by user from past that the user needs to fix.
  }, []); // ComponentDidMount

  useEffect(() => {
    if (tempField) {
      validator.validate(tempField, form[tempField]);
      forceUpdate();
    }
  }, [form]);

  const handleFocus = (fieldname: keyof ChangeCompanyInfoForm) => {
    setTempField(fieldname);
  };

  const handleInputChange = (fieldname: keyof ChangeCompanyInfoForm, e: React.SyntheticEvent<HTMLInputElement>) => {
    setForm({ ...form, [fieldname]: e.currentTarget.value });
  };

  const formSubmit = (e: React.SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (validator.hasErrors()) return;
    setConfirmationModal(true);
  };

  const submitCommand = () => {
    onSubmit(form);
  };

  return (
    <Modal size="md" isOpen onClose={() => onClose(false)}>
      <ModalHeader>
        <ModalTitle>{intl.messages[`${COMPONENT_INTL_KEY}.Title`]}</ModalTitle>
      </ModalHeader>
      <form onSubmit={formSubmit}>
        <ModalContent>
          <FormControl size="small" isInvalid={!!validator.getErrorIntent('title')} mb="11">
            <FormLabel>{intl.messages[`${COMPONENT_INTL_KEY}.Form.Labels.Title`]}*</FormLabel>
            <Input
              value={form.title}
              placeholder={`${intl.messages[`${COMPONENT_INTL_KEY}.Form.Placeholders.Title`]}`}
              onChange={(e: React.SyntheticEvent<HTMLInputElement>) => handleInputChange('title', e)}
              onFocus={() => handleFocus('title')}
            />
            <FormErrorMessage>{validator.getErrorIntent('title')?.text}</FormErrorMessage>
          </FormControl>
          <FormControl size="small" isInvalid={!!validator.getErrorIntent('address')} mb="11">
            <FormLabel>{intl.messages[`${COMPONENT_INTL_KEY}.Form.Labels.Address`]}*</FormLabel>
            <Input
              value={form.address}
              placeholder={`${intl.messages[`${COMPONENT_INTL_KEY}.Form.Placeholders.Address`]}`}
              onFocus={() => handleFocus('address')}
              onChange={(e: React.SyntheticEvent<HTMLInputElement>) => handleInputChange('address', e)}
            />
            <FormErrorMessage>{validator.getErrorIntent('address')?.text}</FormErrorMessage>
          </FormControl>
          <FormControl size="small" isInvalid={!!validator.getErrorIntent('phone')} mb="11">
            <FormLabel>{intl.messages[`${COMPONENT_INTL_KEY}.Form.Labels.Phone`]}*</FormLabel>
            <Input
              value={form.phone}
              placeholder={`${intl.messages[`${COMPONENT_INTL_KEY}.Form.Placeholders.Phone`]}`}
              onFocus={() => handleFocus('phone')}
              onChange={(e: React.SyntheticEvent<HTMLInputElement>) => handleInputChange('phone', e)}
            />
            <FormErrorMessage>{validator.getErrorIntent('phone')?.text}</FormErrorMessage>
          </FormControl>
          <FormControl size="small" isInvalid={!!validator.getErrorIntent('taxAdministration')} mb="11">
            <FormLabel>{intl.messages[`${COMPONENT_INTL_KEY}.Form.Labels.TaxAdministration`]}*</FormLabel>
            <Input
              value={form.taxAdministration}
              placeholder={`${intl.messages[`${COMPONENT_INTL_KEY}.Form.Placeholders.TaxAdministration`]}`}
              onFocus={() => handleFocus('taxAdministration')}
              onChange={(e: React.SyntheticEvent<HTMLInputElement>) => handleInputChange('taxAdministration', e)}
            />
            <FormErrorMessage>{validator.getErrorIntent('taxAdministration')?.text}</FormErrorMessage>
          </FormControl>
          <FormControl size="small" isInvalid={!!validator.getErrorIntent('taxNo')} mb="11">
            <FormLabel>{intl.messages[`${COMPONENT_INTL_KEY}.Form.Labels.TaxNo`]}*</FormLabel>
            <Input
              value={form.taxNo}
              placeholder={`${intl.messages[`${COMPONENT_INTL_KEY}.Form.Placeholders.TaxNo`]}`}
              onFocus={() => handleFocus('taxNo')}
              onChange={(e: React.SyntheticEvent<HTMLInputElement>) => handleInputChange('taxNo', e)}
            />
            <FormErrorMessage>{validator.getErrorIntent('taxNo')?.text}</FormErrorMessage>
          </FormControl>
          <Flex mt="22">
            <Button
              size="small"
              width={1 / 3}
              kind="outline"
              type="button"
              variant="dark"
              disabled={isBusy}
              onClick={() => onClose()}
              mr="11"
            >
              {intl.messages['Form.Action.Cancel']}
            </Button>
            <Button
              size="small"
              width={2 / 3}
              variant="success"
              disabled={isBusy || validator.hasErrors()}
              isLoading={isBusy}
              type="submit"
            >
              {intl.messages['Form.Action.Save']}
            </Button>
          </Flex>
        </ModalContent>
      </form>
      {confirmationModal && (
        <Dialog
          isLoading={isBusy}
          onCancel={() => setConfirmationModal(false)}
          onApprove={submitCommand}
          isOpen
          type={DialogTypes.Warning}
          text={{
            approve: intl.messages[`Modal.Warning.Okay`],
            cancel: intl.messages[`Modal.Warning.Cancel`],
          }}
          message={
            <FormattedMessage
              id={`${COMPONENT_INTL_KEY}.ConfirmationDialog`}
              defaultMessage={intl.messages[`${COMPONENT_INTL_KEY}.ConfirmationDialog`]}
            />
          }
        />
      )}
      {isSuccessful && (
        <Dialog
          type={DialogTypes.Success}
          message={
            <FormattedMessage
              id={`${COMPONENT_INTL_KEY}.SuccesDialog`}
              defaultMessage={intl.messages[`${COMPONENT_INTL_KEY}.SuccesDialog`]}
            />
          }
          isOpen={isSuccessful}
          onCancel={() => onClose(true)}
          onApprove={() => onClose(true)}
          text={{
            approve: intl.messages[`Modal.Success.Okay`],
          }}
        />
      )}
    </Modal>
  );
};

import { ResourceType } from '@models';
import { validation } from '@oplog/express';
import { resourceActions, resourceSelectors } from '@oplog/resource-redux';
import { UpdateCustomerInfoCommand } from '@services';
import { StoreState } from '@store/state';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Dispatch } from 'redux';
import {
  EditCustomerInfoForm,
  EditCustomerInfoFormProps,
} from '../../components/molecules/EditCustomerInfoForm/EditCustomerInfoForm';

export interface EditCustomerInfoFormContainerProps extends RouteComponentProps {
  onModalClose: () => void;
  customerNo: number;
  onSuccessClose: () => void;
}

function mapStateToProps({ resources }: StoreState): Partial<EditCustomerInfoFormProps> {
  return {
    commandIsBusy: resourceSelectors.isBusy(resources, ResourceType.UpdateCustomerInfo),
    userInfo: resources[ResourceType.CustomerInfo],
    isCompleted: resourceSelectors.isSuccessComplete(resources, ResourceType.UpdateCustomerInfo),
  };
}

function mapDispatchToProps(
  dispatch: Dispatch<resourceActions.ResourceAction>,
  ownProps: EditCustomerInfoFormContainerProps
) {
  return {
    initResources: (): void => {
      dispatch(resourceActions.resourceInit(ResourceType.CustomerInfo));
      dispatch(resourceActions.resourceInit(ResourceType.UpdateCustomerInfo));
    },
    onDidMount: (): void => {
      dispatch(resourceActions.resourceRequested(ResourceType.CustomerInfo, { customerNo: ownProps.customerNo }));
    },
    onSubmit(form: UpdateCustomerInfoCommand) {
      const customerInfo: UpdateCustomerInfoCommand = {
        ...form,
      };
      dispatch(resourceActions.resourceRequested(ResourceType.UpdateCustomerInfo, { customerInfo }));
    },
  };
}

export const EditCustomerInfoFormContainer = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(validation(injectIntl(EditCustomerInfoForm)))
);

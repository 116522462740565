import { resourceActions } from '@oplog/resource-redux';
import { Inventory, InventoryProps } from '@pages/Inventory';
import { onTabChange } from '@utils';
import { RouterAction } from 'connected-react-router';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Dispatch } from 'redux';

function mapDispatchToProps(
  dispatch: Dispatch<resourceActions.ResourceAction | RouterAction>,
  routeProps: RouteComponentProps<any>
): Partial<InventoryProps> {
  return {
    goTab: tab => {
      onTabChange(tab, routeProps, dispatch);
    },
  };
}

export const InventoryContainer = connect(null, mapDispatchToProps)(injectIntl(Inventory));

import { GridContainer } from '@components/atoms/GridContainer';
import { bubbleStepFormatter, geti18nName, SalesOrderDetailsLinkFormatter } from '@containers/formatters';
import { GridProps, GridType } from '@models';
import {
  ColumnSize,
  DataGridColumn,
  DataGridRow,
  dateTimeFormatter,
  ellipsisFormatter,
  getEnumOptions,
  PredefinedFilter,
} from '@oplog/data-grid';
import { SalesOrderState } from '@services';
import { checkShippingAddress } from '@utils';
import { SortDirection, SortField, StringFilter, StringFilterOperation } from 'dynamic-query-builder-client';
import * as React from 'react';

const intlKey = 'CustomerSalesOrdersGrid';

export interface CustomerSalesOrdersProps extends GridProps {
  customerNo: string;
}

const customerSalesOrderGridInitalSort: SortField = new SortField({
  property: 'orderCreatedAt',
  by: SortDirection.DESC,
});

const customerSalesOrderGridPredefinedFilters: Array<PredefinedFilter> = [
  {
    filter: new StringFilter({
      id: 'Undelivered',
      property: 'state',
      op: StringFilterOperation.NotEqual,
      value: SalesOrderState.Delivered.toString(),
    }),
    selected: false,
    visibility: true,
  },
];

export class CustomerSalesOrdersGrid extends React.Component<CustomerSalesOrdersProps> {
  render() {
    const { intl, titleKey } = this.props;
    const customerSalesOrdersGridColumns: Array<DataGridColumn> = [
      {
        name: '#',
        key: 'index',
        resizable: true,
        locked: true,
        sortable: false,
        type: 'number',
        visibility: true,
        filterable: false,
        width: ColumnSize.Medium,
        cellClass: 'index-column',
      },
      {
        name: geti18nName('referenceNumber', intl, intlKey),
        key: 'referenceNumber',
        locked: true,
        resizable: true,
        sortable: true,
        type: 'string',
        visibility: true,
        filterable: true,
        formatter: SalesOrderDetailsLinkFormatter,
        getRowMetaData: (row: DataGridRow) => row,
      },
      {
        name: geti18nName('state', intl, intlKey),
        key: 'state',
        locked: true,
        resizable: true,
        sortable: true,
        type: 'enum',
        visibility: true,
        filterable: true,
        formatter: bubbleStepFormatter,
        options: getEnumOptions(intl, SalesOrderState),
        getRowMetaData: (row: DataGridRow) => {
          return {
            intl,
            enum: SalesOrderState,
            row,
          };
        },
      },
      {
        name: geti18nName('shippingAddress', intl, intlKey),
        key: 'shippingAddress',
        locked: true,
        resizable: true,
        sortable: true,
        type: 'string',
        visibility: true,
        filterable: true,
        formatter: row => {
          const modRow = { ...row };
          modRow.value = checkShippingAddress(row.value);
          return ellipsisFormatter(modRow);
        },
        getRowMetaData: (row: DataGridRow) => row,
      },
      {
        name: geti18nName('uniqueProductCount', intl, intlKey),
        key: 'uniqueProductCount',
        resizable: true,
        locked: true,
        sortable: true,
        type: 'number',
        visibility: true,
        filterable: true,
      },
      {
        name: geti18nName('totalProductCount', intl, intlKey),
        key: 'totalProductCount',
        resizable: true,
        locked: true,
        sortable: true,
        type: 'number',
        visibility: true,
        filterable: true,
      },
      {
        name: geti18nName('orderCreatedAt', intl, intlKey),
        key: 'orderCreatedAt',
        resizable: true,
        locked: true,
        sortable: true,
        type: 'moment',
        visibility: true,
        filterable: true,
        formatter: dateTimeFormatter,
      },
    ];

    return (
      <GridContainer
        titleKey={
          titleKey
            ? `${intl.messages['CustomerSalesOrdersGrid.Grid.Title']} - ${titleKey}`
            : intl.messages['CustomerSalesOrdersGrid.Grid.Title']
        }
        intlKey={intlKey}
        remoteExportToExcel
        gridKey={GridType.CustomerSalesOrders}
        columns={customerSalesOrdersGridColumns}
        predefinedFilters={customerSalesOrderGridPredefinedFilters}
        apiArgs={[this.props.customerNo]}
        intl={intl}
        sortField={customerSalesOrderGridInitalSort}
      />
    );
  }
}

import { ErrorPanel as Panel } from '@oplog/express';
import * as React from 'react';
import { injectIntl } from 'react-intl';

const ErrorPanel = ({ ...props }: any) => {
  return (
    <Panel
      title={props.intl.messages['ErrorPanel.ErrorMessage']}
      message={props.intl.messages['ErrorPanel.ReloadMessage']}
      {...props}
    />
  );
};

export const GeneralErrorPanel = injectIntl(ErrorPanel);

import React from 'react';
import globalHook, { Store } from 'use-global-hook';

interface CommonDataStore {
  tenants: any;
  integrations: any;
  cargoCarriers: any; //CargoCarriersApiDTO;
  salesChannels: any; //SalesChannelsDTO;
}

interface CommonDataActions {
  clearState: (data: Partial<CommonDataStore>) => void;
  setTenants: (tenants: any) => void;
  setIntegrations: (integrations: any) => void;
  setCargoCarriers: (cargoCarriers: any) => void;
  setSalesChannels: (salesChannels: any) => void;
}

/* InitalStates */
export const initialCommonState: CommonDataStore = {
  tenants: undefined,
  integrations: undefined,
  cargoCarriers: undefined,
  salesChannels: undefined,
};

export const OTHER_SALES_CHANNEL_KEY = 'Other';

/* Actions */
export const commonActions: object = {
  clearState: (store: Store<CommonDataStore, CommonDataActions>, data: Partial<CommonDataStore>) => {
    store.setState({ ...store.state, ...data });
  },
  setTenants: (store: Store<CommonDataStore, CommonDataActions>, tenants: any) => {
    store.setState({ ...store.state, tenants: tenants });
  },
  setIntegrations: (store: Store<CommonDataStore, CommonDataActions>, integrations: any) => {
    store.setState({ ...store.state, integrations: integrations });
  },
  setCargoCarriers: (store: Store<CommonDataStore, CommonDataActions>, cargoCarriers: any) => {
    store.setState({ ...store.state, cargoCarriers: cargoCarriers });
  },
  setSalesChannels: (store: Store<CommonDataStore, CommonDataActions>, salesChannels: any) => {
    store.setState({ ...store.state, salesChannels: salesChannels });
  },
};

/* Export */
const useCommonDataStore = globalHook<CommonDataStore, CommonDataActions>(React, initialCommonState, commonActions);

export default useCommonDataStore;

import { ProductGrid } from '@components';
import { FileUploader } from '@components/molecules/FileUploader/FileUploader';
import { ProductFormModal } from '@components/molecules/ProductFormModal/ProductFormModal';
import { ProductQuarantineGrid } from '@components/organisms/ProductQuarantineGrid';
import { MemberPageContainer } from '@containers/MemberPageContainer/MemberPageContainer';
import {
  ActionBar,
  Box,
  Button,
  Dialog,
  DialogProps,
  DialogTypes,
  FormValidator,
  LayoutContent,
  Modal,
  ModalContent,
  ModalHeader,
  ModalTitle,
  Panel,
  Popover,
  Tab,
  Text
} from '@oplog/express';
import { CreateProductCommand, DimensionsDTO, PCSDimensionsDTO } from '@services';
import useCommonDataStore from '@store/commonData/commonDataState';
import * as React from 'react';
import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { tracking } from '../../../utils/analytics';
import { Props } from '../../atoms/Component/Component';

const COMPONENT_INTL_KEY = 'ProductCatalog';

export interface ProductCatalogProps extends Props {
  fileUrl: string;
  validator: FormValidator;
  isCreateSuccessfull: boolean;
  isUploading: boolean;
  onExcelUploadClose: () => void;
  onRefresh: () => void;
  onModalClose: (id: string) => void;
  onReset: () => void;
  onFileSelect: (file: File) => void;
  onCreate: (form: CreateProductCommand) => void;
  goTab: (tab: string) => void;
  integrations: any;
}

export interface ProductCatalogState {
  createModal: boolean;
  isCreateSuccessfulModal: boolean;
  sku: string;
  excelUploadModalOpen: boolean;
  isExcelUploadSuccess: boolean;
}

enum ProductCatalogTabs {
  Products = 'Products',
  ProductsQuarantine = 'ProductsQuarantine',
}

export const ProductCatalog: React.FC<ProductCatalogProps> = ({
  intl,
  fileUrl,
  onReset,
  isBusy,
  validator,
  onFileSelect,
  onModalClose,
  isUploading,
  onCreate,
  onExcelUploadClose,
  onRefresh,
  error,
  isCreateSuccessfull,
  goTab,
  onWillUnmount,
}) => {
  const [createModal, setCreateModal] = React.useState(false);
  const [isCreateSuccessfulModal, setIsCreateSuccessfulModal] = React.useState(false);
  const [sku, setSku] = React.useState('');
  const [excelUploadModalOpen, setExcelUploadModalOpen] = React.useState(false);
  const [isExcelUploadSuccess, setIsExcelUploadSuccess] = React.useState(false);
  const [currentTab, setCurrentTab] = React.useState(0);

  const [{ integrations }, { setIntegrations }] = useCommonDataStore();

  const COMPONENT_ID_KEY = 'productcatalog';

  useEffect(() => {
    return () => {
      onWillUnmount && onWillUnmount();
    };
  }, []);

  const onSubmit = (form: CreateProductCommand) => {
    if(form.sioc !== null && form.sioc){
      const dimensions: PCSDimensionsDTO = {
        height: form.height,
        width: form.width,
        weight: form.weight,
        length: form.length,
        unitOfWeight: form.unitOfWeight?.toLocaleLowerCase(),
        unitOfDimension: form.unitOfDimension?.toLocaleLowerCase()
      }

      form.dimensions = dimensions;
    }
    setSku(form.sku);
    onCreate(form);
    tracking.emitEvent('ProductCatalog', 'CreateProductSubmit');
  };

  const setExcelUploadStatusToSuccess = () => {
    setIsExcelUploadSuccess(true);
    tracking.emitEvent('ProductCatalog', 'ExcelUploadSucess');
  };

  const changeExcelUploadModalOpen = () => {
    const eventName = excelUploadModalOpen ? 'ExcelUploadModalClose' : 'ExcelUploadModalOpen';
    tracking.emitEvent('ProductCatalog', eventName);
    setExcelUploadModalOpen(!excelUploadModalOpen);
  };

  useEffect(() => {
    if (!excelUploadModalOpen && isExcelUploadSuccess) {
      setIsExcelUploadSuccess(false);
      onExcelUploadClose();
    }
  }, [excelUploadModalOpen]);

  useEffect(() => {
    if (isCreateSuccessfull) {
      setCreateModal(false);
      setIsCreateSuccessfulModal(true);
    }
  }, [isCreateSuccessfull]);

  const breadcrumb = [
    {
      title: intl.messages[`${COMPONENT_INTL_KEY}.Header.Title`],
    },
  ];

  const modalProps = {
    fileUrl,
    intl,
    isBusy,
    isUploading,
    onSubmit: onSubmit,
    onClose: () => {
      onRefresh();
      setCreateModal(false);
      tracking.emitEvent('ProductCatalog', 'CreateProductModalClose');
    },
    onFileSelect,
    onReset,
    validator,
    isEdit: false,
  };

  const dialogProps: DialogProps = {
    type: DialogTypes.Success,
    message: <FormattedMessage id={`${COMPONENT_INTL_KEY}.SuccessModal`} />,
    text: { approve: intl.messages[`Modal.Success.Okay`] },
    showCloseButton: false,
    isOpen: true,
    onApprove: () => {
      tracking.emitEvent('ProductCatalog', 'CreateProductSuccess');
      onModalClose(sku);
    },
  };

  return (
    <MemberPageContainer id="product-catalog" documentTitle={intl.messages[`${COMPONENT_INTL_KEY}.Header.Title`]}>
      <ActionBar
        top="66px"
        title={intl.messages[`${COMPONENT_INTL_KEY}.Header.Title`]}
        subtitle={intl.messages[`${COMPONENT_INTL_KEY}.Header.Subtitle`]}
        breadcrumb={breadcrumb}
      >
        <Button
          marginLeft="auto"
          variant="dark"
          size="large"
          data-cy={`${COMPONENT_ID_KEY}-button-actionbutton`}
          dropdown={{
            items: [
              {
                text:
                  intl.messages[
                    `${COMPONENT_INTL_KEY}.Header.ActionButtons.AddProductButtonOptions.AddProductManually`
                  ],
                value:
                  intl.messages[
                    `${COMPONENT_INTL_KEY}.Header.ActionButtons.AddProductButtonOptions.AddProductManually`
                  ],
                onClick: () => {
                  setCreateModal(true);
                  tracking.emitEvent('ProductCatalog', 'CreateProductModalOpen');
                },
              },
              {
                text:
                  intl.messages[`${COMPONENT_INTL_KEY}.Header.ActionButtons.AddProductButtonOptions.AddProductByExcel`],
                value:
                  intl.messages[`${COMPONENT_INTL_KEY}.Header.ActionButtons.AddProductButtonOptions.AddProductByExcel`],
                onClick: () => changeExcelUploadModalOpen(),
              },
            ],
          }}
        >
          {intl.messages[`${COMPONENT_INTL_KEY}.Header.ActionButtons.Add`]}
        </Button>
      </ActionBar>
      <LayoutContent>
        <Panel>
          <Box>
            <Tab
              onTabChange={data => {
                setCurrentTab(data);
                goTab(Object.values(ProductCatalogTabs)[data]);
              }}
              activeIndex={currentTab}
              tabs={[
                {
                  id: ProductCatalogTabs.Products,
                  title: (
                    <Popover
                      content={
                        <Text
                          fontFamily="Lato"
                          fontSize="16">
                          {intl.messages[`${COMPONENT_INTL_KEY}.Grid.TitleMessage`]}
                        </Text>
                      }
                      isDark
                      withPortal
                      placement="top"
                      action={['hover']}>
                      <Text>
                        {intl.messages[`${COMPONENT_INTL_KEY}.Grid.Title`]}
                      </Text>
                    </Popover>),
                  component: <ProductGrid intl={intl} integrations={integrations} />,
                },
                {
                  id: ProductCatalogTabs.ProductsQuarantine,
                  title: (
                    <Popover
                      content={
                        <Text
                          fontFamily="Lato"
                          fontSize="16">
                          {intl.messages[`${COMPONENT_INTL_KEY}.Grid.QuarantineMessage`]}
                        </Text>
                      }
                      isDark
                      withPortal
                      placement="top"
                      action={['hover']}>
                      <Text>
                        {intl.messages[`${COMPONENT_INTL_KEY}.Grid.Quarantine`]}
                      </Text>
                    </Popover>),
                  component: <ProductQuarantineGrid intl={intl} integrations={integrations} />,
                },
              ]}
              data-cy={`${COMPONENT_ID_KEY}-tab-gridtabs`}
            />
          </Box>
        </Panel>
      </LayoutContent>
      {excelUploadModalOpen && (
        <Modal
          onClose={changeExcelUploadModalOpen}
          isOpen={excelUploadModalOpen}
          size="lg"
          disableEscapeButtonClose={true}
          disableOutsideMouseEvents={true}
          data-cy={`${COMPONENT_ID_KEY}-modal-excelupload`}
        >
          <ModalHeader>
            <ModalTitle>
              {intl.messages[`${COMPONENT_INTL_KEY}.Header.ActionButtons.AddProductButtonOptions.AddProductByExcel`]}
            </ModalTitle>
          </ModalHeader>
          <ModalContent>
            <FileUploader
              intl={intl}
              uploadFileType={COMPONENT_INTL_KEY}
              apiUrlExtension="/api/Products.importFromExcel"
              fileType=".xls,.xlsx"
              onClose={changeExcelUploadModalOpen}
              onSuccess={setExcelUploadStatusToSuccess}
              data-cy={`${COMPONENT_ID_KEY}-fileuploader-importproduct`}
            />
          </ModalContent>
        </Modal>
      )}
      {createModal && (
        <ProductFormModal {...modalProps} error={error} data-cy={`${COMPONENT_ID_KEY}-component-productformmodal`} />
      )}
      {isCreateSuccessfulModal && (
        <Dialog {...dialogProps} data-cy={`${COMPONENT_ID_KEY}-modal-isCreateSuccessfulModal`} />
      )}
    </MemberPageContainer>
  );
};

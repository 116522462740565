import { DrawerTitle, Widget, WidgetNineProps, Icon } from '@oplog/express';
import { SalesOrderQueryOutputDTO } from '@services';
import * as React from 'react';
import Component, { Props } from '../../atoms/Component/Component';

export interface SalesOrderSummaryProps extends Props {
  intlKey: string;
  salesOrderInfo: SalesOrderQueryOutputDTO;
  onCloseClick: () => void;
  isOrderSelected: boolean;
}

export class SalesOrderSummary extends Component<SalesOrderSummaryProps, {}> {
  renderInformationCard() {
    const { intl, intlKey, salesOrderInfo, onCloseClick, isOrderSelected } = this.props;

    if (salesOrderInfo === undefined) {
      if (!isOrderSelected) {
        const cardProps: WidgetNineProps = {
          text: intl.messages[`${intlKey}.ContinueWithoutProduct`],
          icon: {
            name: 'fal fa-truck-loading',
            fontSize: '44px',
          },
          onCloseClick,
        };

        return <Widget.Nine {...cardProps} />;
      }

      return (
        <div className="empty-sales-order">
          <Icon name="fas fa-truck-loading" />
          {intl.messages[`${intlKey}.EmptySalesOrder`]}
        </div>
      );
    }

    const informationCardProps: WidgetNineProps = {
      title: salesOrderInfo ? `#${salesOrderInfo.referenceNumber}` : '',
      text: salesOrderInfo ? salesOrderInfo.customerFullName : '',
      subText: salesOrderInfo ? salesOrderInfo.shippingAddressCity : intl.messages[`${intlKey}.EmptySalesOrder`],
      icon: {
        name: 'fal fa-truck-loading',
        fontSize: '44px',
      },
      onCloseClick,
    };

    return (
      <>
        <div className="ui clearing info-header">{intl.messages[`${intlKey}.SalesOrderSummary`]}</div>
        <Widget.Nine {...informationCardProps} />
      </>
    );
  }

  render() {
    const { intl, intlKey } = this.props;

    return (
      <>
        <DrawerTitle>{intl.messages[`${intlKey}.Header.ChooseSalesOrder`]}</DrawerTitle>
        <div className="sales-order-summary">{this.renderInformationCard()}</div>
      </>
    );
  }
}

import { ColumnSize, DataGridColumn, DataGridRow } from '@oplog/data-grid';
import { geti18nName, SalesOrderDetailsLinkFormatter } from '../../../../containers/formatters';

export const getSupportSalesOrdersSyncColumns = (intl: any, intlKey: any): DataGridColumn[] => {
  const SupportSalesOrdersColumns: Array<DataGridColumn> = [
    {
      name: '#',
      key: 'index',
      resizable: false,
      locked: true,
      sortable: false,
      type: 'number',
      visibility: true,
      filterable: false,
      cellClass: 'index-column',
      width: ColumnSize.Medium,
    },
    {
      name: geti18nName('id', intl, intlKey),
      key: 'id',
      resizable: true,
      locked: true,
      sortable: true,
      type: 'string',
      visibility: true,
      filterable: true,
    },
    {
      name: geti18nName('referenceNumber', intl, intlKey),
      key: 'referenceNumber',
      resizable: true,
      locked: true,
      sortable: true,
      type: 'string',
      visibility: true,
      filterable: true,
      formatter: SalesOrderDetailsLinkFormatter,
      getRowMetaData: (row: DataGridRow) => row,
    },
    {
      name: geti18nName('wmsSyncState', intl, intlKey),
      key: 'wmsSyncState',
      resizable: true,
      locked: true,
      sortable: true,
      type: 'string',
      visibility: true,
      filterable: true,
    },
    {
      name: geti18nName('wmsSyncRetryCount', intl, intlKey),
      key: 'wmsSyncRetryCount',
      resizable: true,
      locked: true,
      sortable: true,
      type: 'number',
      visibility: true,
      filterable: true,
    },
    {
      name: geti18nName('salesOrderFulfillmentSyncState', intl, intlKey),
      key: 'salesOrderFulfillmentSyncState',
      resizable: true,
      locked: true,
      sortable: true,
      type: 'string',
      visibility: true,
      filterable: true,
    },
    {
      name: geti18nName('salesOrderFulfillmentSyncRetryCount', intl, intlKey),
      key: 'salesOrderFulfillmentSyncRetryCount',
      resizable: true,
      locked: true,
      sortable: true,
      type: 'number',
      visibility: true,
      filterable: true,
    },
  ];
  return SupportSalesOrdersColumns;
};

export default getSupportSalesOrdersSyncColumns;

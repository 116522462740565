import { Box, Flex, Image, Tooltip } from '@oplog/express';
import { IntegrationType, OpenApiIdentifier, ProductType, ReturnSource } from '@services';
import * as React from 'react';
import { InjectedIntl } from 'react-intl';

export function getIntegrationImageSrc(type?: ProductType | IntegrationType | ReturnSource | OpenApiIdentifier | string | null | 'N/A') {
  const integrationImageFolderPath = '/images/integrations';
  if (!type || type === 'N/A') {
    return `${integrationImageFolderPath}/ban.svg`;
  }
  return `${integrationImageFolderPath}/${type.toString().toLowerCase()}.png`;
}

export const sourceIconFormatter = (content: string, type: any, intl: InjectedIntl) => {
  var altText: string;
  altText = content;
  var imageSrc;
  const { integrationType, openApiIdentifier } = type

  if (integrationType && openApiIdentifier) {
    if (openApiIdentifier !== OpenApiIdentifier.None) {
      imageSrc = getIntegrationImageSrc(openApiIdentifier)
    }
    else {
      imageSrc = getIntegrationImageSrc(integrationType)
    }
  }
  else {
    imageSrc = getIntegrationImageSrc(type)
  }

  return (
    <>
      <Box height="28px" width="28px" mr="8" mb="5px">
        <Tooltip
          placement="right"
          withPortal
          content={<Flex>{altText || intl.messages['Integration.NoIntegration']}</Flex>}
        >
          <Image src={imageSrc} alt={altText || intl.messages['Integration.NoIntegration']} />
        </Tooltip>
      </Box>
    </>
  );
};

export function getCargoCarrierImageSrc(cargoCarrier?: string | null | 'N/A') {
  const cargoCarrierImageFolderPath = '/images/cargoCarrierLogos';
  if (!cargoCarrier || cargoCarrier === 'N/A') {
    return `/images/integrations/ban.svg`;
  }
  return `${cargoCarrierImageFolderPath}/${cargoCarrier.toString().toLocaleLowerCase()}.png`;
}

/* eslint-disable @typescript-eslint/interface-name-prefix */

import { IntegrationType } from '@services';
import React from 'react';
import globalHook, { Store } from 'use-global-hook';

export enum IntegrationState {
  CreationType = 'creationType',
  CreationSuccess = 'creationSuccess',
  TSoft = 'tSoft',
}

export enum IntegrationKeys {
  Step = 'step',
  Id = 'id',
  Integration = 'integration',
}

export enum IntegrationSteps {
  Integration = 1,
  Configuration = 2,
}

/* Interfaces */
export interface IntegrationsState {
  creationType?: IntegrationType;
  creationSuccess: boolean;
  tSoft: {
    step: number;
    id: string;
    integration: any;
  };
  ticimax: {
    step: number;
    id: string;
    integration: any;
  };
}

export interface IntegrationsActions {
  clearState: (data: Partial<IntegrationsState>) => void;
  setTsoftData: (key: IntegrationKeys, data: any) => void;
  setTicimaxData: (key: IntegrationKeys, data: any) => void;
  setStateData: (key: IntegrationState, data: any) => void;
  clearTsoftData: () => void;
  clearTicimaxData: () => void;
}

/* InitalStates */
export const initalState: IntegrationsState = {
  creationSuccess: false,
  creationType: undefined,
  tSoft: {
    step: IntegrationSteps.Integration,
    id: '',
    integration: {},
  },
  ticimax: {
    step: IntegrationSteps.Integration,
    id: '',
    integration: {},
  },
};

/* Actions */
export const initalActions: object = {
  clearState: (store: Store<IntegrationsState, IntegrationsActions>, data: Partial<IntegrationsState>) => {
    store.setState({ ...store.state, ...data });
  },
  clearTsoftData: (store: Store<IntegrationsState, IntegrationsActions>) => {
    store.setState({
      ...store.state,
      tSoft: { ...initalState.tSoft },
    });
  },
  clearTicimaxData: (store: Store<IntegrationsState, IntegrationsActions>) => {
    store.setState({
      ...store.state,
      ticimax: { ...initalState.ticimax },
    });
  },

  setTsoftData: (store: Store<IntegrationsState, IntegrationsActions>, key: IntegrationKeys, data: any) => {
    const tsoftData = {
      ...store.state.tSoft,
      [key]: data,
    };
    store.setState({ ...store.state, tSoft: tsoftData });
  },
  setTicimaxData: (store: Store<IntegrationsState, IntegrationsActions>, key: IntegrationKeys, data: any) => {
    const ticimaxData = {
      ...store.state.ticimax,
      [key]: data,
    };
    store.setState({ ...store.state, ticimax: ticimaxData });
  },

  setStateData: (store: Store<IntegrationsState, IntegrationsActions>, key: IntegrationState, data: any) => {
    store.setState({ ...store.state, [key]: data });
  },
};

/* Export */
const useIntegrationsState = globalHook<IntegrationsState, IntegrationsActions>(React, initalState, initalActions);

export default useIntegrationsState;

import { Flex, Icon, Modal, ModalContent, ModalFooter, ModalHeader, Text } from '@oplog/express';
import { Resource } from '@oplog/resource-redux';
import { IntegrationOutputDTO } from '@services';
import useCommonDataStore from '@store/commonData/commonDataState';
import useSupportOrdersState from '@store/supportPage/SupportSalesOrdersState';
import React, { ReactElement } from 'react';
import { Props } from '../../atoms/Component/Component';
import DateAndIntegrationSelection from './bones/DateAndIntegrationSelection';
import DateAndIntegrationSelectionConfirmation from './bones/DateAndIntegrationSelectionConfirmation';

export interface SyncUnfulfilledAndCancelledIntegrationsModalProps extends Props {
  onClose: () => void;
  hasError: any;
  integrations: Resource<Array<IntegrationOutputDTO>>;
  responseData: any;
  COMPONENT_INTL_KEY: string;
  syncCommandTimeRange: (integrationId: String, startDate: Date, endDate: Date) => void;
}

export interface SyncUnfulfilledAndCancelledIntegrationsModalState {}

export const SyncUnfulfilledAndCancelledIntegrationsModal: React.FC<SyncUnfulfilledAndCancelledIntegrationsModalProps> = ({
  onClose,
  COMPONENT_INTL_KEY,
  syncCommandTimeRange,
  intl,
  isBusy,
  onDidMount,
  responseData,
  hasError,
  onWillUnmount,
}): ReactElement => {
  const [state, actions] = useSupportOrdersState();
  const [step, setStep] = React.useState(0);
  const { integration, endDate, startDate } = state.syncModal;
  const [{ integrations }, { setIntegrations }] = useCommonDataStore();

  React.useEffect(() => {
    onDidMount && onDidMount();
  }, []);

  const onSubmit = () => {
    if (startDate && endDate) {
      const integrationID = integration.split('/')[0];
      syncCommandTimeRange(integrationID, startDate, endDate);
      setStep(1);
    }
  };

  const closeModal = () => {
    onWillUnmount && onWillUnmount();
    actions.clearSyncModal();
    onClose();
  };

  var filtered = integrations && integrations.filter((item: any) => item.syncable == true && item.state === 'Active');
  var options: any = [];
  if (filtered) {
    var options = filtered.map((integration: any) => {
      return { value: integration.id + '/' + integration.name, label: integration.name };
    });
  }

  return (
    <Modal onClose={closeModal} isOpen={true}>
      <ModalHeader>{intl.messages[`${COMPONENT_INTL_KEY}.Title`]}</ModalHeader>

      <ModalContent>
        {step == 0 && !isBusy && (
          <DateAndIntegrationSelection
            options={options}
            COMPONENT_INTL_KEY={COMPONENT_INTL_KEY}
            intl={intl}
            onClose={closeModal}
            onSubmit={onSubmit}
          ></DateAndIntegrationSelection>
        )}
        {step == 1 && responseData && !isBusy && (
          <DateAndIntegrationSelectionConfirmation
            options={options}
            COMPONENT_INTL_KEY={COMPONENT_INTL_KEY}
            intl={intl}
            responseData={responseData}
            onClose={closeModal}
          ></DateAndIntegrationSelectionConfirmation>
        )}
        {isBusy && <Text>{intl.messages[`${COMPONENT_INTL_KEY}.Loading`]}</Text>}
        {hasError && <Text>{intl.messages[`${COMPONENT_INTL_KEY}.ErrorCodes.UnHandledError`]}</Text>}
      </ModalContent>
      <ModalFooter borderTop="xs" borderColor="palette.snow_light">
        <Flex>
          <Icon mr="8px" name="far fa-question-circle" fontSize="21px" />
          <Text color="palette.grey" fontSize="13" lineHeight={1.54}>
            {intl.messages[`${COMPONENT_INTL_KEY}.Note`]}
          </Text>
        </Flex>
      </ModalFooter>
    </Modal>
  );
};

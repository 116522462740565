import { Props } from '@components/atoms/Component/Component';
import { ResourceType } from '@models';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogTypes,
  Flex,
  Modal,
  ModalContent,
  ModalHeader,
  ModalTitle,
  Text,
} from '@oplog/express';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ExcelExportFilterErrorDialog } from '../ExcelExportFilterErrorDialog';

interface ExcelExportModalProps extends Props {
  excelToExport: (resourceType: ResourceType) => void;
  onModalClose: () => void;
  excelToExportInit: () => void;
  excelExportType: ExportTypes;
  excelExportResource: any;
  excelExportDetailedResource: any;
  rowCount: number;
  defaultExportLimit: number;
  detailedExportLimit: number;
}

export enum ExportTypes {
  SalesOrder = 'SalesOrder',
  SalesOrderBlocked = 'SalesOrderBlocked',
  Returns = 'Returns',
  ReceivingOrders = 'ReceivingOrders',
}

export const ExcelExportModal: React.FC<ExcelExportModalProps> = ({
  intl,
  excelToExport,
  onModalClose,
  excelToExportInit,
  excelExportType,
  excelExportResource,
  excelExportDetailedResource,
  rowCount,
  defaultExportLimit,
  detailedExportLimit,
}) => {
  const [excelExportModalCheckBoxes, setExcelExportModalCheckBoxes] = React.useState({
    defaultExport: false,
    detailedExport: false,
  });

  const [filterErrorDialogOpen, setIsFilterErrorDialogOpen] = React.useState(false);

  const COMPONENT_INTL_KEY = 'ExcelUploadModal';

  const onDefaultExportChange = (e: any) => {
    setExcelExportModalCheckBoxes({
      defaultExport: e.currentTarget.checked,
      detailedExport: e.currentTarget.checked ? false : excelExportModalCheckBoxes.detailedExport,
    });
  };

  const onDetailedExportChange = (e: any) => {
    setExcelExportModalCheckBoxes({
      detailedExport: e.currentTarget.checked,
      defaultExport: e.currentTarget.checked ? false : excelExportModalCheckBoxes.defaultExport,
    });
  };

  const isExcelUploadDisabled = (): boolean => {
    if (!excelExportModalCheckBoxes.defaultExport && !excelExportModalCheckBoxes.detailedExport) {
      return true;
    }

    return false;
  };

  const onButtonClick = () => {
    if (excelExportModalCheckBoxes.defaultExport == true) {
      if (rowCount > defaultExportLimit) {
        setIsFilterErrorDialogOpen(true);
        return;
      }
      excelToExport(getDefaultExportType(excelExportType));
    } else {
      if (rowCount > detailedExportLimit) {
        setIsFilterErrorDialogOpen(true);
        return;
      }
      excelToExport(getDetailedExportType(excelExportType));
    }
  };

  const dialogClose = () => {
    onModalClose();
    excelToExportInit();
  };

  const getDefaultExportType = (type: ExportTypes): any => {
    switch (type) {
      case ExportTypes.SalesOrder:
        return ResourceType.SalesOrderExcelExport;
      case ExportTypes.SalesOrderBlocked:
        return ResourceType.PendingSalesOrderExcelExport;
      case ExportTypes.Returns:
        return ResourceType.ReturnsExcelExport;
      case ExportTypes.ReceivingOrders:
        return ResourceType.ReceivingOrdersExcelExport;

      default:
        return undefined; // Case only added if there is no type, which is impossible.
    }
  };

  const getDetailedExportType = (type: ExportTypes): any => {
    switch (type) {
      case ExportTypes.SalesOrder:
        return ResourceType.SalesOrderExcelDetailedExport;
      case ExportTypes.SalesOrderBlocked:
        return ResourceType.PendingSalesOrderExcelDetailedExport;
      case ExportTypes.Returns:
        return ResourceType.ReturnsExcelDetailedExport;
      case ExportTypes.ReceivingOrders:
        return ResourceType.ReceivingOrdersDetailedExcelExport;

      default:
        return undefined; // Case only added if there is no type, which is impossible.
    }
  };

  return (
    <Box>
      <Modal
        onClose={() => {
          onModalClose();
        }}
        isOpen={true}
        size="xl"
        disableOutsideMouseEvents={true}
        disableEscapeButtonClose={true}
      >
        <ModalHeader>
          <ModalTitle>{intl.messages[`${COMPONENT_INTL_KEY}.Header.${excelExportType}`]}</ModalTitle>
        </ModalHeader>
        <ModalContent>
          <Flex flexDirection="column" justifyContent="center" alignItems="center">
            <Text>{intl.messages[`${COMPONENT_INTL_KEY}.HelpText.${excelExportType}`]}</Text>

            <Box mt="44">
              <Flex flexDirection="column">
                <Checkbox
                  isDisabled={false}
                  isFullWidth={false}
                  isChecked={excelExportModalCheckBoxes.defaultExport}
                  onChange={onDefaultExportChange}
                >
                  {intl.messages[`${COMPONENT_INTL_KEY}.DefaultExport.${excelExportType}`]}
                </Checkbox>

                <Checkbox
                  isDisabled={false}
                  isFullWidth={false}
                  isChecked={excelExportModalCheckBoxes.detailedExport}
                  onChange={onDetailedExportChange}
                  mt="11"
                >
                  {intl.messages[`${COMPONENT_INTL_KEY}.DetailedExport.${excelExportType}`]}
                </Checkbox>
              </Flex>
            </Box>

            <Button
              type="button"
              disabled={isExcelUploadDisabled()}
              isLoading={
                (excelExportResource && excelExportResource.isBusy) ||
                (excelExportDetailedResource && excelExportDetailedResource.isBusy)
              }
              onClick={() => onButtonClick()}
              kind="solid"
              variant="success"
              width={1}
              size="small"
              mt="44"
            >
              {intl.messages[`${COMPONENT_INTL_KEY}.ButtonText.${excelExportType}`]}
            </Button>
          </Flex>
        </ModalContent>
      </Modal>

      {((excelExportResource && excelExportResource.isSuccess) ||
        (excelExportDetailedResource && excelExportDetailedResource.isSuccess)) && (
        <Dialog
          isLoading={false}
          onApprove={dialogClose}
          showCloseButton={false}
          isOpen
          type={DialogTypes.Success}
          text={{
            approve: intl.messages[`Modal.Success.Okay`],
          }}
          message={
            <FormattedMessage
              id={intl.messages[`${COMPONENT_INTL_KEY}.AlertSuccess.${excelExportType}`]}
              defaultMessage={intl.messages[`${COMPONENT_INTL_KEY}.AlertSuccess.${excelExportType}`]}
            />
          }
        />
      )}

      {((excelExportResource && excelExportResource.error) ||
        (excelExportDetailedResource && excelExportDetailedResource.error)) && (
        <Dialog
          isLoading={false}
          onApprove={dialogClose}
          showCloseButton={false}
          isOpen
          type={DialogTypes.Danger}
          text={{
            approve: intl.messages[`Modal.Success.Okay`],
          }}
          message={
            <FormattedMessage
              id={intl.messages[`${COMPONENT_INTL_KEY}.AlertSuccess.${excelExportType}`]}
              defaultMessage={intl.messages[`${COMPONENT_INTL_KEY}.AlertFail.${excelExportType}`]}
            />
          }
        />
      )}

      {filterErrorDialogOpen && (
        <ExcelExportFilterErrorDialog
          isOpen={filterErrorDialogOpen}
          intl={intl}
          closeDialog={() => {
            setIsFilterErrorDialogOpen(false);
            dialogClose();
          }}
          rowCount={rowCount}
          exportLimit={excelExportModalCheckBoxes.defaultExport ? defaultExportLimit : detailedExportLimit}
          excelExportType={excelExportType}
          isDetailed={excelExportModalCheckBoxes.detailedExport}
        />
      )}
    </Box>
  );
};

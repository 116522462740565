import ImagePreviewer from '@components/atoms/ImagePreviewer/ImagePreviewer';
import {
  Box,
  DrawerTitle,
  Flex,
  FormControl,
  FormErrorMessage,
  Icon,
  Input,
  InputGroup,
  InputRightAddon,
  InputSelect,
  Popover,
  SelectHTML,
  Text,
  Image,
  ImageViewer,
} from '@oplog/express';
import { FileUploader } from '@oplog/file-uploader';
import { Currency, CustomerInfoOutputDTO, PaymentOption } from '@services';
import * as React from 'react';
import { useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { Props } from '../../atoms/Component/Component';

export const validateRefNo = (value: string | undefined) => {
  const regex = /^[a-zA-Z0-9-]{1,30}$/;
  let valid = true;
  if (value) {
    valid = regex.test(value);
  }

  if (valid && value) {
    const regex2 = /^.*?(?=-)/;
    let valida = regex2.exec(value.substring(0, 6));
    valid = valida?.input.toLowerCase() == 'oplog-' ? false : true;
  }

  return valid;
};

export interface OrderGeneralInfoProps extends Props {
  intlKey: string;
  customerInfo?: CustomerInfoOutputDTO;
  isUploadingVas: boolean;
  fileUrlVas: string;
  onOrderDeliveryInfoFields: (fields: object) => void;
  onVasFileSelect: (file: File) => void;
  onVasFileReset: () => void;
  setDeliveryInfoFields: OrderGeneralInfoFields;
}

export interface OrderGeneralInfoFields {
  orderRefNo: string | undefined;
  vasAttachmentUrl: string;
  paymentOption: string;
  paymentAmount: number;
  currency: string;
}

export const OrderGeneralInfo: React.FC<OrderGeneralInfoProps> = ({
  setDeliveryInfoFields,
  intl,
  intlKey,
  onOrderDeliveryInfoFields,
  onVasFileSelect,
  fileUrlVas,
  isBusy,
  isUploadingVas,
  onVasFileReset,
  onWillUnmount,
}: OrderGeneralInfoProps) => {
  const [fields, setFields] = useState<OrderGeneralInfoFields>({
    vasAttachmentUrl: '',
    orderRefNo: '',
    paymentOption: '',
    currency: '',
    paymentAmount: 0,
  });

  const [isVasFileUploadCompleted, setIsVasFileUploadCompleted] = useState(false);
  const [vasFileSelectHasError, setVasFileSelectHasError] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const onUpload = () => { };

  const handleOnOrderRefNoChange = (e: any) => {
    setFields({
      ...fields,
      orderRefNo: e.currentTarget.value,
    });
  };

  React.useEffect(() => {
    if (fileUrlVas !== undefined && fileUrlVas !== '' && fileUrlVas !== fields.vasAttachmentUrl) {
      setIsVasFileUploadCompleted(true);

      setFields({
        ...fields,
        vasAttachmentUrl: fileUrlVas,
      });
    }
  }, [fileUrlVas]);

  const onVasFileSelected = (file: File) => {
    if (file && (file.type === 'application/pdf'
    || file.type === 'image/jpeg' || file.type === 'image/jpg' 
    || file.type === 'image/png')) {
      onVasFileSelect(file);
      setVasFileSelectHasError(false);
    } else {
      setVasFileSelectHasError(true);
    }
  };

  React.useEffect(() => {
    onOrderDeliveryInfoFields(fields);
  }, [fields]);

  const retrieveCoDPaymentOptions = () => {
    return Object.keys(PaymentOption).map(coDPaymentOption => {
      return {
        label: intl.messages[`${intlKey}.OrderInfoFormPaymentOptions.${coDPaymentOption}`],
        value: coDPaymentOption,
      };
    });
  };

  const retrieveCoDCurrencyOptions = () => {
    return Object.keys(Currency)
      .map(CoDCurrencyOption => {
        return {
          label: intl.messages[`${intlKey}.OrderInfoFormCurrencyOptions.${CoDCurrencyOption}`],
          value: CoDCurrencyOption,
        };
      })
      .filter(currencyOption => currencyOption.value !== Currency.UNSUPPORTED.toString() && currencyOption.label);
  };

  const changeSelectedPaymentOption = (e: any) => {
    setFields({
      ...fields,
      paymentOption: e.currentTarget.value,
      paymentAmount: 0,
    });
  };

  const changeSelectedCurrencyOption = (e: any) => {
    setFields({
      ...fields,
      currency: e.currentTarget.value,
    });
  };

  const handleOnPaymentAmountChange = (e: any) => {
    let amount =
      parseFloat(e.currentTarget.value) < 1
        ? e.currentTarget.value.replace(/^00+/, '0')
        : e.currentTarget.value.replace(/^0+/, '');
    amount = amount === '' ? '0' : amount;
    setFields({
      ...fields,
      paymentAmount: amount,
    });
  };

  const codPaymentOptions = retrieveCoDPaymentOptions();

  React.useEffect(() => {
    setFields({
      ...setDeliveryInfoFields,
    });

    return () => {
      onWillUnmount && onWillUnmount();
    };
  }, []);

  return isBusy ? (
    <Skeleton />
  ) : (
    <>
      <DrawerTitle size="medium" boxShadow="small">
        {intl.messages[`${intlKey}.Header.EnterOrderInfo`]}
      </DrawerTitle>
      <Box px="22">
        <DrawerTitle pt="16" pl="0" size="small">
          {intl.messages[`${intlKey}.OrderInfoFormHeaders.OrderRefNo`]}
        </DrawerTitle>
        <FormControl isInvalid={!validateRefNo(fields.orderRefNo)}>
          <Input
            placeholder={intl.messages[`${intlKey}.OrderInfoFormPlaceholders.LeaveThisInfoNone`]}
            onChange={(e: React.SyntheticEvent<HTMLInputElement>) => handleOnOrderRefNoChange(e)}
            value={fields.orderRefNo}
          />
          <FormErrorMessage>{intl.messages[`${intlKey}.MaxOrderRefNo`]}</FormErrorMessage>
        </FormControl>

        <Box display="flex" flexDirection="column" mt="22">
          <DrawerTitle pt="0" pl="0" size="small">
            <>
              {intl.messages[`${intlKey}.OrderInfoFormHeaders.VasDocumentURL`]}
              <Popover
                isDark
                content={
                  <ImagePreviewer
                    image="/images/sample/Invoice.png"
                    text={intl.messages[`${intlKey}.OrderInfoFormHeaders.VasDocumentURLInfo`]}
                  />
                }
                placement="bottom"
                action={['hover']}
              >
                <Image src="/images/information_icon.png" width="20px" marginLeft="5px" marginTop="-5px" />
              </Popover>
            </>
          </DrawerTitle>
          <Box>
            <InputGroup>
              <InputRightAddon
                as="label"
                htmlFor="VasFile"
                cursor="pointer"
                bg={fields.vasAttachmentUrl === '' ? 'palette.grey' : 'palette.teal'}
                disabled
              >
                <Text fontWeight={800} color="palette.white" fontFamily="heading" fontSize="13">
                  {fields.vasAttachmentUrl && <Icon name="fas fa-check" fontSize="13" mr="11" color="palette.white" />}
                  {
                    intl.messages[
                    `${intlKey}.OrderInfoFormUploadButtons.${fields.vasAttachmentUrl ? 'Uploaded' : 'Upload'}`
                    ]
                  }
                </Text>
              </InputRightAddon>
              <Input
                value={fields.vasAttachmentUrl}
                placeholder={intl.messages[`${intlKey}.OrderInfoFormPlaceholders.UploadVasDocument`]}
              />
            </InputGroup>
          </Box>
          {vasFileSelectHasError && (
            <Text mt="10px" color="red">
              {intl.messages[`${intlKey}.OrderInfoFormPlaceholders.UploadVasDocumentError`]}
            </Text>
          )}
          <Box display="none">
            <FileUploader
              id="VasFile"
              labelTextKey="Upload"
              onComplete={onUpload}
              resetTimeout={1500}
              intl={intl}
              isDisabled
              isBusy={true || isUploadingVas}
              isCompleted={isVasFileUploadCompleted}
              onReset={onVasFileReset}
              url={fields.vasAttachmentUrl}
              onFileSelect={onVasFileSelected}
              fileType="application/pdf, image/png, image/jpg, image/jpeg"
            />
          </Box>
        </Box>

        <Box mt="22">
          <DrawerTitle pt="0" pl="0" size="small">
            {intl.messages[`${intlKey}.PaymentInfoFormHeaders.PaymentInfo`]}
          </DrawerTitle>
          <SelectHTML
            placeholder={intl.messages[`${intlKey}.PaymentInfoFormHeaders.Placeholder`]}
            value={fields.paymentOption}
            onChange={(e: any) => changeSelectedPaymentOption(e)}
            options={codPaymentOptions}
          />
        </Box>
        {!(PaymentOption.Cash === fields.paymentOption || PaymentOption.CreditCard === fields.paymentOption) ? null : (
          <Box>
            <Box mt="22">
              <DrawerTitle pt="0" pl="0" size="small">
                {intl.messages[`${intlKey}.PaymentInfoFormHeaders.PaymentAmount`]}
              </DrawerTitle>
              <InputSelect
                inputProps={{
                  type: 'number',
                  name: 'inputProps',
                  onChange: (e: any) => handleOnPaymentAmountChange(e),
                  value: fields.paymentAmount,
                  step: 0.1,
                  min: 1,
                  max: 1000000,
                  precision: 2,
                  mb: '10px',
                }}
                selectProps={
                  {
                    name: 'inputProps',
                    onChange: (e: any) => changeSelectedCurrencyOption(e),
                    value: fields.currency,
                    defaultValue: 'TRY',
                  } as any
                }
                options={retrieveCoDCurrencyOptions()}
              />
            </Box>
            <Box>
              {fields.paymentAmount === 0 || fields.paymentAmount.toString() === '0' ? (
                <Text color="red">{intl.messages[`${intlKey}.PaymentInfoFormHeaders.AmountWarning`]}</Text>
              ) : (
                ''
              )}
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
};

import React, { FC, SyntheticEvent, useEffect, useState } from 'react';
import {
  Modal,
  ModalTitle,
  ModalHeader,
  ModalContent,
  Box,
  Button,
  ModalFooter,
  Dialog,
  DialogTypes,
  Flex,
  Icon,
  FormLabel,
  FormControl,
  Input,
  SelectHTML,
  FormValidator,
  FormErrorMessage,
  InputSelect,
} from '@oplog/express';
import { injectIntl, FormattedMessage } from 'react-intl';
import { CargoCarriersApiDTO, CargoType, CorrectInvalidSalesOrderCommand, Currency, PaymentOption } from '@services';
import { Text } from 'recharts';
import * as Yup from 'yup';
import useForceUpdate from 'use-force-update';
import { linkFormatter } from '@oplog/data-grid';

export interface CorrectInvalidSalesOrderModalProps {
  onClose: () => void;
  invalidSalesOrderDetails: any;
  correctInvalidSalesOrderResource: any;
  intl?: any;
  cargoCarriers?: any;
  validator: FormValidator;
  requestInfo?: {
    isBusy: boolean;
    isCompleted: boolean;
    isError: boolean;
  };
  dialogOpen?: boolean;
  onDialogClose?: () => void;
  onSubmit: (referenceNumber: string | undefined, form: CorrectInvalidSalesOrderCommand) => void;
}

const INTL_KEY = 'InvalidSalesOrderDetails';

const CorrectInvalidSalesOrderModal: FC<CorrectInvalidSalesOrderModalProps> = ({
  onClose,
  intl,
  invalidSalesOrderDetails,
  cargoCarriers,
  requestInfo,
  validator,
  correctInvalidSalesOrderResource,
  onSubmit,
  dialogOpen,
  onDialogClose,
}: CorrectInvalidSalesOrderModalProps) => {
  const [dialogSettings, setDialogSettings] = useState<{ show: boolean }>({ show: dialogOpen || false });
  const [form, setForm] = useState<CorrectInvalidSalesOrderCommand>({
    orderId: invalidSalesOrderDetails.orderId ? invalidSalesOrderDetails.orderId : undefined,
    orderCreatedAt: invalidSalesOrderDetails.orderCreatedAt ? invalidSalesOrderDetails.orderCreatedAt : undefined,
    salesChannel: invalidSalesOrderDetails.salesChannel ? invalidSalesOrderDetails.salesChannel : undefined,
    vasDocumentUrl: invalidSalesOrderDetails.vasDocumentUrl
        ? invalidSalesOrderDetails.vasDocumentUrl
        : undefined,
    cargo: {
      cargoCarrier: invalidSalesOrderDetails.cargo?.requestedCargoCarrier
        ? invalidSalesOrderDetails.cargo?.requestedCargoCarrier
        : undefined,
      cargoCode: invalidSalesOrderDetails.cargo?.cargoCode ? invalidSalesOrderDetails.cargo?.cargoCode : undefined,
      cargoDocumentUrl: invalidSalesOrderDetails.cargo?.cargoDocumentUrl
        ? invalidSalesOrderDetails.cargo?.cargoDocumentUrl
        : undefined,
      cargoType: invalidSalesOrderDetails.cargo?.cargoType ? invalidSalesOrderDetails.cargo?.cargoType : undefined,
    },
    customer: {
      email: invalidSalesOrderDetails.customer?.email ? invalidSalesOrderDetails.customer?.email : undefined,
      firstName: invalidSalesOrderDetails.customer?.firstName
        ? invalidSalesOrderDetails.customer?.firstName
        : undefined,
      lastName: invalidSalesOrderDetails.customer?.lastName ? invalidSalesOrderDetails.customer?.lastName : undefined,
      phone: invalidSalesOrderDetails.customer?.phone ? invalidSalesOrderDetails.customer?.phone : undefined,
    },
    payment: {
      currency: invalidSalesOrderDetails.payment?.currency ? invalidSalesOrderDetails.payment?.currency : undefined,
      paymentGatewayNames: invalidSalesOrderDetails.payment?.paymentGatewayNames
        ? invalidSalesOrderDetails.payment?.paymentGatewayNames
        : undefined,
      totalPaymentAmount: invalidSalesOrderDetails.payment?.totalPaymentAmount
        ? invalidSalesOrderDetails.payment?.totalPaymentAmount
        : undefined,
      isPaid: invalidSalesOrderDetails.payment?.isPaid
    },
    shippingAddress: {
      addressFirstName: invalidSalesOrderDetails.shippingAddress?.addressFirstName
        ? invalidSalesOrderDetails.shippingAddress?.addressFirstName
        : undefined,
      addressLastName: invalidSalesOrderDetails.shippingAddress?.addressLastName
        ? invalidSalesOrderDetails.shippingAddress?.addressLastName
        : undefined,
      addressOne: invalidSalesOrderDetails.shippingAddress?.addressOne
        ? invalidSalesOrderDetails.shippingAddress?.addressOne
        : undefined,
      addressPhone: invalidSalesOrderDetails.shippingAddress?.addressPhone
        ? invalidSalesOrderDetails.shippingAddress?.addressPhone
        : undefined,
      addressTwo: invalidSalesOrderDetails.shippingAddress?.addressTwo
        ? invalidSalesOrderDetails.shippingAddress?.addressTwo
        : undefined,
      city: invalidSalesOrderDetails.shippingAddress?.city ? invalidSalesOrderDetails.shippingAddress?.city : undefined,
      country: invalidSalesOrderDetails.shippingAddress?.country
        ? invalidSalesOrderDetails.shippingAddress?.country
        : undefined,
      district: invalidSalesOrderDetails.shippingAddress?.district
        ? invalidSalesOrderDetails.shippingAddress?.district
        : undefined,
      postalCode: invalidSalesOrderDetails.shippingAddress?.postalCode
        ? invalidSalesOrderDetails.shippingAddress?.postalCode
        : undefined,
    },
  });

  const invalidSalesOrderSchema = () => {
    return {
      addressFirstName: Yup.string().required(
        `${intl.messages[`${INTL_KEY}.CorrectInvalidSalesOrderModal.Error.AddressFirstName`]}`
      ),
      addressLastName: Yup.string().required(
        `${intl.messages[`${INTL_KEY}.CorrectInvalidSalesOrderModal.Error.AddressLastName`]}`
      ),
      addressPhone: Yup.string().required(
        `${intl.messages[`${INTL_KEY}.CorrectInvalidSalesOrderModal.Error.AddressPhone`]}`
      ),
      addressOne: Yup.string().required(
        `${intl.messages[`${INTL_KEY}.CorrectInvalidSalesOrderModal.Error.AddressOne`]}`
      ),
      country: Yup.string().required(`${intl.messages[`${INTL_KEY}.CorrectInvalidSalesOrderModal.Error.Country`]}`),
      city: Yup.string().required(`${intl.messages[`${INTL_KEY}.CorrectInvalidSalesOrderModal.Error.City`]}`),
      district: Yup.string().required(`${intl.messages[`${INTL_KEY}.CorrectInvalidSalesOrderModal.Error.District`]}`),
      postalCode: Yup.string().notRequired(),
      firstName: Yup.string().required(
        `${intl.messages[`${INTL_KEY}.CorrectInvalidSalesOrderModal.Error.CustomerFirstName`]}`
      ),
      lastName: Yup.string().required(
        `${intl.messages[`${INTL_KEY}.CorrectInvalidSalesOrderModal.Error.CustomerLastName`]}`
      ),
      phone: Yup.string().required(`${intl.messages[`${INTL_KEY}.CorrectInvalidSalesOrderModal.Error.CustomerPhone`]}`),
      email: Yup.string().required(`${intl.messages[`${INTL_KEY}.CorrectInvalidSalesOrderModal.Error.CustomerEmail`]}`),
      cargoDocumentUrl: Yup.string().notRequired(),
      cargoCode: Yup.string().notRequired(),
      vasDocumentUrl: Yup.string().notRequired(),
    };
  };

  const getKeysRecursively = (obj: any, prefix = ''): any =>
    Object.keys(obj).reduce((r, k) => {
      if (Array.isArray(obj[k])) {
        return r;
      } else if (typeof obj[k] === 'object' && obj[k] !== null) {
        return [...r, ...getKeysRecursively(obj[k], prefix + k + '.')];
      }
      return [...r, prefix + k];
    }, []);

  useEffect(() => {
    validator.registerSchema(invalidSalesOrderSchema());
    validator.clearErrors();

    const keys = getKeysRecursively(form);

    keys.forEach((key: string) => {
      var schema = form;
      var pList = key.split('.');
      var len = pList.length;
      for (var i = 0; i < len - 1; i++) {
        var elem = pList[i];
        if (!schema[elem]) schema[elem] = {};
        schema = schema[elem];
      }
      validator.validate(pList[len - 1], schema[pList[len - 1]]);
      forceUpdate();
    });
  }, []);

  useEffect(() => {
    if (correctInvalidSalesOrderResource && !correctInvalidSalesOrderResource.isBusy) {
      setDialogSettings({ show: false });
      onClose();
    }
  }, [correctInvalidSalesOrderResource]);

  const retrieveCargoOptions = () => {
    return cargoCarriers?.map((cargoCarrierOption: CargoCarriersApiDTO) => {
      return {
        label: cargoCarrierOption.value,
        value: cargoCarrierOption.key,
      };
    });
  };

  const retrieveCargoTypeOptions = () => {
    return Object.keys(CargoType).map((cargoTypeOption: CargoType) => {
      return {
        label: intl.messages[`CreateSalesOrderContextualPanel.OrderCargoTypeOptios.${cargoTypeOption}`],
        value: cargoTypeOption,
      };
    });
  };

  const retrieveCoDPaymentOptions = () => {
    return Object.keys(PaymentOption).map(coDPaymentOption => {
      return {
        label:
          intl.messages[
            `${INTL_KEY}.CorrectInvalidSalesOrderModal.PaymentInfo.OrderInfoFormPaymentOptions.${coDPaymentOption}`
          ],
        value: coDPaymentOption,
      };
    });
  };

  const retrieveCoDCurrencyOptions = () => {
    return Object.keys(Currency)
      .map(CoDCurrencyOption => {
        return {
          label:
            intl.messages[
              `${INTL_KEY}.CorrectInvalidSalesOrderModal.PaymentInfo.OrderInfoFormCurrencyOptions.${CoDCurrencyOption}`
            ],
          value: CoDCurrencyOption,
        };
      })
      .filter(currencyOption => currencyOption.value !== Currency.UNSUPPORTED.toString() && currencyOption.label);
  };

  const forceUpdate = useForceUpdate();

  function handleInputChange(path: string, e: SyntheticEvent<HTMLInputElement>) {
    var schema = form;
    var pList = path.split('.');
    var len = pList.length;
    for (var i = 0; i < len - 1; i++) {
      var elem = pList[i];
      if (!schema[elem]) schema[elem] = {};
      schema = schema[elem];
    }

    schema[pList[len - 1]] = e.currentTarget.value;

    setForm({
      ...form,
      [pList[len - 1]]: e.currentTarget.value,
    });

    validator.validate(pList[len - 1], e.currentTarget.value);
    forceUpdate();
  }

  const handleBlur = (path: string) => {
    var schema = form;
    var pList = path.split('.');
    var len = pList.length;
    for (var i = 0; i < len - 1; i++) {
      var elem = pList[i];
      if (!schema[elem]) schema[elem] = {};
      schema = schema[elem];
    }

    return () => {
      validator.validate(pList[len - 1], schema[pList[len - 1]]);
      forceUpdate();
    };
  };

  return (
    <>
      <Modal isOpen onClose={onClose} size="3xl" disableOutsideMouseEvents>
        <ModalHeader>
          <ModalTitle>{intl.messages[`${INTL_KEY}.CorrectInvalidSalesOrderModal.Title`]}</ModalTitle>
        </ModalHeader>
        <ModalContent>
          <Flex
            display="flex"
            flexDirection="row"
            backgroundColor="palette.orange_lighter"
            p="22px 44px"
            margin="-22px -44px"
          >
            <Icon mr="8px" name="fas fa-exclamation-triangle" fontSize="16" color="#ffd578" marginTop="5px" />
            <Flex flexDirection="column">
              <Text color="palette.grey_default">
                {intl.messages[`${INTL_KEY}.CorrectInvalidSalesOrderModal.WarningInfo`]}
              </Text>
            </Flex>
          </Flex>
          <Flex color="#7D7D7D" paddingTop="30px">
            <Icon mr="8px" name="fas fa-exclamation" fontSize="12px" color="#E8A028" />
            <Text>{intl.messages[`Enum.${invalidSalesOrderDetails?.error}`]}</Text>
          </Flex>
          <Flex marginTop="20px" fontSize="16" font="base" color="h6">
            <Text>{intl.messages[`${INTL_KEY}.CorrectInvalidSalesOrderModal.ShippingAddress.Title`]}</Text>
          </Flex>
          <Flex marginTop="20px">
            <FormControl isInvalid={!!validator.getErrorIntent('addressFirstName')} width={1 / 2} pr="16" mb="11">
              <FormLabel fontWeight={900}>
                {intl.messages[`${INTL_KEY}.CorrectInvalidSalesOrderModal.ShippingAddress.Name`]}*
              </FormLabel>
              <Input
                value={form.shippingAddress?.addressFirstName}
                placeholder={`${
                  intl.messages[`${INTL_KEY}.CorrectInvalidSalesOrderModal.ShippingAddress.NamePlaceholder`]
                }`}
                onBlur={() => handleBlur('shippingAddress.addressFirstName')}
                onChange={(e: any) => {
                  handleInputChange('shippingAddress.addressFirstName', e);
                }}
              />
              <FormErrorMessage>{validator.getErrorIntent('addressFirstName')?.text}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!validator.getErrorIntent('addressLastName')} width={1 / 2} pr="16" mb="11">
              <FormLabel fontWeight={900}>
                {intl.messages[`${INTL_KEY}.CorrectInvalidSalesOrderModal.ShippingAddress.LastName`]}*
              </FormLabel>
              <Input
                value={form.shippingAddress?.addressLastName}
                placeholder={`${
                  intl.messages[`${INTL_KEY}.CorrectInvalidSalesOrderModal.ShippingAddress.LastNamePlaceholder`]
                }`}
                onBlur={() => handleBlur('shippingAddress.addressLastName')}
                onChange={(e: any) => {
                  handleInputChange('shippingAddress.addressLastName', e);
                }}
              />
              <FormErrorMessage>{validator.getErrorIntent('addressLastName')?.text}</FormErrorMessage>
            </FormControl>
          </Flex>
          <Flex marginTop="20px">
            <FormControl isInvalid={!!validator.getErrorIntent('addressPhone')} width={1} pr="16" mb="11">
              <FormLabel fontWeight={900}>
                {intl.messages[`${INTL_KEY}.CorrectInvalidSalesOrderModal.ShippingAddress.PhoneNumber`]}*
              </FormLabel>
              <Input
                value={form.shippingAddress?.addressPhone}
                placeholder={`${
                  intl.messages[`${INTL_KEY}.CorrectInvalidSalesOrderModal.ShippingAddress.PhoneNumberPlaceholder`]
                }`}
                onBlur={() => {
                  handleBlur('shippingAddress.addressPhone');
                }}
                onChange={(e: any) => {
                  handleInputChange('shippingAddress.addressPhone', e);
                }}
              />
              <FormErrorMessage>{validator.getErrorIntent('addressPhone')?.text}</FormErrorMessage>
            </FormControl>
          </Flex>
          <Flex marginTop="20px">
            <FormControl isInvalid={!!validator.getErrorIntent('addressOne')} width={1} pr="16" mb="11">
              <FormLabel fontWeight={900}>
                {intl.messages[`${INTL_KEY}.CorrectInvalidSalesOrderModal.ShippingAddress.AddressOne`]}*
              </FormLabel>
              <Input
                value={form.shippingAddress?.addressOne}
                placeholder={`${
                  intl.messages[`${INTL_KEY}.CorrectInvalidSalesOrderModal.ShippingAddress.AddressOnePlaceholder`]
                }`}
                onBlur={() => {
                  handleBlur('shippingAddress.addressOne');
                }}
                onChange={(e: any) => {
                  handleInputChange('shippingAddress.addressOne', e);
                }}
              />
              <FormErrorMessage>{validator.getErrorIntent('addressOne')?.text}</FormErrorMessage>
            </FormControl>
          </Flex>
          <Flex marginTop="20px">
            <FormControl width={1} pr="16" mb="11">
              <FormLabel fontWeight={900}>
                {intl.messages[`${INTL_KEY}.CorrectInvalidSalesOrderModal.ShippingAddress.AddressTwo`]}
              </FormLabel>
              <Input
                value={form.shippingAddress?.addressTwo}
                placeholder={`${
                  intl.messages[`${INTL_KEY}.CorrectInvalidSalesOrderModal.ShippingAddress.AddressTwoPlaceholder`]
                }`}
                onBlur={() => {
                  handleBlur('shippingAddress.addressTwo');
                }}
                onChange={(e: any) => {
                  handleInputChange('shippingAddress.addressTwo', e);
                }}
              />
              <FormErrorMessage>{validator.getErrorIntent('addressTwo')?.text}</FormErrorMessage>
            </FormControl>
          </Flex>
          <Flex marginTop="20px">
            <FormControl isInvalid={!!validator.getErrorIntent('country')} width={1 / 2} pr="16" mb="11">
              <FormLabel fontWeight={900}>
                {intl.messages[`${INTL_KEY}.CorrectInvalidSalesOrderModal.ShippingAddress.Country`]}*
              </FormLabel>
              <Input
                value={form.shippingAddress?.country}
                placeholder={`${
                  intl.messages[`${INTL_KEY}.CorrectInvalidSalesOrderModal.ShippingAddress.CountryPlaceholder`]
                }`}
                onBlur={() => {
                  handleBlur('shippingAddress.country');
                }}
                onChange={(e: any) => {
                  handleInputChange('shippingAddress.country', e);
                }}
              />
              <FormErrorMessage>{validator.getErrorIntent('country')?.text}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!validator.getErrorIntent('city')} width={1 / 2} pr="16" mb="11">
              <FormLabel fontWeight={900}>
                {intl.messages[`${INTL_KEY}.CorrectInvalidSalesOrderModal.ShippingAddress.City`]}*
              </FormLabel>
              <Input
                value={form.shippingAddress?.city}
                placeholder={`${
                  intl.messages[`${INTL_KEY}.CorrectInvalidSalesOrderModal.ShippingAddress.CityPlaceholder`]
                }`}
                onBlur={() => {
                  handleBlur('shippingAddress.city');
                }}
                onChange={(e: any) => {
                  handleInputChange('shippingAddress.city', e);
                }}
              />
              <FormErrorMessage>{validator.getErrorIntent('city')?.text}</FormErrorMessage>
            </FormControl>
          </Flex>
          <Flex marginTop="20px">
            <FormControl isInvalid={!!validator.getErrorIntent('district')} width={1 / 2} pr="16" mb="11">
              <FormLabel fontWeight={900}>
                {intl.messages[`${INTL_KEY}.CorrectInvalidSalesOrderModal.ShippingAddress.District`]}*
              </FormLabel>
              <Input
                value={form.shippingAddress?.district}
                placeholder={`${
                  intl.messages[`${INTL_KEY}.CorrectInvalidSalesOrderModal.ShippingAddress.DistrictPlaceholder`]
                }`}
                onBlur={() => {
                  handleBlur('shippingAddress.district');
                }}
                onChange={(e: any) => {
                  handleInputChange('shippingAddress.district', e);
                }}
              />
              <FormErrorMessage>{validator.getErrorIntent('district')?.text}</FormErrorMessage>
            </FormControl>
            <FormControl width={1 / 2} pr="16" mb="11">
              <FormLabel fontWeight={900}>
                {intl.messages[`${INTL_KEY}.CorrectInvalidSalesOrderModal.ShippingAddress.PostalCode`]}
              </FormLabel>
              <Input
                value={form.shippingAddress?.postalCode}
                placeholder={`${
                  intl.messages[`${INTL_KEY}.CorrectInvalidSalesOrderModal.ShippingAddress.PostalCodePlaceholder`]
                }`}
                onBlur={() => {
                  handleBlur('shippingAddress.postalCode');
                }}
                onChange={(e: any) => {
                  handleInputChange('shippingAddress.postalCode', e);
                }}
              />
            </FormControl>
          </Flex>
          <Flex marginTop="20px" fontSize="16" font="base" color="h6">
            <Text>{intl.messages[`${INTL_KEY}.CorrectInvalidSalesOrderModal.CustomerInfo.Title`]}</Text>
          </Flex>
          <Flex marginTop="20px">
            <FormControl isInvalid={!!validator.getErrorIntent('firstName')} width={1 / 2} pr="16" mb="11">
              <FormLabel fontWeight={900}>
                {intl.messages[`${INTL_KEY}.CorrectInvalidSalesOrderModal.CustomerInfo.Name`]}*
              </FormLabel>
              <Input
                value={form.customer?.firstName}
                placeholder={`${
                  intl.messages[`${INTL_KEY}.CorrectInvalidSalesOrderModal.CustomerInfo.NamePlaceholder`]
                }`}
                onBlur={() => {
                  handleBlur('customer.firstName');
                }}
                onChange={(e: any) => {
                  handleInputChange('customer.firstName', e);
                }}
              />
              <FormErrorMessage>{validator.getErrorIntent('firstName')?.text}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!validator.getErrorIntent('lastName')} width={1 / 2} pr="16" mb="11">
              <FormLabel fontWeight={900}>
                {intl.messages[`${INTL_KEY}.CorrectInvalidSalesOrderModal.CustomerInfo.LastName`]}*
              </FormLabel>
              <Input
                value={form.customer?.lastName}
                placeholder={`${
                  intl.messages[`${INTL_KEY}.CorrectInvalidSalesOrderModal.CustomerInfo.LastNamePlaceholder`]
                }`}
                onBlur={() => {
                  handleBlur('customer.lastName');
                }}
                onChange={(e: any) => {
                  handleInputChange('customer.lastName', e);
                }}
              />
              <FormErrorMessage>{validator.getErrorIntent('lastName')?.text}</FormErrorMessage>
            </FormControl>
          </Flex>
          <Flex marginTop="20px">
            <FormControl isInvalid={!!validator.getErrorIntent('phone')} width={1} pr="16" mb="11">
              <FormLabel fontWeight={900}>
                {intl.messages[`${INTL_KEY}.CorrectInvalidSalesOrderModal.CustomerInfo.PhoneNumber`]}*
              </FormLabel>
              <Input
                value={form.customer?.phone}
                placeholder={`${
                  intl.messages[`${INTL_KEY}.CorrectInvalidSalesOrderModal.CustomerInfo.PhoneNumberPlaceholder`]
                }`}
                onBlur={() => {
                  handleBlur('customer.phone');
                }}
                onChange={(e: any) => {
                  handleInputChange('customer.phone', e);
                }}
              />
              <FormErrorMessage>{validator.getErrorIntent('phone')?.text}</FormErrorMessage>
            </FormControl>
          </Flex>
          <Flex marginTop="20px">
            <FormControl isInvalid={!!validator.getErrorIntent('email')} width={1} pr="16" mb="11">
              <FormLabel fontWeight={900}>
                {intl.messages[`${INTL_KEY}.CorrectInvalidSalesOrderModal.CustomerInfo.Email`]}*
              </FormLabel>
              <Input
                value={form.customer?.email}
                placeholder={`${
                  intl.messages[`${INTL_KEY}.CorrectInvalidSalesOrderModal.CustomerInfo.EmailPlaceholder`]
                }`}
                onBlur={() => {
                  handleBlur('customer.email');
                }}
                onChange={(e: any) => {
                  handleInputChange('customer.email', e);
                }}
              />
              <FormErrorMessage>{validator.getErrorIntent('email')?.text}</FormErrorMessage>
            </FormControl>
          </Flex>
          <Flex marginTop="20px" fontSize="16" font="base" color="h6">
            <Text>{intl.messages[`${INTL_KEY}.CorrectInvalidSalesOrderModal.CargoInfo.Title`]}</Text>
          </Flex>
          <Flex marginTop="20px">
            <FormControl width={1 / 2} pr="16" mb="11">
              <FormLabel fontWeight={900}>
                {intl.messages[`${INTL_KEY}.CorrectInvalidSalesOrderModal.CargoInfo.SalesChannel`]}
              </FormLabel>
              <Input
                disabled
                value={form.salesChannel}
                placeholder={`${
                  intl.messages[`${INTL_KEY}.CorrectInvalidSalesOrderModal.CargoInfo.SalesChannelPlaceholder`]
                }`}
                onBlur={() => {}}
                onChange={(e: any) => {}}
              />
            </FormControl>
          </Flex>
          <Flex marginTop="20px">
            <FormControl width={1 / 2} pr="16" mb="11">
              <FormLabel fontWeight={900}>
                {intl.messages[`${INTL_KEY}.CorrectInvalidSalesOrderModal.CargoInfo.CargoCompany`]}
              </FormLabel>
              <SelectHTML
                disabled
                value={invalidSalesOrderDetails.cargo?.cargoCarrier}
                options={retrieveCargoOptions()}
                placeholder={`${
                  intl.messages[`${INTL_KEY}.CorrectInvalidSalesOrderModal.CargoInfo.CargoCompanyPlaceholder`]
                }`}
                onBlur={() => {
                  handleBlur('cargo.cargoCarrier');
                }}
                onChange={(e: any) => {
                  handleInputChange('cargo.cargoCarrier', e);
                }}
              />
            </FormControl>
            <FormControl width={1 / 2} pr="16" mb="11">
              <FormLabel fontWeight={900}>
                {intl.messages[`${INTL_KEY}.CorrectInvalidSalesOrderModal.CargoInfo.CargoType`]}
              </FormLabel>
              <SelectHTML
                value={form.cargo?.cargoType}
                disabled
                options={retrieveCargoTypeOptions()}
                placeholder={`${
                  intl.messages[`${INTL_KEY}.CorrectInvalidSalesOrderModal.CargoInfo.CargoTypePlaceholder`]
                }`}
                onBlur={() => {
                  handleBlur('cargo.cargoType');
                }}
                onChange={(e: any) => {
                  handleInputChange('cargo.cargoType', e);
                }}
              />
            </FormControl>
          </Flex>
          <Flex marginTop="20px">
            <FormControl isInvalid={!!validator.getErrorIntent('cargoDocumentUrl')} width={1 / 2} pr="16" mb="11">
              <FormLabel fontWeight={900}>
                {intl.messages[`${INTL_KEY}.CorrectInvalidSalesOrderModal.CargoInfo.CargoDocumentUrl`]}
              </FormLabel>
              <Input
                value={form.cargo?.cargoDocumentUrl}
                placeholder={`${
                  intl.messages[`${INTL_KEY}.CorrectInvalidSalesOrderModal.CargoInfo.CargoDocumentUrlPlaceholder`]
                }`}
                isDisabled={!!form.cargo?.cargoCode}
                onBlur={() => {
                  handleBlur('cargo.cargoDocumentUrl');
                }}
                onChange={(e: any) => {
                  handleInputChange('cargo.cargoDocumentUrl', e);
                }}
              />
              <FormErrorMessage>{validator.getErrorIntent('cargoDocumentUrl')?.text}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!validator.getErrorIntent('cargoCode')} width={1 / 2} pr="16" mb="11">
              <FormLabel fontWeight={900}>
                {intl.messages[`${INTL_KEY}.CorrectInvalidSalesOrderModal.CargoInfo.CargoCode`]}
              </FormLabel>
              <Input
                value={form.cargo?.cargoCode}
                placeholder={`${
                  intl.messages[`${INTL_KEY}.CorrectInvalidSalesOrderModal.CargoInfo.CargoCodePlaceholder`]
                }`}
                isDisabled={!!form.cargo?.cargoDocumentUrl}
                onBlur={() => {
                  handleBlur('cargo.cargoCode');
                }}
                onChange={(e: any) => {
                  handleInputChange('cargo.cargoCode', e);
                }}
              />
              <FormErrorMessage>{validator.getErrorIntent('cargoCode')?.text}</FormErrorMessage>
            </FormControl>
          </Flex>
          <Flex marginTop="20px" fontSize="16" font="base" color="h6">
            <Text>{intl.messages[`${INTL_KEY}.CorrectInvalidSalesOrderModal.PaymentInfo.Title`]}</Text>
          </Flex>
          <Flex marginTop="20px">
            <FormControl width={1 / 2} pr="16" mb="11">
              <FormLabel fontWeight={900}>
                {intl.messages[`${INTL_KEY}.CorrectInvalidSalesOrderModal.PaymentInfo.PaymentOptions`]}
              </FormLabel>
              <SelectHTML
                disabled
                value={invalidSalesOrderDetails.payment?.paymentOption}
                options={retrieveCoDPaymentOptions()}
                placeholder={`${
                  intl.messages[`${INTL_KEY}.CorrectInvalidSalesOrderModal.PaymentInfo.PaymentOptionsPlaceholder`]
                }`}
                onBlur={() => {}}
                onChange={(e: any) => {}}
              />
            </FormControl>
            <FormControl width={1 / 2} pr="16" mb="11">
              <FormLabel fontWeight={900}>
                {intl.messages[`${INTL_KEY}.CorrectInvalidSalesOrderModal.PaymentInfo.PaymentAmount`]}
              </FormLabel>
              <InputSelect
                inputProps={{
                  type: 'number',
                  name: 'inputProps',
                  onChange: () => {},
                  value: form.payment?.totalPaymentAmount,
                  step: 0.1,
                  min: 1,
                  max: 1000000,
                  precision: 2,
                  mb: '10px',
                  disabled: true,
                }}
                selectProps={
                  {
                    name: 'inputProps',
                    onChange: () => {},
                    value: form.payment?.currency,
                    defaultValue: 'TRY',
                    isDisabled: true,
                  } as any
                }
                options={retrieveCoDCurrencyOptions()}
              />
            </FormControl>
          </Flex>
          <Flex marginTop="20px">
            <FormControl isInvalid={!!validator.getErrorIntent('vasDocumentUrl')} width={1 / 2} pr="16" mb="11">
              <FormLabel fontWeight={900}>
                {intl.messages[`${INTL_KEY}.CorrectInvalidSalesOrderModal.PaymentInfo.VasDocumentUrl`]}
              </FormLabel>
              <Input
                value={form.vasDocumentUrl}
                placeholder={`${
                  intl.messages[`${INTL_KEY}.CorrectInvalidSalesOrderModal.PaymentInfo.VasDocumentUrlPlaceholder`]
                }`}
                onBlur={() => {
                  handleBlur('vasDocumentUrl');
                }}
                onChange={(e: any) => {
                  handleInputChange('vasDocumentUrl', e);
                }}
              />
              <FormErrorMessage>{validator.getErrorIntent('vasDocumentUrl')?.text}</FormErrorMessage>
            </FormControl>
          </Flex>
        </ModalContent>
        <ModalFooter>
          <Box display="flex" justifyContent="center" m="0 auto">
            <Button onClick={onClose} mr="11px" variant="light" size="small" kind="outline" minWidth="120px">
              {intl.messages[`Modal.Warning.Cancel`]}
            </Button>
            <Button
              isLoading={requestInfo?.isBusy}
              disabled={validator.hasErrors()}
              onClick={() => setDialogSettings({ show: true })}
              size="small"
              minWidth="200px"
            >
              {intl.messages[`Modal.Confirmation.Save`]}
            </Button>
          </Box>
        </ModalFooter>
      </Modal>
      <Dialog
        type={DialogTypes.Warning}
        message={<FormattedMessage id={`${INTL_KEY}.Warning.ConfirmationWarning`} />}
        isLoading={correctInvalidSalesOrderResource && correctInvalidSalesOrderResource.isBusy}
        text={{
          approve: intl.messages[`Modal.Confirmation.Okay`],
          cancel: intl.messages[`Modal.Confirmation.Cancel`],
        }}
        onApprove={() => {
          onSubmit(invalidSalesOrderDetails?.referenceNumber, form);
        }}
        onCancel={() => {
          setDialogSettings({ show: false });
          onDialogClose && onDialogClose();
        }}
        isOpen={dialogSettings.show}
      />
    </>
  );
};

export default injectIntl(CorrectInvalidSalesOrderModal);

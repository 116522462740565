import { CleanMonthlyInvalidSalesOrdersModalProps, CleanMonthlyInvalidSalesOrdersModal } from '@components/molecules/CleanMonthlyInvalidSalesOrdersModal';
import { GridType, ResourceType } from '@models';
import { gridActions } from '@oplog/data-grid';
import { GridFetchRequested } from '@oplog/data-grid/dist/store/actions';
import { resourceActions, resourceSelectors } from '@oplog/resource-redux';
import { RouterAction } from 'connected-react-router';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { StoreState } from '../../store/state';

export interface CleanMonthlyInvalidSalesOrdersModalContainerProps {
  onClose: () => void;
}

function mapStateToProps(
  { resources }: StoreState,
  ownProps: CleanMonthlyInvalidSalesOrdersModalContainerProps
): Partial<CleanMonthlyInvalidSalesOrdersModalProps> {
  return {
    responseData: resourceSelectors.getData(resources, ResourceType.CleanMonthlyInvalidSalesOrders),
    isBusy:
      resourceSelectors.isBusy(resources, ResourceType.CleanMonthlyInvalidSalesOrders) ||
      resourceSelectors.isBusy(resources, ResourceType.GetIntegrations),
    hasError: resourceSelectors.hasError(resources, ResourceType.CleanMonthlyInvalidSalesOrders),
    COMPONENT_INTL_KEY: 'CleanMonthlyInvalidSalesOrders.Modal',
  };
}

function mapDispatchToProps(
  dispatch: Dispatch<resourceActions.ResourceAction | RouterAction | GridFetchRequested>
): Partial<CleanMonthlyInvalidSalesOrdersModalProps> {
  return {
    setIntegration: (integrationId: String) => {
        const value = { integrationId: integrationId };
      dispatch(resourceActions.resourceRequested(ResourceType.CleanMonthlyInvalidSalesOrders, value));
    },
    onWillUnmount: () => {
      dispatch(resourceActions.resourceInit(ResourceType.SyncFromIntegrationCancelled));
      dispatch(gridActions.gridFetchRequested(GridType.SupportSalesOrders));
    },
  };
}

export const CleanMonthlyInvalidSalesOrdersModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(CleanMonthlyInvalidSalesOrdersModal));

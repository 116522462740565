import { Box, Icon } from '@oplog/express';
import useSendToWarehouseState, { SendToWarehouseStateKeys } from '@store/sendToWarehouse/SendToWarehouseState';
import React from 'react';

export interface SendToWarehouseDeleteButtonDeleteButtonProps {
  sku: string;
}

export const SendToWarehouseDeleteButton: React.FC<SendToWarehouseDeleteButtonDeleteButtonProps> = ({
  sku,
}: SendToWarehouseDeleteButtonDeleteButtonProps) => {
  const [state, actions] = useSendToWarehouseState();

  const onDelete = () => {
    const gridSplit = state.gridData.filter((item: any) => item.sku !== sku);
    const panelSplit = state.panelData.filter((item: any) => item.sku !== sku);

    actions.setStateData(SendToWarehouseStateKeys.gridData, gridSplit);
    actions.setStateData(SendToWarehouseStateKeys.panelData, panelSplit);
  };

  return (
    <Box width={1}>
      <Icon cursor="pointer" onClick={() => onDelete()} name="fa fa-times" />
    </Box>
  );
};

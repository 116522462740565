import { clickableCellAction } from '@oplog/data-grid';
import { resourceActions } from '@oplog/resource-redux';
import { RouterAction } from 'connected-react-router';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Dispatch } from 'redux';
import {
  SupportReceivingOrders,
  SupportReceivingOrdersProps,
} from '../../components/pages/SupportReceivingOrders/SupportReceivingOrders';

function mapDispatchToProps(
  dispatch: Dispatch<resourceActions.ResourceAction | clickableCellAction.ClickableCellActions | RouterAction>,
  routeProps: RouteComponentProps
): Partial<SupportReceivingOrdersProps> {
  return {
    closeModalViewer: (): void => {
      dispatch(clickableCellAction.clickableCellResetted());
    },
  };
}

export const SupportReceivingOrdersContainer = connect(null, mapDispatchToProps)(injectIntl(SupportReceivingOrders));

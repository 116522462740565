import { config } from '@config';
import { clickableCellReducer, gridReducer, gridStore, imageViewerReducer } from '@oplog/data-grid';
import { createFileUploadsStore, fileUploadsReducer } from '@oplog/file-uploader';
import { resourceReducer, resourceStore } from '@oplog/resource-redux';
import { getGridData, postExcelGridData, resourceApiMap, uploadImage } from '@services/Api';
import { initialState, saveState, StoreState } from '@store/state';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { intlReducer } from 'react-intl-redux';
import { applyMiddleware, compose, createStore, Store } from 'redux';
import createSagaMiddleware from 'redux-saga';
import history from '../history';
import { createReturnReducer } from './createReturn';
import { customerDetailsReducer } from './customerDetails/reducer';
import { feedBackModalReducer } from './feedbackModal';
import { returnDetailsReducer } from './returnDetails';

const throttle = require('lodash.throttle');

export function rootReducer(state: any, action: any) {
  return {
    customerDetails: customerDetailsReducer(state.customerDetails, action),
    returnDetails: returnDetailsReducer(state.returnDetails, action),
    createReturn: createReturnReducer(state.createReturn, action),
    intl: intlReducer(state.intl, action),
    grid: gridReducer(state.grid, action),
    resources: resourceReducer(state.resources, action),
    files: fileUploadsReducer(state.files, action),
    imageViewer: imageViewerReducer(state.imageViewer, action),
    clickableCell: clickableCellReducer(state.clickableCell, action),
    feedbackModal: feedBackModalReducer(state.feedbackModal, action),
  };
}

export const resourceSaga = resourceStore({
  httpRequestMap: resourceApiMap,
});

export const fileUploadsStore = createFileUploadsStore({
  uploadFile: uploadImage,
});

export function initStore(): Store<StoreState> {
  const STORE_SAVE_INTERVAL = 500;
  const sagaMiddleware = createSagaMiddleware();

  const middleware = applyMiddleware(routerMiddleware(history), sagaMiddleware);

  let middlewares;
  if (!config.isTest && !config.isProduction && window['__REDUX_DEVTOOLS_EXTENSION__']) {
    middlewares = compose(middleware, window['__REDUX_DEVTOOLS_EXTENSION__']());
  } else {
    middlewares = compose(middleware);
  }

  const store = createStore<any, any, any, any>(connectRouter(history)(rootReducer), initialState, middlewares);

  store.subscribe(
    throttle(() => {
      saveState({
        intl: {
          locale: store.getState().intl.locale,
        },
      });
    }, STORE_SAVE_INTERVAL)
  );

  sagaMiddleware.run(gridStore({ getGridData, postExcelGridData }).saga);
  sagaMiddleware.run(resourceSaga.resourceSaga);
  sagaMiddleware.run(fileUploadsStore.fileUploadsSaga);

  return store;
}

import { Scopes } from '@auth';
import { config } from '@config';
import React from 'react';
import globalHook, { Store } from 'use-global-hook';

interface AuthStore {
  auth0UserInfo: any;
}

interface AuthActions {
  clearState: (data: Partial<AuthStore>) => void;
  setAuth0UserInfo: (auth0UserInfo: any) => void;
  userHasScope: (requiredRole: Scopes[]) => boolean;
  userIsAdmin: () => boolean;
  userIsTenantAdmin: () => boolean;
}

/* InitalStates */
export const initialCommonState: AuthStore = {
  auth0UserInfo: {},
};

/* Actions */
export const commonActions: object = {
  clearState: (store: Store<AuthStore, AuthActions>, data: Partial<AuthStore>) => {
    store.setState({ ...store.state, ...data });
  },
  setAuth0UserInfo: (store: Store<AuthStore, AuthActions>, auth0UserInfo: any) => {
    store.setState({ ...store.state, auth0UserInfo });
  },
  userHasScope: (store: Store<AuthStore, AuthActions>, requiredScope: Scopes[]): boolean => {
    const userInfo = store.state.auth0UserInfo;
    if (userInfo[config.auth.userPermissionKey]) {
      const roles = userInfo[config.auth.userPermissionKey].split(' ');
      return requiredScope.every(scope => roles.includes(scope));
    }

    return false;
  },
  userIsAdmin: (store: Store<AuthStore, AuthActions>): boolean => {
    const userInfo = store.state.auth0UserInfo;
    if (userInfo[config.auth.userPermissionKey]) {
      const roles = userInfo[config.auth.userPermissionKey].split(' ');
      return roles.includes(Scopes.SystemAdmin);
    }

    return false;
  },
  userIsTenantAdmin: (store: Store<AuthStore, AuthActions>): boolean => {
    const userInfo = store.state.auth0UserInfo;
    if (userInfo[config.auth.userPermissionKey]) {
      const roles = userInfo[config.auth.userPermissionKey].split(' ');
      return roles.includes(Scopes.TenantAdmin);
    }

    return false;
  },
};

/* Export */
const useAuthStore = globalHook<AuthStore, AuthActions>(React, initialCommonState, commonActions);

export default useAuthStore;

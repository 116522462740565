import { HTTP_ERRORS } from '@models';
import {
  Box,
  Button,
  DATE_FORMAT,
  Dialog,
  DialogTypes,
  Flex,
  formatUtcToLocal,
  FormControl,
  FormLabel,
  FormValidator,
  Icon,
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  Text,
  Toggle,
  Widget,
} from '@oplog/express';
import { Resource } from '@oplog/resource-redux';
import {
  DeleteIntegrationCommand,
  IntegrationOutputDTO,
  IntegrationState,
  IntegrationType,
  UpdateIntegrationCommand,
} from '@services';
import { IntegrationSteps } from '@store/integrations/integrationsState';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Props } from '../../atoms/Component/Component';
import { LastSyncTime } from '../../atoms/LastSyncTime/LastSyncTime';
import { EntegraIntegrationForm } from '../EntegraIntegrationForm/EntegraIntegrationForm';
import { OpenApiIntegrationForm } from '../OpenApiIntegrationForm/OpenApiIntegrationForm';
import { ShopifyIntegrationForm } from '../ShopifyIntegrationForm/ShopifyIntegrationForm';
import { TicimaxIntegrationForm } from '../TicimaxIntegrationForm/TicimaxIntegrationForm';
import { TSoftIntegrationForm } from '../TSoftIntegrationForm/TSoftIntegrationForm';
import { WooCommerceIntegrationForm } from '../WooCommerceIntegrationForm/WooCommerceIntegrationForm';
import TicimaxMappingForm from './bones/TicimaxMappingForm';
import TSoftMappingForm from './bones/TSoftMappingForm';
import { TrendyolIntegrationForm } from '../TrendyolIntegrationForm/TrendyolIntegrationForm';
import { OrderDeskIntegrationForm } from '../OrderDeskIntegrationForm/OrderDeskIntegrationForm';

export interface IntegrationInfoPanelProps extends Props {
  integration: IntegrationOutputDTO;
  integrationLogoSource: string;
  validator: FormValidator;
  deleteIntegrationResource: Resource<Response>;
  onDeleteIntegration: (command: DeleteIntegrationCommand) => void;
  onUpdateIntegration: (integration: UpdateIntegrationCommand) => void;
  onSyncIntegration?: (integrationId: string) => void;
  onGetIntegrationPayload: (integrationId: string, integrationType: IntegrationType) => void;
  getIntegrationPayloadResource: Resource<Response>;
  initResources: () => void;
}

export interface IntegrationInfoPanelState {
  isDeleteIntegrationDialogOpen: boolean;
  isUpdateIntegrationFormOpen: boolean;
  isSyncConfirmationDialogOpen: boolean;
  initialIntegrationState?: boolean;
  updateIntegrationCommand: UpdateIntegrationCommand;
  deleteIntegrationCommand: DeleteIntegrationCommand;
  step: number;
}

const COMPONENT_INTL_KEY = 'Integrations.IntegrationInfo';

export const IntegrationInfoPanel: React.FC<IntegrationInfoPanelProps> = ({
  integration,
  integrationLogoSource,
  intl,
  onUpdateIntegration,
  onGetIntegrationPayload,
  getIntegrationPayloadResource,
  onDeleteIntegration,
  onSyncIntegration,
  validator,
  deleteIntegrationResource,
  initResources,
  onDidMount,
  onWillUnmount,
  error,
  isBusy,
}) => {
  const [dialogState, setDialogStates] = useState({
    isDeleteIntegrationDialogOpen: false,
    isUpdateIntegrationFormOpen: false,
    isSyncConfirmationDialogOpen: false,
  });

  const [initialIntegrationState, setInitialIntegrationState] = useState(integration.state === IntegrationState.Active);
  const [updateIntegrationCommand, setUpdateIntegrationCommand] = useState<UpdateIntegrationCommand>({
    id: integration.id,
    isActive: integration.state === IntegrationState.Active,
    integrationName: integration.name,
    apiPassword: integration.apiPassword || '',
    shopLink: integration.shopLink || '',
    shouldSyncMissingData: false,
    shouldSyncMissingOrders: false,
    shouldSyncMissingProducts: false,
    type: integration.type,
    openApiIdentifier: integration.openApiIdentifier
  });

  const [originalIntegrationState, setOriginalIntegrationState] = useState(integration);

  const [deleteIntegrationCommand, setDeleteIntegrationCommand] = useState<DeleteIntegrationCommand>({
    id: integration.id,
  });

  const [step, setStep] = useState<IntegrationSteps>(IntegrationSteps.Integration);

  const [syncButtonDisabled, setsyncButtonDisabled] = useState({ isSyncButtonDisabled: false });

  useEffect(() => {
    if (
      error &&
      error.code !== HTTP_ERRORS.BadRequest &&
      deleteIntegrationResource.error &&
      deleteIntegrationResource.error.code !== HTTP_ERRORS.BadRequest
    ) {
      setDialogStates({
        ...dialogState,
        isUpdateIntegrationFormOpen: false,
      });
    }
  }, [error]);

  useEffect(() => {
    onDidMount && onDidMount();
    return () => onWillUnmount && onWillUnmount();
  }, []);

  const handleOpen = () => {
    validator.clearErrors();
    setDialogStates({
      ...dialogState,
      isUpdateIntegrationFormOpen: true,
    });
  };

  const handleClose = () => {
    integration = originalIntegrationState;
    setDialogStates({
      ...dialogState,
      isUpdateIntegrationFormOpen: false,
    });
    initResources();
  };

  const handleDelete = () => {
    setDialogStates({
      ...dialogState,
      isDeleteIntegrationDialogOpen: true,
    });
  };

  const handleSubmit = (integration: UpdateIntegrationCommand, initialIntegrationState: boolean) => {

    if (integration.isActive && !initialIntegrationState) {
      setDialogStates({
        ...dialogState,
        isSyncConfirmationDialogOpen: true,
      });

      setUpdateIntegrationCommand(integration);
      return;
    }
    onUpdateIntegration(integration);
  };

  const renderDeleteIntegrationConfirmationDialog = () => {
    return (
      <Dialog
        type={DialogTypes.Warning}
        isOpen={dialogState.isDeleteIntegrationDialogOpen}
        text={{
          approve: intl.messages[`Modal.Warning.Okay`],
          cancel: intl.messages[`Modal.Warning.Cancel`],
        }}
        message={intl.messages[`${COMPONENT_INTL_KEY}.Dialog.RemoveConfirmation`]}
        closeOnDimmerClick={false}
        onApprove={() => {
          setDialogStates({
            ...dialogState,
            isDeleteIntegrationDialogOpen: false,
          });

          onDeleteIntegration(deleteIntegrationCommand);
        }}
        onCancel={() => {
          setDialogStates({
            ...dialogState,
            isDeleteIntegrationDialogOpen: false,
          });
        }}
      />
    );
  };

  const onSyncButton = (integrationId: string) => {
    setTimeout(() => setsyncButtonDisabled({ isSyncButtonDisabled: true }));
    setTimeout(() => setsyncButtonDisabled({ isSyncButtonDisabled: false }), 10000);
    onSyncIntegration ? onSyncIntegration(integrationId) : null;
  };

  const renderFormModal = () => {
    const FormProps = {
      integration,
      validator,
      onSubmit: handleSubmit,
      onDelete: handleDelete,
      onClose: handleClose,
      intl,
      isBusy,
      error,
      deleteIntegrationResource,
      isEdit: true,
      isNew: false,
    };

    const mappingFormProps = {
      intl,
      integration,
      onSubmit: handleSubmit,
      onGetIntegrationPayload: (integrationId: string, integrationType: IntegrationType) => {
        onGetIntegrationPayload(integrationId, integrationType);
      },
      getIntegrationPayloadResource,
      editMode: true,
    };

    return (
      <Modal isOpen={dialogState.isUpdateIntegrationFormOpen} onClose={handleClose} size="3xl">
        <ModalHeader>
          <ModalTitle>{intl.messages[`${COMPONENT_INTL_KEY}.Form.Title.${integration.type}`]}</ModalTitle>
          {(integration.type === IntegrationType.TSoft || integration.type === IntegrationType.Ticimax) && (
            <Box mt="10px">
              <Text
                color={step == IntegrationSteps.Integration ? 'palette.blue_link' : ''}
                cursor="pointer"
                onClick={() => setStep(IntegrationSteps.Integration)}
              >
                1 - {intl.messages[`${COMPONENT_INTL_KEY}.TSoftMappingForm.Integration`]}
              </Text>{' '}
              <Text
                color={step == IntegrationSteps.Configuration ? 'palette.blue_link' : ''}
                cursor="pointer"
                ml="10px"
                onClick={() => setStep(IntegrationSteps.Configuration)}
              >
                2 - {intl.messages[`${COMPONENT_INTL_KEY}.TSoftMappingForm.Configuration`]}
              </Text>
            </Box>
          )}
        </ModalHeader>
        <ModalContent>
          {integration.type === IntegrationType.Shopify && <ShopifyIntegrationForm {...FormProps} />}
          {integration.type === IntegrationType.Trendyol && <TrendyolIntegrationForm {...FormProps} />}
          {integration.type === IntegrationType.TrendyolDE && <TrendyolIntegrationForm {...FormProps} />}
          {integration.type === IntegrationType.OrderDesk && <OrderDeskIntegrationForm {...FormProps} />}
          {integration.type === IntegrationType.OpenApi && <OpenApiIntegrationForm {...FormProps} />}
          {integration.type === IntegrationType.Entegra && <EntegraIntegrationForm {...FormProps} />}
          {integration.type === IntegrationType.WooCommerce && <WooCommerceIntegrationForm {...FormProps} />}
          {integration.type === IntegrationType.Ticimax && step === IntegrationSteps.Integration && (
            <TicimaxIntegrationForm {...FormProps} />
          )}
          {integration.type === IntegrationType.Ticimax && step === IntegrationSteps.Configuration && (
            <TicimaxMappingForm {...mappingFormProps} />
          )}
          {integration.type === IntegrationType.TSoft && step === IntegrationSteps.Integration && (
            <TSoftIntegrationForm {...FormProps} />
          )}
          {integration.type === IntegrationType.TSoft && step === IntegrationSteps.Configuration && (
            <TSoftMappingForm {...mappingFormProps} />
          )}
        </ModalContent>
        <ModalFooter borderTop="xs" borderColor="palette.snow_light">
          <Flex>
            <Icon mr="8px" name="far fa-question-circle" fontSize="21px" />
            <Text color="palette.grey" fontSize="13" lineHeight={1.54}>
              <FormattedMessage
                id={`${COMPONENT_INTL_KEY}.Form.Info`}
                defaultMessage={intl.messages[`${COMPONENT_INTL_KEY}.Form.Info`]}
                values={{
                  state: <strong>{intl.messages[`${COMPONENT_INTL_KEY}.Form.IntegrationState`]}</strong>,
                  remove: <strong>{intl.messages[`${COMPONENT_INTL_KEY}.Form.RemoveIntegration`]}</strong>,
                }}
              />
            </Text>
          </Flex>
        </ModalFooter>
      </Modal>
    );
  };

  const getLastSyncStatus = (integration: IntegrationOutputDTO) => {
    if (integration) {
      if (integration.state === IntegrationState.Passive) {
        return (
          <Text color="red" fontSize="10" lineHeight="medium">
            {intl.messages[`${COMPONENT_INTL_KEY}.Deactive`]}
          </Text>
        );
      }

      if (integration.lastSyncTime && integration.syncable) {
        // get textEllipsis
        return (
          <Box width={1} overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
            <LastSyncTime intl={intl} data={{ tenantLastSyncTime: integration.lastSyncTime }} icon={null} />
          </Box>
        );
      }

      if (integration.type === IntegrationType.Oplog) {
        return <span>{intl.messages[`${COMPONENT_INTL_KEY}.DefaultIntegration`]}</span>;
      }

      if (integration.type === IntegrationType.Entegra) {
        return <span>{intl.messages[`${COMPONENT_INTL_KEY}.Entegra`]}</span>;
      }

      if (integration.type === IntegrationType.OpenApi) {
        return <span>{intl.messages[`${COMPONENT_INTL_KEY}.OpenApi`]}</span>;
      }

      if (integration.type === IntegrationType.WooCommerce) {
        return <span>{intl.messages[`${COMPONENT_INTL_KEY}.WooCommerce`]}</span>;
      }

      if (integration.type === IntegrationType.Shopify) {
        return <span>{intl.messages[`${COMPONENT_INTL_KEY}.Shopify`]}</span>;
      }

      if (integration.type === IntegrationType.Custom) {
        return <span>{intl.messages[`${COMPONENT_INTL_KEY}.Custom`]}</span>;
      }

      if (integration.type === IntegrationType.TSoft) {
        return <span>{intl.messages[`${COMPONENT_INTL_KEY}.TSoft`]}</span>;
      }
    }
    return null;
  };

  const widgetButtons = () => {
    const canViewButtons = integration.editable || integration.syncable;
    if (canViewButtons) {
      const buttons = [];
      integration.editable &&
        buttons.push({
          icon: {
            fontSize: '18',
            name: 'fas fa-cog',
          },
          backgroundColor: 'palette.grey_dark',
          onClick: () => handleOpen(),
        });
      integration.syncable &&
        buttons.push({
          icon: {
            fontSize: '18',
            name: 'fas fa-sync-alt',
          },
          onClick: () => {
            onSyncIntegration ? onSyncButton(integration.id) : null;
          },
          disabled: integration.state === IntegrationState.Passive || syncButtonDisabled.isSyncButtonDisabled,
        });
      return buttons;
    }
    return [];
  };

  const handleSyncConfirm = () => {
    const updateIntegration = {
      ...updateIntegrationCommand,
      shouldSyncMissingData: true,
    };

    setUpdateIntegrationCommand({
      ...updateIntegration,
    });

    setDialogStates({
      ...dialogState,
      isSyncConfirmationDialogOpen: false,
    });

    onUpdateIntegration({ ...updateIntegration });
  };

  const handleSyncReject = () => {
    setDialogStates({
      ...dialogState,
      isSyncConfirmationDialogOpen: false,
    });

    onUpdateIntegration(updateIntegrationCommand);
  };

  const renderSyncMissingDataConfirmationDialog = () => {
    return integration.type === IntegrationType.Entegra ? (
      <Dialog
        type={DialogTypes.Warning}
        className="sync-missing-data-dialog"
        closeOnDimmerClick={false}
        text={{
          approve: intl.messages[`${COMPONENT_INTL_KEY}.Dialog.SyncConfirm`],
        }}
        isOpen={dialogState.isSyncConfirmationDialogOpen}
        buttons={[
          <Button
            key={`${integration.name}_sync-reject`}
            className="dialog-button"
            size="large"
            onClick={handleSyncConfirm}
          >
            {intl.messages[`${COMPONENT_INTL_KEY}.Dialog.SyncReject`]}
          </Button>,
          <Button
            key={`${integration.name}_sync-confirm`}
            className="dialog-button"
            size="large"
            variant="dark"
            onClick={handleSyncReject}
          >
            {intl.messages[`${COMPONENT_INTL_KEY}.Dialog.SyncConfirm`]}
          </Button>,
        ]}
        message={
          <>
            <FormattedMessage
              id={`${COMPONENT_INTL_KEY}.SyncConfirmationMessage`}
              defaultMessage={intl.messages[`${COMPONENT_INTL_KEY}.SyncConfirmationMessage`]}
              values={{
                date: (
                  <b>
                    {formatUtcToLocal(
                      integration.deactivationTime ? integration.deactivationTime : new Date(),
                      DATE_FORMAT
                    )}
                  </b>
                ),
              }}
            />
            <FormControl size="small" isInline mb="22">
              <FormLabel>{intl.messages[`${COMPONENT_INTL_KEY}.ShouldSyncMissingOrders`]}</FormLabel>
              <Toggle
                isChecked={updateIntegrationCommand.shouldSyncMissingOrders || false}
                onChange={() => {
                  setUpdateIntegrationCommand({
                    ...updateIntegrationCommand,
                    shouldSyncMissingOrders: !updateIntegrationCommand.shouldSyncMissingOrders,
                  });
                }}
              />
            </FormControl>

            <FormControl size="small" isInline mb="22">
              <FormLabel>{intl.messages[`${COMPONENT_INTL_KEY}.ShouldSyncMissingProducts`]}</FormLabel>
              <Toggle
                isChecked={updateIntegrationCommand.shouldSyncMissingProducts || false}
                onChange={() => {
                  setUpdateIntegrationCommand({
                    ...updateIntegrationCommand,
                    shouldSyncMissingProducts: !updateIntegrationCommand.shouldSyncMissingProducts,
                  });
                }}
              />
            </FormControl>
          </>
        }
      />
    ) : (
      <Dialog
        type={DialogTypes.Warning}
        className="sync-missing-data-dialog"
        closeOnDimmerClick={false}
        text={{
          approve: intl.messages[`${COMPONENT_INTL_KEY}.Dialog.SyncConfirm`],
        }}
        isOpen={dialogState.isSyncConfirmationDialogOpen}
        buttons={[
          <Button
            key={`${integration.name}_sync-reject`}
            className="dialog-button"
            size="large"
            onClick={handleSyncConfirm}
          >
            {intl.messages[`${COMPONENT_INTL_KEY}.Dialog.SyncReject`]}
          </Button>,
          <Button
            key={`${integration.name}_sync-confirm`}
            className="dialog-button"
            size="large"
            variant="dark"
            onClick={handleSyncReject}
          >
            {intl.messages[`${COMPONENT_INTL_KEY}.Dialog.SyncConfirm`]}
          </Button>,
        ]}
        message={
          <FormattedMessage
            id={`${COMPONENT_INTL_KEY}.SyncConfirmationMessage`}
            defaultMessage={intl.messages[`${COMPONENT_INTL_KEY}.SyncConfirmationMessage`]}
            values={{
              date: (
                <b>
                  {formatUtcToLocal(
                    integration.deactivationTime ? integration.deactivationTime : new Date(),
                    DATE_FORMAT
                  )}
                </b>
              ),
            }}
          />
        }
      />
    );
  };

  return (
    <>
      <Widget.Four
        key={`#${integration.name}`}
        title={`#${integration.name}`}
        subtitle={getLastSyncStatus(integration)}
        image={integrationLogoSource}
        buttons={widgetButtons()}
        content={[
          {
            icon: {
              color: 'palette.orange',
              name: 'fal fa-truck-loading',
              fontSize: '18',
            },
            text: integration.salesOrderCount,
          },
          {
            icon: {
              color: 'palette.purple',
              name: 'fal fa-tags',
              fontSize: '18',
            },
            text: integration.productCount,
          },
        ]}
      />
      {integration.editable && renderFormModal()}
      {renderDeleteIntegrationConfirmationDialog()}
      {renderSyncMissingDataConfirmationDialog()}
    </>
  );
};

import { ResourceType } from '@models';
import { validation } from '@oplog/express';
import { resourceActions, resourceSelectors } from '@oplog/resource-redux';
import { UpdateAddressOfCustomerCommand } from '@services';
import { StoreState } from '@store/state';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Dispatch } from 'redux';
import {
  EditCustomerShippingAddressesForm,
  EditCustomerShippingAddressesFormProps,
} from '../../components/molecules/EditCustomerShippingAddressesForm/EditCustomerShippingAddressesForm';

export interface EditCustomerShippingAddressesFormContainerProps extends RouteComponentProps {
  onModalClose: () => void;
  customerNo: number;
  selectAddressId?: string;
  onSuccessClose: () => void;
}

function mapStateToProps({ resources }: StoreState): Partial<EditCustomerShippingAddressesFormProps> {
  return {
    isBusy: resourceSelectors.isBusy(resources, ResourceType.CustomerShippingAddresses),
    commandIsBusy: resourceSelectors.isBusy(resources, ResourceType.UpdateCustomerShippingAddress),
    addresses: resourceSelectors.getData(resources, ResourceType.CustomerShippingAddresses),
    isEditCustomerShippingAddressesUpdateSuccess: resourceSelectors.isSuccessComplete(
      resources,
      ResourceType.UpdateCustomerShippingAddress
    ),
    error: resourceSelectors.getError(resources, ResourceType.UpdateCustomerShippingAddress),
    provinceResource: resources[ResourceType.AllProvinces],
    districtResource: resources[ResourceType.DistrictsOfProvince],
    countryResource: resources[ResourceType.AllCountries],
  };
}

function mapDispatchToProps(
  dispatch: Dispatch<resourceActions.ResourceAction>,
  ownProps: EditCustomerShippingAddressesFormContainerProps
) {
  return {
    onModalClose: ownProps.onModalClose,
    onDidMount() {
      dispatch(
        resourceActions.resourceRequested(ResourceType.CustomerShippingAddresses, { customerNo: ownProps.customerNo })
      );
      dispatch(resourceActions.resourceRequested(ResourceType.AllProvinces));
      
      dispatch(resourceActions.resourceRequested(ResourceType.AllCountries));
    },
    onWillUnmount() {
      dispatch(resourceActions.resourceInit(ResourceType.UpdateCustomerShippingAddress));
      dispatch(resourceActions.resourceInit(ResourceType.CustomerShippingAddresses));
      dispatch(resourceActions.resourceInit(ResourceType.DistrictsOfProvince));
      dispatch(resourceActions.resourceInit(ResourceType.AllProvinces));
    },
    onSubmit(form: UpdateAddressOfCustomerCommand, customerNo: number) {
      const customerShippingAddress: UpdateAddressOfCustomerCommand = {
        ...form,
        customerNo,
      };
      dispatch(
        resourceActions.resourceRequested(ResourceType.UpdateCustomerShippingAddress, {
          customerShippingAddress,
        })
      );
    },
    provinceSelect: (command: { province: string }): void => {
      dispatch(resourceActions.resourceRequested(ResourceType.DistrictsOfProvince, command));
    },
  };
}

export const EditCustomerShippingAddressesFormContainer = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(validation(injectIntl(EditCustomerShippingAddressesForm)))
);

import { Box, convertUtcToLocal, duration, formatUtcToLocal, Icon } from '@oplog/express';
import { TenantLastSyncTimeOutputDTO } from '@services';
import * as React from 'react';
import { InjectedIntl } from 'react-intl';
import Skeleton from 'react-loading-skeleton';

export interface LastSyncTimeProps {
  intl: InjectedIntl;
  isBusy?: boolean;
  error?: any;
  data?: TenantLastSyncTimeOutputDTO;
  icon?: JSX.Element | null;
}

export const LastSyncTime: React.FC<LastSyncTimeProps> = ({ intl, isBusy, error, data, icon }: LastSyncTimeProps) => {
  if (isBusy) {
    return <Skeleton duration={3} />;
  }

  let message;
  if (error || data === undefined || data.tenantLastSyncTime === undefined) {
    message = intl.messages['LastSyncUnknown'];
  } else {
    const { tenantLastSyncTime } = data;
    const hours = formatUtcToLocal(tenantLastSyncTime, 'HH:mm');
    message = `${duration(
      convertUtcToLocal(tenantLastSyncTime),
      new Date(Date.now())
      // todo: intl.locale
    )}, ${hours}`;
  }

  return (
    <Box fontSize="11">
      {icon || <Icon mr="4px" name="fa fa-sync" />}
      {`${intl.messages['LastSync']}: ${message}`}
    </Box>
  );
};

import { GridType, ResourceType } from '@models';
import { gridActions } from '@oplog/data-grid';
import { GridAction } from '@oplog/data-grid/dist/store/actions';
import { resourceActions, resourceSelectors } from '@oplog/resource-redux';
import { Returns, ReturnsProps } from '@pages/Returns';
import { urls } from '@routes/urls';
import { StoreState } from '@store';
import { onTabChange } from '@utils';
import { push, RouterAction } from 'connected-react-router';
import {
  DateFilter,
  DateFilterOperation,
  Filter,
  StringFilter,
  StringFilterOperation,
} from 'dynamic-query-builder-client';
import moment from 'moment';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Dispatch } from 'redux';

function mapStateToProps({ resources, grid }: StoreState): Partial<ReturnsProps> {
  return {
    longTermReportResource: resources[ResourceType.ReturnsLongTermReport],
    gridFilters: grid.returns,
    weeklyReportResource: resources[ResourceType.ReturnsWeeklyReport],
    excelExportResource: resourceSelectors.getResource(resources, ResourceType.ReturnsExcelExport),
    excelExportDetailedResource: resourceSelectors.getResource(resources, ResourceType.ReturnsExcelDetailedExport),
  };
}

function mapDispatchToProps(
  dispatch: Dispatch<resourceActions.ResourceAction | RouterAction | GridAction>,
  routeProps: RouteComponentProps<any>
): Partial<ReturnsProps> {
  // Format the time in moment.
  const longTermDate = new Date();
  longTermDate.setUTCHours(0, 0, 0, 0);
  longTermDate.setDate(longTermDate.getDate() - 90);

  const weeklyDate = new Date();
  weeklyDate.setUTCHours(0, 0, 0, 0);
  weeklyDate.setDate(weeklyDate.getDate() - 7);

  return {
    onDidMount: () => {
      dispatch(
        resourceActions.resourceRequested(ResourceType.ReturnsLongTermReport, {
          longTermDate,
        })
      );
      dispatch(
        resourceActions.resourceRequested(ResourceType.ReturnsWeeklyReport, {
          weeklyDate,
        })
      );
    },
    goTab: tab => {
      onTabChange(tab, routeProps, dispatch);
    },
    navigateToCreateReturn: () => dispatch(push(urls.createReturn)),
    onClick: ({ day, state }: { day?: string; state?: string }) => {
      let filters: Filter[] = [];
      if (day) {
        const dateFilter = new DateFilter({
          op: DateFilterOperation.GreaterThan,
          value: moment()
            .utc()
            .subtract(day, 'days')
            .toString(),
          property: 'createdAt',
        });
        filters = [...filters, dateFilter];
      }
      if (state) {
        const stateFilter = new StringFilter({
          op: StringFilterOperation.Equals,
          value: state,
          property: 'state',
        });
        filters = [...filters, stateFilter];
      }
      dispatch(gridActions.gridFiltersSubmitted(GridType.Returns, filters, []));
      dispatch(gridActions.gridPaginationOffsetReset(GridType.Returns));
      dispatch(gridActions.gridFetchRequested(GridType.Returns));
    },
    excelToExport: (resourceType: ResourceType) => {
      const url_string = window.location.href;
      const url = new URL(url_string);
      const dqb = url.searchParams.get('dqb');

      const gridState = JSON.parse(decodeURIComponent(dqb ? dqb : ''));

      dispatch(resourceActions.resourceRequested(resourceType, { dqb: gridState.query }));
    },

    excelToExportInit: () => {
      dispatch(resourceActions.resourceInit(ResourceType.ReturnsExcelExport));
      dispatch(resourceActions.resourceInit(ResourceType.ReturnsExcelDetailedExport));
    },
  };
}

export const ReturnsContainer = connect(mapStateToProps, mapDispatchToProps)(injectIntl(Returns));

import { GridContainer } from '@components/atoms/GridContainer';
import { barcodeFormatter, geti18nName, ProductDetailsLinkFormatter } from '@containers/formatters';
import { GridProps, GridType } from '@models';
import { ColumnSize, DataGridColumn, DataGridRow, PredefinedFilter } from '@oplog/data-grid';
import { ArrayFilterOperation, NumericFilter, NumericFilterOperation, SortDirection, SortField } from 'dynamic-query-builder-client';
import * as React from 'react';

const intlKey = 'InventoryGrid';

const invertoryGridInitalSort: SortField = new SortField({
  property: 'sku',
  by: SortDirection.ASC,
});

export const inventoryGridPredefinedFilters: Array<PredefinedFilter> = [
  {
    filter: new NumericFilter({
      id: 'AvailableStock',
      property: 'availableAmount',
      op: NumericFilterOperation.Equals,
      value: 0,
    }),
    selected: false,
  },
];

interface InventoryQuarantineGridProps extends GridProps {}

export const InventoryQuarantineGrid: React.FC<InventoryQuarantineGridProps> = ({ intl, titleKey }) => {
  const inventoryGridColumns: Array<DataGridColumn> = [
    {
      name: '#',
      key: 'index',
      resizable: true,
      locked: true,
      sortable: false,
      type: 'number',
      visibility: true,
      filterable: false,
      width: ColumnSize.Medium,
      cellClass: 'index-column',
    },
    {
      name: geti18nName('sku', intl, intlKey),
      key: 'sku',
      locked: true,
      sortable: true,
      type: 'string',
      visibility: true,
      filterable: true,
      resizable: true,
    },
    {
      name: geti18nName('barcode', intl, intlKey),
      key: 'barcodes',
      locked: true,
      type: 'string',
      visibility: true,
      resizable: true,
      sortable: false,
      filterable: true,
      formatter: barcodeFormatter,
      fieldType: 'array',
      searchField: '_',
      outerOp: ArrayFilterOperation.Any,
    },
    {
      name: geti18nName('name', intl, intlKey),
      key: 'name',
      locked: true,
      sortable: false,
      type: 'string',
      visibility: true,
      filterable: true,
      formatter: ProductDetailsLinkFormatter,
      getRowMetaData: (row: DataGridRow) => row,
      resizable: true,
    },
    {
      name: geti18nName('missingAmount', intl, intlKey),
      key: 'missingAmount',
      locked: true,
      sortable: false,
      type: 'number',
      visibility: true,
      filterable: true,
      resizable: true,
    },
  ];
  return (
    <GridContainer
      titleKey={
        titleKey ? `${intl.messages['StockStatus.Grid.Title']} - ${titleKey}` : intl.messages['StockStatus.Grid.Title']
      }
      intlKey={intlKey}
      remoteExportToExcel
      gridKey={GridType.InventoryQuarantine}
      columns={inventoryGridColumns}
      predefinedFilters={inventoryGridPredefinedFilters}
      intl={intl}
      sortField={invertoryGridInitalSort}
    />
  );
};

import { SendToCreateReturnCartGrid } from '@models';
import { DataGridRow } from '@oplog/data-grid';
import { SalesOrderQueryOutputDTO } from '@services/swagger';
import { CreateReturnResource } from '@store/state';

export function getCreateReturnData(createReturn: CreateReturnResource<Map<string, SendToCreateReturnCartGrid>>) {
  return createReturn.data;
}

export function getCreateReturnDataRows(createReturn: CreateReturnResource<Map<string, SendToCreateReturnCartGrid>>) {
  if (createReturn.data) {
    const values = Array.from(createReturn.data.values());

    return values.map((value, index) => {
      return {
        ...value,
        index: index + 1,
      };
    });
  }
  return undefined;
}

export function getCreateReturnDataWithReturnAmount(
  createReturn: CreateReturnResource<Map<string, SendToCreateReturnCartGrid>>,
  rows: DataGridRow[]
) {
  const returnData = createReturn.data;
  if (returnData) {
    return rows.map(row => {
      let returnAmount = 0;

      const returnRowData = returnData.get(row.id);

      if (returnRowData) {
        returnAmount = returnRowData.returnAmount;
      }
      return {
        ...row,
        returnAmount,
      };
    });
  }
  return [];
}

export function getCreateReturnCartGridInfo(
  createReturn: CreateReturnResource<Map<string, SendToCreateReturnCartGrid>>
) {
  if (createReturn.data) {
    const numOfTypes: number = createReturn.data.size;
    let numOfLineItems = 0;
    createReturn.data.forEach(returnData => {
      numOfLineItems += returnData.returnAmount;
    });

    return {
      numOfTypes,
      numOfLineItems,
      data: createReturn.data,
    } as any;
  }

  return { numOfTypes: 0, numOfLineItems: 0 } as any;
}

export function getCreateReturnSalesOrder(
  createReturn: CreateReturnResource<Map<string, SendToCreateReturnCartGrid>>
): SalesOrderQueryOutputDTO | undefined {
  if (createReturn.salesOrder) {
    return createReturn.salesOrder;
  }

  return undefined;
}

/* eslint-disable no-nested-ternary */
import { Props } from '@components/atoms/Component/Component';
import {
  Box,
  Button,
  Flex,
  FormControl,
  Icon,
  Input,
  OptionProps,
  Select,
  SelectHTML,
  Text,
  Image,
  Popover,
} from '@oplog/express';
import { Resource } from '@oplog/resource-redux';
import {
  CargoCarriersApiDTO,
  CreateIntegrationCommand,
  ECommerceCargoCompany,
  ECommerceSalesOrderStatuses,
  IntegrationOutputDTO,
  IntegrationState,
  IntegrationType,
  OpenApiIdentifier,
  TSoftIntegrationPayload,
  UpdateIntegrationCommand,
} from '@services';
import useCommonDataStore from '@store/commonData/commonDataState';
import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import useForceUpdate from 'use-force-update';

export enum SalesOrderChosenFrom {
  None = 0,
  TSoft = 1,
  OplogOne = 2,
}

export interface TSoftMappingProps extends Props {
  integration: IntegrationOutputDTO;
  onSubmit: (
    integration: UpdateIntegrationCommand | CreateIntegrationCommand,
    initialIntegrationState: boolean | undefined
  ) => void;
  onGetIntegrationPayload: (integrationId: string, integrationType: IntegrationType) => void;
  getIntegrationPayloadResource: Resource<Response>;
  editMode: boolean;
}

const TSoftMappingForm: React.FC<TSoftMappingProps> = ({
  integration,
  onSubmit,
  onGetIntegrationPayload,
  getIntegrationPayloadResource,
  isBusy,
  intl,
  editMode,
  onWillUnmount,
}: TSoftMappingProps) => {
  const COMPONENT_INTL_KEY = 'Integrations.IntegrationInfo.TSoftMappingForm';

  const [initialIntegrationState, setInitalIntegrationState] = useState(
    integration ? integration.state === IntegrationState.Active : true
  );

  const [{ cargoCarriers }, { clearState }] = useCommonDataStore();

  const [integrationUpdateState, setIntegrationUpdateState] = useState<UpdateIntegrationCommand>({} as any);
  const [cargoCompanyCount, setCargoCompanyCount] = useState(integration.tSoftPayload?.cargoCompanies?.length || 0);

  const [salesOrderStatusesState, setSalesOrderStatusesState] = useState('');
  const [salesOrderChosenFrom, setSalesOrderChosenFrom] = useState(SalesOrderChosenFrom.None);
  const [integrationCount, setIntegrationCount] = useState(0);
  const [salesOrdersStatusArray, setSalesOrdersStatusArray] = useState<OptionProps[]>([]);
  const [cargoCompanies, setCargoCompanies] = useState<ECommerceCargoCompany[]>();
  const [salesOrderStatuses, setSalesOrderStatuses] = useState<ECommerceSalesOrderStatuses[]>();
  const [isGetPayloadCalled, setIsGetPayloadCalled] = useState(false);

  const renderCargoCompanies = () => {
    var options: any = [];
    cargoCarriers.forEach((carrier: CargoCarriersApiDTO) => {
      options.push({
        label: carrier.value,
        value: carrier.value,
      });
    });
    if (!isGetPayloadCalled) {
      return options;
    }
    return options;
  };

  const renderStates = () => {
    var options: any = [];

    if (integration && integration.tSoftPayload && integration.tSoftPayload.salesOrderStatuses) {
      integration.tSoftPayload?.salesOrderStatuses.forEach(element => {
        options.push({
          label: element.name,
          value: element.referenceCode,
        });
      });
    }

    return options;
  };

  const checkSubmit = () => {
    if (integrationCount != cargoCompanyCount && editMode === false) {
      return true;
    }
    return isBusy;
  };

  const initalMoveItems = () => {
    if (
      integration.tSoftConfigurations?.fulfillmentWaitingOrderStatusCode &&
      integration.tSoftConfigurations?.fulfillmentWaitingOrderStatusCode.length > 0
    ) {
      let salesOrdersStatusArrayTemp: any = salesOrdersStatusArray;
      let orderStatesFiltered: any = orderStates;
      integration.tSoftConfigurations?.fulfillmentWaitingOrderStatusCode.forEach(element => {
        const orderStateObject: any = orderStates.find(object => object.value === element);
        salesOrdersStatusArrayTemp.push(orderStateObject);

        orderStatesFiltered = orderStatesFiltered.filter(function (item: any) {
          return item.label !== orderStateObject.label;
        });
      });

      setOrderState(orderStatesFiltered);
      setSalesOrdersStatusArray(salesOrdersStatusArrayTemp);
    }
  };

  const [orderStates, setOrderState] = useState<OptionProps[]>(renderStates());

  useEffect(() => {
    return () => {
      onWillUnmount && onWillUnmount();
    };
  }, []);

  useEffect(() => {
    if (integration.id) {
      if (integration.tSoftPayload) {
        setCargoCompanies(integration.tSoftPayload.cargoCompanies);
        setSalesOrderStatuses(integration.tSoftPayload.salesOrderStatuses);
      }
      setIntegrationUpdateState({
        id: integration.id,
        isActive: integration ? integration.state === IntegrationState.Active : true,
        integrationName: integration.name,
        type: integration.type,
        shouldSyncMissingData: false,
        shouldSyncMissingOrders: false,
        shouldSyncMissingProducts: false,
        apiUserName: integration.apiUserName,
        apiPassword: integration.apiPassword,
        shopLink: integration.shopLink,
        isSubProductActive: integration.isSubProductActive,
        tSoftIntegrationConfigurations: {
          cargoCompanies: integration.tSoftConfigurations?.cargoCompanies
            ? { ...integration.tSoftConfigurations?.cargoCompanies }
            : ({} as any),
          fulfillmentWaitingOrderStatusCode: integration.tSoftConfigurations?.fulfillmentWaitingOrderStatusCode
            ? [...integration.tSoftConfigurations?.fulfillmentWaitingOrderStatusCode]
            : [],
        },
        tSoftIntegrationPayload: integration.tSoftPayload,
        openApiIdentifier: integration ? integration.openApiIdentifier : OpenApiIdentifier.None,
      });

      initalMoveItems();
    }
  }, [integration]);

  const handleOnSubmit = () => {
    onSubmit(integrationUpdateState, initialIntegrationState);
  };

  useEffect(() => {
    if (getIntegrationPayloadResource.isSuccess && isGetPayloadCalled) {
      const tSoftPayload: TSoftIntegrationPayload = getIntegrationPayloadResource.data as TSoftIntegrationPayload;

      setCargoCompanyCount(tSoftPayload.cargoCompanies?.length || 0);

      setIntegrationUpdateState({
        ...integrationUpdateState,
        tSoftIntegrationConfigurations: {
          cargoCompanies: {},
          fulfillmentWaitingOrderStatusCode: [],
        },
        tSoftIntegrationPayload: tSoftPayload,
      });
      setSalesOrdersStatusArray([]);
      setCargoCompanies(tSoftPayload.cargoCompanies);
      setSalesOrderStatuses(tSoftPayload.salesOrderStatuses);

      var options: OptionProps[] = [];
      if (tSoftPayload.salesOrderStatuses) {
        tSoftPayload.salesOrderStatuses.forEach(element => {
          options.push({
            label: element.name,
            value: element.referenceCode,
          });
        });
      }
      setOrderState(options);
    }
  }, [getIntegrationPayloadResource]);

  const handleOnUpdateConfiguration = () => {
    setCargoCompanies([]);
    setSalesOrderStatuses([]);
    onGetIntegrationPayload(integration.id, integration.type);
    setIsGetPayloadCalled(true);
  };

  const moveItems = () => {
    if (salesOrderStatusesState && salesOrderChosenFrom == SalesOrderChosenFrom.TSoft) {
      const orderStateObject: any = orderStates.find(object => object.value === salesOrderStatusesState);

      setSalesOrdersStatusArray([...salesOrdersStatusArray, orderStateObject]);
      setSalesOrderStatusesState('');

      const orderStatesFiltered: any = orderStates.filter(function (item) {
        return item.label !== orderStateObject.label;
      });

      setOrderState(orderStatesFiltered);

      const array = integrationUpdateState.tSoftIntegrationConfigurations?.fulfillmentWaitingOrderStatusCode;
      array?.push(orderStateObject.value);

      setIntegrationUpdateState({
        ...integrationUpdateState,
        tSoftIntegrationConfigurations: {
          cargoCompanies: integrationUpdateState.tSoftIntegrationConfigurations?.cargoCompanies,
          fulfillmentWaitingOrderStatusCode: array,
        },
      });
    }
  };

  const moveItemsLeft = () => {
    // Remove the orders from chosen states side.

    if (salesOrderStatusesState && salesOrderChosenFrom == SalesOrderChosenFrom.OplogOne) {
      const orderStateObject: any = salesOrdersStatusArray.find(object => object.value === salesOrderStatusesState);

      const orderStatesFiltered: any = salesOrdersStatusArray.filter(function (item) {
        return item.value !== salesOrderStatusesState;
      });

      setSalesOrdersStatusArray(orderStatesFiltered);
      setSalesOrderStatusesState('');

      // Move the items back to left side.
      const tempArray = orderStates;
      tempArray.push(orderStateObject);
      setOrderState(tempArray);

      // Remove it from our update state.
      var array: any = integrationUpdateState.tSoftIntegrationConfigurations?.fulfillmentWaitingOrderStatusCode;
      array = array.filter((value: any) => value !== orderStateObject.value);

      // Update our integration update state that is going to be sent to the database.
      setIntegrationUpdateState({
        ...integrationUpdateState,
        tSoftIntegrationConfigurations: {
          cargoCompanies: integrationUpdateState.tSoftIntegrationConfigurations?.cargoCompanies,
          fulfillmentWaitingOrderStatusCode: array,
        },
      });
    }
  };

  const handleSelectChange = (e: any, integrationValue: string) => {
    if (cargoCompanyCount != integrationCount) {
      // If all items are already checked stop the count.
      setIntegrationCount(integrationCount + 1);
    }

    setIntegrationUpdateState({
      ...integrationUpdateState,
      tSoftIntegrationConfigurations: {
        cargoCompanies: {
          ...integrationUpdateState.tSoftIntegrationConfigurations?.cargoCompanies,
          [integrationValue]: e.value.value
        } as any,
        fulfillmentWaitingOrderStatusCode:
          integrationUpdateState.tSoftIntegrationConfigurations?.fulfillmentWaitingOrderStatusCode,
      },
    });
  };

  const handleCompanySelectChange = (e: any) => {
    const referenceCode: any = e.target.value;
    if (referenceCode != undefined && referenceCode != salesOrderStatusesState) {
      setSalesOrderChosenFrom(SalesOrderChosenFrom.TSoft);
      setSalesOrderStatusesState(referenceCode);
    }
  };

  const handleCompanyUndoSelectChange = (e: any) => {
    const referenceCode: any = e.target.value;
    if (referenceCode != undefined && referenceCode != salesOrderStatusesState) {
      setSalesOrderChosenFrom(SalesOrderChosenFrom.OplogOne);
      setSalesOrderStatusesState(referenceCode);
    }
  };

  const forceUpdate = useForceUpdate();

  const boxStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  };

  return (
    <>
      <Box flexDirection="row">
        <Image src="/images/information_icon.png" width="20px" marginLeft="5px" marginTop="-5px" />
        <Text>{intl.messages[`${COMPONENT_INTL_KEY}.UpdateConfigurationWarning`]}</Text>
      </Box>
      <Box {...boxStyle}>
        <Button
          size="small"
          type="submit"
          onClick={() => handleOnUpdateConfiguration()}
          isLoading={getIntegrationPayloadResource.isBusy}
          disabled={getIntegrationPayloadResource.isBusy}
          variant="alternative"
          m="16"
          width="100%"
        >
          {intl.messages[`${COMPONENT_INTL_KEY}.UpdateConfigurationButton`]}
        </Button>
      </Box>
      <Box flexDirection="row">
        <Text color="palette.steel_dark" fontSize="14" fontWeight={800}>
          {intl.messages[`${COMPONENT_INTL_KEY}.CompanyMapping`]}
        </Text>
        <Popover
          isDark
          content={intl.messages[`${COMPONENT_INTL_KEY}.CompanyMappingInfo`]}
          placement="bottom"
          action={['hover']}
        >
          <Image src="/images/questionmark_icon.png" width="20px" marginLeft="5px" marginTop="-5px" />
        </Popover>
      </Box>
      <Box marginTop="10px" marginBottom="10px">
        {integration &&
          cargoCompanies?.map((value: any, index: number) => {
            const existingIntegrations = integrationUpdateState.tSoftIntegrationConfigurations?.cargoCompanies;
            var keyValue;
            if (existingIntegrations) {
              var key = Object.keys(existingIntegrations).find(
                key => key === value.referenceCode
              );
              if (key)
                keyValue = existingIntegrations[key];
            }

            return (
              <Flex width={1} key={index}>
                <FormControl p={10} width={0.45} size={'small'} isRequired={false} isInvalid={false} isDisabled={true}>
                  <Input placeholder={value.name} />
                </FormControl>

                <Box {...boxStyle}>
                  <Icon p={10} color="palette.snow_dark" name="fa fa-caret-right" fontSize={20} />
                </Box>

                <FormControl p={10} width={0.45} size={'small'}>
                  <Select
                    key={'select-' + index}
                    options={renderCargoCompanies()}
                    placeholder={intl.messages[`${COMPONENT_INTL_KEY}.CompanySelectionPlaceHolder`]}
                    onChange={(e: any) => handleSelectChange(e, value.referenceCode)}
                    value={keyValue}
                  />
                </FormControl>
              </Flex>
            );
          })}

        {cargoCompanyCount == 0 && "Cargo company data doesn't exist."}
      </Box>

      <Box flexDirection="row">
        <Text color="palette.steel_dark" mb="10px" fontSize="14" fontWeight={800}>
          {intl.messages[`${COMPONENT_INTL_KEY}.StatusSelection`]}
        </Text>
        <Popover
          isDark
          content={intl.messages[`${COMPONENT_INTL_KEY}.StatusSelectionInfo`]}
          placement="bottom"
          action={['hover']}
        >
          <Image src="/images/questionmark_icon.png" width="20px" marginLeft="5px" marginTop="-5px" />
        </Popover>
      </Box>

      <Box marginTop="10px">
        <Flex width={1}>
          <Box width={0.45}>
            <Text color="grey" mb="10px" fontSize="13">
              {intl.messages[`${COMPONENT_INTL_KEY}.TSoft`]}
            </Text>
            <SelectHTML
              m={10}
              width={1}
              height={200}
              border={'1px solid #B5B6BD'}
              borderRadius={'9px !important'}
              placeholder="Select Item"
              options={orderStates}
              multiple
              onChange={(e: any) => handleCompanySelectChange(e)}
            />
          </Box>

          <Box {...boxStyle} width={0.1}>
            <Icon fontSize={30} color="palette.snow_dark" name="fa fa-caret-left" onClick={moveItemsLeft} />
            <Icon fontSize={30} mt="10px" color="palette.snow_dark" name="fa fa-caret-right" onClick={moveItems} />
          </Box>

          <Box width={0.45}>
            <Text color="grey" mb="10px" fontSize="13">
              {intl.messages[`${COMPONENT_INTL_KEY}.OplogOne`]}
            </Text>

            <SelectHTML
              m={10}
              width={1}
              height={200}
              placeholder="Select Item"
              border={'1px solid #B5B6BD'}
              options={salesOrdersStatusArray}
              multiple
              onChange={(e: any) => handleCompanyUndoSelectChange(e)}
            />
          </Box>
        </Flex>

        <Box {...boxStyle}>
          <Button
            size="small"
            type="submit"
            disabled={checkSubmit() || cargoCompanyCount == 0}
            onClick={() => handleOnSubmit()}
            isLoading={false}
            variant="success"
            m="16"
            width="100%"
          >
            {intl.messages[`Modal.Confirmation.Save`]}
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default injectIntl(TSoftMappingForm);

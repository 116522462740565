import { GridContainer } from '@components/atoms/GridContainer';
import { ExcelExportModal, ExportTypes } from '@components/molecules/ExcelExportModal';
import { geti18nName, ReturnDetailsLinkFormatter, sourceIconFormatter } from '@containers/formatters';
import { GridProps, GridType, ResourceType } from '@models';
import {
  ColumnSize,
  DataGridColumn,
  DataGridRow,
  dateTimeFormatter,
  enumFormatter,
  getEnumOptions,
} from '@oplog/data-grid';
import { Box } from '@oplog/express';
import { ReturnSource, ReturnState } from '@services';
import { EXCEL_EXPORT_LIMIT, EXCEL_EXPORT_RETURNS_LIMIT } from '@utils';
import { SortDirection, SortField } from 'dynamic-query-builder-client';
import * as React from 'react';

const intlKey = 'ReturnsGrid';

const returnsGridInitialSort: SortField = new SortField({
  property: 'createdAt',
  by: SortDirection.DESC,
});

interface ReturnsGridProps extends GridProps {
  excelToExport: (resourceType: ResourceType) => void;
  excelExportResource: any;
  excelToExportInit: () => void;
  excelExportDetailedResource: any;
  gridFilters: any;
}

export const ReturnsGrid: React.FC<ReturnsGridProps> = ({
  intl,
  titleKey,
  excelToExport,
  excelToExportInit,
  excelExportResource,
  excelExportDetailedResource,
  gridFilters,
}) => {
  const [isExcelExportModalOpen, setIsExcelExportModalOpen] = React.useState(false);
  const [rowCount, setRowCount] = React.useState(0);

  React.useEffect(() => {
    setRowCount(gridFilters.footer.rowCount);
  }, [gridFilters.footer]);

  const returnsGridColumns: DataGridColumn[] = [
    {
      name: '#',
      key: 'index',
      resizable: true,
      locked: true,
      sortable: false,
      type: 'number',
      visibility: true,
      filterable: false,
      width: ColumnSize.Medium,
      cellClass: 'index-column',
    },
    {
      name: geti18nName('source', intl, intlKey),
      key: 'source',
      locked: true,
      sortable: false,
      type: 'enum',
      visibility: true,
      filterable: true,
      resizable: true,
      width: ColumnSize.Large,
      formatter: row => sourceIconFormatter(row.value, row.dependentValues, intl),
      getRowMetaData: (row: DataGridRow) => row['source'],
      options: getEnumOptions(intl, ReturnSource),
    },
    {
      name: geti18nName('referenceNumber', intl, intlKey),
      key: 'referenceNumber',
      resizable: true,
      locked: true,
      sortable: true,
      type: 'string',
      visibility: true,
      filterable: true,
      formatter: ReturnDetailsLinkFormatter,
      getRowMetaData: (row: DataGridRow) => row,
    },
    {
      name: geti18nName('salesOrderReferenceNumber', intl, intlKey),
      key: 'salesOrderReferenceNumber',
      locked: true,
      sortable: false,
      type: 'string',
      visibility: true,
      filterable: true,
      resizable: true,
    },
    {
      name: geti18nName('salesOrderChannel', intl, intlKey),
      key: 'salesOrderChannel',
      locked: true,
      sortable: false,
      type: 'string',
      visibility: true,
      filterable: true,
      resizable: true,
    },
    {
      name: geti18nName('state', intl, intlKey),
      key: 'state',
      locked: true,
      sortable: false,
      type: 'enum',
      visibility: true,
      filterable: true,
      resizable: true,
      options: getEnumOptions(intl, ReturnState),
      formatter: enumFormatter,
      getRowMetaData: () => {
        return intl;
      },
    },
    {
      name: geti18nName('customerFullName', intl, intlKey),
      key: 'customerFullName',
      locked: true,
      sortable: false,
      type: 'string',
      visibility: true,
      filterable: true,
      resizable: true,
    },
    {
      name: geti18nName('trackingId', intl, intlKey),
      key: 'trackingId',
      locked: true,
      sortable: false,
      type: 'string',
      visibility: true,
      filterable: true,
      resizable: true,
    },
    {
      name: geti18nName('city', intl, intlKey),
      key: 'city',
      locked: true,
      sortable: true,
      type: 'string',
      visibility: true,
      filterable: true,
      resizable: true,
    },
    {
      name: geti18nName('createdAt', intl, intlKey),
      key: 'createdAt',
      locked: true,
      sortable: true,
      type: 'moment',
      visibility: true,
      filterable: true,
      resizable: true,
      formatter: dateTimeFormatter,
    },
    {
      name: geti18nName('arrivedToWarehouseAt', intl, intlKey),
      key: 'arrivedToWarehouseAt',
      locked: true,
      sortable: true,
      type: 'moment',
      visibility: true,
      filterable: true,
      resizable: true,
      formatter: dateTimeFormatter,
    },
    /*
    {
      name: geti18nName('courierRequest', intl, intlKey),
      key: 'courierRequest',
      type: 'string',
      locked: true,
      sortable: false,
      visibility: true,
      filterable: true,
      resizable: true,
      formatter: (data: FormatterProps) => {
        const { value } = data;
        const { No, Requested, Success, Fail } = ReturnCourierRequest;

        if (value === No || value === Requested || value === Success || value === Fail) {
          return <Text>{intl.messages[`Enum.ReturnCourierRequest.${value}`]}</Text>;
        }
        return null;
      },
      getRowMetaData: () => {
        return intl;
      },
      
    },
    */
  ];

  const toggleExcelExportModal = () => {
    setIsExcelExportModalOpen(true);
  };

  const onModalClose = () => {
    setIsExcelExportModalOpen(false);
  };

  return (
    <Box>
      <GridContainer
        titleKey={
          titleKey ? `${intl.messages['Returns.Grid.Title']} - ${titleKey}` : intl.messages['Returns.Grid.Title']
        }
        intlKey={intlKey}
        gridKey={GridType.Returns}
        remoteExportToExcel
        onExcelExportButtonClick={toggleExcelExportModal}
        columns={returnsGridColumns}
        predefinedFilters={[]}
        intl={intl}
        sortField={returnsGridInitialSort}
      />
      {isExcelExportModalOpen && (
        <ExcelExportModal
          excelToExport={excelToExport}
          intl={intl}
          onModalClose={onModalClose}
          excelExportResource={excelExportResource}
          excelExportDetailedResource={excelExportDetailedResource}
          excelToExportInit={excelToExportInit}
          excelExportType={ExportTypes.Returns}
          rowCount={EXCEL_EXPORT_RETURNS_LIMIT}
          defaultExportLimit={EXCEL_EXPORT_LIMIT}
          detailedExportLimit={EXCEL_EXPORT_RETURNS_LIMIT}
        />
      )}
    </Box>
  );
};

import { Ellipsis, PseudoBox } from '@oplog/express';
import * as React from 'react';

export interface FormatterProps {
    value: any;
    dependentValues: any;
}


// TODO: Move this formatter inside the projects
// function returns a linkFormatter given route and route field key to be replaced by data grid row's dependent values
export function customLinkFormatter(history: any, route: string, pound: boolean, encode: boolean, ...fields: string[]) {
    return (props: FormatterProps) => {
        const { value, dependentValues } = props;

        const customDependentValues: { [key: string]: any } = {
            referenceNumber: value
        }

        if (value === 'N/A') {
            return value;
        }

        let url: string = route;
        fields.map(field => {
            if (customDependentValues[field] === undefined) {
                console.error(`field "${field}" is not present in row data`);
                url = '#';
                const urlValue = encode ? encodeURIComponent(customDependentValues[field]) : customDependentValues[field];
                url = url.replace(`:${field}`, urlValue);
                url = url.replace(`:${field}`, urlValue);
            }
        });

        const urlValue = encode ? encodeURIComponent(customDependentValues[fields[0]]) : customDependentValues[fields[0]];
        url = url.replace(`:${fields[0]}`, urlValue);

        const text = value ? (pound ? `#${value}` : value) : '';

        const link = (
            <PseudoBox as="a" href={url} >
                <Ellipsis>{text} </Ellipsis>
            </PseudoBox>
        );

        return link;
    };
}
import React, { useEffect, useState } from 'react';
import ReactDataGrid from 'react-data-grid';
import { CustomSalesOrderDetailsLinkFormatter, geti18nName } from '@containers/formatters';
import { Box, Button, Ellipsis, Flex, Text } from '@oplog/express';
import { StockQuantityDetailsExtendedDataGridRow } from '@utils';

interface CleanBlockedSalesOrdersPreviewGridProps {
    data: StockQuantityDetailsExtendedDataGridRow[];
    intl: any;
}

export const CleanBlockedSalesOrdersPreviewGrid = ({ intl, data }: CleanBlockedSalesOrdersPreviewGridProps) => {
    const intlKey = 'CleanBlockedReviewGrid';
    const [currentPage, setCurrentPage] = useState(0);
    const [pageSize, setPageSize] = useState(20);

    useEffect(() => {
        let gridHeaders = document.getElementsByClassName('react-grid-Header');
        let gridHeader = gridHeaders[1] as HTMLElement;
        if (gridHeader) {
            gridHeader.style.top = '0px';
        }
    }, []);

    // Prepare data with empty rows between different referenceNumbers without sorting
    const prepareDataWithEmptyRows = (data: StockQuantityDetailsExtendedDataGridRow[]) => {
        const modifiedData = [];
        let index = 0;

        for (let i = 0; i < data.length; i++) {
            modifiedData.push(data[i]);
            
            if (i < data.length - 1 && data[i].referenceNumber !== data[i + 1].referenceNumber) {
                // Insert an empty row between different referenceNumber groups
                modifiedData.push({ isEmptyRow: true });
                data[i].index = index + 1;
                index = 0;  // Reset the index for the next group
                data[i + 1].index = index + 1;
            } else {
                data[i].index = index + 1;
                index++;
            }
        }
        return modifiedData;
    };

    const handlePageChange = (newPage: any) => {
        if (newPage >= 0 && newPage < totalPages) {
            setCurrentPage(newPage);
        }
    };

    // Modify rowGetter to inject index into each row and insert empty rows
    const rowGetter = (i: number) => {
        const startIndex = currentPage * pageSize;
        const preparedData = prepareDataWithEmptyRows(data);
        const row = { ...preparedData[startIndex + i] };

        return row;
    };

    const cleanBlockedGridColumns = [
        {
            name: '#',
            key: 'index',
            resizable: true,
            locked: true,
            sortable: false,
            type: 'number',
            visibility: true,
            filterable: false,
            width: 50,
        },
        {
            name: geti18nName('referenceNumber', intl, intlKey),
            key: 'referenceNumber',
            resizable: true,
            locked: true,
            sortable: true,
            type: 'string',
            visibility: true,
            filterable: true,
            formatter: CustomSalesOrderDetailsLinkFormatter,
        },
        {
            name: geti18nName('sku', intl, intlKey),
            key: 'sku',
            locked: true,
            sortable: true,
            type: 'string',
            visibility: true,
            filterable: true,
            resizable: true,
        },
        {
            name: geti18nName('amountInOrder', intl, intlKey),
            key: 'amountInOrder',
            locked: true,
            sortable: true,
            type: 'number',
            visibility: true,
            filterable: true,
            resizable: true,
        },
        {
            name: geti18nName('missingAmount', intl, intlKey),
            key: 'missingAmount',
            locked: true,
            sortable: true,
            type: 'number',
            visibility: true,
            filterable: true,
            resizable: true,
        },
        {
            name: geti18nName('usingAmount', intl, intlKey),
            key: 'usingAmount',
            locked: true,
            sortable: true,
            type: 'number',
            visibility: true,
            filterable: true,
            resizable: true,
        },
    ];

    const columns = cleanBlockedGridColumns.map((col) => ({
        key: col.key,
        name: col.name,
        width: col.width,
        formatter: col.formatter,
        headerRenderer: (
            <Box width="full" display="flex" alignItems="center">
                <Box
                    fontSize="11"
                    textTransform="uppercase"
                    width="full"
                    color="palette.grey_darker"
                    display="inline"
                    mr="5px"
                >
                    <Ellipsis>{col.name}</Ellipsis>
                </Box>
            </Box>
        )
    }));

    const totalPages = Math.ceil(prepareDataWithEmptyRows(data).length / pageSize);

    return (
        <div>
            <ReactDataGrid
                columns={columns}
                rowGetter={rowGetter}
                rowsCount={Math.min(pageSize, prepareDataWithEmptyRows(data).length - currentPage * pageSize)}
                rowHeight={35}
                minHeight={300}
            />

            {/* Pagination Controls */}
            <Flex mt="10px">
                <Button
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 0}
                    size='small'
                    variant='alternative'
                >
                    Previous
                </Button>

                <Text mx="10px" my="15px">
                    Page {currentPage + 1} of {totalPages}
                </Text>

                <Button
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage + 1 === totalPages}
                    size='small'
                >
                    Next
                </Button>
            </Flex>
        </div>
    );
};

import { FormatterProps } from '@oplog/data-grid';
import { Box, Flex, Text } from '@oplog/express';
import * as React from 'react';

export const salesOrderDetailBlockedBadgeFormatter = (props: FormatterProps) => {
  return props.value > 0 ? (
    <Flex justifyContent="flex-start">
      <Box
        width="auto"
        backgroundColor="palette.red_darker"
        textAlign="center"
        borderRadius="16px"
        paddingY={4}
        paddingX={10}
      >
        <Text color="palette.white" py={1} fontSize={13} fontWeight={600} lineHeight="normal">
          {props.value} {props.dependentValues.intl.messages['Enum.Missing']}
        </Text>
      </Box>
    </Flex>
  ) : (
    <Flex justifyContent="flex-start">-</Flex>
  );
};

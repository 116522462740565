import { ReceivingOrdersGrid } from '@components';
import { MemberPageContainer } from '@containers/MemberPageContainer/MemberPageContainer';
import { ResourceType } from '@models';
import { ActionBar, Button, Flex, LayoutContent, Panel, PanelTitle } from '@oplog/express';
import useCommonDataStore from '@store/commonData/commonDataState';
import * as React from 'react';
import { Props } from '../../atoms/Component/Component';

const COMPONENT_INTL_KEY = 'ReceivingOrders';

export interface ReceivingOrdersProps extends Props {
  routeSendToWarehouse: () => void;
  gridFilters: any;
  excelToExport: (resourceType: ResourceType) => void;
  excelToExportInit: () => void;
  excelExportResource: any;
  excelExportDetailedResource: any;
}

export const ReceivingOrders: React.FC<ReceivingOrdersProps> = ({
  routeSendToWarehouse,
  gridFilters,
  excelToExport,
  intl,
  excelToExportInit,
  excelExportResource,
  excelExportDetailedResource,
}) => {
  const breadcrumb = [{ title: intl.messages[`${COMPONENT_INTL_KEY}.Header.Title`] }];

  const [{ integrations }, { setIntegrations }] = useCommonDataStore();

  return (
    <MemberPageContainer documentTitle={intl.messages[`${COMPONENT_INTL_KEY}.Header.Title`]}>
      <ActionBar top="66px" title={intl.messages[`${COMPONENT_INTL_KEY}.Header.Title`]} breadcrumb={breadcrumb}>
        <Button variant="dark" marginLeft="auto" onClick={routeSendToWarehouse}>
          {intl.messages[`${COMPONENT_INTL_KEY}.Header.SendToWarehouse`]}
        </Button>
      </ActionBar>

      <LayoutContent className="page-content">
        <Panel>
          <Flex justifyContent="space-between">
            <PanelTitle>{intl.messages[`${COMPONENT_INTL_KEY}.Grid.Title`]}</PanelTitle>
          </Flex>
          <ReceivingOrdersGrid
            intl={intl}
            integrations={integrations}
            excelToExport={excelToExport}
            gridFilters={gridFilters}
            excelExportResource={excelExportResource}
            excelExportDetailedResource={excelExportDetailedResource}
            excelToExportInit={excelToExportInit}
          />
        </Panel>
      </LayoutContent>
    </MemberPageContainer>
  );
};

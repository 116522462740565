import { GridType, ResourceType } from '@models';
import { gridActions } from '@oplog/data-grid';
import { GridFetchRequested } from '@oplog/data-grid/dist/store/actions';
import { resourceActions, resourceSelectors } from '@oplog/resource-redux';
import { RouterAction } from 'connected-react-router';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import {
  SupportTimeRangeSubmitModal,
  SupportTimeRangeSubmitModalProps,
} from '../../components/molecules/SupportTimeRangeSubmitModal';
import { StoreState } from '../../store/state';

export interface SSupportPackagesWMSSyncContainerProps {
  onClose: () => void;
}

function mapStateToProps(
  { resources }: StoreState,
  ownProps: SSupportPackagesWMSSyncContainerProps
): Partial<SupportTimeRangeSubmitModalProps> {
  return {
    responseData: resourceSelectors.getData(resources, ResourceType.PackagesSyncFromWms),
    isBusy: resourceSelectors.isBusy(resources, ResourceType.PackagesSyncFromWms),
    hasError: resourceSelectors.hasError(resources, ResourceType.PackagesSyncFromWms),
    COMPONENT_INTL_KEY: 'SupportPackagesWMSSync.Modal',
  };
}

function mapDispatchToProps(
  dispatch: Dispatch<resourceActions.ResourceAction | RouterAction | GridFetchRequested>
): Partial<SupportTimeRangeSubmitModalProps> {
  return {
    syncCommandTimeRange: (startDate: Date, endDate: Date) => {
      const jsonObject = {
        fetchByTimeRangeMethod: {
          start: startDate,
          end: endDate,
        },
      };
      dispatch(resourceActions.resourceRequested(ResourceType.PackagesSyncFromWms, { jsonObject }));
    },
    onWillUnmount: () => {
      dispatch(resourceActions.resourceInit(ResourceType.PackagesSyncFromWms));
      dispatch(gridActions.gridFetchRequested(GridType.SupportPackages));
    },
  };
}

export const SupportPackagesWMSSyncContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(SupportTimeRangeSubmitModal));

import { Pipeline, PipelineProps } from '@components';
import { resourceActions, resourceSelectors } from '@oplog/resource-redux';
import { StoreState } from '@store';
import { pipelineSelectors } from '@store/pipeline';
import { InjectedIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

export interface PipelineContainerProps {
  pipelineKey: string;
  apiParam: any;
  intl: InjectedIntl;
  enumIntlKey: string;
  detailsIntlKey?: string;
  salesOrderPackages?: object;
}

function mapStateToProps({ resources }: StoreState, ownProps: PipelineContainerProps): Partial<PipelineProps> {
  const { pipelineKey, intl, enumIntlKey, detailsIntlKey } = ownProps;

  return {
    isBusy: resourceSelectors.isBusy(resources, pipelineKey),
    error: resourceSelectors.getError(resources, pipelineKey),
    intl,
    enumIntlKey,
    detailsIntlKey,
    ...pipelineSelectors.getStatusDetails(pipelineKey, resources),
  };
}

function mapDispatchToProps(
  dispatch: Dispatch<resourceActions.ResourceAction>,
  ownProps: PipelineContainerProps
): Partial<PipelineProps> {
  const { pipelineKey, apiParam } = ownProps;

  return {
    onDidMount() {
      dispatch(resourceActions.resourceRequested(pipelineKey, apiParam));
    },
    onWillUnmount() {
      dispatch(resourceActions.resourceInit(pipelineKey));
    },
  };
}

export const PipelineContainer = connect(mapStateToProps, mapDispatchToProps)(Pipeline);

import { GridContainer } from '@components/atoms/GridContainer';
import { barcodeFormatter, geti18nName, ProductDetailsLinkFormatter } from '@containers/formatters';
import { GridProps, GridType } from '@models';
import { ColumnSize, DataGridColumn, DataGridRow, enumFormatter, getEnumOptions, ImageFormatter, PredefinedFilter } from '@oplog/data-grid';
import { InventoryItemType } from '@services';
import { ArrayFilterOperation, NumericFilter, NumericFilterOperation, SortDirection, SortField } from 'dynamic-query-builder-client';
import * as React from 'react';

const intlKey = 'InventorySerialNumbersGrid';

const productSerialNumbersGridInitalSort: SortField = new SortField({
  property: 'sku',
  by: SortDirection.ASC,
});

export const productSerialNumbersGridPredefinedFilters: Array<PredefinedFilter> = [

];

export interface ProductSerialNumbersGridProps extends GridProps {
  sku: string;
}

export class ProductSerialNumbersGrid extends React.Component<ProductSerialNumbersGridProps> {
  render() {
    const { intl, titleKey } = this.props;
    const productSerialNumbersGridColumns: Array<DataGridColumn> = [
      {
        name: '#',
        key: 'index',
        resizable: true,
        locked: true,
        sortable: false,
        type: 'number',
        visibility: true,
        filterable: false,
        width: ColumnSize.Medium,
        cellClass: 'index-column',
      },
      {
        name: geti18nName('sku', intl, intlKey),
        key: 'sku',
        locked: true,
        sortable: true,
        type: 'string',
        visibility: true,
        filterable: true,
        resizable: true,
      },
      {
        name: geti18nName('barcode', intl, intlKey),
        key: 'barcodes',
        locked: true,
        type: 'string',
        visibility: true,
        resizable: true,
        sortable: false,
        filterable: true,
        formatter: barcodeFormatter,
        fieldType: 'array',
        searchField: '_',
        outerOp: ArrayFilterOperation.Any,
      },
      {
        name: geti18nName('serialNumber', intl, intlKey),
        key: 'serialNumber',
        locked: true,
        sortable: false,
        type: 'string',
        visibility: true,
        filterable: true,
        formatter: ProductDetailsLinkFormatter,
        getRowMetaData: (row: DataGridRow) => row,
        resizable: true,
      },
      {
        name: geti18nName('imageUrl', intl, intlKey),
        key: 'imageUrl',
        locked: true,
        sortable: false,
        type: 'string',
        visibility: true,
        filterable: false,
        formatter: ImageFormatter,
        width: ColumnSize.Medium,
      },
      {
        name: geti18nName('name', intl, intlKey),
        key: 'name',
        locked: true,
        sortable: false,
        type: 'string',
        visibility: true,
        filterable: true,
        formatter: ProductDetailsLinkFormatter,
        getRowMetaData: (row: DataGridRow) => row,
        resizable: true,
      },
      {
        name: geti18nName('inventoryType', intl, intlKey),
        key: 'inventoryItemType',
        resizable: true,
        sortable: false,
        type: 'enum',
        options: getEnumOptions(intl, InventoryItemType),
        visibility: true,
        filterable: true,
        formatter: enumFormatter,
        getRowMetaData: () => {
          return intl;
        },
      },
    ];
    return (
      <GridContainer
        titleKey={
          titleKey ? `${intl.messages['StockStatus.Grid.SerialNumbers']} - ${titleKey}` : intl.messages['StockStatus.Grid.SerialNumbers']
        }
        intlKey={intlKey}
        remoteExportToExcel
        gridKey={GridType.ProductSerialNumbers}
        columns={productSerialNumbersGridColumns}
        predefinedFilters={productSerialNumbersGridPredefinedFilters}
        apiArgs={[this.props.sku]}
        intl={intl}
        sortField={productSerialNumbersGridInitalSort}
      />
    );
  }
};

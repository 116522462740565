import React from 'react';
import { Box, Flex } from '@oplog/express';

export const MainContent: React.FC<any> = props => {
  return (
    <Flex className={props.className}>
      <Box width={1} pl="22" pr="22" pt="16">
        {props.children}
      </Box>
    </Flex>
  );
};

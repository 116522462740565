import { CreateReturnLineItemsGrid } from '@components';
import { CreateReturnContextualPanelContainer } from '@containers/CreateReturnContextualPanelContainer/CreateReturnContextualPanelContainer';
import { MemberPageContainer } from '@containers/MemberPageContainer';
import { ActionBar, Alert, AlertDescription, AlertTitle, LayoutContent, Panel } from '@oplog/express';
import { urls } from '@routes/urls';
import { SalesOrderQueryOutputDTO } from '@services';
import * as React from 'react';
import { Props } from '../../atoms/Component/Component';

export interface CreateReturnProps extends Props {
  selectedSalesOrder: SalesOrderQueryOutputDTO;
  onDismissPostErrors: () => void;
  clearRoute: () => void;
}

const COMPONENT_INTL_KEY = 'CreateReturn';

export const CreateReturn: React.FC<CreateReturnProps> = ({ intl, onDismissPostErrors }) => {
  const [isPostErrorOpen, setIsPostErrorOpen] = React.useState(false);
  const [data, setData] = React.useState<SalesOrderQueryOutputDTO>();

  const breadcrumb = [
    { title: `${intl.messages['Returns.Header.Title']}`, url: urls.returns },
    { title: intl.messages[`${COMPONENT_INTL_KEY}.Header.Title`] },
  ];

  const handlePostErrorDismiss = () => {
    setIsPostErrorOpen(false);
    onDismissPostErrors();
  };

  return (
    <MemberPageContainer
      documentTitle={intl.messages[`${COMPONENT_INTL_KEY}.Header.Title`]}
      contextualArea={{
        header: intl.messages[`${COMPONENT_INTL_KEY}.ContextualPanel.Title`],
        component: <CreateReturnContextualPanelContainer salesOrder={data} />,
      }}
    >
      <ActionBar top="66px" title={intl.messages[`${COMPONENT_INTL_KEY}.Header.Title`]} breadcrumb={breadcrumb} />
      <Alert variant="danger" isOpen={isPostErrorOpen} onDismiss={handlePostErrorDismiss}>
        <AlertTitle>{intl.messages['PostErrorMessage.Title']}</AlertTitle>
        <AlertDescription>{intl.messages['PostErrorMessage.Description']}</AlertDescription>
      </Alert>
      <LayoutContent>
        <Panel title={intl.messages[`${COMPONENT_INTL_KEY}.Grid.Title`]}>
          <CreateReturnLineItemsGrid intl={intl} selectItem={item => setData(item)} salesOrder={data} />
        </Panel>
      </LayoutContent>
    </MemberPageContainer>
  );
};

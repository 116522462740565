import { GeneralErrorPanel, MainContent } from '@components';
import { CreateCustomerShippingAddressFormContainer } from '@containers/CreateCustomerShippingAddressFormContainer/CreateCustomerShippingAddressFormContainer';
import { CustomerDetailsTabMenuContainer } from '@containers/CustomerDetailsTabMenuContainer';
import { EditCustomerInfoFormContainer } from '@containers/EditCustomerInfoFormContainer/EditCustomerInfoFormContainer';
import { EditCustomerShippingAddressesFormContainer } from '@containers/EditCustomerShippingAddressesFormContainer/EditCustomerShippingAddressesFormContainer';
import { MemberPageContainer } from '@containers/MemberPageContainer/MemberPageContainer';
import {
  ActionBar,
  Alert,
  AlertDescription,
  AlertTitle,
  Box,
  Button,
  Dialog,
  DialogTypes,
  Flex,
  formatUtcToLocal,
  Icon,
  Modal,
  ModalContent,
  ModalHeader,
  ModalTitle,
  Panel,
  Widget,
} from '@oplog/express';
import { urls } from '@routes/urls';
import { CustomerDetailOutputDTO } from '@services';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Props } from '../../atoms/Component/Component';

const COMPONENT_INTL_KEY = 'CustomerDetails';
const COMPONENT_NEW_ADDRESS_INTL_KEY = 'CustomerNewAddress';

export interface CustomerDetailsProps extends Props {
  customerDetails: CustomerDetailOutputDTO;
  onCreateShippingAddressSuccessModalClose: () => void;
  onEditShippingAddressSuccessModalClose: () => void;
  onNewAddresClick: () => void;
  onNewAddressModalClose: () => void;
  isEditCustomerInfoModalOpen: boolean;
  isNewAddressModalOpen: boolean;
  isNewAddressCreateSuccess: boolean;
  hasError: boolean;
  initResources: () => void;
  getUserInfo: () => void;
}

export interface CustomerDetailsState {
  isInfoEditOpen: boolean;
  isAddressEditOpen: boolean;
}

export const CustomerDetails: React.FC<CustomerDetailsProps> = ({
  customerDetails,
  intl,
  onCreateShippingAddressSuccessModalClose,
  onEditShippingAddressSuccessModalClose,
  onNewAddresClick,
  onNewAddressModalClose,
  isEditCustomerInfoModalOpen,
  isBusy,
  isNewAddressModalOpen,
  isNewAddressCreateSuccess,
  hasError,
  initResources,
  getUserInfo,
  error,
  onDidMount,
  onWillUnmount,
}) => {
  const subtitle = intl.messages[`${COMPONENT_INTL_KEY}.Header.Subtitle`];
  const documentTitle = customerDetails ? `${customerDetails.fullName} - ${subtitle}` : '';

  const breadcrumb = [
    { title: `${intl.messages['Customers.Header.Title']}`, url: urls.customers },
    { title: customerDetails ? customerDetails.fullName : '' },
  ];

  const [modalState, setModalState] = useState({
    isInfoEditOpen: false,
    isAddressEditOpen: false,
  });

  useEffect(() => {
    onDidMount && onDidMount();
    return () => {
      onWillUnmount && onWillUnmount();
    };
  }, []);

  const onEditShippingAddressSuccess = () => {
    setModalState({
      ...modalState,
      isAddressEditOpen: false,
    });
    onEditShippingAddressSuccessModalClose();
  };

  const renderNewAddressSuccessModal = () => {
    const customerName = customerDetails ? customerDetails.fullName : '';
    return (
      <Dialog
        type={DialogTypes.Success}
        message={
          <FormattedMessage
            id={`${COMPONENT_NEW_ADDRESS_INTL_KEY}.Modal.Success`}
            values={{
              CustomerName: <b>{customerName}</b>,
            }}
          />
        }
        isOpen={isNewAddressCreateSuccess}
        onCancel={onCreateShippingAddressSuccessModalClose}
        onApprove={onCreateShippingAddressSuccessModalClose}
        text={{ approve: intl.messages[`Modal.Success.Okay`] }}
      />
    );
  };

  const renderNewAddressModal = () => {
    if (isNewAddressModalOpen) {
      return (
        <Modal size="3xl" isOpen={isNewAddressModalOpen} onClose={onNewAddressModalClose}>
          <ModalHeader>
            <ModalTitle>{intl.messages[`${COMPONENT_NEW_ADDRESS_INTL_KEY}.Header.Title`]}</ModalTitle>
          </ModalHeader>
          <ModalContent>
            <CreateCustomerShippingAddressFormContainer
              customerNo={customerDetails.customerNo}
              onModalClose={onNewAddressModalClose}
            />
          </ModalContent>
        </Modal>
      );
    }
    return undefined;
  };

  const renderEditCustomerInfoModal = () => {
    if (modalState.isInfoEditOpen) {
      return (
        <EditCustomerInfoFormContainer
          onModalClose={() =>
            setModalState({
              ...modalState,
              isInfoEditOpen: false,
            })
          }
          onSuccessClose={getUserInfo}
          customerNo={customerDetails.customerNo}
        />
      );
    }
    return undefined;
  };

  const renderEditCustomerShippingAddressessModal = () => {
    return (
      <EditCustomerShippingAddressesFormContainer
        customerNo={customerDetails.customerNo}
        onModalClose={() =>
          setModalState({
            ...modalState,
            isAddressEditOpen: false,
          })
        }
        onSuccessClose={onEditShippingAddressSuccess}
      />
    );
  };

  const renderInfoBoxes = () => {
    if (error !== undefined) {
      return <GeneralErrorPanel className="align-left" />;
    }
    return (
      <Flex flexDirection="row" flexWrap="wrap">
        <Box width={[1, 1, 1 / 2, 1 / 4]} pr={['0', '0', '30']} mb={['22', '22', '22', 0]}>
          <Widget.Two
            title={!customerDetails ? '' : customerDetails.customerNo.toString()}
            subtitle={intl.messages[`${COMPONENT_INTL_KEY}.Customer.CustomerNo`]}
            iconContainerProps={{ bg: 'palette.blue' }}
            loading={isBusy}
            icon={<Icon color="white" name="fas fa-hashtag" fontSize="56px" />}
            titleProps={{
              wordBreak: 'break-word',
            }}
          />
        </Box>
        <Box width={[1, 1, 1 / 2, 1 / 4]} pr={['0', '0', '0', '30']} mb={['22', '22', '22', 0]}>
          <Widget.Two
            title={!customerDetails ? '' : customerDetails.phone}
            subtitle={intl.messages[`${COMPONENT_INTL_KEY}.Customer.Phone`]}
            iconContainerProps={{ bg: 'palette.orange' }}
            loading={isBusy}
            icon={<Icon color="white" name="fas fa-phone" fontSize="56px" />}
            titleProps={{
              wordBreak: 'break-word',
            }}
          />
        </Box>
        <Box width={[1, 1, 1 / 2, 1 / 4]} pr={['0', '0', '30']} mb={['22', '22', '22', 0]}>
          <Widget.Two
            title={!customerDetails ? '' : customerDetails.email}
            subtitle={intl.messages[`${COMPONENT_INTL_KEY}.Customer.Email`]}
            iconContainerProps={{ bg: 'palette.green' }}
            loading={isBusy}
            tooltip={{
              content: `${!customerDetails ? '' : customerDetails.email}`,
              placement: 'bottom',
              action: ['hover', 'focus'],
            }}
            icon={<Icon color="white" name="fas fa-envelope" fontSize="56px" />}
            titleProps={{
              wordBreak: 'break-word',
            }}
          />
        </Box>
        <Box width={[1, 1, 1 / 2, 1 / 4]} pr="0" mb={['22', '22', '22', '0']}>
          <Widget.Two
            title={!customerDetails ? '' : formatUtcToLocal(customerDetails.createdAt)}
            subtitle={intl.messages[`${COMPONENT_INTL_KEY}.Customer.CreatedAt`]}
            iconContainerProps={{ bg: 'palette.red' }}
            loading={isBusy}
            icon={<Icon color="white" name="fas fa-calendar-alt" fontSize="56px" />}
            titleProps={{
              wordBreak: 'break-word',
            }}
          />
        </Box>
      </Flex>
    );
  };

  return (
    <MemberPageContainer documentTitle={documentTitle}>
      <ActionBar
        top="66px"
        isBusy={isBusy}
        title={customerDetails ? customerDetails.fullName : subtitle}
        subtitle={customerDetails ? subtitle : ''}
        breadcrumb={breadcrumb}
      >
        <Flex marginLeft="auto">
          <Button mr="6" onClick={onNewAddresClick}>
            {intl.messages[`${COMPONENT_INTL_KEY}.Header.Action.AddNewAddress`]}
          </Button>
          <Button
            variant="dark"
            size="large"
            dropdown={{
              items: [
                {
                  text: intl.messages[`${COMPONENT_INTL_KEY}.Header.Action.EditCustomer.CustomerInfo`],
                  value: intl.messages[`${COMPONENT_INTL_KEY}.Header.Action.EditCustomer.CustomerInfo`],
                  onClick: () =>
                    setModalState({
                      ...modalState,
                      isInfoEditOpen: true,
                    }),
                },
                {
                  text: intl.messages[`${COMPONENT_INTL_KEY}.Header.Action.EditCustomer.Address`],
                  value: intl.messages[`${COMPONENT_INTL_KEY}.Header.Action.EditCustomer.Address`],
                  onClick: () =>
                    setModalState({
                      ...modalState,
                      isAddressEditOpen: true,
                    }),
                },
              ],
            }}
          >
            {intl.messages[`${COMPONENT_INTL_KEY}.Header.Action.EditCustomer.Title`]}
          </Button>
        </Flex>
      </ActionBar>
      <Alert variant="danger" isOpen={hasError} onDismiss={initResources}>
        <AlertTitle>{intl.messages['PostErrorMessage.Title']}</AlertTitle>
        <AlertDescription>{intl.messages['PostErrorMessage.Description']}</AlertDescription>
      </Alert>
      <MainContent>
        <Panel className="flex" title={intl.messages[`${COMPONENT_INTL_KEY}.CustomerInfo`]}>
          {renderInfoBoxes()}
          <Box pt="22">
            <CustomerDetailsTabMenuContainer
              intl={intl}
              customerName={customerDetails ? customerDetails.fullName : ''}
            />
          </Box>
        </Panel>
      </MainContent>
      {customerDetails && (
        <>
          {renderNewAddressModal()}
          {renderNewAddressSuccessModal()}
          {renderEditCustomerInfoModal()}
        </>
      )}
      {modalState.isAddressEditOpen && renderEditCustomerShippingAddressessModal()}
    </MemberPageContainer>
  );
};

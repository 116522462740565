import { GridContainer } from '@components/atoms/GridContainer';
import { bubbleStepFormatter, geti18nName, ReceivingOrderDetailsLinkFormatter } from '@containers/formatters';
import { ColumnSize, DataGridColumn, DataGridRow, dateTimeFormatter, getEnumOptions } from '@oplog/data-grid';
import { ReceivingOrderState } from '@services';
import { SortDirection, SortField } from 'dynamic-query-builder-client';
import * as React from 'react';
import { GridProps, GridType } from '../../../models';

const intlKey = 'SupportReceivingOrderGrid';

const tenantGridInitalSort: SortField = new SortField({
  property: 'createdAt',
  by: SortDirection.DESC,
});

export interface SupportReceivingOrderGridProps extends GridProps {}

export class SupportReceivingOrderGrid extends React.Component<SupportReceivingOrderGridProps> {
  render() {
    const { intl, titleKey } = this.props;
    const TenantReceivingOrdersGridColumns: Array<DataGridColumn> = [
      {
        name: '#',
        key: 'index',
        resizable: false,
        locked: true,
        sortable: false,
        type: 'number',
        visibility: true,
        filterable: false,
        cellClass: 'index-column',
        width: ColumnSize.Medium,
      },
      {
        name: geti18nName('id', intl, intlKey),
        key: 'id',
        resizable: true,
        locked: true,
        sortable: true,
        type: 'string',
        visibility: true,
        filterable: true,
      },
      {
        name: geti18nName('referenceNumber', intl, intlKey),
        key: 'referenceNumber',
        resizable: true,
        locked: true,
        sortable: true,
        type: 'string',
        visibility: true,
        filterable: true,
        formatter: ReceivingOrderDetailsLinkFormatter,
        getRowMetaData: (row: DataGridRow) => row,
      },
      {
        name: geti18nName('state', intl, intlKey),
        key: 'state',
        locked: true,
        sortable: false,
        type: 'enum',
        visibility: true,
        filterable: true,
        formatter: bubbleStepFormatter,
        getRowMetaData: (row: DataGridRow) => {
          return {
            intl,
            enum: ReceivingOrderState,
            row,
          };
        },
        resizable: true,
        options: getEnumOptions(intl, ReceivingOrderState),
      },
      {
        name: geti18nName('wmsSyncState', intl, intlKey),
        key: 'wmsSyncState',
        resizable: true,
        locked: true,
        sortable: true,
        type: 'string',
        visibility: true,
        filterable: true,
      },
      {
        name: geti18nName('wmsSyncRetryCount', intl, intlKey),
        key: 'wmsSyncRetryCount',
        resizable: true,
        locked: true,
        sortable: true,
        type: 'number',
        visibility: true,
        filterable: true,
      },
      {
        name: geti18nName('arrivedToWarehouseAt', intl, intlKey),
        key: 'arrivedToWarehouseAt',
        locked: true,
        sortable: true,
        type: 'moment',
        visibility: true,
        filterable: true,
        resizable: true,
        formatter: dateTimeFormatter,
      },
      {
        name: geti18nName('completedAt', intl, intlKey),
        key: 'completedAt',
        locked: true,
        sortable: true,
        type: 'moment',
        visibility: true,
        filterable: true,
        resizable: true,
        formatter: dateTimeFormatter,
      },
      {
        name: geti18nName('createdAt', intl, intlKey),
        key: 'createdAt',
        locked: true,
        sortable: true,
        type: 'moment',
        visibility: true,
        filterable: true,
        resizable: true,
        formatter: dateTimeFormatter,
      },
      {
        name: geti18nName('updatedAt', intl, intlKey),
        key: 'updatedAt',
        locked: true,
        sortable: true,
        type: 'moment',
        visibility: true,
        filterable: true,
        resizable: true,
        formatter: dateTimeFormatter,
      },
      {
        name: geti18nName('cancelledAt', intl, intlKey),
        key: 'cancelledAt',
        locked: true,
        sortable: true,
        type: 'moment',
        visibility: true,
        filterable: true,
        resizable: true,
        formatter: dateTimeFormatter,
      },
      {
        name: geti18nName('deletedAt', intl, intlKey),
        key: 'deletedAt',
        locked: true,
        sortable: true,
        type: 'moment',
        visibility: true,
        filterable: true,
        resizable: true,
        formatter: dateTimeFormatter,
      },
    ];

    return (
      <GridContainer
        titleKey={titleKey ? `${intl.messages['Grid.Title']} - ${titleKey}` : intl.messages['Grid.Title']}
        intlKey={intlKey}
        gridKey={GridType.SupportReceivingOrders}
        columns={TenantReceivingOrdersGridColumns}
        apiArgs={[]}
        predefinedFilters={[]}
        intl={this.props.intl}
        sortField={tenantGridInitalSort}
        actionsDisabled={true}
      />
    );
  }
}

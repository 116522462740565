export const generateIntegrationEnum = (integrationData: any) => {
  const IntegrationNames: any = [];
  integrationData.forEach((integration: any) => {
    IntegrationNames.push({
      label: integration.name,
      value: integration.name,
    });
  });
  return IntegrationNames;
};

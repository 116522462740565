import {
  Box,
  Button,
  Dialog,
  DialogTypes,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  FormValidator,
  Icon,
  Input,
  Modal,
  ModalContent,
  ModalHeader,
  ModalTitle,
  SelectHTML,
} from '@oplog/express';
import { Resource } from '@oplog/resource-redux';
import { CreateCustomerCommand, CreatedCustomerOutputDTO, ErrorCode } from '@services';
import {
  addressErrorMessages,
  DropdownOptionsFromResource,
  generateAddressFormSchema,
  generateUserFormSchema,
  userErrorMessageInterface,
} from '@utils';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import useForceUpdate from 'use-force-update';
import { Props } from '../../atoms/Component/Component';

const COMPONENT_INTL_KEY = 'CreateCustomerMultiForm';
const FORM_COMPONENT_INTL_KEY = 'CreateCustomerMultiForm';

export interface CreateCustomerMultiFormProps extends Props {
  validator: FormValidator;
  isModalOpen: boolean;
  onModalClose: (isCancelClickButton: boolean) => void;
  closeModal: () => void;
  onValidationChanged: (isValidList: boolean[]) => void;
  onSubmit: (form: Partial<CreateCustomerCommand>) => void;
  isCreateCustomerSuccess: boolean;
  getNewCustomerInfo: (customerNo: number) => void;
  createdCustomerData: CreatedCustomerOutputDTO;
  isBusy?: boolean;
  error?: ErrorModel;
  countryResource: Resource<string[]>;
  provinceResource: Resource<string[]>;
  districtResource: Resource<string[]>;
  provinceSelect: (command: { province: string }) => void;
  initError: () => void;
}

export interface CreateCustomerMultiFormState {
  customerStepClass: string;
  addressStepClass: string;
  currentStep: number;
  fields: Partial<CreateCustomerCommand>;
}

export const CreateCustomerMultiForm: React.FC<CreateCustomerMultiFormProps> = ({
  intl,
  isBusy,
  onDidMount,
  onWillUnmount,
  validator,
  districtResource,
  onModalClose,
  onSubmit,
  countryResource,
  provinceResource,
  provinceSelect,
  error,
  createdCustomerData,
  getNewCustomerInfo,
  isCreateCustomerSuccess,
  initError,
  closeModal,
}) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [customerStepClass, setCustomerStepClass] = useState('progress');
  const [addressStepClass, setAddressStepClass] = useState('disable');
  const [errorState, setErrorState] = useState();
  const [fields, setFields] = useState<CreateCustomerCommand>({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    addressFirstName: '',
    addressLastName: '',
    company: '',
    addressOne: '',
    addressTwo: '',
    district: '',
    city: '',
    country: intl.messages[`${FORM_COMPONENT_INTL_KEY}.Turkey`],
    addressPhone: '',
    postalCode: '',
  });

  const forceUpdate = useForceUpdate();

  useEffect(() => {
    validator.registerSchema(createCustomerMultiFormSchema());
    onDidMount && onDidMount();

    return () => {
      validator.clearErrors();
      onWillUnmount && onWillUnmount();
    };
  }, []);

  useEffect(() => {
    if (!validator.hasError('email')) {
      setCurrentStep(0);
      setCustomerStepClass('progress');
      setAddressStepClass('disable');
    }
  }, [validator]);

  const handleOnBlur = (key: string) => () => {
    validator.validate(key, fields[key]);
    forceUpdate();
  };

  const handleOnInputChange = (key: keyof CreateCustomerCommand) => (e: any) => {
    let { district } = fields; 
    if (key === 'city' && fields.city !== e.currentTarget.value) {
      provinceSelect({ province: e.currentTarget.value });
      district = '';
    }
    if (key === 'email') {
      setErrorState(undefined);
    }

    if (key === 'country' && fields.country !== e.currentTarget.value) {    
      setFields({
        ...fields,
        city : '',
        district : '',
        [key]: e.currentTarget.value,
      });
    }else{ 
      setFields({
        ...fields,
        district,
        [key]: e.currentTarget.value,
      });
    }
 
    validator.validate(key, e.currentTarget.value);
    forceUpdate();
  };

  const getErrorMessage = (key: string): string => {
    const errors = error ? error.errors : [];
    if (errors) {
      for (const e of errors) {
        if (e.property.toLowerCase() === key && e.errorCode === ErrorCode.ResourceAlreadyExists.toString()) {
          return intl.messages[`${FORM_COMPONENT_INTL_KEY}.Error.CustomerExists`];
        }
      }
    }
    return '';
  };

  const hasError = (key: string): boolean => {
    const errors = error ? error.errors : [];
    if (errors) {
      for (const e of errors) {
        if (e.property.toLowerCase() === key && e.errorCode === ErrorCode.ResourceAlreadyExists.toString()) {
          return true;
        }
      }
    }
    return false;
  };

  const createCustomerMultiFormSchema = () => {
    const userErrorMessages: userErrorMessageInterface = {
      firstName: {
        required: `${intl.messages[`${COMPONENT_INTL_KEY}.Error.Customer.Name.Empty`]}`,
        invalid: `${intl.messages[`${COMPONENT_INTL_KEY}.Error.Customer.Name.Invalid`]}`,
      },
      lastName: {
        required: `${intl.messages[`${COMPONENT_INTL_KEY}.Error.Customer.LastName.Empty`]}`,
        invalid: `${intl.messages[`${COMPONENT_INTL_KEY}.Error.Customer.LastName.Invalid`]}`,
      },
      email: {
        required: `${intl.messages[`${COMPONENT_INTL_KEY}.Error.Customer.Email.Empty`]}`,
        invalid: `${intl.messages[`${COMPONENT_INTL_KEY}.Error.Customer.Email.Invalid`]}`,
      },
      phone: {
        length: `${intl.messages[`${COMPONENT_INTL_KEY}.Error.Customer.PhoneNumber.PhoneLength`]}`,
        type: `${intl.messages[`${COMPONENT_INTL_KEY}.Error.Customer.PhoneNumber.PhoneType`]}`,
        required: `${intl.messages[`${COMPONENT_INTL_KEY}.Error.Customer.PhoneNumber.Empty`]}`,
      },
    };

    const addressErrorMessages: addressErrorMessages = {
      addressFirstName: {
        required: `${intl.messages[`${COMPONENT_INTL_KEY}.Error.Receiver.Name.Empty`]}`,
        invalid: `${intl.messages[`${COMPONENT_INTL_KEY}.Error.Receiver.Name.Invalid`]}`,
      },
      addressLastName: {
        required: `${intl.messages[`${COMPONENT_INTL_KEY}.Error.Receiver.LastName.Empty`]}`,
        invalid: `${intl.messages[`${COMPONENT_INTL_KEY}.Error.Receiver.LastName.Invalid`]}`,
      },

      addressOne: {
        required: `${intl.messages[`${COMPONENT_INTL_KEY}.Error.Receiver.AddressOne`]}`,
      },

      district: {
        required: `${intl.messages[`${COMPONENT_INTL_KEY}.Error.Receiver.District`]}`,
      },

      city: {
        required: `${intl.messages[`${COMPONENT_INTL_KEY}.Error.Receiver.City`]}`,
      },

      country: {
        required: `${intl.messages[`${COMPONENT_INTL_KEY}.Error.Receiver.Country`]}`,
      },

      addressPhone: {
        length: `${intl.messages[`${COMPONENT_INTL_KEY}.Error.Receiver.PhoneNumber.PhoneLength`]}`,
        type: `${intl.messages[`${COMPONENT_INTL_KEY}.Error.Receiver.PhoneNumber.PhoneType`]}`,
        required: `${intl.messages[`${COMPONENT_INTL_KEY}.Error.Receiver.PhoneNumber.Empty`]}`,
      },
    };

    const userSchema = generateUserFormSchema(userErrorMessages);
    const addressSchema = generateAddressFormSchema(addressErrorMessages);
    const combinedSchema = { ...userSchema, ...addressSchema };

    return combinedSchema;
  };

  const isCustomerFormInvalid = (): boolean => {
    const fieldKeys = ['firstName', 'lastName', 'email', 'phone'];
    return fieldKeys.some(key => {
      return !fields[key] || validator.hasError(key);
    });
  };

  const isAddressFormInvalid = (): boolean => { 
    const fieldKeys = ['addressFirstName', 'addressLastName','district','city', 'country', 'addressPhone'];
    return fieldKeys.some(key => {
      return !fields[key] || validator.hasError(key);
    });
  };

  const renderCreateCustomerSuccessModal = () => {
    if (createdCustomerData) {
      const customerName = `${createdCustomerData.firstName} ${createdCustomerData.lastName}`;
      return (
        <Dialog
          type={DialogTypes.Success}
          message={
            <FormattedMessage
              id={`${COMPONENT_INTL_KEY}.Modal.Success`}
              values={{
                CustomerName: <b>{customerName}</b>,
              }}
            />
          }
          isOpen={isCreateCustomerSuccess}
          onApprove={() => {
            getNewCustomerInfo(createdCustomerData.customerNo);
            onModalClose(false);
            // BURAYI ÇÖZ this.setState(this.initialState);
          }}
          text={{ approve: intl.messages[`Modal.Success.Okay`] }}
        />
      );
    }
    return undefined;
  };

  const renderCreateCustomerErrorDialog = () => {
    if (error) {
      return (
        <Dialog
          type={DialogTypes.Danger}
          message={getErrorMessage('email')}
          isOpen={hasError('email') && isCreateCustomerSuccess == false}
          onApprove={() => {
            initError();
            closeModal();
          }}
          text={{ approve: intl.messages[`Modal.Success.Okay`] }}
        />
      );
    }
    return undefined;
  };

  const steps = [
    { title: `1. ${intl.messages[`${COMPONENT_INTL_KEY}.Modal.FirstStep`]}`, class: customerStepClass },
    { title: `2. ${intl.messages[`${COMPONENT_INTL_KEY}.Modal.SecondStep`]}`, class: addressStepClass },
  ];

  return (
    <Modal height="75%" isOpen onClose={() => onModalClose(true)} size="3xl">
      <ModalHeader>
        <ModalTitle>{intl.messages[`${COMPONENT_INTL_KEY}.Modal.Header`]}</ModalTitle>
      </ModalHeader>
      <ModalContent height="100%" display="flex" p="0">
        <Box flexShrink={0} pt="22" bg="#fafafa" width="244px">
          {steps.map((step, i) => {
            let colors = { bg: 'transparent', color: 'palette.steel_dark', icon: 'far fa-circle' };
            if (currentStep === i) colors = { bg: 'palette.blue', color: 'palette.white', icon: 'far fa-circle' };
            if (step.class === 'valid') {
              colors = { bg: 'palette.green', color: 'palette.white', icon: 'fas fa-check-circle' };
            }

            return (
              <Flex
                key={i.toString()}
                p="22"
                justifyContent="space-between"
                color={colors.color}
                bg={colors.bg}
                textAlign="left"
                fontFamily="heading"
                fontSize="16"
                fontWeight="bold"
                alignItems="center"
              >
                <Box display="inline-block">{step.title}</Box>
                <Icon fontSize="24px" name={colors.icon} />
              </Flex>
            );
          })}
        </Box>

        <Flex justifyContent="space-between" flexDirection="column" width="full" m="22px 30px">
          {currentStep === 0 && (
            <form onSubmit={(e: React.SyntheticEvent<HTMLFormElement>) => e.preventDefault()}>
              <Flex flexDirection="row" flexWrap="wrap">
                <FormControl
                  size="small"
                  isInvalid={!!validator.getErrorIntent('firstName')}
                  width={1 / 2}
                  mb="11"
                  pr="11"
                >
                  <FormLabel>{intl.messages[`${FORM_COMPONENT_INTL_KEY}.CustomerName`]}*</FormLabel>
                  <Input
                    placeholder={intl.messages[`${FORM_COMPONENT_INTL_KEY}.PlaceHolder.CustomerName`]}
                    onBlur={handleOnBlur('firstName')}
                    onChange={handleOnInputChange('firstName')}
                    value={fields.firstName}
                  />
                  <FormErrorMessage>{validator.getErrorIntent('firstName')?.text}</FormErrorMessage>
                </FormControl>
                <FormControl size="small" isInvalid={!!validator.getErrorIntent('lastName')} width={1 / 2} mb="11">
                  <FormLabel>{intl.messages[`${FORM_COMPONENT_INTL_KEY}.CustomerLastname`]}*</FormLabel>
                  <Input
                    placeholder={intl.messages[`${FORM_COMPONENT_INTL_KEY}.PlaceHolder.CustomerLastname`]}
                    onBlur={handleOnBlur('lastName')}
                    onChange={handleOnInputChange('lastName')}
                    value={fields.lastName}
                  />
                  <FormErrorMessage>{validator.getErrorIntent('lastName')?.text}</FormErrorMessage>
                </FormControl>
                <FormControl size="small" isInvalid={!!validator.getErrorIntent('email')} width={1} mb="11">
                  <FormLabel>{intl.messages[`${FORM_COMPONENT_INTL_KEY}.CustomerEmail`]}*</FormLabel>
                  <Input
                    placeholder={intl.messages[`${FORM_COMPONENT_INTL_KEY}.PlaceHolder.CustomerEmail`]}
                    onBlur={handleOnBlur('email')}
                    onChange={handleOnInputChange('email')}
                    value={fields.email}
                  />
                  <FormErrorMessage>{validator.getErrorIntent('email')?.text}</FormErrorMessage>
                </FormControl>
                <FormControl size="small" isInvalid={!!validator.getErrorIntent('phone')} width={1} mb="11">
                  <FormLabel>{intl.messages[`${FORM_COMPONENT_INTL_KEY}.CustomerPhoneNumber`]}*</FormLabel>
                  <Input
                    placeholder={intl.messages[`${FORM_COMPONENT_INTL_KEY}.PlaceHolder.CustomerPhoneNumber`]}
                    onChange={handleOnInputChange('phone')}
                    onBlur={handleOnBlur('phone')}
                    value={fields.phone}
                  />
                  <FormErrorMessage>{validator.getErrorIntent('phone')?.text}</FormErrorMessage>
                </FormControl>
              </Flex>
            </form>
          )}

          {currentStep === 1 && (
            <form onSubmit={(e: React.SyntheticEvent<HTMLFormElement>) => e.preventDefault()}>
              <Flex flexDirection="row" flexWrap="wrap">
                <FormControl
                  size="small"
                  pr="11"
                  mb="11"
                  width={1 / 2}
                  isInvalid={!!validator.getErrorIntent('addressFirstName')}
                >
                  <FormLabel>{intl.messages[`${FORM_COMPONENT_INTL_KEY}.ReceiverName`]}*</FormLabel>
                  <Input
                    placeholder={intl.messages[`${FORM_COMPONENT_INTL_KEY}.PlaceHolder.ReceiverName`]}
                    onChange={handleOnInputChange('addressFirstName')}
                    onBlur={handleOnBlur('addressFirstName')}
                    value={fields.addressFirstName}
                  />
                  <FormErrorMessage>{validator.getErrorIntent('addressFirstName')?.text}</FormErrorMessage>
                </FormControl>
                <FormControl
                  size="small"
                  isInvalid={!!validator.getErrorIntent('addressLastName')}
                  width={1 / 2}
                  mb="11"
                >
                  <FormLabel>{intl.messages[`${FORM_COMPONENT_INTL_KEY}.ReceiverLastname`]}*</FormLabel>
                  <Input
                    placeholder={intl.messages[`${FORM_COMPONENT_INTL_KEY}.PlaceHolder.ReceiverLastname`]}
                    onChange={handleOnInputChange('addressLastName')}
                    onBlur={handleOnBlur('addressLastName')}
                    value={fields.addressLastName}
                  />
                  <FormErrorMessage>{validator.getErrorIntent('addressLastName')?.text}</FormErrorMessage>
                </FormControl>
                <FormControl size="small" width={1} mb="11">
                  <FormLabel>{intl.messages[`${FORM_COMPONENT_INTL_KEY}.Company`]}</FormLabel>
                  <Input
                    placeholder={intl.messages[`${FORM_COMPONENT_INTL_KEY}.PlaceHolder.Company`]}
                    onChange={handleOnInputChange('company')}
                    value={fields.company}
                  />
                </FormControl>
                <FormControl size="small" isInvalid={!!validator.getErrorIntent('addressOne')} width={1} mb="11">
                  <FormLabel>{intl.messages[`${FORM_COMPONENT_INTL_KEY}.ReceiverAddressOne`]}*</FormLabel>
                  <Input
                    placeholder={intl.messages[`${FORM_COMPONENT_INTL_KEY}.PlaceHolder.ReceiverAddressOne`]}
                    onChange={handleOnInputChange('addressOne')}
                    onBlur={handleOnBlur('addressOne')}
                    value={fields.addressOne}
                  />
                  <FormErrorMessage>{validator.getErrorIntent('addressOne')?.text}</FormErrorMessage>
                </FormControl>
                <FormControl size="small" isInvalid={!!validator.getErrorIntent('addressTwo')} width={1} mb="11">
                  <FormLabel>{intl.messages[`${FORM_COMPONENT_INTL_KEY}.ReceiverAddressTwo`]}</FormLabel>
                  <Input
                    placeholder={intl.messages[`${FORM_COMPONENT_INTL_KEY}.PlaceHolder.ReceiverAddressTwo`]}
                    onChange={handleOnInputChange('addressTwo')}
                    value={fields.addressTwo}
                  />
                  <FormErrorMessage>{validator.getErrorIntent('addressTwo')?.text}</FormErrorMessage>
                </FormControl>
                <FormControl size="small" width={1 / 2} pr="11" mb="11">
                  <FormLabel>{intl.messages[`${FORM_COMPONENT_INTL_KEY}.Country`]}*</FormLabel> 
                  <SelectHTML
                    placeholder={intl.messages[`${FORM_COMPONENT_INTL_KEY}.PlaceHolder.Country`]}
                    onChange={handleOnInputChange('country')}
                    onBlur={handleOnBlur('country')}
                    value={fields.country}
                    options={DropdownOptionsFromResource(countryResource)}
                  />
                </FormControl>
                <FormControl size="small" width={1 / 2} mb="11"  >
                  <FormLabel>{intl.messages[`${FORM_COMPONENT_INTL_KEY}.City`]}*</FormLabel> 
                    {
                      fields.country == intl.messages[`${COMPONENT_INTL_KEY}.Turkey`] 
                      ? 
                      <SelectHTML
                      placeholder={intl.messages[`${FORM_COMPONENT_INTL_KEY}.PlaceHolder.City`]}
                      onChange={handleOnInputChange('city')}
                      onBlur={handleOnBlur('city')}
                      value={fields.city}
                      options={DropdownOptionsFromResource(provinceResource)}
                    />
                     :
                    <Input
                    placeholder={intl.messages[`${COMPONENT_INTL_KEY}.PlaceHolder.City`]} 
                    onChange={handleOnInputChange('city')}
                    value={fields.city} /> 
                    } 
                  {/* <FormErrorMessage>{validator.getError('city')?.message}</FormErrorMessage> */}
                </FormControl>
                <FormControl
                  size="small"
                  width={1 / 2}
                  pr="11"
                  mb="11"  
                >
                  <FormLabel>{intl.messages[`${FORM_COMPONENT_INTL_KEY}.District`]}*</FormLabel> 
                  {
                     fields.country == intl.messages[`${COMPONENT_INTL_KEY}.Turkey`] 
                     ?  
                    <SelectHTML
                      placeholder={intl.messages[`${FORM_COMPONENT_INTL_KEY}.PlaceHolder.District`]}
                      onChange={handleOnInputChange('district')}
                      onBlur={handleOnBlur('district')}
                      value={fields.district}
                      options={DropdownOptionsFromResource(districtResource)}
                      selection
                    /> 
                    :  
                    <Input
                    placeholder={intl.messages[`${COMPONENT_INTL_KEY}.PlaceHolder.District`]} 
                    onChange={handleOnInputChange('district')}
                    value={fields.district} />
                  }


                  {/* <FormErrorMessage>{validator.getError('district').message}</FormErrorMessage> */}
                </FormControl>
                <FormControl size="small" mb="11" width={1 / 2}>
                  <FormLabel>{intl.messages[`${FORM_COMPONENT_INTL_KEY}.PostalCode`]}</FormLabel>
                  <Input
                    placeholder={intl.messages[`${FORM_COMPONENT_INTL_KEY}.PlaceHolder.PostalCode`]}
                    onChange={handleOnInputChange('postalCode')}
                    onBlur={handleOnBlur('postalCode')}
                    value={fields.postalCode}
                  />
                  <FormErrorMessage>{validator.getError('postalCode').message}</FormErrorMessage>
                </FormControl>
                <FormControl size="small" width={1} mb="11" isInvalid={!!validator.getErrorIntent('addressPhone')}>
                  <FormLabel>{intl.messages[`${FORM_COMPONENT_INTL_KEY}.ReceiverPhoneNumber`]}*</FormLabel>
                  <Input
                    placeholder={intl.messages[`${FORM_COMPONENT_INTL_KEY}.PlaceHolder.ReceiverPhoneNumber`]}
                    onChange={handleOnInputChange('addressPhone')}
                    onBlur={handleOnBlur('addressPhone')}
                    value={fields.addressPhone}
                  />
                  <FormErrorMessage>{validator.getErrorIntent('addressPhone')?.text}</FormErrorMessage>
                </FormControl>
              </Flex>
            </form>
          )}

          <Flex justifyContent="center">
            <Button
              size="small"
              width="120px"
              variant="dark"
              disabled={currentStep === 0 || isBusy}
              mr="11"
              onClick={() => {
                setCurrentStep(0);
                setCustomerStepClass('progress');
                setAddressStepClass('disable');
              }}
            >
              {intl.messages[`${COMPONENT_INTL_KEY}.Modal.Prev`]}
            </Button>
            <Button
              size="small"
              mr="11"
              width="120px"
              kind="outline"
              variant="dark"
              disabled={isBusy}
              onClick={() => {
                setCurrentStep(0);
                setCustomerStepClass('progress');
                setAddressStepClass('disable');
                onModalClose(true);
              }}
            >
              {intl.messages[`${COMPONENT_INTL_KEY}.Modal.Cancel`]}
            </Button>
            <Button
              size="small"
              mr="11"
              width="190px"
              variant="success"
              disabled={currentStep === 0 ? isCustomerFormInvalid() : isAddressFormInvalid()}
              isLoading={isBusy}
              onClick={() => {
                if (currentStep === 0) {
                  setCurrentStep(1);
                  setCustomerStepClass('valid');
                  setAddressStepClass('progress');
                } else {
                  onSubmit(fields);
                  setCurrentStep(1);
                  setCustomerStepClass('valid');
                  setAddressStepClass('valid');
                }
              }}
            >
              {currentStep === 0
                ? intl.messages[`${COMPONENT_INTL_KEY}.Modal.Next`]
                : intl.messages[`${COMPONENT_INTL_KEY}.Modal.Save`]}
            </Button>
          </Flex>
        </Flex>
        {renderCreateCustomerSuccessModal()}
        {renderCreateCustomerErrorDialog()}
      </ModalContent>
    </Modal>
  );
};

import { GeneralErrorPanel, MainContent } from '@components';
import { ReturnLineItemsGrid } from '@components/organisms/ReturnLineItemsGrid/ReturnLineItemsGrid';
import { getIntegrationImageSrc } from '@containers/formatters';
import { MemberPageContainer } from '@containers/MemberPageContainer/MemberPageContainer';
import { PipelineContainer } from '@containers/PipelineContainer';
import { ResourceType } from '@models';
import {
  ActionBar,
  Alert,
  AlertDescription,
  AlertTitle,
  Box,
  Flex,
  formatUtcToLocal,
  Icon,
  Panel,
  Text,
  Widget,
} from '@oplog/express';
import { Resource } from '@oplog/resource-redux';
import { urls } from '@routes/urls';
import { ReturnDetailOutputDTO } from '@services';
import { checkShippingAddress } from '@utils';
import * as React from 'react';
import { useEffect } from 'react';
import { Props } from '../../atoms/Component/Component';

export interface ReturnDetailsProps extends Props {
  resources: Resource<ReturnDetailOutputDTO>;
}

export interface ReturnDetailsState { }

export const COMPONENT_INTL_KEY = 'ReturnDetails';

export const ReturnDetails: React.FC<ReturnDetailsProps> = ({ intl, onDidMount, resources, error }) => {
  useEffect(() => {
    onDidMount && onDidMount();
  }, []);

  const integrationName = resources && resources.data && resources.data.source;
  const referenceNumber = resources && resources.data && resources.data.referenceNumber;

  const breadcrumb = [
    {
      title: `${intl.messages[COMPONENT_INTL_KEY + '.Header.Title']}`,
      url: urls.returns,
    },
    {
      title: `#${referenceNumber}`,
    },
  ];

  return (
    <MemberPageContainer>
      <ActionBar
        top="66px"
        title={referenceNumber ? `#${referenceNumber}` : ''}
        subtitle={`${intl.messages[COMPONENT_INTL_KEY + '.Header.SubTitle']}`}
        isLoading={resources && resources.isBusy}
        integration={{
          icon: getIntegrationImageSrc(integrationName),
          name: integrationName || intl.messages['Integration.NoIntegration'],
        }}
        breadcrumb={breadcrumb}
      />

      <Box width={1}>
        <Alert variant="danger" isOpen={!!error} onDismiss={() => { }}>
          <AlertTitle>{intl.messages['PostErrorMessage.Title']}</AlertTitle>
          <AlertDescription>{intl.messages['PostErrorMessage.Description']}</AlertDescription>
        </Alert>
        <MainContent>
          <Panel title={intl.messages[`${COMPONENT_INTL_KEY}.Title.ReturnStatus`]} mb="22">
            {resources && resources.data && (
              <PipelineContainer
                pipelineKey={ResourceType.PipelineStateOfReturn}
                apiParam={{ returnNo: resources.data?.referenceNumber }}
                intl={intl}
                enumIntlKey="ReturnState"
                detailsIntlKey={`${COMPONENT_INTL_KEY}.Section.Status.SectionDetails`}
              />
            )}
          </Panel>

          <Panel title={intl.messages[`${COMPONENT_INTL_KEY}.Title.ReturnNotes`]}>
            {resources && resources.data?.notes ? (
              <Flex boxShadow="medium" backgroundColor="palette.white" py="11" px="22" alingItems="center" mb="22">
                <Text ml="11" fontSize="14" color="palette.grey">
                  {resources.data?.notes}
                </Text>
              </Flex>
            ) : (
              <Flex boxShadow="medium" backgroundColor="palette.white" py="11" px="22" alignItems="center" mb="22">
                <Icon color="palette.steel" name="fas fa-sticky-note" fontSize="44px" />
                <Text ml="11" color="palette.steel">
                  {intl.messages[`${COMPONENT_INTL_KEY}.EmptyReturnNotes`]}
                </Text>
              </Flex>
            )}
          </Panel>

          <Panel title={intl.messages[`${COMPONENT_INTL_KEY}.Title.SalesOrderDetails`]}>
            {!(resources && resources.data) || resources.error ? (
              <GeneralErrorPanel className="align-left" />
            ) : (
              <Flex flexDirection="row" flexWrap="wrap">
                {resources.data.returnSalesOrder ? (
                  <>
                    <Box width={[1, 1, 1 / 2, 1 / 5]} pr={['0', '0', '30']} mb={['22', '22', '22', '0']}>
                      <Widget.Two
                        title={resources.data?.returnSalesOrder?.referenceNumber}
                        subtitle={intl.messages[`${COMPONENT_INTL_KEY}.SalesOrderDetails.ReferenceNumber`]}
                        isLoading={resources.isBusy}
                        icon={<Icon color="white" name="fas fa-hashtag" fontSize="55px" />}
                        iconContainerProps={{ bg: 'palette.blue' }}
                      />
                    </Box>
                    <Box width={[1, 1, 1 / 2, 1 / 5]} pr={['0', '0', '0', '30']} mb={['22', '22', '22', '0']}>
                      <Widget.Two
                        title={resources.data?.returnSalesOrder?.customerFullName}
                        subtitle={intl.messages[`${COMPONENT_INTL_KEY}.SalesOrderDetails.CustomerFullName`]}
                        isLoading={resources.isBusy}
                        icon={<Icon color="white" name="fas fa-address-book" fontSize="56px" />}
                        iconContainerProps={{ bg: 'palette.orange' }}
                      />
                    </Box>
                    <Box width={[1, 1, 1 / 2, 2 / 5]} pr={['0', '0', '0', '30']} mb={['22', '22', '22', '0']}>
                      <Widget.Two
                        title={
                          <>
                            <Text fontFamily="heading" fontSize="13" fontWeight="600" color="palette.grey_darker">
                              {resources.data?.returnSalesOrder?.shippingAddressFullName}
                            </Text>{' '}
                            -{' '}
                            <Text fontFamily="heading" fontSize="13" fontWeight="300" color="palette.grey_darker">
                              {checkShippingAddress(resources.data?.returnSalesOrder?.fullAddress)}
                            </Text>
                          </>
                        }
                        subtitle={intl.messages[`${COMPONENT_INTL_KEY}.SalesOrderDetails.ShippingInfo`]}
                        isLoading={resources.isBusy}
                        icon={<Icon color="white" name="fas fa-map-marker-alt" fontSize="56px" />}
                        iconContainerProps={{ bg: 'palette.green' }}
                      />
                    </Box>
                    <Box width={[1, 1, 1 / 2, 1 / 5]} pr="0" mb="0">
                      <Widget.Two
                        title={formatUtcToLocal(
                          resources.data.returnSalesOrder ? resources.data.returnSalesOrder.createdAt : ('' as any)
                        )}
                        subtitle={intl.messages[`${COMPONENT_INTL_KEY}.SalesOrderDetails.CreatedAt`]}
                        isLoading={resources.isBusy}
                        icon={<Icon color="white" name="fas fa-calendar-alt" fontSize="56px" />}
                        iconContainerProps={{ bg: 'palette.red' }}
                      />
                    </Box>
                  </>
                ) : (
                  <Flex
                    boxShadow="medium"
                    backgroundColor="palette.white"
                    py="11"
                    px="22"
                    alignItems="center"
                    mb="22"
                    width="100%"
                  >
                    <Icon color="palette.steel_light" name="fas fa-exclamation-triangle" fontSize="44px" />
                    <Text ml="11" color="palette.steel">
                      {intl.messages[`${COMPONENT_INTL_KEY}.EmptySalesOrder`]}
                    </Text>
                  </Flex>
                )}
              </Flex>
            )}
          </Panel>

          <Box pt="22">
            <Panel title={intl.messages[`${COMPONENT_INTL_KEY}.Grid.Title`]}>
              {resources && resources.data && (
                <ReturnLineItemsGrid intl={intl} returnNo={resources.data.referenceNumber.toString()} />
              )}
            </Panel>
          </Box>
        </MainContent>
      </Box>
    </MemberPageContainer>
  );
};

import { GridProps } from '@models';
import {
    Box,
    Button,
    Dialog,
    DialogTypes,
    Flex,
    Modal,
    ModalContent,
    ModalFooter,
    Radio,
    RadioGroup,
    Text,
} from '@oplog/express';
import React, { useEffect, useState } from 'react';
import { BulkCancelLineItemsInput } from '@services';
import { StockQuantityDetailsExtendedDataGridRow } from '@utils';

interface CleanBlockedSalesOrdersApprovalModalProps extends GridProps {
    onModalClose: () => void;
    selectedReferenceNumbers: string[];
    onApproveCleanBlockedClick: ({ referenceNumbers }: { referenceNumbers: string[]; }) => void;
    cleanBlockedGridResource: any;
    bulkcreatePackageResource: any;
    bulkCancelLineItemsResource: any;
    bulkCancelLineItemsCommand: (cancelLineItems: BulkCancelLineItemsInput[]) => void;
    stockQuantityDetailsUsedInBlockedOrders: StockQuantityDetailsExtendedDataGridRow[];
}

enum BulkCreatePackageDialogType {
    NoDialog = 'NoDialog',
    Success = 'Success',
    Error = 'Error',
}

enum ApproveCleanBlockedOptions {
    CancelMissingProducts = 'CancelMissingProducts',
    WaitMissingProducts = 'WaitMissingProducts',
}

export const CleanBlockedSalesOrdersApprovalModal = ({
    intl,
    onModalClose,
    selectedReferenceNumbers,
    onApproveCleanBlockedClick,
    cleanBlockedGridResource,
    bulkcreatePackageResource,
    bulkCancelLineItemsResource,
    bulkCancelLineItemsCommand,
    stockQuantityDetailsUsedInBlockedOrders
}: CleanBlockedSalesOrdersApprovalModalProps) => {

    const [isWaitForMissingProducts, setIsWaitForMissingProducts] = useState<boolean>();
    const [showDialog, setShowDialog] = useState(BulkCreatePackageDialogType.NoDialog);
    const [isBulkCancelCompleted, setIsBulkCancelCompleted] = useState(false);

    const isCompleted = bulkcreatePackageResource?.isCompleted;
    const isError = bulkcreatePackageResource?.isError;
    const errorMessage = bulkcreatePackageResource?.errorMessage?.errors?.[0]?.message;
    const isOrderNotFound = errorMessage === 'SalesOrder: Any Order was not found.';
    const isBusy = bulkcreatePackageResource?.isBusy || bulkCancelLineItemsResource?.isBusy;

    const COMPONENT_INTL_KEY = 'CleanBlockedReviewGridModal';

    useEffect(() => {
        if (isCompleted) {
            setShowDialog(BulkCreatePackageDialogType.Success);
        }
    }, [isCompleted]);

    useEffect(() => {
        if (isError || bulkCancelLineItemsResource?.isError) {
            setShowDialog(BulkCreatePackageDialogType.Error);
        }
    }, [isError, bulkCancelLineItemsResource]);

    useEffect(() => {
        if (bulkCancelLineItemsResource?.isCompleted && !isBulkCancelCompleted) {
            setIsBulkCancelCompleted(true);
            onApproveCleanBlockedClick({ referenceNumbers: selectedReferenceNumbers });
        }
    }, [bulkCancelLineItemsResource, selectedReferenceNumbers, isBulkCancelCompleted]);

    const onApproveButtonClick = () => {
        if (isWaitForMissingProducts) {
            onApproveCleanBlockedClick({ referenceNumbers: selectedReferenceNumbers });
        } else {
            const bulkCancelLineItemsInput: BulkCancelLineItemsInput[] = Object.values(
                stockQuantityDetailsUsedInBlockedOrders
                    .filter(stockQuantityDetails => stockQuantityDetails.missingAmount > 0)
                    .reduce((acc, stockQuantityDetails) => {
                        const { referenceNumber, sku, missingAmount } = stockQuantityDetails;
                        if (!acc[referenceNumber]) {
                            acc[referenceNumber] = {
                                referenceNumber,
                                lineItems: []
                            };
                        }
                        acc[referenceNumber].lineItems.push({
                            sku,
                            amount: missingAmount
                        });
                        return acc;
                    }, {} as Record<string, { referenceNumber: string; lineItems: { sku: string; amount: number }[] }>)
            );

            bulkCancelLineItemsCommand(bulkCancelLineItemsInput);
            setIsBulkCancelCompleted(false);
        }
    };

    const onCancelButtonClick = () => {
        onModalClose();
    };

    const handleRadioChange = (e: React.SyntheticEvent<any>) => {
        setIsWaitForMissingProducts(e.currentTarget.value === ApproveCleanBlockedOptions.WaitMissingProducts);
    };

    const boxStyle = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
    };

    const descriptionBoxStyle = {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'row',
    };

    return (
        <Modal
            onClose={onModalClose}
            isOpen={true}
            size="4xl"
            disableOutsideMouseEvents={true}
            disableEscapeButtonClose={true}
            disablePortal
        >
            <ModalContent>
                <Box {...boxStyle}>
                    <Box {...descriptionBoxStyle}>
                        <Text fontSize="18" color="palette.red" fontWeight="bold">
                            {intl.messages[`${COMPONENT_INTL_KEY}.ApproveWarningModal.Description`]}
                        </Text>
                    </Box>
                    <Box flexDirection="column">
                        <RadioGroup
                            spacing="10px"
                            mt="20px"
                            defaultValue={1}
                            onChange={handleRadioChange}
                        >
                            <Radio
                                name="radioGroup"
                                value={ApproveCleanBlockedOptions.CancelMissingProducts}
                                fontSize="16"
                                fontWeight="bold"
                                isDisabled={cleanBlockedGridResource?.isBusyCleanBlockedBulkCreatePackage}
                            >
                                {intl.messages[`${COMPONENT_INTL_KEY}.ApproveWarningModal.CancelMissingProducts`]}
                            </Radio>
                            <Radio
                                name="radioGroup"
                                value={ApproveCleanBlockedOptions.WaitMissingProducts}
                                fontSize="16"
                                fontWeight="bold"
                                isDisabled={cleanBlockedGridResource?.isBusyCleanBlockedBulkCreatePackage}
                            >
                                {intl.messages[`${COMPONENT_INTL_KEY}.ApproveWarningModal.WaitMissingProducts`]}
                            </Radio>
                        </RadioGroup>
                    </Box>
                </Box>
            </ModalContent>
            <ModalFooter>
                <Flex justifyContent="space-between">
                    <Button
                        type="button"
                        onClick={onCancelButtonClick}
                        kind="solid"
                        variant="success"
                        width={1 / 3}
                        size="small"
                        disabled={isBusy}
                        isLoading={isBusy}
                    >
                        {intl.messages[`${COMPONENT_INTL_KEY}.ApproveWarningModal.Cancel`]}
                    </Button>
                    <Button
                        type="button"
                        onClick={onApproveButtonClick}
                        kind="solid"
                        variant="danger"
                        width={1 / 3}
                        size="small"
                        disabled={isWaitForMissingProducts === undefined || isBusy}
                        isLoading={isBusy}
                    >
                        {intl.messages[`${COMPONENT_INTL_KEY}.ApproveWarningModal.Approve`]}
                    </Button>
                </Flex>
            </ModalFooter>
            <Dialog
                type={
                    isCompleted || isOrderNotFound
                        ? DialogTypes.Success
                        : isError
                        ? DialogTypes.Danger
                        : DialogTypes.Warning
                }
                message={
                    isCompleted || isOrderNotFound
                        ? intl.messages[`${COMPONENT_INTL_KEY}.ApproveWarningModal.WarningDialog.Success`]
                        : isError
                        ? intl.messages[`${COMPONENT_INTL_KEY}.ApproveWarningModal.WarningDialog.Error`]
                        : ''
                }
                isOpen={showDialog !== BulkCreatePackageDialogType.NoDialog}
                isLoading={isBusy}
                text={{
                    approve: intl.messages[`Modal.Success.Okay`],
                }}
                onApprove={() => {
                    setShowDialog(BulkCreatePackageDialogType.NoDialog);
                    onModalClose();
                }}
                onCancel={() => setShowDialog(BulkCreatePackageDialogType.NoDialog)}
                showOverlay
            />
        </Modal>
    );
};

import { push, RouterAction } from 'connected-react-router';
import { Dispatch } from 'react';
import { RouteProps } from 'react-router';

export const IKAS_INFORMATION_URL = 'https://ikas.com/tr/kargo-ve-lojistik-entegrasyonlari';
export const PIXA_INFORMATION_URL = 'https://pixasoftware.com/';
export const SHOPIVERSE_INFORMATION_URL = 'https://shopiverse.com/partners/';
export const LOGO_INFORMATION_URL = 'https://www.logo.com.tr/';
export const MNM_INFORMATION_URL = 'https://www.mnm.com.tr/';
export const DOPIGO_INFORMATION_URL = 'https://www.dopigo.com/oplog-entegrasyonu/';

export function getRouteParams(props: any): any {
  const routeProps = props as any;
  if (routeProps && routeProps.match && routeProps.match.params) {
    return routeProps.match.params;
  }

  return undefined;
}

export function getRouteParam(props: any, key: string): any {
  const routeParams = getRouteParams(props);
  if (!routeParams) {
    return undefined;
  }

  if (routeParams[key] === undefined) {
    return undefined;
  }

  return decodeURIComponent(routeParams[key]);
}

export function getPathFromUrl(path: string): string {
  if (path) {
    const pieces = path.split('/');
    if (pieces.length > 0) {
      return pieces[pieces.length - 1];
    }
  }

  return '';
}

export function checkRoute(props: any, route: string) {
  return props && route && props.location && props.location.pathname === route;
}

export function clearDqbFromUrl(url: string): string {
  const urlPieces = url.split('?');
  if (urlPieces.length > 1) {
    return urlPieces[0];
  }
  return url;
}

export function onTabChange(activeTab: string, routeProps: any, dispatch: Dispatch<RouterAction>) {
  if (routeProps && routeProps.location) {
    const urlPieces = clearDqbFromUrl(routeProps.location.pathname).split('/');
    const tabRouteParam = getRouteParam(routeProps, 'tab');
    if (tabRouteParam) {
      const index = urlPieces.findIndex(x => x === tabRouteParam);
      urlPieces[index] = activeTab;
    } else {
      urlPieces.push(activeTab);
    }
    dispatch(push(`${urlPieces.join('/')}`));
  }
}

import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogTypes,
  DrawerTitle,
  Flex,
  Icon,
  Text,
  Widget,
  DialogProps,
} from '@oplog/express';
import { Resource } from '@oplog/resource-redux';
import {
  CreatedReturnOutputDTO,
  CreateOmnitroReturnCommand,
  SalesOrderQueryOutputDTO,
  CountOutputDTO,
} from '@services';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { checkShippingAddress, tracking } from '@utils';
import { Props } from '../../atoms/Component/Component';

export interface CreateReturnContextualPanelProps extends Props {
  createReturnResources: Resource<CreatedReturnOutputDTO>;
  returnCountResources: Resource<CountOutputDTO>;
  data: SalesOrderQueryOutputDTO;
  createReturn: (data: CreateOmnitroReturnCommand) => void;
  checkReturnCount: (salesOrderId?: string, startTime?: Date) => void;
  initalCreateReturn: (referenceNumber?: string) => void;
  resetResources: () => void;
}

const intlKey = 'CreateReturnContextualPanel';

export const CreateReturnContextualPanel: React.FC<CreateReturnContextualPanelProps> = ({
  intl,
  data,
  createReturn,
  returnCountResources,
  createReturnResources,
  checkReturnCount,
  initalCreateReturn,
  resetResources,
}) => {
  const [check, setCheck] = React.useState(false);
  const [dialog, setDialog] = React.useState<Partial<DialogProps>>({ isOpen: false });

  React.useEffect(() => () => resetResources(), []);

  React.useEffect(() => {
    if (createReturnResources) {
      if (createReturnResources.isBusy) setDialog({ ...dialog, isLoading: createReturnResources.isBusy });
      if (createReturnResources.isSuccess) {
        setDialog({
          ...dialog,
          type: DialogTypes.Success,
          message: intl.messages[`${intlKey}.Dialog.Successful`],
          text: { approve: intl.messages[`Modal.Success.Okay`] },
          isOpen: true,
          isLoading: createReturnResources.isBusy,
          onApprove: () => initalCreateReturn(createReturnResources.data && createReturnResources.data.referenceNumber),
          onCancel: () => initalCreateReturn(createReturnResources.data && createReturnResources.data.referenceNumber),
        });
        tracking.emitEvent('CreateReturn', 'CreateReturnSuccess');
      }
    }
  }, [createReturnResources]);

  React.useEffect(() => {
    const setApproveDialog = () => {
      setDialog({
        type: DialogTypes.Confirmation,
        isOpen: true,
        message: intl.messages[`${intlKey}.Dialog.Confirmation`],
        onApprove: () => createReturn({ salesOrderId: data.id, courierRequested: check }),
        onCancel: () => {
          setDialog({ ...dialog, isOpen: false });
          tracking.emitEvent('CreateReturn', 'CreateReturnCanceled');
        },
        text: { approve: intl.messages[`Modal.Confirmation.Okay`], cancel: intl.messages[`Modal.Confirmation.Cancel`] },
      });
    };

    if (returnCountResources && returnCountResources.isSuccess) {
      if (returnCountResources.data!.count > 0) {
        setDialog({
          type: DialogTypes.Danger,
          isOpen: true,
          message: (
            <FormattedMessage
              id={`${intlKey}.Dialog.ReturnExistError`}
              values={{
                referenceNumber: <strong>{data?.referenceNumber}</strong>,
                count: <strong>{returnCountResources.data!.count}</strong>,
              }}
            />
          ),
          onApprove: () => setApproveDialog(),
          onCancel: () => {
            setDialog({ ...dialog, isOpen: false });
            tracking.emitEvent('CreateReturn', 'CreateReturnCanceled');
          },
          text: {
            approve: intl.messages[`Modal.Confirmation.Okay`],
            cancel: intl.messages[`Modal.Confirmation.Cancel`],
          },
        });
      } else {
        setApproveDialog();
      }
    }
  }, [returnCountResources]);

  return (
    <>
      <DrawerTitle size="medium" boxShadow="small">
        {intl.messages[`${intlKey}.Header.ChooseSalesOrder`]}
      </DrawerTitle>
      {data && data.referenceNumber ? (
        <>
          <Box p="22">
            <Widget.Nine
              title={data.referenceNumber}
              text={data.customerFullName}
              subText={checkShippingAddress(data.shippingAddress)}
              icon={{ name: 'fal fa-truck-loading', fontSize: '44px' }}
            />
          </Box>
          {/* <DrawerTitle size="medium" boxShadow="small">
            {intl.messages[`${intlKey}.Header.ChooseCargo`]}
          </DrawerTitle>
          <Box px="22" pb="22">
            <Checkbox checked={check} onChange={e => setCheck(e.currentTarget.checked)}>
              {intl.messages[`${intlKey}.ContinueCargo`]}
            </Checkbox>
          </Box> */}
        </>
      ) : (
        <Flex p="22px" alignItems="center">
          <Icon name="fas fa-truck-loading" fontSize="44px" color="palette.steel" />
          <Text color="palette.snow" fontSize="14" pl="16">
            {intl.messages[`${intlKey}.NoRowsMessageWithoutSalesOrder`]}
          </Text>
        </Flex>
      )}
      <Flex pr="22" justifyContent="flex-end">
        <Button
          type="submit"
          disabled={!(data && data.referenceNumber)}
          addons={{ right: <Icon name="far fa-angle-right" /> }}
          isLoading={returnCountResources?.isBusy}
          onClick={() => checkReturnCount(data.id)}
        >
          {intl.messages[`${intlKey}.Buttons.Complete`]}
        </Button>
      </Flex>
      <Dialog {...(dialog as any)} />
    </>
  );
};

import { NumericStep } from '@oplog/express';
import useCreateSalesOrderState from '@store/createSalesOrder/CreateSalesOrderState';
import React from 'react';

export interface SalesOrderNumericStepProps {
  sku: string;
  onAmountChange: (amount: number, dependentValues: any, sku: string) => void;
  dependentValues: any;
}

export const SalesOrderNumericStep: React.FC<SalesOrderNumericStepProps> = ({
  sku,
  onAmountChange,
  dependentValues,
}) => {
  const [state, actions] = useCreateSalesOrderState();

  const findValue = (receivedSKU: string) => {
    let value;
    const itemExist = state.panelData.findIndex((data: any) => data.sku === receivedSKU);
    if (itemExist !== -1) {
      value = state.panelData[itemExist].amountInOrder;
    } else {
      value = 0;
    }
    return value;
  };

  const value = findValue(sku);
  return (
    <NumericStep
      value={value === 0 ? '0' : value}
      max={99999} // Değer product manager tarafından verilmiştir.
      onChange={(amount: any) => onAmountChange(amount, dependentValues, sku)}
    />
  );
};

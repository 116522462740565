import { ProductReceivingOrdersGrid, ProductSalesOrderCustomerGrid, ProductSalesOrderGrid } from '@components';
import { ProductExpirationDatesGrid } from '@components/organisms/ProductExpirationDatesGrid';
import { ProductSerialNumbersGrid } from '@components/organisms/ProductSerialNumbersGrid';
import { resolveActiveIndex } from '@containers/CustomerDetailsTabMenuContainer';
import { Tab, TabItem, TabProps } from '@oplog/express';
import { StoreState } from '@store';
import { getRouteParam, onTabChange } from '@utils';
import { RouterAction } from 'connected-react-router';
import * as React from 'react';
import { InjectedIntl } from 'react-intl';
import { connect, Omit } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { Dispatch } from 'redux';

enum Tabs {
  SalesOrders = 'sales-orders',
  Customers = 'customers',
  ReceivingOrders = 'receiving-order',
  ExpirationDates = 'expiration-dates',
  SerialNumbers = 'serial-numbers'
}

const tabOrders = [Tabs.SalesOrders, Tabs.Customers, Tabs.ReceivingOrders, Tabs.ExpirationDates, Tabs.SerialNumbers];

export interface ProductDetailsTabMenuContainerProps extends RouteComponentProps {
  intl: InjectedIntl;
}

interface TabMenuContainerProps extends TabProps {
  intl: InjectedIntl;
}

function mapStateToProps({}: StoreState, ownProps: ProductDetailsTabMenuContainerProps): Partial<TabProps> {
  const tabItems: Array<TabItem> = [];
  const sku = getRouteParam(ownProps, 'sku');
  const { intl } = ownProps;
  tabItems.push({
    id: Tabs.SalesOrders,
    title: intl.messages['ProductDetailsTabMenu.Panel.Orders.Title'],
    component: <ProductSalesOrderGrid sku={sku} intl={ownProps.intl} titleKey={sku} />,
  });

  tabItems.push({
    id: Tabs.Customers,
    title: intl.messages['ProductDetailsTabMenu.Panel.Customers.Title'],
    component: <ProductSalesOrderCustomerGrid sku={sku} intl={ownProps.intl} titleKey={sku} />,
  });

  tabItems.push({
    id: Tabs.ReceivingOrders,
    title: intl.messages['ProductDetailsTabMenu.Panel.ReceivingOrders'],
    component: <ProductReceivingOrdersGrid sku={sku} intl={ownProps.intl} titleKey={sku} />,
  });

  tabItems.push({
    id: Tabs.ExpirationDates,
    title: intl.messages['ProductDetailsTabMenu.Panel.ExpirationDates.Title'],
    component: <ProductExpirationDatesGrid sku={sku} intl={ownProps.intl} titleKey={sku} />,
  });

  tabItems.push({
    id: Tabs.ExpirationDates,
    title: intl.messages['ProductDetailsTabMenu.Panel.SerialNumbers.Title'],
    component: <ProductSerialNumbersGrid sku={sku} intl={ownProps.intl} titleKey={sku} />,
  });

  return {
    tabs: tabItems,
    activeIndex: resolveActiveIndex(tabItems, Tabs, ownProps),
  };
}

function mapDispatchToProps(
  dispatch: Dispatch<RouterAction>,
  routeProps: ProductDetailsTabMenuContainerProps
): Partial<TabProps> {
  return {
    onTabChange: (activeIndex: number) => {
      const activeTab = tabOrders[activeIndex];
      onTabChange(activeTab, routeProps, dispatch);
    },
  };
}

export const ProductDetailsTabMenuContainer = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Tab)
) as React.ComponentType<Omit<TabMenuContainerProps, 'activeIndex' | 'onTabChange' | 'tabs'>>;

import { CorrectInvalidSalesOrdersLineItemNotFoundModal, CorrectInvalidSalesOrdersLineItemNotFoundModalProps } from '@components/molecules/CorrectInvalidSalesOrdersLineItemNotFoundModal';
import { GridType, ResourceType } from '@models';
import { gridActions } from '@oplog/data-grid';
import { GridFetchRequested } from '@oplog/data-grid/dist/store/actions';
import { resourceActions, resourceSelectors } from '@oplog/resource-redux';
import { RouterAction } from 'connected-react-router';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { StoreState } from '../../store/state';

export interface CorrectInvalidSalesOrdersLineItemNotFoundModalContainerProps {
    onClose: () => void;
}

function mapStateToProps(
    { resources }: StoreState,
    ownProps: CorrectInvalidSalesOrdersLineItemNotFoundModalContainerProps
): Partial<CorrectInvalidSalesOrdersLineItemNotFoundModalProps> {
    return {
        responseData: resourceSelectors.getData(resources, ResourceType.CorrectInvalidSalesOrdersLineItemNotFound),
        isBusy:
            resourceSelectors.isBusy(resources, ResourceType.CorrectInvalidSalesOrdersLineItemNotFound),
        hasError: resourceSelectors.hasError(resources, ResourceType.CorrectInvalidSalesOrdersLineItemNotFound),
        COMPONENT_INTL_KEY: 'CorrectInvalidSalesOrdersLineItemNotFound.Modal',
    };
}

function mapDispatchToProps(
    dispatch: Dispatch<resourceActions.ResourceAction | RouterAction | GridFetchRequested>
): Partial<CorrectInvalidSalesOrdersLineItemNotFoundModalProps> {
    return {
        addQueueMessage: () => {
            dispatch(resourceActions.resourceRequested(ResourceType.CorrectInvalidSalesOrdersLineItemNotFound));
        },
        onWillUnmount: () => {
            dispatch(resourceActions.resourceInit(ResourceType.SyncFromIntegrationCancelled));
            dispatch(gridActions.gridFetchRequested(GridType.SupportSalesOrders));
        },
    };
}

export const CorrectInvalidSalesOrdersLineItemNotFoundModalContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(CorrectInvalidSalesOrdersLineItemNotFoundModal));  
 
import { Box, Button, DrawerTitle, Flex, Select, Text, Widget } from '@oplog/express';
import { Resource } from '@oplog/resource-redux';
import { AddressOfCustomerOutputDTO, CustomerInfoOutputDTO } from '@services';
import { checkShippingAddress } from '@utils';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Skeleton from 'react-loading-skeleton';
import theme from '../../../theme';
import { Props } from '../../atoms/Component/Component';

export interface CustomerShippingInfoSearchProps extends Props {
  intlKey: string;
  customerInfo?: CustomerInfoOutputDTO;
  shippingAddresses: Resource<AddressOfCustomerOutputDTO[]>;
  receivedSelectShippingAddress?: any;
  showTitle: boolean;
  disabled?: boolean;
  onShippingAddressChange: (e: any) => void;
  onCustomerCloseClick?: () => void;
  onEditAddressClick?: () => void;
  onAddAddressClick?: () => void;
  onEditCustomerClick?: (customerNo?: number) => void;
}

export const CustomerShippingInfoSearch: React.FC<CustomerShippingInfoSearchProps> = ({
  receivedSelectShippingAddress,
  shippingAddresses,
  onShippingAddressChange,
  customerInfo,
  onEditCustomerClick,
  onEditAddressClick,
  onCustomerCloseClick,
  isBusy,
  showTitle,
  intl,
  intlKey,
  onAddAddressClick,
  disabled,
}) => {
  const [selectShippingAddress, setSelectShippingAddress] = useState({
    label: '',
    value: '',
  });

  const [shippingAddressesOptions, setShippingAddressesOptions] = useState({});
  const [isAddressSelectOpen, setIsAddressSelectOpen] = useState(false);

  useEffect(() => {
    if (receivedSelectShippingAddress) {
      setSelectShippingAddress({
        label: receivedSelectShippingAddress.details,
        value: receivedSelectShippingAddress.id,
      });
    }
  }, [receivedSelectShippingAddress]);

  useEffect(() => {
    if (shippingAddresses) {
      setOptions(shippingAddresses);
    }
  }, [shippingAddresses]);

  const setOptions = (addresses: any) => {
    let shippingAddressesOptionsConst =
      addresses.data &&
      addresses.data.map((data: AddressOfCustomerOutputDTO) => {
        return {
          value: data.id || '',
          label: data.details || '',
        };
      });

    if (!selectShippingAddress && shippingAddressesOptionsConst) {
      setSelectShippingAddress(shippingAddressesOptionsConst[0]);
      onShippingAddressChange(shippingAddressesOptionsConst[0]);
    }

    setShippingAddressesOptions(shippingAddressesOptionsConst);
  };

  const onEditInfo = () => {
    if (customerInfo && onEditCustomerClick) {
      onEditCustomerClick(customerInfo.customerNo);
    }
  };

  return isBusy ? (
    <Skeleton />
  ) : (
    <>
      {showTitle && (
        <DrawerTitle size="medium" boxShadow="small">
          {intl.messages[`${intlKey}.Header.ChooseCustomer`]}
        </DrawerTitle>
      )}

      <Box mt="13px" px="22">
        {showTitle && (
          <Flex alignItems="center" mt="22" mb="11" justifyContent="space-between">
            <DrawerTitle size="small" px="0" py="0">
              {intl.messages['ShippingInfoSearch.LeftTitle']}
            </DrawerTitle>
            {onEditCustomerClick && (
              <Button kind="link" onClick={onEditInfo}>
                <Text fontSize="13" color="text.link" fontWeight={700}>
                  {intl.messages['ShippingInfoSearch.Edit']}
                </Text>
              </Button>
            )}
          </Flex>
        )}

        <Widget.Nine
          title={`${customerInfo?.firstName} ${customerInfo?.lastName}`}
          text={customerInfo?.email}
          subText={customerInfo?.phone}
          isLoading={!customerInfo}
          icon={{
            name: 'fal fa-user-circle',
            fontSize: '54px',
            color: 'palette.grey',
          }}
          onCloseClick={onCustomerCloseClick}
        />

        {showTitle && (
          <Flex alignItems="center" mt="22" mb="11" justifyContent="space-between">
            <DrawerTitle size="small" px="0" py="0">
              {intl.messages['ShippingInfoSearch.ShippingInfo']}
            </DrawerTitle>
            <Flex>
              {onAddAddressClick && (
                <Button
                  pr={selectShippingAddress ? '8px' : 0}
                  borderRight={selectShippingAddress && 'xs'}
                  borderColor="palette.grey"
                  kind="link"
                  onClick={onAddAddressClick}
                >
                  <Text fontSize="13" color="text.link" fontWeight={700}>
                    {intl.messages['ShippingInfoSearch.AddAddress']}
                  </Text>
                </Button>
              )}
              {onEditAddressClick && selectShippingAddress && (
                <Button pl="8px" kind="link" onClick={onEditAddressClick}>
                  <Text fontSize="13" color="text.link" fontWeight={700}>
                    {intl.messages['ShippingInfoSearch.Edit']}
                  </Text>
                </Button>
              )}
            </Flex>
          </Flex>
        )}

        <Box>
          {shippingAddresses.isBusy ? (
            <Skeleton height={76} />
          ) : (
            <Box position="relative">
              {/* basically, we're creating hidden select input like we're doing file inputs. */}
              {selectShippingAddress ? (
                <>
                  <Widget.Nine
                    title={selectShippingAddress && checkShippingAddress(selectShippingAddress.label)}
                    isLoading={!customerInfo}
                    icon={{
                      name: 'fal fa-map-marker-alt',
                      fontSize: '54px',
                      color: 'palette.grey',
                    }}
                    rightIcon={{ name: 'fas fa-sort' }}
                    onClick={() => {
                      setIsAddressSelectOpen(!isAddressSelectOpen);
                    }}
                    borderBottomLeftRadius={isAddressSelectOpen ? '0' : '6px'}
                    borderBottomRightRadius={isAddressSelectOpen ? '0' : '6px'}
                    textProps={{ whiteSpace: 'break-spaces', fontWeight: 600 }}
                    cursor="pointer"
                  />
                  {isAddressSelectOpen && (
                    <Select
                      disabled={disabled}
                      onChange={(e: any) => {
                        onShippingAddressChange(e);
                        setSelectShippingAddress(e.value);
                        setIsAddressSelectOpen(false);
                      }}
                      options={shippingAddressesOptions}
                      value={selectShippingAddress.value}
                      isSearchable={false}
                      styles={{
                        control: () => ({ visibility: 'hidden', position: 'absolute', bottom: 0, width: '100%' }),
                        option: (base: any, state: any) => ({
                          ...base,
                          paddingLeft: '76px',
                          paddingRight: '22px',
                          paddingTop: '11px',
                          paddingBottom: '11px',
                          cursor: 'pointer',
                          backgroundColor:
                            theme.colors.palette[state.isFocused || state.isSelected ? 'steel_lighter' : 'white'],
                          color:
                            state.isFocused || state.isSelected
                              ? theme.colors.palette['black']
                              : theme.colors.text.input,
                          fontWeight: state.isSelected ? 800 : 500,
                          ':hover': {
                            backgroundColor: theme.colors.palette['steel_lighter'],
                          },
                          ':active': {
                            backgroundColor: theme.colors.palette['steel_light'],
                          },
                        }),
                      }}
                      menuIsOpen
                    />
                  )}
                  {isAddressSelectOpen && (
                    <Box
                      position="fixed"
                      top="0"
                      left="0"
                      bottom="0"
                      right="0"
                      onClick={() => {
                        setIsAddressSelectOpen(!isAddressSelectOpen);
                      }}
                    />
                  )}
                </>
              ) : (
                <FormattedMessage
                  id="ShippingInfoSearch.AddressNotFound"
                  values={{
                    NewAddress: (
                      <Button kind="link" color="palette.blue" onClick={onAddAddressClick}>
                        {intl.messages['ShippingInfoSearch.NewAddress']}
                      </Button>
                    ),
                  }}
                />
              )}
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};

import { FormatterProps } from '@oplog/data-grid';
import * as React from 'react';

export const booleanFormatter = (props: FormatterProps) => {
  if (props) {
    const {
      dependentValues: { intl, negativeIntlKey, positiveIntlKey },
    } = props;
    return <>{intl.messages[props.value ? positiveIntlKey : negativeIntlKey]}</>;
  }
  return <></>;
};

/* eslint-disable @typescript-eslint/interface-name-prefix */

import React from 'react';
import globalHook, { Store } from 'use-global-hook';

export enum CreateSalesOrderStateKeys {
  gridData = 'gridData',
  panelData = 'panelData',
}

export interface CreateSalesOrderCardInfo {
  numOfTypes: number;
  numOfProducts: number;
}

interface GridArray {
  amount: number;
  row: any;
  sku: string;
}

/* Interfaces */
export interface SalesOrderState {
  gridData: GridArray[];
  panelData: any[];
}

export interface CreateSalesOrderActions {
  clearState: (data: Partial<SalesOrderState>) => void;
  setStateData: (key: CreateSalesOrderStateKeys, data: any) => void;
  clearData: () => void;
}

/* InitalStates */
export const initalState: SalesOrderState = {
  gridData: [],
  panelData: [],
};

export const doesContainItemWithAmountMoreThanStock = (panelData: any[]): boolean => {
  let value = false;
  if (panelData.length > 0) {
    panelData.forEach((item: any) => {
      if (typeof item.availableAmount !== 'number' || item.availableAmount < item.amountInOrder) {
        value = true;
      }
    });
  }
  return value;
};

export const getCreateSalesOrderCartGridInfo = (panelData: any[]): CreateSalesOrderCardInfo => {
  if (panelData.length > 0) {
    const numOfTypes: number = panelData.length;
    let numOfProducts = 0;
    panelData.forEach((order: any) => {
      numOfProducts += order.amountInOrder;
    });

    return { numOfTypes, numOfProducts } as CreateSalesOrderCardInfo;
  }

  return { numOfTypes: 0, numOfProducts: 0 } as CreateSalesOrderCardInfo;
};

export const getCreateSalesOrderPanelData = (store: Store<SalesOrderState, CreateSalesOrderActions>) => {
  return store.state.panelData;
};

/* Actions */
export const initalActions: object = {
  clearState: (store: Store<SalesOrderState, CreateSalesOrderActions>, data: Partial<SalesOrderState>) => {
    store.setState({ ...store.state, ...data });
  },

  setStateData: (store: Store<SalesOrderState, CreateSalesOrderActions>, key: CreateSalesOrderStateKeys, data: any) => {
    store.setState({ ...store.state, [key]: data });
  },

  clearData: (store: Store<SalesOrderState, CreateSalesOrderActions>) => {
    store.setState({
      ...store.state,
      gridData: [...initalState.gridData],
      panelData: [...initalState.panelData],
    });
  },
};

/* Export */
const useCreateSalesOrderState = globalHook<SalesOrderState, CreateSalesOrderActions>(
  React,
  initalState,
  initalActions
);

export default useCreateSalesOrderState;

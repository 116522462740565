import { IntegrationStates } from '@components/pages/Integrations';
import {
  Alert,
  AlertDescription,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  FormValidator,
  Input,
  Popover,
  Toggle,
  Image
} from '@oplog/express';
import { Resource } from '@oplog/resource-redux';
import {
  CreateIntegrationCommand,
  IntegrationOutputDTO,
  IntegrationState,
  IntegrationType,
  OpenApiIdentifier,
  UpdateIntegrationCommand,
} from '@services';
import { getIntegrationErrorMessage } from '@utils';
import * as React from 'react';
import { useEffect, useState } from 'react';
import useForceUpdate from 'use-force-update';
import * as Yup from 'yup';
import { Props } from '../../atoms/Component/Component';

const COMPONENT_INTL_KEY = 'Integrations.IntegrationInfo';

export interface TicimaxIntegrationProps extends Props {
  integration?: IntegrationOutputDTO;
  type?: IntegrationType;
  validator: FormValidator;
  deleteIntegrationResource?: Resource<Response>;
  onSubmit: (
    integration: UpdateIntegrationCommand | CreateIntegrationCommand,
    initialIntegrationState: boolean | undefined
  ) => void;
  onClose: () => void;
  onDelete?: () => void;
  isEdit: boolean;
  isNew: boolean;
  isReferenceNumberRequired?: boolean;
}

export const TicimaxIntegrationForm: React.FC<TicimaxIntegrationProps> = ({
  integration,
  validator,
  deleteIntegrationResource,
  onSubmit,
  onClose,
  onDelete,
  isEdit,
  isNew,
  isReferenceNumberRequired,
  isBusy,
  intl,
  onWillMount,
  onWillUnmount,
  error,
}) => {
  const forceUpdate = useForceUpdate();

  useEffect(() => {
    validator.registerSchema(ticimaxIntegrationFormSchemaFields());

    onWillMount && onWillMount();

    return () => {
      onWillUnmount && onWillUnmount();
      validator.clearErrors();
    };
  }, []);

  const [initialIntegrationState, setInitialIntegrationState] = useState(
    integration ? integration.state === IntegrationState.Active : true
  );

  const isEmpty = () => {
    return (
      integrationState.integrationName === '' ||
      integrationState.password === '' ||
      integrationState.shopLink === '' ||
      (isReferenceNumberRequired === true && integrationState.referenceNumberPrefix === '') ||
      (integrationState.isCoDActive && (integrationState.cashKey === '' || integrationState.creditCardKey === ''))
    );
  };

  const [integrationState, setIntegrationState] = useState<IntegrationStates>({
    id: integration ? integration.id : '',
    isActive: integration ? integration.state === IntegrationState.Active : true,
    integrationName: integration ? integration.name : '',
    password: integration ? integration.password : '',
    shopLink: integration ? integration.shopLink : '',
    referenceNumberPrefix: integration ? integration.referenceNumberPrefix : undefined,
    shouldSyncMissingData: false,
    shouldSyncMissingOrders: false,
    shouldSyncMissingProducts: false,
    type: IntegrationType.Ticimax,
    isCoDActive: integration ? integration.isCoDActive : false,
    cashKey: integration ? integration.cashKey : '',
    creditCardKey: integration ? integration.creditCardKey : '',
    openApiIdentifier: integration ? integration.openApiIdentifier : OpenApiIdentifier.None,
    isSameAsBarcode : integration ? integration.isSameAsBarcode : false,
    skuWithBarcode : integration ? integration.skuWithBarcode : false,
    isActiveOrderNumber : integration ? integration.isActiveOrderNumber : false
  });

  const handleSubmit = (e: React.SyntheticEvent<HTMLFormElement>) => {
    const integration = { ...integrationState };

    e && e.preventDefault();
    if ((deleteIntegrationResource && deleteIntegrationResource.isBusy) || isBusy) {
      return;
    }

    if (isNew) {
      const excludeNew = ['id', 'isActive', 'shouldSyncMissingData'];
      excludeNew.forEach(key => delete integration[key]);
    }

    for (const key in integration) {
      if (integration.hasOwnProperty(key)) {
        const element = integration[key];
        validator.validate(key, element);
      }
    }

    if (validator.hasErrors()) {
      forceUpdate();
      return;
    }

    if (!integration.referenceNumberPrefix) {
      delete integration.referenceNumberPrefix;
    }

    onSubmit(integration, initialIntegrationState);
  };

  const handleClose = () => {
    if (deleteIntegrationResource && (isBusy || deleteIntegrationResource.isBusy)) {
      return;
    }

    onClose();
  };

  const handleDelete = () => {
    if (onDelete) {
      if (deleteIntegrationResource && (isBusy || deleteIntegrationResource.isBusy)) {
        return;
      }

      onDelete();
    }
  };

  const handleBlur = (key: string) => () => {
    validator.validate(key, integrationState[key]);
    forceUpdate();
  };

  const handleCheckboxChange = (key: 'isCoDActive' | 'isActive' | 'isSameAsBarcode' | 'skuWithBarcode' | 'isActiveOrderNumber') => (e: React.SyntheticEvent<HTMLInputElement>) => {
    setIntegrationState({
      ...integrationState,
      [key]: e.currentTarget.checked || false,
    });
  };

  const handleIsSameAsBarcodeCheckboxChange = (key: 'isSameAsBarcode') => (e: React.SyntheticEvent<HTMLInputElement>) => {
    setIntegrationState({
      ...integrationState,
      [key]: e.currentTarget.checked || false,
      ['skuWithBarcode']: false
    });
  };

  const handleSkuWithBarcodeCheckboxChange = (key: 'skuWithBarcode') => (e: React.SyntheticEvent<HTMLInputElement>) => {
    setIntegrationState({
      ...integrationState,
      [key]: e.currentTarget.checked || false,
      ['isSameAsBarcode']: false
    });
  };

  const handleInputChange = (key: string) => (e: React.SyntheticEvent<HTMLInputElement>) => {
    setIntegrationState({
      ...integrationState,
      [key]: e.currentTarget.value,
    });
  };

  const ticimaxIntegrationFormSchemaFields = () => {
    const referenceNumberPrefix = isReferenceNumberRequired
      ? Yup.string()
        .required(`${intl.messages[`${COMPONENT_INTL_KEY}.Form.ReferenceNumberPrefixInfo`]}`)
        .max(3, `${intl.messages[`${COMPONENT_INTL_KEY}.Error.ReferenceNumberPrefixMaxChar`]}`)
      : Yup.string().max(3, `${intl.messages[`${COMPONENT_INTL_KEY}.Error.ReferenceNumberPrefixMaxChar`]}`);

    return {
      id: Yup.string(),
      isActive: Yup.boolean(),
      isCoDActive: Yup.boolean(),
      integrationName: Yup.string().required(`${intl.messages[`${COMPONENT_INTL_KEY}.Error.IntegrationName`]}`),
      password: Yup.string().required(`${intl.messages[`${COMPONENT_INTL_KEY}.Error.ApiPassword`]}`),
      shopLink: Yup.string()
        .url(`${intl.messages[`${COMPONENT_INTL_KEY}.Error.Url`]}`)
        .required(`${intl.messages[`${COMPONENT_INTL_KEY}.Error.ShopLink`]}`),
      referenceNumberPrefix,
      type: Yup.string(),
      shouldSyncMissingData: Yup.boolean(),
    };
  };

  return (
    <form onSubmit={handleSubmit}>
      {error?.errors && (
        <Alert variant="danger" isOpen mb="11" flexDirection="column" alignItems="flex-start">
          {error?.errors.map(error => (
            <AlertDescription>
              {' '}
              {getIntegrationErrorMessage(error.errorCode, error.property, COMPONENT_INTL_KEY, intl)}
            </AlertDescription>
          ))}
        </Alert>
      )}
      {isEdit && integration && (
        <FormControl
          size="small"
          isInline
          mb="22"
          isDisabled={isBusy || (deleteIntegrationResource && deleteIntegrationResource.isBusy)}
        >
          <FormLabel>{intl.messages[`${COMPONENT_INTL_KEY}.Form.Fields.IsActive`]}</FormLabel>
          <Toggle isChecked={isEdit && integrationState.isActive} onChange={handleCheckboxChange('isActive')} />
        </FormControl>
      )}
      <FormControl
        isInvalid={!!validator.getErrorIntent('integrationName')}
        isInline
        isDisabled={isBusy || (deleteIntegrationResource && deleteIntegrationResource.isBusy)}
        mb="11"
      >
        <FormLabel>{intl.messages[`${COMPONENT_INTL_KEY}.Form.Fields.IntegrationName`]}*</FormLabel>
        <Input
          placeholder={intl.messages[`${COMPONENT_INTL_KEY}.Form.Placeholders.IntegrationName`]}
          value={integrationState.integrationName}
          onChange={handleInputChange('integrationName')}
          onBlur={handleBlur('integrationName')}
        />
        <FormErrorMessage>{validator.getErrorIntent('integrationName')?.text}</FormErrorMessage>
      </FormControl>
      <FormControl
        isDisabled={isBusy || (deleteIntegrationResource && deleteIntegrationResource.isBusy)}
        isInvalid={!!validator.getErrorIntent('password')}
        isInline
        mb="11"
      >
        <FormLabel>{intl.messages[`${COMPONENT_INTL_KEY}.Form.Fields.Password`]}*</FormLabel>
        <Input
          placeholder={intl.messages[`${COMPONENT_INTL_KEY}.Form.Placeholders.Password`]}
          value={integrationState.password}
          onChange={handleInputChange('password')}
          onBlur={handleBlur('password')}
          type="password"
        />
        <FormErrorMessage>{validator.getErrorIntent('password')?.text}</FormErrorMessage>
      </FormControl>
      <FormControl
        isInvalid={!!validator.getErrorIntent('shopLink')}
        isInline
        isDisabled={isBusy || (deleteIntegrationResource && deleteIntegrationResource.isBusy)}
        mb="11"
      >
        <FormLabel>{intl.messages[`${COMPONENT_INTL_KEY}.Form.Fields.ShopLink`]}*</FormLabel>
        <Input
          placeholder={intl.messages[`${COMPONENT_INTL_KEY}.Form.Placeholders.ShopLink`]}
          value={integrationState.shopLink}
          onChange={handleInputChange('shopLink')}
          onBlur={handleBlur('shopLink')}
        />
        <FormErrorMessage>{validator.getErrorIntent('shopLink')?.text}</FormErrorMessage>
      </FormControl>
      <FormControl
        size="small"
        isInvalid={!!validator.getErrorIntent('referenceNumberPrefix')}
        isInline
        isDisabled={isEdit || isBusy || (deleteIntegrationResource && deleteIntegrationResource.isBusy)}
        mb="11"
      >
        <FormLabel>{intl.messages[`${COMPONENT_INTL_KEY}.Form.Fields.ReferenceNumberPrefix`]}</FormLabel>
        <Input
          placeholder={intl.messages[`${COMPONENT_INTL_KEY}.Form.Placeholders.ReferenceNumberPrefix`]}
          value={integrationState.referenceNumberPrefix}
          onChange={handleInputChange('referenceNumberPrefix')}
          onBlur={handleBlur('referenceNumberPrefix')}
        />
        <FormErrorMessage>{validator.getErrorIntent('referenceNumberPrefix')?.text}</FormErrorMessage>
      </FormControl>
      <FormControl
        my="22"
        isInline
        isDisabled={isBusy || (deleteIntegrationResource && deleteIntegrationResource.isBusy)}
      >
        <FormLabel>{intl.messages[`${COMPONENT_INTL_KEY}.Form.Fields.CashOnDelivery`]}</FormLabel>
        <Toggle isChecked={integrationState.isCoDActive} onChange={handleCheckboxChange('isCoDActive')} />
      </FormControl>
      {integrationState.isCoDActive && (
        <>
          <FormControl
            size="small"
            isDisabled={isBusy || (deleteIntegrationResource && deleteIntegrationResource.isBusy)}
            isInline
            isInvalid={!!validator.getErrorIntent('cashKey')}
            mb="11"
          >
            <FormLabel>{intl.messages[`${COMPONENT_INTL_KEY}.Form.Fields.CashKey`]}*</FormLabel>
            <Input
              placeholder={intl.messages[`${COMPONENT_INTL_KEY}.Form.Placeholders.CashKey`]}
              value={integrationState.cashKey}
              onChange={handleInputChange('cashKey')}
              onBlur={handleBlur('cashKey')}
            />
            <FormErrorMessage>{validator.getErrorIntent('cashKey')?.text}</FormErrorMessage>
          </FormControl>
          <FormControl
            size="small"
            isInline
            isInvalid={!!validator.getErrorIntent('creditCardKey')}
            isDisabled={isBusy || (deleteIntegrationResource && deleteIntegrationResource.isBusy)}
            mb="11"
          >
            <FormLabel>{intl.messages[`${COMPONENT_INTL_KEY}.Form.Fields.CreditCardKey`]}*</FormLabel>
            <Input
              placeholder={intl.messages[`${COMPONENT_INTL_KEY}.Form.Placeholders.CreditCardKey`]}
              value={integrationState.creditCardKey}
              onChange={handleInputChange('creditCardKey')}
              onBlur={handleBlur('creditCardKey')}
            />
            <FormErrorMessage>{validator.getErrorIntent('creditCardKey')?.text}</FormErrorMessage>
          </FormControl>
          <FormErrorMessage independent mb="11">
            {intl.messages[`${COMPONENT_INTL_KEY}.Form.TicimaxCODWarning`]}
          </FormErrorMessage>
        </>
      )}
      <FormControl
        my="22"
        isInline
        isDisabled={isBusy || (deleteIntegrationResource && deleteIntegrationResource.isBusy)}
      >
        <Popover
          isDark
          content={intl.messages[`${COMPONENT_INTL_KEY}.Form.Fields.SameAsBarcodeInformation`]}
          placement="bottom"
          action={['hover']}
        >
          <Image src="/images/questionmark_icon.png" width="20px" marginRight="5px" marginTop="-5px" />
        </Popover>
        <FormLabel>{intl.messages[`${COMPONENT_INTL_KEY}.Form.Fields.SameAsBarcode`]}</FormLabel>
        <Toggle isChecked={integrationState.isSameAsBarcode} onChange={handleIsSameAsBarcodeCheckboxChange('isSameAsBarcode')} />
      </FormControl>
      <FormControl
        my="22"
        isInline
        isDisabled={isBusy || (deleteIntegrationResource && deleteIntegrationResource.isBusy)}
      >
        <Popover
          isDark
          content={intl.messages[`${COMPONENT_INTL_KEY}.Form.Fields.SkuWithBarcodeInformation`]}
          placement="bottom"
          action={['hover']}
        >
          <Image src="/images/questionmark_icon.png" width="20px" marginRight="5px" marginTop="-5px" />
        </Popover>
        <FormLabel>{intl.messages[`${COMPONENT_INTL_KEY}.Form.Fields.SkuWithBarcode`]}</FormLabel>
        <Toggle isChecked={integrationState.skuWithBarcode} onChange={handleSkuWithBarcodeCheckboxChange('skuWithBarcode')} />
      </FormControl>

      <FormControl
        my="22"
        isInline
        isDisabled={isBusy || (deleteIntegrationResource && deleteIntegrationResource.isBusy)}
      >
        <Popover
          isDark
          content={intl.messages[`${COMPONENT_INTL_KEY}.Form.Fields.IsActiveOrderNumberInformation`]}
          placement="bottom"
          action={['hover']}
        >
          <Image src="/images/questionmark_icon.png" width="20px" marginRight="5px" marginTop="-5px" />
        </Popover>
        <FormLabel>{intl.messages[`${COMPONENT_INTL_KEY}.Form.Fields.IsActiveOrderNumber`]}</FormLabel>
        <Toggle isChecked={integrationState.isActiveOrderNumber} onChange={handleCheckboxChange('isActiveOrderNumber')} />
      </FormControl>

      <Flex mt="16">
        <Button
          size="small"
          type="button"
          kind="outline"
          variant="dark"
          disabled={isBusy || (deleteIntegrationResource && deleteIntegrationResource.isBusy)}
          onClick={handleClose}
          mr="11"
          width="full"
          maxWidth="118px"
        >
          {intl.messages[`${COMPONENT_INTL_KEY}.Form.Cancel`]}
        </Button>
        {isEdit && (
          <Button
            size="small"
            type="button"
            variant="danger"
            disabled={isBusy}
            isLoading={deleteIntegrationResource && deleteIntegrationResource.isBusy}
            onClick={handleDelete}
            width="full"
          >
            {intl.messages[`${COMPONENT_INTL_KEY}.Form.Delete`]}
          </Button>
        )}
        <Button
          size="small"
          type="submit"
          disabled={
            isBusy ||
            (deleteIntegrationResource && deleteIntegrationResource.isBusy) ||
            validator.hasErrors() ||
            isEmpty()
          }
          isLoading={isBusy && !(deleteIntegrationResource && deleteIntegrationResource.isBusy)}
          width="full"
          ml="11"
          variant="success"
        >
          {intl.messages[`${COMPONENT_INTL_KEY}.Form.Submit`]}
        </Button>
      </Flex>
    </form>
  );
};

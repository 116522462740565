import { SendToWarehouseGrid, TransferInfoModal } from '@components';
import { FileUploader } from '@components/molecules/FileUploader/FileUploader';
import { MemberPageContainer, MemberPageContainerProps } from '@containers/MemberPageContainer';
import { WarehouseContextualPanelContainer } from '@containers/WarehouseContextualPanelContainer';
import {
  ActionBar,
  Alert,
  AlertDescription,
  AlertTitle,
  Button,
  Flex,
  LayoutContent,
  Modal,
  ModalContent,
  ModalHeader,
  ModalTitle,
  Panel,
  PanelTitle,
} from '@oplog/express';
import { CreateReceivingOrderLineItemInputDTO, ReceivingOrderTransferType, UpdateReceivingOrderTransferDetailCommand } from '@services';
import * as React from 'react';
import { useState } from 'react';
import { Props } from '../../atoms/Component/Component';

const COMPONENT_INTL_KEY = 'SendToWarehouse';

export interface SendToWarehouseProps extends Props {
  onDismissPostErrors: () => void;
  onUpdateTransferDetails: (receivingOrderTransferDetail: UpdateReceivingOrderTransferDetailCommand) => void;
  postIsBusy: boolean;
  updateSucceeded: boolean;
  initResources: () => void;
  redirect: (referenceNumber: string) => void;
}

export const SendToWarehouse: React.FC<SendToWarehouseProps> = ({
  onDismissPostErrors,
  initResources,
  redirect,
  intl,
  onUpdateTransferDetails,
  postIsBusy,
  updateSucceeded,
}) => {
  const [isPostErrorOpen, setIsPostErrorOpen] = useState(false);
  const [excelUploadModalOpen, setExcelUploadModalOpen] = useState(false);
  const [isTransferInfoModalVisible, setIsTransferInfoModalVisible] = useState(false);
  const [referenceNumber, setReferenceNumber] = useState('');
  const [lineItems, setLineItems] = useState(Array<CreateReceivingOrderLineItemInputDTO>());
  const [errorCode, setErrorCode] = useState<any>('');

  // this.handlePostErrorDismiss = this.handlePostErrorDismiss.bind(this);

  const handlePostError = (error: ErrorModel) => {
    if (error && error.errors) {
      error.errors.forEach(error => {
        if (error.errorCode == 'ResourceAlreadyExists') {
          setErrorCode(error.errorCode);
        } else if (error.errorCode == 'InvalidReceivingOrderReferenceNumber') {
          setErrorCode(error.errorCode);
        } else if (error.errorCode == 'EmptyReceivingOrderShipmentDate') {
          setErrorCode(error.errorCode);
        } else if (error.errorCode == 'InvalidReceivingOrderShipmentDate') {
          setErrorCode(error.errorCode);
        }
        // Do not handle codes that is unknown.
      });
    }

    setIsPostErrorOpen(true);
  };

  const handlePostErrorDismiss = () => {
    setIsPostErrorOpen(false);
    setErrorCode('');
    onDismissPostErrors();
  };

  const changeExcelUploadModalOpen = () => {
    //let eventName = excelUploadModalOpen ? 'ExcelUploadModalClose' : 'ExcelUploadModalOpen';
    //tracking.emitEvent('Warehouse', eventName);
    setExcelUploadModalOpen(!excelUploadModalOpen);
  };

  const renderContextualPanel = () => {
    return <WarehouseContextualPanelContainer onPostError={handlePostError} />;
  };

  const handleOnFileUploaderSuccess = (props: any) => {
    setReferenceNumber(props.responseData.referenceNumber);
    setLineItems(props.responseData.lineItems)
    setIsTransferInfoModalVisible(true);
    //tracking.emitEvent('Warehouse', 'ExcelUploadSuccess');
  };

  const transferInfoModalInitial = () => {
    initResources();
    redirect(referenceNumber);
  };

  const contextualProps: Partial<MemberPageContainerProps> = {
    contextualArea: {
      component: renderContextualPanel(),
      header: intl.messages[`${COMPONENT_INTL_KEY}.ContextualPanel.Title`],
    },
  };

  const breadcrumb = [{ title: intl.messages[`${COMPONENT_INTL_KEY}.Header.Title`] }];

  return (
    <MemberPageContainer documentTitle={intl.messages[`${COMPONENT_INTL_KEY}.Header.Title`]} {...contextualProps}>
      <ActionBar top="66px" title={intl.messages[`${COMPONENT_INTL_KEY}.Header.Title`]} breadcrumb={breadcrumb}>
        <Button marginLeft="auto" variant="dark" kind="solid" size="large" onClick={changeExcelUploadModalOpen}>
          {intl.messages[`${COMPONENT_INTL_KEY}.CreateTransferByExcel`]}
        </Button>
      </ActionBar>

      {isPostErrorOpen && (
        <Alert variant="danger" isOpen={isPostErrorOpen} onDismiss={handlePostErrorDismiss}>
          <AlertTitle>{intl.messages['PostErrorMessage.Title']}</AlertTitle>
          <AlertDescription>
            {errorCode
              ? intl.messages[`PostErrorMessage.${errorCode}`]
              : intl.messages['PostErrorMessage.DefaultMessage']}
          </AlertDescription>
        </Alert>
      )}

      <LayoutContent>
        <Panel>
          <Flex justifyContent="space-between">
            <PanelTitle>{intl.messages[`${COMPONENT_INTL_KEY}.Grid.Title`]}</PanelTitle>
          </Flex>
          <SendToWarehouseGrid intl={intl} />
        </Panel>
        {isTransferInfoModalVisible && (
          <TransferInfoModal
            intl={intl}
            isModalOpen={isTransferInfoModalVisible}
            isSendToWarehouse={true}
            transferType={ReceivingOrderTransferType.CargoTransfer}
            transferDetails={{}}
            onUpdateTransferDetails={onUpdateTransferDetails}
            toggleTransferDetailsModal={() => setIsTransferInfoModalVisible(!isTransferInfoModalVisible)}
            referenceNumber={referenceNumber}
            lineItems={lineItems}
            isBusy={postIsBusy}
            isSuccess={updateSucceeded}
            initResources={transferInfoModalInitial}
          />
        )}
      </LayoutContent>
      {excelUploadModalOpen && (
        <Modal
          onClose={changeExcelUploadModalOpen}
          isOpen={excelUploadModalOpen}
          disableEscapeButtonClose={true}
          disableOutsideMouseEvents={true}
        >
          <ModalHeader>
            <ModalTitle>{intl.messages[`${COMPONENT_INTL_KEY}.CreateTransferByExcel`]}</ModalTitle>
          </ModalHeader>
          <ModalContent>
            <FileUploader
              intl={intl}
              uploadFileType={COMPONENT_INTL_KEY}
              apiUrlExtension="/api/ReceivingOrders.importFromExcel"
              fileType=".xls,.xlsx"
              onClose={changeExcelUploadModalOpen}
              onSuccess={handleOnFileUploaderSuccess}
            />
          </ModalContent>
        </Modal>
      )}
    </MemberPageContainer>
  );
};

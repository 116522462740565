import * as React from 'react';
import { InjectedIntl } from 'react-intl';

export interface Props {
  /**
   * intl is the object to inject react-intl"s provider to the components that use localization
   */
  intl: InjectedIntl;

  /**
   * isBusy indicates the loading state of a component
   */
  isBusy?: boolean;

  /**
   * error message of the response
   */
  error?: ErrorModel;

   /**
   * error message of the response
   */
   errorCreatePackages?: ErrorModel;

  /**
   * onWillMount is the callback triggered when componentWillMount
   */
  onWillMount?: () => void;

  /**
   * onWillReceiveProps is the callback triggered when componentWillReceiveProps
   */
  onWillReceiveProps?: (nextProps: any) => void;

  /**
   * onWillUnmount is the callback triggered when componentWillUnmount
   */
  onWillUnmount?: () => void;

  /**
   * onWillUpdate is the callback triggered when componentWillUpdate
   */
  onWillUpdate?: () => void;

  /**
   * onDidMount is the callback triggered when componentDidMount
   */
  onDidMount?: () => void;

  /**
   * onDidCatch is the callback triggered when componentDidCatch
   */
  onDidCatch?: () => void;

  /**
   * onDidUpdate is the callback triggered when componentDidUpdate
   */
  onDidUpdate?: () => void;
}

class Component<P extends Props, S> extends React.Component<P, S> {
  constructor(props: P) {
    super(props);
  }

  componentWillMount() {
    if (this.props.onWillMount) {
      this.props.onWillMount();
    }
  }

  componentWillReceiveProps(nextProps: any) {
    if (this.props.onWillReceiveProps) {
      this.props.onWillReceiveProps(nextProps);
    }
  }

  componentWillUnmount() {
    if (this.props.onWillUnmount) {
      this.props.onWillUnmount();
    }
  }

  componentWillUpdate() {
    if (this.props.onWillUpdate) {
      this.props.onWillUpdate();
    }
  }

  componentDidCatch() {
    if (this.props.onDidCatch) {
      this.props.onDidCatch();
    }
  }

  componentDidUpdate(prevProps: any) {
    if (this.props.onDidUpdate) {
      this.props.onDidUpdate();
    }
  }

  componentDidMount(): void {
    window.scrollTo(0, 0);
    if (this.props.onDidMount) {
      this.props.onDidMount();
    }
  }
}

export default Component;

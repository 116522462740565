//import { config } from '@config';
import { BulkActionOptions, DataGridFilter } from '@oplog/data-grid';
import history from '../../history';
import ReactGA from 'react-ga4';

export default class Tracking {
  trackingId: string;
  userId: string;

  constructor() {
    this.trackingId = 'G-QX7S6PRYRR';
  }

  initAnalytics() {
    ReactGA.initialize(this.trackingId);
    history.listen((location: any) => {
      ReactGA.send({ hitType: 'pageview', page: location.pathname, query: location.search, title: document.title });
    });
  }

  setUserId(userId: string) {
    ReactGA.set({
      userId: userId,
    });
    this.userId = userId;
  }

  emitEvent(category: string, action: string) {
    ReactGA.event({
      category: category,
      action: action,
    });
  }

  emitGridActionClick(data: BulkActionOptions) {
    this.emitEvent('ActionClick', String(data));
  }

  emitFilterOpen() {
    this.emitEvent('FilterOps', 'FiltersOpened');
  }

  emitFilterAdd(data: DataGridFilter[]) {
    // We are only interested in the latest addition, which refers to the item
    // at the index (data.length - 2) since the last one is empty/new
    let l = data.length;
    if (l < 2) {
      return;
    }
    let latest = data[l - 2];
    let columnKey = latest.column?.key;
    if (columnKey) {
      this.emitEvent('FilterOps', 'FilterSelect-' + columnKey);
    }
  }

  emitFilterSend(data: any) {
    if ('appliedFilters' in data) {
      let finalKey = '-';
      for (let af of data.appliedFilters) {
        finalKey += af.id.slice(0, 3) + '-';
      }
      this.emitEvent('FilterOps', 'FS' + finalKey);
    }
  }
}

export const tracking = new Tracking();

import { GridContainer } from '@components/atoms/GridContainer';
import { barcodeFormatter, geti18nName, ProductDetailsLinkFormatter } from '@containers/formatters';
import { GridType } from '@models';
import { ColumnSize, DataGridColumn, DataGridRow, ImageFormatter, PredefinedFilter } from '@oplog/data-grid';
import { ProductState } from '@services';
import useCreateSalesOrderState, { CreateSalesOrderStateKeys } from '@store/createSalesOrder/CreateSalesOrderState';
import {
  BooleanFilter,
  BooleanFilterOperation,
  SortDirection,
  SortField,
  StringFilter,
  StringFilterOperation,
} from 'dynamic-query-builder-client';
import * as React from 'react';
import { useEffect, useRef } from 'react';
import { Props } from '../../atoms/Component/Component';
import { SalesOrderNumericStep } from './bones';

export interface CreateSalesOrderGridProps extends Props {
  upsertRow?: (row: any) => void;
  createdSalesOrderData?: any;
}

const intlKey = 'CreateSalesOrderGrid';
const titleKey = 'CreateSalesOrder.Grid.Title';

const createSalesOrderGridInitalSort: SortField = new SortField({
  property: 'sku',
  by: SortDirection.ASC,
});

export const createSalesOrderGridPredefinedFilters: Array<PredefinedFilter> = [
  {
    filter: new StringFilter({
      id: 'State',
      property: 'state',
      op: StringFilterOperation.Equals,
      value: `${ProductState.Active}`,
    }),
    selected: true,
    visibility: false,
  },
  {
    filter: new BooleanFilter({
      id: 'Starred',
      property: 'favorite',
      op: BooleanFilterOperation.Equals,
      value: true,
    }),
    selected: false,
  },
];

export const CreateSalesOrderGrid: React.FC<CreateSalesOrderGridProps> = ({ intl }) => {
  const [state, actions] = useCreateSalesOrderState();
  const gridRef = useRef(state.gridData);

  useEffect(() => {
    if (state.panelData.length === 0) {
      actions.setStateData(CreateSalesOrderStateKeys.gridData, []);
    }
  }, [state.panelData]);

  useEffect(() => {
    gridRef.current = state.gridData;
  }, [state.gridData]);

  const onAmountChange = (amount: number, row: any, sku: string) => {
    let itemList = [...gridRef.current];
    const itemExist = itemList.findIndex(item => item.sku === sku);
    if (itemExist === -1 && amount !== 0) {
      itemList = [...itemList, { amount, row, sku }];
      actions.setStateData(CreateSalesOrderStateKeys.gridData, itemList);
    } else {
      if (amount === 0) {
        itemExist !== -1 && itemList.splice(itemExist, 1);
      } else {
        itemList[itemExist].amount = amount;
      }
      actions.setStateData(CreateSalesOrderStateKeys.gridData, itemList);
    }

    const newRows = itemList.map((item: any) => ({
      stockAmount: item.row.stockAmount,
      id: item.row.id,
      productName: item.row.name,
      sku: item.row.sku,
      amountInOrder: item.amount,
      imageUrl: item.row.imageUrl,
      barcodes: item.row.barcodes,
      availableStock: item.row.availableStock,
      productType: item.row.productType,
      productCategory: item.row.productCategory,
      availableAmount: item.row.availableAmount,
      missingQuantity: item.row.missingQuantity,
      cancellableAmount: item.row.cancellableAmount,
      packableAmount: item.row.packableAmount,
      isCancellable: item.row.isCancellable,
      cancelledAmount: item.row.cancelledAmount,
      state: item.row.state,
      isPendingReason: item.row.isPendingReason,
    }));

    actions.setStateData(CreateSalesOrderStateKeys.panelData, newRows);
  };

  const createSalesOrderCartGridColums: Array<DataGridColumn> = [
    {
      name: '#',
      key: 'index',
      resizable: true,
      locked: true,
      sortable: false,
      type: 'number',
      visibility: true,
      filterable: false,
      width: ColumnSize.Medium,
      cellClass: 'index-column',
    },
    {
      name: geti18nName('sku', intl, intlKey),
      key: 'sku',
      resizable: true,
      locked: true,
      sortable: true,
      type: 'string',
      visibility: true,
      filterable: true,
    },
    {
      name: geti18nName('barcodes', intl, intlKey),
      key: 'barcodes',
      resizable: true,
      locked: true,
      sortable: false,
      type: 'string',
      visibility: true,
      filterable: false,
      formatter: barcodeFormatter,
    },
    {
      name: geti18nName('name', intl, intlKey),
      key: 'name',
      resizable: true,
      locked: true,
      sortable: true,
      type: 'string',
      visibility: true,
      filterable: true,
      formatter: ProductDetailsLinkFormatter,
      getRowMetaData: (row: DataGridRow) => row,
    },
    {
      name: geti18nName('imageUrl', intl, intlKey),
      key: 'imageUrl',
      locked: true,
      sortable: false,
      type: 'string',
      visibility: true,
      filterable: false,
      formatter: ImageFormatter,
      width: ColumnSize.Medium,
    },
    {
      name: geti18nName('availableAmount', intl, intlKey),
      key: 'availableAmount',
      resizable: true,
      sortable: true,
      type: 'number',
      visibility: true,
      filterable: true,
    },
    {
      name: geti18nName('sentAmount', intl, intlKey),
      key: '',
      resizable: false,
      sortable: false,
      type: 'component',
      visibility: true,
      locked: true,
      filterable: false,
      formatter: row => {
        return (
          <SalesOrderNumericStep
            sku={row.dependentValues['sku']}
            dependentValues={row.dependentValues}
            onAmountChange={onAmountChange}
          />
        );
      },
      getRowMetaData: (row: DataGridRow) => row,
    },
  ];

  return (
    <GridContainer
      titleKey={titleKey}
      intlKey={intlKey}
      gridKey={GridType.Products}
      columns={createSalesOrderCartGridColums}
      predefinedFilters={createSalesOrderGridPredefinedFilters}
      intl={intl}
      sortField={createSalesOrderGridInitalSort}
      actionsDisabled
    />
  );
};

import {
  Button,
  Dialog,
  DialogProps,
  DialogTypes,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  FormValidator,
  Input,
  Modal,
  ModalContent,
  ModalHeader,
  ModalProps,
  ModalTitle,
} from '@oplog/express';
import { UserDTO } from '@services';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import useForceUpdate from 'use-force-update';
import * as Yup from 'yup';
import { Props } from '../../atoms/Component/Component';

const COMPONENT_INTL_KEY = 'Settings.ChangeUserInfoModal';
export interface ChangeUserInfoModalProps extends Props {
  isOpen: boolean;
  validator: FormValidator;
  onClose: (refresh?: boolean) => void;
  fields: Partial<UserDTO>;
  isSuccessful: boolean;
  onSubmit: (form: any, userID: string) => void;
  userID: string;
}

export interface ChangeUserInfoForm {
  fullName: string;
}
export interface ChangeUserInfoModalState {
  form: ChangeUserInfoForm;
  confirmationModal: boolean;
}

export const ChangeUserInfoModal: React.FC<ChangeUserInfoModalProps> = ({
  intl,
  validator,
  onClose,
  fields,
  isSuccessful,
  onSubmit,
  userID,
  isBusy,
}) => {
  const { fullName = '' } = fields;
  const [confirmationModal, setConfirmationModal] = useState(false);
  const forceUpdate = useForceUpdate();
  const [form, setForm] = useState<ChangeUserInfoForm>({
    fullName: fullName,
  });

  useEffect(() => {
    validator.registerSchema(changeUserInfoSchema());
  }, []);

  const changeUserInfoSchema = () => {
    return {
      fullName: Yup.string().required(`${intl.messages[`${COMPONENT_INTL_KEY}.Form.Errors.Fullname`]}`),
    };
  };

  /*
  const handleBlur = (fieldname: keyof ChangeUserInfoForm) => {
    return () => {
      const {
        form: { [fieldname]: field },
      } = this.state;

      setForm({
        [fieldName]: field
      });
    
      validator.validate(fieldname, field);
      forceUpdate();
    };
  }
  */

  const handleInputChange = (fieldname: keyof ChangeUserInfoForm, e: React.SyntheticEvent<HTMLInputElement>) => {
    setForm({
      [fieldname]: e.currentTarget.value,
    });

    validator.validate(fieldname, e.currentTarget.value);
    forceUpdate();
  };

  const submitCommand = () => {
    const formData = {
      userMetadata: {
        fullName: form.fullName,
      },
    };
    onSubmit(formData, userID);
  };

  const formSubmit = (e: React.SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!validator.valid(form)) {
      return;
    }
    setConfirmationModal(true);
  };

  const renderConfirmationDialog = () => {
    const dialogProps: DialogProps = {
      isLoading: isBusy,
      onCancel: () => setConfirmationModal(false),
      isOpen: true,
      onApprove: submitCommand,
      type: DialogTypes.Warning,
      text: {
        approve: intl.messages[`Modal.Warning.Okay`],
        cancel: intl.messages[`Modal.Warning.Cancel`],
      },
    };
    return (
      <Dialog
        {...dialogProps}
        message={
          <FormattedMessage
            id={`${COMPONENT_INTL_KEY}.ConfirmationDialog`}
            defaultMessage={intl.messages[`${COMPONENT_INTL_KEY}.ConfirmationDialog`]}
            values={{
              fullName: <b>{form.fullName}</b>,
            }}
          />
        }
      />
    );
  };

  const formModalProps: ModalProps = {
    isOpen: true,
    size: 'sm',
    onClose: () => onClose(false),
  };

  return (
    <Modal {...formModalProps}>
      <ModalHeader>
        <ModalTitle>{intl.messages[`${COMPONENT_INTL_KEY}.Title`]}</ModalTitle>
      </ModalHeader>
      <form onSubmit={formSubmit}>
        <ModalContent>
          <FormControl size="small" isInvalid={!!validator.getErrorIntent('fullName')} mb="22">
            <FormLabel>{intl.messages[`${COMPONENT_INTL_KEY}.Form.Labels.Fullname`]}</FormLabel>
            <Input
              value={form.fullName}
              placeholder={`${intl.messages[`${COMPONENT_INTL_KEY}.Form.Placeholders.Fullname`]}`}
              onChange={(e: React.SyntheticEvent<HTMLInputElement>) => handleInputChange('fullName', e)}
            />
            <FormErrorMessage>{validator.getErrorIntent('fullName')?.text}</FormErrorMessage>
          </FormControl>
          <Flex>
            <Button
              size="small"
              width={1 / 3}
              mr="11"
              type="button"
              kind="outline"
              variant="dark"
              disabled={isBusy}
              onClick={() => onClose()}
            >
              {intl.messages['Form.Action.Cancel']}
            </Button>
            <Button size="small" width={2 / 3} variant="success" disabled={isBusy} isLoading={isBusy} type="submit">
              {intl.messages['Form.Action.Save']}
            </Button>
          </Flex>
        </ModalContent>
      </form>
      {confirmationModal && renderConfirmationDialog()}
      {isSuccessful && (
        <Dialog
          type={DialogTypes.Success}
          message={
            <FormattedMessage
              id={`${COMPONENT_INTL_KEY}.SuccesDialog`}
              defaultMessage={intl.messages[`${COMPONENT_INTL_KEY}.SuccesDialog`]}
            />
          }
          isOpen={isSuccessful}
          onCancel={() => onClose(true)}
          onApprove={() => onClose(true)}
          text={{ approve: intl.messages[`Modal.Success.Okay`] }}
        />
      )}
    </Modal>
  );
};

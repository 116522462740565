import { Box, Datepicker, SelectHTML } from '@oplog/express';
import * as React from 'react';

const range = (start: any, count: any) => {
  return Array.apply(0, Array(count)).map((element: any, index: any) => index + start);
};

const date = new Date();
const yearsArray = range(date.getFullYear() - 5, 16);

const yearsOptions = [] as any;
yearsArray.forEach((year: string) => {
  yearsOptions.push({
    label: year,
    value: year,
  });
});

const months: Array<string> = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const turkishMonths: Array<string> = [
  'Ocak',
  'Şubat',
  'Mart',
  'Nisan',
  'Mayıs',
  'Haziran',
  'Temmuz',
  'Ağustos',
  'Eylül',
  'Ekim',
  'Kasım',
  'Aralık',
];

const monthLanguage = [months, turkishMonths];

enum Languages {
  English = 0,
  Turkish = 1,
}

const getMonthValues = (monthArray: any) => {
  var monthOptions = [] as any;
  monthArray.forEach((month: string, index: number) => {
    monthOptions.push({
      label: month,
      value: index,
    });
  });
  return monthOptions;
};

const YearMonthDatePicker = ({ ...props }: any) => {
  var valueIndex = Languages.English;
  if (props.intl.locale && props.intl.locale == 'tr-TR') {
    valueIndex = Languages.Turkish;
  }

  return (
    <Datepicker
      renderCustomHeader={({
        date,
        changeYear,
        changeMonth,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
      }) => (
        <Box
          style={{
            margin: 10,
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Box style={{ padding: '10px' }} onClick={() => decreaseMonth()}>
            <i className="fas fa-arrow-left"></i>
          </Box>
          <SelectHTML
            value={date.getMonth()}
            onChange={(e: any) => changeMonth(e.target.value)}
            options={getMonthValues(monthLanguage[valueIndex])}
          ></SelectHTML>

          <SelectHTML
            value={date.getFullYear()}
            onChange={(e: any) => changeYear(e.target.value)}
            options={yearsOptions}
          ></SelectHTML>

          <Box style={{ padding: '10px' }} onClick={() => increaseMonth()}>
            <i className="fas fa-arrow-right"></i>
          </Box>
        </Box>
      )}
      {...props}
    />
  );
};

export default YearMonthDatePicker;

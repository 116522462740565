import { GridContainer } from '@components/atoms/GridContainer';
import { geti18nName } from '@containers/formatters';
import { GridType } from '@models';
import { ColumnSize, DataGridColumn, DataGridRow, ellipsisFormatter, FormatterProps } from '@oplog/data-grid';
import { Button, Flex } from '@oplog/express';
import { SalesOrderQueryOutputDTO } from '@services';
import { checkShippingAddress } from '@utils';
import { SortDirection, SortField } from 'dynamic-query-builder-client';
import * as React from 'react';
import { Props } from '../../atoms/Component/Component';

const intlKey = 'CreateReturnLineItemsGrid';
const titleKey = 'CreateReturnLineItems.Grid.Title';

const createReturnLineItemsGridInitalSort: SortField = new SortField({
  property: 'orderCreatedAt',
  by: SortDirection.DESC,
});

export interface CreateReturnLineItemsGridProps extends Props {
  selectItem: (id: SalesOrderQueryOutputDTO) => void;
  salesOrder?: SalesOrderQueryOutputDTO;
}

export const CreateReturnLineItemsGrid: React.FC<CreateReturnLineItemsGridProps> = ({
  intl,
  selectItem,
  salesOrder,
}) => {
  const columns: DataGridColumn[] = [
    {
      name: '#',
      key: 'index',
      type: 'number',
      resizable: true,
      locked: true,
      sortable: false,
      visibility: true,
      filterable: false,
      width: ColumnSize.Medium,
      cellClass: 'index-column',
    },
    {
      name: geti18nName('referenceNumber', intl, intlKey),
      key: 'referenceNumber',
      type: 'string',
      resizable: true,
      locked: true,
      sortable: true,
      visibility: true,
      filterable: true,
    },
    {
      name: geti18nName('customerFullName', intl, intlKey),
      key: 'customerFullName',
      type: 'string',
      resizable: true,
      locked: true,
      sortable: true,
      visibility: true,
      filterable: true,
    },
    {
      name: geti18nName('shippingAddressFullName', intl, intlKey),
      key: 'shippingAddressFullName',
      type: 'string',
      locked: true,
      visibility: true,
      resizable: true,
      sortable: true,
      filterable: true,
    },
    {
      name: geti18nName('shippingAddress', intl, intlKey),
      key: 'shippingAddress',
      type: 'string',
      locked: true,
      sortable: true,
      visibility: true,
      filterable: true,
      formatter: row => {
        const modRow = { ...row };
        modRow.value = checkShippingAddress(row.value);
        return ellipsisFormatter(modRow);
      },
    },
    {
      name: geti18nName('id', intl, intlKey),
      key: 'id',
      type: 'string',
      resizable: false,
      sortable: false,
      visibility: true,
      locked: true,
      filterable: false,
      inputLabel: salesOrder && salesOrder.id,
      formatter: (data: FormatterProps) => (
        <Flex>
          <Button
            width="90px"
            size="small"
            variant={salesOrder && data.value === salesOrder.id ? 'primary' : 'dark'}
            onClick={() => {
              selectItem(data.dependentValues);
            }}
          >
            {salesOrder && data.value === salesOrder.id
              ? intl.messages[`${intlKey}.Buttons.Chosen`]
              : intl.messages[`${intlKey}.Buttons.Select`]}
          </Button>
        </Flex>
      ),
      getRowMetaData: (row: DataGridRow) => row,
    },
  ];

  return (
    <GridContainer
      titleKey={titleKey}
      intlKey={intlKey}
      gridKey={GridType.SalesOrdersReturnable}
      columns={columns}
      predefinedFilters={[]}
      apiArgs={[]}
      sortField={createReturnLineItemsGridInitalSort}
      intl={intl}
      actionsDisabled={true}
    />
  );
};

import { ResourceType } from '@models';
import { resourceActions, resourceSelectors } from '@oplog/resource-redux';
import { ReceivingOrders, ReceivingOrdersProps } from '@pages/ReceivingOrders';
import { urls } from '@routes/urls';
import { StoreState } from '@store';
import { push, RouterAction } from 'connected-react-router';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

function mapStateToProps({ resources, grid }: StoreState): Partial<ReceivingOrdersProps> {
  return {
    excelExportResource: resourceSelectors.getResource(resources, ResourceType.ReceivingOrdersExcelExport),
    gridFilters: grid.receivingOrders,
    excelExportDetailedResource: resourceSelectors.getResource(
      resources,
      ResourceType.ReceivingOrdersDetailedExcelExport
    ),
  };
}

function mapDispatchToProps(
  dispatch: Dispatch<resourceActions.ResourceAction | RouterAction>
): Partial<ReceivingOrdersProps> {
  return {
    routeSendToWarehouse: () => {
      dispatch(push(urls.warehouse));
    },
    excelToExport: (resourceType: ResourceType) => {
      const url_string = window.location.href;
      const url = new URL(url_string);
      const dqb = url.searchParams.get('dqb');

      const gridState = JSON.parse(decodeURIComponent(dqb ? dqb : ''));

      dispatch(resourceActions.resourceRequested(resourceType, { dqb: gridState.query }));
    },

    excelToExportInit: () => {
      dispatch(resourceActions.resourceInit(ResourceType.ReceivingOrdersExcelExport));
      dispatch(resourceActions.resourceInit(ResourceType.ReceivingOrdersDetailedExcelExport));
    },
  };
}

export const ReceivingOrdersContainer = connect(mapStateToProps, mapDispatchToProps)(injectIntl(ReceivingOrders));

import { PipelineStepState } from '@components';
import { resourceSelectors, ResourceStoreState } from '@oplog/resource-redux';
import { StateDetailOutputDTO, StateDetailsOutputDTO } from '@services';

export function getStatusDetails(pipelineKey: string, resources: ResourceStoreState) {
  const pipelineData: StateDetailsOutputDTO = resourceSelectors.getData(resources, pipelineKey);

  if (!pipelineData) {
    return pipelineData;
  }

  const statusDetails: Array<StateDetailOutputDTO> = pipelineData.details ? pipelineData.details : [];

  const pipelineSteps = statusDetails.map(
    (x: StateDetailOutputDTO): PipelineStepState => {
      const stepState: PipelineStepState = {
        active: false,
        disabled: false,
        completed: false,
        cancelled: false,
      };

      if (x.isCancelled) {
        return {
          ...stepState,
          cancelled: true,
        };
      }
      if (x.state < pipelineData.currentStateIndex || x.state < pipelineData.lastCompletedStateIndex || x.completedAt) {
        return {
          ...stepState,
          completed: true,
        };
      }
      if (x.state === pipelineData.currentStateIndex) {
        return {
          ...stepState,
          active: true,
        };
      }
      return {
        ...stepState,
        disabled: true,
      };
    }
  );

  return {
    pipelineData,
    pipelineSteps,
  };
}

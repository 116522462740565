export interface AppRouteConfigParams {
  url: string;
  component: React.ComponentType;
  protectedRoute?: boolean;
  enabled?: boolean;
  key?: string;
  scopes?: string[];
}

export class AppRouteConfig {
  public readonly key: string;

  public readonly url: string;

  public readonly component: React.ComponentType<any>;

  public readonly protectedRoute: boolean;

  public readonly enabled: boolean;

  public readonly scopes: string[];

  constructor(params: AppRouteConfigParams) {
    this.url = params.url;
    this.component = params.component;
    this.protectedRoute = params.protectedRoute !== undefined ? params.protectedRoute : true;
    this.enabled = params.enabled !== undefined ? params.enabled : true;
    this.key = params.key !== undefined ? params.key : params.url.replace('/', '');
    this.scopes = params.scopes ? params.scopes : [];
  }
}

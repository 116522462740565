import { ResourceType } from '@models';
import { gridActions } from '@oplog/data-grid';
import { resourceActions, resourceSelectors } from '@oplog/resource-redux';
import { urls } from '@routes/urls';
import { StoreState } from '@store';
import { push, RouterAction } from 'connected-react-router';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { Support, SupportProps } from '../../components/pages/Support';

function mapStateToProps({ resources }: StoreState): Partial<SupportProps> {
  return {
    tenantInfo: resourceSelectors.getResource(resources, ResourceType.GetTenantDetailedInfo),
    isBusy: resourceSelectors.isBusy(resources, ResourceType.GetTenantDetailedInfo),
  };
}

function mapDispatchToProps(
  dispatch: Dispatch<resourceActions.ResourceAction | gridActions.GridAction | RouterAction>
): Partial<SupportProps> {
  return {
    onSalesOrderClickHandler: (): void => {
      dispatch(push(urls.supportSalesOrders));
    },
    onReceivingOrderClickHandler: (): void => {
      dispatch(push(urls.supportReceivingOrders));
    },
    onPackagesClickHandler: (): void => {
      dispatch(push(urls.supportPackages));
    },
    onDidMount: (): void => {
      dispatch(resourceActions.resourceRequested(ResourceType.GetTenantDetailedInfo));
    },
  };
}

export const SupportPageContainer = connect(mapStateToProps, mapDispatchToProps)(injectIntl(Support));

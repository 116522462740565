import { ResourceType } from '@models';
import { resourceActions, resourceSelectors } from '@oplog/resource-redux';
import { InvalidSalesOrderDetails, InvalidSalesOrderDetailsProps } from '@pages/InvalidSalesOrderDetails';
import { StoreState } from '@store';
import { push, RouterAction } from 'connected-react-router';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { Dispatch } from 'redux';
import { getRouteParam } from '@utils';
import { GridAction } from '@oplog/data-grid/dist/store/actions';
import { validation } from '@oplog/express';
import { CorrectInvalidSalesOrderCommand } from '@services';
import { urls } from '@routes/urls';
export interface InvalidSalesOrderDetailsContainerProps extends RouteComponentProps {
  referenceNumber: number;
}

export function mapStateToProps(
  state: StoreState,
  ownProps: InvalidSalesOrderDetailsContainerProps
): Partial<InvalidSalesOrderDetailsProps> {
  const referenceNumber = getRouteParam(ownProps, 'referenceNumber');
  const refNo = decodeURIComponent(referenceNumber);
  const isBusy = resourceSelectors.isBusy(state.resources, ResourceType.InvalidSalesOrderDetails);
  const invalidSalesOrderDetails = resourceSelectors.getData(state.resources, ResourceType.InvalidSalesOrderDetails);
  const error = resourceSelectors.getError(state.resources, ResourceType.InvalidSalesOrderDetails);
  const cargoCompaniesOutput = resourceSelectors.getData(state.resources, ResourceType.CargoCarriers);

  return {
    correctInvalidSalesOrderResource: state.resources[ResourceType.CorrectInvalidSalesOrder],
    invalidSalesOrderDetails,
    isBusy,
    error,
    referenceNumber: refNo,
    cargoCompaniesOutput,
    invalidSalesOrderLineItems: resourceSelectors.getData(state.resources, ResourceType.InvalidSalesOrderLineItems),
  };
}

export function mapDispatchToProps(
  dispatch: Dispatch<resourceActions.ResourceAction | RouterAction | GridAction>,
  routeProps: RouteComponentProps
): Partial<InvalidSalesOrderDetailsProps> {
  const referenceNumber = decodeURIComponent(routeProps?.match?.params?.['referenceNumber']);

  const getInvalidSalesOrderDetails = (refNumber?: any) => {
    const value = { referenceNumber: refNumber || referenceNumber };
    dispatch(resourceActions.resourceRequested(ResourceType.InvalidSalesOrderDetails, value));
  };

  const getInvalidSalesOrderLineItems = () => {
    const value = { referenceNumber };

    dispatch(resourceActions.resourceRequested(ResourceType.InvalidSalesOrderLineItems, value));
  };

  const getCargoCarrierOptions = () => {
    dispatch(resourceActions.resourceRequested(ResourceType.CargoCarriers));
  };

  return {
    onDidMount(): void {
      getInvalidSalesOrderDetails();
      getInvalidSalesOrderLineItems();
      getCargoCarrierOptions();
    },
    refreshData: () => {
      getInvalidSalesOrderDetails();
      getInvalidSalesOrderLineItems();
    },
    redirectOnSalesOrderCreateSuccess(): void {
      dispatch(push(urls.salesOrders.replace(':tab', `Quarantine`)));
    },
    onWillUnmount: (): void => {
      dispatch(resourceActions.resourceInit(ResourceType.InvalidSalesOrderDetails));
    },
    onSubmit(referenceNumber: string | undefined, form: CorrectInvalidSalesOrderCommand) {
      dispatch(
        resourceActions.resourceRequested(ResourceType.CorrectInvalidSalesOrder, {
          referenceNumber,
          correctInvalidSalesOrderCommand: form,
        })
      );
    },
  };
}

export const InvalidSalesOrderDetailsContainer = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(validation(injectIntl(InvalidSalesOrderDetails)))
);

import { ModuleScopes } from '@auth';
import { useAuth0 } from '@auth/auth0';
import { config } from '@config';
import { FeedbackFormContainer } from '@containers/FeedbackFormContainer';
import {
  Box,
  Button,
  Drawer,
  DrawerContent,
  DrawerTitle,
  Flex,
  FormControl,
  FormLabel,
  Header,
  HeaderLogo,
  Icon,
  Image,
  isEmpty,
  Layout,
  LayoutContent,
  Link as OplogExpressLink,
  Modal,
  ModalContent,
  ModalHeader,
  ModalTitle,
  Popover,
  PseudoBox,
  Select,
  SideBar,
  SideBarItem,
  Text,
  UserDropdown
} from '@oplog/express';
import { Resource } from '@oplog/resource-redux';
import { urls } from '@routes/urls';
import { IntegrationOutputDTO, TenantOutputDTO } from '@services';
import useAuthStore from '@store/auth/auth';
import useCommonDataStore from '@store/commonData/commonDataState';
import * as React from 'react';
import { useEffect } from 'react';
import { FormattedMessage, InjectedIntl } from 'react-intl';
import { RouteChildrenProps } from 'react-router';
import { Link } from 'react-router-dom';
import { LanguageHelper } from '../../../utils/language';
import { TenantTeleport } from './TenantTeleport';

export const UserContext = React.createContext({});

export interface MemberPageProps {
  intl: InjectedIntl;
  contextualArea?: {
    component?: JSX.Element;
    header?: string;
  };
  className?: string;
  documentTitle: string;
  id?: string;
  routeTo: (key: string) => void;
  fetchTenantList: () => void;
  tenantList: Resource<TenantOutputDTO[]>;
  getIntegrations: () => void;
  integrations: Resource<IntegrationOutputDTO[]>;
  match?: RouteChildrenProps['match'];
}

const COMPONENT_INTL_KEY = 'MemberPage';

export const MemberPage: React.FC<MemberPageProps> = ({
  intl,
  routeTo,
  fetchTenantList,
  tenantList,
  className,
  documentTitle,
  contextualArea,
  id = '',
  children,
  match,
  integrations,
  getIntegrations,
}) => {
  const [{ auth0UserInfo }, { setAuth0UserInfo, userHasScope, userIsAdmin }] = useAuthStore();

  const [languageModal, setLanguageModal] = React.useState(false);
  const [marketingModal, setMarketingModal] = React.useState(false);
  const [selectLanguage, setSelectLanguage] = React.useState(config.i18n.default);
  const [activePage, setActivePage] = React.useState('');
  const [modifiedURLs, setModifiedURLs] = React.useState<any>(urls);
  const [isSystemAdmin, setIsSystemAdmin] = React.useState(false);
  const [userName, setUserName] = React.useState('');

  const { logout, user, loading } = useAuth0();

  const [state, actions] = useCommonDataStore();

  useEffect(() => {
    if (userIsAdmin()) {
      setIsSystemAdmin(true);
    }
  }, [auth0UserInfo]);

  useEffect(() => {
    if (!state.tenants && tenantList && tenantList.data) {
      actions.setTenants(tenantList);
    }
  }, [tenantList]);

  useEffect(() => {
    // if url has :tab prop we are deleting that and redirecting without prop
    const fixedUrls = Object.keys(urls).reduce((prev, current) => {
      const index = urls[current].indexOf('/:');
      return {
        ...prev,
        [current]: urls[current].substring(0, index < 0 ? undefined : index),
      };
    }, {});

    setModifiedURLs(fixedUrls);

    const getActiveSidebarItem = () => {
      const routeKey = Object.keys(urls).find(k => urls[k] === match?.path);
      if (routeKey) setActivePage(routeKey);
    };

    // auth.getUserMetadata((data: UserMetadata) => setAuth0TenantData(data));
    setSelectLanguage(LanguageHelper.getLanguage());
    getActiveSidebarItem();

    if (!state.integrations) {
      getIntegrations();
    }

    let isOpenMarketingModal = localStorage.getItem('isOpenMarketingModal');
    if (isOpenMarketingModal == 'false' || isOpenMarketingModal === null) {
      setMarketingModal(true);
    }
  }, []);

  useEffect(() => {
    if (!state.integrations && integrations) {
      actions.setIntegrations(integrations);
    }
  }, [integrations]);

  useEffect(() => {
    if (user) {
      setAuth0UserInfo(user);
      // For Easy Tenant Switching
      if (!state.tenants) {
        fetchTenantList();
      }
    }
  }, [user]);

  const sideBarOptions: SideBarItem[] = [
    {
      key: 'salesOrders',
      name: intl.messages['SideBar.Orders'],
      icon: { name: 'fad fa-truck-loading' },
      isVisible: true, // for faster loading purpoes since this is a scope that is viewed by every user.
      isActive: activePage === 'salesOrders',
      itemProps: { to: modifiedURLs['salesOrders'] },
    },
    {
      key: 'inventory',
      name: intl.messages['SideBar.Inventory'],
      icon: { name: 'fad fa-tags' },
      isVisible: true,
      isActive: activePage === 'inventory',
      itemProps: { to: modifiedURLs['inventory'] },
    },
    {
      key: 'products',
      name: intl.messages['SideBar.Products'],
      icon: { name: 'fad fa-warehouse-alt' },
      isVisible: true,
      isActive: activePage === 'products',
      itemProps: { to: modifiedURLs['products'] },
    },
    {
      key: 'customers',
      name: intl.messages['SideBar.Contacts'],
      icon: {
        name: 'fad fa-address-book',
        fontSize: '22px',
      },
      isVisible: true,
      isActive: activePage === 'customers',
      itemProps: { to: modifiedURLs['customers'] },
    },
    {
      key: 'returns',
      name: intl.messages['SideBar.Returns'],
      icon: { name: 'fad fa-fragile', fontSize: '22px' },
      isVisible: true,
      isActive: activePage === 'returns',
      itemProps: { to: modifiedURLs['returns'] },
    },
    {
      key: 'warehouse',
      name: intl.messages['SideBar.Warehouse'],
      icon: { name: 'fad fa-shipping-fast' },
      isVisible: true,
      isActive: activePage === 'warehouse',
      itemProps: { to: modifiedURLs['warehouse'] },
    },
    {
      key: 'receivingOrders',
      name: intl.messages['SideBar.ReceivingOrders'],
      icon: { name: 'fad fa-pallet' },
      isVisible: true,
      isActive: activePage === 'receivingOrders',
      itemProps: { to: modifiedURLs['receivingOrders'] },
    },
    {
      key: 'integrations',
      name: intl.messages['SideBar.Integrations'],
      icon: { name: 'fad fa-plug', fontSize: '22px' },
      isVisible: true,
      isActive: activePage === 'integrations',
      itemProps: { to: modifiedURLs['integrations'] },
    },
    {
      key: 'support',
      name: intl.messages['SideBar.Support'],
      icon: {
        name: 'fad fa-user-headset',
        fontSize: '17px',
      },
      isVisible: userHasScope(ModuleScopes.Support),
      isActive: activePage === 'support',
      itemProps: { to: modifiedURLs['support'] },
    },
  ];

  const menuOptions = [
    {
      value: 'user-settings',
      text: intl.messages[`${COMPONENT_INTL_KEY}.UserMenu.UserSettings`],
      icon: { name: 'fal fa-cog' },
      onClick: () => routeTo(urls.settings),
    },
    {
      value: 'change-language',
      text: intl.messages[`${COMPONENT_INTL_KEY}.UserMenu.ChangeLanguage`],
      icon: { name: 'fal fa-flag' },
      onClick: () => setLanguageModal(true),
    },
    {
      value: 'sign-out',
      text: intl.messages[`${COMPONENT_INTL_KEY}.UserMenu.LogOut`],
      icon: { name: 'fal fa-power-off' },
      onClick: () => (!loading ? logout({ returnTo: config.auth.logout_uri }) : () => { }),
    },
  ];

  const changeLanguage = () => {
    LanguageHelper.setLanguage(selectLanguage);
    setLanguageModal(false);
  };

  const userMetadata = auth0UserInfo[config.auth.userMetadataKey];
  document.title = !isEmpty(documentTitle) ? `${documentTitle} - OPLOG` : 'OPLOG';

  useEffect(() => {
    if (userMetadata && userMetadata) {
      setUserName(userMetadata.tenant.name);
    }
  });   

  return (
    <>
    <p hidden><i>{userName}</i></p>
      <Layout className={className} id={id}>
        <Header justifyContent="space-between">
          <HeaderLogo onClick={() => routeTo(urls.home)}>
            <Image height="30px" src="/images/oplog_logo_2.png" alt="Logo" />
          </HeaderLogo>
          <Flex>
            <Box
              display="flex"
              mr="11px"
              alignItems="center"
              onClick={() => {
                setMarketingModal(true);
              }}
            >
              <Popover
                content={intl.messages[`${COMPONENT_INTL_KEY}.UserMenu.MarketingReferral`]}
                action={['hover']}
                placement="bottom"
                isDark
              >
                <PseudoBox as="button" bg="transparent" border="0" height="100%" _focus={{ outline: 0 }}>
                  <Icon name="fal fa-user-plus" fontSize="28px" color="palette.blue_dark" />
                </PseudoBox>
              </Popover>
            </Box>
            <Box display="flex" mr="11px" alignItems="center">
              <Popover
                content={intl.messages[`${COMPONENT_INTL_KEY}.UserMenu.Help`]}
                action={['hover']}
                placement="bottom"
                isDark
              >
                <PseudoBox as="button" bg="transparent" border="0" height="100%" _focus={{ outline: 0 }}>
                  <OplogExpressLink
                    href="https://oplog.getoutline.com/share/41c03ca7-b915-413c-8f6a-384b9f237cb0"
                    isExternal
                  >
                    <Icon name="fal fa-file-alt" fontSize="28px" color="palette.blue_dark" />
                  </OplogExpressLink>
                </PseudoBox>
              </Popover>
            </Box>
            {/* <FeedbackFormContainer /> */}
            {state.tenants?.data && state.tenants?.data.length > 0 && <TenantTeleport tenantList={state.tenants} />}
            <UserDropdown
              imagePath="/images/earth1.jpg"
              options={menuOptions}
              fullName={userMetadata ? userMetadata.fullName : ''}
            />
          </Flex>
        </Header>
        <SideBar
          items={sideBarOptions}
          color="palette.slate"
          width="268px"
          secondaryColor="slate_light"
          itemProps={{
            as: Link,
          }}
        />
        {contextualArea && contextualArea.component && (
          <Drawer id="contextual-grid" width="413px">
            <DrawerTitle>{contextualArea.header || ''}</DrawerTitle>
            <DrawerContent mb="100px">{contextualArea.component}</DrawerContent>
          </Drawer>
        )}
        <LayoutContent p={0}>
          <UserContext.Provider value={auth0UserInfo}>{children}</UserContext.Provider>
        </LayoutContent>
      </Layout>
      {languageModal && (
        <Modal isOpen={languageModal} onClose={() => setLanguageModal(false)} size="sm">
          <ModalHeader>
            <ModalTitle>{intl.messages[`${COMPONENT_INTL_KEY}.LanguageModal.Header`]}</ModalTitle>
          </ModalHeader>
          <ModalContent>
            <FormControl>
              <FormLabel>{intl.messages[`${COMPONENT_INTL_KEY}.LanguageModal.Label`]}</FormLabel>
              <Select
                onChange={(e: React.SyntheticEvent<HTMLInputElement>) => setSelectLanguage(e.currentTarget.value)}
                placeholder={intl.messages[`${COMPONENT_INTL_KEY}.LanguageModal.Label`]}
                options={[
                  { label: intl.messages[`${COMPONENT_INTL_KEY}.LanguageModal.Turkish`], value: 'tr-TR' },
                  { label: intl.messages[`${COMPONENT_INTL_KEY}.LanguageModal.English`], value: 'en-US' },
                ]}
                value={selectLanguage}
              />
              <Flex mt="16">
                <Button
                  width={1 / 3}
                  mr="11"
                  kind="outline"
                  variant="dark"
                  onClick={() => setLanguageModal(false)}
                  size="small"
                >
                  {intl.messages['Modal.Confirmation.Cancel']}
                </Button>
                <Button width={2 / 3} variant="success" onClick={changeLanguage} size="small">
                  {intl.messages['Form.Action.Save']}
                </Button>
              </Flex>
            </FormControl>
          </ModalContent>
        </Modal>
      )}
      {marketingModal && (
        <Modal
          isOpen={marketingModal}
          onClose={() => {
            setMarketingModal(false);
            localStorage.setItem('isOpenMarketingModal', 'true');
          }}
          size="lg"
        >
          <ModalHeader>
            <ModalTitle>{intl.messages[`${COMPONENT_INTL_KEY}.MarketingModal.Header`]}</ModalTitle>
          </ModalHeader>
          <ModalContent>
            <Text font="heading" fontSize="14">
              <FormattedMessage
                id={`${COMPONENT_INTL_KEY}.MarketingModal.Content`}
                values={{
                  space: <br />,
                }}
              />
            </Text>
            <Button
              mt="16px"
              size="small"
              width="100%"
              onClick={() => {
                setMarketingModal(false);
                localStorage.setItem('isOpenMarketingModal', 'true');
                window.open(
                  `https://www.oplog.io/tr/tavsiye-programi?onerilen_musteri_oplog_one_tenant_id=${userMetadata.tenant.id}&oneren_kisinin_sirketi=${userMetadata.tenant.name}&oneren_kisinin_ismi=${userMetadata.fullName}`,
                  '_blank'
                );
              }}
            >
              {intl.messages[`${COMPONENT_INTL_KEY}.MarketingModal.Button`]}
            </Button>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};

import { Props } from '@components/atoms/Component/Component';
import { HTTP_ERRORS } from '@models';
import { Box, Button, Flex, FormControl, FormErrorMessage, FormLabel, FormValidator, Input } from '@oplog/express';
import { CreateUserCommand, ErrorCode } from '@services';
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';

const COMPONENT_INTL_KEY = 'Settings';

export interface AddUserFormProps extends Props {
  onSubmit: (form: CreateUserCommand) => void;
  onClose: () => void;
  validator: FormValidator;
  isBusy: boolean;
  createError: ErrorModel;
  closeConfirmationModal: () => void;
}

export interface AddUserFormState {
  fullName: string | undefined;
  email: string | undefined;
}

export const AddUserForm: React.FC<AddUserFormProps> = ({
  intl,
  onSubmit,
  onClose,
  validator,
  isBusy,
  createError,
  closeConfirmationModal,
  onWillMount,
  onWillUnmount,
}: AddUserFormProps) => {
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [formErrors, setFormErrors] = useState<AddUserFormState>({
    fullName: undefined,
    email: undefined,
  });

  useEffect(() => {
    onWillMount && onWillMount();
    validator.registerSchema(addUserFormSchema());

    return () => {
      onWillUnmount && onWillUnmount();
      validator.clearErrors();
    };
  }, []);

  useEffect(() => {
    if (createError && createError.code === HTTP_ERRORS.Conflict) {
      closeConfirmationModal();
    }
  }, [createError]);

  const isEmpty = () => {
    return fullName == '' || email == '';
  };

  const addUserFormSchema = () => {
    return {
      fullName: Yup.string().required(`${intl.messages[`${COMPONENT_INTL_KEY}.AddUserForm.Error.Fullname`]}`),
      email: Yup.string()
        .email(`${intl.messages[`${COMPONENT_INTL_KEY}.AddUserForm.Error.EmailInvalid`]}`)
        .required(`${intl.messages[`${COMPONENT_INTL_KEY}.AddUserForm.Error.Email`]}`),
    };
  };

  const handleFullNameChange = (e: any) => {
    setFullName(e.currentTarget.value);
  };

  const handleEmailChange = (e: any) => {
    setEmail(e.currentTarget.value);
    // Didn't call this is fullName because it is unnecessary. Since email is the second form and has format while fullname can only be empty. Which is checked in fullname.
    validator.validate('email', email);
    setFormErrors({
      ...formErrors,
      email: validator.getErrorIntent('email')?.text,
    });
  };

  const handleFullNameBlur = () => {
    validator.validate('fullName', fullName);
    setFormErrors({
      ...formErrors,
      fullName: validator.getErrorIntent('fullName')?.text,
    });
  };

  const handleEmailBlur = () => {
    validator.validate('email', email);
    setFormErrors({
      ...formErrors,
      email: validator.getErrorIntent('email')?.text,
    });
  };

  const getEmailError = (): string | undefined => {
    if (createError && createError.errors) {
      for (const e of createError.errors) {
        if (e.property.toLowerCase() === 'email' && e.errorCode === ErrorCode.ResourceAlreadyExists.toString()) {
          return intl.messages[`${COMPONENT_INTL_KEY}.AddUserForm.Error.EmailExists`];
        }
      }
    }
    return formErrors.email;
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const form = { fullName, email };
    if (!validator.valid(form)) {
      return;
    }
    onSubmit(form);
  };

  return (
    <form onSubmit={handleSubmit}>
      <FormControl size="small" isInvalid={!!validator.getErrorIntent('fullName')} isDisabled={isBusy} isInline mb="22">
        <FormLabel>{intl.messages[`${COMPONENT_INTL_KEY}.AddUserForm.Inputs.Fullname.Label`]}*</FormLabel>
        <Input
          onBlur={handleFullNameBlur}
          onChange={handleFullNameChange}
          placeholder={intl.messages[`${COMPONENT_INTL_KEY}.AddUserForm.Inputs.Fullname.Placeholder`]}
        />
        <FormErrorMessage>{formErrors.fullName}</FormErrorMessage>
      </FormControl>
      <FormControl size="small" isInvalid={!!getEmailError()} isDisabled={isBusy} isInline mb="22">
        <FormLabel>{intl.messages[`${COMPONENT_INTL_KEY}.AddUserForm.Inputs.Email.Label`]}*</FormLabel>
        <Input
          onBlur={handleEmailBlur}
          onChange={handleEmailChange}
          placeholder={intl.messages[`${COMPONENT_INTL_KEY}.AddUserForm.Inputs.Email.Placeholder`]}
        />
        <FormErrorMessage>{getEmailError()}</FormErrorMessage>
      </FormControl>
      <Flex>
        <Box width={1 / 3} pr="11">
          <Button
            type="button"
            disabled={isBusy}
            onClick={onClose}
            kind="outline"
            variant="dark"
            width={1}
            size="small"
          >
            {intl.messages[`${COMPONENT_INTL_KEY}.AddUserForm.Buttons.Cancel`]}
          </Button>
        </Box>
        <Box width={2 / 3}>
          <Button
            variant="success"
            disabled={isBusy || isEmpty() || validator.hasErrors()}
            isLoading={isBusy}
            type="submit"
            width={1}
            size="small"
          >
            {intl.messages[`${COMPONENT_INTL_KEY}.AddUserForm.Buttons.Submit`]}
          </Button>
        </Box>
      </Flex>
    </form>
  );
};

import * as Yup from 'yup';

export const phoneValidation = (
  requiredErrorMessage: string,
  phoneLengthErrorMessage: string,
  phoneTypeErrorMessage: string
) => {
  const regEx: RegExp = /^[+][0-9]{0,}$/; // This regular expression only validates if the string starts with a + sign and the rest of the string contains digits.
  const phoneNumberMaxLength = 15; // Including the + sign.
  const minimumPhoneNumberLength = 12; // Including the + sign.

  return Yup.string()
    .required(requiredErrorMessage)
    .test(
      'len',
      phoneLengthErrorMessage,
      val =>
        !val ||
        (val && val.toString().length <= phoneNumberMaxLength && val.toString().length >= minimumPhoneNumberLength)
    )
    .matches(regEx, phoneTypeErrorMessage);
};

export const isFormEmpty = (requiredFields: string[], formObject: any) => {
  const hasEmptyFields = requiredFields.some((key: string) => !formObject[key]);
  return hasEmptyFields;
};

export interface userErrorMessageInterface {
  firstName: {
    required: string;
    invalid: string;
  };
  lastName: {
    required: string;
    invalid: string;
  };
  email: {
    invalid: string;
    required: string;
  };
  phone: {
    required: string;
    length: string;
    type: string;
  };
}

export const generateUserFormSchema = (errorMessages: userErrorMessageInterface) => {
  const regExp: RegExp = /\D/; // Names shouldn't contain numbers.
  const { firstName, lastName, email, phone } = errorMessages;

  const schema = {
    firstName: Yup.string()
      .required(firstName.required)
      .matches(regExp, firstName.invalid),
    lastName: Yup.string()
      .required(lastName.required)
      .matches(regExp, lastName.invalid),
    email: Yup.string()
      .email(email.invalid)
      .required(email.required),
    phone: phoneValidation(phone.required, phone.length, phone.type),
  };

  return schema;
};

export interface addressErrorMessages {
  addressFirstName: {
    required: string;
    invalid: string;
  };
  addressLastName: {
    required: string;
    invalid: string;
  };
  addressOne: {
    required: string;
  };
  district: {
    required: string;
  };
  city: {
    required: string;
  };
  country: {
    required: string;
  };
  addressPhone: {
    required: string;
    length: string;
    type: string;
  };
}
  
export const generateAddressFormSchema = (errorMessages: addressErrorMessages) => {
  const regExp: RegExp = /\D/; // Names shouldn't contain numbers.
  const { addressFirstName, addressLastName, addressOne, district, city, country, addressPhone } = errorMessages;

  const schema = {
    addressFirstName: Yup.string()
      .required(addressFirstName.required)
      .matches(regExp, addressFirstName.invalid),
    addressLastName: Yup.string()
      .required(addressLastName.required)
      .matches(regExp, addressLastName.invalid),
    addressOne: Yup.string().required(addressOne.required),
    district: Yup.string().required(district.required),
    city: Yup.string().required(city.required),
    country: Yup.string().required(country.required),
    // postalCode: Yup.string().required(`${intl.messages[`${COMPONENT_INTL_KEY}.Error.Receiver.PostalCode`]}`),
    addressPhone: phoneValidation(addressPhone.required, addressPhone.length, addressPhone.type),
  };

  return schema;
};

/* eslint-disable no-nested-ternary */
import { MainContent } from '@components';
import CorrectInvalidSalesOrderModal from '@components/molecules/CorrectInvalidSalesOrderModal/CorrectInvalidSalesOrderModal';
import { InvalidSalesOrderLineItemsGrid } from '@components/organisms/InvalidSalesOrderLineItemsGrid';
import { getIntegrationImageSrc, getCargoCarrierImageSrc } from '@containers/formatters';
import { MemberPageContainer } from '@containers/MemberPageContainer/MemberPageContainer';
import {
  ActionBar,
  Box,
  Button,
  Dialog,
  DialogProps,
  DialogTypes,
  Flex,
  FormValidator,
  Icon,
  Link,
  Panel,
  Tab,
  Text,
  Image,
  Widget,
} from '@oplog/express';
import { urls } from '@routes/urls';
import {
  CorrectInvalidSalesOrderCommand,
  InvalidSalesOrderDetailOutputDTO,
  LineItemOfSalesOrderQueryOutputDTODynamicQueryOutputDTO,
  OmnitroErrorCode,
} from '@services';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import Skeleton from 'react-loading-skeleton';
import { Props } from '../../atoms/Component/Component';
import { OrderStates } from '../../organisms';
import { integrationIdentifier } from '@utils';

const COMPONENT_INTL_KEY = 'InvalidSalesOrderDetails';

export interface InvalidSalesOrderDetailsProps extends Props {
  invalidSalesOrderDetails: InvalidSalesOrderDetailOutputDTO;
  referenceNumber: string;
  validator: FormValidator;
  cargoCompaniesOutput: any;
  grid?: {
    salesOrderLineItems: any;
  };
  invalidSalesOrderLineItems: LineItemOfSalesOrderQueryOutputDTODynamicQueryOutputDTO;
  refreshData: () => void;
  getInvalidSalesOrderLineItems: () => void;
  onSubmit: (referenceNumber: string | undefined, form: CorrectInvalidSalesOrderCommand) => void;
  correctInvalidSalesOrderResource: any;
  redirectOnSalesOrderCreateSuccess: () => void;
}

interface Modals {
  cancelModal: boolean;
  packageModal: boolean;
}
export interface InvliadSalesOrderDetailsState {
  modals: Modals;
  currentTab: number;
}

export const InvalidSalesOrderDetails: React.FC<InvalidSalesOrderDetailsProps> = ({
  intl,
  invalidSalesOrderDetails: invalidSalesOrder,
  error,
  referenceNumber,
  isBusy,
  validator,
  onDidMount,
  getInvalidSalesOrderLineItems,
  invalidSalesOrderLineItems,
  onWillUnmount,
  refreshData,
  onSubmit,
  correctInvalidSalesOrderResource,
  cargoCompaniesOutput,
  redirectOnSalesOrderCreateSuccess,
  ...otherProps
}) => {
  const [currentTab, setCurrentTab] = React.useState(0);
  const [correctInvalidSalesOrderModal, setCorrectInvalidSalesOrderModal] = React.useState(false);
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  const [receivedPackageNumber, setPackageNumber] = React.useState<any>(undefined);
  let orderState: keyof typeof OrderStates = 'LineItems';

  React.useEffect(() => {
    if (correctInvalidSalesOrderResource) {
      if (correctInvalidSalesOrderResource.error || correctInvalidSalesOrderResource.isSuccess) setIsDialogOpen(true);
    }
  }, [correctInvalidSalesOrderResource]);

  React.useEffect(() => {
    onDidMount && onDidMount();
    return () => {
      onWillUnmount && onWillUnmount();
    };
  }, []);

  const isEditButtonActive = () => {
    if (
      invalidSalesOrder?.error === OmnitroErrorCode.EmptyAddressFirstName ||
      invalidSalesOrder?.error === OmnitroErrorCode.EmptyAddressLastName ||
      invalidSalesOrder?.error === OmnitroErrorCode.EmptyAddressPhone ||
      invalidSalesOrder?.error === OmnitroErrorCode.EmptyAddressOne ||
      invalidSalesOrder?.error === OmnitroErrorCode.EmptyAddressCity ||
      invalidSalesOrder?.error === OmnitroErrorCode.EmptyAddressPostalCode ||
      invalidSalesOrder?.error === OmnitroErrorCode.EmptyAddressCountry ||
      invalidSalesOrder?.error === OmnitroErrorCode.EmptyCustomerFirstName ||
      invalidSalesOrder?.error === OmnitroErrorCode.EmptyCustomerLastName ||
      invalidSalesOrder?.error === OmnitroErrorCode.EmptyCustomerPhone ||
      invalidSalesOrder?.error === OmnitroErrorCode.CustomerEmailWrongFormat ||
      invalidSalesOrder?.error === OmnitroErrorCode.InvalidCountry ||
      invalidSalesOrder?.error === OmnitroErrorCode.InvalidCity ||
      invalidSalesOrder?.error === OmnitroErrorCode.InvalidDistrict ||
      invalidSalesOrder?.error === OmnitroErrorCode.InvalidCargoDocumentUrlForOpenApiIntegration ||
      invalidSalesOrder?.error === OmnitroErrorCode.InvalidVasDocumentUrlForOpenApiIntegration 
    )
      return true;
    return false;
  };

  const subtitle = `${intl.messages[`${COMPONENT_INTL_KEY}.Subtitle`]}`;
  const breadcrumb = [
    { title: `${intl.messages['SalesOrders.Header.Title']}`, url: urls.salesOrders },
    { title: `#${referenceNumber}` },
  ];

  if (history.state && history.state.state && receivedPackageNumber == undefined) {
    setPackageNumber(history.state.state.packageNumber - 1);
    setCurrentTab(history.state.state.packageNumber - 1);
  }

  const customerFirstName = invalidSalesOrder?.customer?.firstName ? invalidSalesOrder?.customer?.firstName : '';
  const customerLastName = invalidSalesOrder?.customer?.lastName ? invalidSalesOrder?.customer?.lastName : '';

  const shippingAddressFirstName = invalidSalesOrder?.shippingAddress?.addressFirstName
    ? invalidSalesOrder?.shippingAddress?.addressFirstName
    : '';
  const shippingAddressLastName = invalidSalesOrder?.shippingAddress?.addressLastName
    ? invalidSalesOrder?.shippingAddress?.addressLastName
    : '';

  const integrationIconName = integrationIdentifier(invalidSalesOrder?.integration?.type, invalidSalesOrder?.integration?.openApiIdentifier)

  return (
    <MemberPageContainer id="sales-order-detail" documentTitle={`#${referenceNumber} - ${subtitle}`}>
      <ActionBar
        top="66px"
        title={referenceNumber ? `#${referenceNumber}` : ''}
        subtitle={referenceNumber ? subtitle : intl.messages['ErrorPanel.ErrorMessage']}
        isLoading={isBusy}
        integration={{
          icon: getIntegrationImageSrc(integrationIconName),
          name: invalidSalesOrder?.integration?.name || intl.messages['Integration.NoIntegration'],
        }}
        breadcrumb={breadcrumb}
      >
        <Button
          marginLeft="auto"
          variant="dark"
          size="large"
          onClick={() => {
            setCorrectInvalidSalesOrderModal(true);
          }}
          disabled={!isEditButtonActive()}
        >
          {intl.messages[`${COMPONENT_INTL_KEY}.CorrectInfo`]}
        </Button>
      </ActionBar>
      <MainContent>
        <Box pl="0" pt="22">
          <Box
            marginBottom="30px"
            border="xs"
            display="flex"
            flexDirection="row"
            borderColor="palette.orange_dark"
            borderRadius="sm"
            backgroundColor="palette.orange_lighter"
            p="16"
          >
            <Icon mr="8px" name="fas fa-exclamation-triangle" fontSize="28" color="#E8A028;" marginTop="5px" />
            <Box flexDirection="column">
              <Text
                font="heading"
                fontSize="14"
                fontWeight="700"
                dangerouslySetInnerHTML={{ __html: intl.messages[`${COMPONENT_INTL_KEY}.Warning.EditRecommendation`] }}
              ></Text>
              <li>
                <Text font="heading" fontSize="14" fontWeight="700">
                  {intl.messages[`Enum.${invalidSalesOrder?.error}`]}
                </Text>
              </li>
            </Box>
          </Box>
          <Panel title={intl.messages[`${COMPONENT_INTL_KEY}.Section.Info.Title`]}>
            {
              <Flex flexDirection="row" flexWrap="wrap">
                <Box width={[1, 1, 1, 1 / 4]} pr={['0', '0', '30']} mb={['22', '22', '22', '0']}>
                  <Widget.Two
                    title={customerFirstName + ' ' + customerLastName}
                    subtitle={intl.messages[`${COMPONENT_INTL_KEY}.Section.Info.CustomerFirstNameLastName`]}
                    loading={isBusy}
                    icon={<Icon color="white" name="fas fa-address-book" fontSize="56px" />}
                    iconContainerProps={{ bg: 'palette.orange' }}
                  />
                </Box>
                <Box width={[1, 1, 1, 1 / 4]} pr={['0', '0', '0', '30']} mb={['22', '22', '22', '0']}>
                  <Widget.Two
                    title={
                      <div>
                        <Text fontWeight="bold">{shippingAddressFirstName + ' ' + shippingAddressLastName} - </Text>
                        <Text>{invalidSalesOrder?.shippingAddress?.addressOne}</Text>
                      </div>
                    }
                    subtitle={intl.messages[`${COMPONENT_INTL_KEY}.Section.Info.DeliveryInfo`]}
                    loading={isBusy}
                    icon={<Icon color="white" name="fas fa-truck" fontSize="56px" />}
                    iconContainerProps={{ bg: 'palette.green' }}
                  />
                </Box>
                <Box width={[1, 1, 1, 1 / 4]} pr={['0', '0', '0', '30']} mb={['22', '22', '22', '0']}>
                  <Widget.Two
                    title={
                      <div>
                        <Text fontWeight="bold">
                          {invalidSalesOrder?.cargo?.cargoCode ? (
                            invalidSalesOrder?.cargo?.cargoCode
                          ) : invalidSalesOrder?.cargo?.cargoDocumentUrl ? (
                            <Link href={invalidSalesOrder?.cargo?.cargoDocumentUrl}>
                              {intl.messages[`${COMPONENT_INTL_KEY}.Section.Info.CargoDocumentLink`]}
                            </Link>
                          ) : (
                            '-'
                          )}
                          <br />
                        </Text>
                        <Text>
                          {
                            intl.messages[
                            `${COMPONENT_INTL_KEY}.Section.Info.OrderCargoTypeOptios.${invalidSalesOrder?.cargo?.cargoType}`
                            ]
                          }
                        </Text>
                      </div>
                    }
                    subtitle={intl.messages[`${COMPONENT_INTL_KEY}.Section.Info.CargoInfo`]}
                    loading={isBusy}
                    icon={<Image src={getCargoCarrierImageSrc(invalidSalesOrder?.cargo?.cargoCarrier)} width="100%" />}
                  />
                </Box>
                <Box width={[1, 1, 1, 1 / 4]} pr="0" mb="0">
                  <Widget.Two
                    title={
                      <div>
                        <Text fontWeight="bold">
                          {
                            intl.messages[
                            `${COMPONENT_INTL_KEY}.Section.Info.OrderInfoFormPaymentOptions.${invalidSalesOrder?.payment?.paymentOption}`
                            ]
                          }
                          <br />
                        </Text>
                        <Text>
                          {invalidSalesOrder?.payment?.totalPaymentAmount}{' '}
                          {
                            intl.messages[
                            `${COMPONENT_INTL_KEY}.Section.Info.Currencies.${invalidSalesOrder?.payment?.currency}`
                            ]
                          }
                        </Text>
                      </div>
                    }
                    subtitle={intl.messages[`${COMPONENT_INTL_KEY}.Section.Info.PaymentInfo`]}
                    loading={isBusy}
                    icon={<Icon color="white" name="fas fa-money-bill" fontSize="56px" />}
                    iconContainerProps={{ bg: 'palette.blue' }}
                  />
                </Box>
              </Flex>
            }
          </Panel>
        </Box>
        <Box mt="22">
          {invalidSalesOrderLineItems ? (
            <Tab
              onTabChange={(tabIndex: number) => {
                setCurrentTab(tabIndex);
              }}
              activeIndex={currentTab}
              tabs={[
                ...(invalidSalesOrderLineItems.count > 0
                  ? [
                    {
                      id: 0,
                      title: intl.messages[`${COMPONENT_INTL_KEY}.Grid.${orderState}`],
                      component: (
                        <InvalidSalesOrderLineItemsGrid
                          intl={intl}
                          isPending={false}
                          referenceNumber={referenceNumber}
                          titleKey={referenceNumber}
                          items={invalidSalesOrderLineItems}
                          state={orderState}
                        />
                      ),
                    },
                  ]
                  : []),
              ]}
            />
          ) : (
            <Box>
              <Skeleton count={10} />
            </Box>
          )}
        </Box>
        {correctInvalidSalesOrderModal && (
          <CorrectInvalidSalesOrderModal
            onClose={() => {
              setCorrectInvalidSalesOrderModal(false);
            }}
            correctInvalidSalesOrderResource={correctInvalidSalesOrderResource}
            onSubmit={onSubmit}
            validator={validator}
            invalidSalesOrderDetails={invalidSalesOrder}
            dialogOpen={false}
            cargoCarriers={cargoCompaniesOutput}
          />
        )}
        <Dialog
          type={DialogTypes.Success}
          isOpen={isDialogOpen}
          isLoading={false}
          text={{
            approve: intl.messages[`Modal.Success.Okay`],
          }}
          onApprove={() => {
            if (correctInvalidSalesOrderResource.isSuccess) {
              setIsDialogOpen(false);
              redirectOnSalesOrderCreateSuccess();
            }
            if (correctInvalidSalesOrderResource.error) {
              setIsDialogOpen(false);
              window.location.reload();
            }
          }}
          onCancel={() => {
            setIsDialogOpen(false);
          }}
          message={
            correctInvalidSalesOrderResource && correctInvalidSalesOrderResource.error
              ? intl.messages[`${COMPONENT_INTL_KEY}.Dialog.HasErrors`]
              : '#' + referenceNumber + intl.messages[`${COMPONENT_INTL_KEY}.Dialog.Success`]
          }
          infoMessage={
            correctInvalidSalesOrderResource && correctInvalidSalesOrderResource.isSuccess
              ? intl.messages[`${COMPONENT_INTL_KEY}.Dialog.SuccessSubMessage`]
              : ''
          }
        />
      </MainContent>
    </MemberPageContainer>
  );
};

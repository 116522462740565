import { ChangeCompanyInfoModal, ChangeUserInfoModal, GeneralErrorPanel, UsersGrid } from '@components';
import AddUserModal from '@components/molecules/AddUserModal/AddUserModal';
import { MemberPageContainer } from '@containers/MemberPageContainer';
import { HTTP_ERRORS } from '@models';
import {
  ActionBar,
  Alert,
  AlertDescription,
  AlertTitle,
  Box,
  Button,
  Dialog,
  DialogProps,
  DialogTypes,
  Flex,
  FormValidator,
  LayoutContent,
  Panel,
  Widget,
  Text,
  Popover,
  Image,
} from '@oplog/express';
import { Resource } from '@oplog/resource-redux';
import {
  ChangeUserTypeCommand,
  CompanyDTO,
  CreateUserCommand,
  DeleteUserCommand,
  UpdateCompanyCommand,
  UserDTO,
  UserType,
} from '@services';
import useAuthStore from '@store/auth/auth';
import moment from 'moment';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Props } from '../../atoms/Component/Component';

const DATE_FORMAT = 'DD.MM.YYYY HH:MM';
const COMPONENT_INTL_KEY = 'Settings';

export interface SettingsProps extends Props {
  isCreateSuccesful: boolean;
  isChangeSuccessful: boolean;
  isError: boolean;
  isBusy: boolean;
  onCreate: (form: CreateUserCommand) => void;
  initResource: () => void;
  validator: FormValidator;
  onChangeType: (form: ChangeUserTypeCommand) => void;
  refreshList: () => void;
  userData: Resource<UserDTO>;
  companyData: Resource<CompanyDTO>;
  onDelete: (form: DeleteUserCommand) => void;
  isDeleteSuccessful: boolean;
  isDeleteError: boolean;
  isUpdateUserSuccessful: boolean;
  updateUser: (form: any, userID: string) => void;
  isUpdateCompanySuccessful: boolean;
  updateCompany: (form: UpdateCompanyCommand) => void;
  refreshUserDetail: (userInfo: any) => void;
  initComponent: (userInfo: any) => void;
  refreshCompanyDetail: () => void;
  refreshGrid: () => void;
  createError: ErrorModel;
}

export const Settings: React.FC<SettingsProps> = ({
  intl,
  onDelete,
  createError,
  initResource,
  userData,
  companyData,
  validator,
  isCreateSuccesful,
  isBusy,
  isError,
  isUpdateUserSuccessful,
  updateUser,
  refreshUserDetail,
  refreshGrid,
  isUpdateCompanySuccessful,
  updateCompany,
  refreshCompanyDetail,
  onChangeType,
  onCreate,
  isChangeSuccessful,
  refreshList,
  initComponent,
  onWillUnmount,
  isDeleteSuccessful,
}) => {
  const [isOpenCreateUserModal, setIsOpenCreateUserModal] = useState(false);
  const [isOpenChangeUserInfoModal, setIsOpenChangeUserInfoModal] = useState(false);
  const [isOpenChangeCompanyInfoModal, setIsOpenChangeCompanyInfoModal] = useState(false);
  const [form, setForm] = useState<CreateUserCommand>({ email: '', fullName: '' });

  const [{ auth0UserInfo }, { userIsTenantAdmin }] = useAuthStore();

  useEffect(() => {
    if (Object.keys(auth0UserInfo).length != 0) {
      initComponent(auth0UserInfo);
    }
  }, [auth0UserInfo]);

  const [dialog, setDialog] = useState<DialogProps>({
    type: DialogTypes.Success,
    message: '',
    isOpen: false,
    onApprove: () => undefined,
    text: { approve: intl.messages[`Modal.Warning.Okay`] },
  });

  const isTenantAdmin = userIsTenantAdmin();

  useEffect(() => {
    return () => {
      onWillUnmount && onWillUnmount();
    };
  }, []);

  useEffect(() => {
    const handleSuccessClick = () => {
      setDialog({ ...dialog, isOpen: false });
      setIsOpenCreateUserModal(false);
      setIsOpenChangeUserInfoModal(false);
      setIsOpenChangeCompanyInfoModal(false);
      setForm({ email: '', fullName: '' });
      initResource();
      refreshList();
    };

    const isSuccessful = isChangeSuccessful || isCreateSuccesful || isDeleteSuccessful;

    if (isSuccessful) {
      let messageType = 'ChangeTypeSuccess';
      if (isCreateSuccesful) messageType = 'Success';
      if (isDeleteSuccessful) messageType = 'DeleteSuccess';

      setDialog({
        type: DialogTypes.Success,
        isOpen: true,
        text: {
          approve: intl.messages[`Modal.Warning.Okay`],
        },
        onApprove: () => handleSuccessClick(),
        message: (
          <FormattedMessage
            id={`${COMPONENT_INTL_KEY}.AddUserForm.Modal.${messageType}`}
            values={{ fullname: <strong>{form.fullName}</strong>, email: <strong>{form.email}</strong> }}
          />
        ),
      });
    }

    if (isBusy) {
      setDialog({
        ...dialog,
        isLoading: isBusy,
      });
    }
  }, [isChangeSuccessful, isCreateSuccesful, isDeleteSuccessful, isBusy]);

  const userAction = (rowData: UserDTO, type: 'assign' | 'delete') => {
    const isAssign = type === 'assign';
    setForm({ fullName: rowData.fullName, email: isAssign ? '' : rowData.email });
    setDialog({
      type: isAssign ? DialogTypes.Warning : DialogTypes.Danger,
      isOpen: true,
      isLoading: isBusy,
      text: { approve: intl.messages[`Modal.Warning.Okay`], cancel: intl.messages[`Modal.Warning.Cancel`] },
      onCancel: () => {
        setDialog({ ...dialog, isOpen: false });
        setForm({ email: '', fullName: '' });
      },
      onApprove: () => {
        if (!isBusy) {
          if (isAssign) {
            onChangeType({ userId: rowData.id, type: UserType.Owner });
          } else {
            onDelete({ userId: rowData.id });
          }
        }
      },
      message: (
        <FormattedMessage
          id={`${COMPONENT_INTL_KEY}.AddUserForm.Modal.${isAssign ? 'TypeConfirmation' : 'DeleteConfirmation'}`}
          values={{ email: <strong>{rowData.email}</strong>, fullname: <strong>{rowData.fullName}</strong> }}
        />
      ),
    });
  };

  const renderValues = (key: string, value: string) => {
    switch (key) {
      case 'type':
        return (
          <Flex>
            <Text fontFamily="heading" fontSize="16" mr="8px" color="text.body" fontWeight={800} lineHeight="xxlarge">
              {intl.messages[`Enum.${value}`]}
            </Text>
            <Popover
              isDark
              content={<Text textAlign="center" fontWeight={500} dangerouslySetInnerHTML={{ __html: intl.messages[`Enum.${value}Privileges`] }}></Text>}
              placement="top"
              withArrow
              action={['hover']}
            >
              <Image src="/images/information_icon.png" width="20px"/>
            </Popover>
          </Flex>
        );
      case 'createdAt':
        return moment(value).format(DATE_FORMAT);
      default:
        return value;
    }
  };

  const getValues = (
    keys: any,
    resource: Resource<UserDTO | CompanyDTO>,
    intl_key: string
  ): { title: string; value: string }[] => {
    return keys.map((key: any) => ({
      title: intl.messages[`${COMPONENT_INTL_KEY}.${intl_key}.${key}`],
      value: resource && resource.data ? renderValues(key, resource.data[key]) : '',
    }));
  };

  const getUserInfo = () => {
    const keys = ['fullName', 'email', 'type', 'createdAt'];
    return getValues(keys, userData, 'UserInfoPanel');
  };

  const getCompanyInfo = () => {
    const keys = ['title', 'address', 'phone', 'taxAdministration', 'taxNo'];
    return getValues(keys, companyData, 'CompanyInfoPanel');
  };

  const submitForm = (formValues: CreateUserCommand) => {
    if (!isBusy) onCreate(formValues);
  };

  return (
    <MemberPageContainer documentTitle={intl.messages[`${COMPONENT_INTL_KEY}.Title`]}>
      {createError && createError.code !== HTTP_ERRORS.Conflict && (
        <Alert
          variant="danger"
          isOpen={createError && createError.code !== HTTP_ERRORS.Conflict}
          onDismiss={initResource}
        >
          <AlertTitle>{intl.messages['PostErrorMessage.Title']}</AlertTitle>
          <AlertDescription>{intl.messages['PostErrorMessage.Description']}</AlertDescription>
        </Alert>
      )}
      <ActionBar
        top="66px"
        title={intl.messages[`${COMPONENT_INTL_KEY}.Title`]}
        breadcrumb={[{ title: intl.messages[`${COMPONENT_INTL_KEY}.Title`] }]}
      >
        <Flex marginLeft="auto">
          {isTenantAdmin && (
            <Button mr="6" onClick={() => setIsOpenCreateUserModal(true)} size="large">
              {intl.messages[`${COMPONENT_INTL_KEY}.CreateNewUser`]}
            </Button>
          )}

          <Button
            variant="dark"
            size="large"
            dropdown={{
              items: [
                {
                  text: intl.messages[`${COMPONENT_INTL_KEY}.DropdownList.EditProfile`],
                  value: intl.messages[`${COMPONENT_INTL_KEY}.DropdownList.EditProfile`],
                  onClick: () => setIsOpenChangeUserInfoModal(true),
                },
                {
                  text: intl.messages[`${COMPONENT_INTL_KEY}.DropdownList.EditCompany`],
                  value: intl.messages[`${COMPONENT_INTL_KEY}.DropdownList.EditCompany`],
                  onClick: () => setIsOpenChangeCompanyInfoModal(true),
                },
              ],
            }}
          >
            {intl.messages[`${COMPONENT_INTL_KEY}.Edit`]}
          </Button>
        </Flex>
      </ActionBar>
      <LayoutContent>
        <Flex flexDirection="row" flexWrap="wrap">
          <Flex flexDirection="column" width={[1, 1, 1 / 2]} pr={['0', '0', '16']} mb="16">
            {userData && userData.error ? (
              <Panel title={intl.messages[`${COMPONENT_INTL_KEY}.UserInfoPanel.Title`]}>
                <GeneralErrorPanel className="align-top" />
              </Panel>
            ) : (
              <Widget.One
                panelProps={{
                  title: intl.messages[`${COMPONENT_INTL_KEY}.UserInfoPanel.Title`],
                  height: '100%',
                }}
                isLoading={!userData || (userData && userData.isBusy)}
                loadingItems={4}
                fields={getUserInfo()}
                column={1}
              />
            )}
          </Flex>
          <Box width={[1, 1, 1 / 2]} mb="16">
            {companyData && companyData.error ? (
              <Panel title={intl.messages[`${COMPONENT_INTL_KEY}.CompanyInfoPanel.Title`]}>
                <GeneralErrorPanel className="align-top" />
              </Panel>
            ) : (
              <Widget.One
                panelProps={{
                  title: intl.messages[`${COMPONENT_INTL_KEY}.CompanyInfoPanel.Title`],
                }}
                height="full"
                loadingItems={6}
                isLoading={!companyData || (companyData && companyData.isBusy)}
                fields={getCompanyInfo()}
                column={1}
              />
            )}
          </Box>
        </Flex>
        <Box width={1}>
          <Panel title={intl.messages[`${COMPONENT_INTL_KEY}.Grid.Title`]} height="full">
            <UsersGrid
              intl={intl}
              onAssignAdmin={rowData => userAction(rowData, 'assign')}
              onDeleteUser={rowData => userAction(rowData, 'delete')}
            />
          </Panel>
        </Box>
        {isOpenCreateUserModal && (
          <AddUserModal
            onClose={() => {
              validator.clearErrors();
              initResource();
              setIsOpenCreateUserModal(false);
            }}
            isOpen={isOpenCreateUserModal}
            onSubmit={(formValues: CreateUserCommand) => {
              setForm(formValues);
              setDialog({
                type: DialogTypes.Confirmation,
                isOpen: true,
                isLoading: isBusy,
                text: {
                  approve: intl.messages[`Modal.Warning.Okay`],
                  cancel: intl.messages[`Modal.Warning.Cancel`],
                },
                onCancel: () => {
                  setDialog({ ...dialog, isOpen: false });
                },
                onApprove: () => submitForm(formValues),
                message: (
                  <FormattedMessage
                    id={`${COMPONENT_INTL_KEY}.AddUserForm.Modal.Confirmation`}
                    values={{
                      email: <strong>{formValues.email}</strong>,
                      fullname: <strong>{formValues.fullName}</strong>,
                    }}
                  />
                ),
              });
            }}
            closeConfirmationModal={() => setDialog({ ...dialog, isOpen: false })}
            validator={validator}
            isBusy={isBusy}
            isError={isError}
            createError={createError}
          />
        )}

        <Dialog {...dialog} />

        {isOpenChangeUserInfoModal && userData.data && (
          <ChangeUserInfoModal
            isOpen={isOpenChangeUserInfoModal}
            intl={intl}
            validator={validator}
            isBusy={isBusy}
            isSuccessful={isUpdateUserSuccessful}
            onClose={(refresh = false) => {
              setIsOpenChangeUserInfoModal(false);
              validator.clearErrors();
              initResource();
              if (refresh) {
                // auth.renewSession();
                refreshUserDetail(auth0UserInfo);
                refreshGrid();
              }
            }}
            onSubmit={updateUser}
            fields={{
              fullName: userData.data.fullName,
            }}
            userID={auth0UserInfo.sub}
          />
        )}
        {isOpenChangeCompanyInfoModal && companyData.data && (
          <ChangeCompanyInfoModal
            isOpen={isOpenChangeCompanyInfoModal}
            intl={intl}
            validator={validator}
            isBusy={isBusy}
            isSuccessful={isUpdateCompanySuccessful}
            onClose={(refresh = false) => {
              setIsOpenChangeCompanyInfoModal(false);
              initResource();
              validator.clearErrors();
              if (refresh) refreshCompanyDetail();
            }}
            onSubmit={updateCompany}
            fields={{ ...companyData.data }}
          />
        )}
      </LayoutContent>
    </MemberPageContainer>
  );
};

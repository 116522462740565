import { GridContainer } from '@components/atoms/GridContainer';
import { barcodeFormatter, geti18nName, ProductDetailsLinkFormatter } from '@containers/formatters';
import { GridProps, GridType } from '@models';
import { ColumnSize, DataGridColumn, DataGridRow, ImageFormatter } from '@oplog/data-grid';
import {
  LineItemOfSalesOrderQueryOutputDTO,
  LineItemOfSalesOrderQueryOutputDTODynamicQueryOutputDTO,
  ProductType,
} from '@services';
import { ArrayFilterOperation } from 'dynamic-query-builder-client';
import * as React from 'react';

const intlKey = 'InvalidSalesOrderLineItemsGrid';

export enum OrderStates {
  'LineItems',
  'CancelledItems',
  'WaitingForPackaging',
}

export interface InvalidSalesOrderLineItemsGridProps extends GridProps {
  referenceNumber: string;
  isPending: boolean;
  isPackage?: boolean;
  headerContent?: React.ReactNode;
  onCancelLineItem?: (rowData: LineItemOfSalesOrderQueryOutputDTO) => void;
  items?: LineItemOfSalesOrderQueryOutputDTODynamicQueryOutputDTO;
  state?: keyof typeof OrderStates;
}

export const InvalidSalesOrderLineItemsGrid: React.FC<InvalidSalesOrderLineItemsGridProps> = ({
  intl,
  titleKey,
  isPending,
  referenceNumber,
  isPackage,
  headerContent,
  items,
  state,
}) => {
  const [cancelledExist, setCancelledExist] = React.useState(false);
  const [missingExist, setMissignExist] = React.useState(false);
  let quantityTitle = 'amountInOrder';
  if (state === 'CancelledItems') quantityTitle = 'cancelledAmountInOrder';
  if (isPackage) quantityTitle = 'amountInPackage';

  const invalidSalesOrderLineItemsGridColumns: Array<DataGridColumn> = [
    {
      name: '#',
      key: 'index',
      resizable: true,
      locked: true,
      sortable: false,
      type: 'number',
      visibility: true,
      filterable: false,
      width: ColumnSize.Medium,
      cellClass: 'index-column',
    },
    {
      name: geti18nName('sku', intl, intlKey),
      key: 'sku',
      locked: true,
      sortable: true,
      type: 'string',
      visibility: true,
      filterable: true,
      resizable: true,
    },
    {
      name: geti18nName('barcode', intl, intlKey),
      key: 'barcodes',
      locked: true,
      type: 'string',
      visibility: true,
      resizable: true,
      sortable: false,
      filterable: true,
      formatter: barcodeFormatter,
      fieldType: 'array',
      searchField: '_',
      outerOp: ArrayFilterOperation.Any,
    },
    {
      name: geti18nName('imageUrl', intl, intlKey),
      key: 'imageUrl',
      locked: true,
      sortable: false,
      type: 'string',
      visibility: true,
      filterable: false,
      formatter: ImageFormatter,
      width: ColumnSize.Medium,
    },
    {
      name: geti18nName('productName', intl, intlKey),
      key: 'productName',
      locked: true,
      sortable: true,
      type: 'string',
      visibility: true,
      filterable: true,
      formatter: ProductDetailsLinkFormatter,
      getRowMetaData: (row: DataGridRow) => row,
      resizable: true,
    },
    {
      name: geti18nName(quantityTitle, intl, intlKey),
      key: 'amountInOrder',
      resizable: true,
      sortable: true,
      type: 'number',
      visibility: true,
      filterable: true,
    },
  ];

  const checkItems = () => {
    if (isPending && items && items.count > 0) {
      // eslint-disable-next-line no-unused-expressions
      items?.data?.find(item => {
        if (item.cancelledAmount > 0) {
          setCancelledExist(true);
        }

        if (item.missingQuantity > 0) {
          setMissignExist(true);
        }
      });
    }
  };

  React.useEffect(() => checkItems(), []);
  React.useEffect(() => {
    checkItems();
  }, [items, isPending]);

  const rowRenderer = ({ ...props }: any) => {
    return props.isPendingReason ? 'productStockWarning' : '';
  };

  return (
    <GridContainer
      titleKey={
        titleKey
          ? `${intl.messages['SalesOrderDetails.Grid.Title']} - ${titleKey}`
          : intl.messages['SalesOrderDetails.Grid.Title']
      }
      intlKey={intlKey}
      gridKey={GridType.InvalidSalesOrderLineItems}
      columns={invalidSalesOrderLineItemsGridColumns}
      predefinedFilters={[]}
      filtersDisabled
      apiArgs={[referenceNumber]}
      intl={intl}
      headerContent={headerContent}
      rowRenderer={isPending ? rowRenderer : undefined}
    />
  );
};

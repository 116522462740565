export const urls = {
  // Misc
  help: '/help/:thread/:category/:subject',
  helpHome: '/help',
  settings: '/settings',
  notFound: '*',
  home: '/',
  authentication: '/login',
  callback: '/callback',
  // Sales Orders
  salesOrders: '/sales-orders/tabs/:tab?',
  salesOrderDetails: '/sales-orders/:referenceNumber/:tab?',
  createSalesOrder: '/create-sales-order',
  // Invalid Sales Orders
  invalidSalesOrderDetails: '/invalid-sales-orders/:referenceNumber',
  // Customers
  customers: '/contacts',
  customerDetails: '/contacts/:customerNo/:tab?',
  createCustomer: '/create-contact',
  // Integrations
  integrations: '/integrations',
  // Products
  products: '/products/tabs/:tab?',
  productDetails: '/products/:sku/:tab?',
  // Inventory
  inventory: '/inventory/tabs/:tab?',
  // Receiving Orders
  receivingOrders: '/receiving-orders',
  receivingOrderDetails: '/receiving-orders/:referenceNumber/:tab?',
  // Warehouse
  warehouse: '/send-to-warehouse',
  // Return
  returns: '/returns/tabs/:tab?',
  returnDetails: '/returns/:referenceNumber',
  createReturn: '/create-return',
  // Support Page
  support: '/support',
  supportSalesOrders: '/support/salesOrders/:tab',
  supportReceivingOrders: '/support/receivingOrders',
  supportPackages: '/support/packages/:tab',
};

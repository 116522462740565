import { GridContainer } from '@components/atoms/GridContainer';
import { ExcelExportModal, ExportTypes } from '@components/molecules/ExcelExportModal';
import {
  bubbleStepFormatter,
  CustomerDetailsLinkFormatter,
  geti18nName,
  returnCountFormatter,
  SalesOrderDetailsLinkFormatter,
  sourceIconFormatter,
} from '@containers/formatters';
import { GridProps, GridType, ResourceType } from '@models';
import {
  ColumnSize,
  DataGridColumn,
  DataGridRow,
  dateTimeFormatter,
  ellipsisFormatter,
  enumFormatter,
  getEnumOptions,
  PredefinedFilter,
} from '@oplog/data-grid';
import { Box } from '@oplog/express';
import { CargoType, PaymentOption, SalesOrderState } from '@services';
import { EXCEL_EXPORT_LIMIT, EXCEL_EXPORT_SALESORDER_LIMIT, generateIntegrationEnum } from '@utils';
import {
  NumericFilter,
  NumericFilterOperation,
  SortDirection,
  SortField,
  StringFilter,
  StringFilterOperation,
} from 'dynamic-query-builder-client';
import * as React from 'react';

const intlKey = 'SalesOrdersGrid';

const salesOrdersGridInitalSort: SortField = new SortField({
  property: 'orderCreatedAt',
  by: SortDirection.DESC,
});

export const salesOrdersGridPredefinedFilters: Array<PredefinedFilter> = [
  {
    filter: new StringFilter({
      id: 'isDelivered',
      property: 'state',
      op: StringFilterOperation.NotEqual,
      value: `${SalesOrderState.Delivered}`,
    }),
    selected: false,
  },
  {
    filter: new StringFilter({
      id: 'isOrderCreated',
      property: 'state',
      op: StringFilterOperation.Equals,
      value: `${SalesOrderState.Created}`,
    }),
    selected: false,
  },
  {
    filter: new StringFilter({
      id: 'isOrderPicked',
      property: 'state',
      op: StringFilterOperation.Equals,
      value: `${SalesOrderState.Picked}`,
    }),
    selected: false,
  },
  {
    filter: new StringFilter({
      id: 'isOrderPacked',
      property: 'state',
      op: StringFilterOperation.Equals,
      value: `${SalesOrderState.Packed}`,
    }),
    selected: false,
  },
  {
    filter: new StringFilter({
      id: 'isOrderReadyToBeShipped',
      property: 'state',
      op: StringFilterOperation.Equals,
      value: `${SalesOrderState.ReadyToBeShipped}`,
    }),
    selected: false,
  },
  {
    filter: new StringFilter({
      id: 'isOrderShipped',
      property: 'state',
      op: StringFilterOperation.Equals,
      value: `${SalesOrderState.Shipped}`,
    }),
    selected: false,
  },
  {
    filter: new StringFilter({
      id: 'isOrderDelivered',
      property: 'state',
      op: StringFilterOperation.Equals,
      value: `${SalesOrderState.Delivered}`,
    }),
    selected: false,
  },
  {
    filter: new StringFilter({
      id: 'isOrderCancelled',
      property: 'state',
      op: StringFilterOperation.Equals,
      value: `${SalesOrderState.Cancelled}`,
    }),
    selected: false,
  },
  {
    filter: new NumericFilter({
      id: 'doesOrderHaveReturn',
      property: 'returnCount',
      op: NumericFilterOperation.GreaterThanOrEqual,
      value: 1,
    }),
    selected: false,
  },
];

interface SalesOrdersGridProps extends GridProps {
  filters: any;
  integrations: any;
  excelToExport: (resourceType: ResourceType) => void;
  excelExportResource: any;
  excelToExportInit: () => void;
  excelExportDetailedResource: any;
  salesOrdersPackageDetailExcelExportLimit: number;
}

export const SalesOrdersGrid: React.FC<SalesOrdersGridProps> = ({
  intl,
  titleKey,
  filters,
  integrations,
  excelToExport,
  excelToExportInit,
  excelExportResource,
  excelExportDetailedResource,
  salesOrdersPackageDetailExcelExportLimit
}) => {
  const [cancelVisibility, setCancelVisibility] = React.useState(false);
  const [isExcelExportModalOpen, setIsExcelExportModalOpen] = React.useState(false);
  const [rowCount, setRowCount] = React.useState(0);

  const [excelExportPackageDetailLimit, setExcelExportPackageDetailLimit] = React.useState(EXCEL_EXPORT_SALESORDER_LIMIT);

  React.useEffect(() => {
    setRowCount(filters.footer.rowCount);
  }, [filters.footer]);

  React.useEffect(() => {
    if (salesOrdersPackageDetailExcelExportLimit) {
      setExcelExportPackageDetailLimit(salesOrdersPackageDetailExcelExportLimit);
    }
    else {
      setExcelExportPackageDetailLimit(EXCEL_EXPORT_SALESORDER_LIMIT);
    }
  });

  const salesOrdersGridColumns: Array<DataGridColumn> = [
    {
      name: '#',
      key: 'index',
      resizable: true,
      locked: true,
      sortable: false,
      type: 'number',
      visibility: true,
      filterable: false,
      width: ColumnSize.Medium,
      cellClass: 'index-column',
    },
    {
      name: geti18nName('referenceNumber', intl, intlKey),
      key: 'referenceNumber',
      resizable: true,
      locked: true,
      sortable: true,
      type: 'string',
      visibility: true,
      filterable: true,
      formatter: SalesOrderDetailsLinkFormatter,
      getRowMetaData: (row: DataGridRow) => row,
    },
    {
      name: geti18nName('customerEmail', intl, intlKey),
      key: 'customerEmail',
      type: 'string',
      visibility: false,
      filterable: true,
    },
    {
      name: geti18nName('customerPhone', intl, intlKey),
      key: 'customerPhone',
      type: 'string',
      visibility: false,
      filterable: true,
    },
    {
      name: geti18nName('integrationType', intl, intlKey),
      key: 'integrationName',
      locked: true,
      sortable: false,
      type: 'enum',
      visibility: true,
      filterable: true,
      resizable: true,
      width: ColumnSize.Large,
      formatter: row => sourceIconFormatter(row.value, row.dependentValues, intl),
      getRowMetaData: (row: DataGridRow) => row,
      options: integrations ? generateIntegrationEnum(integrations) : [],
    },
    {
      name: geti18nName('salesChannel', intl, intlKey),
      key: 'salesChannel',
      locked: true,
      sortable: false,
      type: 'string',
      visibility: true,
      filterable: true,
      resizable: true,
      getRowMetaData: (row: DataGridRow) => row['integrationType'],
    },
    {
      name: geti18nName('state', intl, intlKey),
      key: 'state',
      locked: true,
      sortable: false,
      type: 'enum',
      visibility: true,
      filterable: true,
      formatter: bubbleStepFormatter,
      getRowMetaData: (row: DataGridRow) => {
        return {
          intl,
          enum: SalesOrderState,
          row,
        };
      },
      resizable: true,
      options: getEnumOptions(intl, SalesOrderState),
    },
    {
      name: geti18nName('cargoType', intl, intlKey),
      key: 'cargoType',
      locked: true,
      sortable: false,
      type: 'enum',
      visibility: true,
      filterable: true,
      formatter: enumFormatter,
      getRowMetaData: () => {
        return intl;
      },
      resizable: true,
      options: getEnumOptions(intl, CargoType),
    },
    {
      name: geti18nName('expectedShipmentDate', intl, intlKey),
      key: 'expectedShipmentDate',
      locked: true,
      sortable: true,
      type: 'moment',
      visibility: true,
      filterable: true,
      resizable: true,
      formatter: dateTimeFormatter,
    },
    {
      name: geti18nName('customerFullName', intl, intlKey),
      key: 'customerFullName',
      locked: true,
      sortable: false,
      type: 'string',
      visibility: true,
      filterable: true,
      formatter: CustomerDetailsLinkFormatter,
      getRowMetaData: (row: DataGridRow) => row,
      resizable: true,
    },
    {
      name: geti18nName('shippingAddressFullName', intl, intlKey),
      key: 'shippingAddressFullName',
      type: 'string',
      visibility: true,
      filterable: true,
      sortable: false,
      resizable: true,
      locked: true,
    },
    {
      name: geti18nName('warehouseLocation', intl, intlKey),
      key: 'warehouseLocation',
      locked: true,
      type: 'string',
      visibility: true,
      filterable: true,
      resizable: true,
    },
    {
      name: geti18nName('shippingAddressCity', intl, intlKey),
      key: 'shippingAddressCity',
      locked: true,
      sortable: true,
      type: 'string',
      visibility: true,
      filterable: true,
      resizable: true,
      formatter: ellipsisFormatter,
      getRowMetaData: (row: DataGridRow) => row,
    },
    {
      name: geti18nName('shippingAddressCountry', intl, intlKey),
      key: 'shippingAddressCountry',
      locked: true,
      sortable: true,
      type: 'string',
      visibility: true,
      filterable: true,
      resizable: true,
      formatter: ellipsisFormatter,
      getRowMetaData: (row: DataGridRow) => row,
    },
    {
      name: geti18nName('paymentOption', intl, intlKey),
      key: 'paymentOption',
      locked: true,
      sortable: false,
      type: 'enum',
      visibility: true,
      filterable: true,
      formatter: enumFormatter,
      options: getEnumOptions(intl, PaymentOption),
      getRowMetaData: () => {
        return intl;
      },
    },
    {
      name: geti18nName('uniqueProductCount', intl, intlKey),
      key: 'uniqueProductCount',
      locked: true,
      sortable: true,
      type: 'number',
      visibility: true,
      filterable: true,
      resizable: true,
    },
    {
      name: geti18nName('totalProductCount', intl, intlKey),
      key: 'totalProductCount',
      locked: true,
      sortable: true,
      type: 'number',
      visibility: true,
      filterable: true,
      resizable: true,
    },
    {
      name: geti18nName('returnCount', intl, intlKey),
      key: 'returnCount',
      locked: true,
      sortable: true,
      type: 'number',
      visibility: true,
      filterable: true,
      resizable: true,
      formatter: returnCountFormatter,
    },
    {
      name: geti18nName('orderCreatedAt', intl, intlKey),
      key: 'orderCreatedAt',
      locked: true,
      sortable: true,
      type: 'moment',
      visibility: true,
      filterable: true,
      resizable: true,
      formatter: dateTimeFormatter,
    },
    {
      name: geti18nName('cancelledAt', intl, intlKey),
      key: 'cancelledAt',
      locked: true,
      sortable: false,
      type: 'moment',
      visibility: cancelVisibility,
      filterable: true,
      resizable: true,
      formatter: dateTimeFormatter,
    },
  ];

  React.useEffect(() => {
    const filtersHeader = filters.header;

    const filterTypes = [filtersHeader['appliedFilters'], filtersHeader['predefinedFilters']];
    const isCancelFilterOn = filterTypes.some(type => {
      return type.some((filter: any) => {
        return (
          (filter.op === 'Equals' && filter.value === 'Cancelled') ||
          (filter.selected && filter.filter.op === 'Equals' && filter.filter.value === 'Cancelled')
        );
      });
    });

    if (isCancelFilterOn) {
      setCancelVisibility(true);
    } else {
      setCancelVisibility(false);
    }
  }, [filters]);

  const toggleExcelExportModal = () => {
    setIsExcelExportModalOpen(true);
  };

  const onModalClose = () => {
    setIsExcelExportModalOpen(false);
  };

  return (
    <Box>
      <GridContainer
        titleKey={
          titleKey
            ? `${intl.messages['SalesOrders.Tabs.Orders']} - ${titleKey}`
            : intl.messages['SalesOrders.Tabs.Orders']
        }
        intlKey={intlKey}
        remoteExportToExcel
        onExcelExportButtonClick={toggleExcelExportModal}
        gridKey={GridType.SalesOrders}
        columns={salesOrdersGridColumns}
        predefinedFilters={salesOrdersGridPredefinedFilters}
        intl={intl}
        sortField={salesOrdersGridInitalSort}
      />
      {isExcelExportModalOpen && (
        <ExcelExportModal
          excelToExport={excelToExport}
          intl={intl}
          onModalClose={onModalClose}
          excelExportResource={excelExportResource}
          excelExportDetailedResource={excelExportDetailedResource}
          excelToExportInit={excelToExportInit}
          excelExportType={ExportTypes.SalesOrder}
          rowCount={rowCount}
          defaultExportLimit={EXCEL_EXPORT_LIMIT}
          detailedExportLimit={excelExportPackageDetailLimit}
        />
      )}
    </Box>
  );
};
